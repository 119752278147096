import { Component } from "react";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { connect } from "react-redux";
import { RootState } from "src/store/reducer";

export interface OwnProps {
  show?: boolean;
  label: string;
  className?: string;
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
  return {
    show: state.system.loading,
    ...ownProps,
  };
}

function mapDispatchToProps() {
  return {};
}

class Loader extends Component<ReturnType<typeof mapStateToProps>> {
  static defaultProps = {
    show: false,
    label: "",
    className: "",
  };

  render() {
    const { show, label, className } = this.props;
    return (
      <div
        className={[show && "active", "loader", className]
          .filter((e) => !!e)
          .join(" ")}
      >
        <Spinner {...this.props} size={SpinnerSize["large"]} label={label} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
