import { HttpClient } from "src/library/HttpClient";
import {
  FacilityTypeChangeStatusRequestModel,
  FacilityTypeGetByIdRequestModel,
  FacilityTypeUpdateRequestModel,
  FacilityTypeSaveRequestModel,
  FacilityTypeGetAllRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class FacilityType {
  async getAll(request: FacilityTypeGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.facilityType + "GetAll",
      { ...request, token }
    );
  }

  async save(request: FacilityTypeSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.facilityType + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: FacilityTypeUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.facilityType + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(request: FacilityTypeGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.facilityType + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(
    request: FacilityTypeChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.facilityType + "ChangeStatus",
      { ...request, token }
    );
  }
}
