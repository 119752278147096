import { StatusEnums } from "../BaseEnum";

export interface HotelSaveDataModel {
  companyName: string;
  brandName: string;
  districtId: number;
  layoverSubCategoryId: number;
  status: number;
  hotelStarCount: number;
  webSite: string;
  documentNo: number;
  layoverFacilityTypeId: number;
  attachmentIdList: any[];
  bedCount: string;
  roomCount: number;
  hotelEmail: string;
  hotelAddress: string;
  latitude: number;
  longitude: number;
  hotelPhone: string;
  hotelManagerName: string;
  hospitalId: number;
  isOpen: boolean;
  isHaveHealthKit: boolean;
  isApproved: boolean;
  openingDate: number;
  closingDate: number;
}
export interface HotelSaveRequestModel {
  data: HotelSaveDataModel;
}

export interface IGetHotelHistoryListRequest {
  hotelId: number;
  pageSize?: number;
  pageIndex?: number;
}

export interface GetHotelListModel {
  searchKey?: string;
  companyName?: string;
  districtId?: number;
  cityId?: number;
  layoverSubCategoryId?: number;
  layoverFacilityTypeId?: number;
  isOpen?: true;
  isHaveHealthKit?: true;
  hospitalId?: number;
  pageSize?: number;
  pageIndex?: number;
  orderField?: string;
  isDescending?: boolean;
  status?: StatusEnums;
  roomCount?: number;
  isForAdditionalAudit?: false;
}
export interface GetHotelCertificateListByHotelModel {
  id: number;
}
export interface HotelUpdateDataModel {
  hotelId: number;
  companyName: string;
  brandName: string;
  districtId: number;
  layoverSubCategoryId: number;
  status: number;
  hotelStarCount: number;
  webSite: string;
  documentNo: number;
  layoverFacilityTypeId: number;
  attachmentIdList: any[];
  bedCount: string;
  roomCount: number;
  hotelEmail: string;
  hotelAddress: string;
  latitude: number;
  longitude: number;
  hotelPhone: string;
  hotelManagerName: string;
  hospitalId: number;
  isOpen: boolean;
  isHaveHealthKit: boolean;
  isApproved: boolean;
  openingDate: number;
  closingDate: number;
  isImportantSave?: any;
}

export interface HotelUpdateRequestModel {
  data: HotelUpdateDataModel;
}

export interface HotelChangeStatusRequestModel {
  status: StatusEnums;
  id: number;
}

export enum ConditionOperatorEnum {
  Eq = 0,
  NotEq = 1,
  Like = 2,
  Gt = 3,
  Lt = 4,
  Ge = 5,
  Le = 6,
}

export interface criteriaItemListData {
  propertyName: string;
  value: any;
  isOr: boolean;
  conditionOperator: ConditionOperatorEnum;
}

export interface HotelGetAllRequestModel {
  criteriaItemList?: criteriaItemListData[];
  selectItemList?: string[];
  pageSize?: number;
  pageIndex?: number;
  orderField?: string;
  isDescending?: boolean;
  status?: StatusEnums;
  isForAdditionalAudit?: false;
}
export interface HotelGetByIdRequestModel {
  id: number;
}

export interface HotelChangeStatusRequestModel {
  id: number;
  status: StatusEnums;
}
