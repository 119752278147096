import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Header from "../../components/Header";
import LeftPanel from "../../components/LeftPanel";
import { EditSeaTourismCertifica } from "../../components/SeaTourism";

export default class EditSeaTourismRoute extends React.Component<
  RouteComponentProps<any>
> {
  render() {
    return (
      <React.Fragment>
        <Header history={this.props.history} />
        <div className="row">
          <LeftPanel history={this.props.history} />
          <EditSeaTourismCertifica
            history={this.props.history}
            SeaTourismId={this.props.match.params.SeaTourismId}
            BackId={this.props.match.params.BackId}
          />
        </div>
      </React.Fragment>
    );
  }
}
