import { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  TextField,
  // MessageBar,
  MessageBarType,
  PrimaryButton,
  Dropdown,
  Label,
  Modal,
  Icon,
  DefaultButton,
} from "office-ui-fabric-react";
import {
  setFormData,
  setFormErrors,
  initialFormData,
} from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import {
  getById,
  update,
  changeStatus,
  getAllSertificationMA,
} from "src/store/thunkHotel";
import { castThunkAction } from "src/helpers/casting";

import {
  HotelGetByIdRequestModel,
  HotelUpdateRequestModel,
  HotelChangeStatusRequestModel,
  GetHotelCertificateListByHotelModel,
} from "src/services/Hotel/RequestModel";

import { StatusEnums } from "src/services/BaseEnum";
import { apiConfig } from "src/config/apiConfig";
import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import { getAllLFA } from "src/store/thunkLayoverFacilityType";
import { getAllLSU } from "src/store/thunkLayoverSubCategory";
import {
  getAllTy,
  showErrorDialog,
  showChangesFieldDialog,
  showAtDialog,
} from "src/store/thunkCertificateType";

import { getAllHA } from "src/store/thunkHospital";
import { GetHospitalList } from "src/services/Hospital/RequestModel";

import { getAllCompany } from "src/store/thunksCompany";
import _ from "lodash";

import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";
import { SubCategoryGetAllRequestModel } from "src/services/SubCategory/RequestModel";
import { CertificateTypeGetAllRequestModel } from "src/services/CertificateType/RequestModel";

import cookie from "react-cookies";

import { FacilityTypeGetAllRequestModel } from "src/services/FacilityType/RequestModel";
import { FileUpload } from "../FileUpload";

import HospitalModal from "../HospitalModal";
// @ts-ignore

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toDate } from "src/helpers/converts";
import { getChangesFieldGlobal, isNumberValue } from "src/helpers/functions";
let token = "";

interface OwnProps {
  history: History;
  HotelId: number;
}
function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        getAllSertificationMA,
        setFormErrors,
        initialFormData,
        getById,
        getAllM,
        getAllC,
        getAllLSU,
        getAllTy,

        update,
        getAllCompany,
        getAllLFA,
        showErrorDialog,
        showAtDialog,
        showChangesFieldDialog,
        changeStatus,
        getAllHA,
      },
      dispatch
    ),
  };
}

type CreateHotelProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  messageType: MessageBarType;
  infoMessageGetDocumentNo: string;
  isFileUploading: boolean;
  fileUploadContainer: any;
  changesFieldHtml: any;
  modalshow: boolean;
  modalanswer: boolean;
  modelattachmentId: number;
  modalType: number;
  isHaveHealthKit: boolean;
  onlyCountry: string[];
}
let oldData: any = {};
let usertype: any = 0;
let hasCertificateStatusTypeId = 0;
let hasCorrectionTypeId = 0;

class ViewHotel extends Component<CreateHotelProps, OwnState> {
  state = {
    messageType: MessageBarType.warning,
    infoMessageGetDocumentNo: "",
    isFileUploading: false,
    fileUploadContainer: "",
    changesFieldHtml: "",
    modalshow: false,
    modalanswer: false,
    modelattachmentId: 0,
    modalType: 0,
    isHaveHealthKit: this.props.formData.isHaveHealthKit,
    onlyCountry: ["tr"],
  };

  UNSAFE_componentWillMount() {
    token = cookie.load("userId");
    this.props.initialFormData({
      status: StatusEnums.Published,
    });
    usertype = cookie.load("systemUserInfo");

    if (this.props.HotelId) {
      castThunkAction(
        this.props.getById({
          id: this.props.HotelId,
        } as HotelGetByIdRequestModel)
      ).then((response: any) => {
        this.props.setFormData("hotelEmail2", "");
        this.props.setFormData("hotelPhone2", "");
        this.props.setFormData("hotelManagerName2", "");
        this.props.setFormData("companyName2", "");

        const request10: GetHotelCertificateListByHotelModel = {
          id: this.props.HotelId,
        };
        this.props.getAllSertificationMA(request10);

        hasCertificateStatusTypeId = this.props.formData
          .certificateStatusTypeId;
        hasCorrectionTypeId = this.props.formData.correctionType;

        this.renderFileUpload();
        this.props.setFormData(
          "certificateNo",
          this.props.formData?.certificateNo?.slice(
            this.props.formData.certificateNo.indexOf("-") + 1
          )
        );
        oldData = this.props.formData;

        this.props.formData.webSite === ""
          ? this.props.setFormData("webSite", null)
          : this.props.setFormData("webSite", this.props.formData.webSite);

        const request2: CityGetAllRequestModel = {
          selectItemList: ["CityId", "CityName"],
          orderField: "CityName",
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllM(request2);

        const request7: GetHospitalList = {
          orderField: "HospitalName",
        };
        this.props.getAllHA(request7);

        const request3: SubCategoryGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
            this.props.formData.certificateTypeId === 5
              ? {
                propertyName: "isMinistry",
                value:
                  this.props.formData.certificateTypeId === 5 ? true : false,
                isOr: false,
                conditionOperator: ConditionOperatorEnum.Eq,
              }
              : {
                propertyName: "Status",
                value: StatusEnums.Published,
                isOr: false,
                conditionOperator: ConditionOperatorEnum.Eq,
              },
          ],
          selectItemList: ["LayoverSubCategoryId", "LayoverSubCategoryName"],
        };

        castThunkAction(this.props.getAllLSU(request3)).then(
          (response: any) => {
            if (this.props.formData.layoverSubCategoryId === 0) {
              this.props.setFormData("layoverSubCategoryId", 1);
              this.SubCategoryChange(1, 1);
            } else {
              this.SubCategoryChange(
                this.props.formData.layoverSubCategoryId,
                this.props.formData.layoverFacilityTypeId
              );
            }
          }
        );
        const request4: CertificateTypeGetAllRequestModel = {
          selectItemList: ["CertificateTypeId", "Title"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllTy(request4);

        this.CityChange(
          this.props.formData?.cityId,
          this.props.formData?.districtId
        );
        this.props.formData.appealDate === 0 &&
          this.props.setFormData("appealDate", null);
        this.props.formData.confirmationDate === 0 &&
          this.props.setFormData("confirmationDate", null);
      });
    }
  }
  componentDidUpdate(nextprops: any) {
    if (this.props.formData.certificateTypeId !== 0) {
      this.setLabelDescription(
        "companyName",
        "<br /> <span  id='companyName-span'  class='descSpan'>Tesis sahibi olan firmanın ticari ünvanı</span>"
      );

      if (this.props.formData.certificateTypeId === 4) {
        this.setLabelDescription(
          "documentNoDesc",
          "<br /> <span  id='documentNoDesc-span'  class='descSpan'>TC Kültür ve Turizm Bakanlığı tarafından tesise verilen çalışma belge numarası</span>"
        );
      }
    }
    if (
      this.props.formData.certificateStatusTypeId === 2 &&
      this.props.formData.prefix
    ) {
      this.setPrefix();
    }
  }
  setPrefix(): any {
    const el = document.createElement("span");
    el.className = "CertificateNo-span prefixSpan";
    el.id = "CertificateNo-span";
    el.innerHTML =
      usertype === "2"
        ? cookie.load("prefix") + "-"
        : this.props.formData.prefix + "-";
    const div = document.getElementById("CertificateNo");

    if (!document.getElementById("CertificateNo-span")) {
      this.insertBefore(div, el);
    } else {
      document.getElementById("CertificateNo-span")?.remove();
      this.insertBefore(div, el);
    }
    return false;
  }
  insertAfter(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  insertBefore(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode);
  }

  componentDidMount() {
    this.setDdlDescription(
      "certificateTypeIdDesc-label",
      "<br/> <span id='certificateTypeIdDesc-label-span' class='descSpan'>Tesisin çalışma belgesi aldığı kurumu seçiniz <b>TC Kültür ve Turizm Bakanlığı </b> harici tüm kurumlar için <b>Diğer</b> seçeneğini işaretleyiniz.</b></span>"
    );
  }

  setDdlDescription(id: string, message: string) {
    const el = document.createElement("span");
    el.innerHTML = message;
    const div = document.getElementById(id);
    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div, el);
    }
  }

  setLabelDescription(id: string, message: string) {
    const el2 = document.createElement("span");
    el2.innerHTML = message;
    const div2 = document.querySelectorAll('[for="' + id + '"]');

    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div2[0], el2);
    }
  }
  // renderTextByMessageType() {
  // 	switch (this.state.messageType) {
  // 		case MessageBarType.success:
  // 			return "Kaydetme işlemi başarıyla gerçekleşmiştir."
  // 		case MessageBarType.error:
  // 			return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin."
  // 		default:
  // 			return "Lütfen aşağıdaki bilgileri doldurun ve formu oluşturun!"
  // 	}
  // }

  // renderMessageBar() {
  // 	return (
  // 		<MessageBar
  // 			messageBarType={this.state.messageType}
  // 			isMultiline={false}
  // 		>
  // 			{this.renderTextByMessageType()}
  // 		</MessageBar>
  // 	)
  // }

  onSave(isDraft?: boolean) {
    const request: HotelUpdateRequestModel = {
      data: {
        ...this.props.formData,
        status: isDraft ? StatusEnums.Draft : StatusEnums.Published,
      },
    };
    if (
      this.props.formData.attachmentList &&
      this.props.formData.attachmentList.length > 0
    ) {
      request.data["attachmentIdList"] = (
        this.props.formData.attachmentList || []
      ).map((item: any) => item.attachmentId);
    }
    if (
      this.props.formData.attachmentCertificateList &&
      this.props.formData.attachmentCertificateList.length > 0
    ) {
      request.data["attachmentCertificateIdList"] = (
        this.props.formData.attachmentCertificateList || []
      ).map((item: any) => item.attachmentId);
    }
    // hasCertificateStatusTypeId = this.props.formData.certificateStatusTypeId
    // hasIsPublishWeb = this.props.formData.isPublishWeb
    if (this.props.formData.confirmationDate === null) {
      this.props.formData.confirmationDate = 0;
    }
    hasCertificateStatusTypeId = this.props.formData.certificateStatusTypeId;
    hasCorrectionTypeId = this.props.formData.correctionType;

    if (this.props.formData.hotelEmail2 !== "" && usertype === "2") {
      request.data.hotelEmail = this.props.formData.hotelEmail2;
    } else {
      request.data.hotelEmail = this.props.formData.hotelEmail;
    }

    if (this.props.formData.hotelPhone2 !== "" && usertype === "2") {
      request.data.hotelPhone = this.props.formData.hotelPhone2;
    } else {
      request.data.hotelPhone = this.props.formData.hotelPhone;
    }

    if (this.props.formData.hotelManagerName2 !== "" && usertype === "2") {
      request.data.hotelManagerName = this.props.formData.hotelManagerName2;
    } else {
      request.data.hotelManagerName = this.props.formData.hotelManagerName;
    }

    getChangesFieldGlobal(usertype, oldData, this.props.formData).then((x: any) => {
      this.props.setFormData("changesField", JSON.stringify(x));
      request.data["changesField"] = JSON.stringify(x);
      castThunkAction(this.props.update(request)).then((response: any) => {
        this.props.setFormData(
          "initialAttachmentList",
          this.props.formData.attachmentList
        );
        this.props.setFormData(
          "initialAttachmentCertificateList",
          this.props.formData.attachmentCertificateList
        );
        this.setState(
          {
            messageType: response
              ? MessageBarType.success
              : MessageBarType.error,
          },
          () => {
            this.props.setFormData(
              "status",
              isDraft ? StatusEnums.Draft : StatusEnums.Published
            );
          }
        );
      });
    });
  }

  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  removeElement(id: any) {
    var elem = document.getElementById(id);
    return elem?.parentNode?.removeChild(elem);
  }
  filterFileds(val: any) {
    if (
      val !== "" &&
      val !== 0 &&
      val !== "0" &&
      val !== null &&
      val !== "null" &&
      val !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  }
  onSubmit(isDraft?: boolean) {
    let desc = "";
    const webSite = /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

    if (this.props.formData.webSite) {
      if (!webSite.test(this.props.formData.webSite)) {
        desc += "Geçersiz Web Sitesi,  <br/>";
      }
    }

    const mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!mail.test(this.props.formData.hotelEmail)) {
      desc += "Geçersiz E-posta Adresi,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.certificateTypeId)) {
      desc += "Tesis Belge Tipi, <br/>";
    }
    if (!this.filterFileds(this.props.formData.companyName)) {
      desc += "Belge Sahibi / Ticari Ünvan,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.brandName)) {
      desc += "Tesis Adı,  <br/>";
    }

    if (!this.filterFileds(this.props.formData.cityId)) {
      desc += "İl,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.districtId)) {
      desc += "İlçe,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.hotelEmail)) {
      desc += "Tesis E-posta Adresi,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.hotelPhone)) {
      desc += "Tesis Telefon Numarası,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.hotelManagerName)) {
      desc += "Tesis Genel Müdürünün Adı ve Soyadı,  <br/>";
    }

    if (this.props.formData.certificateTypeId === 4) {
      if (!this.filterFileds(this.props.formData.roomCount)) {
        desc += "Tesis Oda Sayısı,  <br/>";
      }
      if (!this.filterFileds(this.props.formData.bedCount)) {
        desc += "Tesis Yatak Sayısı,  <br/>";
      }
    }
    // if (!this.props.formData.attachmentList || this.props.formData.attachmentList.length === 0) {
    // 	desc += "İşletme belgesi yükleyiniz,  <br/>"
    // }

    const request = {
      title: "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
      desc: desc,
    };
    if (desc !== "") {
      castThunkAction(
        this.props.showErrorDialog(request)
      ).then((response: any) => { });
    }

    if (desc === "") {
      const { formData, setFormErrors } = this.props;
      const validationSchema = _yup.object({
        companyName: _yup.string().required("Bu alan zorunludur!"),
        brandName: _yup.string().required("Bu alan zorunludur!"),
        cityId: _yup.string().required("Bu alan zorunludur!"),
        districtId: _yup.string().required("Bu alan zorunludur!"),

        webSite: _yup
          .string()
          .matches(
            // eslint-disable-next-line
            /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
            "Geçersiz Web Sitesi"
          )
          .notRequired()
          .nullable(),
        roomCount: _yup.string().required("Bu alan zorunludur!"),
        bedCount: _yup.string().required("Bu alan zorunludur!"),
        hotelEmail: _yup
          .string()
          .matches(
            // eslint-disable-next-line
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            "Geçersiz E-posta Adresi"
          )
          .notRequired()
          .nullable(),
        hotelPhone: _yup
          .string()
          .required("Bu alan zorunludur!")
          .notRequired()
          .nullable(),
        hotelManagerName: _yup.string().required("Bu alan zorunludur!"),
        hotelAddress: _yup.string().required("Bu alan zorunludur!"),
      });
      let formErrors = {};
      validationSchema
        .validate(formData, {
          abortEarly: false,
        })
        .then(() => {
          setFormErrors({});

          this.onSave(isDraft);
          // if (this.props.formData.attachmentList && this.props.formData.attachmentList.length > 0) {
          // 	this.onSave(isDraft)
          // } else {

          // 	castThunkAction(this.props.showErrorDialog({
          // 		title: "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
          // 		desc: "İşletme belgesi yükleyiniz"
          // 	})).then((response: any) => {
          // 	});

          // }
        })
        .catch((err) => {
          err.inner.forEach((e: any) => {
            const { path, message } = e;
            formErrors[path] = message;
          });
          setFormErrors(formErrors);
        });
    }
  }

  CityChange(item: any, districtId?: any) {
    const request2: CountyGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "cityId",
          value: item,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
      selectItemList: ["DistrictId", "DistrictName"],
      orderField: "DistrictName",
    };
    castThunkAction(this.props.getAllC(request2)).then((response: any) => {
      this.props.setFormData("districtId", districtId);
    });
  }

  SubCategoryChange(item: any, layoverFacilityTypeId?: any) {
    const request2: FacilityTypeGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "layoverSubCategoryId",
          value: item,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
      selectItemList: ["LayoverFacilityTypeId", "LayoverFacilityTypeName"],
      orderField: "LayoverFacilityTypeName",
    };

    castThunkAction(this.props.getAllLFA(request2)).then((response: any) => { });
  }
  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Seçiniz" });
    }

    return newArray;
  }

  getCategories(key: any, type: any) {
    const request2: SubCategoryGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        type === 5
          ? {
            propertyName: "isMinistry",
            value: key === 5 ? true : false,
            isOr: false,
            conditionOperator: ConditionOperatorEnum.Eq,
          }
          : {
            propertyName: "Status",
            value: StatusEnums.Published,
            isOr: false,
            conditionOperator: ConditionOperatorEnum.Eq,
          },
      ],
      selectItemList: ["LayoverSubCategoryId", "LayoverSubCategoryName"],
    };

    castThunkAction(this.props.getAllLSU(request2)).then((response: any) => {
      this.props.setFormData("layoverSubCategoryId", 1);
      this.SubCategoryChange(1, 7);
    });
  }

  renderFileUpload() {
    let fileuploadStatus = false;
    hasCorrectionTypeId === 1
      ? (fileuploadStatus = true)
      : usertype === "2" && hasCertificateStatusTypeId === 2
        ? (fileuploadStatus = false)
        : (fileuploadStatus = true);

    if (fileuploadStatus) {
      this.setState({
        ...this.state,
        fileUploadContainer: (
          <FileUpload
            onProcessStartFile={() => {
              this.setState({
                isFileUploading: true,
              });
            }}
            onProcessEndFile={(queue: boolean) => {
              if (!queue) {
                this.setState({
                  isFileUploading: false,
                });
              }
            }}
            onTransferedFile={(file: any, promise?: boolean) => {
              this.props.setFormData("attachmentList", [
                ...(this.props.formData?.attachmentList || []),
                file.attachmentIdList[0],
              ]);
            }}
            requestKey="files"
            token={token}
            endpointUrl={apiConfig.prefixes.attachment + "UploadFile"}
          />
        ),
      });
    }
  }

  renderForm() {
    const { formData, formErrors, setFormData } = this.props;
    return (
      <div className="form-groups row">
        <div className="form-group col-md-3" style={{ paddingBottom: 0 }}>
          <div className="form-wrapper">
            <div className="item row">
              <Dropdown
                disabled={true}
                id="certificateTypeIdDesc"
                selectedKey={this.props.formData.certificateTypeId}
                onChanged={(event: any) => (
                  setFormData("certificateTypeId", event.key),
                  event.key === 0
                    ? (setFormData("GroupListSU", {}),
                      setFormData("GroupListFA", {}),
                      setFormData("layoverSubCategoryId", 0),
                      setFormData("layoverFacilityTypeId", 0))
                    : (setFormData("layoverFacilityTypeId", 0),
                      this.getCategories(event.key, event.key))
                )}
                errorMessage={formErrors.certificateTypeId}
                label="Tesis Belge Tipi"
                options={(this.props.formData.GroupListTY?.dataList
                  ? this.returnOptions(
                    this.props.formData.GroupListTY?.dataList,
                    "CertificateTypeId",
                    "Title"
                  )
                  : ([] as any)
                ).map((item: any) => ({
                  key: item.CertificateTypeId,
                  text: item.Title,
                }))}
                className="custom-dropdown"
              />
            </div>

            {this.props.formData.certificateTypeId === 4 ? (
              <div className="item row">
                <TextField
                  disabled={true}
                  id="documentNoDesc"
                  placeholder="Belge No"
                  value={formData.documentNo || ""}
                  label="Belge No "
                  className="custom-textfield border"
                  errorMessage={formErrors.documentNo}
                  onChange={(event: any) =>
                    setFormData("documentNo", event.target.value)
                  }
                />
              </div>
            ) : (
              ""
            )}

            {usertype && usertype !== "2" ? (
              <div className="item row">
                <Dropdown
                  disabled={true}
                  options={[
                    { key: true, text: "Onaylanmış" },
                    { key: false, text: "Onaylanmamış" },
                  ].map((item: any) => ({
                    key: item.key,
                    text: item.text,
                  }))}
                  label="Tesis Onay Durumu"
                  placeholder="Seçiniz"
                  selectedKey={this.props.formData?.isApproved}
                  onChanged={(event: any) =>
                    setFormData("isApproved", event.key)
                  }
                  calloutProps={{
                    className: "custom-dropdown-callout",
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="form-group col-md-3">
          <div className="form-wrapper">
            <div className="item row">
              <TextField
                disabled={true}
                id="companyName"
                placeholder="Belge Sahibi / Ticari Ünvan"
                value={formData.companyName}
                label="Belge Sahibi / Ticari Ünvan"
                className="custom-textfield border"
                errorMessage={
                  usertype !== "2"
                    ? formErrors.companyName
                    : formErrors.companyName2
                }
                onChange={(event: any) =>
                  setFormData("companyName", event.target.value)
                }
              />
            </div>

            <div className="item row">
              <TextField
                disabled={true}
                placeholder="Tesis Adı"
                value={formData.brandName || ""}
                label="Tesis Adı"
                className="custom-textfield border"
                errorMessage={formErrors.brandName}
                onChange={(event: any) =>
                  setFormData("brandName", event.target.value)
                }
              />
            </div>

            <div className="item row">
              <Dropdown
                disabled={true}
                onChanged={(event: any) => (
                  this.CityChange(event.key), setFormData("cityId", event.key)
                )}
                label="İl"
                options={(
                  this.props.formData.GroupList?.dataList || ([] as any)
                ).map((item: any) => ({
                  key: item.CityId,
                  text: item.CityName,
                }))}
                className="custom-dropdown"
                selectedKey={this.props.formData.cityId}
                errorMessage={formErrors.cityId}
              />
            </div>

            <div className="item row">
              <Dropdown
                disabled={true}
                onChanged={(event: any) => setFormData("districtId", event.key)}
                label="İlçe"
                options={(formData.GroupListC?.dataList || ([] as any)).map(
                  (item: any) => ({
                    key: item.DistrictId,
                    text: item.DistrictName,
                  })
                )}
                className="custom-dropdown"
                selectedKey={this.props.formData.districtId}
                errorMessage={formErrors.districtId}
              />
            </div>

            <div className="item row">
              <TextField
                disabled={true}
                placeholder="Tesis Adresi"
                value={formData.hotelAddress || ""}
                label="Tesis Adresi"
                className="custom-textfield border"
                errorMessage={formErrors.hotelAddress}
                onChange={(event: any) =>
                  setFormData("hotelAddress", event.target.value)
                }
              />
            </div>

            <div className="item row">
              <div className="form-groups row">
                <div className="form-group col-md-6">
                  <div className="item row">
                    <TextField
                      disabled={true}
                      placeholder="X Koordinatı"
                      value={formData.latitude || ""}
                      label="X Koordinatı"
                      className="custom-textfield border"
                      errorMessage={formErrors.latitude}
                      onChange={(event: any) =>
                        setFormData("latitude", event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <div className="item row">
                    <TextField
                      disabled={true}
                      placeholder="Y Koordinatı"
                      value={formData.longitude || ""}
                      label="Y Koordinatı"
                      className="custom-textfield border"
                      errorMessage={formErrors.longitude}
                      onChange={(event: any) =>
                        setFormData("longitude", event.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="item row">

							<TextField
								placeholder="Otel Email Adresi"
								value={usertype !== "2" ? formData.hotelEmail : formData.hotelEmail2 || ""}
								label="Otel Email Adresi"
								className="custom-textfield border"
								errorMessage={usertype !== "2" ? formErrors.hotelEmail : formErrors.hotelEmail2}
								onChange={(event: any) => setFormData(usertype !== "2" ? "hotelEmail" : "hotelEmail2", event.target.value)}
							/>
						</div>
						<div className="item row">

							<TextField
								placeholder="Otel Telefon Numarası"
								value={usertype !== "2" ? formData.hotelPhone : formData.hotelPhone2 || ""}
								label="Otel Telefon Numarası"
								className="custom-textfield border"
								errorMessage={usertype !== "2" ? formErrors.hotelPhone : formErrors.hotelPhone2}
								onChange={(event: any) => setFormData(usertype !== "2" ? "hotelPhone" : "hotelPhone2", event.target.value)}
							/>
						</div> */}

            <div className="item row">
              <TextField
                disabled={true}
                placeholder=""
                value={""}
                label="Tesis E-posta Adresi"
                className="custom-textfield border"
                errorMessage={
                  usertype !== "2"
                    ? formErrors.hotelEmail
                    : formErrors.hotelEmail2
                }
                onChange={(event: any) =>
                  setFormData("hotelEmail", event.target.value)
                }
              />
            </div>

            <div className="item row">
              <Label>Tesis Telefon Numarası</Label>
              <PhoneInput
                disabled={true}
                onlyCountries={this.state.onlyCountry}
                country={"tr"}
                placeholder="Tesis Telefon Numarası"
                value={
                  (formData.hotelPhone && formData.hotelPhone.charAt(0) === "0"
                    ? formData.hotelPhone.substring(1, 20)
                    : formData.hotelPhone) || ""
                }
                onChange={(phone: any) => setFormData("hotelPhone", phone)}
                disableDropdown={true}
                disableCountryCode={true}
                masks={{ tr: "(...) *** ** **" }}
                inputClass={"customizephone"}
                defaultErrorMessage={formErrors.hotelPhone}
              />
            </div>
          </div>
        </div>

        <div className="form-group col-md-3">
          <div className="form-wrapper">
            {/* <div className="item row">

							<TextField
								placeholder="Otel Genel Müdürü Adı Soyadı"
								value={usertype !== "2" ? formData.hotelManagerName : formData.hotelManagerName2 || ""}
								label="Otel Genel Müdürü Adı Soyadı"
								className="custom-textfield border"
								errorMessage={usertype !== "2" ? formErrors.hotelManagerName : formErrors.hotelManagerName2}
								onChange={(event: any) => setFormData(usertype !== "2" ? "hotelManagerName" : "hotelManagerName2", event.target.value)}
							/>
						</div> */}
            <div className="item row">
              <TextField
                disabled={true}
                value={""}
                label="Tesis Genel Müdürünün Adı ve Soyadı"
                className="custom-textfield border"
                errorMessage={
                  usertype !== "2"
                    ? formErrors.hotelManagerName
                    : formErrors.hotelManagerName2
                }
                onChange={(event: any) =>
                  setFormData("hotelManagerName", event.target.value)
                }
              />
            </div>
            <div className="item row">
              <TextField
                disabled={true}
                placeholder="Tesisin Web Sitesi"
                value={formData.webSite || ""}
                label="Tesisin Web Sitesi"
                className="custom-textfield border"
                errorMessage={formErrors.webSite}
                onChange={(event: any) =>
                  setFormData(
                    "webSite",
                    event.target.value === "" ? null : event.target.value
                  )
                }
              />
            </div>

            <div className="item row">
              <Dropdown
                disabled={true}
                onChanged={(event: any) => (
                  this.SubCategoryChange(event.key),
                  setFormData("layoverSubCategoryId", event.key),
                  setFormData("layoverFacilityTypeId", 0)
                )}
                label="Tesis Kategorisi"
                options={(
                  this.props.formData.GroupListSU?.dataList || ([] as any)
                ).map((item: any) => ({
                  key: item.LayoverSubCategoryId,
                  text: item.LayoverSubCategoryName,
                }))}
                errorMessage={formErrors.layoverSubCategoryId}
                className="custom-dropdown"
                selectedKey={this.props.formData.layoverSubCategoryId}
              />
            </div>
            <div className="item row">
              <Dropdown
                disabled={true}
                onChanged={(event: any) =>
                  setFormData("layoverFacilityTypeId", event.key)
                }
                label="Tesis Sınıfı"
                options={(
                  this.props.formData.GroupListFA?.dataList || ([] as any)
                ).map((item: any) => ({
                  key: item.LayoverFacilityTypeId,
                  text: item.LayoverFacilityTypeName,
                }))}
                errorMessage={formErrors.layoverFacilityTypeId}
                className="custom-dropdown"
                selectedKey={this.props.formData.layoverFacilityTypeId}
              />
            </div>

            <div className="item row">
              <TextField
                disabled={true}
                placeholder="Tesis Oda Sayısı"
                value={formData.roomCount || ""}
                label="Tesis Oda Sayısı"
                className="custom-textfield border"
                errorMessage={formErrors.roomCount}
                onChange={(event: any) =>
                  setFormData(
                    "roomCount",
                    isNumberValue(
                      event.target.value === "" ? null : event.target.value
                    )
                  )
                }
              />
            </div>

            <div className="item row">
              <TextField
                disabled={true}
                placeholder="Tesis Yatak  Sayısı"
                value={formData.bedCount || ""}
                label="Tesis Yatak  Sayısı"
                className="custom-textfield border"
                errorMessage={formErrors.bedCount}
                onChange={(event: any) =>
                  setFormData(
                    "bedCount",
                    event.target.value === "" ? null : event.target.value
                  )
                }
              />
            </div>
            {this.props.formData.hospitalModalShow && (
              <div className="modal-overlay">
                <div className="modal">
                  <button
                    className="btn-close"
                    onClick={(x) =>
                      this.props.setFormData("hospitalModalShow", false)
                    }
                  >
                    Kapat
                  </button>
                  <HospitalModal history={this.props.history} />
                </div>
              </div>
            )}
          </div>
        </div>

        <aside className="col-md-12 row" style={{ padding: 0 }}>
          <div className="form-group col-md-4"></div>
        </aside>
      </div>
    );
  }
  getDate(date: any): any {
    if (date !== 0 && date !== undefined) {
      return toDate(date, "");
    }
  }
  showDialog(req: any): any {
    this.props.showErrorDialog(req);
  }

  onChangeStatus(status: StatusEnums) {
    const request: HotelChangeStatusRequestModel = {
      id: this.props.HotelId,
      status: status,
    };
    castThunkAction(this.props.changeStatus(request)).then((response: any) => {
      this.setState(
        {
          messageType: response ? MessageBarType.success : MessageBarType.error,
        },
        () => {
          this.props.setFormData("status", status);
        }
      );
    });
  }
  render() {
    return (
      <div className="page push-all">
        {this.state.modalshow && (
          <Modal className={"warning custom-message-box danger"} isOpen={true}>
            <div className="modal-header row">
              <div className="title">
                {this.state.modalType === 1
                  ? "Dosya Siliyorsunuz Emin misiniz?"
                  : "Tesis dahilinde covid testi kiti bulunduğunu onaylıyor musunuz?"}
              </div>
              <span
                onClick={() => this.setState({ modalshow: false })}
                className="close"
              >
                <Icon iconName="ChromeClose" />
              </span>
            </div>
            <div
              style={{ paddingBottom: 0 }}
              className="description modal-content"
            >
              <div className="modal-indicators">
                {this.state.modalType === 1 && (
                  <DefaultButton
                    style={{ marginTop: 15 }}
                    text="EVET"
                    onClick={() => {
                      let files = _.cloneDeep(
                        this.props.formData?.attachmentList
                      );
                      let findIndex = (files || []).findIndex(
                        (x: any) =>
                          x.attachmentId === this.state.modelattachmentId
                      );
                      if (findIndex !== -1) {
                        files.splice(findIndex, 1);
                      }
                      this.props.setFormData("attachmentList", files);
                      this.setState({ modalshow: false });
                    }}
                  />
                )}
                {this.state.modalType === 2 && (
                  <DefaultButton
                    style={{ marginTop: 15 }}
                    text="EVET"
                    onClick={() => {
                      {
                        this.props.setFormData("isHaveHealthKit", true);
                        this.setState({
                          modalshow: false,
                          isHaveHealthKit: true,
                        });
                      }
                    }}
                  />
                )}
                {this.state.modalType === 1 && (
                  <DefaultButton
                    style={{ marginTop: 15 }}
                    text="İPTAL"
                    onClick={() => this.setState({ modalshow: false })}
                  />
                )}
                {this.state.modalType === 2 && (
                  <DefaultButton
                    style={{ marginTop: 15 }}
                    text="HAYIR"
                    onClick={() => {
                      this.setState({
                        modalshow: false,
                        isHaveHealthKit: false,
                      });
                      setFormData("isHaveHealthKit", false);
                    }}
                  />
                )}
              </div>
            </div>
          </Modal>
        )}

        <PageTitle
          title="İl Tipi Düzenle"
          titleRenderer={() => (
            <span className="edit-title">
              <strong>Tesis Bilgilerini Görüntüleme</strong>
              <strong>{this.props.formData?.title || ""}</strong>
            </span>
          )}
          showBack
          backUrl="/Hotel"
          history={this.props.history}
          subTitle=""
        />

        {/* {this.renderMessageBar()} */}

        {this.renderForm()}
      </div>
    );
  }

  showChangesField(): any {
    let desc = [];
    const changesField2: any[] = this.props.formData.changesField
      ? JSON.parse(this.props.formData.changesField)
      : [];
    for (let index = 0; index < changesField2.length; index++) {
      desc.push(<span>{changesField2[index].tr} </span>);
    }
    if (desc.length === 0) {
      desc.push(<span>Değişiklik Yok</span>);
    }
    return desc;
  }

  goEdit(endId: any): any {
    window.open("/HotelCertification/editendId/" + endId);
  }

  getSaveButton(): any {
    return (
      <PrimaryButton
        onClick={() => this.onSubmit()}
        disabled={this.state.isFileUploading ? true : false}
        styles={{
          rootDisabled: {
            background: "#ececec",
            borderColor: "#ccc !important",
            opacity: 0.5,
          },
        }}
        iconProps={{ iconName: "CheckMark" }}
        text={
          this.props.formData.status === StatusEnums.Published
            ? "Kaydet"
            : "Yayımla"
        }
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewHotel);
