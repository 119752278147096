import axios, { AxiosRequestConfig, AxiosInstance } from "axios";
import cookie from "react-cookies";
import { convertToQueryString } from "src/helpers/functions";
export interface RequestConfigWithMetadata extends AxiosRequestConfig {
  metadata: {
    requestStartTimestamp: number;
  };
}

export class HttpClient {
  static _authenticatedClient?: HttpClient;
  static _client?: HttpClient;

  private axiosClient: AxiosInstance;

  post<T>(url: string, request: any): Promise<T> {
    axios.defaults.timeout = 1000;
    return this.axiosClient
      .post(url, request)
      .then((res) => {
        if (res?.data.ErrorCode === 19) {
          cookie.remove("userId");
          cookie.remove("systemUserInfo");
          cookie.remove("systemUserName");
          window.location.reload();
        }
        if (res?.data.errorCode === 20) {
          alert("Bu Sayfaya Erişiminiz Bulunmamaktadır.");
          window.location.href = "/";
        }

        if (res?.data.ErrorCode === 90) {
          alert("Sistem kısa süreliğine bakım çalışmasındadır.");
          cookie.remove("userId");
          cookie.remove("systemUserInfo");
          cookie.remove("systemUserName");
          window.location.href = "/";
        }

        if (res?.data.errorCode === 72) {
          alert(res?.data.errorMessage);
          window.location.reload();
        }

        return res?.data as T;
      })
      .catch((err) => {
        alert("Sunucuda  Bir Hata Oluştu.Lütfen Tekrar Deneyin");
        return err;
      });
  }
  downloadFile(url: string, name: string, request: any, isPdf = false): any {
    let token = request.token;
    delete request.token;
    return fetch(
      `${url}?${convertToQueryString(request)}&Token=${encodeURIComponent(
        token
      )}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${request.token}`,
        }),
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = isPdf ? `${name}.pdf` : `${name}.xlsx`;
        a.click();
      })
      .catch((error) => {
      });
  }

  reportFileDownload(
    url: string,
    request: any,
    name: string
  ): any {
    let token = request.token;
    delete request.token;
    return fetch(
      `${url}?${convertToQueryString(request)}&Token=${encodeURIComponent(
        token
      )}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${request.token}`,
        }),
      }
    )
      .then((res) => res.blob())
      .then((blob) => {

        let url = window.URL.createObjectURL(blob);
        //  window.open(url, "_blank");

        let a = document.createElement("a");
        a.href = url;
        a.download = `${name}.xlsx`;
        a.click();
      })
      .catch((error) => {
        alert('Dosya indirilirken bir hata oluştu.')
      });
  }

  postReq<T>(url: string, request: any): Promise<T> {
    axios.defaults.timeout = 1000;
    return this.axiosClient
      .post(url, request)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        alert("Sunucuda  Bir Hata Oluştu.Lütfen Tekrar Deneyin");
        return err;
      });
  }

  get<T>(url: string): Promise<T> {
    return this.axiosClient.get(url).then((res) => {
      return res?.data as T;
    });
  }

  /**
   * Returns the HttpClient that includes Authentication header automatically.
   */
  static getClient(): HttpClient {
    if (!this._authenticatedClient) {
      const client = new HttpClient();
      client.axiosClient = axios.create({
        withCredentials: true,
      });
      this._authenticatedClient = client;
    }

    return this._authenticatedClient;
  }

  static getDefaultClient(baseURL?: string): HttpClient {
    if (!this._client) {
      const client = new HttpClient();

      client.axiosClient = axios.create({
        baseURL,
      });

      this._client = client;
    }

    return this._client;
  }
}
