import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import CongressSubCategory from "../services/CongressSubCategory/CongressSubCategoryService";
import {
  CongressSubCategoryGetAllRequestModel,
  CongressSubCategoryChangeStatusRequestModel,
  CongressSubCategoryGetByIdRequestModel,
  CongressSubCategoryUpdateRequestModel,
  CongressSubCategorySaveRequestModel,
} from "../services/CongressSubCategory/RequestModel";
import { MessageType } from "./system/types";
import { errorMessageSaveOrUpdate } from "src/helpers/functions";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllLSU(
  request: CongressSubCategoryGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const CongressSubCategoryService = new CongressSubCategory();
    const result = await CongressSubCategoryService.getAll(request);
    dispatch(setFormData("GroupListSU", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: CongressSubCategoryGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressSubCategoryService = new CongressSubCategory();
    const result = await CongressSubCategoryService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: CongressSubCategorySaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressSubCategoryService = new CongressSubCategory();
    const result = await CongressSubCategoryService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Alt Kategori Yönetimi"
        )
      );
      return result;
    } else {
      errorMessageSaveOrUpdate(dispatch,result)

      return false;
    }
  };
}
export function update(
  request: CongressSubCategoryUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressSubCategoryService = new CongressSubCategory();
    const result = await CongressSubCategoryService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Alt Kategori Yönetimi"
        )
      );
      return true;
    } else {
      errorMessageSaveOrUpdate(dispatch,result)

      return false;
    }
  };
}

export function getById(
  request: CongressSubCategoryGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressSubCategoryService = new CongressSubCategory();
    const result = await CongressSubCategoryService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: CongressSubCategoryChangeStatusRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressSubCategoryService = new CongressSubCategory();
    const result = await CongressSubCategoryService.changeStatus(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Alt Kategori Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Alt Kategori Yönetimi"
        )
      );
      return false;
    }
  };
}
