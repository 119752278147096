import { HttpClient } from "src/library/HttpClient";
import {
  CityGetAllRequestModel,
  CitySaveRequestModel,
  CityUpdateRequestModel,
  CityGetByIdRequestModel,
  CityChangeStatusRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class City {
  

  async getAll(request: CityGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.city + "GetAll",
      { ...request, token }
    );
  }
  async GetAllOrdered(request: CityGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.city + "GetAllOrdered",
      { ...request, token }
    );
  }
  async save(request: CitySaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.city + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: CityUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.city + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(request: CityGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.city + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(request: CityChangeStatusRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.city + "ChangeStatus",
      { ...request, token }
    );
  }
}
