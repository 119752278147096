import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  TextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Dropdown,
  DatePicker,
  Label,
  Checkbox,
  ComboBox,
  Modal,
  Icon,
} from "office-ui-fabric-react";
import {
  setFormData,
  setFormErrors,
  initialFormData,
} from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";
import { save } from "src/store/thunkWinterTourismCertification";
import { castThunkAction } from "src/helpers/casting";
import { StatusEnums } from "src/services/BaseEnum";

import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import { getAllTy, showErrorDialog } from "src/store/thunkCertificateType";
import { getAllCR } from "src/store/thunkCertificateStatusType";
import { getAllCompany } from "src/store/thunksCompany";
import { getCompanyUserList } from "src/store/thunksSystemUser";

import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";
import { CertificateTypeGetAllRequestModel } from "src/services/CertificateType/RequestModel";
import { CertificateStatusTypeGetAllRequestModel } from "src/services/CertificateStatusType/RequestModel";
import cookie from "react-cookies";
import {
  DayPickerStrings,
  firstDayOfWeek,
  onFormatDate,
} from "src/config/otherConfig";
import { toTimeStamp, toDate } from "src/helpers/converts";
import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";
import _ from "lodash";

import { FileUpload } from "../FileUpload";
import { apiConfig, qrUrl } from "src/config/apiConfig";
import { WinterTourismCertificationSaveRequestModel } from "src/services/WinterTourismCertification/RequestModel";
import WinterTourismModal from "../WinterTourismModal";
import { formatSize, certificateAppealStatusDate, sertifikaTarihiBayram } from "src/helpers/functions";
import ValidityDate from "../ValidityDate";

let token = "";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        initialFormData,
        save,
        getAllM,
        getAllC,
        getAllTy,
        getAllCR,
        getCompanyUserList,
        getAllCompany,
        showErrorDialog,
      },
      dispatch
    ),
  };
}

type CreateWinterTourismCertificationProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  showFields: boolean;
  messageType: MessageBarType;
  isFileUploading: boolean;
  infoMessageGetDocumentNo: string;
  certificateStatusSifir: any;
  certificateStatusBir: any;
  certificateStatusIki: any;
  certificateStatusUc: any;
  isOpenStatus:string;
  modalStatus: boolean;
}
let usertype: any = 0;
class CreateWinterTourismCertification extends Component<
  CreateWinterTourismCertificationProps,
  OwnState
> {
  state = {
    messageType: MessageBarType.warning,
    showFields: true,
    isFileUploading: false,
    infoMessageGetDocumentNo: "",
    certificateStatusSifir: 0,
    certificateStatusBir: 0,
    certificateStatusIki: 0,
    certificateStatusUc: 0,
    isOpenStatus:"0",
    modalStatus: false,
  };

  UNSAFE_componentWillMount() {
    token = cookie.load("userId");
    this.props.initialFormData({
      status: StatusEnums.Published,
    });

    usertype = cookie.load("systemUserInfo");
    const request6: SystemUserGetAllModel = {
      systemUserType: 2,
    };
    usertype === "2"
      ? this.props.setFormData("prefix", cookie.load("prefix"))
      : this.props.setFormData("prefix", null);
    castThunkAction(this.props.getCompanyUserList(request6)).then(
      (response: any) => {
        if (usertype && usertype === "2") {
          this.props.setFormData("companyUserId", 0);
        }

        const request2: CityGetAllRequestModel = {
          selectItemList: ["CityId", "CityName"],
          isDescending: false,
          orderField: "CityName",
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllM(request2);

        const request4: CertificateTypeGetAllRequestModel = {
          selectItemList: ["CertificateTypeId", "Title"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllTy(request4);

        const request5: CertificateStatusTypeGetAllRequestModel = {
          selectItemList: ["CertificateStatusTypeId", "Title"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        castThunkAction(this.props.getAllCR(request5)).then((e: any) => {
          this.props.setFormData("certificateStatusTypeId", 1);
        });
      }
    );
  }

  componentDidUpdate(nextprops: any) {
    if (this.props.formData.certificateTypeId !== 0) {
      this.setDdlDescription(
        "certificateStatusTypeId-label",
        "<br /> <span  id='certificateStatusTypeId-label-span'  class='descSpan'>Denetim için sözleşme yapıldığında <b>Belgelendirme Sürecinde</b>, belge verildikten sonra ise <b>Belge Verildi</b> seçeneğinin işaretlenmesi gerekmektedir.</span>"
      );
      if (this.props.formData.certificateTypeId === 4) {
        this.setLabelDescription(
          "documentNoDesc",
          "<br /> <span  id='documentNoDesc-span'  class='descSpan'>TC Kültür ve Turizm Bakanlığı tarafından tesise verilen çalışma belge numarası</span>"
        );
      }
    }
    if (
      this.props.formData.certificateStatusTypeId === 2 &&
      this.props.formData.prefix
    ) {
      this.setPrefix();
    }
  }

  insertAfter(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  insertBefore(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode);
  }

  setDdlDescription(id: string, message: string) {
    const el = document.createElement("span");
    el.innerHTML = message;
    const div = document.getElementById(id);
    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div, el);
    }
  }

  setLabelDescription(id: string, message: string) {
    const el2 = document.createElement("span");
    el2.innerHTML = message;
    const div2 = document.querySelectorAll('[for="' + id + '"]');

    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div2[0], el2);
    }
  }

  renderTextByMessageType() {
    switch (this.state.messageType) {
      case MessageBarType.success:
        return "Kaydetme işlemi başarıyla gerçekleşmiştir.";
      case MessageBarType.error:
        return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
      default:
        return "Lütfen aşağıdaki bilgileri doldurun ve formu oluşturun";
    }
  }

  renderMessageBar() {
    return (
      <MessageBar messageBarType={this.state.messageType} isMultiline={false}>
        {this.renderTextByMessageType()}
      </MessageBar>
    );
  }

  onCreate(isDraft?: boolean) {
    let request: WinterTourismCertificationSaveRequestModel = {
      data: isDraft
        ? {
            endId: this.props.formData.endId,
            winterTourismId: this.props.formData.winterTourismId,
            certificateStatusTypeId:
              this.props.formData.certificateStatusTypeId,
            isPublishWeb: this.props.formData.isPublishWeb,
            isPublishQr: this.props.formData.isPublishQr,
            appealDate: this.props.formData.appealDate,
            confirmationDate: this.props.formData.confirmationDate,
            certificateNo: this.props.formData.CertificateNo,
            companyUserId: this.props.formData.companyUserId,
            attachmentCertificateIdList:
              this.props.formData.attachmentCertificateIdList,
            correctionType: this.props.formData.correctionType,
            correctionDescription: this.props.formData.correctionDescription,
            correctionPerson: this.props.formData.correctionPerson,
            changesField: this.props.formData.changesField,
            status: StatusEnums.Draft,
            certificateStatus: this.props.formData.certificateStatus,
            extendedClosedDate: this.props.formData.extendedClosedDate,
            isOpen: this.props.formData.isOpen === "1" ? true : this.props.formData.isOpen === '2' ? false : null,
            openingDate: this.props.formData.openingDate,
            closingDate: this.props.formData.closingDate,

          }
        : {
            endId: this.props.formData.endId,
            winterTourismId: this.props.formData.winterTourismId,
            certificateStatusTypeId:
              this.props.formData.certificateStatusTypeId,
            isPublishWeb: this.props.formData.isPublishWeb,
            isPublishQr: this.props.formData.isPublishQr,
            appealDate: this.props.formData.appealDate,
            confirmationDate: this.props.formData.confirmationDate,
            certificateNo: this.props.formData.CertificateNo,
            companyUserId: this.props.formData.companyUserId,
            attachmentCertificateIdList:
              this.props.formData.attachmentCertificateIdList,
            correctionType: this.props.formData.correctionType,
            correctionDescription: this.props.formData.correctionDescription,
            correctionPerson: this.props.formData.correctionPerson,
            changesField: this.props.formData.changesField,
            status: this.props.formData.status,
            certificateStatus: this.props.formData.certificateStatus,
            extendedClosedDate: this.props.formData.extendedClosedDate,
            isOpen: this.props.formData.isOpen === "1" ? true : this.props.formData.isOpen === '2' ? false : null,
            openingDate: this.props.formData.openingDate,
            closingDate: this.props.formData.closingDate,
          },
    };
    if (
      this.props.formData.attachmentList &&
      this.props.formData.attachmentList.length > 0
    ) {
      request.data["attachmentIdList"] = (
        this.props.formData.attachmentList || []
      ).map((item: any) => item.attachmentId);
    }
    if (
      this.props.formData.attachmentCertificateList &&
      this.props.formData.attachmentCertificateList.length > 0
    ) {
      request.data["attachmentCertificateIdList"] = (
        this.props.formData.attachmentCertificateList || []
      ).map((item: any) => item.attachmentId);
    }

    castThunkAction(this.props.save(request)).then((response: any) => {
      this.props.setFormData(
        "initialAttachmentList",
        this.props.formData.attachmentList
      );
      this.props.setFormData(
        "initialAttachmentCertificateList",
        this.props.formData.attachmentCertificateList
      );
      this.setState(
        {
          messageType: response ? MessageBarType.success : MessageBarType.error,
        },
        () => {
          if (response) {
            this.props.history.push(
              "/winterTourismCertification/edit/" + response.id
            );
          } else {
            setTimeout(() => {
              //window.location.reload(false)
            }, 1000);
          }
        }
      );
    });
  }

  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  removeElement(id: any) {
    var elem = document.getElementById(id);
    return elem?.parentNode?.removeChild(elem);
  }
  filterFileds(val: any) {
    if (
      val !== "" &&
      val !== 0 &&
      val !== "0" &&
      val !== null &&
      val !== "null" &&
      val !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  }
  onSubmit(isDraft?: boolean) {
    let desc = "";
    if (
      this.state.certificateStatusBir === 1 &&
      this.state.certificateStatusUc === 1
    ) {
      this.props.setFormData("certificateStatus", 4);
    } else if (
      this.state.certificateStatusBir === 1 &&
      this.state.certificateStatusUc === 0
    ) {
      this.props.setFormData("certificateStatus", 1);
    } else if (
      this.state.certificateStatusBir === 0 &&
      this.state.certificateStatusUc === 1
    ) {
      this.props.setFormData("certificateStatus", 3);
    } else if (
      this.state.certificateStatusBir === 0 &&
      this.state.certificateStatusUc === 0
    ) {
      this.props.setFormData("certificateStatus", 0);
    }

    if (!this.filterFileds(this.props.formData.winterTourismId)) {
      desc += "Kış Turizmi Mekanik Hatlar Tesisi,  <br/>";
    }

    if (!this.filterFileds(this.props.formData.appealDate)) {
      desc += "Sertifika Başvuru Tarihi,  <br/>";
    }
    if (this.state.isOpenStatus === "0") {
      desc += "Tesis Faaliyet Durumu,  <br/>";
    }
    
    if(this.state.certificateStatusBir === 1 && this.props.formData.certificateStatusTypeId!== 2){
      if (this.props.formData.extendedClosedDate) {
          } else {
            desc += "Belge Verildi durumu dışındaki sertifikalar Sertifika Uzatmalı Kapalı olarak seçilemez,  <br/>";
          }
    }

    if (usertype && usertype !== "2") {
      if (!this.filterFileds(this.props.formData.companyUserId)) {
        desc += "Denetleyici Firma <br/> ";
      }
    }
    if (this.props.formData.certificateStatusTypeId === 2) {
      if (!this.filterFileds(this.props.formData.confirmationDate)) {
        desc += "Sertifika Düzenleme Tarihi,  <br/>";
      }
      if (!this.filterFileds(this.props.formData.CertificateNo)) {
        desc += "Sertifika No,  <br/>";
      }

      if (!this.filterFileds(this.props.formData.extendedClosedDate)) {
        desc += "Sertifika Geçerlilik Tarihi boş bırakılamaz.  <br/>";
      }
    }

    const request = {
      title: "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
      desc: desc,
    };
    if (desc !== "") {
      castThunkAction(this.props.showErrorDialog(request)).then(
        (response: any) => {}
      );
    }

    if (
      this.props.formData.appealDate &&
      this.props.formData.appealDate !== "" &&
      this.props.formData.appealDate !== null &&
      this.props.formData.appealDate !== 0 &&
      this.props.formData.appealDate !== "null" &&
      this.props.formData.appealDate !== "0"
    ) {
      this.removeElement("appealDate-span");
    } else {
      const el = document.createElement("span");
      el.className = "errorspan";
      el.innerHTML =
        "<br/> <span id='appealDate-span' class='descSpan errorspan'>Bu alan zorunludur!</span>";
      const div = document.getElementById("appealDate");
      if (!document.getElementById("appealDate-span")) {
        this.insertAfter(div, el);
      }
    }

    if (this.props.formData.certificateStatusTypeId === 2) {
      if (
        this.props.formData.confirmationDate &&
        this.props.formData.confirmationDate !== "" &&
        this.props.formData.confirmationDate !== null &&
        this.props.formData.confirmationDate !== 0 &&
        this.props.formData.confirmationDate !== "null" &&
        this.props.formData.confirmationDate !== "0"
      ) {
        this.removeElement("confirmationDate-span");
      } else {
        const el = document.createElement("span");
        el.className = "errorspan";
        el.innerHTML =
          "<br/> <span id='confirmationDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
        const div = document.getElementById("confirmationDate");
        if (!document.getElementById("confirmationDate-span")) {
          this.insertAfter(div, el);
        }
      }
    } else {
      this.removeElement("confirmationDate-span");
    }

    const { formData, setFormErrors } = this.props;
    const validationSchema = _yup.object({
      webSite: _yup
        .string()
        .matches(
          // eslint-disable-next-line
          /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "Geçersiz Web Sitesi"
        )
        .notRequired()
        .nullable(),

      certificateStatusTypeId: _yup.string().required("Bu alan zorunludur!"),
      winterTourismId: _yup.string().required("Bu alan zorunludur!"),
      companyUserId: _yup.string().required("Bu alan zorunludur!"),
      CertificateNo:
        this.props.formData.certificateStatusTypeId === 2
          ? _yup.string().required("Bu alan zorunludur!")
          : _yup.string().nullable(),
      extendedClosedDate:
        this.state.certificateStatusBir === 1
          ? _yup.string().required("Bu alan zorunludur!")
          : _yup.string().nullable(),
    });

    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});
        if (
          this.props.formData.appealDate &&
          this.props.formData.appealDate !== "" &&
          this.props.formData.appealDate !== null &&
          this.props.formData.appealDate !== 0 &&
          this.props.formData.appealDate !== "null" &&
          this.props.formData.appealDate !== "0"
        ) {
          this.removeElement("appealDate-span");

          if (this.props.formData.certificateStatusTypeId === 2) {
            if (
              this.props.formData.confirmationDate &&
              this.props.formData.confirmationDate !== "" &&
              this.props.formData.confirmationDate !== null &&
              this.props.formData.confirmationDate !== 0 &&
              this.props.formData.confirmationDate !== "null" &&
              this.props.formData.confirmationDate !== "0"
            ) {
              this.removeElement("appealDate-span");
              this.removeElement("confirmationDate-span");

              desc === "" &&   this.onCreate(isDraft);
            } else {
              const el = document.createElement("span");
              el.className = "errorspan";
              el.innerHTML =
                "<br/> <span id='confirmationDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
              const div = document.getElementById("confirmationDate");
              if (!document.getElementById("confirmationDate-span")) {
                this.insertAfter(div, el);
              }
            }
          } else {
            this.removeElement("appealDate-span");
            this.removeElement("confirmationDate-span");

            desc === "" &&  this.onCreate(isDraft);
          }
        } else {
          const el = document.createElement("span");
          el.className = "errorspan";
          el.innerHTML =
            "<br/> <span id='appealDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
          const div = document.getElementById("appealDate");
          if (!document.getElementById("appealDate-span")) {
            this.insertAfter(div, el);
          }
        }
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  CityChange(item: any, districtId?: any) {
    const request2: CountyGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "cityId",
          value: item,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
      selectItemList: ["DistrictId", "DistrictName"],
      orderField: "DistrictName",
    };
    castThunkAction(this.props.getAllC(request2)).then((response: any) => {
      this.props.setFormData("districtId", districtId);
    });
  }

  getDate(date: any): any {
    if (date !== 0 && date !== undefined) {
      return toDate(date, "");
    }
  }
  renderForm() {
    const { formData, formErrors, setFormData } = this.props;
    return (
      <div className="form-groups row">
        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="item row">
            {(usertype === "1" && usertype === "2" && usertype === "3") || this.props.formData.winterTourismName ? 
              ""
              :<PrimaryButton
                onClick={(x) =>
                  this.props.setFormData("winterTourismModalShow", true)
                }
                iconProps={{ iconName: "Add" }}
                className=""
                text="Kış Turizmi Mekanik Hatlar Tesisi Seç"
                style={{ marginTop: "20px", marginBottom: "5px" }}
                styles={{
                  root: {
                    background: "#aea64c",
                    borderColor: "#aea64c",
                  },
                  rootHovered: {
                    background: "#aea64c",
                    borderColor: "#aea64c",
                  },
                  rootDisabled: {
                    background: "#aea64c",
                  },
                }}
              />}
              {this.props.formData.winterTourismId ? (
                <Label className="certificateNew full-width-label">
                <b> Seçili Kış Turizm Tesisi :{" "}
                  {this.props.formData.brandName}</b> 
                </Label>
              ) : (
                <Label className="certificateNew alert full-width-label">
                 <b> Seçilmiş Kış Turizm Tesisi Bulunmuyor </b>
                </Label>
              )}
            </div>
            {(usertype === "1" && usertype === "2" && usertype === "3") || this.props.formData.winterTourismName && (
              <button
                style={{
                  background: "#0078d4",
                  fontSize: 12,
                  height: 30,
                  color: "#fff",
                  cursor: "pointer",
                  padding: " 0 14px",
                  borderColor:"#0078d4",
                }}
                onClick={() => {
                  this.setState({
                    modalStatus: true,
                  });
                }}
                className="view"
              >
                <i style={{ marginRight: 4 }} className="icon-explore"></i>{" "}
                Görüntüle
              </button>
            )}
             {(usertype === "1" && usertype === "2" && usertype === "3") || this.props.formData.winterTourismName && (
            <PrimaryButton
              text="Seçimi Temizle"
              className="custom-button"
            onClick={() => {
              this.props.setFormData("winterTourismName", "");
              this.props.setFormData("winterTourismId", "");

            }}
              style={{ marginLeft: "15px" }}
              styles={{
                root: {
                  fontSize: "12px",
                  marginTop: "27px",
                  height: "30px",
                  background: "#FF4343",
                  borderColor: "#FF4350",
                  margin: "0",
                },
              }}
            />
            )}

            {usertype && usertype !== "2" ? (
              <>
                <div className="item row">
                  <Dropdown
                    onChanged={(event: any) =>
                      setFormData("isPublishWeb", event.key)
                    }
                    label="Web Sitesinde Yayımlansın mı?"
                    options={[
                      { key: false, text: "Hayır" },
                      { key: true, text: "Evet" },
                    ].map((item: any) => ({
                      key: item.key,
                      text: item.text,
                    }))}
                    className="custom-dropdown"
                  />
                </div>
                <div className="item row">
                  <Dropdown
                    onChanged={(event: any) =>
                      setFormData("isPublishQr", event.key)
                    }
                    label="Sertifika QR Kodu Yayımlansın mı?"
                    options={[
                      { key: false, text: "Hayır" },
                      { key: true, text: "Evet" },
                    ].map((item: any) => ({
                      key: item.key,
                      text: item.text,
                    }))}
                    className="custom-dropdown"
                  />
                </div>
                <div className="item row">
                  <ComboBox
                    onChange={(event: any, option: any) => {
                      setFormData("companyUserId", option.key);
                      setFormData("prefix", option.prefix);
                    }}
                    allowFreeform={true}
                    autoComplete="on"
                    label="Denetleyici Firma"
                    options={(
                      this.props.formData.CompanyUserList?.dataList ||
                      ([] as any)
                    ).map((item: any) => ({
                      key: item.systemUserId,
                      text: item.title,
                      prefix: item.prefix,
                    }))}
                    selectedKey={this.props.formData.companyUserId || 0}
                    className="custom-dropdown"
                    errorMessage={formErrors.companyUserId}
                  />
                </div>
              </>
            ) : (
              ""
            )}

            <div className="item row">
              <DatePicker
                strings={DayPickerStrings}
                firstDayOfWeek={firstDayOfWeek}
                onSelectDate={(date) =>
                  setFormData("appealDate", toTimeStamp(date?.toString()))
                }
                label="Sertifika Başvuru Tarihi"
                placeholder="gün.ay.yıl"
                formatDate={onFormatDate}
                value={this.getDate(this.props.formData.appealDate)}
                maxDate={new Date()}
                minDate={certificateAppealStatusDate(usertype, false)}
              />

              <PrimaryButton
                id="appealDate"
                onClick={(x) => this.props.setFormData("appealDate", 0)}
                iconProps={{ iconName: "Trash" }}
                text="Tarihi Sil"
                className="deleteDateBtn"
                styles={{
                  root: {
                    marginTop: 5,
                    background: "#ac6262",
                    borderColor: "#ac6262",
                  },
                  rootHovered: {
                    background: "#a34c4c",
                    borderColor: "#a34c4c",
                  },
                  rootPressed: {
                    background: "#a34c4c",
                    borderColor: "#a34c4c",
                  },
                  rootDisabled: {
                    background: "#ececec",
                  },
                }}
              />
            </div>
            {this.props.formData.winterTourismId && <div className="item row">
              <Dropdown
                label="Tesis Faaliyet Durumu"

                placeholder={
                  'Seçiniz'
                }
                options={[
                  { key: "", text: "Seçiniz" },
                  { key: "1", text: "Tesis Açık" },
                  { key: "2", text: "Tesis Kapalı" },
                ].map((item: any) => ({
                  key: item.key,
                  text: item.text,
                }))}
                onChanged={(item: any) => {
                  if (item.key === "1") {
                    this.setState({ isOpenStatus: "1" })
                  } else if (item.key === "2") {
                    this.setState({ isOpenStatus: "2" })
                  } else if (item.key === "") {
                    this.setState({ isOpenStatus: '0' })
                  }
                  this.props.setFormData("isOpen", item.key);
                  this.props.setFormData("closingDate", 0);
                  this.props.setFormData("openingDate", 0);
                }}
                errorMessage={formErrors.isOpen}
              />

              {this.props.formData.isOpen === "1" ? (
                <DatePicker

                  strings={DayPickerStrings}
                  firstDayOfWeek={firstDayOfWeek}
                  onSelectDate={(date) =>
                    this.props.setFormData(
                      "closingDate",
                      toTimeStamp(date?.toString())
                    )
                  }
                  label="Tesisin Kapanış Tarihi "

                  placeholder="gün.ay.yıl"
                  formatDate={onFormatDate}
                  value={this.getDate(this.props.formData.closingDate)}
                  minDate={new Date()} />
              ) : this.props.formData.isOpen === "2" ? (
                <DatePicker

                  strings={DayPickerStrings}
                  firstDayOfWeek={firstDayOfWeek}
                  onSelectDate={(date) =>
                    this.props.setFormData(
                      "openingDate",
                      toTimeStamp(date?.toString())
                    )
                  }
                  label="Tesisin Açılış Tarihi "
                  placeholder="gün.ay.yıl"
                  formatDate={onFormatDate}
                  value={this.getDate(this.props.formData.openingDate)}
                  minDate={new Date()} />
              ) : null}

              {(this.state.isOpenStatus === "1" || this.state.isOpenStatus === "2") && <PrimaryButton
                id="appealDate"
                onClick={(x) => {
                  this.props.setFormData("openingDate", 0);
                  this.props.setFormData("closingDate", 0)
                }}
                iconProps={{ iconName: "Trash" }}
                text="Tarihi Sil"
                className="deleteDateBtn"
                styles={{
                  root: {
                    marginTop: 5,
                    background: "#ac6262",
                    borderColor: "#ac6262",
                  },
                  rootHovered: {
                    background: "#a34c4c",
                    borderColor: "#a34c4c",
                  },
                  rootPressed: {
                    background: "#a34c4c",
                    borderColor: "#a34c4c",
                  },
                  rootDisabled: {
                    background: "#ececec",
                  },
                }}
              />}
            </div>}
          </div>
        </div>

        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="item row">
              <Dropdown
                id="certificateStatusTypeId"
                onChanged={(event: any) => {
                  if (event.key !== 2) {
                    setFormData("confirmationDate", 0);
                    setFormData("CertificateNo", "");
                  }
                  setFormData("certificateStatusTypeId", event.key);
                }}
                errorMessage={formErrors.certificateStatusTypeId}
                label="Sertifika Durumu"
                options={(
                  this.props.formData.GroupListCR?.dataList || ([] as any)
                ).map((item: any) => ({
                  key: item.CertificateStatusTypeId,
                  text: item.Title,
                }))}
                className="custom-dropdown"
                selectedKey={this.props.formData.certificateStatusTypeId}
              />
            </div>

            {this.props.formData.certificateStatusTypeId === 2 ? (
              this.props.formData.prefix ? (
                <>
                  <div className="item row">
                    <DatePicker
                      strings={DayPickerStrings}
                      firstDayOfWeek={firstDayOfWeek}
                      onSelectDate={(date) =>
                        setFormData(
                          "confirmationDate",
                          toTimeStamp(date?.toString())
                        )
                      }
                      label="Sertifika Düzenleme Tarihi"
                      placeholder="gün.ay.yıl"
                      formatDate={onFormatDate}
                      value={this.getDate(formData.confirmationDate)}
                      maxDate={sertifikaTarihiBayram(usertype)}
                    />
                    <PrimaryButton
                      id="confirmationDate"
                      onClick={(x) =>
                        this.props.setFormData("confirmationDate", 0)
                      }
                      iconProps={{ iconName: "Trash" }}
                      text="Tarihi Sil"
                      className="deleteDateBtn"
                      styles={{
                        root: {
                          marginTop: 5,
                          background: "#ac6262",
                          borderColor: "#ac6262",
                        },
                        rootHovered: {
                          background: "#a34c4c",
                          borderColor: "#a34c4c",
                        },
                        rootPressed: {
                          background: "#a34c4c",
                          borderColor: "#a34c4c",
                        },
                        rootDisabled: {
                          background: "#ececec",
                        },
                      }}
                    />
                  </div>


            <div className="item row">
                    <TextField
                      id="CertificateNo"
                      placeholder="Sertifika No"
                      value={formData.CertificateNo || ""}
                      label="Sertifika No"
                      className="custom-textfield border"
                      onChange={(event: any) =>
                        setFormData("CertificateNo", event.target.value)
                      }
                      errorMessage={formErrors.CertificateNo}
                    />
                  </div>
                </>
              ) : (
                this.showDialog({
                  title: "Uyarı",
                  desc: "Sertifika Numarası için firma kodu Yok <br/> Denetleyici Firma Seçiniz",
                })
              )
            ) : (
              ""
            )}
            <ValidityDate />
            <div className="item row">
              <Checkbox
                checked={this.state.certificateStatusBir === 1}
                onChange={() =>
                  this.state.certificateStatusBir === 1
                    ? this.setState({
                        certificateStatusBir: 0,
                      })
                    : this.setState({
                        certificateStatusBir: 1,
                      })
                }
                label="Sertifika Uzatmalı Kapalı"
                className="custom-checkbox"
              />
            </div>

            <div className="item row">
              <Checkbox
                checked={this.state.certificateStatusUc === 1}
                onChange={() =>
                  this.state.certificateStatusUc === 1
                    ? this.setState({
                        certificateStatusUc: 0,
                      })
                    : this.setState({
                        certificateStatusUc: 1,
                      })
                }
                // disabled={usertype && usertype === "2"}
                className="custom-checkbox"
                label="Kare Kodsuz Sertifikalı"
              />
            </div>
          </div>
        </div>

        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="title">Sertifika Belgesi</div>
            <FileUpload
              onProcessStartFile={() => {
                this.setState({
                  isFileUploading: true,
                });
              }}
              onProcessEndFile={(queue: boolean) => {
                if (!queue) {
                  this.setState({
                    isFileUploading: false,
                  });
                }
              }}
              onTransferedFile={(file: any, promise?: boolean) => {
                this.props.setFormData("attachmentCertificateList", [
                  ...(this.props.formData?.attachmentCertificateList || []),
                  file.attachmentIdList[0],
                ]);
              }}
              requestKey="files"
              token={token}
              endpointUrl={
                apiConfig.prefixes.attachment + "UploadCertificateFile"
              }
            />
            {this.props.formData.attachmentCertificateList &&
              this.props.formData.attachmentCertificateList.length > 0 && (
                <React.Fragment>
                  <div className="title">Yüklenen Belgeler</div>
                  {JSON.stringify(
                    this.props.formData.initialAttachmentCertificateList
                  ) !==
                    JSON.stringify(
                      this.props.formData.attachmentCertificateList
                    ) && (
                    <div className="file-save-warning">
                      Düzenlemeleri ve yüklenen belgeleri kaydetmek için, sağ
                      üst kısımdan "Kaydet veya Yayımla"ya basın.
                    </div>
                  )}
                  <div className="uploaded-files">
                    {(this.props.formData?.attachmentCertificateList || []).map(
                      (
                        item: {
                          attachmentId: number;
                          attachmentPath: string;
                          fileName: string;
                          fileSize: number;
                        },
                        index: number
                      ) => (
                        <div key={index} className="uploaded-file">
                          <div className="name row">
                            <span className="text col no-padding">
                              {item.fileName}
                              <strong className="size m-left-auto">
                                {formatSize(item.fileSize)}
                              </strong>
                            </span>
                            <div className="buttons m-left-auto row">
                              <button
                                onClick={() =>
                                  window.open(
                                    qrUrl + item.attachmentPath,
                                    "_blank"
                                  )
                                }
                                className="view"
                              >
                                <i className="icon-explore"></i> Görüntüle
                              </button>
                              <button
                                onClick={() => {
                                  let files = _.cloneDeep(
                                    this.props.formData
                                      ?.attachmentCertificateList
                                  );
                                  let findIndex = (files || []).findIndex(
                                    (x: any) =>
                                      x.attachmentId === item.attachmentId
                                  );
                                  if (findIndex !== -1) {
                                    files.splice(findIndex, 1);
                                  }
                                  this.props.setFormData(
                                    "attachmentCertificateList",
                                    files
                                  );
                                }}
                                className="remove"
                              >
                                <i className="icon-close"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </React.Fragment>
              )}
          </div>
        </div>
      </div>
    );
  }
  showDialog(req: any): any {
    this.props.showErrorDialog(req);
  }
  setPrefix(): any {
    const el = document.createElement("span");
    el.className = "CertificateNo-span prefixSpan";
    el.id = "CertificateNo-span";
    el.innerHTML =
      usertype === "2"
        ? cookie.load("prefix") + "-"
        : this.props.formData.prefix + "-";
    const div = document.getElementById("CertificateNo");
    if (!document.getElementById("CertificateNo-span")) {
      this.insertBefore(div, el);
    } else {
      document.getElementById("CertificateNo-span")?.remove();
      this.insertBefore(div, el);
    }
    return false;
  }

  render() {
    return (
      <div className="page push-all">
        {this.props.formData.winterTourismModalShow && (
          <div className="modal-overlay">
            <div className="modal">
              <button
                className="btn-close"
                onClick={(x) =>
                  this.props.setFormData("winterTourismModalShow", false)
                }
              >
                Kapat
              </button>
              <WinterTourismModal history={this.props.history} />
            </div>
          </div>
        )}
         {this.state.modalStatus && (
          <Modal className={"warning custom-message-box danger"} isOpen={true}>
            <div className="modal-header row">
              <div className="title">{this.props.formData.brandName}</div>
              <span
                onClick={() => this.setState({ modalStatus: false })}
                className="close"
              >
                <Icon iconName="ChromeClose" />
              </span>
            </div>
            <div
              style={{ paddingBottom: 0 }}
              className="description modal-content"
            >
              <div style={{ textAlign: "left" }} className="modal-indicators">
                <table>
                  <tr>
                    <td>
                      <b>ID</b>
                    </td>
                    <td><b> :</b> {this.props.formData?.winterTourismId}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>İl</b>
                    </td>
                    <td><b> :</b> {this.props.formData?.cityName}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>İlçe</b>
                    </td>
                    <td>
                      <b> :</b> {this.props.formData?.districtName}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Belge Sahibi / Ticari Ünvan</b>
                    </td>
                    <td>
                      <b> :</b> {this.props.formData?.companyName}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Tesis Adresi</b>
                    </td>
                    <td><b>:</b> {this.props.formData?.address}</td>
                  </tr>


                </table>
              </div>
            </div>
          </Modal>
        )}

        <PageTitle
          title="Yeni Kış Turizmi Mekanik Hatlar Tesisi Sertifikası Ekle"
          titleRenderer={() => (
            <span className="edit-title">
              Yönet:
              <strong>
                Yeni Kış Turizmi Mekanik Hatlar Tesisi Sertifikası Ekle
              </strong>
            </span>
          )}
          showBack
          backUrl="/winterTourismCertification"
          history={this.props.history}
          subTitle="Yeni bir Kış Turizmi Mekanik Hatlar Tesisi Sertifikası eklemek için aşağıdaki bilgileri doldurunuz."
          right={
            <FormButtons
              isAbsolute
              onSubmit={() => this.onSubmit()}
              customRenderButtons={() => (
                <React.Fragment>
                  <PrimaryButton
                    onClick={() => this.onSubmit()}
                    disabled={
                      this.state.messageType === MessageBarType.success ||
                      this.state.isFileUploading
                        ? this.state.isFileUploading
                          ? true
                          : false
                        : false
                    }
                    styles={{
                      rootDisabled: {
                        background: "#626262",
                        borderColor: "#777373 !important",
                      },
                    }}
                    iconProps={{ iconName: "CheckMark" }}
                    text="Yayımla"
                  />
                  {this.state.isFileUploading && (
                    <div className="tooltip-warning">
                      Bazı dosyalar yükleniyor, lütfen dosyalar yüklenene kadar
                      bekleyiniz.
                    </div>
                  )}
                </React.Fragment>
              )}
            />
          }
          rightType="create"
        />
        {this.renderMessageBar()}

        {this.renderForm()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateWinterTourismCertification);
