import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { History } from "history";
import BasicTable from "../BasicTable";
import { Label, PrimaryButton } from "office-ui-fabric-react";
import { castThunkAction } from "src/helpers/casting";
import { listCount } from "src/config/otherConfig";
import {
  GetFacilityCompanyList,
  GetFacilityCompanyUserStatistic,
  excelDownloadCompany,
} from "src/store/thunkStatistic";
import { formatDate2, toDate } from "src/helpers/converts";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        GetFacilityCompanyList,
        GetFacilityCompanyUserStatistic,
        excelDownloadCompany,
      },
      dispatch
    ),
  };
}

type AdditionalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  pagerCurrent: number;
  pagerTotal: number;
}

class FacilityCompanyList extends Component<AdditionalProps, OwnState> {
  state: any = {
    pagerCurrent: 0,
    pagerTotal: 0,
  };

  UNSAFE_componentWillMount() {
    this.getData(this.state.pagerCurrent);
  }

  getData(pagerSelected: number) {
    const request: any = {
      pageSize: listCount,
      pageIndex: pagerSelected,
    };

    castThunkAction(this.props.GetFacilityCompanyList(request)).then(
      (response: any) => {
        this.setState({
          pagerTotal: this.props.formData?.GetFacilityCompanyList?.pageCount,
        });
      }
    );
  }

  getDataPager(pagerSelected: number) {
    const request: any = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...this.state.filterData,
    };
    castThunkAction(this.props.GetFacilityCompanyList(request)).then();
  }
  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Tümünü Seç" });
    }
    return newArray;
  }
  getDate(date: any): any {
    if (date !== 0) {
      return formatDate2(toDate(date, ""));
    }
  }

  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "hotelId",
            text: "Tesis ID",
            col: "col-md-1",
          },
          {
            columnName: "brandName",
            text: "Tesis Adı",
            col: "col-md-1",
          },
          {
            columnName: "certificateTypeName",
            text: "Belge Tipi",
            col: "col-md-1",
          },
          {
            columnName: "roomCount",
            text: "Oda Sayısı",
            col: "col-md-1",
          },
          {
            columnName: "hotelCreatedSystemUserTitle",
            text: "Oluşturan Firma",
            col: "col-md-1",
          },
          {
            columnName: "hotelCreatedSystemUserTitle",
            text: "İlk Denetleyici Firma",
            col: "col-md-1",
          },
          {
            columnName: "createDateString",
            text: "İlk Sertifika Tarih",
            col: "col-md-1",
          },
          {
            columnName: "companyUserTitle",
            text: "Güncel Denetleyici Firma",
            col: "col-md-2",
          },
          {
            columnName: "certificateStatusTypeName",
            text: "Güncel Sertifika Durumu",
            col: "col-md-1",
          },
          {
            columnName: "confirmationDate",
            text: "Güncel Sertifika Düzenleme Tarihi",
            col: "col-md-1",
            onRender: (item: { confirmationDate: any }) => (
              <React.Fragment>
                {item.confirmationDate !== 0
                  ? this.getDate(item.confirmationDate)
                  : ""}
              </React.Fragment>
            ),
          },
          {
            columnName: "systemUserCount",
            text: "Toplam Denetleyeci Firma Sayısı",
            col: "col-md-1",
          },
        ]}
        items={this.props.formData?.GetFacilityCompanyList?.dataList || []}
      />
    );
  }
  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.state.pagerTotal}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }
  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getDataPager(selected);
  };

  getReport() {
    const requestReport: any = {};
    castThunkAction(this.props.excelDownloadCompany(requestReport)).then(
      (response: any) => {}
    );
  }

  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Denetim Firması Değişiklikleri"
          subTitle="Yönetilebilecek tüm alanları aşağıdan görüntüleyebilirsiniz."
          backUrl="/Statistic"
          showBack
          history={this.props.history}
        />
        <PrimaryButton
          onClick={(x) => this.getReport()}
          iconProps={{ iconName: "Report" }}
          className="absolute-create"
          text="Rapor İndir"
          styles={{
            root: {
              background: "#62a98e",
              borderColor: "#5b9e85",
            },
            rootHovered: {
              background: "#5b9e85",
              borderColor: "#5b9e85",
            },
            rootDisabled: {
              background: "#ececec",
            },
          }}
        />
        <Label className="totalRecord">
          Denetim Sayısı:
          {" " + this.props.formData?.GetFacilityCompanyList?.dataCount}
        </Label>
        <div style={{ marginTop: "30px" }}>{this.renderList()}</div>
        {this.renderPager()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FacilityCompanyList);
