import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Header from "../../components/Header";
import LeftPanel from "../../components/LeftPanel";
import { EditWinterTourismEndId } from "../../components/WinterTourismCertification";

export default class EditWinterTourismCertificationEndId extends React.Component<
  RouteComponentProps<any>
> {
  render() {
    return (
      <React.Fragment>
        <Header history={this.props.history} />
        <div className="row">
          <LeftPanel history={this.props.history} />
          <EditWinterTourismEndId
            history={this.props.history}
            WinterTourismCertificateId={
              this.props.match.params.WinterTourismCertificateId
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
