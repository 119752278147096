import {
  IDatePickerStrings,
  DayOfWeek,
} from "office-ui-fabric-react/lib/DatePicker";

export const listCount = 10;

export const DayPickerStrings: IDatePickerStrings = {
  months: [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ],

  shortMonths: [
    "Oca",
    "Şub",
    "Mar",
    "Nis",
    "May",
    "Haz",
    "Tem",
    "Ağu",
    "Eyl",
    "Eki",
    "Kas",
    "Ara",
  ],

  days: [
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
  ],

  shortDays: ["P", "Pt", "S", "Ç", "P", "C", "Ct"],

  goToToday: "Bu güne Git",
  prevMonthAriaLabel: "Önceki aya git",
  nextMonthAriaLabel: "Sonraki aya git",
  prevYearAriaLabel: "Önceki yıla git",
  nextYearAriaLabel: "Sonraki yıla git",
  closeButtonAriaLabel: "Takvimi Kapat",

  isRequiredErrorMessage: "Tarih girilmesi gerekli.",

  invalidInputErrorMessage: "Geçersiz tarih biçimi.",
};

export const firstDayOfWeek = DayOfWeek.Monday;

export const onFormatDate = (date: any): string => {
  return (
    date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
  );
};
export const onFormatDate2 = (date: any): any => {};
