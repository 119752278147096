import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import LayoverFacilityType from "src/services/LayoverFacilityType/LayoverFacilityTypeService";
import {
  LayoverFacilityTypeGetAllRequestModel,
  LayoverFacilityTypeChangeStatusRequestModel,
  LayoverFacilityTypeGetByIdRequestModel,
  LayoverFacilityTypeUpdateRequestModel,
  LayoverFacilityTypeSaveRequestModel,
} from "src/services/LayoverFacilityType/RequestModel";
import { MessageType } from "./system/types";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllLFA(
  request: LayoverFacilityTypeGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const LayoverFacilityTypeService = new LayoverFacilityType();
    const result = await LayoverFacilityTypeService.getAll(request);
    dispatch(setFormData("GroupListFA", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: LayoverFacilityTypeGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const LayoverFacilityTypeService = new LayoverFacilityType();
    const result = await LayoverFacilityTypeService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: LayoverFacilityTypeSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const LayoverFacilityTypeService = new LayoverFacilityType();
    const result = await LayoverFacilityTypeService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Tesis Sınıfı Yönetimi"
        )
      );
      return result;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Tesis Sınıfı Yönetimi"
        )
      );
      return false;
    }
  };
}
export function update(
  request: LayoverFacilityTypeUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const LayoverFacilityTypeService = new LayoverFacilityType();
    const result = await LayoverFacilityTypeService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Tesis Sınıfı Yönetimi"
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Tesis Sınıfı Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getById(
  request: LayoverFacilityTypeGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const LayoverFacilityTypeService = new LayoverFacilityType();
    const result = await LayoverFacilityTypeService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: LayoverFacilityTypeChangeStatusRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const LayoverFacilityTypeService = new LayoverFacilityType();
    const result = await LayoverFacilityTypeService.changeStatus(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Tesis Sınıfı Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Tesis Sınıfı Yönetimi"
        )
      );
      return false;
    }
  };
}
