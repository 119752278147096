import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import Congress from "src/services/Congress/CongressService";
import {
  CongressChangeStatusRequestModel,
  GetCongressListModel,
  CongressGetByIdRequestModel,
  CongressUpdateRequestModel,
  CongressSaveRequestModel,
  GetCongressCertificateListByCongressModel,
} from "src/services/Congress/RequestModel";
import { MessageType } from "./system/types";
import { formatDate } from "src/helpers/converts";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllMA(
  request: GetCongressListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const CongressService = new Congress();
    const result = await CongressService.getAllCongress(request);
    dispatch(setFormData("CongressList", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}
export function getAllForPager(
  request: GetCongressListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const CongressService = new Congress();
    const result = await CongressService.getAllCongress(request);
    dispatch(setFormData("dataList", result.dataList));
    dispatch(setFormData("pageCount", result.pageCount));
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAllCongress(
  request: GetCongressListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressService = new Congress();
    const result = await CongressService.getAllCongress(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAllCongressForModal(
  request: GetCongressListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressService = new Congress();
    const result = await CongressService.getAllCongress(request);
    dispatch(setFormData("CongressListForModal", result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}
export function getAllCongressForExcel(
  request: GetCongressListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressService = new Congress();
    const excelName = "KongreVeSanatSalonu" + formatDate();
    await CongressService.excelDownload(request, excelName);
    dispatch(toggleLoader());

    return false;
  };
}

export function getAllSertificationMA(
  request: GetCongressCertificateListByCongressModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const HotelService = new Congress();
    const result = await HotelService.GetCongressCertificateListByCongress(
      request
    );
    dispatch(setFormData("CongressSerticationList", result));
    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: CongressSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressService = new Congress();
    const result = await CongressService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          ""
        )
      );
      return result;
    } else {
      if (result.errorCode === 87) {
        dispatch(
          showMessage(
            result.errorMessage,
            "Uyarı!",
            MessageType.ERROR,
            "Sertifika No Benzerliği"
          )
        );
      } else {
        dispatch(
          showMessage(
            result.errorMessage,
            "Bir Sorun Oluştu!",
            MessageType.ERROR,
            ""
          )
        );
      }
      return false;
    }
  };
}
export function update(
  request: CongressUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressService = new Congress();
    const result = await CongressService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          ""
        )
      );
      return true;
    } else {
      if (result.errorCode === 87) {
        dispatch(
          showMessage(
            result.errorMessage,
            "Uyarı!",
            MessageType.ERROR,
            "Sertifika No Benzerliği"
          )
        );
      } else {
        dispatch(
          showMessage(
            result.errorMessage,
            "Bir Sorun Oluştu!",
            MessageType.ERROR,
            ""
          )
        );
      }
      return false;
    }
  };
}

export function getById(
  request: CongressGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressService = new Congress();
    const result = await CongressService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getByIdForCongressForCert(
  request: CongressGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressService = new Congress();
    const result = await CongressService.getById(request);
    // // bunu kullanırsan form data baştan olşuturulur eskiyi kaybedersin
    // dispatch(initialFormData(result.data));
    //bunu kullanırsan olan formdataya parametre eklenir okey? ok
    dispatch(setFormData("findCongreByQSId", result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: CongressChangeStatusRequestModel,
  isDelete: boolean = false
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressService = new Congress();
    const result = await CongressService.changeStatus(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(initialFormData(result));
      dispatch(
        showMessage(
          isDelete
            ? "Silme işlemi başarıyla değiştirildi."
            : "Kayıt durumu işlemi başarıyla değiştirildi.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          "",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          ""
        )
      );
      return false;
    }
  };
}
