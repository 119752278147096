import { HttpClient } from "src/library/HttpClient";
import {
  VehicleGetAllRequestModel,
  VehicleSaveRequestModel,
  VehicleUpdateRequestModel,
  VehicleGetByIdRequestModel,
  VehicleChangeStatusRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class Vehicle {
  async getAll(request: VehicleGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Vehicle + "GetTransportList",
      { ...request, token }
    );
  }
  async save(request: VehicleSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Vehicle + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: VehicleUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Vehicle + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(request: VehicleGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Vehicle + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(request: VehicleChangeStatusRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Vehicle + "ChangeStatus",
      { ...request, token }
    );
  }

  async excelDownload(
    request: VehicleGetAllRequestModel,
    name: string
  ) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.Vehicle + "TransportReport",
      name,
      { ...request, token }
    );
  }

  async GetVehicleCertificateListByTransport(
    request: VehicleGetByIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Transport + "GetTransportCertificateListByTransport",
      { ...request, token }
    );
  }
}
