import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Dropdown,
  DatePicker,
  Label,
  TextField,
  ComboBox,
} from "office-ui-fabric-react";
import {
  setFormData,
  setFormErrors,
  initialFormData,
} from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";
import { save } from "src/store/thunkAdditionalControl";
import { getByIdForCertificate } from "src/store/thunkHotel";
import { getByIdForCongressForCert } from "src/store/thunkCongress";
import { getByIdForSeaForCert } from "src/store/thunkSeaTourism";
import { getByIdForWinterForCert } from "src/store/thunkWinterTourism";
import { getByIdForThemedForCert } from "src/store/thunkThemedFacility";
import { castThunkAction } from "src/helpers/casting";
import { StatusEnums } from "src/services/BaseEnum";
import { getAllM } from "src/store/thunksCity";
import { getAllLFA } from "src/store/thunkLayoverFacilityType";
import { getAllC } from "src/store/thunkCounty";
import { getAllLSU } from "src/store/thunkLayoverSubCategory";
import { getAllTy, showErrorDialog } from "src/store/thunkCertificateType";
import { getAllCR } from "src/store/thunkCertificateStatusType";
import { getAllCompany } from "src/store/thunksCompany";
import { getCompanyUserList } from "src/store/thunksSystemUser";
import { ConditionOperatorEnum } from "src/services/City/RequestModel";
import { CertificateStatusTypeGetAllRequestModel } from "src/services/CertificateStatusType/RequestModel";
import cookie from "react-cookies";
import {
  DayPickerStrings,
  firstDayOfWeek,
  onFormatDate,
} from "src/config/otherConfig";
import {
  toTimeStamp,
  toDate,
  add3Hour,
  toTimeStampWithDate,
  addDays,
} from "src/helpers/converts";
import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";
import _ from "lodash";
import { FileUpload } from "../FileUpload";
import { apiConfig, qrUrl } from "src/config/apiConfig";
import {
  AuditResultEnum,
  AuditTypeEnum,
  FinalResultEnum,
} from "src/helpers/enums";
import { AdditionalSaveRequestModel } from "src/services/AdditionalControl/RequestModel";
import { formatSize } from "src/helpers/functions";

let token = "";

interface OwnProps {
  history: History;
  Id: number;
  Type: number;
}
function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}
function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        initialFormData,
        save,
        getAllM,
        getAllC,
        getAllLSU,
        getAllLFA,
        getAllTy,
        getAllCR,
        getCompanyUserList,
        getAllCompany,
        showErrorDialog,
        getByIdForCertificate,
        getByIdForCongressForCert,
        getByIdForSeaForCert,
        getByIdForWinterForCert,
        getByIdForThemedForCert,
      },
      dispatch
    ),
  };
}

type CreateHotelCertificationProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  showFields: boolean;
  messageType: MessageBarType;
  isFileUploading: boolean;
  infoMessageGetDocumentNo: string;
}
let usertype: any = 0;
class CreateHotelCertification extends Component<
  CreateHotelCertificationProps,
  OwnState
> {
  state = {
    messageType: MessageBarType.warning,
    showFields: true,
    isFileUploading: false,
    infoMessageGetDocumentNo: "",
  };

  UNSAFE_componentWillMount() {
    token = cookie.load("userId");
    this.props.initialFormData({
      status: StatusEnums.Published,
    });
    usertype = cookie.load("systemUserInfo");
    const request6: SystemUserGetAllModel = {
      systemUserType: 2,
    };

    castThunkAction(this.props.getCompanyUserList(request6)).then(
      (response: any) => {
        if (usertype && usertype === "2") {
          this.props.setFormData("companyUserId", 0);
        }
        const request5: CertificateStatusTypeGetAllRequestModel = {
          selectItemList: ["CertificateStatusTypeId", "Title"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.Type == 5 &&
          castThunkAction(this.props.getAllCR(request5)).then((e: any) => {
            this.props.setFormData("certificateStatusTypeId", 1);
            if (this.props.Id) {
              this.props.setFormData("relatedId", this.props.Id);
              this.props.setFormData("type", this.props.Type);
              castThunkAction(
                this.props.getByIdForCertificate({ id: this.props.Id })
              ).then((x: any) => {
                this.props.setFormData(
                  "brandName",
                  this.props.formData.findHotelByQSId.brandName
                );
                this.props.setFormData(
                  "cityId",
                  this.props.formData.findHotelByQSId.cityId
                );
                this.props.setFormData(
                  "districtId",
                  this.props.formData.findHotelByQSId.districtId
                );

                this.props.formData.findHotelByQSId.roomCount === ""
                  ? this.props.setFormData("roomCount", 0)
                  : this.props.setFormData(
                    "roomCount",
                    this.props.formData.findHotelByQSId.roomCount
                  );
              });
            }
          });

        this.props.Type == 6 &&
          castThunkAction(this.props.getAllCR(request5)).then((e: any) => {
            this.props.setFormData("certificateStatusTypeId", 1);
            if (this.props.Id) {
              this.props.setFormData("relatedId", this.props.Id);
              this.props.setFormData("type", this.props.Type);
              castThunkAction(
                this.props.getByIdForCongressForCert({ id: this.props.Id })
              ).then((x: any) => {
                this.props.setFormData(
                  "brandName",
                  this.props.formData.findCongreByQSId.brandName
                );
                this.props.setFormData(
                  "cityId",
                  this.props.formData.findCongreByQSId.cityId
                );
                this.props.setFormData(
                  "districtId",
                  this.props.formData.findCongreByQSId.districtId
                );

                this.props.formData.findCongreByQSId.saloonCount === ""
                  ? this.props.setFormData("roomCount", 0)
                  : this.props.setFormData(
                    "roomCount",
                    this.props.formData.findCongreByQSId.saloonCount
                  );
              });
            }
          });

        this.props.Type == 7 &&
          castThunkAction(this.props.getAllCR(request5)).then((e: any) => {
            this.props.setFormData("certificateStatusTypeId", 1);
            if (this.props.Id) {
              this.props.setFormData("relatedId", this.props.Id);
              this.props.setFormData("type", this.props.Type);
              castThunkAction(
                this.props.getByIdForSeaForCert({ id: this.props.Id })
              ).then((x: any) => {
                this.props.setFormData(
                  "brandName",
                  this.props.formData.findSeaByQSId.brandName
                );
                this.props.setFormData(
                  "cityId",
                  this.props.formData.findSeaByQSId.cityId
                );
                this.props.setFormData(
                  "districtId",
                  this.props.formData.findSeaByQSId.districtId
                );

                this.props.formData.findSeaByQSId.roomCount === ""
                  ? this.props.setFormData("roomCount", 0)
                  : this.props.setFormData(
                    "roomCount",
                    this.props.formData.findSeaByQSId.roomCount
                  );
              });
            }
          });

        this.props.Type == 8 &&
          castThunkAction(this.props.getAllCR(request5)).then((e: any) => {
            this.props.setFormData("certificateStatusTypeId", 1);
            if (this.props.Id) {
              this.props.setFormData("relatedId", this.props.Id);
              this.props.setFormData("type", this.props.Type);
              castThunkAction(
                this.props.getByIdForThemedForCert({ id: this.props.Id })
              ).then((x: any) => {
                this.props.setFormData(
                  "brandName",
                  this.props.formData.findThemedByQSId.brandName
                );
                this.props.setFormData(
                  "cityId",
                  this.props.formData.findThemedByQSId.cityId
                );
                this.props.setFormData(
                  "districtId",
                  this.props.formData.findThemedByQSId.districtId
                );
                this.props.formData.findThemedByQSId.roomCount === ""
                  ? this.props.setFormData("roomCount", 0)
                  : this.props.setFormData(
                    "roomCount",
                    this.props.formData.findThemedByQSId.roomCount
                  );
              });
            }
          });

        this.props.Type == 9 &&
          castThunkAction(this.props.getAllCR(request5)).then((e: any) => {
            this.props.setFormData("certificateStatusTypeId", 1);
            if (this.props.Id) {
              this.props.setFormData("relatedId", this.props.Id);
              this.props.setFormData("type", this.props.Type);
              castThunkAction(
                this.props.getByIdForWinterForCert({ id: this.props.Id })
              ).then((x: any) => {
                this.props.setFormData(
                  "brandName",
                  this.props.formData.findWinterByQSId.brandName
                );
                this.props.setFormData(
                  "cityId",
                  this.props.formData.findWinterByQSId.cityId
                );
                this.props.setFormData(
                  "districtId",
                  this.props.formData.findWinterByQSId.districtId
                );
                this.props.formData.findWinterByQSId.roomCount === ""
                  ? this.props.setFormData("roomCount", 0)
                  : this.props.setFormData(
                    "roomCount",
                    this.props.formData.findWinterByQSId.roomCount
                  );
              });
            }
          });
      }
    );
  }

  insertAfter(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }
  insertBefore(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode);
  }
  setDdlDescription(id: string, message: string) {
    const el = document.createElement("span");
    el.innerHTML = message;
    const div = document.getElementById(id);
    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div, el);
    }
  }
  setLabelDescription(id: string, message: string) {
    const el2 = document.createElement("span");
    el2.innerHTML = message;
    const div2 = document.querySelectorAll('[for="' + id + '"]');

    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div2[0], el2);
    }
  }
  renderTextByMessageType() {
    switch (this.state.messageType) {
      case MessageBarType.success:
        return "Kaydetme işlemi başarıyla gerçekleşmiştir.";
      case MessageBarType.error:
        return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
      default:
        return "Lütfen aşağıdaki bilgileri doldurun ve formu oluşturun";
    }
  }
  renderMessageBar() {
    return (
      <MessageBar messageBarType={this.state.messageType} isMultiline={false}>
        {this.renderTextByMessageType()}
      </MessageBar>
    );
  }
  onCreate(isDraft?: boolean) {
    let request: AdditionalSaveRequestModel = {
      data: {
        companyUserId: this.props.formData.companyUserId,
        relatedId: parseInt(this.props.formData.relatedId),
        facilityName: this.props.formData.brandName,
        facilityType: parseInt(this.props.formData.type),
        auditDate: toTimeStampWithDate(
          add3Hour(toDate(this.props.formData.AuditDate))
        ),
        notSuitableCloseEndDate: this.props.formData.NotSuitableCloseEndDate,
        auditType: this.props.formData.AuditType,
        auditResult: this.props.formData.AuditResult,
        finalResult: this.props.formData.FinalResult,
        auditResultDescription: this.props.formData.AuditResultDescription,
        finalResultDescription: this.props.formData.FinalResultDescription,
        roomCount: parseInt(this.props.formData.roomCount),
        cityId: this.props.formData.cityId,
        districtId: this.props.formData.districtId,
      },
    };
    if (
      this.props.formData.auditReportAttachmentList &&
      this.props.formData.auditReportAttachmentList.length > 0
    ) {
      request.data["auditReportAttachmentList"] = (
        this.props.formData.auditReportAttachmentList || 0
      ).map((item: any) => item.attachmentId);
    }
    if (
      this.props.formData.auditNotSuitableAttachmentList &&
      this.props.formData.auditNotSuitableAttachmentList.length > 0
    ) {
      request.data["auditNotSuitableAttachmentList"] = (
        this.props.formData.auditNotSuitableAttachmentList || 0
      ).map((item: any) => item.attachmentId);
    }
    castThunkAction(this.props.save(request)).then((response: any) => {
      this.props.setFormData(
        "initialauditReportAttachmentList",
        this.props.formData.auditReportAttachmentList
      );
      this.props.setFormData(
        "initialauditNotSuitableAttachmentList",
        this.props.formData.auditNotSuitableAttachmentList
      );
      this.setState(
        {
          messageType: response ? MessageBarType.success : MessageBarType.error,
        },
        () => {
          if (response) {
            this.props.history.push("/AdditionalControl/Edit/" + response.id);
          } else {
            setTimeout(() => { }, 1000);
          }
        }
      );
    });
  }
  onSubmit(isDraft?: boolean) {
    let desc = "";

    if (!this.filterFileds(this.props.formData.AuditType)) {
      desc += "Tesis Tipi, <br/>";
    }
    if (!this.filterFileds(this.props.formData.AuditDate)) {
      desc += "Denetim Tarihi,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.AuditResult)) {
      desc += "Denetim Sonucu,  <br/>";
    }
    if (this.props.formData.AuditResult === 2) {
      if (!this.filterFileds(this.props.formData.NotSuitableCloseEndDate)) {
        desc += "Kapama Tarihi,  <br/>";
      }
      if (!this.filterFileds(this.props.formData.FinalResult)) {
        desc += "Nihai Sonuç,  <br/>";
      }
    }
    if (this.props.formData.AuditResult !== 1) {
      if (!this.filterFileds(this.props.formData.AuditResultDescription)) {
        desc += "Denetim Sonucu Açıklama,  <br/>";
      }
    }
    if (this.props.formData.FinalResult === 3) {
      if (!this.filterFileds(this.props.formData.FinalResultDescription)) {
        desc += "Nihai Sonuc Açıklama,  <br/>";
      }
    }

    if (
      !this.props.formData.auditReportAttachmentList ||
      this.props.formData.auditReportAttachmentList.length === 0
    ) {
      desc += "Denetim raporu yükleyiniz,  <br/>";
    }

    // if (
    //   (this.props.formData.AuditResult === 2 &&
    //     this.props.formData.FinalResult === 3) ||
    //   this.props.formData.AuditResult === 3
    // ) {
    //   if (
    //     !this.props.formData.auditNotSuitableAttachmentList ||
    //     this.props.formData.auditNotSuitableAttachmentList.length === 0
    //   ) {
    //     desc += "Uygunsuzluk raporu yükleyiniz,  <br/>";
    //   }
    // }

    const request = {
      title: "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
      desc: desc,
    };
    if (desc !== "") {
      castThunkAction(
        this.props.showErrorDialog(request)
      ).then((response: any) => { });
    } else {
      this.removeElement("confirmationDate-span");
      this.onCreate();
    }

    const { formData, setFormErrors } = this.props;
    const validationSchema = _yup.object({
      AuditType: _yup.string().required("Bu alan zorunludur!"),
      AuditDate: _yup.string().required("Bu alan zorunludur!"),
      AuditResult: _yup.string().required("Bu alan zorunludur!"),
      NotSuitableCloseEndDate: _yup.string().required("Bu alan zorunludur!"),
      AuditResultDescription: _yup
        .string()
        .when(
          "AuditResult",
          (AuditResult: any, schema: any, AuditResultDescription: string) => {
            return schema.test({
              test: (AuditResultDescription: any) =>
                AuditResult !== "1"
                  ? !this.props.formData.AuditResultDescription ||
                    this.props.formData.AuditResultDescription === ""
                    ? false
                    : true
                  : true,
              message:
                AuditResult !== "1"
                  ? !this.props.formData.AuditResultDescription ||
                    this.props.formData.AuditResultDescription === ""
                    ? "Lütfen tespit edilen uygunsuzlukları kısaca belirtiniz"
                    : ""
                  : "",
            });
          }
        ),
      FinalResult: _yup
        .string()
        .when(
          "AuditResult",
          (AuditResult: any, schema: any, FinalResult: any) => {
            return schema.test({
              test: (FinalResult: any) =>
                AuditResult == "2"
                  ? !this.props.formData.FinalResult ||
                    this.props.formData.FinalResult === ""
                    ? false
                    : true
                  : true,
              message:
                AuditResult == "2"
                  ? !this.props.formData.FinalResult ||
                    this.props.formData.FinalResult === ""
                    ? "Lütfen Nihai Sonuç seçimini yapınız"
                    : ""
                  : "",
            });
          }
        ),
      FinalResultDescription: _yup
        .string()
        .when(
          "FinalResult",
          (FinalResult: any, schema: any, FinalResultDescription: any) => {
            return schema.test({
              test: (FinalResultDescription: any) =>
                FinalResult == 3
                  ? !this.props.formData.FinalResultDescription ||
                    this.props.formData.FinalResultDescription === ""
                    ? false
                    : true
                  : true,
              message:
                FinalResult == 3
                  ? !this.props.formData.FinalResultDescription ||
                    this.props.formData.FinalResultDescription === ""
                    ? "Lütfen Nihai Sonuç Açıklaması Giriniz."
                    : ""
                  : "",
            });
          }
        ),
    });

    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  removeElement(id: any) {
    var elem = document.getElementById(id);
    return elem?.parentNode?.removeChild(elem);
  }
  filterFileds(val: any) {
    if (
      val !== "" &&
      val !== 0 &&
      val !== "0" &&
      val !== null &&
      val !== "null" &&
      val !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  }
  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Seçiniz" });
    }

    return newArray;
  }
  getDate(date: any): any {
    if (date !== 0 && date !== undefined) {
      return toDate(date, "");
    }
  }
 

  renderForm() {
    const { formData, formErrors, setFormData } = this.props;
    return (
      <div className="form-groups row">
        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="item row">
              {this.props.Id ? (
                <Label className="hotelName full-width-label">
                  Seçili Tesis: {this.props.formData.brandName}
                </Label>
              ) : (
                <Label className="hotelName alert full-width-label">
                  Seçilmiş Tesis Bulunmuyor
                </Label>
              )}
            </div>
            <div className="item row">
              <Dropdown
                onChanged={(event: any) => setFormData("AuditType", event.key)}
                label="Denetim Türü"
                options={AuditTypeEnum.map((item: any) => ({
                  key: item.key,
                  text: item.text,
                }))}
                className="custom-dropdown"
                errorMessage={formErrors.AuditType}
              />
            </div>
            <div className="item row">
              <DatePicker
                id="AuditNotificationDate"
                onSelectDate={(date) =>
                  setFormData(
                    "AuditNotificationDate",
                    toTimeStamp(date?.toString())
                  )
                }
                disabled={true}
                formatDate={onFormatDate}
                label="Denetim Bildirim Tarihi"
                value={new Date()}
              />
            </div>
            <div className="item row">
              <DatePicker
                strings={DayPickerStrings}
                firstDayOfWeek={firstDayOfWeek}
                onSelectDate={(date) =>
                  setFormData("AuditDate", toTimeStamp(date?.toString()))
                }
                label="Denetim Tarihi"
                placeholder="gün.ay.yıl"
                formatDate={onFormatDate}
                value={this.getDate(this.props.formData.AuditDate)}
                maxDate={new Date()}
                isRequired={true}
              />

              <PrimaryButton
                id="AuditDate"
                onClick={(x) => this.props.setFormData("AuditDate", 0)}
                iconProps={{ iconName: "Trash" }}
                text="Tarihi Sil"
                className="deleteDateBtn"
                styles={{
                  root: {
                    marginTop: 5,
                    background: "#ac6262",
                    borderColor: "#ac6262",
                  },
                  rootHovered: {
                    background: "#a34c4c",
                    borderColor: "#a34c4c",
                  },
                  rootPressed: {
                    background: "#a34c4c",
                    borderColor: "#a34c4c",
                  },
                  rootDisabled: {
                    background: "#ececec",
                  },
                }}
              />
            </div>
            {usertype && usertype !== "2" && (
              <div className="item row">
                <ComboBox
                  onChange={(event: any, option: any) =>
                    setFormData("companyUserId", option.key)
                  }
                  allowFreeform={true}
                  autoComplete="on"
                  label="Denetleyici Firma"
                  options={(
                    this.props.formData.CompanyUserList?.dataList || ([] as any)
                  ).map((item: any) => ({
                    key: item.systemUserId,
                    text: item.title,
                  }))}
                  selectedKey={this.props.formData.companyUserId || 0}
                  className="custom-dropdown"
                  errorMessage={formErrors.companyUserId}
                />
              </div>
            )}
          </div>
        </div>

        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="item row">
              <Dropdown
                onChanged={(event: any) =>
                  setFormData("AuditResult", event.key)
                }
                label="Denetim Sonucu"
                options={AuditResultEnum.map((item: any) => ({
                  key: item.key,
                  text: item.text,
                }))}
                className="custom-dropdown"
                errorMessage={formErrors.AuditResult}
              />
            </div>

            {formData.AuditResult === 2 && (
              <div className="item row">
                <DatePicker
                  strings={DayPickerStrings}
                  firstDayOfWeek={firstDayOfWeek}
                  onSelectDate={(date) =>
                    setFormData(
                      "NotSuitableCloseEndDate",
                      toTimeStamp(date?.toString())
                    )
                  }
                  label="Kapama Süresi Bitiş Tarihi"
                  placeholder="gün.ay.yıl"
                  formatDate={onFormatDate}
                  isRequired={formData.AuditResult === 2}
                  value={this.getDate(
                    this.props.formData.NotSuitableCloseEndDate
                  )}
                  maxDate={addDays(toDate(this.props.formData.AuditDate), 7)}
                  minDate={this.getDate(this.props.formData.AuditDate)}
                  initialPickerDate={this.getDate(
                    this.props.formData.AuditDate
                  )}
                />

                <PrimaryButton
                  id="NotSuitableCloseEndDate"
                  onClick={(x) =>
                    this.props.setFormData("NotSuitableCloseEndDate", 0)
                  }
                  iconProps={{ iconName: "Trash" }}
                  text="Tarihi Sil"
                  className="deleteDateBtn"
                  styles={{
                    root: {
                      marginTop: 5,
                      background: "#ac6262",
                      borderColor: "#ac6262",
                    },
                    rootHovered: {
                      background: "#a34c4c",
                      borderColor: "#a34c4c",
                    },
                    rootPressed: {
                      background: "#a34c4c",
                      borderColor: "#a34c4c",
                    },
                    rootDisabled: {
                      background: "#ececec",
                    },
                  }}
                />
              </div>
            )}
            {formData.AuditResult === 2 && (
              <div className="item row">
                <Dropdown
                  onChanged={(event: any) =>
                    setFormData("FinalResult", event.key)
                  }
                  label="Nihai Sonuç"
                  options={FinalResultEnum.map((item: any) => ({
                    key: item.key,
                    text: item.text,
                  }))}
                  className="custom-dropdown"
                  errorMessage={formErrors.FinalResult}
                />
              </div>
            )}
            <div className="item row">
              <TextField
                className="custom-textfield"
                label="Denetim Sonucu Açıklama"
                multiline
                errorMessage={formErrors.AuditResultDescription}
                onChange={(event: any) =>
                  setFormData("AuditResultDescription", event.target.value)
                }
              />
            </div>
            {formData.AuditResult === 2 && (
              <div className="item row">
                <TextField
                  className="custom-textfield"
                  label="Nihai Sonucu Açıklama"
                  multiline
                  onChange={(event: any) =>
                    setFormData("FinalResultDescription ", event.target.value)
                  }
                  errorMessage={formErrors.FinalResultDescription}
                />
              </div>
            )}
          </div>
        </div>

        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="title">Denetim Raporu</div>
            <FileUpload
              onProcessStartFile={() => {
                this.setState({
                  isFileUploading: true,
                });
              }}
              onProcessEndFile={(queue: boolean) => {
                console.log(!queue,queue,"onProcessEndFile")
                if (!queue) {
                  this.setState({
                    isFileUploading: false,
                  });
                }
              }}
              onTransferedFile={(file: any, promise?: boolean) => {
                this.props.setFormData("auditReportAttachmentList", [
                  ...(this.props.formData?.auditReportAttachmentList || []),
                  file.attachmentIdList[0],
                ]);
              }}
              fileTypes={[
                {
                  key: "image/jpeg",
                  text: "jpeg",
                },
                {
                  key: "image/jpg",
                  text: "jpg",
                },
                {
                  key: "image/png",
                  text: "png",
                },
                {
                  key: "application/pdf",
                  text: "pdf",
                },
                {
                  key: "application/vnd.ms-excel",
                  text: "xls",
                },
                {
                  key: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  text: "xlsx",
                },
                {
                  key: "application/msword",
                  text: "doc",
                },
                {
                  key: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  text: "docx",
                },
              ]}
              requestKey="files"
              token={token}
              maxFileSize="20MB"
              endpointUrl={
                apiConfig.prefixes.attachment + "UploadCertificateFile"
              }
            />
            {this.props.formData.auditReportAttachmentList &&
              this.props.formData.auditReportAttachmentList.length > 0 && (
                <React.Fragment>
                  <div className="title">Yüklenen Belgeler</div>
                  {JSON.stringify(
                    this.props.formData.initialauditReportAttachmentList
                  ) !==
                    JSON.stringify(
                      this.props.formData.auditReportAttachmentList
                    ) && (
                      <div className="file-save-warning">
                        Düzenlemeleri ve yüklenen belgeleri kaydetmek için, sağ
                        üst kısımdan "Kaydet veya Yayımla"ya basın.
                      </div>
                    )}
                  <div className="uploaded-files">
                    {(this.props.formData?.auditReportAttachmentList || []).map(
                      (
                        item: {
                          attachmentId: number;
                          attachmentPath: string;
                          fileName: string;
                          fileSize: number;
                        },
                        index: number
                      ) => (
                        <div key={index} className="uploaded-file">
                          <div className="name row">
                            <span className="text col no-padding">
                              {item.fileName}
                              <strong className="size m-left-auto">
                                {formatSize(item.fileSize)}
                              </strong>
                            </span>
                            <div className="buttons m-left-auto row">
                              <button
                                onClick={() =>
                                  window.open(
                                    qrUrl + item.attachmentPath,
                                    "_blank"
                                  )
                                }
                                className="view"
                              >
                                <i className="icon-explore"></i> Görüntüle
                              </button>
                              <button
                                onClick={() => {
                                  let files = _.cloneDeep(
                                    this.props.formData
                                      ?.auditReportAttachmentList
                                  );
                                  let findIndex = (files || []).findIndex(
                                    (x: any) =>
                                      x.attachmentId === item.attachmentId
                                  );
                                  if (findIndex !== -1) {
                                    files.splice(findIndex, 1);
                                  }
                                  this.props.setFormData(
                                    "auditReportAttachmentList",
                                    files
                                  );
                                }}
                                className="remove"
                              >
                                <i className="icon-close"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </React.Fragment>
              )}
          </div>
          {formData.AuditResult && formData.AuditResult !== 1 && (
            <div className="form-wrapper">
              <div className="title">Uygunsuzluk Raporu</div>
              <FileUpload
                onProcessStartFile={() => {
                  this.setState({
                    isFileUploading: true,
                  });
                }}
                onProcessEndFile={(queue: boolean) => {
                  if (!queue) {
                    this.setState({
                      isFileUploading: false,
                    });
                  }
                }}
                onTransferedFile={(file: any, promise?: boolean) => {
                  this.props.setFormData("auditNotSuitableAttachmentList", [
                    ...(this.props.formData?.auditNotSuitableAttachmentList ||
                      []),
                    file.attachmentIdList[0],
                  ]);
                }}
                requestKey="files"
                fileTypes={[
                  {
                    key: "image/jpeg",
                    text: "jpeg",
                  },
                  {
                    key: "image/jpg",
                    text: "jpg",
                  },
                  {
                    key: "image/png",
                    text: "png",
                  },
                  {
                    key: "application/pdf",
                    text: "pdf",
                  },
                  {
                    key: "application/vnd.ms-excel",
                    text: "xls",
                  },
                  {
                    key: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    text: "xlsx",
                  },
                  {
                    key: "application/msword",
                    text: "doc",
                  },
                  {
                    key: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    text: "docx",
                  },
                ]}  
                token={token}
                maxFileSize="20MB"
                endpointUrl={
                  apiConfig.prefixes.attachment + "UploadCertificateFile"
                }
              />
              {this.props.formData.auditNotSuitableAttachmentList &&
                this.props.formData.auditNotSuitableAttachmentList.length >
                0 && (
                  <React.Fragment>
                    <div className="title">Yüklenen Belgeler</div>
                    {JSON.stringify(
                      this.props.formData.initialauditNotSuitableAttachmentList
                    ) !==
                      JSON.stringify(
                        this.props.formData.auditNotSuitableAttachmentList
                      ) && (
                        <div className="file-save-warning">
                          Düzenlemeleri ve yüklenen belgeleri kaydetmek için, sağ
                          üst kısımdan "Kaydet veya Yayımla"ya basın.
                        </div>
                      )}
                    <div className="uploaded-files">
                      {(
                        this.props.formData?.auditNotSuitableAttachmentList ||
                        []
                      ).map(
                        (
                          item: {
                            attachmentId: number;
                            attachmentPath: string;
                            fileName: string;
                            fileSize: number;
                          },
                          index: number
                        ) => (
                          <div key={index} className="uploaded-file">
                            <div className="name row">
                              <span className="text col no-padding">
                                {item.fileName}
                                <strong className="size m-left-auto">
                                  {formatSize(item.fileSize)}
                                </strong>
                              </span>
                              <div className="buttons m-left-auto row">
                                <button
                                  onClick={() =>
                                    window.open(
                                      qrUrl + item.attachmentPath,
                                      "_blank"
                                    )
                                  }
                                  className="view"
                                >
                                  <i className="icon-explore"></i> Görüntüle
                                </button>
                                <button
                                  onClick={() => {
                                    let files = _.cloneDeep(
                                      this.props.formData
                                        ?.auditNotSuitableAttachmentList
                                    );
                                    let findIndex = (files || []).findIndex(
                                      (x: any) =>
                                        x.attachmentId === item.attachmentId
                                    );
                                    if (findIndex !== -1) {
                                      files.splice(findIndex, 1);
                                    }
                                    this.props.setFormData(
                                      "auditNotSuitableAttachmentList",
                                      files
                                    );
                                  }}
                                  className="remove"
                                >
                                  <i className="icon-close"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </React.Fragment>
                )}
            </div>
          )}
        </div>
      </div>
    );
  }
  showDialog(req: any): any {
    this.props.showErrorDialog(req);
  }
  onEditFacility() {
    this.props.Type == 5 && window.open("/hotel/edit/" + this.props.Id);
    this.props.Type == 6 && window.open("/Congress/edit/" + this.props.Id);
    this.props.Type == 7 && window.open("/SeaTourism/edit/" + this.props.Id);
    this.props.Type == 8 &&
      window.open("/ThemedFacility/edit/" + this.props.Id);
    this.props.Type == 9 && window.open("/WinterTourism/edit/" + this.props.Id);
  }
  render() {
    console.log(this.state,"render create")
    return (
      <div className="page push-all">
        <PageTitle
          title="Ek Denetim Ekle"
          titleRenderer={() => (
            <span className="edit-title">
              Yönet:
              <strong>Ek Denetim Ekle</strong>
            </span>
          )}
          showBack
          backUrl="/AdditionalControl/CreateType"
          history={this.props.history}
          subTitle="Yeni bir ek denetim eklemek için aşağıdaki alanları doldurunuz."
          right={
            <>
              <FormButtons
                isAbsolute
                onSubmit={() => this.onSubmit()}
                customRenderButtons={() => (
                  <React.Fragment>
                    <PrimaryButton
                      onClick={() => this.onSubmit()}
                      disabled={
                        this.state.messageType === MessageBarType.success ||
                          this.state.isFileUploading
                          ? this.state.isFileUploading
                            ? true
                            : false
                          : false
                      }
                      styles={{
                        rootDisabled: {
                          background: "#626262",
                          borderColor: "#777373 !important",
                        },
                      }}
                      iconProps={{ iconName: "CheckMark" }}
                      text="Yayımla"
                    />
                    {this.state.isFileUploading && (
                      <div className="tooltip-warning">
                        Bazı dosyalar yükleniyor, lütfen dosyalar yüklenene
                        kadar bekleyiniz.
                      </div>
                    )}
                  </React.Fragment>
                )}
              />
              <PrimaryButton
                onClick={() => this.onEditFacility()}
                className="AdditionalButton"
                styles={{
                  rootDisabled: {
                    background: "#ececec",
                    borderColor: "#ccc !important",
                    opacity: 0.5,
                  },
                }}
                text={"Tesis Bilgilerini Görüntüle"}
              />
            </>
          }
          rightType="create"
        />
        {this.renderMessageBar()}

        {this.renderForm()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateHotelCertification);
