import { HttpClient } from "src/library/HttpClient";
import {
  SeaTourismSaveRequestModel,
  GetSeaTourismListModel,
  SeaTourismUpdateRequestModel,
  SeaTourismGetByIdRequestModel,
  SeaTourismChangeStatusRequestModel,
  GetSeaTourismCertificateListBySeaTourismModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class SeaTourism {
  async getAllSeaTourism(request: GetSeaTourismListModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourism + "GetSeaTourismList",
      { ...request, token }
    );
  }

  async excelDownload(request: any, name: string) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.SeaTourism + "GenerateExcel",
      name,
      { ...request, token }
    );
  }

  async save(request: SeaTourismSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourism + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: SeaTourismUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourism + "SaveOrUpdate",
      { ...request, token }
    );
  }
  async GetSeaTourismCertificateListBySeaTourism(
    request: GetSeaTourismCertificateListBySeaTourismModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismCertification +
        "GetSeaTourismCertificateListBySeaTourism",
      { ...request, token }
    );
  }
  async getById(request: SeaTourismGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourism + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(
    request: SeaTourismChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourism + "ChangeStatus",
      { ...request, token }
    );
  }
}
