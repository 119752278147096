import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import {
  toggleLoader,
  showMessage,
  toggleConfirmModalDialog,
} from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import HotelCertification from "src/services/HotelCertification/HotelCertification";
import {
  HotelCertificationGetAllRequestModel,
  GetHotelCertificateListFourtyFourDay,
  HotelCertificationChangeStatusRequestModel,
  HotelCertificationGetByIdEndIdRequestModel,
  HotelCertificationGetByIdRequestModel,
  HotelCertificationUpdateRequestModel,
  HotelCertificationSaveRequestModel,
  RemovePublish,
  MergeHotelCertificate,
  HotelCertificationUpdateBySequenceIdRequestModel,
} from "src/services/HotelCertification/RequestModel";
import { MessageType } from "./system/types";
import Restaurant from "src/services/Restaurant/RestaurantService";
import { formatDate } from "src/helpers/converts";
import { errorMessageSaveOrUpdate } from "src/helpers/functions";
import { ILeaveCertificationProcessRequest } from "src/services/BaseEnum";

let cityRegions: any[] = [];

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllPage(
  request: HotelCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const HotelCertificationService = new HotelCertification();
    const result = await HotelCertificationService.getAll(request);
    dispatch(setFormData("excel", result));
    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: HotelCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const HotelCertificationService = new HotelCertification();
    const result = await HotelCertificationService.getAll(request);
    dispatch(initialFormData(result));

    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getComparisonAll(
  request: GetHotelCertificateListFourtyFourDay
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const HotelCertificationService = new HotelCertification();
    const result = await HotelCertificationService.getComparisonAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: HotelCertificationSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelCertificationService = new HotelCertification();
    const result = await HotelCertificationService.save(request);
    // dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return result;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}
export function update(
  request:
    | HotelCertificationUpdateRequestModel
    | HotelCertificationUpdateBySequenceIdRequestModel,
  sequence?: boolean
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const HotelCertificationService = new HotelCertification();
    const result = sequence
      ? await HotelCertificationService.updateBySequenceId(
          request as HotelCertificationUpdateBySequenceIdRequestModel
        )
      : await HotelCertificationService.update(request);
    // dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}
export function getById(
  request: HotelCertificationGetByIdRequestModel,
  sequence?: boolean
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelCertificationService = new HotelCertification();
    const result = sequence
      ? await HotelCertificationService.getBySequenceId(request)
      : await HotelCertificationService.getById(request);
    if (result.success) {
      dispatch(
        initialFormData({
          ...result.data,
          intitialAppealDate: result.data.appealDate,
          initialAttachmentList: result.data.attachmentList,
          initialAttachmentCertificateList:
            result.data.attachmentCertificateList,
          initialAttachmentUnCertificateList:
            result.data.attachmentUnCertificateList,
        })
      );
    } else {
      dispatch(initialFormData({}));
    }

    dispatch(toggleLoader());
    return result.success;
  };
}

export function getByEndId(
  request: HotelCertificationGetByIdEndIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelCertificationService = new HotelCertification();
    const result = await HotelCertificationService.getByEndId(request);
    dispatch(
      initialFormData({
        ...result.data,
        initialAttachmentList: result.data.attachmentList,
        initialAttachmentCertificateList: result.data.attachmentCertificateList,
        initialAttachmentUnCertificateList:
          result.data.attachmentUnCertificateList,
      })
    );
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: HotelCertificationChangeStatusRequestModel,
  isDelete: boolean = false,
  sequence?: boolean
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelCertificationService = new HotelCertification();
    const result = sequence
      ? await HotelCertificationService.changeStatusBySequenceId(request)
      : await HotelCertificationService.changeStatus(request);
    dispatch(toggleLoader());
    if (result.success) {
      // if (sequence && request.status === StatusEnums.Published) {
      //   return 2;
      // }else {
      dispatch(initialFormData(result));
      dispatch(
        showMessage(
          isDelete
            ? "Silme işlemi başarıyla değiştirildi."
            : "Kayıt durumu işlemi başarıyla değiştirildi.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
      // }
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}

export function excelDownloadHotel(
  request: HotelCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const excelName = "KonaklamaTesisleri_" + formatDate();

    const HotelCertificationService = new HotelCertification();
    await HotelCertificationService.excelDownload(request, excelName);

    dispatch(toggleLoader());

    return true;
  };
}

export function getAllHotelCertificationANDRestaurant(
  request: HotelCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    cityRegions = require("../cityRegions.json");

    let success = false;

    if (request["hotelExport"] && request["hotelExport"] === true) {
      const HotelCertificationService = new HotelCertification();
      const resultHotelCertification = await HotelCertificationService.getAll(
        request
      );
      if (resultHotelCertification.success) {
        success = true;
        for (
          let index = 0;
          index < resultHotelCertification.dataList.length;
          index++
        ) {
          resultHotelCertification.dataList[index]["region"] =
            resultHotelCertification.dataList[index].cityName &&
            resultHotelCertification.dataList[index].cityName !== ""
              ? cityRegions.filter(
                  (x: any) =>
                    x.city === resultHotelCertification.dataList[index].cityName
                )[0]?.region
              : "";
          resultHotelCertification.dataList[index]["categoryName"] =
            "KONAKLAMA TESİSİ";
          resultHotelCertification.dataList[index]["categoryName_En"] =
            "Accommodation Facilities";
        }
        dispatch(
          setFormData(
            "HotelCertificationListForExcel",
            resultHotelCertification
          )
        );
      }
    }

    let resultRestaurant: any = null;
    if (
      request["HotelCertificationSubCategoryId"] === 0 ||
      request["HotelCertificationSubCategoryId"] === null ||
      request["HotelCertificationSubCategoryId"] === undefined
    ) {
      const RestaurantService = new Restaurant();
      resultRestaurant = await RestaurantService.getAll(request);
      success = true;
      for (let index = 0; index < resultRestaurant.dataList.length; index++) {
        resultRestaurant.dataList[index]["region"] =
          resultRestaurant.dataList[index].cityName &&
          resultRestaurant.dataList[index].cityName !== ""
            ? cityRegions.filter(
                (x: any) => x.city === resultRestaurant.dataList[index].cityName
              )[0]?.region
            : "";

        resultRestaurant.dataList[index]["categoryName"] = "RESTORAN / KAFE";
        resultRestaurant.dataList[index]["categoryName_En"] =
          "Restaurant / Cafe";
      }
      dispatch(setFormData("RestaurantListForExcel", resultRestaurant));
    }

    dispatch(toggleLoader());

    return success;
  };
}

export function getAllForPager(
  request: HotelCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelCertificationService = new HotelCertification();
    const result = await HotelCertificationService.getAll(request);
    dispatch(setFormData("dataList", result.dataList));
    dispatch(setFormData("pageCount", result.pageCount));
    dispatch(setFormData("dataCount", result.dataCount));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function GetHotelCertificationAndRestaurantCount(
  request: HotelCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelCertificationService = new HotelCertification();
    const result =
      await HotelCertificationService.GetHotelCertificationAndRestaurantCount(
        request
      );
    dispatch(setFormData("GetHotelCertificateList", result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function RemoveWebPublish(
  request: RemovePublish
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelCertificationService = new HotelCertification();
    const result = await HotelCertificationService.RemoveWebPublish(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Sertifika Web Sitesinde Yayımdan Kaldırıldı",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}
export function RemoveQrPublish(
  request: RemovePublish
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelCertificationService = new HotelCertification();
    const result = await HotelCertificationService.RemoveQrPublish(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "QR Yayımdan Kaldırıldı",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}

export function ChangeCompanyUser(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelService = new HotelCertification();
    const result = await HotelService.ChangeCompanyUser(request);
    //dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          ""
        )
      );
      return false;
    }
  };
}

export function MergeHotel(
  request: MergeHotelCertificate
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelService = new HotelCertification();
    const result = await HotelService.MergeHotelCertificate(request);
    //dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success && result.errorCode !== 100 && request.isSaveApproved) {
      dispatch(
        showMessage(
          "Data aktarım işlemi başarı ile gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "",
          true
        )
      );
      return true;
    } else {
      if (result.errorCode === 100) {
        dispatch(
          toggleConfirmModalDialog(true, "Onayla", result.errorMessage, () =>
            dispatch(MergeHotel({ ...request, isSaveApproved: true }))
          )
        );
      } else if (result.errorCode === 0 && !request.isSaveApproved) {
        dispatch(MergeHotel({ ...request, isSaveApproved: true }));
      } else {
        dispatch(
          showMessage(
            result.errorMessage,
            "Bir Sorun Oluştu!",
            MessageType.ERROR,
            ""
          )
        );
      }
      return false;
    }
  };
}



export function leaveCertificationProcess(
  request: ILeaveCertificationProcessRequest
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelCertificationService = new HotelCertification();
    const result = await HotelCertificationService.leaveCertificationProcess(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "İşleminiz başarı ile gerçekleşmiştir.",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}