import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import _ from "lodash";
import {
  TextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Dropdown,
} from "office-ui-fabric-react";
import { setFormData, setFormErrors } from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";
import {
  getSystemUserById,
  onChangeSystemUserStatus,
  UpdateSystemUser,
} from "../../store/thunksSystemUser";
import {
  SystemUserGetByIdRequestModel,
  SystemUserChangeStatusModel,
  SystemUserUpdateRequestModel,
} from "src/services/SystemUser/RequestModel";
import { castThunkAction } from "src/helpers/casting";
import { StatusEnums } from "src/services/BaseEnum";
import BasicTable from "../BasicTable";
import { toggleConfirmDialog } from "src/store/system/actions";

interface OwnProps {
  history: History;
  SystemUserId: number;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        getSystemUserById,
        onChangeSystemUserStatus,
        UpdateSystemUser,
        toggleConfirmDialog
      },
      dispatch
    ),
  };
}
const keys = {
  "uid": "Kullanıcı Adı",
  'title': "İsim Soyisim",
  'password': "Parola",
  'systemUserType': "Kullanıcı Tipi",
  'prefix': "Sertifika No Prefix",
  'emailAddressList': "Eposta Adresi",
}
type EditClinicProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  messageType: MessageBarType;
}
let changesFiled: any[] = [];
let oldData: any = {};

class EditClinic extends Component<EditClinicProps, OwnState> {
  state = {
    messageType: MessageBarType.warning,
  };

  UNSAFE_componentWillMount() {

    if (this.props.SystemUserId) {
      castThunkAction(
        this.props.getSystemUserById({
          id: this.props.SystemUserId,
        } as SystemUserGetByIdRequestModel)
      ).then((res) => {
        Object.keys(oldData).length === 0 && (oldData = _.cloneDeep(this.props.formData))
      });
    }

  }

  renderTextByMessageType() {
    switch (this.state.messageType) {
      case MessageBarType.success:
        return "Kaydetme işlemi başarıyla gerçekleşmiştir.";
      case MessageBarType.error:
        return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
      default:
        return "Lütfen aşağıdaki bilgileri doldurun, ve oluşturmayı tamamlayın!";
    }
  }

  renderMessageBar() {
    return (
      <MessageBar messageBarType={this.state.messageType} isMultiline={false}>
        {this.renderTextByMessageType()}
      </MessageBar>
    );
  }

  onSave(isDraft?: boolean) {
    const request: SystemUserUpdateRequestModel = {
      data: {
        uid: this.props.formData.uid,
        systemUserId: this.props.SystemUserId,
        password: this.props.formData.password,
        title: this.props.formData.title,
        status: isDraft ? StatusEnums.Draft : StatusEnums.Published,
        systemUserType: this.props.formData.systemUserType,
        prefix: this.props.formData.prefix,
        email: null,
        emailAddressList: this.props.formData.emailAddressList,
      },
    };

    this.getChangesField().then((changesFiled: any) => {



      if (changesFiled && changesFiled.length > 0) {
        this.props.setFormData("changesFields", JSON.stringify(changesFiled));
        request.data["changesFields"] = JSON.stringify(changesFiled);
      }

      castThunkAction(this.props.UpdateSystemUser(request)).then(
        (response: any) => {
          this.setState(
            {
              messageType: response
                ? MessageBarType.success
                : MessageBarType.error,
            },
            () => {
              this.props.setFormData(
                "status",
                isDraft ? StatusEnums.Draft : StatusEnums.Published
              );
            }
          );
        }
      );


    });

  }
  getChangesField(): any {
    return new Promise((resolve: any, reject) => {
      changesFiled = []

      Object.keys(keys).forEach(key => {
        if (this.props.formData?.[key] === 'password' && this.props.formData.password && this.props.formData.password !== '') {
          changesFiled.push(key);
        } else {
          if (JSON.stringify(this.props.formData?.[key]) !== JSON.stringify(oldData?.[key])) {
            changesFiled.push(key);
          }
        }

      })

      resolve(changesFiled);
    });
  }
  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  onSubmit(isDraft?: boolean) {
    const { formData, setFormErrors } = this.props;
    const validationSchema = _yup.object({
      password: _yup
        .string()
        .matches(
          // eslint-disable-next-line
          // eslint-disable-next-line
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "En az bir büyük harf, bir küçük harf, bir sayı ve bir özel karakter olmalıdır"
        )
        .notRequired()
        .nullable(),
      title: _yup.string().required("Bu alan zorunludur!"),
      uid: _yup.string().required("Bu alan zorunludur!"),
    });
    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});
        if (this.props.formData.systemUserType === 2) {
          if (
            this.props.formData.prefix &&
            this.props.formData.prefix !== "" &&
            this.props.formData.prefix !== null
          ) {
            this.onSave(isDraft);
          } else {
            alert("Prefix Girmek Zorundasınız");
          }
        } else {
          this.onSave(isDraft);
        }
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  onChangeStatus(status: StatusEnums) {
    const request: SystemUserChangeStatusModel = {
      id: this.props.SystemUserId,
      status,
    };
    castThunkAction(this.props.onChangeSystemUserStatus(request))
  }
  addEmail() {
    //eslint-disable-next-lin
    const mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!mail.test(this.props.formData.newEmail)) {
      alert("Geçersiz e-Posta");
    } else {
      if (this.props.formData?.emailAddressList.length > 0 && this.props.formData?.emailAddressList?.indexOf(this.props.formData.newEmail) >= 0) {
        alert("Eklemeye çalıştığınız e-posta sisteme kayıtlıdır.")
      } else {
        const newArray = Object.assign(this.props.formData?.emailAddressList);
        newArray.push(this.props.formData.newEmail)
        this.props.setFormData("emailAddressList", newArray);
        this.props.setFormData("newEmail", "");
      }
    }
  }
  renderForm() {
    const { formData, formErrors, setFormData } = this.props;
    return (
      <div className="form-groups row">
        <div className="form-group col-md-4">
          <div className="title">Genel Bilgiler</div>
          <div className="form-wrapper">
            <div className="item row">
              <TextField
                placeholder="Kullanıcı Adınızı Giriniz"
                value={formData.uid || ""}
                label="Kullanıcı"
                className="custom-textfield border"
                errorMessage={formErrors.uid}
                onChange={(event: any) =>
                  setFormData("uid", event.target.value)
                }
              />
            </div>
            <div className="item row">
              <TextField
                placeholder="Adınızı Giriniz"
                value={formData.title || ""}
                label="İsim"
                className="custom-textfield border"
                errorMessage={formErrors.title}
                onChange={(event: any) =>
                  setFormData("title", event.target.value)
                }
              />
            </div>
            <div className="item row">
              <TextField
                type="password"
                placeholder="Şifre Giriniz"
                value={formData.password || ""}
                label="Şifre"
                className="custom-textfield border"
                autoComplete="new-password"
                resizable={false}
                errorMessage={formErrors.password}
                onChange={(event: any) =>
                  setFormData(
                    "password",
                    event.target.value === "" ? null : event.target.value
                  )
                }
              />
            </div>
            <div className="item row">
              <Dropdown
                onChanged={(event: any) =>
                  setFormData("systemUserType", event.key)
                }
                label="Kullanıcı Tipi"
                options={[
                  { key: 1, text: "Super Admin" },
                  { key: 2, text: "Firma" },
                  { key: 3, text: "TGA" },
                  { key: 4, text: "KTB" },
                  { key: 5, text: "Çağrı Merkezi" },
                ].map((item: any) => ({ key: item.key, text: item.text }))}
                className="custom-dropdown"
                selectedKey={this.props.formData.systemUserType}
              />
            </div>

            <div className="item row">
              <TextField
                placeholder="Sertifika No Prefix"
                value={formData.prefix || ""}
                label="Sertifika No Prefix"
                className="custom-textfield border"
                errorMessage={formErrors.prefix}
                onChange={(event: any) =>
                  setFormData("prefix", event.target.value)
                }
              />
            </div>

            <div className="item row">
              <label style={{ marginTop: "10px", fontSize: "11px", fontWeight: "bold", color: "#323130" }}>Kayıtlı E-Posta Adresleri</label>
              {this.props.formData?.emailAddressList?.map((x: any) => (
                <div className="newEmailDiv" key={x}>
                  <label className="newEmailLabel">{x}</label>
                  <button
                    className="removeEmailBtn"
                    onClick={() => {
                      if (this.props.formData.emailAddressList && this.props.formData.emailAddressList.length > 1) {
                        this.props.setFormData(
                          "emailAddressList",
                          this.props.formData.emailAddressList.filter(
                            (y: any) => y !== x
                          )
                        )
                      } else {
                        alert('En az bir eposta adresi zorunlu.')
                      }

                    }}
                  >
                    Sil
                  </button>
                </div>
              ))}
            </div>
            <div className="item row" style={{ width: "65%", marginTop: "20px" }}>
              <TextField
                placeholder="Yeni E-Posta Adresi Ekle"
                value={formData.newEmail || ""}
                label="Yeni E-Posta Adresi Ekle"
                className="custom-textfield border"
                errorMessage={formErrors.newEmail}
                type="email"
                onChange={(event: any) => {
                  setFormData("newEmail", event.target.value)
                }}
              />
            </div>
            <div className="item row">
              <button className="addEmailBtn" onClick={() => this.addEmail()} style={{
                marginLeft:'auto',
                marginTop: "-7%",
                backgroundColor: '#eb8f0e', justifySelf: 'flex-end'
              }}>
                e-Posta Ekle
              </button>
            </div>

          </div>
        </div>
      </div>
    );
  }

  renderStatusByType() {
    switch (this.props.formData.status) {
      case StatusEnums.Published:
        return (
          <div className="status">
            Durum: <strong>Yayımda</strong>
          </div>
        );
      case StatusEnums.Draft:
        return (
          <div className="status">
            Durum: <strong>Taslak</strong>
          </div>
        );
      case StatusEnums.Deleted:
        return (
          <div className="status">
            Durum: <strong>Silindi</strong>
          </div>
        );
      default:
        return null;
    }
  }

  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "updatedUsername",
            // columnName: "updatedUserTitle",
            text: "Değişikliği Yapan",
            col: "col-md-3",
          },

          {
            columnName: "changesField",
            text: "İşlem",
            col: "col-md-6",
            onRender: (item: { changesField: any }) => {



              try {
                const changesFieldParse = JSON.parse(item.changesField)
                if (changesFieldParse && changesFieldParse.length === 0) {
                  return ''
                }
                else if (changesFieldParse && changesFieldParse.length === 1) {
                  return (`${changesFieldParse
                    .map((f: string) => (Object.keys(keys).includes(f) ? keys[f] : f))
                    .join(', ')} alanında değişiklik yapıldı.`)
                } else {
                  return (`${changesFieldParse
                    .map((f: string) => (Object.keys(keys).includes(f) ? keys[f] : f))
                    .join(', ')} alanlarında değişiklik yapıldı.`)
                }

              } catch (e) {
                return `${keys[item.changesField]} alanında değişiklik yapıldı.`;
              }

            }

          },
          {
            // columnName: "updatedDatetimeString",
            columnName: "updatedDateTimeString",
            text: "Değişiklik Tarihi",
            col: "col-md-3",
          }
        ]}
        items={
          Array.isArray(this.props.formData?.changesHistory) ?
            (this.props.formData?.changesHistory?.filter((h: { changesField: string; }) => (h.changesField !== "[]")) || []) : [this.props.formData?.changesHistory]
        }
      />
    );
  }
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Kullanıcı Düzenle"
          titleRenderer={() => (
            <span className="edit-title">
              Düzenle:
              <strong>{this.props.formData?.title || ""}</strong>
            </span>
          )}
          showBack
          backUrl="/systemuser-management"
          history={this.props.history}
          subTitle="Kişi bilgilerini düzenlemek için  aşağıdaki bilgileri doldurunuz."
          right={<FormButtons
            isAbsolute
            onSubmit={() => this.onSubmit()}
            customRenderButtons={() => (
              <React.Fragment>
                {this.props.formData.status !== StatusEnums.Deleted && (
                  <PrimaryButton
                    onClick={() => {
                      this.props.toggleConfirmDialog(
                        true,
                        "Silme işlemini gerçekleştirmek istiyor musunuz?",
                        () => this.onChangeStatus(StatusEnums.Deleted)
                      )
                    }}
                    iconProps={{ iconName: "Trash" }}
                    text="Sil"
                    styles={{
                      root: {
                        background: "#ac6262",
                        borderColor: "#ac6262",
                      },
                      rootHovered: {
                        background: "#a34c4c",
                        borderColor: "#a34c4c",
                      },
                      rootPressed: {
                        background: "#a34c4c",
                        borderColor: "#a34c4c",
                      },
                      rootDisabled: {
                        background: "#ececec",
                      },
                    }}
                  />
                )}
                {
                  this.props.formData.status === StatusEnums.Published ? <PrimaryButton
                    onClick={() => this.onSubmit()}
                    styles={{
                      rootDisabled: {
                        background: "#626262",
                        borderColor: "#777373 !important",
                      },
                    }}
                    iconProps={{ iconName: "CheckMark" }}
                    text={"Kaydet"}
                  />
                    : <PrimaryButton
                      onClick={() => this.onChangeStatus(StatusEnums.Published)}
                      styles={{
                        rootDisabled: {
                          background: "#626262",
                          borderColor: "#777373 !important",
                        },
                      }}
                      iconProps={{ iconName: "CheckMark" }}
                      text={"Yayımla"
                      }
                    />
                }

                {this.renderStatusByType()}
              </React.Fragment>
            )}
          />}
          rightType="create"
        />
        {this.renderMessageBar()}

        {this.renderForm()}
        {this.props.formData && this.props.formData.changesHistory && this.props.formData.changesHistory.length > 0 && this.renderList()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditClinic);
