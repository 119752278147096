import { HttpClient } from "src/library/HttpClient";
import {
  CertificateStatusTypeGetAllRequestModel,
  CertificateStatusTypeSaveRequestModel,
  CertificateStatusTypeUpdateRequestModel,
  CertificateStatusTypeGetByIdRequestModel,
  CertificateStatusTypeChangeStatusRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class CertificateStatusType {
  async getAll(request: CertificateStatusTypeGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.certificateStatusType + "GetAll",
      { ...request, token }
    );
  }

  async save(request: CertificateStatusTypeSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.certificateStatusType + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: CertificateStatusTypeUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.certificateStatusType + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(
    request: CertificateStatusTypeGetByIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.certificateStatusType + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(
    request: CertificateStatusTypeChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.certificateStatusType + "ChangeStatus",
      { ...request, token }
    );
  }
}
