import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { panels } from "src/utils/constants";
import { Link } from "react-router-dom";
import { History } from "history";
import BasicTable from "../BasicTable";
import {
  TextField,
  Dropdown,
  PrimaryButton,
  Modal,
  Icon,
  DefaultButton,
} from "office-ui-fabric-react";
import {
  getAllHotel,
  getDeletedHotelList,
  changeStatus,
  getAllForPager,
  getAllHotelForExcel,
  getDeleteAllForPager,
} from "src/store/thunkHotel";
import { MergeHotel } from "src/store/thunkHotelCertification";
import {
  GetHotelListModel,
  HotelChangeStatusRequestModel,
} from "src/services/Hotel/RequestModel";

import { MergeHotelCertificate } from "src/services/HotelCertification/RequestModel";

import { StatusEnums } from "src/services/BaseEnum";
import { castThunkAction } from "src/helpers/casting";
import { toggleConfirmDialog,toggleConfirmFilterRemoveDialog } from "src/store/system/actions";

import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import { getAllCR } from "src/store/thunkCertificateStatusType";
import { getCompanyUserList } from "src/store/thunksSystemUser";

import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";

import { getAllLSU } from "src/store/thunkLayoverSubCategory";

import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";
import cookie from "react-cookies";

import { LayoverSubCategoryGetAllRequestModel } from "src/services/LayoverSubCategory/RequestModel";

import { CertificateStatusTypeGetAllRequestModel } from "src/services/CertificateStatusType/RequestModel";
import { filterModal, RECORD_STATUS_ENUM } from "src/helpers/functions";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        getAllHotel,
        changeStatus,
        toggleConfirmDialog,
        toggleConfirmFilterRemoveDialog,
        getAllM,
        getAllC,
        getAllLSU,
        getAllCR,
        getCompanyUserList,
        getAllHotelForExcel,
        getAllForPager,
        getDeletedHotelList,
        getDeleteAllForPager,
        MergeHotel,
      },
      dispatch
    ),
  };
}

type LayoverProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  pagerTotal: number;
  download: boolean;
  excel: boolean;
  hotelId: number;
  hotelName: string;
  status: number;
  oldVersionHotelId: number;
  oldVersionHotelName: string;
  section: number;
  modalShow: boolean;
}

const intitalState = {
  section: 1,
  hotelId: 0,
  status: 0,
  oldVersionHotelId: 0,
  modalShow: false,
  filterData: {
    isApproved: null,
    searchKey: "",
    districtId: 0,
    status: 1,
    cityId: 0,
    layoverSubCategoryId: 0,
    companyUserId: 0,
    correctionType: null,
    orderField: "UpdatedSystemUserDatetime",
    isDescending: true,
    hotelId: 0,
    documentNo: null,
    isHaveBusinessCertificate: null,
    roomCount: 1,
    certificateStatusTypeId: 0,
  },

  pagerCurrent: 0,
  pagerTotal: 0,
};
class Layover extends Component<LayoverProps, OwnState> {
  state: any = intitalState;

  componentDidMount() {
    filterModal(this, "mergeListHotelState").finally(() =>
      this.getData(this.state.pagerCurrent)
    );
  }

  getData(pagerSelected: number) {
    const request: GetHotelListModel = {
      pageSize: 5,
      pageIndex: pagerSelected,
      ...this.state.filterData,
    };

    request["isPublishQr"] =
      request["isPublishQr"] === "1"
        ? true
        : request["isPublishQr"] === "2"
        ? false
        : null;

    castThunkAction(this.props.getAllHotel(request)).then((response: any) => {
      const request2: CityGetAllRequestModel = {
        selectItemList: ["CityId", "CityName"],
        orderField: "CityName",
      };

      this.props.getAllM(request2);
      const requestCr: CertificateStatusTypeGetAllRequestModel = {
        criteriaItemList: [
          {
            propertyName: "Status",
            value: 1,
            isOr: false,
            conditionOperator: 0,
          },
        ],
        selectItemList: ["CertificateStatusTypeId", "Title"],
      };
      this.props.getAllCR(requestCr);
      const request3: LayoverSubCategoryGetAllRequestModel = {
        criteriaItemList: [
          {
            propertyName: "Status",
            value: 1,
            isOr: false,
            conditionOperator: 0,
          },
        ],
        selectItemList: ["LayoverSubCategoryId", "LayoverSubCategoryName"],
      };
      this.props.getAllLSU(request3);

      const request6: SystemUserGetAllModel = {
        systemUserType: 2,
      };
      this.props.getCompanyUserList(request6);

      this.state.filterData.cityId !== 0 &&
        this.state.filterData.cityId !== null &&
        this.CityChange(this.state.filterData.cityId);
    });
  }

  getDeleteData(pagerSelected: number) {
    const request: GetHotelListModel = {
      pageSize: 5,
      pageIndex: pagerSelected,
      ...this.state.filterData,
    };

    request["isPublishQr"] =
      request["isPublishQr"] === "1"
        ? true
        : request["isPublishQr"] === "2"
        ? false
        : null;

    castThunkAction(this.props.getDeletedHotelList(request)).then(
      (response: any) => {
        const request2: CityGetAllRequestModel = {
          selectItemList: ["CityId", "CityName"],
          orderField: "CityName",
        };

        this.props.getAllM(request2);

        const request3: LayoverSubCategoryGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "Status",
              value: 1,
              isOr: false,
              conditionOperator: 0,
            },
          ],
          selectItemList: ["LayoverSubCategoryId", "LayoverSubCategoryName"],
        };
        this.props.getAllLSU(request3);

        const request6: SystemUserGetAllModel = {
          systemUserType: 2,
        };
        this.props.getCompanyUserList(request6);

        this.state.filterData.cityId !== 0 &&
          this.state.filterData.cityId !== null &&
          this.CityChange(this.state.filterData.cityId);
      }
    );
  }
  getDataPager(pagerSelected: number) {
    this.setState({ excel: false });
    const request: GetHotelListModel = {
      pageSize: 5,
      pageIndex: pagerSelected,
      ...this.state.filterData,
    };

    request["isPublishQr"] =
      request["isPublishQr"] === "1"
        ? true
        : request["isPublishQr"] === "2"
        ? false
        : null;

    this.props.getAllForPager(request);
  }

  getDeleteDataPager(pagerSelected: number) {
    this.setState({ excel: false });
    const request: GetHotelListModel = {
      pageSize: 5,
      pageIndex: pagerSelected,
      ...this.state.filterData,
    };

    request["isPublishQr"] =
      request["isPublishQr"] === "1"
        ? true
        : request["isPublishQr"] === "2"
        ? false
        : null;

    this.props.getDeleteAllForPager(request);
  }

  onDelete(LayoverId: number) {
    const request: HotelChangeStatusRequestModel = {
      id: LayoverId,
      status: StatusEnums.Deleted,
    };
    castThunkAction(this.props.changeStatus(request)).then((response: any) => {
      this.getData(this.state.pagerCurrent);
    });
  }

  renderDashboardItems() {
    return (
      <div className="dashboard-items row">
        {panels.map(
          (item, index) =>
            item.children && (
              <React.Fragment>
                <div className="title">
                  {item.text}
                  <div className="title-text">{item.subText}</div>
                </div>
                <div className="childrens row">
                  {item.children.map((children, childrenIndex) => (
                    <div key={index} className={`dashboard-item col-md-3`}>
                      <Link
                        className="row"
                        to={children.url ? children.url : "/"}
                      >
                        <div className="icon">
                          <i className={children.icon}></i>
                        </div>
                        <div className="title">
                          {children.text}
                          <div className="sub-text">{children.subText}</div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )
        )}
      </div>
    );
  }
  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Tümünü Seç" });
    }
    if (
      key === "CertificateStatusTypeId" &&
      newArray.filter((x) => x[key] === 9).length === 0
    ) {
      newArray.push({ [key]: 9, [text]: "Sertifikasız" });
    }
    return newArray;
  }

  CityChange(item: any) {
    this.setState(
      {
        filterData: {
          ...this.state.filterData,
          cityId: item,
        },
      },
      () => {
        const request2: CountyGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "cityId",
              value: item,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
            {
              propertyName: "Status",
              value: 1,
              isOr: false,
              conditionOperator: 0,
            },
          ],
          selectItemList: ["DistrictId", "DistrictName"],
          orderField: "DistrictName",
        };
        this.props.getAllC(request2);
      }
    );
  }

  Merge(isSaveApproved:boolean = false) {
    this.setState({ modalShow: false });
    const request2: MergeHotelCertificate = {
      hotelId: this.state.hotelId,
      oldVersionHotelId: this.state.oldVersionHotelId,
      isSaveApproved
    };
    this.props.MergeHotel(request2);
  }

  renderFilter() {
    return (
      <div className="table-filter row">
        <Dropdown
          options={[
            { key: 0, text: "Tümü" },
            { key: 4, text: "TC Kültür ve Turizm Bakanlığı" },
            { key: 5, text: "Diğer" },
          ]}
          className="custom-dropdown col-md-2"
          label="Tesis Belge Tipi"
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                certificateTypeId: option.key === 2 ? null : option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <TextField
          className="custom-textfield col-md-3"
          label="Tesis Adı"
          value={this.state.filterData?.searchKey || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                searchKey: event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        <TextField
          className="custom-textfield col-md-1"
          label="ID"
          value={this.state.filterData?.hotelId || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                hotelId: event.target.value === "" ? 0 : event.target.value,
              },
            });
          }}
        />
        <TextField
          className="custom-textfield col-md-2"
          label="Belge Sahibi / Ticari Ünvan"
          value={this.state.filterData?.companyName || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                companyName:
                  event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        <Dropdown
          defaultSelectedKey={this.state.filterData?.cityId || ""}
          onChanged={(event: any) => {
            this.CityChange(event.key);
            this.setState({
              filterData: {
                ...this.state.filterData,
                cityId: event.key,
              },
            });
          }}
          label="İl"
          options={(this.props.formData.GroupList?.dataList
            ? this.returnOptions(
                this.props.formData.GroupList?.dataList,
                "CityId",
                "CityName"
              )
            : ([] as any)
          ).map((item: any) => ({ key: item.CityId, text: item.CityName }))}
          className="custom-dropdown col-md-2"
        />
        <Dropdown
          defaultSelectedKey={this.state.filterData?.districtId || ""}
          onChanged={(event: any) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                districtId: event.key,
              },
            })
          }
          label="İlçe"
          options={(this.props.formData.GroupListC?.dataList
            ? this.returnOptions(
                this.props.formData.GroupListC?.dataList,
                "DistrictId",
                "DistrictName"
              )
            : ([] as any)
          ).map((item: any) => ({
            key: item.DistrictId,
            text: item.DistrictName,
          }))}
          className="custom-dropdown col-md-2"
        />
        <Dropdown
          defaultSelectedKey={this.state.filterData?.layoverSubCategoryId || ""}
          onChanged={(event: any) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                layoverSubCategoryId: event.key,
              },
            })
          }
          label="Tesis Kategorisi"
          options={(this.props.formData.GroupListSU?.dataList
            ? this.returnOptions(
                this.props.formData.GroupListSU?.dataList,
                "LayoverSubCategoryId",
                "LayoverSubCategoryName"
              )
            : ([] as any)
          ).map((item: any) => ({
            key: item.LayoverSubCategoryId,
            text: item.LayoverSubCategoryName,
          }))}
          className="custom-dropdown col-md-2"
        />
        <Dropdown
          options={[
            { key: 0, text: "Seçiniz" },
            { key: 1, text: "İşletme Belgesi Yüklü" },
            { key: 2, text: "İşletme Belgesi Yok" },
          ]}
          className="custom-dropdown col-md-2"
          label="İşletme Belgesi"
          placeholder="Seçiniz"
          selectedKey={
            this.state.filterData?.isHaveBusinessCertificate === true
              ? 1
              : this.state.filterData?.isHaveBusinessCertificate === false
              ? 2
              : 0 || null
          }
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isHaveBusinessCertificate:
                  option.key === 1 ? true : option.key === 2 ? false : null,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: "BrandName", text: "Tesis Adı" },
            { key: "CreatedSystemUserDatetime", text: "Oluşturma Tarihi" },
            { key: "UpdatedSystemUserDatetime", text: "Güncelleme Tarihi" },
          ]}
          className="custom-dropdown col-md-2"
          label="Sıralama Kriteri"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.orderField || null}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                orderField: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={(this.state.filterData.orderField === "BrandName"
            ? [
                { key: false, text: "Baştan Sona" },
                { key: true, text: "Sondan Başa" },
              ]
            : [
                { key: false, text: "Eskiden Yeniye" },
                { key: true, text: "Yeniden Eskiye" },
              ]
          ).map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-2"
          label="Sıralama Türü"
          selectedKey={this.state.filterData?.isDescending}
          placeholder="Seçiniz"
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isDescending: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={RECORD_STATUS_ENUM}
          className="custom-dropdown col-md-2"
          label="Kayıt Durumu"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.status || 1}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                status: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: 2, text: "Tümü" },
            { key: 1, text: "Onaylanmış" },
            { key: 0, text: "Onaylanmamış" },
          ]}
          className="custom-dropdown col-md-2"
          label="Tesis Onay Durumu"
          selectedKey={
            this.state.filterData?.isApproved === 0
              ? 0
              : this.state.filterData?.isApproved === 1
              ? 1
              : 2 || 2
          }
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isApproved: option.key === 2 ? null : option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <TextField
          className="custom-textfield col-md-2"
          label="Belge No"
          value={this.state.filterData?.documentNo || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                documentNo: event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        <Dropdown
          options={[
            { key: 1, text: "Tümü" },
            { key: 2, text: "30 Oda ve Altı" },
            { key: 3, text: "30 ile 49 Oda Arası" },
            { key: 4, text: "50 Oda ve Üstü" },
          ]}
          className="custom-dropdown col-md-2"
          label="Tesis Kapasitesi"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.roomCount || null}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                roomCount: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={(this.props.formData.GroupListCR?.dataList
            ? this.returnOptions(
                this.props.formData.GroupListCR?.dataList,
                "CertificateStatusTypeId",
                "Title"
              )
            : ([] as any)
          ).map((item: any) => ({
            key: item.CertificateStatusTypeId,
            text: item.Title,
          }))}
          className="custom-dropdown col-md-2"
          label="Sertifika Durumu"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData.certificateStatusTypeId}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                certificateStatusTypeId: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <PrimaryButton
          text="Arama Yap"
          className="custom-button"
          onClick={() => {
            cookie.save("mergeListHotelState", JSON.stringify(this.state), {
              path: "/",
            });
            this.getDataPager(0);
          }}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />

        <PrimaryButton
          text="Tüm Filtreleri Temizle"
          className="custom-button"
          iconProps={{ iconName: "trash" }}
          onClick={() =>
            this.setState(intitalState, () => {
              cookie.save("mergeListHotelState", JSON.stringify(this.state), {
                path: "/",
              });
              this.getDataPager(0);
            })
          }
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
              background: "#FF4343",
              borderColor: "#FF4350",
            },
          }}
        />
        <PrimaryButton
          text="Aktarılmış Otel Kayıtlarını Geri Aktar"
          className="custom-button"
          onClick={() => this.props.history.push("/HotelTransferBack")}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
      </div>
    );
  }

  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "hotelId",
            text: "ID",
            col: "col-md-1",
          },
          {
            columnName: "cityDistrictName",
            text: "İl / İlçe",
            col: "col-md-2",
          },
          {
            columnName: "companyName",
            text: "Belge Sahibi / Ticari Ünvan",
            col: "col-md-3",
          },

          {
            columnName: "brandName",
            text: "Tesis Adı",
            col: "col-md-3",
          },

          {
            columnName: "certificateStatusTypeName",
            text: "Sertifika Durumu",
            col: "col-md-2",
          },

          {
            columnName: "hotelId",
            text: "İşlem",
            col: "col-md-1",
            className: "operations",
            onRender: (item: {
              hotelId: any;
              Status: StatusEnums;
              brandName: string;
              hotelCertificateId: any;
            }) => (
              <React.Fragment>
                <button
                  onClick={(x) =>
                    window.open(
                      "/hotel/edit/" + item.hotelId
                    )
                  } style={{
                    backgroundColor:"#eb8f0e"

                  }}
                >
                  <i className="icon-edit-outline"></i> Detay
                </button>
                {
                  <button
                    disabled={item.hotelId === this.state.oldVersionHotelId}
                    className={
                      item.hotelId === this.state.oldVersionHotelId
                        ? "disabled"
                        : ""
                    }
                    style={{
                      backgroundColor:
                        this.state.section === 1
                          ? this.state.oldVersionHotelId === item.hotelId
                            ? "green"
                            : "#0078d4"
                          : this.state.hotelId === item.hotelId
                          ? "green"
                          : "#0078d4",
                    }}
                    onClick={(x) =>
                      this.state.section === 1
                        ? this.setState({
                            oldVersionHotelId: item.hotelId,
                            oldVersionHotelName: item.brandName,
                          })
                        : this.setState({
                            hotelId: item.hotelId,
                            hotelName: item.brandName,
                          })
                    }
                  >
                    <i className="icon-edit-outline"></i> Seç
                  </button>
                }
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.dataList || []}
      />
    );
  }

  goEdit(hotelId: any): any {
    window.open("/hotel/edit/" + hotelId);
  }

  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.props.formData.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }
  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getDataPager(selected);
  };
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Otel Kayıtları Aktarımı"
          subTitle="Buradan Otel Kayıtlarınızı Başka Bir Otele Aktarabilirsiniz"
          backUrl="/"
          showBack
          history={this.props.history}
        />
        {this.state.section === 1 ? (
          <h3>** Dataları Aktarılacak Oteli Seçiniz (Silinecek)</h3>
        ) : (
          <h3>** Data Aktarılacak Oteli Seçiniz (Esas Kayıt Olacak)</h3>
        )}
        {
          <h3>
            {this.state.oldVersionHotelName &&
              "Dataları Aktarılacak Otel (Silinecek): " +
                this.state.oldVersionHotelName +
                " ID=" +
                this.state.oldVersionHotelId}
            {this.state.hotelName &&
              " => Data Aktarılacak Otel (Esas Kayıt Olacak): " +
                this.state.hotelName +
                " ID=" +
                this.state.hotelId}
          </h3>
        }

        {this.renderFilter()}
        {this.renderList()}
        {this.renderPager()}

        {this.state.oldVersionHotelId !== 0 && this.state.section === 1 && (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <React.Fragment>
              {
                <PrimaryButton
                  text="Aktarım Yapılacak Otelin Seçim Ekranına Git"
                  className="custom-button"
                  onClick={(x) =>
                    this.setState(
                      {
                        section: 2,
                        filterData: {
                          isApproved: null,
                          searchKey: "",
                          districtId: 0,
                          status: 1,
                          cityId: 0,
                          layoverSubCategoryId: 0,
                          companyUserId: 0,
                          correctionType: null,
                          orderField: "UpdatedSystemUserDatetime",
                          isDescending: true,
                          hotelId: 0,
                          documentNo: null,
                          isHaveBusinessCertificate: null,
                          roomCount: 1,
                          certificateStatusTypeId: 0,
                        },
                      },
                      () => {
                        this.getData(0);
                      }
                    )
                  }
                  style={{ marginLeft: "15px" }}
                  styles={{
                    root: {
                      fontSize: "16px",
                      marginTop: "27px",
                      height: "30px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    },
                  }}
                />
              }
            </React.Fragment>
          </div>
        )}

        {this.state.hotelId !== 0 && this.state.section === 2 && (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <React.Fragment>
              {
                <PrimaryButton
                  text="Aktarımı Gerçekleştir"
                  className="custom-button"
                  onClick={() => this.setState({ modalShow: true })}
                  style={{ marginLeft: "15px" }}
                  styles={{
                    root: {
                      fontSize: "16px",
                      marginTop: "27px",
                      height: "30px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    },
                  }}
                />
              }
            </React.Fragment>
          </div>
        )}
        {this.state.modalShow && (
          <Modal className={"warning custom-message-box danger"} isOpen={true}>
            <div className="modal-header row">
              <div className="title">Uyarı!</div>
              <span
                onClick={() => this.setState({ modalShow: false })}
                className="close"
              >
                <Icon iconName="ChromeClose" />
              </span>
            </div>
            <div
              style={{ paddingBottom: 0 }}
              className="description modal-content"
            >
              <div className="modal-indicators">
                <h3>
                  {this.state.oldVersionHotelName &&
                    "Dataları Aktarılacak Otel (Silinecek): " +
                      this.state.oldVersionHotelName +
                      " ID=" +
                      this.state.oldVersionHotelId}
                </h3>
                <h3>
                  {" "}
                  {this.state.hotelName &&
                    "=> Data Aktarılacak Otel (Esas Kayıt Olacak): " +
                      this.state.hotelName +
                      " ID=" +
                      this.state.hotelId}
                </h3>

                <h2>Onaylıyor musunuz?</h2>
                <DefaultButton
                  style={{ marginTop: 15, marginRight: 10 }}
                  text="Evet"
                  onClick={() => {
                    this.Merge();
                  }}
                />
                <DefaultButton
                  style={{ marginTop: 15 }}
                  text="Hayır"
                  onClick={() => {
                    this.setState({ modalShow: false });
                  }}
                />
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
//yorumdamı

export default connect(mapStateToProps, mapDispatchToProps)(Layover);
