import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { showMessage, toggleLoader } from "./system/actions";
import { initialFormData } from "./form/actions";
import HotelTransferBackCertification from "src/services/HotelTransferBackCertification/HotelTransferBackCertification";
import {
  HotelTransferBackCertificationRequestGetMergedCertificateListModel,
  HotelTransferBackCertificationRequestRestoreMergedHotelCertificateModel,
} from "src/services/HotelTransferBackCertification/RequestModel";
import { MessageType } from "./system/types";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getMergedCertificateList(
  request: HotelTransferBackCertificationRequestGetMergedCertificateListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const HotelTransferBackCertificationService =
      new HotelTransferBackCertification();
    const result =
      await HotelTransferBackCertificationService.getMergedCertificateList(
        request
      );
    dispatch(initialFormData(result));
    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function restoreMergedHotelCertificate(
  request: HotelTransferBackCertificationRequestRestoreMergedHotelCertificateModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const HotelTransferBackCertificationService =
      new HotelTransferBackCertification();
    const result =
      await HotelTransferBackCertificationService.restoreMergedHotelCertificateList(
        request
      );
    dispatch(toggleLoader());

    if (result.success && result.errorCode === 0) {

      dispatch(
        showMessage(
          "Data geri aktarım başarıyla tamamlanmıştır.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          "Otel Kayıtları Aktarımı"
        )
      );

      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Uyarı!",
          MessageType.ERROR,
          "Otel Kayıtları Aktarımı"
        )
      );

      return false;
    }
  };
}
