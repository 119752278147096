import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { panels } from "src/utils/constants";
import { Link } from "react-router-dom";
import { History } from "history";
import BasicTable from "../BasicTable";
import {
  TextField,
  Dropdown,
  PrimaryButton,
  Label,
} from "office-ui-fabric-react";
import {
  getSystemUserList,
  onChangeSystemUserStatus,
} from "src/store/thunksSystemUser";
import {
  SystemUserGetAllModel,
  SystemUserChangeStatusModel,
} from "src/services/SystemUser/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";
import { castThunkAction } from "src/helpers/casting";
import Confirm from "../Confirm";
import { toggleConfirmDialog } from "src/store/system/actions";

import { listCount } from "src/config/otherConfig";
import ReactPaginate from "react-paginate";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        getSystemUserList,
        onChangeSystemUserStatus,
        toggleConfirmDialog,
      },
      dispatch
    ),
  };
}

type SystemUserManagementProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  pagerTotal: number;
}

class SystemUserManagement extends Component<
  SystemUserManagementProps,
  OwnState
> {
  state = {
    filterData: {
      title: "",

      systemUserType: 1,
      status: 1,
    },
    pagerCurrent: 0,
    pagerTotal: 0,
  };

  componentDidMount() {
    this.getData(this.state.pagerCurrent);
  }
  

  getData(pagerSelected: number) {
    const request: SystemUserGetAllModel = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      title: this.state.filterData.title,
      status: this.state.filterData.status,
      systemUserType: this.state.filterData.systemUserType,
    };

    castThunkAction(this.props.getSystemUserList(request));
  }

  onDelete(SystemUserId: number) {
    const request: SystemUserChangeStatusModel = {
      id: SystemUserId,
      status: StatusEnums.Deleted,
    };
    castThunkAction(this.props.onChangeSystemUserStatus(request)).then(
      (response: any) => {
        this.getData(this.state.pagerCurrent);
      }
    );
  }
  filter() {
    this.setState(
      {
        filterData: {
          title: "",

          systemUserGroupId: 0,
          status: 1,
        },
        pagerCurrent: 0,
        pagerTotal: 0,
      },
      () => this.getData(this.state.pagerCurrent)
    );
  }
  renderDashboardItems() {
    return (
      <div className="dashboard-items row">
        {panels.map(
          (item, index) =>
            item.children && (
              <React.Fragment>
                <div className="title">
                  {item.text}
                  <div className="title-text">{item.subText}</div>
                </div>
                <div className="childrens row">
                  {item.children.map((children, childrenIndex) => (
                    <div key={index} className={`dashboard-item col-md-3`}>
                      <Link
                        className="row"
                        to={children.url ? children.url : "/"}
                      >
                        <div className="icon">
                          <i className={children.icon}></i>
                        </div>
                        <div className="title">
                          {children.text}
                          <div className="sub-text">{children.subText}</div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )
        )}
      </div>
    );
  }

  renderFilter() {
    return (
      <div className="table-filter row">
        <TextField
          className="custom-textfield col-md-3"
          label="Başlık İle Arama"
          value={this.state.filterData?.title || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                title: event.target.value !== "" ? event.target.value : "",

              },
            });
          }}
        />
        <Dropdown
          selectedKey={this.state.filterData?.systemUserType || ""}
          onChanged={(event: any) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                systemUserType: event.key,
              },
            })
          }
          label="Kullanıcı Tipi"
          options={[
            { key: 1, text: "Super Admin" },
            { key: 2, text: "Firma" },
            { key: 3, text: "TGA" },
            { key: 4, text: "KTB" },
            { key: 5, text: "Çağrı Merkezi" },
          ].map((item: any) => ({ key: item.key, text: item.text }))}
          className="custom-dropdown col-md-3"
        />
        <Dropdown
          options={[
            { key: 1, text: "Yayımda" },
            // { key: 2, text: "Taslak" },
            { key: 8, text: "Silindi" },
          ]}
          className="custom-dropdown col-md-3"
          label="Duruma Göre Arama"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.status || 1}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                status: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <PrimaryButton
          text="Arama Yap"
          className="custom-button"
          onClick={() => this.getData(0)}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
        <PrimaryButton
          text="Fitreyi Temizle"
          className="custom-button clearFilter"
          onClick={() => this.filter()}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
      </div>
    );
  }
  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.props.formData.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }
  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getData(selected);
  };
  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "title",
            text: "İsim",
            col: "col-md-2",
          },
          {
            columnName: "uid",
            text: "Kullanıcı Adı",
            col: "col-md-2",
          },
          {
            columnName: "createdUserName",
            text: "Kayıt Yapan",
            col: "col-md-2",
          },
          {
            columnName: "createDateString",
            text: "Kayıt Tarihi",
            col: "col-md-1",
          },

          {
            columnName: "updatedUserName",
            text: "Güncelleme Yapan",
            col: "col-md-2",
          },

          {
            columnName: "updateDateString",
            text: "Güncelleme Tarihi",
            col: "col-md-1",
          },
          {
            columnName: "systemUserId",
            text: "İşlem",
            col: "col-md-2",
            className: "operations",
            onRender: (item: { systemUserId: any; Status: StatusEnums }) => (
              <React.Fragment>
                {
                  <button
                    onClick={(x) =>
                      (window.location.href = `/systemuser-management/edit/${item.systemUserId}`)
                    }
                  >
                    <i className="icon-edit-outline"></i> Düzenle
                  </button>
                }

                {item.Status !== StatusEnums.Deleted && (
                  <button
                    onClick={() =>
                      this.props.toggleConfirmDialog(true, "Silme işlemini gerçekleştirmek istiyor musunuz?", () =>
                        this.onDelete(item.systemUserId)
                      )
                    }
                  >
                    <i className="icon-trash-outline"></i> Sil
                  </button>
                )}
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.dataList || []}
      />
    );
  }

  render() {
    return (
      <div className="page push-all">
        <Confirm />
        <PageTitle
          title="Sistem Kullanıcıları Yönetimi"
          subTitle="Yönetilebilecek tüm alanları aşağıdan görüntüleyebilirsiniz."
          backUrl="/"
          showBack
          history={this.props.history}
        />
        <PrimaryButton
          onClick={() =>
            this.props.history.push("/systemuser-management/create")
          }
          iconProps={{ iconName: "Add" }}
          className="absolute-create"
          text="Sistem Kullanıcısı Ekle"
          styles={{
            root: {
              background: "#62a98e",
              borderColor: "#5b9e85",
            },
            rootHovered: {
              background: "#5b9e85",
              borderColor: "#5b9e85",
            },
            rootDisabled: {
              background: "#ececec",
            },
          }}
        />
        <Label className="totalRecord">
          Toplam Kayıt Sayısı: {this.props.formData?.dataCount}
        </Label>
        {this.renderFilter()}
        {this.renderList()}
        {this.renderPager()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemUserManagement);
