import { StatusEnums } from "../BaseEnum";

export interface CountySaveDataModel {
  cityId: number;
  districtName: string;
  status: StatusEnums;
}
export interface CountySaveRequestModel {
  data: CountySaveDataModel;
}

export interface CountyUpdateDataModel {
  countyId: number;
  cityId: number;
  districtName: string;
  status: StatusEnums;
}

export interface CountyUpdateRequestModel {
  data: CountyUpdateDataModel;
}

export interface CountyChangeStatusRequestModel {
  status: StatusEnums;
  id: number;
}

export enum ConditionOperatorEnum {
  Eq = 0,
  NotEq = 1,
  Like = 2,
  Gt = 3,
  Lt = 4,
  Ge = 5,
  Le = 6,
}

export interface criteriaItemListData {
  propertyName: string;
  value: any;
  isOr: boolean;
  conditionOperator: ConditionOperatorEnum;
}

export interface CountyGetAllRequestModel {
  criteriaItemList?: criteriaItemListData[];
  selectItemList?: string[];
  pageSize?: number;
  pageIndex?: number;
  orderField?: string;
  isDescending?: boolean;
  status?: StatusEnums;
}
export interface CountyGetByIdRequestModel {
  id: number;
}

export interface CountyChangeStatusRequestModel {
  id: number;
  status: StatusEnums;
}
