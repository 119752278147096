import { HttpClient } from "src/library/HttpClient";
import {
  AirportGetAllRequestModel,
  AirportSaveRequestModel,
  AirportUpdateRequestModel,
  AirportGetByIdRequestModel,
  AirportChangeStatusRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";
import { ILeaveCertificationProcessRequest } from "../BaseEnum";

export default class Airport {
  async getAll(request: AirportGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Airport + "GetAirportCertificateList",
      { ...request, token }
    );
  }

  async excelDownload(request: any, name: string) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.Airport + "GenerateExcel",
      name,
      { ...request, token }
    );
  }

  async save(request: AirportSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Airport + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: AirportUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Airport + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(request: AirportGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Airport + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(request: AirportChangeStatusRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Airport + "ChangeStatus",
      { ...request, token }
    );
  }

  async leaveCertificationProcess(
    request: ILeaveCertificationProcessRequest
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Airport + "LeaveCertificationProcess",
      { ...request, token }
    );
  }
}
