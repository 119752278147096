import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Header from "../../components/Header";
import LeftPanel from "../../components/LeftPanel";
import { EditCongressFacilityTypep } from "../../components/CongressFacilityType";

export default class EditCongressFacilityType extends React.Component<
  RouteComponentProps<any>
> {
  render() {
    return (
      <React.Fragment>
        <Header history={this.props.history} />
        <div className="row">
          <LeftPanel history={this.props.history} />
          <EditCongressFacilityTypep
            history={this.props.history}
            CongressFacilityTypeId={
              this.props.match.params.CongressFacilityTypeId
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
