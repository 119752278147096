import { HttpClient } from "src/library/HttpClient";
import {
  ThemedFacilitySaveRequestModel,
  GetThemedFacilityListModel,
  ThemedFacilityUpdateRequestModel,
  ThemedFacilityGetByIdRequestModel,
  ThemedFacilityChangeStatusRequestModel,
  GetThemedFacilityCertificateListByThemedFacilityModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class ThemedFacility {
  async getAllThemedFacility(
    request: GetThemedFacilityListModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.ThemedFacility + "GetThemedFacilityList",
      { ...request, token }
    );
  }

  async excelDownload(request: any, name: string) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.ThemedFacility + "GenerateExcel",
      name,
      { ...request, token }
    );
  }

  async save(request: ThemedFacilitySaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.ThemedFacility + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: ThemedFacilityUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.ThemedFacility + "SaveOrUpdate",
      { ...request, token }
    );
  }
  async GetThemedFacilityCertificateListByThemedFacility(
    request: GetThemedFacilityCertificateListByThemedFacilityModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.ThemedFacilityCertification +
        "GetThemedFacilityCertificateListByThemedFacility",
      { ...request, token }
    );
  }
  async getById(request: ThemedFacilityGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.ThemedFacility + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(
    request: ThemedFacilityChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.ThemedFacility + "ChangeStatus",
      { ...request, token }
    );
  }
}
