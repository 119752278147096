import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import VehicleDocumentType from "src/services/VehicleDocumentType/VehicleDocumentTypeService";
import {
  VehicleDocumentTypeGetAllRequestModel,
  VehicleDocumentTypeChangeStatusRequestModel,
  VehicleDocumentTypeGetByIdRequestModel,
  VehicleDocumentTypeUpdateRequestModel,
  VehicleDocumentTypeSaveRequestModel,
} from "src/services/VehicleDocumentType/RequestModel";
import { MessageType } from "./system/types";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllVD(
  request: VehicleDocumentTypeGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const VehicleDocumentTypeService = new VehicleDocumentType();
    const result = await VehicleDocumentTypeService.getAll(request);
    dispatch(setFormData("GroupListVD", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: VehicleDocumentTypeGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const VehicleDocumentTypeService = new VehicleDocumentType();
    const result = await VehicleDocumentTypeService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: VehicleDocumentTypeSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const VehicleDocumentTypeService = new VehicleDocumentType();
    const result = await VehicleDocumentTypeService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "İl Yönetimi"
        )
      );
      return result;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "İl Yönetimi"
        )
      );
      return false;
    }
  };
}
export function update(
  request: VehicleDocumentTypeUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const VehicleDocumentTypeService = new VehicleDocumentType();
    const result = await VehicleDocumentTypeService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "İl Yönetimi"
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "İl Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getById(
  request: VehicleDocumentTypeGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const VehicleDocumentTypeService = new VehicleDocumentType();
    const result = await VehicleDocumentTypeService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: VehicleDocumentTypeChangeStatusRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const VehicleDocumentTypeService = new VehicleDocumentType();
    const result = await VehicleDocumentTypeService.changeStatus(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "İl Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "İl Yönetimi"
        )
      );
      return false;
    }
  };
}
