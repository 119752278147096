import React from "react";
// @ts-ignore
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import { showMessage } from "src/store/system/actions";

import { connect } from "react-redux";
import { RootState } from "../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import { MessageType } from "src/store/system/types";

registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);

export type OnTransferedFileFunction = (file: any, promise?: boolean) => void;
export type OnDataFileFunction = (file: any) => void;
export type onProcessStartFileFunction = (file: any) => void;
export type OnProcessEndFileFunction = (queue: boolean) => void;
export type OnProcessFileFunction = (file: any) => void;

export interface FileUploadProps {
  endpointUrl?: string;
  requestKey?: string;
  dragDropText?: string;
  browseText?: string;
  uploadIcon?: string;
  fileTypes?: any[];
  maxFiles?: number; // File Upload Max File
  maxFileSize?: string;
  instantUpload?: boolean;
  maxTotalFileSize?: string;
  maxFileSizeText?: string;
  maxTotalFileSizeText?: string;
  token?: string;
  acceptedFileTypeText?: string;
  maxFileText?: string;
  onProcessFile?: OnProcessFileFunction;
  onDataFile?: OnDataFileFunction;
  onProcessStartFile?: onProcessStartFileFunction;
  onProcessEndFile?: OnProcessEndFileFunction;
  onTransferedFile?: OnTransferedFileFunction;
  includeFileTypes?: any;
  allowFileSizeValidation?: boolean;
}



function mapStateToProps(state: RootState) {
  return {
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {

        showMessage,
      },
      dispatch
    ),
  };
}


type FileUploadXProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  FileUploadProps;

class FileUploadx extends React.Component<FileUploadXProps> {
  filePond: any;

  static defaultProps = {
    url: "",
    fileTypes: [
      {
        key: "image/jpeg",
        text: "jpeg",
      },
      {
        key: "image/jpg",
        text: "jpg",
      },
      {
        key: "image/png",
        text: "png",
      },
      {
        key: "application/pdf",
        text: "pdf",
      },
    ],
    dragDropText: "Dosyaları buraya sürükle ya da",
    uploadIcon: "fas fa-upload",
    browseText: "Bilgisayardan Seç",
    maxFiles: 1000,
    maxFileSize: "10MB",
    maxFileSizeText: "Dosya Başına Maks. Boyut:",
    maxTotalFileSizeText: "Total File / Files Maximum Size:",
    acceptedFileTypeText: "İzinli Dosya Tipleri:",
    maxFileText: "Maksimum Dosya Sayısı:",
    instantUpload: true,
    onTransferedFile: (item: any) =>
      console.log("FILE UPLOAD ITEM / ITEMS", item),
    onProcessFile: (items: any[]) =>
      console.log("FILE UPLOAD ON PROCESS FILES", items),
    allowFileSizeValidation: true
  };



  render() {
    let {
      endpointUrl,
      requestKey,
      maxFiles,
      fileTypes,
      dragDropText,
      browseText,
      uploadIcon,
      maxFileSize,
      maxFileSizeText,
      acceptedFileTypeText,
      maxFileText,
      instantUpload,
      allowFileSizeValidation = true
    } = this.props;


    return (
      <div
        className={[
          "fa-FileUpload disable-inside fa-draggable",
          !instantUpload && "hide-upload",
        ]
          .filter((e) => !!e)
          .join(" ")}
      >
        <FilePond
          // files={this.state.uploadFiles}
          ref={(e: any) => (this.filePond = e)}
          acceptedFileTypes={(fileTypes || []).map((item) => item.key)}
          onprocessfile={(error: any, file: any) => {
            console.log(error, file, "onprocessfile")
            this.filePond.removeFile(file.id);
            if (this.props.onProcessEndFile) {
              this.props.onProcessEndFile(
                this.filePond.getFiles().length > 0 ? true : false
              );
            }
          }}
          allowFileSizeValidation={allowFileSizeValidation}
          allowPaste={false}
          onupdatefiles={(files: any[]) => {
            console.log(files, "onupdatefiles")
            this.props.onProcessFile && this.props.onProcessFile(files)
          }
          }
          onaddfile={(error: any, file) => {
            if (error && error.main === 'Dosya çok büyük') {
              this.props.showMessage(
                `Yüklemeye çalıştığınız dosya, boyutu  ${maxFileSize}'tan büyük olduğu için yüklenemiyor!`,
                "Uyarı!",
                MessageType.ERROR,
                "Dosya Yükleme Boyut Hatası"
              )
              this.props.onProcessEndFile && this.props.onProcessEndFile(false)
            } else {
              this.props.onProcessStartFile
                && this.props.onProcessStartFile(file)
            }
            console.log(file, error, "onaddfile")
          }}
          // onaddfilestart={(file: any) => {
          //   console.log(file.getFiles(),this.filePond.getFiles(), "onaddfilestart"); 

          //   this.props.onProcessStartFile
          //     && this.props.onProcessStartFile(file)
          // }
          // }
          allowMultiple={true}
          allowRevert={false}
          instantUpload={instantUpload}
          maxFileSize={maxFileSize}
          labelMaxFileSize={`${maxFileSizeText} ${maxFileSize}`}
          maxFiles={this.props.maxFiles || 0}
          labelMaxTotalFileSizeExceeded={"Maksimum toplam boyut aşıldı"}
          labelMaxTotalFileSize={`Maksimum toplam dosya boyutu ${maxFileSize}`}
          labelMaxFileSizeExceeded={'Dosya çok büyük'}
          labelIdle={`<i class='${uploadIcon}'></i> ${dragDropText} <span class='upload-label'> ${browseText} </span>`}
          name={requestKey}
          labelFileProcessingError="Yüklenirken bir problem oluştu!"
          labelFileLoadError="Yüklenirken bir problem oluştu!"
          labelFileLoading="Yükleniyor"
          labelFileProcessing="Yükleniyor"
          labelTapToCancel="Silmek için tıkla"
          labelTapToRetry="Tekrar dene"
          labelTapToUndo="Geri al"
          labelFileProcessingComplete="Yükleme tamamlandı!"

          server={{
            url: endpointUrl,
            process: {
              method: "POST",
              withCredentials: false,
              url: "",
              ondata: (formData: any) => {
                formData.append("token", this.props.token);
                return formData;
              },
              onload: (res: any) => {
                const response = JSON.parse(res);
                if (response && this.props.onTransferedFile) {
                  this.props.onTransferedFile(response);
                  this.props.onProcessEndFile && this.props.onProcessEndFile(false)
                }

                return "Success";
              },
              onerror: (res: any) => {
                console.log("onerror", res)
              }
            },
          }}
        />
        <div className="fa-FileUpload-labels row">
          <div className="col">
            <div className="title">
              {maxFileSizeText} <strong>{maxFileSize}</strong>
            </div>
            {/* <div className="title">{maxTotalFileSizeText} <strong>{maxTotalFileSize}</strong></div> */}
            {maxFiles !== 1000 && (
              <div className="title">
                {maxFileText} <strong>{maxFiles}</strong>
              </div>
            )}
          </div>
          <div className="col m-left-auto">
            <div className="title">{acceptedFileTypeText} </div>
            <div className="text">
              {(fileTypes || []).map((item) => item.text).toString()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const FileUpload = connect(mapStateToProps, mapDispatchToProps)(FileUploadx);
