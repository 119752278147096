import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  TextField,
  // MessageBar,
  MessageBarType,
  PrimaryButton,
  Dropdown,
  DatePicker,
  Label,
  ComboBox,
  Modal,
  Icon,
  DefaultButton,
  Checkbox,
} from "office-ui-fabric-react";
import {
  setFormData,
  setFormErrors,
  initialFormData,
} from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";
import {
  getById,
  update,
  changeStatus,
  leaveCertificationProcess
} from "src/store/thunkSeaTourismCertification";
import {
  RemoveWebPublish,
  RemoveQrPublish,
} from "src/store/thunkHotelCertification";
import { castThunkAction } from "src/helpers/casting";
import {
  SeaTourismCertificationGetByIdRequestModel,
  SeaTourismCertificationUpdateRequestModel,
  SeaTourismCertificationChangeStatusRequestModel, SeaTourismCertificationUpdateBySequenceIdRequestModel,
} from "src/services/SeaTourismCertification/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";

import { qrUrl, apiConfig } from "src/config/apiConfig";

import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import { getAllLFA } from "src/store/thunkSeaTourismFacilityType";
import { getAllLSU } from "src/store/thunkSeaTourismSubCategory";
import {
  getAllTy,
  showErrorDialog,
  showChangesFieldDialog,
} from "src/store/thunkCertificateType";
import { getAllCR } from "src/store/thunkCertificateStatusType";
import { getCompanyUserList } from "src/store/thunksSystemUser";
import { getAllCompany } from "src/store/thunksCompany";
import _ from "lodash";

import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";
import { GetSeaTourismListModel } from "src/services/SeaTourism/RequestModel";
import { SubCategoryGetAllRequestModel } from "src/services/SubCategory/RequestModel";
import { CertificateTypeGetAllRequestModel } from "src/services/CertificateType/RequestModel";
import { CertificateStatusTypeGetAllRequestModel } from "src/services/CertificateStatusType/RequestModel";
import cookie from "react-cookies";
import {
  DayPickerStrings,
  firstDayOfWeek,
  onFormatDate,
} from "src/config/otherConfig";
import { toTimeStamp, toDate } from "src/helpers/converts";
import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";
import { FacilityTypeGetAllRequestModel } from "src/services/FacilityType/RequestModel";
import { FileUpload } from "../FileUpload";
import { NotificationUpdateRequestModel } from "src/services/Notification/RequestModel";
import {
  updateNotification,
  updateNotificationUnRead,
} from "src/store/thunkNotification";
import { certificateStatusEnum, RECORD_CERTIFICATION_STATUS } from "src/helpers/enums";
import { getAllMA } from "src/store/thunkSeaTourism";
import { ChangeCompanyUser } from "src/store/thunkSeaTourismCertification";
import { toggleConfirmDialog } from "src/store/system/actions";
import { certificateAppealStatusDate, formatSize, getChangesFieldGlobal, onSaveText, sertifikaTarihiBayram } from "src/helpers/functions";
import CertificateStatus from "../CertificateStatus";
import ValidityDate from "../ValidityDate";
import QrCode from "../QrCode";

let token = "";
// let hasCertificateStatusTypeId = 0;
// let hasIsPublishWeb = false;

interface OwnProps {
  history: History;
  SeaTourismCertificateId: number;
  SequenceId?: string;
}
function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        toggleConfirmDialog,
        setFormData,
        setFormErrors,
        initialFormData,
        getById,
        getAllMA,
        getAllM,
        getAllC,
        getAllLSU,
        getAllTy,
        getAllCR,
        update,
        getAllCompany,
        getAllLFA,
        getCompanyUserList,
        showErrorDialog,
        RemoveWebPublish,
        RemoveQrPublish,
        updateNotification,
        updateNotificationUnRead,
        showChangesFieldDialog,
        changeStatus,
        ChangeCompanyUser,
        leaveCertificationProcess
      },
      dispatch
    ),
  };
}

type CreateSeaTourismCertificationProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  messageType: MessageBarType;
  infoMessageGetDocumentNo: string;
  isFileUploading: boolean;
  fileUploadContainer: any;
  changesFieldHtml: any;
  modalshow: boolean;
  modalshow2: boolean;
  modalanswer: boolean;
  modelattachmentId: number;
  seaTourismDetail: boolean;
}
let oldData: any = {};
let usertype: any = 0;
let username: any = "";
let hasCertificateStatusTypeId = 0;
let hasCorrectionTypeId = 0;
let isDisabled = false;
class EditSeaTourismCertification extends Component<
  CreateSeaTourismCertificationProps,
  OwnState
> {
  state = {
    messageType: MessageBarType.warning,
    infoMessageGetDocumentNo: "",
    isFileUploading: false,
    fileUploadContainer: "",
    changesFieldHtml: "",
    modalshow: false,
    modalanswer: false,
    modelattachmentId: 0,
    seaTourismDetail: true,
    modalshow2: false,
  };




  componentDidUpdate(nextprops: any) {
    if (
      this.props.formData.certificateStatusTypeId === 2 &&
      this.props.formData.prefix
    ) {
      this.setPrefix();
    }
  }
  componentDidMount() {

    if (
      this.props.formData.certificateStatusTypeId === 2 &&
      this.props.formData.prefix
    ) {
      this.setPrefix();
    }
    token = cookie.load("userId");
    this.props.initialFormData({
      status: StatusEnums.Published,
    });
    usertype = cookie.load("systemUserInfo");

    username = cookie.load("systemUserName");
    if (this.props.SeaTourismCertificateId) {
      if (usertype === "2" && this.props.SequenceId) {
        window.location.href = "/";
      }
      castThunkAction(
        this.props.getById({
          id: +this.props.SeaTourismCertificateId,
          sequenceId: this.props.SequenceId,
        } as SeaTourismCertificationGetByIdRequestModel, !!this.props.SequenceId)
      ).then((response: any) => {
        let item = certificateStatusEnum.find(
          (x: any) =>
            JSON.stringify(x.key) ===
            JSON.stringify(this.props.formData.certificateStatus)
        );
        this.props.setFormData("checkedStatusList", item ? item.list : []);

        const request: NotificationUpdateRequestModel = {
          CategoryType: 7,
          RelatedId: this.props.formData.seaTourismCertificateId,
          isRead: true,
        };
        castThunkAction(
          this.props.updateNotification(request)
        ).then((response: any) => { });
        hasCertificateStatusTypeId = this.props.formData
          .certificateStatusTypeId;
        hasCorrectionTypeId = this.props.formData.correctionType;
        isDisabled = (
          hasCorrectionTypeId === 1
            ? false
            : usertype === "2" && hasCertificateStatusTypeId === 2
        )
          ? true
          : false;

        this.renderFileUpload();
        this.props.setFormData(
          "certificateNo",
          this.props.formData?.certificateNo?.slice(
            this.props.formData.certificateNo.indexOf("-") + 1
          )
        );
        oldData = Object.assign(this.props.formData, {});

        // this.props.formData.webSite === ""
        //   ? this.props.setFormData("webSite", null)
        //   : this.props.setFormData("webSite", this.props.formData.webSite);

        const request2: CityGetAllRequestModel = {
          selectItemList: ["CityId", "CityName"],
          orderField: "CityName",
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllM(request2);

        const request3: SubCategoryGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
            this.props.formData.certificateTypeId === 5
              ? {
                propertyName: "isMinistry",
                value:
                  this.props.formData.certificateTypeId === 5 ? true : false,
                isOr: false,
                conditionOperator: ConditionOperatorEnum.Eq,
              }
              : {
                propertyName: "Status",
                value: StatusEnums.Published,
                isOr: false,
                conditionOperator: ConditionOperatorEnum.Eq,
              },
          ],
          selectItemList: [
            "SeaTourismSubCategoryId",
            "SeaTourismSubCategoryName",
          ],
        };

        castThunkAction(this.props.getAllLSU(request3)).then(
          (response: any) => {
            if (this.props.formData.seaTourismSubCategoryId === 0) {
              this.props.setFormData("layoverSubCategoryId", 1);
              this.SubCategoryChange(1, 1);
            } else {
              this.SubCategoryChange(
                this.props.formData.seaTourismSubCategoryId,
                this.props.formData.seaTourismFacilityTypeId
              );
            }
          }
        );
        const request4: CertificateTypeGetAllRequestModel = {
          selectItemList: ["CertificateTypeId", "Title"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllTy(request4);

        const request5: CertificateStatusTypeGetAllRequestModel = {
          selectItemList: ["CertificateStatusTypeId", "Title"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };

        this.props.getAllCR(request5);

        const request7: GetSeaTourismListModel = {};

        this.props.getAllMA(request7);

        const request6: SystemUserGetAllModel = {
          systemUserType: 2,
        };
        castThunkAction(this.props.getCompanyUserList(request6)).then(
          (y: any) => {
            if (usertype !== "2" && usertype !== "5") {
              const prefix = this.props.formData.CompanyUserList.dataList.filter(
                (x: any) => x.systemUserId === this.props.formData.companyUserId
              )[0]?.prefix;

              this.props.setFormData("prefix", prefix);
            } else {
              this.props.setFormData("prefix", cookie.load("prefix"));
            }
          }
        );

        // this.props.formData?.status === 30 &&
        //   this.props.setFormData("companyUserId", 0);

          
        this.CityChange(
          this.props.formData.cityId,
          this.props.formData.districtId
        );
        this.props.formData.appealDate === 0 &&
          this.props.setFormData("appealDate", null);
        this.props.formData.confirmationDate === 0 &&
          this.props.setFormData("confirmationDate", null);
      });
    }
    setTimeout(() => {
      let minHeigh = 0;

      var slides = document.getElementsByClassName("calculateHeight");
      for (var i = 0; i < slides.length; i++) {

        let newHeight = slides.item(i) ? slides.item(i)?.clientHeight : 0;
        if (newHeight && minHeigh < newHeight) {
          minHeigh = newHeight;
        }
      }
      
      const test = Array.from(
        document.getElementsByClassName(
          "calculateHeight"
        ) as HTMLCollectionOf<HTMLElement>
      );
      test.forEach((element) => {
        element.style.minHeight = minHeigh + 5 + "px";
      });
    }, 1000);
  }

  setPrefix(): any {
    const el = document.createElement("span");
    el.className = "CertificateNo-span prefixSpan";
    el.id = "CertificateNo-span";
    el.innerHTML =
      usertype === "2"
        ? cookie.load("prefix") + "-"
        : this.props.formData.prefix + "-";
    const div = document.getElementById("CertificateNo");

    if (!document.getElementById("CertificateNo-span")) {
      this.insertBefore(div, el);
    } else {
      document.getElementById("CertificateNo-span")?.remove();
      this.insertBefore(div, el);
    }
    return false;
  }
  insertAfter(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  insertBefore(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode);
  }

  setDdlDescription(id: string, message: string) {
    const el = document.createElement("span");
    el.innerHTML = message;
    const div = document.getElementById(id);
    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div, el);
    }
  }

  setLabelDescription(id: string, message: string) {
    const el2 = document.createElement("span");
    el2.innerHTML = message;
    const div2 = document.querySelectorAll('[for="' + id + '"]');

    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div2[0], el2);
    }
  }
  // renderTextByMessageType() {
  // 	switch (this.state.messageType) {
  // 		case MessageBarType.success:
  // 			return "Kaydetme işlemi başarıyla gerçekleşmiştir.";
  // 		case MessageBarType.error:
  // 			return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
  // 		default:
  // 			return "Lütfen aşağıdaki bilgileri doldurun ve formu oluşturun!";
  // 	}
  // }

  // renderMessageBar() {
  // 	return (
  // 		<MessageBar messageBarType={this.state.messageType} isMultiline={false}>
  // 			{this.renderTextByMessageType()}
  // 		</MessageBar>
  // 	);
  // }

  onSave(isDraft?: boolean) {
    let item = certificateStatusEnum.find(
      (x: any) =>
        JSON.stringify(x.list) ===
        JSON.stringify(
          this.props.formData.checkedStatusList.sort((a: any, b: any) =>
            a > b ? 1 : -1
          )
        )
    );


    this.props.setFormData("certificateStatus", item ? item.key : null)

    const request: SeaTourismCertificationUpdateRequestModel = {
      data: {
        seaTourismCertificateId: this.props.formData?.seaTourismCertificateId,
        endId: this.props.formData.endId,
        seaTourismId: this.props.formData.seaTourismId,
        certificateStatusTypeId: this.props.formData.certificateStatusTypeId,
        isPublishWeb: this.props.formData.isPublishWeb,
        isPublishQr: this.props.formData.isPublishQr,
        appealDate: this.props.formData.appealDate,
        confirmationDate:
          this.props.formData.certificateStatusTypeId === 1
            ? 0
            : this.props.formData.confirmationDate,
        certificateNo: this.props.formData.certificateNo,
        companyUserId: this.props.formData.companyUserId,
        attachmentCertificateIdList: this.props.formData
          .attachmentCertificateIdList,
        correctionType: this.props.formData.correctionType,
        correctionDescription: this.props.formData.correctionDescription,
        correctionPerson: this.props.formData.correctionPerson,
        changesField: this.props.formData.changesField,
        status: isDraft ? StatusEnums.Draft : this.props.formData.status,
        certificateStatus: item ? item.key : null,
        extendedClosedDate: this.props.formData.extendedClosedDate,
        deletedReason: this.props.formData.deletedReason,
        latitude: this.props.formData.latitude,
        longitude: this.props.formData.longitude,
      },
    };


    if (
      this.props.formData.attachmentList &&
      this.props.formData.attachmentList.length > 0
    ) {
      request.data["attachmentIdList"] = (
        this.props.formData.attachmentList || []
      ).map((item: any) => item.attachmentId);
    }
    if (
      this.props.formData.attachmentCertificateList &&
      this.props.formData.attachmentCertificateList.length > 0
    ) {
      request.data["attachmentCertificateIdList"] = (
        this.props.formData.attachmentCertificateList || []
      ).map((item: any) => item.attachmentId);
    }
    if (
      this.props.formData.attachmentUnCertificateList &&
      this.props.formData.attachmentUnCertificateList.length > 0
    ) {
      request.data["attachmentUnCertificateIdList"] = (
        this.props.formData.attachmentUnCertificateList || []
      ).map((item: any) => item.attachmentId);
    }
    // hasCertificateStatusTypeId = this.props.formData.certificateStatusTypeId
    // hasIsPublishWeb = this.props.formData.isPublishWeb
    if (this.props.formData.confirmationDate === null) {
      this.props.formData.confirmationDate = 0;
    }
    hasCertificateStatusTypeId = this.props.formData.certificateStatusTypeId;
    hasCorrectionTypeId = this.props.formData.correctionType;
    isDisabled = (
      hasCorrectionTypeId === 1
        ? false
        : usertype === "2" && hasCertificateStatusTypeId === 2
    )
      ? true
      : false;

    getChangesFieldGlobal(usertype, oldData, this.props.formData).then((x: any) => {
      this.props.setFormData("changesField", JSON.stringify(x));
      request.data["changesField"] = JSON.stringify(x);

      let action;
      if (this.props.SequenceId) {
        action = castThunkAction(this.props.update({
          data: {
            ...request.data,
            seaTourismCertificateId: this.props.formData.endId,
            sequenceId: this.props.SequenceId,
            isLastVersion: this.props.formData.currentVersionStatus
          }
        } as SeaTourismCertificationUpdateBySequenceIdRequestModel, !!this.props.SequenceId))
      } else {
        action = castThunkAction(this.props.update(request))
      }

      action.then((response: any) => {
        this.props.setFormData(
          "initialAttachmentList",
          this.props.formData.attachmentList
        );
        this.props.setFormData(
          "initialAttachmentCertificateList",
          this.props.formData.attachmentCertificateList
        );
        this.props.setFormData(
          "initialAttachmentUnCertificateList",
          this.props.formData.attachmentUnCertificateList
        );
        this.setState(
          {
            messageType: response
              ? MessageBarType.success
              : MessageBarType.error,
          },
          () => {
            this.props.setFormData(
              "status",
              isDraft ? StatusEnums.Draft : this.props.formData.status
            );
          }
        );
      });
    });
  }


  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  removeElement(id: any) {
    var elem = document.getElementById(id);
    return elem?.parentNode?.removeChild(elem);
  }
  filterFileds(val: any) {
    if (
      val !== "" &&
      val !== 0 &&
      val !== "0" &&
      val !== null &&
      val !== "null" &&
      val !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  }
  onSubmit(isDraft?: boolean) {
    let desc = "";
    if (!this.filterFileds(this.props.formData.seaTourismId)) {
      desc += "Deniz Turizm Tesisi / Aracı,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.appealDate)) {
      desc += "Sertifika Başvuru Tarihi,  <br/>";
    }
    if (usertype && usertype !== "2") {
      if (!this.filterFileds(this.props.formData.companyUserId)) {
        desc += "Denetleyici Firma <br/> ";
      }
    }
    if (this.props.formData.certificateStatusTypeId === 2) {
      if (!this.filterFileds(this.props.formData.confirmationDate)) {
        desc += "Sertifika Düzenleme Tarihi,  <br/>";
      }
      if (!this.filterFileds(this.props.formData.certificateNo)) {
        desc += "Sertifika No,  <br/>";
      }
      if (!this.filterFileds(this.props.formData.extendedClosedDate)) {
        desc += "Sertifika Geçerlilik Tarihi boş bırakılamaz.  <br/>";
      }
    }

    const request = {
      title: "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
      desc: desc,
    };
    if (desc !== "") {
      castThunkAction(
        this.props.showErrorDialog(request)
      ).then((response: any) => { });
    }

    if (
      this.props.formData.appealDate &&
      this.props.formData.appealDate !== "" &&
      this.props.formData.appealDate !== null &&
      this.props.formData.appealDate !== 0 &&
      this.props.formData.appealDate !== "null" &&
      this.props.formData.appealDate !== "0"
    ) {
      this.removeElement("appealDate-span");
    } else {
      const el = document.createElement("span");
      el.className = "errorspan";
      el.innerHTML =
        "<br/> <span id='appealDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
      const div = document.getElementById("appealDate");
      if (!document.getElementById("appealDate-span")) {
        this.insertAfter(div, el);
      }
    }

    if (this.props.formData.certificateStatusTypeId === 2) {
      if (
        this.props.formData.confirmationDate &&
        this.props.formData.confirmationDate !== "" &&
        this.props.formData.confirmationDate !== null &&
        this.props.formData.confirmationDate !== 0 &&
        this.props.formData.confirmationDate !== "null" &&
        this.props.formData.confirmationDate !== "0"
      ) {
        this.removeElement("confirmationDate-span");
      } else {
        const el = document.createElement("span");
        el.className = "errorspan";
        el.innerHTML =
          "<br/> <span id='confirmationDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
        const div = document.getElementById("confirmationDate");
        if (!document.getElementById("confirmationDate-span")) {
          this.insertAfter(div, el);
        }
      }
    } else {
      this.removeElement("confirmationDate-span");
    }
    const { formData, setFormErrors } = this.props;
    const validationSchema = _yup.object({
      certificateStatusTypeId: _yup.string().required("Bu alan zorunludur!"),
      certificateNo:
        this.props.formData.certificateStatusTypeId === 2
          ? _yup.string().required("Bu alan zorunludur!")
          : _yup.string().nullable(),
      appealDate: _yup.string().required("Bu alan zorunludur!"),
      confirmationDate:
        this.props.formData.certificateStatusTypeId === 2
          ? _yup.string().required("Bu alan zorunludur!")
          : _yup.string().nullable(),
    });

    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});
        if (
          this.props.formData.appealDate &&
          this.props.formData.appealDate !== "" &&
          this.props.formData.appealDate !== null &&
          this.props.formData.appealDate !== 0 &&
          this.props.formData.appealDate !== "null" &&
          this.props.formData.appealDate !== "0"
        ) {
          this.removeElement("appealDate-span");

          if (this.props.formData.certificateStatusTypeId === 2) {
            if (
              this.props.formData.confirmationDate &&
              this.props.formData.confirmationDate !== "" &&
              this.props.formData.confirmationDate !== null &&
              this.props.formData.confirmationDate !== 0 &&
              this.props.formData.confirmationDate !== "null" &&
              this.props.formData.confirmationDate !== "0"
            ) {
              this.removeElement("appealDate-span");
              this.removeElement("confirmationDate-span");

              if (
                usertype !== 2 ||
                (this.props.formData.attachmentList &&
                  this.props.formData.attachmentList.length > 0)
              ) {
                desc === "" && this.onSave(isDraft);
              } else {
                castThunkAction(
                  this.props.showErrorDialog({
                    title:
                      "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
                    desc: "İşletme belgesi yükleyiniz",
                  })
                ).then((response: any) => { });
              }
            } else {
              const el = document.createElement("span");
              el.className = "errorspan";
              el.innerHTML =
                "<br/> <span id='confirmationDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
              const div = document.getElementById("confirmationDate");
              if (!document.getElementById("confirmationDate-span")) {
                this.insertAfter(div, el);
              }
            }
          } else {
            this.removeElement("appealDate-span");
            this.removeElement("confirmationDate-span");
            if (
              usertype !== 2 ||
              (this.props.formData.attachmentList &&
                this.props.formData.attachmentList.length > 0)
            ) {
              desc === "" &&  this.onSave(isDraft);
            } else {
              castThunkAction(
                this.props.showErrorDialog({
                  title:
                    "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
                  desc: "İşletme belgesi yükleyiniz",
                })
              ).then((response: any) => { });
            }
          }
        } else {
          const el = document.createElement("span");
          el.className = "errorspan";
          el.innerHTML =
            "<br/> <span id='appealDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
          const div = document.getElementById("appealDate");
          if (!document.getElementById("appealDate-span")) {
            this.insertAfter(div, el);
          }
        }
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  CityChange(item: any, districtId?: any) {
    const request2: CountyGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "cityId",
          value: item,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
      selectItemList: ["DistrictId", "DistrictName"],
      orderField: "DistrictName",
    };
    castThunkAction(this.props.getAllC(request2)).then((response: any) => {
      this.props.setFormData("districtId", districtId);
    });
  }

  SubCategoryChange(item: any, seaTourismFacilityTypeId?: any) {
    const request2: FacilityTypeGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "seaTourismSubCategoryId",
          value: item,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        this.props.formData.certificateTypeId === 5
          ? {
            propertyName: "isMinistry",
            value: this.props.formData.certificateTypeId === 5 ? true : false,
            isOr: false,
            conditionOperator: ConditionOperatorEnum.Eq,
          }
          : {
            propertyName: "Status",
            value: StatusEnums.Published,
            isOr: false,
            conditionOperator: ConditionOperatorEnum.Eq,
          },
      ],
      selectItemList: [
        "SeaTourismFacilityTypeId",
        "SeaTourismFacilityTypeName",
      ],
      orderField: "SeaTourismFacilityTypeName",
    };

    castThunkAction(this.props.getAllLFA(request2)).then((response: any) => { });
  }
  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Seçiniz" });
    }

    return newArray;
  }

  getCategories(key: any, type: any) {
    const request2: SubCategoryGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        type === 5
          ? {
            propertyName: "isMinistry",
            value: key === 5 ? true : false,
            isOr: false,
            conditionOperator: ConditionOperatorEnum.Eq,
          }
          : {
            propertyName: "Status",
            value: StatusEnums.Published,
            isOr: false,
            conditionOperator: ConditionOperatorEnum.Eq,
          },
      ],
      selectItemList: ["SeaTourismSubCategoryId", "SeaTourismSubCategoryName"],
    };

    castThunkAction(this.props.getAllLSU(request2)).then((response: any) => {
      this.props.setFormData("seaTourismSubCategoryId", 1);
      this.SubCategoryChange(1, 7);
    });
  }

  renderFileUpload() {
    let fileuploadStatus = false;
    hasCorrectionTypeId === 1
      ? (fileuploadStatus = true)
      : usertype === "2" && hasCertificateStatusTypeId === 2
        ? (fileuploadStatus = false)
        : (fileuploadStatus = true);

    if (fileuploadStatus) {
      this.setState({
        ...this.state,
        fileUploadContainer: (
          <FileUpload
            onProcessStartFile={() => {
              this.setState({
                isFileUploading: true,
              });
            }}
            onProcessEndFile={(queue: boolean) => {
              if (!queue) {
                this.setState({
                  isFileUploading: false,
                });
              }
            }}
            onTransferedFile={(file: any, promise?: boolean) => {
              this.props.setFormData("attachmentList", [
                ...(this.props.formData?.attachmentList || []),
                file.attachmentIdList[0],
              ]);
            }}
            requestKey="files"
            token={token}
            endpointUrl={apiConfig.prefixes.attachment + "UploadFile"}
          />
        ),
      });
    }
  }

  renderForm() {
    const { formData, formErrors, setFormData } = this.props;

    return (
      <div className="form-groups row">
        {(usertype !== "2" || this.props.formData.correctionType === 1) && (
          <div className="form-group col-md-12 ">
            <PrimaryButton
              onClick={(x) =>
                window.open(
                  "/EditSeaTourismCeritifaciton/edit/" +
                  this.props.formData.seaTourismId +
                  "/" +
                  this.props.SeaTourismCertificateId
                )
              }
              text="Deniz Turizm Tesisi / Araç Bilgilerini Düzenle"
              className="deleteDateBtn"
              style={{ float: "left", marginTop: "15px"}}
              styles={{
                root: {
                  marginTop: 5,
                  background: "#126EBE",
                  borderColor: "#126EBE",
                },
                rootHovered: {
                  background: "#126EBE",
                  borderColor: "#126EBE",
                },
                rootPressed: {
                  background: "#126EBE",
                  borderColor: "#126EBE",
                },
                rootDisabled: {
                  background: "#ececec",
                },
              }}
            />
            <PrimaryButton
              onClick={() => this.onAdditional()}
              className="AdditionalButtonCert"
              styles={{
                rootDisabled: {
                  background: "#ececec",
                  borderColor: "#ccc !important",
                  opacity: 0.5,
                },
              }}
              text="Denetim Raporları"
            />
          </div>
        )}

        {usertype !== "2" && (
          <div className="form-group col-md-12 ">
            <button
              style={{
                background: "#444",
                border: "none",
                color: "#fff",
                padding: "5px 15px",
              }}
              onClick={() =>
                this.setState({
                  seaTourismDetail: !this.state.seaTourismDetail,
                })
              }
            >
              <i
                className={
                  this.state.seaTourismDetail
                    ? "fa fa-angle-down"
                    : "fa fa-angle-up"
                }
              ></i>
              Deniz Turizm Tesis / Araç Bilgileri
            </button>
            {this.state.seaTourismDetail && (
              <div className="form-wrapper hotel-detail-2">
                <div className="item calculateHeight minH">
                  <label>{(this.props.formData && this.props.formData.seaTourismSubCategoryId === 1) ? `Deniz Turizm Tesis ID` : `Deniz Turizm Araç ID`}
                  </label>
                  <span>{this.props.formData.seaTourismId}</span>
                </div>
                <div className="item calculateHeight minH">
                  <label>Tesis / Araç Statüsü</label>
                  <span>{this.props.formData.certificateTypeName}</span>
                </div>

                <div className="item calculateHeight minH">
                  <label>Belge No</label>
                  <span>{this.props.formData.documentNo}</span>
                </div>
                <div className="item calculateHeight minH">
                  <label>Tesis / Araç Onay Durumu</label>
                  <span>
                    {this.props.formData.isApproved
                      ? "Onaylanmış"
                      : "Onaylanmamış"}
                  </span>
                </div>
                <div className="item calculateHeight">
                  <label>Belge Sahibi / Ticari Ünvan</label>
                  <span>{this.props.formData.companyName}</span>
                </div>
                <div className="item calculateHeight">
                  <label>Tesis / Araç Adı</label>
                  <span>{this.props.formData.brandName}</span>
                </div>
                <div className="item calculateHeight">
                  <label>İl</label>
                  <span>{this.props.formData.cityName}</span>
                </div>
                <div className="item calculateHeight">
                  <label>İlçe</label>
                  <span>{this.props.formData.districtName}</span>
                </div>
                <div className="item calculateHeight minH">
                  <label>Tesis / Araç E-posta Adresi</label>
                  <span>{this.props.formData.email}</span>
                </div>
                <div className="item calculateHeight minH">
                  <label>Tesis / Araç Telefon Numarası</label>
                  <span>{this.props.formData.phone}</span>
                </div>
                <div className="item calculateHeight minH">
                  <label>Tesis / Araç Genel Müdürünün Adı ve Soyadı</label>
                  <span>{this.props.formData.managerName}</span>
                </div>
                <div className="item calculateHeight minH">
                  <label>Tesisin / Aracın Web Sitesi</label>
                  <span>{this.props.formData.webSite}</span>
                </div>
                <div className="item calculateHeight">
                  <label>Tesisin / Aracın Adresi</label>
                  <span>{this.props.formData.address}</span>
                </div>
                <div className="item calculateHeight">
                  <label>X Koordinatı</label>
                  <span>{this.props.formData.latitude}</span>
                </div>
                <div className="item calculateHeight">
                  <label>Y Koordinatı</label>
                  <span>{this.props.formData.longitude}</span>
                </div>
                <div className="item calculateHeight">
                  <label>Seçili Sağlık Kuruluşu</label>
                  <span>{this.props.formData.hospitalName}</span>
                </div>
                <div className="item calculateHeight minH">
                  <label>Tesis / Araç Faaliyet Durumu</label>
                  <span>
                    {" "}
                    {this.props.formData.isOpen ? "Açık " : "Kapalı "}{" "}
                  </span>
                </div>
                <div className="item calculateHeight minH">
                  <label>
                    T.C Kültür ve Turizm Bakanlığı İşletme Belgesi / İşyeri Açma
                    Ruhsatı ve Tesise / Araca Ait Diğer Resmi Evraklar
                  </label>
                  <span>
                    {this.props.formData.attachmentList?.map((x: any) => (
                      <a
                        style={{ marginRight: "5px", marginBottom: "5px" }}
                        target="_blank"
                        href={qrUrl + x.attachmentPath}
                      >
                        Görüntüle
                      </a>
                    ))}
                  </span>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="item row">
              <ComboBox
                disabled={true}
                label="Deniz Turizm Tesisi / Aracı"
                allowFreeform
                autoComplete="on"
                selectedKey={this.props.formData.seaTourismId}
                onChange={(event: any, option: any) =>
                  this.props.setFormData("seaTourismId", option.key)
                }
                options={(
                  this.props.formData.SeaTourismList?.dataList || ([] as any)
                ).map((item: any) => ({
                  key: item.seaTourismId,
                  text: item.brandName,
                }))}
              />

              {usertype &&
                usertype !== "2" &&
                usertype !== "5" &&
                !this.props.formData.isApproved && (
                  <PrimaryButton
                    onClick={(x) =>
                      window.open(
                        "/EditSeaTourismCeritifaciton/edit/" +
                        this.props.formData.seaTourismId +
                        "/" +
                        this.props.SeaTourismCertificateId
                      )
                    }
                    text="Deniz Turizm Tesisi / Aracı Onayla"
                    className="deleteDateBtn"
                    styles={{
                      root: {
                        marginTop: 5,
                        background: "#ac6262",
                        borderColor: "#ac6262",
                      },
                      rootHovered: {
                        background: "#a34c4c",
                        borderColor: "#a34c4c",
                      },
                      rootPressed: {
                        background: "#a34c4c",
                        borderColor: "#a34c4c",
                      },
                      rootDisabled: {
                        background: "#ececec",
                      },
                    }}
                  />
                )}
            </div>

            {usertype && usertype !== "2" && usertype !== "5" ? (
              <>
                <div className="item row">
                  <Dropdown
                    disabled={isDisabled}
                    onChanged={(event: any) =>
                      setFormData("isPublishWeb", event.key)
                    }
                    label="Web Sitesinde Yayımlansın mı?"
                    options={[
                      { key: false, text: "Hayır" },
                      { key: true, text: "Evet" },
                    ].map((item: any) => ({
                      key: item.key,
                      text: item.text,
                    }))}
                    className="custom-dropdown"
                    selectedKey={this.props.formData.isPublishWeb}
                  />
                </div>

                <div className="item row">
                  {usertype !== "5" && (
                    <Dropdown
                      disabled={isDisabled}
                      onChanged={(event: any) =>
                        setFormData("isPublishQr", event.key)
                      }
                      label="Sertifika QR Kodu Yayımlansın mı?"
                      options={[
                        { key: false, text: "Hayır" },
                        { key: true, text: "Evet" },
                      ].map((item: any) => ({
                        key: item.key,
                        text: item.text,
                      }))}
                      className="custom-dropdown"
                      selectedKey={this.props.formData.isPublishQr}
                    />
                  )}
                </div>

                <div className="item row">
                  <ComboBox
                    onChange={(event: any, option: any) => {
                      setFormData("companyUserId", option.key);
                      setFormData("prefix", option.prefix);
                      setFormData("certificateNo", "")
                    }}
                    disabled={isDisabled}
                    allowFreeform={true}
                    autoComplete="on"
                    label="Denetleyici Firma"
                    selectedKey={this.props.formData.companyUserId || 0}
                    options={(
                      this.props.formData.CompanyUserList?.dataList ||
                      ([] as any)
                    ).map((item: any) => ({
                      key: item.systemUserId,
                      text: item.title,
                      prefix: item.prefix,
                    }))}
                    className="custom-dropdown"
                    errorMessage={formErrors.companyUserId}
                  />
                </div>
              </>
            ) : (
              ""
            )}

            <div className="item row">
              <DatePicker
                disabled={isDisabled}
                strings={DayPickerStrings}
                // style={{
                // 	backgroundColor: this.props.formData.changesField.includes({ en: "appealDate", tr: "Başvuru Tarihi" }) ? 'Red' : '#fff'

                // }}
                firstDayOfWeek={firstDayOfWeek}
                onSelectDate={(date) =>
                  setFormData("appealDate", toTimeStamp(date?.toString()))
                }
                label="Sertifika Başvuru Tarihi"
                placeholder="gün.ay.yıl"
                formatDate={onFormatDate}
                isRequired={true}
                value={this.getDate(
                  this.props.formData.appealDate === null
                    ? 0
                    : this.props.formData.appealDate
                )}
                minDate={certificateAppealStatusDate(
                  usertype,
                  true,
                  this.props.formData.intitialAppealDate
                    ? this.props.formData.intitialAppealDate
                    : this.props.formData.appealDate
                )}
                maxDate={new Date()}
              />

              <PrimaryButton
                disabled={isDisabled}
                id="appealDate"
                onClick={(x) => this.props.setFormData("appealDate", 0)}
                iconProps={{ iconName: "Trash" }}
                text="Tarihi Sil"
                className="deleteDateBtn"
                styles={{
                  root: {
                    marginTop: 5,
                    background: "#ac6262",
                    borderColor: "#ac6262",
                  },
                  rootHovered: {
                    background: "#a34c4c",
                    borderColor: "#a34c4c",
                  },
                  rootPressed: {
                    background: "#a34c4c",
                    borderColor: "#a34c4c",
                  },
                  rootDisabled: {
                    background: "#ececec",
                  },
                }}
              />
            </div>

            <div className="item row">
              {usertype && usertype === "2" ? (
                <Dropdown
                  disabled={isDisabled}
                  onChanged={(event: any) =>
                    event.key !== 0 && setFormData("correctionType", event.key)
                  }
                  label="Revize Durumu"
                  options={[
                    { key: 1, text: "Revize İstendi", disabled: true },
                    {
                      key: 2,
                      text: "Revize Yapıldı",
                      disabled:
                        this.props.formData?.correctionType === 1
                          ? false
                          : true,
                    },
                    { key: 3, text: "Revize Onaylandı", disabled: true },
                  ]}
                  className="custom-dropdown"
                  selectedKey={this.props.formData.correctionType}
                />
              ) : usertype && usertype === "3" ? (
                <Dropdown
                  disabled={isDisabled}
                  onChanged={(event: any) =>
                    event.key !== 0 && setFormData("correctionType", event.key)
                  }
                  label="Revize Durumu"
                  options={[
                    { key: 0, text: "Seçiniz" },
                    { key: 1, text: "Revize İstendi" },
                    { key: 2, text: "Revize Yapıldı", disabled: true },
                    { key: 3, text: "Revize Onaylandı" },
                  ]}
                  className="custom-dropdown"
                  selectedKey={this.props.formData.correctionType}
                />
              ) : (
                <Dropdown
                  disabled={isDisabled}
                  onChanged={(event: any) =>
                    event.key !== 0 && setFormData("correctionType", event.key)
                  }
                  label="Revize Durumu"
                  options={[
                    { key: 0, text: "Seçiniz" },
                    { key: 1, text: "Revize İstendi" },
                    { key: 2, text: "Revize Yapıldı" },
                    { key: 3, text: "Revize Onaylandı" },
                  ].map((item: any) => ({
                    key: item.key,
                    text: item.text,
                  }))}
                  className="custom-dropdown"
                  selectedKey={this.props.formData.correctionType}
                />
              )}
            </div>
            <div className="item row">
              <div
                style={{
                  display:
                    formData.correctionPerson === "" ||
                      formData.correctionPerson === null ||
                      formData.correctionPerson === undefined ||
                      formData.correctionDescription === "" ||
                      formData.correctionDescription === null ||
                      formData.correctionDescription === undefined
                      ? "none"
                      : "block",
                }}
              >
                {usertype && usertype !== "2" && (
                  <Label style={{ width: "100%" }}>
                    <b>Revize İsteyen: {formData.correctionPerson}</b>
                  </Label>
                )}
              </div>
            </div>

            <div className="item row">
              {usertype && usertype === "2" ? (
                <div
                  style={{
                    display:
                      formData.correctionDescription === "" ||
                        formData.correctionDescription === null ||
                        formData.correctionDescription === undefined
                        ? "none"
                        : "block",
                  }}
                >
                  <Label style={{ width: "100%" }}>
                    <b>Revize Açıklama</b>
                  </Label>
                  <Label>{formData.correctionDescription}</Label>
                </div>
              ) : (
                <>
                  <label className="correctionDescriptionLabel">
                    Revize Açıklaması
                  </label>
                  <textarea
                    disabled={isDisabled}
                    placeholder="Revize Açıklaması"
                    value={formData.correctionDescription || ""}
                    rows={20}
                    className="custom-textfield border correctionDescription"
                    onChange={(event: any) => {
                      setFormData(
                        "correctionDescription",
                        event.target.value === "" ? null : event.target.value
                      );
                      setFormData("correctionPerson", username);
                    }}
                  ></textarea>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="item row">
              <CertificateStatus />
            </div>

            <div className="item row">
              <Checkbox
                checked={
                  this.props.formData.checkedStatusList?.filter(
                    (x: any) => x === 1
                  ).length > 0
                }
                onChange={() =>
                  this.props.formData.checkedStatusList?.filter(
                    (x: any) => x === 1
                  ).length > 0
                    ? this.props.setFormData(
                      "checkedStatusList",
                      this.props.formData.checkedStatusList?.filter(
                        (x: any) => x !== 1
                      )
                    )
                    : this.props.setFormData("checkedStatusList", [
                      1,
                      ...this.props.formData.checkedStatusList,
                    ])
                }
                disabled={false}
                label="Sertifika Uzatmalı Kapalı"
                className="custom-checkbox"
              />
            </div>
            
            <div className="item row">
            {usertype !== "2" &&
              <Checkbox
                disabled={false}
                checked={
                  this.props.formData.checkedStatusList?.filter(
                    (x: any) => x === 2
                  ).length > 0
                }
                onChange={() =>
                  this.props.formData.checkedStatusList?.filter(
                    (x: any) => x === 2
                  ).length > 0
                    ? this.props.setFormData(
                      "checkedStatusList",
                      this.props.formData.checkedStatusList.filter(
                        (x: any) => x !== 2
                      )
                    )
                    : this.props.setFormData("checkedStatusList", [
                      2,
                      ...this.props.formData.checkedStatusList,
                    ])
                }
                label="Firma Sertifika Sürecinden Ayrıldı"
                className="custom-checkbox"
              />
             }
            </div>

            <div className="item row">
              <Checkbox
                disabled={false}
                checked={
                  this.props.formData.checkedStatusList?.filter(
                    (x: any) => x === 3
                  ).length > 0
                }
                onChange={() =>
                  this.props.formData.checkedStatusList?.filter(
                    (x: any) => x === 3
                  ).length > 0
                    ? this.props.setFormData(
                      "checkedStatusList",
                      this.props.formData.checkedStatusList.filter(
                        (x: any) => x !== 3
                      )
                    )
                    : this.props.setFormData("checkedStatusList", [
                      3,
                      ...this.props.formData.checkedStatusList,
                    ])
                }
                className="custom-checkbox"
                label="Kare Kodsuz Sertifikalı"
              />
            </div>

            {/* <div className="item row">
							{usertype && usertype === "2" ? (
								<Dropdown
									disabled={isDisabled}
									onChanged={(event: any) =>
										event.key !== 0? setFormData("certificateStatus", event.key):setFormData("certificateStatus", null)
									}
									label="Durum"
									options={[
										{key:0,text:"Seçiniz"},
										{ key: 1, text: "Sertifika Uzatmalı Kapalı" },
										{ key: 2, text: "Firma Sertifika Sürecinden Ayrıldı" },
										{ key: 3, text: "Kare Kodsuz Sertifikalı", disabled: true },
									]}
									className="custom-dropdown"
									selectedKey={this.props.formData.certificateStatus!==null?this.props.formData.certificateStatus :0}
								/>
							) :  (
								<Dropdown
									disabled={isDisabled}
									onChanged={(event: any) =>
										event.key !== 0? setFormData("certificateStatus", event.key):setFormData("certificateStatus", null)
									}
									label="Durum"
									options={[
										{key:0,text:"Seçiniz"},
										{ key: 1, text: "Sertifika Uzatmalı Kapalı" },
										{ key: 2, text: "Firma Sertifika Sürecinden Ayrıldı" },
										{ key: 3, text: "Kare Kodsuz Sertifikalı"},
									]}
									className="custom-dropdown"
									selectedKey={this.props.formData.certificateStatus!==null?this.props.formData.certificateStatus :0}
								/>
							) }
						</div> */}

            {this.props.formData.certificateStatusTypeId === 2 &&
              this.props.formData.prefix ? (
              this.props.formData.prefix ? (
                <>
                  <div className="item row">
                    <DatePicker
                      disabled={false}
                      strings={DayPickerStrings}
                      firstDayOfWeek={firstDayOfWeek}
                      onSelectDate={(date) =>
                        setFormData(
                          "confirmationDate",
                          toTimeStamp(date?.toString())
                        )
                      }
                      label="Sertifika Düzenleme Tarihi"
                      placeholder="gün.ay.yıl"
                      formatDate={onFormatDate}
                      isRequired={
                        this.props.formData.certificateStatusTypeId === 2
                      }
                      value={this.getDate(
                        formData.confirmationDate === null
                          ? 0
                          : formData.confirmationDate
                      )}
                      maxDate={sertifikaTarihiBayram(usertype)}

                    />
                    <PrimaryButton
                      disabled={false}
                      id="confirmationDate"
                      onClick={(x) =>
                        this.props.setFormData("confirmationDate", 0)
                      }
                      iconProps={{ iconName: "Trash" }}
                      text="Tarihi Sil"
                      className="deleteDateBtn"
                      styles={{
                        root: {
                          marginTop: 5,
                          background: "#ac6262",
                          borderColor: "#ac6262",
                        },
                        rootHovered: {
                          background: "#a34c4c",
                          borderColor: "#a34c4c",
                        },
                        rootPressed: {
                          background: "#a34c4c",
                          borderColor: "#a34c4c",
                        },
                        rootDisabled: {
                          background: "#ececec",
                        },
                      }}
                    />
                  </div>
                  <div className="item row">
                    <TextField
                      disabled={false}
                      id="CertificateNo"
                      placeholder="Sertifika No"
                      value={formData.certificateNo || ""}
                      label="Sertifika No"
                      className="custom-textfield border"
                      onChange={(event: any) =>
                        setFormData("certificateNo", event.target.value)
                      }
                      errorMessage={formErrors.certificateNo}
                    />
                  </div>
                </>
              ) : (
                this.showDialog({
                  title: "Uyarı",
                  desc:
                    "Sertifika Numarası için firma kodu Yok <br/> Denetleyici Firma Seçiniz",
                })
              )
            ) : (
              ""
            )}
            <ValidityDate />
            {this.props.formData.certificateStatus != 3 &&
              this.props.formData.certificateStatus != 4 &&
              this.props.formData.certificateStatus != 6 &&
              this.props.formData.certificateStatus != 7 && (
                <QrCode/>
              )}
          </div>
          {(usertype === "1" || usertype === "3") && this.props.formData.status === StatusEnums.Deleted && <div className="form-wrapper">
            <div>
              <label className="correctionDescriptionLabel">
                Silinme Sebebi
              </label>
              <textarea
                disabled={isDisabled}
                placeholder="Silinme açıklamasını giriniz"
                value={formData.deletedReason || ""}
                rows={20}
                className="custom-textfield border correctionDescription"
                onChange={(event: any) => {
                  setFormData(
                    "deletedReason",
                    event.target.value === "" ? null : event.target.value
                  );
                }}
              ></textarea>
            </div>
          </div>}
        </div>
        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="title">Sertifika Belgesi</div>
            {(!this.props.SequenceId) && <FileUpload

              onProcessStartFile={() => {
                this.setState({
                  isFileUploading: true,
                });
              }}
              onProcessEndFile={(queue: boolean) => {
                if (!queue) {
                  this.setState({
                    isFileUploading: false,
                  });
                }
              }}
              onTransferedFile={(file: any, promise?: boolean) => {
                this.props.setFormData("attachmentCertificateList", [
                  ...(this.props.formData?.attachmentCertificateList || []),
                  file.attachmentIdList[0],
                ]);
              }}
              requestKey="files"
              token={token}
              endpointUrl={
                apiConfig.prefixes.attachment + "UploadCertificateFile"
              }
            />}
            {this.props.formData.attachmentCertificateList &&
              this.props.formData.attachmentCertificateList.length > 0 && (
                <React.Fragment>
                  <div className="title">Yüklenen Belgeler</div>
                  {JSON.stringify(
                    this.props.formData.initialAttachmentCertificateList
                  ) !==
                    JSON.stringify(
                      this.props.formData.attachmentCertificateList
                    ) && (
                      <div className="file-save-warning">
                        Düzenlemeleri ve yüklenen belgeleri kaydetmek için, sağ
                        üst kısımdan "Kaydet veya Yayımla"ya basın.
                      </div>
                    )}
                  <div className="uploaded-files">
                    {(this.props.formData?.attachmentCertificateList || []).map(
                      (
                        item: {
                          attachmentId: number;
                          attachmentPath: string;
                          fileName: string;
                          fileSize: number;
                          createDateString: string;
                        },
                        index: number
                      ) => (
                        <div key={index} className="uploaded-file">
                          <div className="name row">
                            <span className="text col no-padding">
                              {item.fileName}-{item.createDateString}
                              <strong className="size m-left-auto">
                                {formatSize(item.fileSize)}
                              </strong>
                            </span>
                            <div className="buttons m-left-auto row">
                              <button
                                onClick={() =>
                                  window.open(
                                    qrUrl + item.attachmentPath,
                                    "_blank"
                                  )
                                }
                                className="view"
                              >
                                <i className="icon-explore"></i> Görüntüle
                              </button>
                              {!this.props.SequenceId && usertype !== "5" && (
                                <button
                                  onClick={() => {
                                    this.setState({
                                      modalshow: true,
                                      modelattachmentId: item.attachmentId,
                                    });
                                  }}
                                  className="remove"
                                >
                                  <i className="icon-close"></i>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </React.Fragment>
              )}
          </div>
          <div className="form-wrapper">
            <div className="title">Uygunsuzluk / Kapalılık Belgesi</div>
            {(!this.props.SequenceId) && <FileUpload
              onProcessStartFile={() => {
                this.setState({
                  isFileUploading: true,
                });
              }}
              onProcessEndFile={(queue: boolean) => {
                if (!queue) {
                  this.setState({
                    isFileUploading: false,
                  });
                }
              }}
              onTransferedFile={(file: any, promise?: boolean) => {
                this.props.setFormData("attachmentUnCertificateList", [
                  ...(this.props.formData?.attachmentUnCertificateList || []),
                  file.attachmentIdList[0],
                ]);
              }}
              requestKey="files"
              token={token}
              endpointUrl={
                apiConfig.prefixes.attachment + "UploadCertificateFile"
              }
            />}
            {this.props.formData.attachmentUnCertificateList &&
              this.props.formData.attachmentUnCertificateList.length > 0 && (
                <React.Fragment>
                  <div className="title">Yüklenen Belgeler</div>
                  {JSON.stringify(
                    this.props.formData.initialAttachmentUnCertificateList
                  ) !==
                    JSON.stringify(
                      this.props.formData.attachmentUnCertificateList
                    ) && (
                      <div className="file-save-warning">
                        Düzenlemeleri ve yüklenen belgeleri kaydetmek için, sağ
                        üst kısımdan "Kaydet veya Yayımla"ya basın.
                      </div>
                    )}
                  <div className="uploaded-files">
                    {(
                      this.props.formData?.attachmentUnCertificateList || []
                    ).map(
                      (
                        item: {
                          attachmentId: number;
                          attachmentPath: string;
                          fileName: string;
                          fileSize: number;
                        },
                        index: number
                      ) => (
                        <div key={index} className="uploaded-file">
                          <div className="name row">
                            <span className="text col no-padding">
                              {item.fileName}
                              <strong className="size m-left-auto">
                                {formatSize(item.fileSize)}
                              </strong>
                            </span>
                            <div className="buttons m-left-auto row">
                              <button
                                onClick={() =>
                                  window.open(
                                    qrUrl + item.attachmentPath,
                                    "_blank"
                                  )
                                }
                                className="view"
                              >
                                <i className="icon-explore"></i> Görüntüle
                              </button>
                              {!this.props.SequenceId && usertype !== "5" && (
                                <button
                                  onClick={() => {
                                    this.setState({
                                      modalshow: true,
                                      modalshow2: true,
                                      modelattachmentId: item.attachmentId,
                                    });
                                  }}
                                  className="remove"
                                >
                                  <i className="icon-close"></i>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </React.Fragment>
              )}
          </div>
        </div>
      </div>
    );
  }
  showDialog(req: any): any {
    this.props.showErrorDialog(req);
  }

  getDate(date: any): any {
    if (date !== 0) {
      return toDate(date, "");
    }
  }
  renderStatusByType() {
    switch (this.props.formData.status) {
      case StatusEnums.Published:
        return (
          <div className="status">
            Durum: <strong>Yayımda</strong>
          </div>
        );
      case StatusEnums.Draft:
        return (
          <div className="status">
            Durum: <strong>Firma Değişti</strong>
          </div>
        );
      case StatusEnums.Deleted:
        return (
          <div className="status">
            Durum: <strong>Silindi</strong>
          </div>
        );
      case StatusEnums.DraftArchive:
        return (
          <div className="status">
            Durum: <strong>Uygun Görülmedi</strong>
          </div>
        );
      case StatusEnums.PendingApproval:
        return (
          <div className="status">
            Durum: <strong>Sertifika Süresi Bitenler</strong>
          </div>
        );
      case StatusEnums.ActivityStopped:
        return (
          <div className="status">
            Durum: <strong>Faaliyetini Durdurdu</strong>
          </div>
        );
      default:
        return null;
    }
  }
  onChangeStatus(status: StatusEnums) {
    const request: SeaTourismCertificationChangeStatusRequestModel = {
      id: this.props.SeaTourismCertificateId,
      status: status,
      deletedReason: this.props.formData.deletedReason

    };
    castThunkAction(this.props.changeStatus(request)).then((response: any) => {
      this.setState(
        {
          messageType: response ? MessageBarType.success : MessageBarType.error,
        },
        () => {
          if (response) this.props.setFormData("status", status);
        }
      );
    });
  }
  render() {
    const actionButton = (
      <FormButtons
        isAbsolute
        onSubmit={() => this.onSubmit()}
        customRenderButtons={() => (
          <React.Fragment>
            {/* {
          //firma ise
          usertype === "2" ? (
            //belge verildi ise
            hasCertificateStatusTypeId === 2 ?
              // webde yayınlandı ise
              (hasIsPublishWeb ?
                // revize istendi ise koy
                (this.props.formData.correctionType === 1 || this.props.formData.correctionType === 2 ?
                  this.getSaveButton()
                  // revize istenmedi ise koyma
                  : "")
                // webde yayınlanmadı ise
                : this.getSaveButton())
              //belge verilmedi ise koy
              : this.getSaveButton()
          )
            // firma değilse koy
            : this.getSaveButton()
        } */}

            {(!this.props.SequenceId) && this.getSaveButton()}
            {
              usertype === "2" && <PrimaryButton
                disabled={this.props.formData?.status !== 1}
                onClick={() => {
                  this.props.toggleConfirmDialog(
                    true,
                    "Kayıt Durumu, Sertifika Sürecinden Ayrıldı olarak düzenlenecektir ve tesis üzerindeki yetkileriniz düşürülecektir. Onaylıyor musunuz?",
                    () => this.props.leaveCertificationProcess({
                      endId: this.props.formData.endId,
                      id: this.props.formData.seaTourismCertificateId
                    })
                  );
                }}
                text={"Sertifika Sürecinden Ayrıl"}
                styles={{
                  root: {
                    background: "#FF4343",
                    borderColor: "#FF4350",
                  },
                }}
              />
            }
            {(!this.props.SequenceId) && usertype !== "2" && usertype !== "5" && this.props.formData?.status !== 30 &&(
              <PrimaryButton
                onClick={() => this.ToChangeCompanyUser()}
                disabled={this.state.isFileUploading ? true : false}
                iconProps={{ iconName: "CheckMark" }}
                text={
                  this.props.formData.status === StatusEnums.Published
                    ? "Firma Düşür"
                    : "Firma Düşür"
                }
                styles={{
                  root: {
                    background: "#FF4343",
                    borderColor: "#FF4350",
                  },
                }}
              />
            )}

            {(!this.props.SequenceId) && usertype !== "2" && usertype !== "5" ? (
              <>
                <PrimaryButton
                  onClick={() => this.SetUnread()}
                  iconProps={{ iconName: "" }}
                  text="Tesis Bildirimlerini Okunmadı Yap"
                  styles={{
                    root: {
                      background: "#169ac0",
                      borderColor: "#169ac0",
                    },
                    rootHovered: {
                      background: "#169ac0",
                      borderColor: "#169ac0",
                    },
                    rootPressed: {
                      background: "#169ac0",
                      borderColor: "#169ac0",
                    },
                    rootDisabled: {
                      background: "#169ac0",
                    },
                  }}
                />
                <PrimaryButton
                  onClick={() => this.RemovePublish(true)}
                  iconProps={{ iconName: "" }}
                  text="Web Sitesinden Kaldır"
                  styles={{
                    root: {
                      background: "#eb8f0e",
                      borderColor: "#eb8f0e",
                    },
                    rootHovered: {
                      background: "#eb8f0e",
                      borderColor: "#eb8f0e",
                    },
                    rootPressed: {
                      background: "#eb8f0e",
                      borderColor: "#eb8f0e",
                    },
                    rootDisabled: {
                      background: "#eb8f0e",
                    },
                  }}
                />
                <PrimaryButton
                  onClick={() => this.RemovePublish(false)}
                  iconProps={{ iconName: "" }}
                  text="QR Kodu Yayımını Durdur"
                  styles={{
                    root: {
                      background: "#eb8f0e",
                      borderColor: "#eb8f0e",
                    },
                    rootHovered: {
                      background: "#eb8f0e",
                      borderColor: "#eb8f0e",
                    },
                    rootPressed: {
                      background: "#eb8f0e",
                      borderColor: "#eb8f0e",
                    },
                    rootDisabled: {
                      background: "#eb8f0e",
                    },
                  }}
                />
              </>
            ) : (
              ""
            )}
            {this.state.isFileUploading && (
              <div className="tooltip-warning">
                Bazı dosyalar yükleniyor, lütfen dosyalar yüklenene kadar
                bekleyiniz.
              </div>
            )}

            {(!this.props.SequenceId) && usertype !== "2" && usertype !== "5" ? (
              <>
                <Dropdown
                  style={{ width: "280px", marginRight: "10px" }}
                  options={RECORD_CERTIFICATION_STATUS}
                  className="custom-dropdown "
                  selectedKey={this.props.formData?.status}
                  onChange={(event: any, option: any) => {
                    this.props.setFormData('isDeletedReason', option.key === StatusEnums.Deleted)
                    this.props.toggleConfirmDialog(
                      true,
                      "Kayıt durumunu değiştirmek istiyor musunuz?",
                      () => this.onChangeStatus(option.key)
                    );
                  }}
                />
              </>
            ) : (
              ""
            )}
            {this.renderStatusByType()}
          </React.Fragment>
        )}
      />
    );
    return (
      <div className="page push-all">
        {this.state.modalshow && (
          <Modal className={"warning custom-message-box danger"} isOpen={true}>
            <div className="modal-header row">
              <div className="title">Dosya Siliyorsunuz Emin misiniz?</div>
              <span
                onClick={() =>
                  this.setState({ modalshow: false, modalshow2: false })
                }
                className="close"
              >
                <Icon iconName="ChromeClose" />
              </span>
            </div>
            <div
              style={{ paddingBottom: 0 }}
              className="description modal-content"
            >
              <div className="modal-indicators">
                {this.state.modalshow2 ? (
                  <DefaultButton
                    style={{ marginTop: 15 }}
                    text="EVET"
                    onClick={() => {
                      let files = _.cloneDeep(
                        this.props.formData?.attachmentUnCertificateList
                      );
                      let findIndex = (files || []).findIndex(
                        (x: any) =>
                          x.attachmentId === this.state.modelattachmentId
                      );
                      if (findIndex !== -1) {
                        files.splice(findIndex, 1);
                      }
                      this.props.setFormData(
                        "attachmentUnCertificateList",
                        files
                      );
                      this.setState({ modalshow: false, modalshow2: false });
                    }}
                  />
                ) : (
                  <DefaultButton
                    style={{ marginTop: 15 }}
                    text="EVET"
                    onClick={() => {
                      let files = _.cloneDeep(
                        this.props.formData?.attachmentCertificateList
                      );
                      let findIndex = (files || []).findIndex(
                        (x: any) =>
                          x.attachmentId === this.state.modelattachmentId
                      );
                      if (findIndex !== -1) {
                        files.splice(findIndex, 1);
                      }
                      this.props.setFormData(
                        "attachmentCertificateList",
                        files
                      );
                      this.setState({ modalshow: false, modalshow2: false });
                    }}
                  />
                )}

                <DefaultButton
                  style={{ marginTop: 15 }}
                  text="iPTAL"
                  onClick={() =>
                    this.setState({ modalshow: false, modalshow2: false })
                  }
                />
              </div>
            </div>
          </Modal>
        )}
        {usertype && usertype != "2" && (
          <PageTitle
            titleRenderer={() => (
              <span className="edit-title">
                <strong>Sertifikayı Düzenle</strong>
                <strong>{this.props.formData?.title || ""}</strong>
              </span>
            )}
            showBack
            backUrl={(this.props.SequenceId) ? "/SeaTourism/edit/" +
              this.props.formData.seaTourismId : '/SeaTourismCertification'}

            history={this.props.history}
            subTitle="Değiştirmek istediğiniz alanları aşağıda bulabilirsiniz."
            createdInfo={{
              createdUser: this.props.formData.createdUserName,
              createdDate: this.props.formData.createDateString,
              updatedUser: this.props.formData.updatedUserName,
              updatedDate: this.props.formData.updateDateString,
            }}
            rightType="edit"
            right={actionButton}
          />
        )}
        {usertype && usertype == "2" && (
          <PageTitle
            title="İl Tipi Düzenle"
            titleRenderer={() => (
              <span className="edit-title">
                <strong>Sertifikayı Düzenle</strong>
                <strong>{this.props.formData?.title || ""}</strong>
              </span>
            )}
            showBack
            backUrl="/SeaTourismCertification"
            history={this.props.history}
            subTitle="Değiştirmek istediğiniz alanları aşağıda bulabilirsiniz."
            rightType="edit"
            right={actionButton}
          />
        )}
        {(usertype === "1" || usertype === "3") && <div className="changedFields">
          <label>Değişen Alanlar : </label>
          {this.showChangesField()}
        </div>}
        <div>Kayıt No : {this.props.formData.sequenceId}</div>
        {(this.props.formData && this.props.formData.seaTourismSubCategoryId === 1) ? `Deniz Turizm Tesis ID: ${this.props.formData.seaTourismId}`: `Deniz Turizm Araç ID: ${this.props.formData.seaTourismId}` } 
        {/* {this.renderMessageBar()} */}
        {this.renderForm()}
      </div>
    );
  }
  ToChangeCompanyUser() {
    var cc = window.confirm("Denetleyici firma düşecektir emin misiniz ?");
    if (cc) {
      castThunkAction(
        this.props.ChangeCompanyUser({
          id: this.props.formData.seaTourismCertificateId,
        })
      ).then((response: any) => { });
    }
  }
  showChangesField(): any {
    let desc = [];
    const changesField2: any[] = this.props.formData.changesField
      ? JSON.parse(this.props.formData.changesField)
      : [];
    for (let index = 0; index < changesField2.length; index++) {
      if (changesField2[index].tr)
        desc.push(<span>{changesField2[index].tr} </span>);
    }
    if (desc.length === 0) {
      desc.push(<span>Değişiklik Yok</span>);
    }
    return desc;
  }
  SetUnread(): any {
    const request: NotificationUpdateRequestModel = {
      CategoryType: 7,
      RelatedId: this.props.formData.seaTourismCertificateId,
      isRead: false,
    };
    castThunkAction(
      this.props.updateNotificationUnRead(request)
    ).then((response: any) => { });
  }
  RemovePublish(arg0: boolean): any {
    if (arg0) {
      castThunkAction(
        this.props.RemoveWebPublish({
          categoryType: 7,
          relatedId: this.props.formData.seaTourismCertificateId,
        })
      ).then((response: any) => {
        window.location.reload();
      });
    } else {
      castThunkAction(
        this.props.RemoveQrPublish({
          categoryType: 7,
          relatedId: this.props.formData.seaTourismCertificateId,
        })
      ).then((response: any) => {
        window.location.reload();
      });
    }
  }
  onAdditional() {
    window.open("/Additional/" + this.props.formData.seaTourismId + "/" + 7);
  }
  getSaveButton(): any {
    return (
      usertype !== "5" && (
        <PrimaryButton
          onClick={() => this.onSubmit()}
          disabled={usertype==="2" && this.props.formData?.status === 30 ? true : this.state.isFileUploading ? true : false}
          styles={{
            rootDisabled: {
              background: "#ececec",
              borderColor: "#ccc !important",
              opacity: 0.5,
            },
          }}
          iconProps={{ iconName: "CheckMark" }}
          text={onSaveText(this.props.formData.status)}
        />
      )
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSeaTourismCertification);
