import { HttpClient } from "src/library/HttpClient";
import {
  ThemedFacilityCertificationGetAllRequestModel,
  ThemedFacilityCertificationGetByIdEndIdRequestModel,
  ThemedFacilityCertificationSaveRequestModel,
  ThemedFacilityCertificationUpdateRequestModel,
  ThemedFacilityCertificationGetByIdRequestModel,
  ThemedFacilityCertificationChangeStatusRequestModel,
  RemovePublish,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";
import { ILeaveCertificationProcessRequest } from "../BaseEnum";

export default class ThemedFacilityCertification {
  async getAll(
    request: ThemedFacilityCertificationGetAllRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.ThemedFacilityCertification +
        "GetThemedFacilityCertificateList",
      { ...request, token }
    );
  }

  async save(
    request: ThemedFacilityCertificationSaveRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.ThemedFacilityCertification + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(
    request: ThemedFacilityCertificationUpdateRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.ThemedFacilityCertification + "SaveOrUpdate",
      { ...request, token }
    );
  }
  async updateBySequenceId(
    request: ThemedFacilityCertificationUpdateRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.ThemedFacilityCertification + "UpdateBySequenceId",
      { ...request, token }
    );
  }

  async getById(
    request: ThemedFacilityCertificationGetByIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.ThemedFacilityCertification + "GetById",
      { ...request, token }
    );
  }
  async getBySequenceId(
    request: ThemedFacilityCertificationGetByIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.ThemedFacilityCertification + "GetBySequenceId",
      { ...request, token }
    );
  }

  async getByEndId(
    request: ThemedFacilityCertificationGetByIdEndIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.ThemedFacilityCertification + "GetByEntityId",
      { ...request, token }
    );
  }

  async changeStatus(
    request: ThemedFacilityCertificationChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.ThemedFacilityCertification + "ChangeStatus",
      { ...request, token }
    );
  }

  async GetThemedFacilityCertificationAndRestaurantCount(
    request: ThemedFacilityCertificationGetAllRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.ThemedFacilityCertification +
        "GetThemedFacilityAndRestaurantCount",
      { ...request, token }
    );
  }
  async RemoveWebPublish(request: RemovePublish): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.ThemedFacilityCertification + "RemoveWebPublish",
      { ...request, token }
    );
  }
  async RemoveQrPublish(request: RemovePublish): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.ThemedFacilityCertification + "RemoveQrPublish",
      { ...request, token }
    );
  }
  async ChangeCompanyUser(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.ThemedFacilityCertification + "ChangeCompanyUser",
      { ...request, token }
    );
  }

  async excelDownload(request: any, name: string) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.ThemedFacilityCertification + "GenerateExcel",
      name,
      { ...request, token }
    );
  }
  async leaveCertificationProcess(
    request: ILeaveCertificationProcessRequest
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.ThemedFacilityCertification + "LeaveCertificationProcess",
      { ...request, token }
    );
  }

}
