import { HttpClient } from "src/library/HttpClient";
import { GetCompanyByDocumentNoRequest } from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class Company {
  async getAllCompany(request: GetCompanyByDocumentNoRequest): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.company + "GetCompanyByDocumentNo",
      { ...request, token }
    );
  }
}
