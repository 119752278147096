import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  TextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
} from "office-ui-fabric-react";
import { setFormData, setFormErrors } from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";
import { getById, changeStatus } from "../../store/thunkCertificateType";

import {
  CertificateTypeGetByIdRequestModel,
  CertificateTypeUpdateRequestModel,
} from "src/services/CertificateType/RequestModel";

import { castThunkAction } from "src/helpers/casting";
import { StatusEnums } from "src/services/BaseEnum";
import { update } from "src/store/thunkCertificateType";
import { requestMapData } from "src/helpers/functions";

interface OwnProps {
  history: History;
  CertificateTypeId: number;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        getById,
        changeStatus,
        update,
      },
      dispatch
    ),
  };
}

type EditCertificateTypeProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  messageType: MessageBarType;
}

class EditCertificateType extends Component<
  EditCertificateTypeProps,
  OwnState
> {
  state = {
    messageType: MessageBarType.warning,
  };

  UNSAFE_componentWillMount() {
    if (this.props.CertificateTypeId) {
      this.props.getById({
        id: this.props.CertificateTypeId,
      } as CertificateTypeGetByIdRequestModel);
    }
  }

  renderTextByMessageType() {
    switch (this.state.messageType) {
      case MessageBarType.success:
        return "Kaydetme işlemi başarıyla gerçekleşmiştir.";
      case MessageBarType.error:
        return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
      default:
        return "Lütfen aşağıdaki bilgileri doldurun, ve oluşturmayı tamamlayın!";
    }
  }

  renderMessageBar() {
    return (
      <MessageBar messageBarType={this.state.messageType} isMultiline={false}>
        {this.renderTextByMessageType()}
      </MessageBar>
    );
  }

  onSave(isDraft?: boolean) {
    const request: CertificateTypeUpdateRequestModel = {
      data: requestMapData({
        ...this.props.formData,
        status: isDraft ? StatusEnums.Draft : StatusEnums.Published,
      }),
    };
    castThunkAction(this.props.update(request)).then((response: any) => {
      this.setState(
        {
          messageType: response ? MessageBarType.success : MessageBarType.error,
        },
        () => {
          this.props.setFormData(
            "status",
            isDraft ? StatusEnums.Draft : StatusEnums.Published
          );
        }
      );
    });
  }

  onSubmit(isDraft?: boolean) {
    const { formData, setFormErrors } = this.props;

    const validationSchema = _yup.object({
      title: _yup.string().required("Bu alan zorunludur!"),
    });
    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});
        this.onSave(isDraft);
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  onChangeStatus(status: StatusEnums) {
    const request: CertificateTypeUpdateRequestModel = {
      data: requestMapData({ ...this.props.formData, status: StatusEnums.Deleted }),
    };
    castThunkAction(this.props.update(request)).then((response: any) => {
      this.setState(
        {
          messageType: response ? MessageBarType.success : MessageBarType.error,
        },
        () => {
          this.props.setFormData("status", StatusEnums.Deleted);
        }
      );
    });
  }

  renderForm() {
    const { formData, formErrors, setFormData } = this.props;
    return (
      <div className="form-groups row">
        <div className="form-group col-md-4">
          <div className="title">Genel Bilgiler</div>
          <div className="form-wrapper">
            <div className="item row">
              <TextField
                placeholder="Sertifika Adı"
                value={formData?.title || ""}
                label="Sertifika Adı"
                className="custom-textfield border"
                errorMessage={formErrors.title}
                onChange={(event: any) =>
                  setFormData("title", event.target.value)
                }
              />
            </div>
            <div className="item row">
              <TextField
                placeholder="Sertifika Adı İngilizce"
                value={formData?.title_En || ""}
                label="Sertifika Adı İngilizce"
                className="custom-textfield border"
                errorMessage={formErrors.title_En}
                onChange={(event: any) =>
                  setFormData("title_En", event.target.value)
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStatusByType() {
    switch (this.props.formData.status) {
      case StatusEnums.Published:
        return (
          <div className="status">
            Durum: <strong>Yayımda</strong>
          </div>
        );
      case StatusEnums.Draft:
        return (
          <div className="status">
            Durum: <strong>Taslak</strong>
          </div>
        );
      case StatusEnums.Deleted:
        return (
          <div className="status">
            Durum: <strong>Silindi</strong>
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="page push-all">
        <PageTitle
          titleRenderer={() => (
            <span className="edit-title">
              Düzenle:
              <strong>{this.props.formData?.title || ""}</strong>
            </span>
          )}
          showBack
          backUrl="/CertificateType"
          history={this.props.history}
          subTitle="Değiştirmek istediğiniz alanları aşağıda bulabilirsiniz."
          rightType="create"
          right={ <FormButtons
            isAbsolute
            onSubmit={() => this.onSubmit()}
            customRenderButtons={() => (
              <React.Fragment>
                {this.props.formData.status !== StatusEnums.Deleted && (
                  <PrimaryButton
                    onClick={() => this.onChangeStatus(StatusEnums.Deleted)}
                    iconProps={{ iconName: "Trash" }}
                    text="Sil"
                    styles={{
                      root: {
                        background: "#ac6262",
                        borderColor: "#ac6262",
                      },
                      rootHovered: {
                        background: "#a34c4c",
                        borderColor: "#a34c4c",
                      },
                      rootPressed: {
                        background: "#a34c4c",
                        borderColor: "#a34c4c",
                      },
                      rootDisabled: {
                        background: "#ececec",
                      },
                    }}
                  />
                )}
  
                <PrimaryButton
                  onClick={() => this.onSubmit()}
                  styles={{
                    rootDisabled: {
                      background: "#626262",
                      borderColor: "#777373 !important",
                    },
                  }}
                  iconProps={{ iconName: "CheckMark" }}
                  text={
                    this.props.formData.status === StatusEnums.Published
                      ? "Kaydet"
                      : "Yayımla"
                  }
                />
                {this.renderStatusByType()}
              </React.Fragment>
            )}
          />}
        />
        {this.renderMessageBar()}
       
        {this.renderForm()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCertificateType);
