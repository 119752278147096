import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { panels } from "src/utils/constants";
import { Link } from "react-router-dom";
import { History } from "history";
import BasicTable from "../BasicTable";
import {
  TextField,
  Dropdown,
  PrimaryButton,
  Label,
} from "office-ui-fabric-react";
import { getAll, changeStatus } from "src/store/thunkSubCategory";
import {
  SubCategoryGetAllRequestModel,
  ConditionOperatorEnum,
  SubCategoryChangeStatusRequestModel,
} from "src/services/SubCategory/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";
import { castThunkAction } from "src/helpers/casting";
import { toggleConfirmDialog } from "src/store/system/actions";
import { listCount } from "src/config/otherConfig";
import { getAllMA } from "src/store/thunkMainCategory";
import { MainCategoryGetAllRequestModel } from "src/services/MainCategory/RequestModel";
interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        getAll,
        changeStatus,
        toggleConfirmDialog,
        getAllMA,
      },
      dispatch
    ),
  };
}

type SubCategoryProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  pagerTotal: number;
}

class SubCategory extends Component<SubCategoryProps, OwnState> {
  state = {
    filterData: {
      SubCategoryName: "",
      mainCategoryId: 0,
      status: 1,
    },
    pagerCurrent: 0,
    pagerTotal: 0,
  };

  componentDidMount() {
    this.getData(this.state.pagerCurrent);
  }
  

  cleargetData(pagerSelected: number) {
    this.setState(
      {
        filterData: {
          SubCategoryName: "",
          mainCategoryId: 0,
          status: 1,
        },
      },
      () => {
        this.getData(pagerSelected);
      }
    );
  }
  getData(pagerSelected: number) {
    const request: SubCategoryGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "SubCategoryName",
          value: this.state.filterData.SubCategoryName,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Like,
        },
        {
          propertyName: "MainCategoryId",
          value: this.state.filterData.mainCategoryId,
          isOr: false,
          conditionOperator:
            this.state.filterData.mainCategoryId === 0
              ? ConditionOperatorEnum.NotEq
              : ConditionOperatorEnum.Eq,
        },
        {
          propertyName: "Status",
          value: this.state.filterData.status,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
      selectItemList: [
        "MainCategory.Title",
        "SubCategoryId",
        "SubCategoryName",
        "SubCategoryName_En",
        "UpdatedSystemUserName",
        "CreatedSystemUserName",
        "CreatedSystemUserDatetime",
        "UpdatedSystemUserDatetime",
        "Status",
      ],
      pageSize: listCount,
      pageIndex: pagerSelected,
    };

    castThunkAction(this.props.getAll(request)).then((response: any) => {
      const request2: MainCategoryGetAllRequestModel = {
        selectItemList: ["MainCategoryId", "Title"],
      };
      this.props.getAllMA(request2);
    });
  }

  onDelete(SubCategoryId: number) {
    const request: SubCategoryChangeStatusRequestModel = {
      id: SubCategoryId,
      status: StatusEnums.Deleted,
    };
    castThunkAction(this.props.changeStatus(request)).then((response: any) => {
      this.getData(this.state.pagerCurrent);
    });
  }

  renderDashboardItems() {
    return (
      <div className="dashboard-items row">
        {panels.map(
          (item, index) =>
            item.children && (
              <React.Fragment>
                <div className="title">
                  {item.text}
                  <div className="title-text">{item.subText}</div>
                </div>
                <div className="childrens row">
                  {item.children.map((children, childrenIndex) => (
                    <div key={index} className={`dashboard-item col-md-3`}>
                      <Link
                        className="row"
                        to={children.url ? children.url : "/"}
                      >
                        <div className="icon">
                          <i className={children.icon}></i>
                        </div>
                        <div className="title">
                          {children.text}
                          <div className="sub-text">{children.subText}</div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )
        )}
      </div>
    );
  }

  renderFilter() {
    return (
      <div className="table-filter row">
        <TextField
          className="custom-textfield col-md-3"
          label="Alt Kategori İsmi İle Arama"
          value={this.state.filterData?.SubCategoryName || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                SubCategoryName:
                  event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />

        <Dropdown
          onChanged={(event: any) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                mainCategoryId: event.key,
              },
            })
          }
          defaultSelectedKey={this.state.filterData?.mainCategoryId || ""}
          label="Ana Kategori"
          options={(
            this.props.formData.GroupListMA?.dataList || ([] as any)
          ).map((item: any) => ({
            key: item.MainCategoryId,
            text: item.Title,
          }))}
          className="custom-dropdown col-md-3"
        />

        <Dropdown
          options={[
            { key: 1, text: "Yayımda" },
            { key: 2, text: "Taslak" },
            { key: 8, text: "Silindi" },
          ]}
          className="custom-dropdown col-md-3"
          label="Duruma Göre Arama"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.status || 1}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                status: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <PrimaryButton
          text="Arama Yap"
          className="custom-button"
          onClick={() => this.getData(0)}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
        <PrimaryButton
          text="Tüm Filtreleri Temizle"
          className="custom-button"
          onClick={() => this.cleargetData(0)}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              marginLeft: "10px",
              height: "30px",
            },
          }}
        />
      </div>
    );
  }
  goEdit(hotelId: any): any {
    window.open("/SubCategory/edit/" + hotelId);
  }

  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "MainCategoryTitle",
            text: "Ana Kategori",
            col: "col-md-1",
          },
          {
            columnName: "SubCategoryName",
            text: "Alt Kategori",
            col: "col-md-1",
          },
          {
            columnName: "SubCategoryName_En",
            text: "Alt Kategori İngilizce",
            col: "col-md-1",
          },
          {
            columnName: "CreatedSystemUserName",
            text: "Kayıt Yapan",
            col: "col-md-2",
          },
          {
            columnName: "CreatedSystemUserDatetimeString",
            text: "Kayıt Tarihi",
            col: "col-md-1",
          },

          {
            columnName: "UpdatedSystemUserName",
            text: "Güncelleme Yapan",
            col: "col-md-2",
          },

          {
            columnName: "UpdatedSystemUserDatetimeString",
            text: "Güncelleme Tarihi",
            col: "col-md-2",
          },
          {
            columnName: "SubCategoryId",
            text: "İşlem",
            col: "col-md-2",
            className: "operations",
            onRender: (item: { SubCategoryId: any; Status: StatusEnums }) => (
              <React.Fragment>
                <button onClick={(x) => this.goEdit(item.SubCategoryId)}>
                  <i className="icon-edit-outline"></i> Düzenle
                </button>

                {item.Status !== StatusEnums.Deleted && (
                  <button
                    onClick={() =>
                      this.props.toggleConfirmDialog(
                        true,
                        "Silme işlemini gerçekleştirmek istiyor musunuz?",
                        () => this.onDelete(item.SubCategoryId)
                      )
                    }
                  >
                    <i className="icon-trash-outline"></i> Sil
                  </button>
                )}
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.dataList || []}
      />
    );
  }
  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.props.formData.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }
  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getData(selected);
  };
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Alt Kategori Yönetimi"
          subTitle="Yönetilebilecek tüm alanları aşağıdan görüntüleyebilirsiniz."
          backUrl="/"
          showBack
          history={this.props.history}
        />
        <PrimaryButton
          onClick={() => this.props.history.push("/SubCategory/create")}
          iconProps={{ iconName: "Add" }}
          className="absolute-create"
          text="Alt Kategori Ekle"
          styles={{
            root: {
              background: "#62a98e",
              borderColor: "#5b9e85",
            },
            rootHovered: {
              background: "#5b9e85",
              borderColor: "#5b9e85",
            },
            rootDisabled: {
              background: "#ececec",
            },
          }}
        />
        <Label className="totalRecord">
          Toplam Kayıt Sayısı: {this.props.formData?.dataCount}
        </Label>
        {this.renderFilter()}
        {this.renderList()}
        {this.renderPager()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubCategory);
