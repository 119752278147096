import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import Transport from "src/services/Transport/TransportService";
import {
  TransportGetAllRequestModel,
  TransportChangeStatusRequestModel,
  TransportGetByIdRequestModel,
  TransportUpdateRequestModel,
  TransportSaveRequestModel,
  RemovePublish,
  TransportCertificationUpdateBySequenceIdRequestModel,
} from "src/services/Transport/RequestModel";
import { MessageType } from "./system/types";
import { errorMessageSaveOrUpdate } from "src/helpers/functions";
import { formatDate } from "src/helpers/converts";
import { ILeaveCertificationProcessRequest } from "src/services/BaseEnum";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllPage(
  request: TransportGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const TransportService = new Transport();
    const result = await TransportService.getAll(request);
    dispatch(setFormData("excel", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: TransportGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const TransportService = new Transport();
    const result = await TransportService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function excelDownload(
  request: TransportGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const TransportService = new Transport();
    const excelName = "TurVeTransferAraclari" + formatDate();
    await TransportService.excelDownload(request, excelName);
    dispatch(toggleLoader());

    return false;
  };
}

export function save(
  request: TransportSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const TransportService = new Transport();
    const result = await TransportService.save(request);
    // dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return result;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}

export function update(
  request:
    | TransportUpdateRequestModel
    | TransportCertificationUpdateBySequenceIdRequestModel,
  sequence?: boolean
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const TransportService = new Transport();
    const result = sequence
      ? await TransportService.updateBySequenceId(
          request as TransportCertificationUpdateBySequenceIdRequestModel
        )
      : await TransportService.update(request);
    // dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}

export function getByEndId(
  request: TransportGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const TransportService = new Transport();
    const result = await TransportService.getByEndId(request);
    dispatch(
      initialFormData({
        ...result.data,
        initialAttachmentList:
          result.data && result.data.attachmentList
            ? result.data.attachmentList
            : [],
        initialAttachmentCertificateList:
          result.data && result.data.attachmentCertificateList
            ? result.data.attachmentCertificateList
            : [],
        initialAttachmentUnCertificateList:
          result.data && result.data.attachmentUnCertificateList
            ? result.data.attachmentUnCertificateList
            : [],
      })
    );
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getById(
  request: TransportGetByIdRequestModel,
  sequence?: boolean
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const TransportService = new Transport();
    const result = sequence
      ? await TransportService.getBySequenceId(request)
      : await TransportService.getById(request);
    if (result.success) {
      dispatch(
        initialFormData({
          ...result.data,
          intitialAppealDate: result.data.appealDate,
          initialAttachmentList: result.data.attachmentList,
          initialAttachmentCertificateList:
            result.data.attachmentCertificateList,
          initialAttachmentUnCertificateList:
            result.data.attachmentUnCertificateList,
        })
      );
    } else {
      dispatch(initialFormData({}));
    }

    dispatch(toggleLoader());
    return result.success;
  };
}

export function changeStatus(
  request: TransportChangeStatusRequestModel,
  isDelete: boolean = false,
  sequence?: boolean
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const TransportService = new Transport();
    const result = sequence
      ? await TransportService.changeStatusBySequenceId(request)
      : await TransportService.changeStatus(request);
    dispatch(toggleLoader());
    if (result.success) {
      // if (sequence && request.status === StatusEnums.Published) {
      //   return 2;
      // }else {
      dispatch(initialFormData(result));
      dispatch(
        showMessage(
          isDelete
            ? "Silme işlemi başarıyla değiştirildi."
            : "Kayıt durumu işlemi başarıyla değiştirildi.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
      // }
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getAllForPager(
  request: TransportGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const TransportService = new Transport();
    const result = await TransportService.getAll(request);
    dispatch(setFormData("dataList", result.dataList));
    dispatch(setFormData("pageCount", result.pageCount));
    dispatch(setFormData("dataCount", result.dataCount));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function RemoveWebPublish(
  request: RemovePublish
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const TransportService = new Transport();
    const result = await TransportService.RemoveWebPublish(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Sertifika Web Sitesinde Yayımdan Kaldırıldı",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}

export function RemoveQrPublish(
  request: RemovePublish
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const TransportService = new Transport();
    const result = await TransportService.RemoveQrPublish(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "QR Yayımdan Kaldırıldı",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}

export function leaveCertificationProcess(
  request: ILeaveCertificationProcessRequest
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const TransportService = new Transport();
    const result = await TransportService.leaveCertificationProcess(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "İşleminiz başarı ile gerçekleşmiştir.",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}
export function ChangeCompanyUser(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const TransportService = new Transport();
    const result = await TransportService.ChangeCompanyUser(request);
    //dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          ""
        )
      );
      return false;
    }
  };
}
