/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import { DatePicker } from "office-ui-fabric-react";
import {
    DayPickerStrings,
    firstDayOfWeek,
    onFormatDate,
} from "src/config/otherConfig";
import { toDate, toTimeStamp } from "src/helpers/converts";

interface OwnProps {
    onChangeStartDate: any
    onChangeEndDate: any
    extendedClosedStartDate: number;
    extendedClosedEndDate: number
}

function mapStateToProps(state: RootState) {
    return {
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        ...bindActionCreators(
            {
            },
            dispatch
        ),
    };
}

type ValidityDateFilterProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    OwnProps;

interface OwnState {
   
}


class ValidityDateFilter extends Component<ValidityDateFilterProps, OwnState> {
    state = {
        
    };

    getDate(date: any): any {
        if (date !== 0) {
            return toDate(date, "");
        }
    }




    render() {

        const { onChangeStartDate, onChangeEndDate } = this.props;

        return <>
            <DatePicker
                strings={DayPickerStrings}
                firstDayOfWeek={firstDayOfWeek}
                onSelectDate={(date) => {
                    onChangeStartDate(toTimeStamp(date?.toString()))
                    this.setState({
                        "extendedClosedStartDate":
                            toTimeStamp(date?.toString())
                    })
                }
                }
                className="col-md-2"
                label="Başlangıç Sertifika Geçerlilik Tarihi"
                placeholder="gün.ay.yıl"
                formatDate={onFormatDate}
                value={this.getDate(
                    this.props && this.props.extendedClosedStartDate
                        ? this.props.extendedClosedStartDate
                        : 0
                )}
            />
            <DatePicker
                strings={DayPickerStrings}
                firstDayOfWeek={firstDayOfWeek}
                onSelectDate={(date) => {
                    onChangeEndDate(toTimeStamp(date?.toString()))
                    this.setState({
                        "extendedClosedEndDate":
                            toTimeStamp(date?.toString())
                    })
                }}
                className="col-md-2"
                label="Bitiş Sertifika Geçerlilik Tarihi"
                placeholder="gün.ay.yıl"
                formatDate={onFormatDate}
                value={this.getDate(
                    this.props && this.props.extendedClosedEndDate
                        ? this.props.extendedClosedEndDate
                        : 0
                )}
                minDate={this.getDate(
                    this.props.extendedClosedStartDate === null
                        ? 0
                        : this.props.extendedClosedStartDate
                )}
            />
        </>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ValidityDateFilter);
