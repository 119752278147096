import { StatusEnums } from "../BaseEnum";

export interface CertificateStatusTypeSaveDataModel {
  title: string;
  title_En: string;
  title_Ru: string;
  title_De: string;
  value: number;
  status: StatusEnums;
  cssClass: string;
}
export interface CertificateStatusTypeSaveRequestModel {
  data: CertificateStatusTypeSaveDataModel;
}

export interface CertificateStatusTypeUpdateDataModel {
  certificateStatusTypeId: number;
  title: string;
  title_En: string;
  title_Ru: string;
  title_De: string;
  value: number;
  status: StatusEnums;
  cssClass: string;
}

export interface CertificateStatusTypeUpdateRequestModel {
  data: CertificateStatusTypeUpdateDataModel;
}

export interface CertificateStatusTypeChangeStatusRequestModel {
  status: StatusEnums;
  id: number;
}

export enum ConditionOperatorEnum {
  Eq = 0,
  NotEq = 1,
  Like = 2,
  Gt = 3,
  Lt = 4,
  Ge = 5,
  Le = 6,
}

export interface criteriaItemListData {
  propertyName: string;
  value: any;
  isOr: boolean;
  conditionOperator: ConditionOperatorEnum;
}

export interface CertificateStatusTypeGetAllRequestModel {
  criteriaItemList?: criteriaItemListData[];
  selectItemList?: string[];
  pageSize?: number;
  pageIndex?: number;
  orderField?: string;
  isDescending?: boolean;
  status?: StatusEnums;
}
export interface CertificateStatusTypeGetByIdRequestModel {
  id: number;
}

export interface CertificateStatusTypeChangeStatusRequestModel {
  id: number;
  status: StatusEnums;
}
