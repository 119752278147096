import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  TextField,
  MessageBarType,
  PrimaryButton,
  Dropdown,
  DatePicker,
  Label,
} from "office-ui-fabric-react";
import {
  setFormData,
  setFormErrors,
  initialFormData,
} from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";

import {
  getByEndId,
  update,
  changeStatus,
} from "src/store/thunkThemedFacilityCertification";
import {
  RemoveWebPublish,
  RemoveQrPublish,
} from "src/store/thunkHotelCertification";
import { castThunkAction } from "src/helpers/casting";
import {
  ThemedFacilityCertificationGetByIdRequestModel,
  ThemedFacilityCertificationUpdateRequestModel,
  ThemedFacilityCertificationChangeStatusRequestModel,
} from "src/services/ThemedFacilityCertification/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";

import { qrUrl, apiConfig } from "src/config/apiConfig";

import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import {
  getAllTy,
  showErrorDialog,
  showChangesFieldDialog,
} from "src/store/thunkCertificateType";
import { getAllCR } from "src/store/thunkCertificateStatusType";
import { getCompanyUserList } from "src/store/thunksSystemUser";
import { getAllCompany } from "src/store/thunksCompany";
import _ from "lodash";

import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";
import { CertificateTypeGetAllRequestModel } from "src/services/CertificateType/RequestModel";
import { CertificateStatusTypeGetAllRequestModel } from "src/services/CertificateStatusType/RequestModel";
import cookie from "react-cookies";
import {
  DayPickerStrings,
  firstDayOfWeek,
  onFormatDate,
} from "src/config/otherConfig";
import { toTimeStamp, toDate } from "src/helpers/converts";
import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";
import { FileUpload } from "../FileUpload";
import { NotificationUpdateRequestModel } from "src/services/Notification/RequestModel";
import {
  updateNotification,
  updateNotificationUnRead,
} from "src/store/thunkNotification";
import { certificateAppealStatusDate, formatSize, getChangesFieldGlobal, onSaveText, requestMapData, sertifikaTarihiBayram } from "src/helpers/functions";
import CertificateStatus from "../CertificateStatus";
import { certificateStatusEnum } from "src/helpers/enums";
import QrCode from "../QrCode";

let token = "";
// let hasCertificateStatusTypeId = 0;
// let hasIsPublishWeb = false;

interface OwnProps {
  history: History;
  ThemedFacilityCertificateId: number;
}
function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        initialFormData,
        getByEndId,
        getAllM,
        getAllC,
        getAllTy,
        getAllCR,
        update,
        getAllCompany,
        getCompanyUserList,
        showErrorDialog,
        RemoveWebPublish,
        RemoveQrPublish,
        updateNotification,
        updateNotificationUnRead,
        showChangesFieldDialog,
        changeStatus,
      },
      dispatch
    ),
  };
}

type CreateThemedFacilityCertificationProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  messageType: MessageBarType;
  infoMessageGetDocumentNo: string;
  isFileUploading: boolean;
  fileUploadContainer: any;
  changesFieldHtml: any;
}
let oldData: any = {};
let usertype: any = 0;
let hasCertificateStatusTypeId = 0;
let hasCorrectionTypeId = 0;

class EditThemedFacilityCertification extends Component<
  CreateThemedFacilityCertificationProps,
  OwnState
> {
  state = {
    messageType: MessageBarType.warning,
    infoMessageGetDocumentNo: "",
    isFileUploading: false,
    fileUploadContainer: "",
    changesFieldHtml: "",
  };

  UNSAFE_componentWillMount() {
    token = cookie.load("userId");
    this.props.initialFormData({
      status: StatusEnums.Published,
    });
    usertype = cookie.load("systemUserInfo");

    if (this.props.ThemedFacilityCertificateId) {
      castThunkAction(
        this.props.getByEndId({
          id: +this.props.ThemedFacilityCertificateId,
        } as ThemedFacilityCertificationGetByIdRequestModel)
      ).then((response: any) => {
        const request: NotificationUpdateRequestModel = {
          CategoryType: 1,
          RelatedId: this.props.formData
            .themedFacilityCertificateId,
          isRead: true,
        };
        castThunkAction(
          this.props.updateNotification(request)
        ).then((response: any) => { });
        hasCertificateStatusTypeId = this.props.formData
          .certificateStatusTypeId;
        hasCorrectionTypeId = this.props.formData.correctionType;

        this.renderFileUpload();
        this.props.setFormData(
          "certificateNo",
          this.props.formData?.certificateNo?.slice(
            this.props.formData.certificateNo.indexOf("-") + 1
          )
        );
        oldData = Object.assign(this.props.formData, {});

        // this.props.formData.webSite === ""
        //   ? this.props.setFormData("webSite", null)
        //   : this.props.setFormData("webSite", this.props.formData.webSite);

        const request2: CityGetAllRequestModel = {
          selectItemList: ["CityId", "CityName"],
          orderField: "CityName",
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllM(request2);

        const request4: CertificateTypeGetAllRequestModel = {
          selectItemList: ["CertificateTypeId", "Title"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllTy(request4);

        const request5: CertificateStatusTypeGetAllRequestModel = {
          selectItemList: ["CertificateStatusTypeId", "Title"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllCR(request5);

        const request6: SystemUserGetAllModel = {
          systemUserType: 2,
        };
        castThunkAction(this.props.getCompanyUserList(request6)).then(
          (x: any) => {
            if (usertype !== "2" && usertype !== "5") {
              const prefix = this.props.formData.CompanyUserList.dataList.filter(
                (x: any) => x.systemUserId === this.props.formData.companyUserId
              )[0].prefix;

              this.props.setFormData("prefix", prefix);
            } else {
              this.props.setFormData("prefix", cookie.load("prefix"));
            }
          }
        );

        this.props.formData?.status === 30 &&
          this.props.setFormData("companyUserId", 0);

          
        this.CityChange(
          this.props.formData.cityId,
          this.props.formData.districtId
        );
        this.props.formData.appealDate === 0 &&
          this.props.setFormData("appealDate", null);
        this.props.formData.confirmationDate === 0 &&
          this.props.setFormData("confirmationDate", null);
      });
    }
  }

  setPrefix(): any {
    const el = document.createElement("span");
    el.className = "CertificateNo-span prefixSpan";
    el.id = "CertificateNo-span";
    el.innerHTML =
      usertype === "2"
        ? cookie.load("prefix") + "-"
        : this.props.formData.prefix + "-";
    const div = document.getElementById("CertificateNo");

    if (!document.getElementById("CertificateNo-span")) {
      this.insertBefore(div, el);
    } else {
      document.getElementById("CertificateNo-span")?.remove();
      this.insertBefore(div, el);
    }
    return false;
  }
  insertAfter(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  insertBefore(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode);
  }

  setDdlDescription(id: string, message: string) {
    const el = document.createElement("span");
    el.innerHTML = message;
    const div = document.getElementById(id);
    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div, el);
    }
  }

  setLabelDescription(id: string, message: string) {
    const el2 = document.createElement("span");
    el2.innerHTML = message;
    const div2 = document.querySelectorAll('[for="' + id + '"]');

    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div2[0], el2);
    }
  }

  onSave(isDraft?: boolean) {
    let item = certificateStatusEnum.find(
      (x: any) =>
        JSON.stringify(x.list) ===
        JSON.stringify(
          this.props.formData.checkedStatusList.sort((a: any, b: any) =>
            a > b ? 1 : -1
          )
        )
    );


    this.props.setFormData("certificateStatus", item ? item.key : null)

    const request: ThemedFacilityCertificationUpdateRequestModel = {
      data: requestMapData({
        ...this.props.formData,
        status: isDraft ? StatusEnums.Draft : this.props.formData.status,
        confirmationDate:
          this.props.formData.certificateStatusTypeId === 1
            ? 0
            : this.props.formData.confirmationDate,
      }),
    };
    if (
      this.props.formData.attachmentList &&
      this.props.formData.attachmentList.length > 0
    ) {
      request.data["attachmentIdList"] = (
        this.props.formData.attachmentList || []
      ).map((item: any) => item.attachmentId);
    }
    if (
      this.props.formData.attachmentCertificateList &&
      this.props.formData.attachmentCertificateList.length > 0
    ) {
      request.data["attachmentCertificateIdList"] = (
        this.props.formData.attachmentCertificateList || []
      ).map((item: any) => item.attachmentId);
    }
    // hasCertificateStatusTypeId = this.props.formData.certificateStatusTypeId
    // hasIsPublishWeb = this.props.formData.isPublishWeb
    if (this.props.formData.confirmationDate === null) {
      this.props.formData.confirmationDate = 0;
    }
    hasCertificateStatusTypeId = this.props.formData.certificateStatusTypeId;
    hasCorrectionTypeId = this.props.formData.correctionType;

    getChangesFieldGlobal(usertype, oldData, this.props.formData).then((x: any) => {
      this.props.setFormData("changesField", JSON.stringify(x));
      request.data["changesField"] = JSON.stringify(x);
      castThunkAction(this.props.update(request)).then((response: any) => {
        this.props.setFormData(
          "initialAttachmentList",
          this.props.formData.attachmentList
        );
        this.props.setFormData(
          "initialAttachmentCertificateList",
          this.props.formData.attachmentCertificateList
        );
        this.setState(
          {
            messageType: response
              ? MessageBarType.success
              : MessageBarType.error,
          },
          () => {
            this.props.setFormData(
              "status",
              isDraft ? StatusEnums.Draft : this.props.formData.status
            );
          }
        );
      });
    });
  }


  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  removeElement(id: any) {
    var elem = document.getElementById(id);
    return elem?.parentNode?.removeChild(elem);
  }
  filterFileds(val: any) {
    if (
      val !== "" &&
      val !== 0 &&
      val !== "0" &&
      val !== null &&
      val !== "null" &&
      val !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  }
  onSubmit(isDraft?: boolean) {
    let desc = "";

    if (!this.filterFileds(this.props.formData.appealDate)) {
      desc += "Sertifika Başvuru Tarihi,  <br/>";
    }
    if (usertype && usertype !== "2" && usertype !== "5") {
      if (!this.filterFileds(this.props.formData.companyUserId)) {
        desc += "Denetleyici Firma <br/> ";
      }
    }
    if (this.props.formData.certificateStatusTypeId === 2) {
      if (!this.filterFileds(this.props.formData.confirmationDate)) {
        desc += "Sertifika Düzenleme Tarihi,  <br/>";
      }
      if (!this.filterFileds(this.props.formData.certificateNo)) {
        desc += "Sertifika No,  <br/>";
      }
    }

    const request = {
      title: "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
      desc: desc,
    };
    if (desc !== "") {
      castThunkAction(
        this.props.showErrorDialog(request)
      ).then((response: any) => { });
    }

    if (
      this.props.formData.appealDate &&
      this.props.formData.appealDate !== "" &&
      this.props.formData.appealDate !== null &&
      this.props.formData.appealDate !== 0 &&
      this.props.formData.appealDate !== "null" &&
      this.props.formData.appealDate !== "0"
    ) {
      this.removeElement("appealDate-span");
    } else {
      const el = document.createElement("span");
      el.className = "errorspan";
      el.innerHTML =
        "<br/> <span id='appealDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
      const div = document.getElementById("appealDate");
      if (!document.getElementById("appealDate-span")) {
        this.insertAfter(div, el);
      }
    }

    if (this.props.formData.certificateStatusTypeId === 2) {
      if (
        this.props.formData.confirmationDate &&
        this.props.formData.confirmationDate !== "" &&
        this.props.formData.confirmationDate !== null &&
        this.props.formData.confirmationDate !== 0 &&
        this.props.formData.confirmationDate !== "null" &&
        this.props.formData.confirmationDate !== "0"
      ) {
        this.removeElement("confirmationDate-span");
      } else {
        const el = document.createElement("span");
        el.className = "errorspan";
        el.innerHTML =
          "<br/> <span id='confirmationDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
        const div = document.getElementById("confirmationDate");
        if (!document.getElementById("confirmationDate-span")) {
          this.insertAfter(div, el);
        }
      }
    } else {
      this.removeElement("confirmationDate-span");
    }

    const { formData, setFormErrors } = this.props;
    const validationSchema = _yup.object({
      companyName: _yup.string().required("Bu alan zorunludur!"),
      brandName: _yup.string().required("Bu alan zorunludur!"),
      certificateStatusTypeId: _yup.string().required("Bu alan zorunludur!"),
      cityId: _yup.string().required("Bu alan zorunludur!"),
      districtId: _yup.string().required("Bu alan zorunludur!"),
      companyUserId: _yup.string().required("Bu alan zorunludur!"),

      certificateNo:
        this.props.formData.certificateStatusTypeId === 2
          ? _yup.string().required("Bu alan zorunludur!")
          : _yup.string().nullable(),
      appealDate: _yup.string().required("Bu alan zorunludur!"),
      confirmationDate:
        this.props.formData.certificateStatusTypeId === 2
          ? _yup.string().required("Bu alan zorunludur!")
          : _yup.string().nullable(),

      webSite: _yup
        .string()
        .matches(
          // eslint-disable-next-line
          /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "Geçersiz Web Sitesi"
        )
        .notRequired()
        .nullable(),
      roomCount:
        this.props.formData.certificateTypeId === 4
          ? _yup.string().required("Bu alan zorunludur!")
          : _yup.string().nullable(),
      bedCount:
        this.props.formData.certificateTypeId === 4
          ? _yup.string().required("Bu alan zorunludur!")
          : _yup.string().nullable(),
      themedFacilityEmail: _yup.string().required("Bu alan zorunludur!"),
      themedFacilityPhone: _yup.string().required("Bu alan zorunludur!"),
      themedFacilityManagerName: _yup.string().required("Bu alan zorunludur!"),
    });

    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});
        if (
          this.props.formData.appealDate &&
          this.props.formData.appealDate !== "" &&
          this.props.formData.appealDate !== null &&
          this.props.formData.appealDate !== 0 &&
          this.props.formData.appealDate !== "null" &&
          this.props.formData.appealDate !== "0"
        ) {
          this.removeElement("appealDate-span");

          if (this.props.formData.certificateStatusTypeId === 2) {
            if (
              this.props.formData.confirmationDate &&
              this.props.formData.confirmationDate !== "" &&
              this.props.formData.confirmationDate !== null &&
              this.props.formData.confirmationDate !== 0 &&
              this.props.formData.confirmationDate !== "null" &&
              this.props.formData.confirmationDate !== "0"
            ) {
              this.removeElement("appealDate-span");
              this.removeElement("confirmationDate-span");

              if (
                usertype !== 2 ||
                (this.props.formData.attachmentList &&
                  this.props.formData.attachmentList.length > 0)
              ) {
                this.onSave(isDraft);
              } else {
                castThunkAction(
                  this.props.showErrorDialog({
                    title:
                      "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
                    desc: "İşletme belgesi yükleyiniz",
                  })
                ).then((response: any) => { });
              }
            } else {
              const el = document.createElement("span");
              el.className = "errorspan";
              el.innerHTML =
                "<br/> <span id='confirmationDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
              const div = document.getElementById("confirmationDate");
              if (!document.getElementById("confirmationDate-span")) {
                this.insertAfter(div, el);
              }
            }
          } else {
            this.removeElement("appealDate-span");
            this.removeElement("confirmationDate-span");
            if (
              usertype !== 2 ||
              (this.props.formData.attachmentList &&
                this.props.formData.attachmentList.length > 0)
            ) {
              this.onSave(isDraft);
            } else {
              castThunkAction(
                this.props.showErrorDialog({
                  title:
                    "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
                  desc: "İşletme belgesi yükleyiniz",
                })
              ).then((response: any) => { });
            }
          }
        } else {
          const el = document.createElement("span");
          el.className = "errorspan";
          el.innerHTML =
            "<br/> <span id='appealDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
          const div = document.getElementById("appealDate");
          if (!document.getElementById("appealDate-span")) {
            this.insertAfter(div, el);
          }
        }
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  CityChange(item: any, districtId?: any) {
    const request2: CountyGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "cityId",
          value: item,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
      selectItemList: ["DistrictId", "DistrictName"],
      orderField: "DistrictName",
    };
    castThunkAction(this.props.getAllC(request2)).then((response: any) => {
      this.props.setFormData("districtId", districtId);
    });
  }

  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Seçiniz" });
    }

    return newArray;
  }

  renderFileUpload() {
    let fileuploadStatus = false;
    hasCorrectionTypeId === 1
      ? (fileuploadStatus = true)
      : usertype === "2" && hasCertificateStatusTypeId === 2
        ? (fileuploadStatus = false)
        : (fileuploadStatus = true);

    if (fileuploadStatus) {
      this.setState({
        ...this.state,
        fileUploadContainer: (
          <FileUpload
            onProcessStartFile={() => {
              this.setState({
                isFileUploading: true,
              });
            }}
            onProcessEndFile={(queue: boolean) => {
              if (!queue) {
                this.setState({
                  isFileUploading: false,
                });
              }
            }}
            onTransferedFile={(file: any, promise?: boolean) => {
              this.props.setFormData("attachmentList", [
                ...(this.props.formData?.attachmentList || []),
                file.attachmentIdList[0],
              ]);
            }}
            requestKey="files"
            token={token}
            endpointUrl={apiConfig.prefixes.attachment + "UploadFile"}
          />
        ),
      });
    }
  }

  renderForm() {
    const { formData, formErrors, setFormData } = this.props;
    return (
      <div className="form-groups row">
        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="title">Sertifika Belgesi</div>

            {this.props.formData.attachmentCertificateList &&
              this.props.formData.attachmentCertificateList.length > 0 && (
                <React.Fragment>
                  <div className="title">Yüklenen Belgeler</div>
                  {JSON.stringify(
                    this.props.formData.initialAttachmentCertificateList
                  ) !==
                    JSON.stringify(
                      this.props.formData.attachmentCertificateList
                    ) && (
                      <div className="file-save-warning">
                        Düzenlemeleri ve yüklenen belgeleri kaydetmek için, sağ
                        üst kısımdan "Kaydet veya Yayımla"ya basın.
                      </div>
                    )}
                  <div className="uploaded-files">
                    {(this.props.formData?.attachmentCertificateList || []).map(
                      (
                        item: {
                          attachmentId: number;
                          attachmentPath: string;
                          fileName: string;
                          fileSize: number;
                          createDateString: string;
                        },
                        index: number
                      ) => (
                        <div key={index} className="uploaded-file">
                          <div className="name row">
                            <span className="text col no-padding">
                              {item.fileName}-{item.createDateString}
                              <strong className="size m-left-auto">
                                {formatSize(item.fileSize)}
                              </strong>
                            </span>
                            <div className="buttons m-left-auto row">
                              <button
                                onClick={() =>
                                  window.open(
                                    qrUrl + item.attachmentPath,
                                    "_blank"
                                  )
                                }
                                className="view"
                              >
                                <i className="icon-explore"></i> Görüntüle
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </React.Fragment>
              )}
          </div>
        </div>

        <aside className="col-md-12" style={{ padding: 0, display: "flex" }}>
          <div className="form-group col-md-4">
            <div className="form-wrapper">
              {usertype && usertype !== "2" && usertype !== "5" ? (
                <>
                  <div className="item row">
                    <Dropdown
                      disabled
                      onChanged={(event: any) =>
                        setFormData("isPublishWeb", event.key)
                      }
                      label="Web Sitesinde Yayımlansın mı?"
                      options={[
                        { key: false, text: "Hayır" },
                        { key: true, text: "Evet" },
                      ].map((item: any) => ({
                        key: item.key,
                        text: item.text,
                      }))}
                      className="custom-dropdown"
                      selectedKey={this.props.formData.isPublishWeb}
                    />
                  </div>
                  <div className="item row">
                    <Dropdown
                      disabled
                      onChanged={(event: any) => (
                        setFormData("companyUserId", event.key),
                        setFormData("prefix", event.prefix),
                        setFormData("certificateNo", "")
                      )}
                      label="Denetleyici Firma"
                      options={(
                        this.props.formData.CompanyUserList?.dataList ||
                        ([] as any)
                      ).map((item: any) => ({
                        key: item.systemUserId,
                        text: item.title,
                        prefix: item.prefix,
                      }))}
                      className="custom-dropdown"
                      selectedKey={this.props.formData.companyUserId}
                      errorMessage={formErrors.companyUserId}
                    />
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="item row">
                <DatePicker
                  disabled
                  strings={DayPickerStrings}
                  firstDayOfWeek={firstDayOfWeek}
                  onSelectDate={(date) =>
                    setFormData("appealDate", toTimeStamp(date?.toString()))
                  }
                  label="Sertifika Başvuru Tarihi"
                  placeholder="gün.ay.yıl"
                  formatDate={onFormatDate}
                  isRequired={true}
                  value={this.getDate(
                    this.props.formData.appealDate === null
                      ? 0
                      : this.props.formData.appealDate
                  )}
                  minDate={certificateAppealStatusDate(
                    usertype,
                    true,
                    (this.props.formData.intitialAppealDate ? this.props.formData.intitialAppealDate : this.props.formData.appealDate)
                  )}
                  maxDate={new Date()}
                />
              </div>

              <div className="item row">
                {usertype && usertype === "2" ? (
                  <Dropdown
                    disabled
                    onChanged={(event: any) =>
                      setFormData("correctionType", event.key)
                    }
                    label="Revize Durumu"
                    options={[
                      { key: 1, text: "Revize İstendi", disabled: true },
                      {
                        key: 2,
                        text: "Revize Yapıldı",
                        disabled:
                          this.props.formData?.correctionType === 1
                            ? false
                            : true,
                      },
                      { key: 3, text: "Revize Onaylandı", disabled: true },
                    ]}
                    className="custom-dropdown"
                    selectedKey={this.props.formData.correctionType}
                  />
                ) : (
                  <Dropdown
                    disabled
                    onChanged={(event: any) =>
                      setFormData("correctionType", event.key)
                    }
                    label="Revize Durumu"
                    options={[
                      { key: 1, text: "Revize İstendi" },
                      { key: 2, text: "Revize Yapıldı" },
                      { key: 3, text: "Revize Onaylandı" },
                    ].map((item: any) => ({
                      key: item.key,
                      text: item.text,
                    }))}
                    className="custom-dropdown"
                    selectedKey={this.props.formData.correctionType}
                  />
                )}
              </div>
              <div className="item row">
                <div
                  style={{
                    display:
                      formData.correctionPerson === "" ||
                        formData.correctionPerson === null ||
                        formData.correctionPerson === undefined ||
                        formData.correctionDescription === "" ||
                        formData.correctionDescription === null ||
                        formData.correctionDescription === undefined
                        ? "none"
                        : "block",
                  }}
                >
                  <Label style={{ width: "100%" }}>
                    <b>Revize İsteyen: {formData.correctionPerson}</b>
                  </Label>
                </div>
              </div>
              <div className="item row">
                {usertype && usertype === "2" ? (
                  <div
                    style={{
                      display:
                        formData.correctionDescription === "" ||
                          formData.correctionDescription === null ||
                          formData.correctionDescription === undefined
                          ? "none"
                          : "block",
                    }}
                  >
                    <Label style={{ width: "100%" }}>
                      <b>Revize Açıklama</b>
                    </Label>
                    <Label>{formData.correctionDescription}</Label>
                  </div>
                ) : (
                  <>
                    <label className="correctionDescriptionLabel">
                      Revize Açıklaması
                    </label>
                    <textarea
                      disabled
                      placeholder="Revize Açıklaması"
                      value={formData.correctionDescription || ""}
                      rows={20}
                      className="custom-textfield border correctionDescription"
                      onChange={(event: any) =>
                        setFormData(
                          "correctionDescription",
                          event.target.value === "" ? null : event.target.value
                        )
                      }
                    ></textarea>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="form-group col-md-4">
            <div className="form-wrapper">
              <div className="item row">
                <CertificateStatus />
              </div>
              <div className="item row">
                {usertype && usertype === "2" ? (
                  <Dropdown
                    onChanged={(event: any) =>
                      event.key !== 0
                        ? setFormData("certificateStatus", event.key)
                        : setFormData("certificateStatus", null)
                    }
                    disabled
                    label="Durum"
                    options={[
                      { key: 0, text: "Seçiniz" },
                      { key: 1, text: "Sertifika Uzatmalı Kapalı" },
                      { key: 2, text: "Firma Sertifika Sürecinden Ayrıldı" },
                      {
                        key: 3,
                        text: "Kare Kodsuz Sertifikalı",
                        disabled: true,
                      },
                    ]}
                    className="custom-dropdown"
                    selectedKey={
                      this.props.formData.certificateStatus !== null
                        ? this.props.formData.certificateStatus
                        : 0
                    }
                  />
                ) : (
                  <Dropdown
                    onChanged={(event: any) =>
                      event.key !== 0
                        ? setFormData("certificateStatus", event.key)
                        : setFormData("certificateStatus", null)
                    }
                    disabled
                    label="Durum"
                    options={[
                      { key: 0, text: "Seçiniz" },
                      { key: 1, text: "Sertifika Uzatmalı Kapalı" },
                      { key: 2, text: "Firma Sertifika Sürecinden Ayrıldı" },
                      { key: 3, text: "Kare Kodsuz Sertifikalı" },
                    ]}
                    className="custom-dropdown"
                    selectedKey={
                      this.props.formData.certificateStatus !== null
                        ? this.props.formData.certificateStatus
                        : 0
                    }
                  />
                )}
              </div>

              {this.props.formData.certificateStatusTypeId === 2 &&
                this.props.formData.prefix ? (
                this.props.formData.prefix ? (
                  <>
                    <div className="item row">
                      <DatePicker
                        disabled
                        strings={DayPickerStrings}
                        firstDayOfWeek={firstDayOfWeek}
                        onSelectDate={(date) =>
                          setFormData(
                            "confirmationDate",
                            toTimeStamp(date?.toString())
                          )
                        }
                        label="Sertifika Düzenleme Tarihi"
                        placeholder="gün.ay.yıl"
                        formatDate={onFormatDate}
                        isRequired={
                          this.props.formData.certificateStatusTypeId === 2
                        }
                        value={this.getDate(
                          formData.confirmationDate === null
                            ? 0
                            : formData.confirmationDate
                        )}
                        maxDate={sertifikaTarihiBayram(usertype)}

                      />
                    </div>
                    <div className="item row">
                      <TextField
                        disabled
                        id="CertificateNo"
                        placeholder="Sertifika No"
                        value={formData.certificateNo || ""}
                        label="Sertifika No"
                        className="custom-textfield border"
                        onChange={(event: any) =>
                          setFormData("certificateNo", event.target.value)
                        }
                        errorMessage={formErrors.certificateNo}
                      />
                    </div>
                    {usertype && usertype !== "2" && usertype !== "5" && (
                      <div className="item row">
                        <Dropdown
                          disabled
                          onChanged={(event: any) =>
                            setFormData("isPublishQr", event.key)
                          }
                          label="Sertifika QR Kodu Yayımlansın mı?"
                          options={[
                            { key: false, text: "Hayır" },
                            { key: true, text: "Evet" },
                          ].map((item: any) => ({
                            key: item.key,
                            text: item.text,
                          }))}
                          className="custom-dropdown"
                          selectedKey={this.props.formData.isPublishQr}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  this.showDialog({
                    title: "Uyarı",
                    desc:
                      "Sertifika Numarası için firma kodu Yok <br/> Denetleyici Firma Seçiniz",
                  })
                )
              ) : (
                ""
              )}
              {this.props.formData.certificateStatus !== 3 && (
                <QrCode />
              )}
            </div>
          </div>
        </aside>
      </div>
    );
  }
  showDialog(req: any): any {
    this.props.showErrorDialog(req);
  }

  getDate(date: any): any {
    if (date !== 0) {
      return toDate(date, "");
    }
  }
  renderStatusByType() {
    switch (this.props.formData.status) {
      case StatusEnums.Published:
        return (
          <div className="status">
            Durum: <strong>Yayımda</strong>
          </div>
        );
      case StatusEnums.Draft:
        return (
          <div className="status">
            Durum: <strong>Firma Değişti</strong>
          </div>
        );
      case StatusEnums.Deleted:
        return (
          <div className="status">
            Durum: <strong>Silindi</strong>
          </div>
        );
      case StatusEnums.DraftArchive:
        return (
          <div className="status">
            Durum: <strong>Uygun Görülmedi</strong>
          </div>
        );
      case StatusEnums.PendingApproval:
        return (
          <div className="status">
            Durum: <strong>Sertifika Süresi Bitenler</strong>
          </div>
        );
      case StatusEnums.ActivityStopped:
        return (
          <div className="status">
            Durum: <strong>Faaliyetini Durdurdu</strong>
          </div>
        );
      default:
        return null;
    }
  }
  onChangeStatus(status: StatusEnums) {
    const request: ThemedFacilityCertificationChangeStatusRequestModel = {
      id: this.props.ThemedFacilityCertificateId,
      status: status,
    };
    castThunkAction(this.props.changeStatus(request)).then((response: any) => {
      this.setState(
        {
          messageType: response ? MessageBarType.success : MessageBarType.error,
        },
        () => {
          if (response) this.props.setFormData("status", status);
        }
      );
    });
  }
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          titleRenderer={() => (
            <span className="edit-title">
              <strong>Sertifikayı Düzenle</strong>
              <strong>{this.props.formData?.title || ""}</strong>
            </span>
          )}
          showBack
          backUrl={"/ThemedFacility/edit/" +
            this.props.formData.themedFacilityId}
          history={this.props.history}
          subTitle="Değiştirmek istediğiniz alanları aşağıda bulabilirsiniz."
          createdInfo={{
            createdUser: this.props.formData.createdUserName,
            createdDate: this.props.formData.createDateString,
            updatedUser: this.props.formData.updatedUserName,
            updatedDate: this.props.formData.updateDateString,
          }}
        />
        {(usertype === "1" || usertype === "3") && <div className="changedFields">
          <label>Değişen Alanlar : </label>
          {this.showChangesField()}
        </div>}
        <div>Kayıt no : {this.props.formData.sequenceId}</div>
        Temalı Tesis ID : {this.props.formData.themedFacilityId}
        {this.renderForm()}
      </div>
    );
  }
  showChangesField(): any {
    let desc = [];
    const changesField2: any[] = this.props.formData.changesField
      ? JSON.parse(this.props.formData.changesField)
      : [];
    for (let index = 0; index < changesField2.length; index++) {
      if (changesField2[index].tr)
        desc.push(<span>{changesField2[index].tr} </span>);
    }
    if (desc.length === 0) {
      desc.push(<span>Değişiklik Yok</span>);
    }
    return desc;
  }
  SetUnread(): any {
    const request: NotificationUpdateRequestModel = {
      CategoryType: 1,
      RelatedId: this.props.formData.themedFacilityCertificateId,
      isRead: false,
    };
    castThunkAction(
      this.props.updateNotificationUnRead(request)
    ).then((response: any) => { });
  }
  RemovePublish(arg0: boolean): any {
    if (arg0) {
      castThunkAction(
        this.props.RemoveWebPublish({
          categoryType: 1,
          relatedId: this.props.formData
            .themedFacilityCertificateId,
        })
      ).then((response: any) => {
        window.location.reload();
      });
    } else {
      castThunkAction(
        this.props.RemoveQrPublish({
          categoryType: 1,
          relatedId: this.props.formData
            .themedFacilityCertificateId,
        })
      ).then((response: any) => {
        window.location.reload();
      });
    }
  }
  getSaveButton(): any {
    return (
      usertype !== "5" && (
        <PrimaryButton
          onClick={() => this.onSubmit()}
          disabled={this.state.isFileUploading ? true : false}
          styles={{
            rootDisabled: {
              background: "#ececec",
              borderColor: "#ccc !important",
              opacity: 0.5,
            },
          }}
          iconProps={{ iconName: "CheckMark" }}
          text={
            onSaveText(this.props.formData.status)
          }
        />
      )
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditThemedFacilityCertification);
