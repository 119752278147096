import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { History } from "history";
import BasicTable from "../BasicTable";
import {
  TextField,
  Dropdown,
  PrimaryButton,
  Label,
  DatePicker,
} from "office-ui-fabric-react";
import {
  getAll,
  getAllPage,
  getAllForPager,
  changeStatus,
  getAllForExcel,
} from "src/store/thunkAdditionalControl";
import { castThunkAction } from "src/helpers/casting";
import { toggleConfirmDialog, toggleConfirmFilterRemoveDialog } from "src/store/system/actions";
import {
  DayPickerStrings,
  firstDayOfWeek,
  listCount,
  onFormatDate,
} from "src/config/otherConfig";
import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import { getAllCR } from "src/store/thunkCertificateStatusType";
import { getCompanyUserList } from "src/store/thunksSystemUser";

import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";

import { getAllLSU } from "src/store/thunkLayoverSubCategory";
import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";
import cookie from "react-cookies";
// @ts-ignore
import ReactExport from "react-export-excel";
import { LayoverSubCategoryGetAllRequestModel } from "src/services/LayoverSubCategory/RequestModel";
import { updateNotification } from "src/store/thunkNotification";
import {
  add3Hour,
  formatDate,
  toDate,
  toTimeStamp,
  toTimeStampWithDate,
} from "src/helpers/converts";
import { CategoryTypeAdditional } from "src/helpers/enums";
import {
  AdditionalChangeStatusRequestModel,
  AdditionalModel,
} from "src/services/AdditionalControl/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";
import { filterModal } from "src/helpers/functions";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface OwnProps {
  history: History;
  filterId: string;
  typeid: string;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        getAll,
        toggleConfirmDialog,
        toggleConfirmFilterRemoveDialog,
        getAllM,
        getAllC,
        getAllLSU,
        getAllCR,
        getAllPage,
        getCompanyUserList,
        getAllForPager,
        updateNotification,
        changeStatus,
        getAllForExcel,
      },
      dispatch
    ),
  };
}

type AdditionalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  excel: boolean;
  pagerCurrent: number;
  pagerTotal: number;
  download: boolean;
}
let usertype: any = 0;


class AdditionalControl extends Component<AdditionalProps, OwnState> {
  state: any = {
    filterData: {
      additionalAuditId: 0,
      relatedId: this.props.filterId ? parseInt(this.props.filterId) : 0,
      facilityType: this.props.typeid ? parseInt(this.props.typeid) : 0,
      auditStartDate: 0,
      auditEndDate: 0,
      auditType: 0,
      auditResult: 0,
      auditNo: "",
      districtId: 0,
      cityId: 0,
      facilityName: "",
      status: 1,
      orderField: "UpdatedSystemUserDatetime",
      isDescending: true,
    },
    pagerCurrent: 0,
    pagerTotal: 0,
    excel: false,
    download: false,
  };

  componentDidMount() {

    filterModal(this, "additionalAuditModalState").finally(() =>
      this.state.selectedType !== 0 && this.getData(this.state.pagerCurrent)
    );
    usertype = cookie.load("systemUserInfo");
  }


  getData(pagerSelected: number) {
    const request: AdditionalModel = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...this.state.filterData,
    };

    castThunkAction(this.props.getAll(request)).then((response: any) => {
      const request2: CityGetAllRequestModel = {
        selectItemList: ["CityId", "CityName"],
        orderField: "CityName",
      };

      this.props.getAllM(request2);

      const request3: LayoverSubCategoryGetAllRequestModel = {
        criteriaItemList: [
          {
            propertyName: "Status",
            value: 1,
            isOr: false,
            conditionOperator: 0,
          },
        ],
        selectItemList: ["LayoverSubCategoryId", "LayoverSubCategoryName"],
      };
      this.props.getAllLSU(request3);

      const request4: any = {
        criteriaItemList: [
          {
            propertyName: "Status",
            value: 1,
            isOr: false,
            conditionOperator: 0,
          },
        ],
        selectItemList: ["CertificateStatusTypeId", "Title"],
      };
      this.props.getAllCR(request4);

      const request6: SystemUserGetAllModel = {
        systemUserType: 2,
      };
      this.props.getCompanyUserList(request6);

      this.state.filterData?.cityId !== 0 &&
        this.state.filterData?.cityId !== null &&
        this.CityChange(this.state.filterData.cityId);
    });
  }
  getDataPager(pagerSelected: number) {
    const request: any = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...this.state.filterData,
    };

    castThunkAction(this.props.getAllForPager(request)).then();
  }
  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Tümünü Seç" });
    }
    return newArray;
  }
  CityChange(item: any) {
    this.setState(
      {
        filterData: {
          ...this.state.filterData,
          cityId: item,
        },
      },
      () => {
        const request2: CountyGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "cityId",
              value: item,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
            {
              propertyName: "Status",
              value: 1,
              isOr: false,
              conditionOperator: 0,
            },
          ],
          selectItemList: ["DistrictId", "DistrictName"],
          orderField: "DistrictName",
        };
        this.props.getAllC(request2);
      }
    );
  }
  getDate(date: any): any {
    if (date !== 0) {
      return toDate(date, "");
    }
  }
  renderFilter() {
    return (
      <div className="table-filter row">
        <TextField
          className="custom-textfield col-md-2"
          label="Denetim No"
          value={this.state.filterData?.auditNo || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                auditNo: event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        <TextField
          className="custom-textfield col-md-1"
          label="Tesis Id"
          value={this.state.filterData?.relatedId || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                relatedId: event.target.value !== "" ? event.target.value : 0,
              },
            });
          }}
        />
        <TextField
          className="custom-textfield col-md-3"
          label="Tesis Adı"
          value={this.state.filterData?.facilityName || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                facilityName:
                  event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        <Dropdown
          defaultSelectedKey={this.state.filterData?.cityId || ""}
          onChanged={(event: any) => {
            this.CityChange(event.key)
            this.setState({
              filterData: {
                ...this.state.filterData,
                cityId: event.key,
              },
            })
          }}
          label="İl"
          options={(this.props.formData.GroupList?.dataList
            ? this.returnOptions(
              this.props.formData.GroupList?.dataList,
              "CityId",
              "CityName"
            )
            : ([] as any)
          ).map((item: any) => ({ key: item.CityId, text: item.CityName }))}
          className="custom-dropdown col-md-2"
        />
        <Dropdown
          defaultSelectedKey={this.state.filterData?.DistrictId || ""}
          onChanged={(event: any) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                DistrictId: event.key,
              },
            })
          }
          label="İlçe"
          options={(this.props.formData?.GroupListC?.dataList
            ? this.returnOptions(
              this.props.formData?.GroupListC?.dataList,
              "DistrictId",
              "DistrictName"
            )
            : ([] as any)
          ).map((item: any) => ({
            key: item.DistrictId,
            text: item.DistrictName,
          }))}
          className="custom-dropdown col-md-2"
        />
        <Dropdown
          options={[
            { key: 0, text: "Tümü" },
            { key: 1, text: "Uygunsuzluk tespit edilmedi." },
            {
              key: 2,
              text: "Uygunsuzluk tespit edildi. Kapama süresi verildi.",
            },
            { key: 3, text: "Uygunsuzluk tespit edildi. Belge iptal edildi." },
          ]}
          className="custom-dropdown col-md-2"
          label="Denetim Sonucu"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.auditResult || 0}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                auditResult: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <DatePicker
          strings={DayPickerStrings}
          firstDayOfWeek={firstDayOfWeek}
          onSelectDate={(date) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                auditStartDate: toTimeStampWithDate(
                  add3Hour(toDate(toTimeStamp(date?.toString())))
                ),
              },
            })
          }
          className="col-md-2"
          label="Denetim Tarihi Başlangıç"
          placeholder="gün.ay.yıl"
          formatDate={onFormatDate}
          value={this.getDate(this.state.filterData.auditStartDate)}
          maxDate={new Date()}
        />
        <DatePicker
          strings={DayPickerStrings}
          firstDayOfWeek={firstDayOfWeek}
          onSelectDate={(date) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                auditEndDate: toTimeStampWithDate(
                  add3Hour(toDate(toTimeStamp(date?.toString())))
                ),
              },
            })
          }
          className="col-md-2"
          label="Denetim Tarihi Bitiş"
          placeholder="gün.ay.yıl"
          formatDate={onFormatDate}
          value={this.getDate(this.state.filterData.auditEndDate)}
          maxDate={new Date()}
        />
        <Dropdown
          options={[
            { key: 0, text: "Tümü" },
            { key: 1, text: "Uygunsuzluk kapama süreci devam ediyor." },
            {
              key: 2,
              text: "Uygunsuzluk giderildi.",
            },
            { key: 3, text: "Uygunsuzluk giderilmedi ve belge iptal edildi." },
          ]}
          className="custom-dropdown col-md-2"
          label="Nihai Sonuç"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.finalResult || 0}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                finalResult: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: 0, text: "Tümü" },
            { key: 1, text: "Haberli Denetim" },
            { key: 2, text: "Habersiz Denetim" },
            { key: 3, text: "Gizli Müşteri Denetimi" },
            { key: 4, text: "Şikayet Denetimi" },
          ]}
          className="custom-dropdown col-md-2"
          label="Denetim Türü"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.auditType || 0}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                auditType: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />

        <Dropdown
          options={[{ key: 0, text: "Tümü" }, ...CategoryTypeAdditional].map(
            (x: any) => ({
              key: x.key,
              text: x.text,
            })
          )}
          className="custom-dropdown col-md-2"
          label="Tesis Türü"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.facilityType || 0}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                facilityType: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />

        <PrimaryButton
          text="Arama Yap"
          className="custom-button"
          onClick={() => {
            cookie.save(
              "additionalAuditModalState",
              JSON.stringify(this.state),
              {
                path: "/",
              }
            )
            this.getDataPager(0)
          }}
          style={{ marginLeft: "12px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />

        <PrimaryButton
          text="Tüm Filtreleri Temizle"
          className="custom-button"
          iconProps={{ iconName: "trash" }}
          onClick={() =>
            this.setState(
              {
                filterData: {
                  additionalAuditId: 0,
                  relatedId: 0,
                  facilityType: 0,
                  auditStartDate: 0,
                  auditEndDate: 0,
                  auditType: 0,
                  auditResult: 0,
                  auditNo: "",
                  districtId: 0,
                  cityId: 0,
                  facilityName: "",
                  status: 1,
                },
              },
              () => {
                cookie.save(
                  "additionalAuditModalState",
                  JSON.stringify(this.state),
                  {
                    path: "/",
                  }
                );
                this.getDataPager(0);
              }
            )
          }
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
              background: "#FF4343",
              borderColor: "#FF4350",
            },
          }}
        />

        {this.rederexcel()}
      </div>
    );
  }
  goEdit(additionalAuditId: number) {
    window.open("/AdditionalControl/Edit/" + additionalAuditId);
  }
  onDelete(additionalAuditId: number) {
    const request: AdditionalChangeStatusRequestModel = {
      id: additionalAuditId,
      status: StatusEnums.Deleted,
    };
    castThunkAction(this.props.changeStatus(request)).then((response: any) => {
      this.getData(this.state.pagerCurrent);
    });
  }
  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "auditNo",
            text: "Denetim No",
            col: "col-md-1",
          },
          {
            columnName: "relatedId",
            text: "Tesis Id",
            col: "col-md-1",
          },
          {
            columnName: "facilityName",
            text: "Tesis Adı",
            col: "col-md-2",
          },
          {
            columnName: "facilityType",
            text: "Tesis Türü ",
            col: "col-md-1",
            onRender: (item: { facilityType: any }) => (
              <React.Fragment>
                {
                  CategoryTypeAdditional.filter(
                    (x: { key: number; text: string }) =>
                      x.key === item.facilityType
                  )[0]?.text
                }
              </React.Fragment>
            ),
          },

          {
            columnName: "auditDateString",
            text: "Denetim Tarihi",
            col: "col-md-1",
          },
          {
            columnName: "auditTypeString",
            text: "Denetim Türü",
            col: "col-md-1",
          },
          {
            columnName: "auditResultString",
            text: "Denetim Sonucu",
            col: "col-md-2",
          },
          {
            columnName: "finalResultString",
            text: "Nihai Sonuç",
            col: "col-md-2",
          },
          {
            columnName: "additionalAuditId",
            text: "İşlem",
            col: "col-md-1",
            className: "operations",
            onRender: (item: {
              additionalAuditId: any;
              status: StatusEnums;
            }) => (
              <React.Fragment>
                {
                  <button onClick={(x) => this.goEdit(item.additionalAuditId)}>
                    <i className="icon-edit-outline"></i> Düzenle
                  </button>
                }

                {usertype !== "5" && item.status !== StatusEnums.Deleted && (
                  <button
                    onClick={(x) => this.onDelete(item.additionalAuditId)}
                  >
                    <i className="icon-trash-outline"></i> Sil
                  </button>
                )}
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.dataList || []}
      />
    );
  }
  downloadexcel() {
    this.setState({ download: true });
    const requestexcel: any = {};
    castThunkAction(this.props.getAllForExcel(requestexcel)).then(
      (response: any) => {
        this.setState({ excel: true, download: false });
      }
    );
  }
  rederexcel() {
    const excelName = "EkDenetimModulu_" + formatDate();
    return this.state.excel ? (
      <ExcelFile
        filename={excelName}
        element={
          <PrimaryButton
            text="Raporu İndir"
            className="custom-button"
            style={{ marginLeft: "15px" }}
            styles={{
              root: {
                fontSize: "11px",
                marginTop: "27px",
                height: "30px",
              },
            }}
          />
        }
      >
        <ExcelSheet
          data={this.props.formData?.AdditionalForExcel?.dataList}
          name="Ek Denetim Raporu"
        >
          <ExcelColumn label="Denetim Seri No" value="auditNo" />
          <ExcelColumn label="Tesis ID" value="relatedId" />
          <ExcelColumn label="Tesis Adı" value="facilityName" />
          <ExcelColumn label="Tesis Il" value="cityName" />
          <ExcelColumn label="Tesis Ilçe" value="districtName" />
          <ExcelColumn label="Tesis Oda Sayısı" value="roomCount" />
          <ExcelColumn
            label="Tesis Türü"
            value={(col: any) =>
              col.facilityType && col.facilityType !== ""
                ? CategoryTypeAdditional.filter(
                  (x) => x.key === col.facilityType
                )[0]?.text
                : ""
            }
          />
          <ExcelColumn
            label="Tesis Kategorisi"
            value="facilitySubCategoryName"
          />
          <ExcelColumn label="Tesis Sınıfı" value="facilityTypeName" />
          <ExcelColumn label="Belge Tipi" value="certificateTypeName" />
          <ExcelColumn label="Belge No" value="documentNo" />
          <ExcelColumn label="Denetim Türü" value="auditTypeString" />
          <ExcelColumn
            label="Denetim Bildirim Tarihi"
            value="auditInformationDateString"
          />
          <ExcelColumn label="Denetim Tarihi" value="auditDateString" />
          <ExcelColumn label="Denetleyici Firma" value="createdUserName" />
          <ExcelColumn label="Denetim Sonucu" value="auditResultString" />
          <ExcelColumn
            label="Denetim Sonucu Açıklama"
            value="auditResultDescription"
          />
          <ExcelColumn label="Nihai Sonucu" value="finalResultString" />
          <ExcelColumn
            label="Nihai Sonucu Açıklama"
            value="finalResultDescription"
          />
          <ExcelColumn
            label="Güncel Denetçi Firma"
            value="certificateCompanyUserName"
          />
          <ExcelColumn
            label="Güncel Sertifika Durumu"
            value="certificateStatus"
          />
          <ExcelColumn
            label="Kapama Tarihi"
            value="notSuitableCloseEndDateString"
          />
        </ExcelSheet>
      </ExcelFile>
    ) : (
      <>
        <PrimaryButton
          text="Rapor Oluştur"
          className="custom-button"
          onClick={() => this.downloadexcel()}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
        {this.state.download && (
          <Label style={{ marginLeft: "10px", marginTop: "27px" }}>
            Rapor Oluşturuluyor...
          </Label>
        )}
      </>
    );
  }
  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.props.formData.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }
  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getDataPager(selected);
  };
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Ek Denetim Yönetimi"
          subTitle="Yönetilebilecek tüm alanları aşağıdan görüntüleyebilirsiniz."
          backUrl="/"
          showBack
          history={this.props.history}
        />
        {usertype !== "5" && (
          <PrimaryButton
            onClick={() =>
              this.props.history.push("/AdditionalControl/CreateType")
            }
            iconProps={{ iconName: "Add" }}
            className="absolute-create"
            text="Ek Denetim Ekle"
            styles={{
              root: {
                background: "#62a98e",
                borderColor: "#5b9e85",
              },
              rootHovered: {
                background: "#5b9e85",
                borderColor: "#5b9e85",
              },
              rootDisabled: {
                background: "#ececec",
              },
            }}
          />
        )}

        <Label className="totalRecord">
          Denetim Sayısı: {this.props.formData.dataCount}
        </Label>

        {this.renderFilter()}

        {this.renderList()}
        {this.renderPager()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalControl);
