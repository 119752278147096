import {
  ModalState,
  ModalActionTypes,
  TOGGLE_MODAL,
  SET_MODAL_DATA,
} from "./types";

const initialState: ModalState = {};

export function modalReducer(
  state = initialState,
  action: ModalActionTypes
): ModalState {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        [action.id]: {
          show: !state[action.id].show,
          showAt: !state[action.id].showAt,
          data: action.data,
        },
      };
    case SET_MODAL_DATA:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          data: action.data,
        },
      };
    default:
      return state;
  }
}
