import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  TextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Dropdown,
  DatePicker,
  Label,
  ComboBox,
} from "office-ui-fabric-react";
import {
  setFormData,
  setFormErrors,
  initialFormData,
} from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";
import { save } from "src/store/thunkCertificateAppeal";
import { castThunkAction } from "src/helpers/casting";
import { CertificateAppealSaveRequestModel } from "src/services/CertificateAppeal/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";

import { getAllM } from "src/store/thunksCity";
import { getAllFA } from "src/store/thunkFacilityType";
import { getAllC } from "src/store/thunkCounty";
import { getAllMA } from "src/store/thunkMainCategory";
import { getAllSU } from "src/store/thunkSubCategory";
import { getAllTy, showErrorDialog } from "src/store/thunkCertificateType";
import { getAllCR } from "src/store/thunkCertificateStatusType";
import { getAllCompany } from "src/store/thunksCompany";
import { getCompanyUserList } from "src/store/thunksSystemUser";

import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";
import { MainCategoryGetAllRequestModel } from "src/services/MainCategory/RequestModel";
import { SubCategoryGetAllRequestModel } from "src/services/SubCategory/RequestModel";
import { CertificateTypeGetAllRequestModel } from "src/services/CertificateType/RequestModel";
import { CertificateStatusTypeGetAllRequestModel } from "src/services/CertificateStatusType/RequestModel";
import cookie from "react-cookies";
import {
  DayPickerStrings,
  firstDayOfWeek,
  onFormatDate,
} from "src/config/otherConfig";
import { toTimeStamp, toDate } from "src/helpers/converts";
import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";
import { GetCompanyByDocumentNoRequest } from "src/services/Company/RequestModel";
import { FacilityTypeGetAllRequestModel } from "src/services/FacilityType/RequestModel";
// import { FileUpload } from "../FileUpload";
// import { apiConfig, qrUrl } from 'src/config/apiConfig';
import _ from "lodash";
import { certificateAppealStatusDate, requestMapStatusData } from "src/helpers/functions";

// const token = cookie.load('userId');

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        initialFormData,
        save,
        getAllM,
        getAllC,
        getAllMA,
        getAllSU,
        getAllFA,
        getAllTy,
        getAllCR,
        getCompanyUserList,
        getAllCompany,
        showErrorDialog,
      },
      dispatch
    ),
  };
}

type CreateCertificateAppealProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  showFields: boolean;
  messageType: MessageBarType;
  isFileUploading: boolean;
  infoMessageGetDocumentNo: string;
}
let usertype: any = 0;
class CreateCertificateAppeal extends Component<
  CreateCertificateAppealProps,
  OwnState
> {
  state = {
    messageType: MessageBarType.warning,
    showFields: true,
    isFileUploading: false,
    infoMessageGetDocumentNo: "",
  };

  UNSAFE_componentWillMount() {
    this.props.initialFormData({
      status: StatusEnums.Published,
    });

    usertype = cookie.load("systemUserInfo");

    const request6: SystemUserGetAllModel = {
      systemUserType: 2,
    };
    castThunkAction(this.props.getCompanyUserList(request6)).then(
      (response: any) => {
        this.props.setFormData("certificateTypeId", 0);
        if (usertype && usertype === "2") {
          this.props.setFormData("companyUserId", 0);
        }

        const request2: CityGetAllRequestModel = {
          selectItemList: ["CityId", "CityName"],
          isDescending: false,
          orderField: "CityName",
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllM(request2);

        const request4: CertificateTypeGetAllRequestModel = {
          selectItemList: ["CertificateTypeId", "Title"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllTy(request4);

        const request5: CertificateStatusTypeGetAllRequestModel = {
          selectItemList: ["CertificateStatusTypeId", "Title"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        castThunkAction(this.props.getAllCR(request5)).then((e: any) => {
          this.props.setFormData("certificateStatusTypeId", 1);
        });
      }
    );
  }

  componentDidUpdate(nextprops: any) {
    if (this.props.formData.certificateTypeId !== 0) {
      this.setLabelDescription(
        "companyName",
        "<br /> <span  id='companyName-span'  class='descSpan'>Tesis sahibi olan firmanın ticari ünvanı</span>"
      );
      this.setDdlDescription(
        "certificateStatusTypeId-label",
        "<br /> <span  id='certificateStatusTypeId-label-span'  class='descSpan'>Denetim için sözleşme yapıldığında <b>Belgelendirme Sürecinde</b>, belge verildikten sonra ise <b>Belge Verildi</b> seçeneğinin işaretlenmesi gerekmektedir.</span>"
      );
      if (this.props.formData.certificateTypeId === 4) {
        this.setLabelDescription(
          "documentNoDesc",
          "<br /> <span  id='documentNoDesc-span'  class='descSpan'>TC Kültür ve Turizm Bakanlığı tarafından tesise verilen çalışma belge numarası</span>"
        );
      }
    }
  }

  insertAfter(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  componentDidMount() {
    this.setDdlDescription(
      "certificateTypeIdDesc-label",
      "<br/> <span id='certificateTypeIdDesc-label-span' class='descSpan'>Tesisin çalışma belgesi aldığı kurumu seçiniz <b>TC Kültür ve Turizm Bakanlığı </b> harici tüm kurumlar için <b>Diğer</b> seçeneğini işaretleyiniz.</b></span>"
    );
  }

  setDdlDescription(id: string, message: string) {
    const el = document.createElement("span");
    el.innerHTML = message;
    const div = document.getElementById(id);
    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div, el);
    }
  }

  setLabelDescription(id: string, message: string) {
    const el2 = document.createElement("span");
    el2.innerHTML = message;
    const div2 = document.querySelectorAll('[for="' + id + '"]');

    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div2[0], el2);
    }
  }

  getCategories(key: any) {
    const request3: MainCategoryGetAllRequestModel = {
      selectItemList: ["MainCategoryId", "Title"],
      criteriaItemList: [
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        key === 5
          ? {
              propertyName: "isMinistry",
              value: key === 5 ? true : false,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            }
          : {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
      ],
    };
    this.props.getAllMA(request3);
  }
  renderTextByMessageType() {
    switch (this.state.messageType) {
      case MessageBarType.success:
        return "Kaydetme işlemi başarıyla gerçekleşmiştir.";
      case MessageBarType.error:
        return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
      default:
        return "Lütfen aşağıdaki bilgileri doldurun ve formu oluşturun";
    }
  }

  renderMessageBar() {
    return (
      <MessageBar messageBarType={this.state.messageType} isMultiline={false}>
        {this.renderTextByMessageType()}
      </MessageBar>
    );
  }

  onCreate(isDraft?: boolean) {
    let request: CertificateAppealSaveRequestModel = requestMapStatusData(this.props.formData,isDraft);
    
    if (
      this.props.formData.certificateFiles &&
      this.props.formData.certificateFiles.length > 0
    ) {
      request.data["attachmentIdList"] = (
        this.props.formData.certificateFiles || []
      ).map((item: any) => item.attachmentId);
    }
    if (!request.data.documentNo) {
      request.data.documentNo = 0;
    }
    castThunkAction(this.props.save(request)).then((response: any) => {
      this.setState(
        {
          messageType: response ? MessageBarType.success : MessageBarType.error,
        },
        () => {
          if (response) {
            this.props.history.push("/CertificateAppeal/edit/" + response.id);
          } else {
            setTimeout(() => {
              //window.location.reload(false)
            }, 1000);
          }
        }
      );
    });
  }

  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  removeElement(id: any) {
    var elem = document.getElementById(id);
    return elem?.parentNode?.removeChild(elem);
  }
  filterFileds(val: any) {
    if (
      val !== "" &&
      val !== 0 &&
      val !== "0" &&
      val !== null &&
      val !== "null" &&
      val !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  }
  onSubmit(isDraft?: boolean) {
    let desc = "";
    if (!this.filterFileds(this.props.formData.certificateTypeId)) {
      desc += "Tesis Belge Tipi, <br/>";
    }
    if (!this.filterFileds(this.props.formData.companyName)) {
      desc += "Belge Sahibi / Ticari Ünvan,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.brandName)) {
      desc += "Tesis Adı,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.mainCategoryId)) {
      desc += "Tesis Türü,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.cityId)) {
      desc += "İl,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.districtId)) {
      desc += "İlçe,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.appealDate)) {
      desc += "Sertifika Başvuru Tarihi,  <br/>";
    }
    if (usertype && usertype !== "2") {
      if (!this.filterFileds(this.props.formData.companyUserId)) {
        desc += "Denetleyici Firma <br/> ";
      }
    }
    if (this.props.formData.certificateStatusTypeId === 2) {
      if (!this.filterFileds(this.props.formData.confirmationDate)) {
        desc += "Sertifika Düzenleme Tarihi,  <br/>";
      }
      if (!this.filterFileds(this.props.formData.CertificateNo)) {
        desc += "Sertifika No,  <br/>";
      }
    }

    const request = {
      title: "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
      desc: desc,
    };
    if (desc !== "") {
      castThunkAction(this.props.showErrorDialog(request)).then(
        (response: any) => {}
      );
    }

    if (
      this.props.formData.appealDate &&
      this.props.formData.appealDate !== "" &&
      this.props.formData.appealDate !== null &&
      this.props.formData.appealDate !== 0 &&
      this.props.formData.appealDate !== "null" &&
      this.props.formData.appealDate !== "0"
    ) {
      this.removeElement("appealDate-span");
    } else {
      const el = document.createElement("span");
      el.className = "errorspan";
      el.innerHTML =
        "<br/> <span id='appealDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
      const div = document.getElementById("appealDate");
      if (!document.getElementById("appealDate-span")) {
        this.insertAfter(div, el);
      }
    }

    if (this.props.formData.certificateStatusTypeId === 2) {
      if (
        this.props.formData.confirmationDate &&
        this.props.formData.confirmationDate !== "" &&
        this.props.formData.confirmationDate !== null &&
        this.props.formData.confirmationDate !== 0 &&
        this.props.formData.confirmationDate !== "null" &&
        this.props.formData.confirmationDate !== "0"
      ) {
        this.removeElement("confirmationDate-span");
      } else {
        const el = document.createElement("span");
        el.className = "errorspan";
        el.innerHTML =
          "<br/> <span id='confirmationDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
        const div = document.getElementById("confirmationDate");
        if (!document.getElementById("confirmationDate-span")) {
          this.insertAfter(div, el);
        }
      }
    } else {
      this.removeElement("confirmationDate-span");
    }

    const { formData, setFormErrors } = this.props;
    const validationSchema = _yup.object({
      webSite: _yup
        .string()
        .matches(
          // eslint-disable-next-line
          /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "Geçersiz Web Sitesi"
        )
        .notRequired()
        .nullable(),
      companyName: _yup.string().required("Bu alan zorunludur!"),
      brandName: _yup.string().required("Bu alan zorunludur!"),
      mainCategoryId: _yup.string().required("Bu alan zorunludur!"),
      certificateStatusTypeId: _yup.string().required("Bu alan zorunludur!"),
      cityId: _yup.string().required("Bu alan zorunludur!"),
      districtId: _yup.string().required("Bu alan zorunludur!"),
      companyUserId: _yup.string().required("Bu alan zorunludur!"),
      CertificateNo:
        this.props.formData.certificateStatusTypeId === 2
          ? _yup.string().required("Bu alan zorunludur!")
          : _yup.string().nullable(),
    });

    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});
        if (
          this.props.formData.appealDate &&
          this.props.formData.appealDate !== "" &&
          this.props.formData.appealDate !== null &&
          this.props.formData.appealDate !== 0 &&
          this.props.formData.appealDate !== "null" &&
          this.props.formData.appealDate !== "0"
        ) {
          this.removeElement("appealDate-span");

          if (this.props.formData.certificateStatusTypeId === 2) {
            if (
              this.props.formData.confirmationDate &&
              this.props.formData.confirmationDate !== "" &&
              this.props.formData.confirmationDate !== null &&
              this.props.formData.confirmationDate !== 0 &&
              this.props.formData.confirmationDate !== "null" &&
              this.props.formData.confirmationDate !== "0"
            ) {
              this.removeElement("appealDate-span");
              this.removeElement("confirmationDate-span");
              this.onCreate(isDraft);
            } else {
              const el = document.createElement("span");
              el.className = "errorspan";
              el.innerHTML =
                "<br/> <span id='confirmationDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
              const div = document.getElementById("confirmationDate");
              if (!document.getElementById("confirmationDate-span")) {
                this.insertAfter(div, el);
              }
            }
          } else {
            this.removeElement("appealDate-span");
            this.removeElement("confirmationDate-span");
            this.onCreate(isDraft);
          }
        } else {
          const el = document.createElement("span");
          el.className = "errorspan";
          el.innerHTML =
            "<br/> <span id='appealDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
          const div = document.getElementById("appealDate");
          if (!document.getElementById("appealDate-span")) {
            this.insertAfter(div, el);
          }
        }
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  CityChange(item: any, districtId?: any) {
    const request2: CountyGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "cityId",
          value: item,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
      selectItemList: ["DistrictId", "DistrictName"],
      orderField: "DistrictName",
    };
    castThunkAction(this.props.getAllC(request2)).then((response: any) => {
      this.props.setFormData("districtId", districtId);
    });
  }
  CategoryChange(item: any, subCategoryId?: any) {
    const request2: SubCategoryGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "MainCategoryId",
          value: item,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        this.props.formData.certificateTypeId === 5
          ? {
              propertyName: "isMinistry",
              value: this.props.formData.certificateTypeId === 5 ? true : false,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            }
          : {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
      ],
      selectItemList: ["SubCategoryId", "SubCategoryName"],
    };

    castThunkAction(this.props.getAllSU(request2)).then((response: any) => {
      if (item === 1) {
        this.props.setFormData("subCategoryId", 3);
        this.SubCategoryChange(3, 1);
      } else {
        this.props.setFormData("subCategoryId", 0);
      }
    });
  }
  SubCategoryChange(item: any, facilityTypeId?: any) {
    const request2: FacilityTypeGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "SubCategoryId",
          value: item,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        this.props.formData.certificateTypeId === 5
          ? {
              propertyName: "isMinistry",
              value: this.props.formData.certificateTypeId === 5 ? true : false,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            }
          : {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
      ],
      selectItemList: ["FacilityTypeId", "FacilityTypeName"],
      orderField: "FacilityTypeName",
    };

    castThunkAction(this.props.getAllFA(request2)).then((response: any) => {
      this.props.setFormData("facilityTypeId", item === 3 ? 1 : 0);
    });
  }
  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Seçiniz" });
    }

    return newArray;
  }
  getDate(date: any): any {
    if (date !== 0 && date !== undefined) {
      return toDate(date, "");
    }
  }

  renderForm() {
    const { formData, formErrors, setFormData } = this.props;
    return (
      <div className="form-groups row">
        <div className="form-group col-md-4" style={{ paddingBottom: 0 }}>
          <div className="form-wrapper">
            <div className="item row">
              <Dropdown
                id="certificateTypeIdDesc"
                onChanged={(event: any) => (
                  setFormData("certificateTypeId", event.key),
                  event.key === 5
                    ? (setFormData("companyName", ""),
                      setFormData("brandName", ""),
                      setFormData("cityId", ""),
                      setFormData("mainCategoryId", ""),
                      setFormData("subCategoryId", 0),
                      setFormData("facilityTypeId", 0),
                      setFormData("districtId", ""),
                      setFormData("GroupListSU", {}),
                      setFormData("GroupListFA", {}))
                    : "",
                  this.getCategories(event.key)
                )}
                errorMessage={formErrors.certificateTypeId}
                label="Tesis Belge Tipi"
                options={(this.props.formData.GroupListTY?.dataList
                  ? this.returnOptions(
                      this.props.formData.GroupListTY?.dataList,
                      "CertificateTypeId",
                      "Title"
                    )
                  : ([] as any)
                ).map((item: any, key: any) => ({
                  key: item.CertificateTypeId,
                  text: item.Title,
                  keys: key,
                }))}
                className="custom-dropdown"
              />
            </div>
            {this.props.formData.certificateTypeId === 4 ? (
              <div className="item row">
                <TextField
                  id="documentNoDesc"
                  placeholder="Belge No"
                  value={formData.documentNo || ""}
                  label="Belge No "
                  className="custom-textfield border"
                  errorMessage={formErrors.documentNo}
                  onChange={(event: any) =>
                    setFormData("documentNo", event.target.value)
                  }
                />
                <PrimaryButton
                  onClick={(x) => this.getCompanyInfo()}
                  text="Bilgileri Getir"
                  className="deleteDateBtn"
                  styles={{
                    root: {
                      marginTop: 5,
                      background: "#62a98e",
                      borderColor: "#5b9e85",
                    },
                    rootHovered: {
                      background: "#5b9e85",
                      borderColor: "#5b9e85",
                    },
                    rootPressed: {
                      background: "#5b9e85",
                      borderColor: "#5b9e85",
                    },
                    rootDisabled: {
                      background: "#ececec",
                    },
                  }}
                />
                <Label style={{ marginLeft: "10px" }}>
                  {this.state.infoMessageGetDocumentNo}
                </Label>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        {/* <div className="form-group col-md-4">
					<div className="form-wrapper">
						<div className="title">Sertifika Dosyası Yükle</div>
						<FileUpload
							onProccessStartFile={() => {
								console.log("PROCESS START RETURNED");
								this.setState({
									isFileUploading: true
								})
							}}
							onDataFile={() => {
								console.log("ONDATA RETURNED");
								this.setState({
									isFileUploading: false
								})
							}}
							onTransferedFile={(file: any) => {
								this.props.setFormData("certificateFiles", [...(this.props.formData?.certificateFiles || []), file.attachmentIdList[0]])
							}}
							requestKey="files"
							token={token}
							endpointUrl={apiConfig.prefixes.attachment + "UploadFile"}
						/>
						{this.props.formData.certificateFiles && this.props.formData.certificateFiles.length > 0 && (
							<React.Fragment>
								<div className="title">Yüklenen Dosyalar</div>
								<div className="uploaded-files">
									{(this.props.formData?.certificateFiles || []).map((item: { attachmentId: number, attachmentPath: string, fileName: string, fileSize: number }, index: number) => (
										<div key={index} className="uploaded-file">
											<div className="name row">
												<span className="text col no-padding">
													{item.fileName}
													<strong className="size m-left-auto">{this.formatSize(item.fileSize)}</strong>
												</span>
												<div className="buttons m-left-auto row">
													<button onClick={() => window.open(qrUrl + item.attachmentPath, "_blank")} className="view"><i className="icon-explore"></i> Görüntüle</button>
													<button onClick={() => {
														let files = _.cloneDeep(this.props.formData?.certificateFiles);
														let findIndex = (files || []).findIndex((x: any) => x.attachmentId === item.attachmentId);
														if (findIndex !== -1) {
															files.splice(findIndex, 1);
														}
														this.props.setFormData("certificateFiles", files);
													}} className="remove"><i className="icon-close"></i></button>
												</div>
											</div>

										</div>
									))}
								</div>
							</React.Fragment>
						)}
					</div>
				</div> */}

        <aside
          className="col-md-12 row"
          style={{
            padding: 0,
            display:
              this.props.formData.certificateTypeId !== 0 ? "flex" : "none",
          }}
        >
          <div className="form-group col-md-4">
            <div className="form-wrapper">
              <div className="item row">
                <TextField
                  id="companyName"
                  disabled={
                    this.props.formData.certificateTypeId === 4 ? true : false
                  }
                  placeholder="Belge Sahibi / Ticari Ünvan"
                  value={formData.companyName || ""}
                  label="Belge Sahibi / Ticari Ünvan"
                  className="custom-textfield border"
                  errorMessage={formErrors.companyName}
                  onChange={(event: any) =>
                    setFormData("companyName", event.target.value)
                  }
                />
              </div>

              <div className="item row">
                <TextField
                  disabled={
                    this.props.formData.certificateTypeId === 4 ? true : false
                  }
                  placeholder="Tesis Adı"
                  value={formData.brandName || ""}
                  label="Tesis Adı"
                  className="custom-textfield border"
                  errorMessage={formErrors.brandName}
                  onChange={(event: any) =>
                    setFormData("brandName", event.target.value)
                  }
                />
              </div>

              <div className="item row">
                <Dropdown
                  disabled={
                    this.props.formData.certificateTypeId === 4 ? true : false
                  }
                  onChanged={(event: any) => (
                    this.CityChange(event.key), setFormData("cityId", event.key)
                  )}
                  label="İl"
                  options={(
                    this.props.formData.GroupList?.dataList || ([] as any)
                  ).map((item: any) => ({
                    key: item.CityId,
                    text: item.CityName,
                  }))}
                  className="custom-dropdown"
                  selectedKey={this.props.formData.cityId}
                  errorMessage={formErrors.cityId}
                />
              </div>

              <div className="item row">
                <Dropdown
                  disabled={
                    this.props.formData.certificateTypeId === 4 ? true : false
                  }
                  onChanged={(event: any) =>
                    setFormData("districtId", event.key)
                  }
                  label="İlçe"
                  options={(formData.GroupListC?.dataList || ([] as any)).map(
                    (item: any) => ({
                      key: item.DistrictId,
                      text: item.DistrictName,
                    })
                  )}
                  className="custom-dropdown"
                  selectedKey={this.props.formData.districtId}
                  errorMessage={formErrors.districtId}
                />
              </div>

              {usertype && usertype !== "2" ? (
                <>
                  <div className="item row">
                    <Dropdown
                      onChanged={(event: any) =>
                        setFormData("isPublishWeb", event.key)
                      }
                      label="Web Sitesinde Yayımlansın mı?"
                      options={[
                        { key: false, text: "Hayır" },
                        { key: true, text: "Evet" },
                      ].map((item: any) => ({
                        key: item.key,
                        text: item.text,
                      }))}
                      className="custom-dropdown"
                    />
                  </div>
                  <div className="item row">
                    <ComboBox
                      onChange={(event: any, option: any) =>
                        setFormData("companyUserId", option.key)
                      }
                      label="Denetleyici Firma"
                      allowFreeform={true}
                      autoComplete="on"
                      selectedKey={this.props.formData.companyUserId || 0}
                      options={(
                        this.props.formData.CompanyUserList?.dataList ||
                        ([] as any)
                      ).map((item: any) => ({
                        key: item.systemUserId,
                        text: item.title,
                      }))}
                      className="custom-dropdown"
                      errorMessage={formErrors.companyUserId}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="form-group col-md-4">
            <div className="form-wrapper">
              <div className="item row">
                <TextField
                  placeholder="Web Sitesi"
                  value={formData.webSite || ""}
                  label="Web Sitesi"
                  className="custom-textfield border"
                  errorMessage={formErrors.webSite}
                  onChange={(event: any) =>
                    setFormData(
                      "webSite",
                      event.target.value === "" ? null : event.target.value
                    )
                  }
                />
              </div>

              <div className="item row">
                <DatePicker
                  strings={DayPickerStrings}
                  firstDayOfWeek={firstDayOfWeek}
                  onSelectDate={(date) =>
                    setFormData("appealDate", toTimeStamp(date?.toString()))
                  }
                  label="Sertifika Başvuru Tarihi"
                  placeholder="gün.ay.yıl"
                  formatDate={onFormatDate}
                  value={this.getDate(this.props.formData.appealDate)}
                  maxDate={new Date()}
                  minDate={certificateAppealStatusDate(usertype, false)}
                />

                <PrimaryButton
                  id="appealDate"
                  onClick={(x) => this.props.setFormData("appealDate", 0)}
                  iconProps={{ iconName: "Trash" }}
                  text="Tarihi Sil"
                  className="deleteDateBtn"
                  styles={{
                    root: {
                      marginTop: 5,
                      background: "#ac6262",
                      borderColor: "#ac6262",
                    },
                    rootHovered: {
                      background: "#a34c4c",
                      borderColor: "#a34c4c",
                    },
                    rootPressed: {
                      background: "#a34c4c",
                      borderColor: "#a34c4c",
                    },
                    rootDisabled: {
                      background: "#ececec",
                    },
                  }}
                />
              </div>

              <div className="item row">
                <Dropdown
                  disabled={
                    this.props.formData.certificateTypeId === 4 ? true : false
                  }
                  onChanged={(event: any) => (
                    this.CategoryChange(event.key),
                    setFormData("mainCategoryId", event.key),
                    setFormData("subCategoryId", 0),
                    setFormData("facilityTypeId", 0),
                    setFormData("GroupListFA", {})
                  )}
                  label="Tesis Türü"
                  options={(
                    this.props.formData.GroupListMA?.dataList || ([] as any)
                  ).map((item: any) => ({
                    key: item.MainCategoryId,
                    text: item.Title,
                  }))}
                  errorMessage={formErrors.mainCategoryId}
                  selectedKey={this.props.formData.mainCategoryId}
                  className="custom-dropdown"
                />
              </div>

              <div className="item row">
                <Dropdown
                  disabled={
                    this.props.formData.certificateTypeId === 4 ? true : false
                  }
                  onChanged={(event: any) => (
                    this.SubCategoryChange(event.key),
                    setFormData("subCategoryId", event.key),
                    setFormData("facilityTypeId", 0)
                  )}
                  label="Tesis türü"
                  options={(
                    this.props.formData.GroupListSU?.dataList || ([] as any)
                  ).map((item: any) => ({
                    key: item.SubCategoryId,
                    text: item.SubCategoryName,
                  }))}
                  errorMessage={formErrors.subCategoryId}
                  className="custom-dropdown"
                  selectedKey={this.props.formData.subCategoryId}
                />
              </div>
              <div className="item row">
                <Dropdown
                  disabled={
                    this.props.formData.certificateTypeId === 4 ? true : false
                  }
                  onChanged={(event: any) =>
                    setFormData("facilityTypeId", event.key)
                  }
                  label="Tesis Sınıfı"
                  options={(
                    this.props.formData.GroupListFA?.dataList || ([] as any)
                  ).map((item: any) => ({
                    key: item.FacilityTypeId,
                    text: item.FacilityTypeName,
                  }))}
                  errorMessage={formErrors.facilityTypeId}
                  className="custom-dropdown"
                  selectedKey={this.props.formData.facilityTypeId}
                />
              </div>
            </div>
          </div>
          <div className="form-group col-md-4">
            <div className="form-wrapper">
              <div className="item row">
                <Dropdown
                  id="certificateStatusTypeId"
                  onChanged={(event: any) =>
                    setFormData("certificateStatusTypeId", event.key)
                  }
                  errorMessage={formErrors.certificateStatusTypeId}
                  label="Sertifika Durumu"
                  options={(
                    this.props.formData.GroupListCR?.dataList || ([] as any)
                  ).map((item: any) => ({
                    key: item.CertificateStatusTypeId,
                    text: item.Title,
                  }))}
                  className="custom-dropdown"
                  selectedKey={this.props.formData.certificateStatusTypeId}
                />
              </div>

              {this.props.formData.certificateStatusTypeId === 2 ? (
                <>
                  <div className="item row">
                    <DatePicker
                      strings={DayPickerStrings}
                      firstDayOfWeek={firstDayOfWeek}
                      onSelectDate={(date) =>
                        setFormData(
                          "confirmationDate",
                          toTimeStamp(date?.toString())
                        )
                      }
                      label="Sertifika Düzenleme Tarihi"
                      placeholder="gün.ay.yıl"
                      formatDate={onFormatDate}
                      value={this.getDate(formData.confirmationDate)}
                      maxDate={new Date()}
                    />
                    <PrimaryButton
                      id="confirmationDate"
                      onClick={(x) =>
                        this.props.setFormData("confirmationDate", 0)
                      }
                      iconProps={{ iconName: "Trash" }}
                      text="Tarihi Sil"
                      className="deleteDateBtn"
                      styles={{
                        root: {
                          marginTop: 5,
                          background: "#ac6262",
                          borderColor: "#ac6262",
                        },
                        rootHovered: {
                          background: "#a34c4c",
                          borderColor: "#a34c4c",
                        },
                        rootPressed: {
                          background: "#a34c4c",
                          borderColor: "#a34c4c",
                        },
                        rootDisabled: {
                          background: "#ececec",
                        },
                      }}
                    />
                  </div>
                  <div className="item row">
                    <TextField
                      placeholder="Sertifika No"
                      value={formData.CertificateNo || ""}
                      label="Sertifika No"
                      className="custom-textfield border"
                      onChange={(event: any) =>
                        setFormData("CertificateNo", event.target.value)
                      }
                      errorMessage={formErrors.CertificateNo}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </aside>
      </div>
    );
  }
  getCompanyInfo(): any {
    const request7: GetCompanyByDocumentNoRequest = {
      documentNo: this.props.formData.documentNo,
    };
    this.setState({
      ...this.state,
      infoMessageGetDocumentNo: "Bilgiler Geliyor",
    });
    castThunkAction(this.props.getAllCompany(request7))
      .then((response: any) => {
        if (this.props.formData.CompanyInfo.success) {
          const data = this.props.formData.CompanyInfo.data;
          this.props.setFormData("companyName", data.companyName);
          this.props.setFormData("brandName", data.brandName);

          this.props.setFormData("cityId", data.cityId);
          this.props.setFormData("mainCategoryId", data.mainCategoryId);
          this.props.setFormData("facilityTypeId", data.facilityTypeId);

          this.CityChange(data.cityId, data.districtId);
          this.CategoryChange(data.mainCategoryId, data.subCategoryId);
          this.SubCategoryChange(data.subCategoryId, data.facilityTypeId);
          this.setState({ ...this.state, infoMessageGetDocumentNo: "" });
        } else {
          this.setState({
            ...this.state,
            infoMessageGetDocumentNo:
              this.props.formData.CompanyInfo.errorMessage,
          });
          this.props.setFormData("companyName", "");
          this.props.setFormData("brandName", "");

          this.props.setFormData("cityId", 0);
          this.props.setFormData("mainCategoryId", 0);
          this.props.setFormData("facilityTypeId", 0);

          this.CityChange(0, 0);
          this.CategoryChange(0, 0);
          this.SubCategoryChange(0, 0);
        }
      })
      .catch((response: any) => {
        this.setState({
          ...this.state,
          infoMessageGetDocumentNo: "Hata oldu !",
        });
      });
  }

  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Yeni Sertifika Ekle"
          titleRenderer={() => (
            <span className="edit-title">
              Yönet:
              <strong>Yeni Sertifika Ekle</strong>
            </span>
          )}
          showBack
          backUrl="/CertificateAppeal"
          history={this.props.history}
          subTitle="Yeni bir Sertifika eklemek için aşağıdaki bilgileri doldurunuz."
          right={
            <FormButtons
              isAbsolute
              onSubmit={() => this.onSubmit()}
              customRenderButtons={() => (
                <React.Fragment>
                  <PrimaryButton
                    onClick={() => this.onSubmit()}
                    disabled={
                      this.state.messageType === MessageBarType.success ||
                      this.state.isFileUploading
                        ? true
                        : false
                    }
                    styles={{
                      rootDisabled: {
                        background: "#626262",
                        borderColor: "#777373 !important",
                      },
                    }}
                    iconProps={{ iconName: "CheckMark" }}
                    text="Yayımla"
                  />
                </React.Fragment>
              )}
            />
          }
          rightType="create"
        />
        {this.renderMessageBar()}

        {this.renderForm()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCertificateAppeal);
