import { HttpClient } from "src/library/HttpClient";
import {
  GetDenunciationListGroupingRequestModel,
  UndoDenunciationStatusRequestModel,
  UpdateRequest,
  SendToIsdemRequest,
  SendDenunciationToMinistry,
  GetDenunciationListByFacilityRequestModel,
  SendDenunciationRequestModel,
  ChangeDenunciationStatus,
  SaveOrUpdateForTGARequest,
  RestoreToUnsentRequestModel
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class Denunciation {
  async UndoDenunciationStatus(
    request: UndoDenunciationStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.denunciation + "UndoDenunciationStatus",
      { ...request, token }
    );
  }
  async RestoreToUnsent(
    request: RestoreToUnsentRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.denunciation + "RestoreToUnsent",
      { ...request, token }
    );
  }

  async getDenunciationList(
    request: GetDenunciationListGroupingRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.denunciation + "GetDenunciationListGrouping",
      { ...request, token }
    );
  }
  async SendDenunciationToMinistry(
    request: SendDenunciationToMinistry
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.denunciation + "SendDenunciationToMinistry",
      { ...request, token }
    );
  }

  async SendToIsdem(request: SendToIsdemRequest): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.denunciation + "SendToIsdem",
      { ...request, token }
    );
  }

  async sendDenunciation(request: SendDenunciationRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.denunciation + "SendDenunciation",
      { ...request, token }
    );
  }

  async changeDenunciationStatus(
    request: ChangeDenunciationStatus
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.denunciation + "ChangeDenunciationStatus",
      { ...request, token }
    );
  }
  async updateStatus(request: UpdateRequest): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.denunciation + "Update",
      { ...request, token }
    );
  }

  async updateDenunciation(request: SaveOrUpdateForTGARequest): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.denunciation + "SaveOrUpdateForTGA",
      { data: { ...request }, token }
    );
  }

  async getDenunciationListByFacility(
    request: GetDenunciationListByFacilityRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.denunciation + "GetDenunciationListByFacility",
      { ...request, token }
    );
  }

  async excelDownload(request: any, name: string) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.denunciation + "GetDenunciationListGroupingExcel",
      name,
      { ...request, token }
    );
  }
  async setActionStatus(
    request: any
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.denunciation + "SetActionStatus",
      { ...request, token }
    );
  }
}



