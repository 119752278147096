import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import Vehicle from "src/services/Vehicle/VehicleService";
import {
  VehicleGetAllRequestModel,
  VehicleChangeStatusRequestModel,
  VehicleGetByIdRequestModel,
  VehicleUpdateRequestModel,
  VehicleSaveRequestModel,
} from "src/services/Vehicle/RequestModel";
import { MessageType } from "./system/types";
import { errorMessageSaveOrUpdate } from "src/helpers/functions";
import { formatDate } from "src/helpers/converts";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllPage(
  request: VehicleGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const VehicleService = new Vehicle();
    const result = await VehicleService.getAll(request);
    dispatch(setFormData("excel", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: VehicleGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const VehicleService = new Vehicle();
    const result = await VehicleService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: VehicleSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const VehicleService = new Vehicle();
    const result = await VehicleService.save(request);
    // dispatch(initialFormData(result));
    console.log(result,"save vehicle")
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Taşıt Modülü",
          true
        )
      );
      return result;
    } else {
      errorMessageSaveOrUpdate(dispatch,result)

      return false;
    }
  };
}
export function update(
  request: VehicleUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const VehicleService = new Vehicle();
    const result = await VehicleService.update(request);
    // dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Taşıt Modülü",
          true
        )
      );
      return true;
    } else {
      errorMessageSaveOrUpdate(dispatch,result)

      return false;
    }
  };
}

export function getById(
  request: VehicleGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const VehicleService = new Vehicle();
    const result = await VehicleService.getById(request);
    dispatch(
      initialFormData({
        ...result.data,
        intitialAppealDate:result.data.appealDate,
        initialAttachmentList: result.data.attachmentList,
        initialAttachmentCertificateList: result.data.attachmentCertificateList,
      })
    );
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: VehicleChangeStatusRequestModel,
  isDelete:boolean = false
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const VehicleService = new Vehicle();
    const result = await VehicleService.changeStatus(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(initialFormData(result));
      dispatch(
        showMessage(  isDelete
          ? "Silme işlemi başarıyla değiştirildi."
          : "Kayıt durumu işlemi başarıyla değiştirildi.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          "Taşıt Modülü",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Taşıt Modülü"
        )
      );
      return false;
    }
  };
}

export function getAllForPager(
  request: VehicleGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const VehicleService = new Vehicle();
    const result = await VehicleService.getAll(request);
    dispatch(setFormData("dataList", result.dataList));
    dispatch(setFormData("pageCount", result.pageCount));
    dispatch(setFormData("dataCount", result.dataCount));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}



export function excelDownloadHotel(
  request: VehicleGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const excelName = "TasitModulu_" + formatDate();

    const VehicleService = new Vehicle();
    await VehicleService.excelDownload(request, excelName);

    dispatch(toggleLoader());

    return true;
  };
}

export function getAllSertificationMA(
  request: VehicleGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const VehicleService = new Vehicle();
    const result = await VehicleService.GetVehicleCertificateListByTransport(request);
    dispatch(setFormData("TransportSerticationList", result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}


export function getAllVehicleForModal(
  request: VehicleGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const VehicleService = new Vehicle();
    const result = await VehicleService.getAll(request);
    dispatch(setFormData("vehicleListForModal", result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}
