import { HttpClient } from "src/library/HttpClient";
import {
  SubCategoryChangeStatusRequestModel,
  SubCategoryGetByIdRequestModel,
  SubCategoryUpdateRequestModel,
  SubCategorySaveRequestModel,
  SubCategoryGetAllRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class SubCategory {
  async getAll(request: SubCategoryGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.subCategory + "GetAll",
      { ...request, token }
    );
  }

  async save(request: SubCategorySaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.subCategory + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: SubCategoryUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.subCategory + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(request: SubCategoryGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.subCategory + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(
    request: SubCategoryChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.subCategory + "ChangeStatus",
      { ...request, token }
    );
  }
}
