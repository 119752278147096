import { Component } from "react";

interface OwnProps {
  onSubmit?: () => void;
  customRenderButtons: () => {};
  isAbsolute?: boolean;
}

export default class FormButtons extends Component<OwnProps> {
  render() {
    return (
      <div
        className={
          this.props.isAbsolute
            ? "form-buttons absolute row"
            : "form-buttons row"
        }
      >
        {this.props.customRenderButtons()}
      </div>
    );
  }
}
