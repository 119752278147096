import { HttpClient } from "src/library/HttpClient";
import {
  WinterTourismFacilityTypeChangeStatusRequestModel,
  WinterTourismFacilityTypeGetByIdRequestModel,
  WinterTourismFacilityTypeUpdateRequestModel,
  WinterTourismFacilityTypeSaveRequestModel,
  WinterTourismFacilityTypeGetAllRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class WinterTourismFacilityType {
  async getAll(
    request: WinterTourismFacilityTypeGetAllRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismFacilityType + "GetAll",
      { ...request, token }
    );
  }

  async save(request: WinterTourismFacilityTypeSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismFacilityType + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(
    request: WinterTourismFacilityTypeUpdateRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismFacilityType + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(
    request: WinterTourismFacilityTypeGetByIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismFacilityType + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(
    request: WinterTourismFacilityTypeChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismFacilityType + "ChangeStatus",
      { ...request, token }
    );
  }
}
