import { HttpClient } from "src/library/HttpClient";
import {
  HotelGetAllRequestModel,
  HotelSaveRequestModel,
  GetHotelCertificateListByHotelModel,
  GetHotelListModel,
  HotelUpdateRequestModel,
  HotelGetByIdRequestModel,
  HotelChangeStatusRequestModel,
  IGetHotelHistoryListRequest,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class Hotel {
  async getAll(request: HotelGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Hotel + "GetAll",
      { ...request, token }
    );
  }
  async getAllHotel(request: GetHotelListModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Hotel + "GetHotelList",
      { ...request, token }
    );
  }

  async excelDownload(request: any, name: string) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.Hotel + "GenerateExcel",
      name,
      { ...request, token }
    );
  }

  async getHotelHistoryList(
    request: IGetHotelHistoryListRequest
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Hotel + "getHotelHistoryList",
      { ...request, token }
    );
  }

  async getDeletedHotelList(request: GetHotelListModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Hotel + "GetDeletedHotelList",
      { ...request, token }
    );
  }

  async save(request: HotelSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Hotel + "SaveOrUpdate",
      { ...request, token }
    );
  }
  async GetHotelCertificateListByHotel(
    request: GetHotelCertificateListByHotelModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification + "GetHotelCertificateListByHotel",
      { ...request, token }
    );
  }
  async update(request: HotelUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Hotel + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(request: HotelGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Hotel + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(request: HotelChangeStatusRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Hotel + "ChangeStatus",
      { ...request, token }
    );
  }

  async putBlocked(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Hotel + "ChangeBlockedStatus",
      { ...request, token }
    );
  }

  async GetHotelCertificateList(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Hotel + "GetHotelCertificateList",
      { ...request, token }
    );
  }
}
