import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import SeaTourismFacilityType from "../services/SeaTourismFacilityType/SeaTourismFacilityTypeService";
import {
  SeaTourismFacilityTypeGetAllRequestModel,
  SeaTourismFacilityTypeChangeStatusRequestModel,
  SeaTourismFacilityTypeGetByIdRequestModel,
  SeaTourismFacilityTypeUpdateRequestModel,
  SeaTourismFacilityTypeSaveRequestModel,
} from "../services/SeaTourismFacilityType/RequestModel";
import { MessageType } from "./system/types";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllLFA(
  request: SeaTourismFacilityTypeGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const SeaTourismFacilityTypeService = new SeaTourismFacilityType();
    const result = await SeaTourismFacilityTypeService.getAll(request);
    dispatch(setFormData("GroupListFA", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: SeaTourismFacilityTypeGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismFacilityTypeService = new SeaTourismFacilityType();
    const result = await SeaTourismFacilityTypeService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: SeaTourismFacilityTypeSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismFacilityTypeService = new SeaTourismFacilityType();
    const result = await SeaTourismFacilityTypeService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Tesis Sınıfı Yönetimi"
        )
      );
      return result;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Tesis Sınıfı Yönetimi"
        )
      );
      return false;
    }
  };
}
export function update(
  request: SeaTourismFacilityTypeUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismFacilityTypeService = new SeaTourismFacilityType();
    const result = await SeaTourismFacilityTypeService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Tesis Sınıfı Yönetimi"
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Tesis Sınıfı Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getById(
  request: SeaTourismFacilityTypeGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismFacilityTypeService = new SeaTourismFacilityType();
    const result = await SeaTourismFacilityTypeService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: SeaTourismFacilityTypeChangeStatusRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismFacilityTypeService = new SeaTourismFacilityType();
    const result = await SeaTourismFacilityTypeService.changeStatus(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Tesis Sınıfı Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Tesis Sınıfı Yönetimi"
        )
      );
      return false;
    }
  };
}
