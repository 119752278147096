export function toTimeStamp(date?: string) {
  return new Date(date || "").getTime() / 1000;
}
export function toTimeStampNow() {
  return Math.round(new Date().getTime() / 1000);
}
export function toTimeStampWithDate(date?: any) {
  return new Date(date || "").getTime() / 1000;
}

export function formatDate(date: Date = new Date()) {
  var d = date,
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    minute = d.getMinutes();

  const minuteText = minute.toString().length >= 2 ? minute : "0" + minute;
  const hourText = hour.toString().length >= 2 ? hour : "0" + hour;

  const hourMinute = "_" + hourText + minuteText;

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year, hourMinute].join("");
}
export function formatDate3(date: Date = new Date()) {
  var d = date,
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    minute = d.getMinutes();

  const minuteText = minute.toString().length >= 2 ? minute : "0" + minute;
  const hourText = hour.toString().length >= 2 ? hour : "0" + hour;

  const hourMinute = hourText + ":" + minuteText;

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join(".") + " " + hourMinute;
}

export function formatDate2(date: Date = new Date()) {
  var d = date,
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("/");
}

export function toDate(timeStamp: any, format: string = "") {
  return new Date(timeStamp * 1000);
}

export function RenderChangesField(changesField2: any): any {
  let desc = "";
  for (let index = 0; index < changesField2.length; index++) {
    desc += "<span>" + changesField2[index].tr + "</span>";
  }

  return desc;
}

export function add3Hour(date?: any) {
  return new Date((toTimeStamp(date) + 10800) * 1000);
}

export function addDays(date: Date, days: number) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function downloadFileFormatDate(date: Date = new Date()) {
  var d = date,
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    minute = d.getMinutes();

  const minuteText = minute.toString().length >= 2 ? minute : "0" + minute;
  const hourText = hour.toString().length >= 2 ? hour : "0" + hour;

  const hourMinute = hourText + '_' + minuteText;

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year, hourMinute].join("_");
}
export function excelDownloadFileDate(date: Date = new Date()) {
  var d = date,
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join(".");
}
