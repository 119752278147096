import { HttpClient } from "src/library/HttpClient";
import {
  CountyChangeStatusRequestModel,
  CountyGetByIdRequestModel,
  CountyUpdateRequestModel,
  CountySaveRequestModel,
  CountyGetAllRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class County {
  async getAll(request: CountyGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.county + "GetAll",
      { ...request, token }
    );
  }

  async GetAllOrdered(request: CountyGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.county + "GetAllOrdered",
      { ...request, token }
    );
  }

  async save(request: CountySaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.county + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: CountyUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.county + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(request: CountyGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.county + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(request: CountyChangeStatusRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.county + "ChangeStatus",
      { ...request, token }
    );
  }
}
