import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import CongressFacilityType from "../services/CongressFacilityType/CongressFacilityTypeService";
import {
  CongressFacilityTypeGetAllRequestModel,
  CongressFacilityTypeChangeStatusRequestModel,
  CongressFacilityTypeGetByIdRequestModel,
  CongressFacilityTypeUpdateRequestModel,
  CongressFacilityTypeSaveRequestModel,
} from "../services/CongressFacilityType/RequestModel";
import { MessageType } from "./system/types";
import { errorMessageSaveOrUpdate } from "src/helpers/functions";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllLFA(
  request: CongressFacilityTypeGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const CongressFacilityTypeService = new CongressFacilityType();
    const result = await CongressFacilityTypeService.getAll(request);
    dispatch(setFormData("GroupListFA", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: CongressFacilityTypeGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressFacilityTypeService = new CongressFacilityType();
    const result = await CongressFacilityTypeService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: CongressFacilityTypeSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressFacilityTypeService = new CongressFacilityType();
    const result = await CongressFacilityTypeService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Tesis Sınıfı Yönetimi"
        )
      );
      return result;
    } else {
      errorMessageSaveOrUpdate(dispatch,result)

      return false;
    }
  };
}
export function update(
  request: CongressFacilityTypeUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressFacilityTypeService = new CongressFacilityType();
    const result = await CongressFacilityTypeService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Tesis Sınıfı Yönetimi"
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Tesis Sınıfı Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getById(
  request: CongressFacilityTypeGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressFacilityTypeService = new CongressFacilityType();
    const result = await CongressFacilityTypeService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: CongressFacilityTypeChangeStatusRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressFacilityTypeService = new CongressFacilityType();
    const result = await CongressFacilityTypeService.changeStatus(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Tesis Sınıfı Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Tesis Sınıfı Yönetimi"
        )
      );
      return false;
    }
  };
}
