/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import { showMessage } from "src/store/system/actions";
import { setFormData } from "src/store/form/actions";
import { DatePicker, Dropdown, PrimaryButton } from "office-ui-fabric-react";
import { DayPickerStrings, firstDayOfWeek, onFormatDate } from "src/config/otherConfig";
import { toDate, toTimeStamp } from "src/helpers/converts";

interface OwnProps {
    label?: string;
    description?: string
    type?:string
}

function mapStateToProps(state: RootState) {
    return {
        language: state.system.language,
        formData: state.form.formData,
        formErrors: state.form.formErrors,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        ...bindActionCreators(
            {
                setFormData,
                showMessage,
            },
            dispatch
        ),
    };
}

type ActivityStatusOpenCloseDateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    OwnProps;

interface OwnState {

}



class ActivityStatusOpenCloseDate extends Component<ActivityStatusOpenCloseDateProps, OwnState> {



    componentDidUpdate(nextProps: ActivityStatusOpenCloseDateProps) {
        const { setFormData } = this.props
        if (this.props.formData.isOpen !== nextProps.formData.isOpen) {
            if (this.props.formData.isOpen === true) {
                setFormData("isOpenStatus", "1");
            } else if (this.props.formData.isOpen === false) {
                setFormData("isOpenStatus", "2");
            } else {
                setFormData("isOpenStatus", "0");
            }
        }
    }


    getDate(date: any): any {
        if (date !== 0 && date !== undefined) {
            return toDate(date, "");
        }
    }
    render() {

        const { formData, formErrors, setFormData,type } = this.props;
        let optionData=[
            { key: "", text: "Seçiniz" },
            { key: "1", text: "Tesis Açık" },
            { key: "2", text: "Tesis Kapalı" },
        ]
        if(type==="edit"){
            optionData=[
                { key: "1", text: "Tesis Açık" },
                { key: "2", text: "Tesis Kapalı" },
            ]
        }
        return (
            <div className="item row">
                <Dropdown

                    label={this.props.label || "Tesis Faaliyet Durumu"}
                    placeholder={"Seçiniz"}
                    selectedKey={formData.isOpen === true ? "1" : formData.isOpen === false ? '2' : ""}
                    options={optionData.map ((item: any) => ({
                        key: item.key,
                        text: item.text,
                    }))}
                    onChanged={(item: any) => {
                        if (item.key === "1") {
                            setFormData("isOpenStatus", "1");
                        } else if (item.key === "2") {
                            setFormData("isOpenStatus", "2");
                        } else if (item.key === "") {
                            setFormData("isOpenStatus", "0");
                        }

                        const isOpenValue = item.key === "1" ? true : item.key === '2' ? false : null
                        setFormData("isOpen", isOpenValue);
                        setFormData("closingDate", 0);
                        setFormData("openingDate", 0);
                    }}
                    errorMessage={formErrors.isOpen}
                />

                {(formData.isOpen) ? (
                    <DatePicker
                        strings={DayPickerStrings}
                        firstDayOfWeek={firstDayOfWeek}
                        onSelectDate={(date) =>
                            setFormData(
                                "closingDate",
                                toTimeStamp(date?.toString())
                            )
                        }
                        label={`${this.props.description ? this.props.description : 'Tesisin'}  Kapanış Tarihi `}
                        placeholder="gün.ay.yıl"
                        formatDate={onFormatDate}
                        value={this.getDate(this.props.formData.closingDate)}
                        minDate={new Date()}
                    />
                ) : (
                    <DatePicker
                        strings={DayPickerStrings}
                        firstDayOfWeek={firstDayOfWeek}
                        onSelectDate={(date) =>
                            setFormData(
                                "openingDate",
                                toTimeStamp(date?.toString())
                            )
                        }
                        label={`${this.props.description ? this.props.description : 'Tesisin'}  Açılış Tarihi `}
                        placeholder="gün.ay.yıl"
                        formatDate={onFormatDate}
                        minDate={new Date()}
                        value={this.getDate(this.props.formData.openingDate)}
                    />
                )}

                <PrimaryButton
                    id="appealDate"
                    onClick={(x) => {
                        this.props.setFormData("openingDate", 0);
                        this.props.setFormData("closingDate", 0)
                    }}
                    iconProps={{ iconName: "Trash" }}
                    text="Tarihi Sil"
                    className="deleteDateBtn"
                    styles={{
                        root: {
                            marginTop: 5,
                            background: "#ac6262",
                            borderColor: "#ac6262",
                        },
                        rootHovered: {
                            background: "#a34c4c",
                            borderColor: "#a34c4c",
                        },
                        rootPressed: {
                            background: "#a34c4c",
                            borderColor: "#a34c4c",
                        },
                        rootDisabled: {
                            background: "#ececec",
                        },
                    }}
                />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityStatusOpenCloseDate);
