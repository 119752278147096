import { StatusEnums } from "../BaseEnum";

export interface VehicleDocumentTypeSaveDataModel {
  vehicleDocumentTypeName: string;
  status: StatusEnums;
}
export interface VehicleDocumentTypeSaveRequestModel {
  data: VehicleDocumentTypeSaveDataModel;
}

export interface VehicleDocumentTypeUpdateDataModel {
  vehicleDocumentTypeId: number;
  vehicleDocumentTypeName: string;
  status: StatusEnums;
}

export interface VehicleDocumentTypeUpdateRequestModel {
  data: VehicleDocumentTypeUpdateDataModel;
}

export interface VehicleDocumentTypeChangeStatusRequestModel {
  status: StatusEnums;
  id: number;
}

export enum ConditionOperatorEnum {
  Eq = 0,
  NotEq = 1,
  Like = 2,
  Gt = 3,
  Lt = 4,
  Ge = 5,
  Le = 6,
}

export interface criteriaItemListData {
  propertyName: string;
  value: any;
  isOr: boolean;
  conditionOperator: ConditionOperatorEnum;
}

export interface VehicleDocumentTypeGetAllRequestModel {
  criteriaItemList?: criteriaItemListData[];
  selectItemList?: string[];
  pageSize?: number;
  pageIndex?: number;
  orderField?: string;
  isDescending?: boolean;
  status?: StatusEnums;
}
export interface VehicleDocumentTypeGetByIdRequestModel {
  id: number;
}

export interface VehicleDocumentTypeChangeStatusRequestModel {
  id: number;
  status: StatusEnums;
}
