import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";

import {
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Checkbox,
  Label,
  Dropdown,
  Modal,
  Icon,
  DefaultButton,
} from "office-ui-fabric-react";
import { setFormData, setFormErrors } from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";
import {
  getIdList,
  sendDenunciation,
  changeDenunciationStatus,
  UndoDenunciationStatus,
  RestoreToUnsentStatus,
  sendDenunciationToMinistry,
  setDenunciationActionStatus,
  SendToIsdem,
  update,
} from "../../store/thunkDenunciation";
import BasicTable from "../BasicTable";
import {
  GetDenunciationListByFacilityRequestModel,
  UndoDenunciationStatusRequestModel,
  SendDenunciationRequestModel,
  UpdateRequest,
  ChangeDenunciationStatus,
  SendDenunciationToMinistry,
  SendToIsdemRequest,
  RestoreToUnsentRequestModel
} from "src/services/Denunciation/RequestModel";
import { getCompanyUserList } from "src/store/thunksSystemUser";
import { castThunkAction } from "src/helpers/casting";
import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";
import cookie from "react-cookies";
import HotelModal from "../HotelModalForDenunciationChange";
import { CATEGORY_TYPE_ENUM, getDenunciation } from "src/helpers/functions";
import { CategoryTypeEnum } from "src/services/Statistic/RequestModel";
let usertype: any = 0;

interface OwnProps {
  history: History;
  DenunciationId: number;
  FacilityType: number;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        SendToIsdem,
        setFormErrors,
        getIdList,
        sendDenunciation,
        changeDenunciationStatus,
        sendDenunciationToMinistry,
        update,
        getCompanyUserList,
        RestoreToUnsentStatus,
        UndoDenunciationStatus,
        setDenunciationActionStatus,
      },
      dispatch
    ),
  };
}

type EditDenunciationProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  messageType: MessageBarType;
  denunciationIdList: number[];
  denunciationIdList2: number[];
  denunciationIdListReturn: number[];
  denunciationIdListMReturn: number[];
  modalType: number;
  modelStatus: boolean;
  modelStatus2: boolean;
  modelStatus3: boolean;
  modelStatus4: boolean;
  modalStatusForFirma: boolean;
  modelStatus3err: boolean;
  modelStatus3number: number;
  unSentDenunciationId: number;
  isActionStatus: boolean;
}

class EditDenunciation extends Component<EditDenunciationProps, OwnState> {
  state = {
    modalType: 0,
    modelStatus3: false,
    modelStatus: false,
    modelStatus2: false,
    modelStatus4: false,
    modelStatus3err: false,
    modalStatusForFirma: false,
    messageType: MessageBarType.warning,
    denunciationIdList: [],
    denunciationIdList2: [],
    denunciationIdListReturn: [],
    denunciationIdListMReturn: [],
    modelStatus3number: 0,
    unSentDenunciationId: 0,
    isActionStatus: false,
  };

  UNSAFE_componentWillMount() {
    usertype = cookie.load("systemUserInfo");
    if (this.props.DenunciationId) {
      castThunkAction(
        this.props.getIdList({
          facilityId: this.props.DenunciationId,
          facilityType: this.props.FacilityType,
        } as GetDenunciationListByFacilityRequestModel)
      ).then((response: any) => {
        let arr: number[];
        arr = [];

        this.props.formData?.data?.unSent.map(
          (x: {
            denunciationId: number;
            isReported: boolean;
            isReportedToMinistry: boolean;
          }) =>
            !x.isReported &&
            !x.isReportedToMinistry &&
            arr.push(x.denunciationId)
        );

        this.setState({ denunciationIdList: arr });

        const request6: SystemUserGetAllModel = {
          systemUserType: 2,
        };
        this.props.getCompanyUserList(request6);
      });
    }
  }

  renderTextByMessageType() {
    switch (this.state.messageType) {
      case MessageBarType.success:
        return "Kaydetme işlemi başarıyla gerçekleşmiştir.";
      case MessageBarType.error:
        return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
      default:
        return "Lütfen aşağıdaki bilgileri doldurun, ve oluşturmayı tamamlayın!";
    }
  }

  renderMessageBar() {
    return (
      <MessageBar messageBarType={this.state.messageType} isMultiline={false}>
        {this.renderTextByMessageType()}
      </MessageBar>
    );
  }

  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  onSubmit(isDraft?: boolean) {
    const { formData, setFormErrors } = this.props;

    const validationSchema = _yup.object({
      resultType: _yup.string().required("Bu alan zorunludur!"),
    });
    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  unSent(id: any) {
    const { formData, setFormErrors } = this.props;

    const validationSchema = _yup.object({});
    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});

        const request: UndoDenunciationStatusRequestModel = {
          denunciationIdList: [id],
        };

        castThunkAction(this.props.UndoDenunciationStatus(request)).then(
          (response: any) => {
            this.setState(
              {
                messageType: response
                  ? MessageBarType.success
                  : MessageBarType.error,
              },
              () => {
                if (response) {
                  this.props.history.push(
                    "/denunciation/edit/" +
                      this.props.DenunciationId +
                      "/" +
                      this.props.FacilityType
                  );
                }
              }
            );
          }
        );
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  onSent() {
    const { formData, setFormErrors } = this.props;

    const validationSchema = _yup.object({});
    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});

        const request: SendDenunciationRequestModel = {
          facilityId: this.props.DenunciationId,
          denunciationIdList: this.state.denunciationIdList,
          correctionDescription: this.props.formData.correctionDescription,
          companyUserId: this.props.formData.companyUserId,
          denunciationAssignedStatus:
            this.props.formData.denunciationAssignedStatus,
        };

        castThunkAction(this.props.sendDenunciation(request)).then(
          (response: any) => {
            this.setState(
              {
                messageType: response
                  ? MessageBarType.success
                  : MessageBarType.error,
              },
              () => {
                if (response) {
                  this.props.history.push(
                    "/denunciation/edit/" +
                      this.props.DenunciationId +
                      "/" +
                      this.props.FacilityType
                  );
                }
              }
            );
          }
        );
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  onSentM() {
    if (this.props.formData.data.certificateTypeId !== 5)
      var cc = window.confirm(
        "İçişleri Bakanlığına gönderilecektir.Onaylıyor musunuz?"
      );
    else var cc = window.confirm("Şikayet kapatılacaktır. Onaylıyor musunuz?");

    if (cc) {
      const { formData, setFormErrors } = this.props;

      const validationSchema = _yup.object({});
      let formErrors = {};
      validationSchema
        .validate(formData, {
          abortEarly: false,
        })
        .then(() => {
          setFormErrors({});

          const request: SendToIsdemRequest = {
            hotelId: this.props.DenunciationId,
            denunciationIdList: this.state.denunciationIdListMReturn,
          };

          castThunkAction(this.props.SendToIsdem(request)).then(
            (response: any) => {
              this.setState(
                {
                  messageType: response
                    ? MessageBarType.success
                    : MessageBarType.error,
                },
                () => {
                  if (response) {
                    this.props.history.push(
                      "/denunciation/edit/" +
                        this.props.DenunciationId +
                        "/" +
                        this.props.FacilityType
                    );
                  }
                }
              );
            }
          );
        })
        .catch((err) => {
          err.inner.forEach((e: any) => {
            const { path, message } = e;
            formErrors[path] = message;
          });
          setFormErrors(formErrors);
        });
    }
  }

  onSentC() {
    const { formData, setFormErrors } = this.props;

    const validationSchema = _yup.object({});
    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});

        const request: UpdateRequest = {
          denunciationIdList: this.state.denunciationIdListReturn,
          resultType: this.props.formData.resultType,
          explanation: this.props.formData.explanation,
        };

        castThunkAction(this.props.update(request)).then((response: any) => {
          this.setState(
            {
              messageType: response
                ? MessageBarType.success
                : MessageBarType.error,
            },
            () => {
              if (response) {
                this.props.history.push(
                  "/denunciation/edit/" +
                    this.props.DenunciationId +
                    "/" +
                    this.props.FacilityType
                );
              }
            }
          );
        });
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  onSentKTB() {
    const { formData, setFormErrors } = this.props;

    const validationSchema = _yup.object({});
    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});

        const request: SendDenunciationToMinistry = {
          denunciationIdList: this.state.denunciationIdList,
          correctionDescription: this.props.formData.correctionDescription,
        };

        castThunkAction(this.props.sendDenunciationToMinistry(request)).then(
          (response: any) => {
            this.setState(
              {
                messageType: response
                  ? MessageBarType.success
                  : MessageBarType.error,
              },
              () => {
                if (response) {
                  this.props.history.push(
                    "/denunciation/edit/" +
                      this.props.DenunciationId +
                      "/" +
                      this.props.FacilityType
                  );
                }
              }
            );
          }
        );
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  RestoreToUnSent(denunciationIdList:any) {

    const request: RestoreToUnsentRequestModel = {
      denunciationIdList: denunciationIdList,
    };

    castThunkAction(this.props.RestoreToUnsentStatus(request)).then(
      (response: any) => {
        this.setState(
          {
            messageType: response
              ? MessageBarType.success
              : MessageBarType.error,
          },
          () => {
            if (response) {
              this.props.history.push(
                "/denunciation/edit/" +
                  this.props.DenunciationId +
                  "/" +
                  this.props.FacilityType
              );
            }
          }
        );
      }
    );
}

  onChange() {
    const { formData, setFormErrors } = this.props;

    const validationSchema = _yup.object({
      resultType: _yup.string().required("Bu alan zorunludur!"),
    });
    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});

        const request: ChangeDenunciationStatus = {
          denunciationIdList: this.state.denunciationIdListReturn,
          resultType: this.props.formData.resultType,
          explanation: this.props.formData.explanation,
        };

        castThunkAction(this.props.changeDenunciationStatus(request)).then(
          (response: any) => {
            this.setState(
              {
                messageType: response
                  ? MessageBarType.success
                  : MessageBarType.error,
              },
              () => {
                this.props.history.push(
                  "/denunciation/edit/" +
                    this.props.DenunciationId +
                    "/" +
                    this.props.FacilityType
                );
              }
            );
          }
        );
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  onSetActionStatus(actionStatus: boolean, denunciationId: number) {
    setFormErrors({});

    const request = {
      denunciationActionType: actionStatus,
      denunciationId: denunciationId,
    };

    castThunkAction(this.props.setDenunciationActionStatus(request)).then(
      (response: any) => {
        this.setState(
          {
            messageType: response
              ? MessageBarType.success
              : MessageBarType.error,
          },
          () => {
            if (response) {
              this.props.history.push(
                "/denunciation/edit/" +
                  this.props.DenunciationId +
                  "/" +
                  this.props.FacilityType
              );
            }
          }
        );
      }
    );
  }
  // onChangeStatus(status: StatusEnums) {
  // 	const request: DenunciationChangeStatusRequestModel = {
  // 		id: this.props.formData.DenunciationId,
  // 		status
  // 	};
  // 	castThunkAction(this.props.changeStatus(request)).then((response: any) => {
  // 		if (response && status === StatusEnums.Deleted) {
  // 			this.props.history.push("/Denunciation");
  // 		}
  // 		else if (status === StatusEnums.Draft || StatusEnums.Published) {
  // 			this.setState({
  // 				messageType: MessageBarType.success
  // 			})
  // 		}
  // 		else {
  // 			this.setState({
  // 				messageType: MessageBarType.error
  // 			})
  // 		}
  // 	});
  // }

  renderForm() {
   
    const { formData, formErrors, setFormData } = this.props;
    return (
      <div className="form-groups row">
        {usertype !== "2" && (
          <>
            <div className="form-group col-md-6">
              <div className="title">Önceki Denetleyici Firma</div>
              <div style={{ marginBottom: 10 }}>
                {this.props.formData?.data?.firstCompanyUserTitle
                  ? this.props.formData?.data?.firstCompanyUserTitle
                  : "Önceki Denetleyici Firması Bulunmamaktadır."}
              </div>
            </div>
            <div className="form-group col-md-6">
              <div className="title">Güncel Denetleyici Firma</div>
              <div style={{ marginBottom: 10 }}>
                {this.props.formData?.data?.companyUserTitle
                  ? this.props.formData?.data?.companyUserTitle
                  : "Güncel Denetleyici Firması Bulunmamaktadır."}
              </div>
            </div>
          </>
        )}

        <div className="form-group col-md-6">
          <div className="title">Tesis Bilgisi</div>
          <div style={{ marginBottom: 10 }}>
            {(this.props.formData?.data?.facilityName
              ? this.props.formData?.data?.facilityName
              : " ") +
              " " +
              (this.props.formData?.data?.documentNo
                ? this.props.formData?.data?.documentNo
                : " ") +
              " " +
              (this.props.formData?.data?.countyName
                ? this.props.formData?.data?.countyName
                : " ") +
              " " +
              (this.props.formData?.data?.cityName
                ? this.props.formData?.data?.cityName
                : " ") +
              " "}
          </div>
        </div>

        <div className="form-group col-md-6">
          <div className="title">Tesis Belge Tipi</div>
          {this.props.formData?.data?.certificateTypeId === 4 && ( 
            <>
              <div>TC Kültür ve Turizm Bakanlığı</div>
            </>
          )}
          {this.props.formData?.data?.certificateTypeId === 5 && (
            <>
              <div> Diğer</div>
            </>
          )}
        </div>

        <div className="form-group col-md-12">
          <div className="form-wrapper">
            <div className="item row">
              {(usertype !== "3" ||
                this.state.denunciationIdListReturn.length > 0) && (
                <Dropdown
                  onChanged={(event: any) =>
                    setFormData("resultType", event.key)
                  }
                  errorMessage={formErrors.resultType}
                  label="Şikayet Sonuç Durumu"
                  options={[
                    {
                      key: 3,
                      text: "Denetleyici Firması Değiştiği için Aksiyon Alınamaz",
                    },
                    { key: 20, text: "Birinci İhlal Uyarı" },
                    { key: 30, text: "İkinci İhlal İdari Para Cezası Teklifi" },
                    {
                      key: 40,
                      text: "Üçüncü İhlal 1 (Bir) Gün Faaliyet Durdurma Teklifi",
                    },
                    {
                      key: 50,
                      text: "Dördüncü İhlal 3 (Üç) Gün Faaliyet Durdurma Teklifi",
                    },
                    { key: 60, text: "Aykırılık tespit edilmemiştir" },
                    { key: 70, text: "Mükerrer İhbar" },
                    {
                      key: 100,
                      text: "Eksik bilgiden dolayı işlem yapılamamıştır.",
                    },
                    { key: 110, text: "Asılsız İhbar" },
                  ]}
                  className="custom-dropdown"
                />
              )}
            </div>

            <div className="item row">
              {(usertype !== "3" ||
                this.state.denunciationIdListReturn.length > 0) && (
                <textarea
                  placeholder="Açıklama"
                  value={formData?.explanation || ""}
                  rows={10}
                  className="custom-textfield border correctionDescription"
                  onChange={(event: any) =>
                    setFormData("explanation", event.target.value)
                  }
                ></textarea>
              )}
            </div>
            <div className="item row">
              {usertype !== "2" && usertype !== "4" && (
                <textarea
                  placeholder="Revize Açıklaması"
                  value={formData?.correctionDescription || ""}
                  rows={1}
                  className="custom-textfield border correctionDescription"
                  onChange={(event: any) =>
                    setFormData("correctionDescription", event.target.value)
                  }
                ></textarea>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStatusByType() {
    switch (this.props.formData.status) {
      case StatusEnums.Published:
        return (
          <div className="status">
            Durum: <strong>Yayımda</strong>
          </div>
        );
      case StatusEnums.Draft:
        return (
          <div className="status">
            Durum: <strong>Taslak</strong>
          </div>
        );
      case StatusEnums.Deleted:
        return (
          <div className="status">
            Durum: <strong>Silindi</strong>
          </div>
        );
      default:
        return null;
    }
  }
  renderListSent() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "denunciationChannel",
            text: "Şikayet Kaynağı",
            col: "col-md-1",
            onRender: (item: any) => getDenunciation(item.denunciationChannel),
            hidden: usertype === "2" || usertype === "4",
          },

          {
            columnName: "denunciationId",
            text: "Durum",
            col: "col-md-1-3",
            className: "operations",
            onRender: (item: {
              isReported: boolean;
              isReportedToMinistry: boolean;
              isReportedToTga: boolean;
              isCorrection: boolean;

            }) => (
              <React.Fragment>
                {item.isReported ? (
                  item.isReportedToMinistry ? (
                    item.isReportedToTga ? (
                      <div>
                        {item.isCorrection
                          ? " " +
                            "Revize KTB Tarafından Cevaplandı"
                          :  " " + "KTB Cevaplandırdı"}
                      </div>
                    ) : (
                      <div>
                        {item.isCorrection
                          ? " " + "KTB'den Revize İstenildi"
                          : " " + "KTB'ye İletildi"}
                      </div>
                    )
                  ) : item.isReportedToTga ? (
                    <div>
                      {item.isCorrection
                        ?  " " + "Revize Firma Tarafından Cevaplandı"
                        :  " " + "Firmaya Cevaplandırdı"}
                    </div>
                  ) : (
                    <div>
                      {item.isCorrection
                        ? " " + "Revize Firmaya İletildi"
                        : " " + "Firmaya İletildi"}
                    </div>
                  )
                ) : item.isReportedToMinistry ? (
                  item.isReportedToTga ? (
                    <div>
                      {item.isCorrection
                        ? " " + "Revize KTB Tarafından Cevaplandı"
                        : " " + "KTB Cevaplandırdı"}
                    </div>
                  ) : (
                    <div>
                      {item.isCorrection
                        ? " " + "KTB'den Revize İstenildi"
                        : " " + "KTB'ye İletildi"}
                    </div>
                  )
                ) : (
                  <div>{" "}İşleme Alınmadı</div>
                )}
              </React.Fragment>
            ),
          },
          {
            columnName: "denunciationIssue",
            text: "Şikayet Açıklama",
            col: "col-md-2",
            className: "operations",
            onRender: (item: { denunciationId: any; isReported: boolean }) => (
              <React.Fragment>
                <PrimaryButton
                  onClick={() => {
                    this.props.setFormData("selectitem", item);
                    this.setState({ modelStatus4: true });
                  }}
                  styles={{
                    rootDisabled: {
                      background: "#626262",
                      borderColor: "#777373 !important",
                    },
                  }}
                  text={"Detay"}
                />
              </React.Fragment>
            ),
          },
          {
            columnName: "explanation",
            text: "Cevap Açıklama",
            col: "col-md-2",
          },
          {
            columnName: "mediaUrl",
            text: "Medya",
            col: "col-md-1",
            onRender: (item: { mediaUrl: any }) =>
              item.mediaUrl && (
                <React.Fragment>
                  <a
                    href={item.mediaUrl}
                    target="_blank"
                    style={{
                      background: "#444",
                      color: "#fff",
                      padding: "5px 10px",
                      border: "none",
                    }}
                  >
                    Göster
                  </a>
                </React.Fragment>
              ),
          },
          {
            columnName: "denunciationStatusString",
            text: "Şikayet Sonuç Durumu",
            col: "col-md-1",
          },
          {
            columnName: "eventTimeString",
            text: "Olay Tarihi-Oluşturma Tarihi",
            col: "col-md-1",
            onRender: (item: {
              eventTimeString: any;
              createDateString: any;
            }) => (
              <React.Fragment>
                <div>
                  {item.eventTimeString} {item.createDateString}
                </div>
              </React.Fragment>
            ),
          },

          {
            columnName: "denunciationId",
            text: "İşlem",
            col: "col-md-1",
            className: "operations",
            onRender: (item: {
              denunciationId: any;
              denunciationChannel: any;
            }) => (
              <React.Fragment>
              {(usertype === "1" || usertype === "3") && item.denunciationChannel === 2 &&
              <button
              onClick={() =>
              this.RestoreToUnSent([item.denunciationId])
              }
            >
              Aktifleştir
            </button>
          } 
              </React.Fragment>
            ),
          },
          {
            columnName: "denunciationId",
            text: "İşlem Yapılamadı Durumu",
            col: "col-md-1",
            className: "operations",
            hidden: usertype === "2" || usertype === "4",
            onRender: (item: {
              denunciationId: any;
              isReported: boolean;
              certificateTypeName: any;
              isActionStatus: any;
              certificateTypeId: any;
            }) => (
              <React.Fragment>
                {item.isActionStatus === true ? "İşlem Yapılamadı" : ""}
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.data?.sent || []}
      />
    );
  }

  renderListUnSentTGA() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "denunciationChannel",
            text: "Şikayet Kaynağı",
            col: "col-md-1-3",
            onRender: (item: any) => getDenunciation(item.denunciationChannel),
          },
          {
            columnName: "denunciationId",
            text: "Durum",
            col: "col-md-1-3",
            className: "operations",
            onRender: (item: {
              isReported: boolean;
              isReportedToMinistry: boolean;
              isReportedToTga: boolean;
              isCorrection: boolean;

            }) => (
              <React.Fragment>
                {item.isReported ? (
                  item.isReportedToMinistry ? (
                    item.isReportedToTga ? (
                      <div>
                        {item.isCorrection
                          ? " " +
                            "Revize KTB Tarafından Cevaplandı"
                          :  " " + "KTB Cevaplandırdı"}
                      </div>
                    ) : (
                      <div>
                        {item.isCorrection
                          ? " " + "KTB'den Revize İstenildi"
                          : " " + "KTB'ye İletildi"}
                      </div>
                    )
                  ) : item.isReportedToTga ? (
                    <div>
                      {item.isCorrection
                        ?  " " + "Revize Firma Tarafından Cevaplandı"
                        :  " " + "Firmaya Cevaplandırdı"}
                    </div>
                  ) : (
                    <div>
                      {item.isCorrection
                        ? " " + "Revize Firmaya İletildi"
                        : " " + "Firmaya İletildi"}
                    </div>
                  )
                ) : item.isReportedToMinistry ? (
                  item.isReportedToTga ? (
                    <div>
                      {item.isCorrection
                        ? " " + "Revize KTB Tarafından Cevaplandı"
                        : " " + "KTB Cevaplandırdı"}
                    </div>
                  ) : (
                    <div>
                      {item.isCorrection
                        ? " " + "KTB'den Revize İstenildi"
                        : " " + "KTB'ye İletildi"}
                    </div>
                  )
                ) : (
                  <div>{" "}İşleme Alınmadı</div>
                )}
              </React.Fragment>
            ),
          },
          {
            columnName: "denunciationIssue",
            text: "Şikayet Açıklama",
            col: "col-md-1-3",
            className: "operations",
            onRender: (item: { denunciationId: any; isReported: boolean }) => (
              <React.Fragment>
                <PrimaryButton
                  onClick={() => {
                    this.props.setFormData("selectitem", item);
                    this.setState({ modelStatus4: true });
                  }}
                  styles={{
                    rootDisabled: {
                      background: "#626262",
                      borderColor: "#777373 !important",
                    },
                  }}
                  text={"Detay"}
                />
              </React.Fragment>
            ),
          },
          {
            columnName: "explanation",
            text: "Cevap Açıklama-Revize Açıklama",
            col: "col-md-1-3",
            onRender: (item: {
              explanation: any;
              correctionDescription: any;
            }) => (
              <React.Fragment>
                {item.explanation}-{item.correctionDescription}
              </React.Fragment>
            ),
          },

          {
            columnName: "mediaUrl",
            text: "Medya",
            col: "col-md-1-3",
            onRender: (item: { mediaUrl: any }) =>
              item.mediaUrl && (
                <React.Fragment>
                  <a
                    href={item.mediaUrl}
                    target="_blank"
                    style={{
                      background: "#444",
                      color: "#fff",
                      padding: "5px 10px",
                      border: "none",
                    }}
                  >
                    Göster
                  </a>
                </React.Fragment>
              ),
          },

          {
            columnName: "denunciationStatusString",
            text: "Şikayet Sonuç Durumu",
            col: "col-md-1-3",
            onRender: (item: {
              denunciationStatusString: any;
              denunciationStatus: any;
            }) =>
              item.denunciationStatus === 3
                ? "Denetleyici Firması Değiştiği için Aksiyon Alınamaz"
                : item.denunciationStatusString,
          },

          {
            columnName: "eventTimeString",
            text: "Olay Tarihi-Oluşturma Tarihi",
            col: "col-md-1-3",
            onRender: (item: {
              eventTimeString: any;
              createDateString: any;
            }) => (
              <React.Fragment>
                <div>
                  {item.eventTimeString} {item.createDateString}
                </div>
              </React.Fragment>
            ),
          },

          {
            columnName: "denunciationId",
            text: "Firmaya veya KTB'ye Gönderim",
            col: "col-md-1-3",
            className: "operations",
            onRender: (item: {
              denunciationId: any;
              isReported: boolean;
              isReportedToMinistry: boolean;
            }) => (
              <React.Fragment>
                {!(item.isReported || item.isReportedToMinistry) ? (
                  <Checkbox
                    checked={
                      this.state?.denunciationIdList?.filter(
                        (x: any) => x === item.denunciationId
                      ).length > 0
                        ? true
                        : false
                    }
                    onChange={() => this.selectCheckbox(item.denunciationId)}
                    className="custom-checkbox"
                  />
                ) : (
                  <PrimaryButton
                    onClick={() => this.unSent(item.denunciationId)}
                    styles={{
                      rootDisabled: {
                        background: "#626262",
                        borderColor: "#777373 !important",
                      },
                    }}
                    text={"Geri Al"}
                  />
                )}
              </React.Fragment>
            ),
          },

          {
            columnName: "denunciationId",
            text: "Cevabı Düzenle",
            col: "col-md-1-3",
            className: "operations",
            onRender: (item: {
              denunciationId: any;
              isReported: boolean;
              certificateTypeName: any;
              isReportedToMinistry:any;
              isReportedToTga:any;
            }) => (
              <React.Fragment>
                {this.props.formData?.data?.certificateTypeName !== "5" &&  (
                  <Checkbox
                    checked={
                      this.state?.denunciationIdListReturn?.filter(
                        (x: any) => x === item.denunciationId
                      ).length > 0
                        ? true
                        : false
                    }
                    onChange={() =>
                      this.selectCheckboxreturn(item.denunciationId)
                    }
                    disabled={(item.isReportedToMinistry===true && item.isReported===false && item.isReportedToTga ===false)|| item.isReportedToMinistry===false && item.isReported===true && item.isReportedToTga ===false }
                    className="custom-checkbox"
                  />
                )}
              </React.Fragment>
            ),
          },

          {
            columnName: "denunciationId",
            text: "Şikayeti Kapat",
            col: "col-md-1-3",
            className: "operations",
            onRender: (item: {
              denunciationId: any;
              isReported: boolean;
              isReportedToTga: boolean;
              certificateTypeName: any;
              isReportedToMinistry:any;
            }) => (
              <React.Fragment>
                {this.props.formData?.data?.certificateTypeName !== "5" &&
                  (item.isReportedToTga ? (
                    <Checkbox
                      checked={
                        this.state?.denunciationIdListMReturn?.filter(
                          (x: any) => x === item.denunciationId
                        ).length > 0
                          ? true
                          : false
                      }
                      onChange={() =>
                        this.selectCheckboxreturnM(item.denunciationId)
                      }

                      className="custom-checkbox"

                    />
                  ) : (
                    <Checkbox
                      checked={
                        this.state?.denunciationIdListMReturn?.filter(
                          (x: any) => x === item.denunciationId
                        ).length > 0
                          ? true
                          : false
                      }
                      onChange={() =>
                        this.selectCheckboxreturnM(item.denunciationId)
                      }
                      styles={{
                        checkbox: {
                          background: "red",
                        },
                      }}
                      className="custom-checkbox"
                      disabled={(item.isReportedToMinistry===true && item.isReported===false && item.isReportedToTga ===false)|| item.isReportedToMinistry===false && item.isReported===true && item.isReportedToTga ===false }
                    />
                  ))}
              </React.Fragment>
            ),
          },

          {
            columnName: "denunciationId",
            text: "Detay",
            col: "col-md-1-3",
            className: "operations",
            onRender: (item: { denunciationId: any; isReported: boolean }) => (
              <React.Fragment>
                <PrimaryButton
                  onClick={() => {
                    this.props.setFormData("selectitem", item);
                    this.setState({ modelStatus2: true });
                  }}
                  styles={{
                    rootDisabled: {
                      background: "#626262",
                      borderColor: "#777373 !important",
                    },
                  }}
                  text={"Detay"}
                />
              </React.Fragment>
            ),
          },
          {
            columnName: "denunciationId",
            text: "Başka Tesise Aktar",
            col: "col-md-1",
            className: "operations",

            onRender: (item: {
              denunciationId: any;
              isReported: boolean;
              facilityType: any;
              isReportedToMinistry:any;
              isReportedToTga:any;
            }) => (
              <React.Fragment>
                {usertype !== "5" &&
                  usertype !== "2" &&
                  CategoryTypeEnum.Hotel === item.facilityType && (
                    <PrimaryButton
                      onClick={(x) => {
                      this.props.setFormData("hotelModalShow", true);
                      this.props.setFormData( "unSentDenunciationId", item.denunciationId);
                       }}
                       disabled={(item.isReportedToMinistry===true && item.isReported===false && item.isReportedToTga ===false)|| item.isReportedToMinistry===false && item.isReported===true && item.isReportedToTga ===false }
                       className={(item.isReportedToMinistry===true && item.isReported===false && item.isReportedToTga ===false) ||(item.isReportedToMinistry===false && item.isReported===true && item.isReportedToTga ===false) ? "disabled" : ""}
                      styles={{
                        rootDisabled: {
                          background: "#626262",
                          borderColor: "#777373 !important",
                        },
                      }}
                      text={"Aktar"}
                    />
                  )}
              </React.Fragment>
            ),
          },
          
          {
            columnName: "denunciationId",
            text: "İşlem Yapılamadı Durumu ",
            col: "col-md-1",
            className: "operations",
            onRender: (item: {
              denunciationId: any;
              isReported: boolean;
              certificateTypeName: any;
              isActionStatus: any;
              certificateTypeId: any;
            }) => (
              <React.Fragment>
                {item.isActionStatus ? (
                  item.certificateTypeId === 4 ? null : ( 
                    <button
                      onClick={() =>
                        this.onSetActionStatus(false, item.denunciationId)
                      }
                    >
                      {" "}
                      Geri Al
                    </button>
                  )
                ) : item.certificateTypeId === 4 ? null : (
                  <button
                    onClick={() =>
                      this.onSetActionStatus(true, item.denunciationId)
                    }
                  >
                    İşlem Yapılamadı Kaydet
                  </button>
                )}
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.data?.unSent || []}
      />
    );
  }

  modal3(): any {
    switch (this.state.modelStatus3number) {
      case 1:
        return (
          <div>
            {this.props.formData?.data?.firstCompanyUserTitle}
            <DefaultButton
              style={{ marginTop: 15 }}
              text="Gönder"
              onClick={() => {
                this.props.setFormData(
                  "companyUserId",
                  this.props.formData?.data?.firstCompanyUserId
                );
                this.props.setFormData("denunciationAssignedStatus", 1);
                this.onSent();
              }}
            />
          </div>
        );

      case 2:
        return (
          <div>
            {this.props.formData?.data?.companyUserTitle}
            <DefaultButton
              style={{ marginTop: 15 }}
              text="Gönder"
              onClick={() => {
                this.props.setFormData(
                  "companyUserId",
                  this.props.formData?.data?.companyUserId
                );
                this.props.setFormData("denunciationAssignedStatus", 2);
                this.onSent();
              }}
            />
          </div>
        );

      case 3:
        return (
          <div>
            {this.props.formData?.data?.assignedCompanyUserTitle}
            <Dropdown
              options={(this.props.formData.CompanyUserList?.dataList).map(
                (item: any) => ({
                  key: item.systemUserId,
                  text: item.title,
                })
              )}
              className="custom-dropdown col-md-12"
              label="Denetleyici Firma"
              placeholder="Seçiniz"
              selectedKey={
                this.props.formData.isOnlyNonCompanyUser === true
                  ? 100000
                  : this.props.formData.companyUserId
              }
              onChange={(event: any, option: any) => {
                this.props.setFormData("companyUserId", option.key);
                this.props.setFormData("denunciationAssignedStatus", 3);
              }}
              calloutProps={{
                className: "custom-dropdown-callout",
              }}
            />

            <DefaultButton
              style={{ marginTop: 15 }}
              text="Gönder"
              onClick={() => {
                this.onSent();
              }}
            />
          </div>
        );
    }
  }
  renderListUnSentFirma() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "denunciationId",
            text: "Durum",
            col: "col-md-1-3",
            className: "operations",
            onRender: (item: {
              isReported: boolean;
              isReportedToMinistry: boolean;
              isReportedToTga: boolean;
              isCorrection: boolean;

            }) => (
              <React.Fragment>
                {item.isReported ? (
                  item.isReportedToMinistry ? (
                    item.isReportedToTga ? (
                      <div>
                        {item.isCorrection
                          ? " " +
                            "Revize KTB Tarafından Cevaplandı"
                          :  " " + "KTB Cevaplandırdı"}
                      </div>
                    ) : (
                      <div>
                        {item.isCorrection
                          ? " " + "KTB'den Revize İstenildi"
                          : " " + "KTB'ye İletildi"}
                      </div>
                    )
                  ) : item.isReportedToTga ? (
                    <div>
                      {item.isCorrection
                        ?  " " + "Revize Firma Tarafından Cevaplandı"
                        :  " " + "Firmaya Cevaplandırdı"}
                    </div>
                  ) : (
                    <div>
                      {item.isCorrection
                        ? " " + "Revize Firmaya İletildi"
                        : " " + "Firmaya İletildi"}
                    </div>
                  )
                ) : item.isReportedToMinistry ? (
                  item.isReportedToTga ? (
                    <div>
                      {item.isCorrection
                        ? " " + "Revize KTB Tarafından Cevaplandı"
                        : " " + "KTB Cevaplandırdı"}
                    </div>
                  ) : (
                    <div>
                      {item.isCorrection
                        ? " " + "KTB'den Revize İstenildi"
                        : " " + "KTB'ye İletildi"}
                    </div>
                  )
                ) : (
                  <div>{" "}İşleme Alınmadı</div>
                )}
              </React.Fragment>
            ),
          },
          {
            columnName: "denunciationIssue",
            text: "Şikayet Açıklama",
            col: "col-md-2",
            className: "operations",
            onRender: (item: { denunciationId: any; isReported: boolean }) => (
              <React.Fragment>
                <PrimaryButton
                  onClick={() => {
                    this.props.setFormData("selectitem", item);
                    this.setState({ modelStatus4: true });
                  }}
                  styles={{
                    rootDisabled: {
                      background: "#626262",
                      borderColor: "#777373 !important",
                    },
                  }}
                  text={"Detay"}
                />
              </React.Fragment>
            ),
          },
          {
            columnName: "explanation",
            text: "Cevap Açıklama-Revize Açıklama",
            col: "col-md-2",
          },

          {
            columnName: "correctionDescription",
            text: "Revize",
            col: "col-md-1",
          },

          {
            columnName: "mediaUrl",
            text: "Medya",
            col: "col-md-1",
            onRender: (item: { mediaUrl: any }) =>
              item.mediaUrl && (
                <React.Fragment>
                  <a
                    href={item.mediaUrl}
                    target="_blank"
                    style={{
                      background: "#444",
                      color: "#fff",
                      padding: "5px 10px",
                      border: "none",
                    }}
                  >
                    Göster
                  </a>
                </React.Fragment>
              ),
          },
          {
            columnName: "denunciationStatusString",
            text: "Şikayet Sonuç Durumu",
            col: "col-md-1-3",
            onRender: (item: {
              denunciationStatusString: any;
              denunciationStatus: any;
            }) =>
              item.denunciationStatus === 3
                ? "Denetleyici Firması Değiştiği için Aksiyon Alınamaz"
                : item.denunciationStatusString,
          },
          {
            columnName: "eventTimeString",
            text: "Olay Tarihi-Oluşturma Tarihi",
            col: "col-md-1",
            onRender: (item: {
              eventTimeString: any;
              createDateString: any;
            }) => (
              <React.Fragment>
                <div>
                  {item.eventTimeString} {item.createDateString}
                </div>
              </React.Fragment>
            ),
          },

          {
            columnName: "denunciationId",
            text: "İşlem",
            col: "col-md-1",
            className: "operations",
            onRender: (item: {
              denunciationId: any;
              isReported: boolean;
              isReportedToTga: boolean;
            }) => (
              <React.Fragment>
                {!item.isReportedToTga && (
                  <Checkbox
                    checked={
                      this.state?.denunciationIdListReturn?.filter(
                        (x: any) => x === item.denunciationId
                      ).length > 0
                        ? true
                        : false
                    }
                    onChange={() =>
                      this.selectCheckboxreturn(item.denunciationId)
                    }
                    className="custom-checkbox"
                  />
                )}
              </React.Fragment>
            ),
          },

          {
            columnName: "denunciationId",
            text: "İşlem Yapılamadı Durumu ",
            col: "col-md-1",
            className: "operations",
            hidden: usertype === "2" || usertype === "4",
            onRender: (item: {
              denunciationId: any;
              isReported: boolean;
              certificateTypeName: any;
              isActionStatus: any;
              certificateTypeId: any;
              isReportedToTga: boolean;
              canCompanyUserChangeActionStatus: boolean;
            }) => (
              //firma
              <React.Fragment>
                {item.isActionStatus ? (
                  (item.certificateTypeId === 4 || item.certificateTypeId === 5) ? null : (
                    <button
                      className={item.isReportedToTga ? "disabled" : ""}
                      onClick={() =>
                        this.onSetActionStatus(false, item.denunciationId)
                      }
                      disabled={item.isReportedToTga}
                    >
                      {" "}
                      Geri Al
                    </button>
                  )
                ) : (item.certificateTypeId === 4 || item.certificateTypeId === 5) ? null : (
                  <button
                    className={item.isReportedToTga ? "disabled" : ""}
                    onClick={() =>
                      this.onSetActionStatus(true, item.denunciationId)
                    }
                    disabled={item.isReportedToTga}
                  >
                    İşlem Yapılamadı Kaydet
                  </button>
                )}
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.data?.unSent || []}
      />
    );
  }
  selectCheckbox(denunciationId: number) {
    let arr: number[];
    arr = this.state?.denunciationIdList;

    if (arr.includes(denunciationId)) {
      const index = arr.indexOf(denunciationId);
      if (index > -1) {
        arr.splice(index, 1);
      }
    } else {
      arr.push(denunciationId);
    }

    this.setState({ denunciationIdList: arr });
  }

  selectCheckboxreturn(denunciationId: number) {
    let arr: number[];
    arr = this.state?.denunciationIdListReturn;

    if (arr.includes(denunciationId)) {
      const index = arr.indexOf(denunciationId);
      if (index > -1) {
        arr.splice(index, 1);
      }
    } else {
      arr.push(denunciationId);
    }

    this.setState({ denunciationIdListReturn: arr });
  }
  selectCheckboxreturn2(denunciationId: number) {
    let arr: number[];
    arr = this.state?.denunciationIdList2;

    if (arr.includes(denunciationId)) {
      const index = arr.indexOf(denunciationId);
      if (index > -1) {
        arr.splice(index, 1);
      }
    } else {
      arr.push(denunciationId);
    }

    this.setState({ denunciationIdList2: arr });
  }

  selectCheckboxreturnM(denunciationId: number) {
    let arr: number[];
    arr = this.state?.denunciationIdListMReturn;

    if (arr.includes(denunciationId)) {
      const index = arr.indexOf(denunciationId);
      if (index > -1) {
        arr.splice(index, 1);
      }
    } else {
      arr.push(denunciationId);
    }

    this.setState({ denunciationIdListMReturn: arr });
  }

  render() {
    return (
      <div className="page push-all">
        {this.state.modelStatus3 && (
          <Modal className={"warning custom-message-box danger"} isOpen={true}>
            <div className="modal-header row">
              <div className="title">
                Firma Ataması İşlemi İçin Seçim Yapınız
              </div>
              <span
                onClick={() =>
                  this.setState({
                    modelStatus3: false,
                    modelStatus3number: 0,
                    modelStatus3err: false,
                  })
                }
                className="close"
              >
                <Icon iconName="ChromeClose" />
              </span>
            </div>
            <div
              style={{ paddingBottom: 0 }}
              className="description modal-content"
            >
              <div className="description modal-content">
                {!this.state.modelStatus3err &&
                  this.state.modelStatus3number === 0 && (
                    <>
                      <DefaultButton
                        style={{ marginTop: 15, marginLeft: 10 }}
                        text="Şikayet Sırasındaki Denetçi Firma’ya Gönder"
                        onClick={() => {
                          this.props.formData?.data?.firstCompanyUserTitle
                            ? this.setState({ modelStatus3number: 1 })
                            : this.setState({ modelStatus3err: true });
                        }}
                      />

                      <DefaultButton
                        style={{ marginTop: 15, marginLeft: 10 }}
                        text="Güncel Denetleyici Firmaya Gönder"
                        onClick={() => {
                          this.props.formData?.data?.companyUserTitle
                            ? this.setState({ modelStatus3number: 2 })
                            : this.setState({ modelStatus3err: true });
                        }}
                      />

                      <DefaultButton
                        style={{ marginTop: 15, marginLeft: 10 }}
                        text="Farklı Bir Denetçi Firmaya Gönder"
                        onClick={() => {
                          this.setState({ modelStatus3number: 3 });
                        }}
                      />
                    </>
                  )}

                {this.state.modelStatus3err && <div>Firma bulunmamaktadır</div>}
                {this.modal3()}
                {}
              </div>
            </div>
          </Modal>
        )}

        {this.state.modelStatus && this.props.formData.data.certificateTypeId === 5 && (
          <Modal className={"warning custom-message-box danger"} isOpen={true}>
            <div className="modal-header row">
              <div className="title">
          
                {this.state.modalType === 1 
                  ? "İncelemeniz sisteme kayıt edilecektir.İşlemi onaylıyor musunuz?"
                  : "KTB Belgeli olmayan bir tesisin/aracın şikayetini KTB Kullanıcısına aktarmak istiyor musunuz?"
                }
 

              </div>
              
              <span
                onClick={() => this.setState({ modelStatus: false })}
                className="close"
              >
                <Icon iconName="ChromeClose" />
              </span>
            </div>
            <div
              style={{ paddingBottom: 0 }}
              className="description modal-content"
            >
              <div className="modal-indicators">
                {this.state.modalType === 1 && (
                  <DefaultButton
                    style={{ marginTop: 15 }}
                    text="EVET"
                    onClick={() => {
                      this.onChange();
                      this.setState({ modelStatus: false });
                    }}
                  />
                )}
                {this.state.modalType === 2 && (
                  <DefaultButton
                    style={{ marginTop: 15 }}
                    text="EVET"
                    onClick={() => {
                      {
                        this.onSentKTB();
                        this.setState({ modelStatus: false });
                      }
                    }}
                  />
                )}

                <DefaultButton
                  style={{ marginTop: 15 }}
                  text="HAYIR"
                  onClick={() => {
                    this.setState({ modelStatus: false });
                  }}
                />
              </div>
            </div>
          </Modal>
        )}
        {this.state.modelStatus && this.props.formData.data.certificateTypeId === 4 && (
          <Modal className={"warning custom-message-box danger"} isOpen={true}>
            <div className="modal-header row">
              <div className="title">
          
                {this.state.modalType === 1 
                  ? "İncelemeniz sisteme kayıt edilecektir.İşlemi onaylıyor musunuz?"
                  : "Seçmiş olduğunuz ihbarlar bakanlık kullanıcısına iletilecektir onaylıyor musunuz?"
                }
               </div>
              
              <span
                onClick={() => this.setState({ modelStatus: false })}
                className="close"
              >
                <Icon iconName="ChromeClose" />
              </span>
            </div>
            <div
              style={{ paddingBottom: 0 }}
              className="description modal-content"
            >
              <div className="modal-indicators">
                {this.state.modalType === 1 && (
                  <DefaultButton
                    style={{ marginTop: 15 }}
                    text="EVET"
                    onClick={() => {
                      this.onChange();
                      this.setState({ modelStatus: false });
                    }}
                  />
                )}
                {this.state.modalType === 2 && (
                  <DefaultButton
                    style={{ marginTop: 15 }}
                    text="EVET"
                    onClick={() => {
                      {
                        this.onSentKTB();
                        this.setState({ modelStatus: false });
                      }
                    }}
                  />
                )}

                <DefaultButton
                  style={{ marginTop: 15 }}
                  text="HAYIR"
                  onClick={() => {
                    this.setState({ modelStatus: false });
                  }}
                />
              </div>
            </div>
          </Modal>
        )}
        {this.state.isActionStatus && (
          <Modal className={"warning custom-message-box danger"} isOpen={true}>
            <div className="modal-header row">
              <div className="title">
                {this.state.modalType === 1
                  ? "İncelemeniz sisteme işlem yapılamadı olarak kayıt edilecektir.İşlemi onaylıyor musunuz?"
                  : "İşlemi"}
              </div>
              <span
                onClick={() => this.setState({ isActionStatus: false })}
                className="close"
              >
                <Icon iconName="ChromeClose" />
              </span>
            </div>
            <div
              style={{ paddingBottom: 0 }}
              className="description modal-content"
            >
              <div className="modal-indicators">
                <DefaultButton
                  style={{ marginTop: 15 }}
                  text="EVET"
                  onClick={() => {
                    this.state.denunciationIdList.forEach((id) => {
                      this.onSetActionStatus(true, id);
                    });
                    this.setState({ isActionStatus: false });
                  }}
                />

                <DefaultButton
                  style={{ marginTop: 15 }}
                  text="HAYIR"
                  onClick={() => {
                    this.setState({ isActionStatus: false });
                  }}
                />
              </div>
            </div>
          </Modal>
        )}

        {this.state.modelStatus2 && (
          <Modal className={"warning custom-message-box danger"} isOpen={true}>
            <div className="modal-header row">
              <div className="title">DETAY</div>
              <span
                onClick={() => this.setState({ modelStatus2: false })}
                className="close"
              >
                <Icon iconName="ChromeClose" />
              </span>
            </div>
            <div
              style={{ paddingBottom: 0 }}
              className="description modal-content"
            >
              <div style={{textAlign:"left"}}className="modal-indicators">
                <table>
                  <tr>
                    <td>
                      <b>Ad</b>
                    </td>
                    <td><b>:</b> {this.props.formData?.selectitem?.informarName}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Soyad</b>
                    </td>
                    <td><b>:</b> {this.props.formData?.selectitem?.informarSurname}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Email</b>
                    </td>
                    <td>
                    <b>:</b> {this.props.formData?.selectitem?.informarEmailAddress}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Adres</b>
                    </td>
                    <td><b>:</b> {this.props.formData?.selectitem?.address}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>İl</b>
                    </td>
                    <td><b>:</b> {this.props.formData?.selectitem?.cityName}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>İlçe</b>
                    </td>
                    <td><b>:</b> {this.props.formData?.selectitem?.countyName}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Revize Açıklama</b>
                    </td>
                    <td>
                    <b>:</b> {this.props.formData?.selectitem?.correctionDescription}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <b>Oluşturma Tarihi</b>
                    </td>
                    <td><b>:</b> {this.props.formData?.selectitem?.createDateString}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Oluşturan</b>
                    </td>
                    <td><b>:</b> {this.props.formData?.selectitem?.denunciationChannel ===1 ? "HES" : "Sertifika Mobil"}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Olay Tarihi</b>
                    </td>
                    <td><b>:</b> {this.props.formData?.selectitem?.eventTimeString}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Şikayet Sonuç Durumu</b>
                    </td>
                    <td>
                    <b>:</b> {this.props.formData?.selectitem?.denunciationStatusString}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <b>Açıklama</b>
                    </td>
                    <td><b>:</b> {this.props.formData?.selectitem?.explanation}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>İçişleri Bakanlığına Gönderim</b>
                    </td>
                    <td><b>:</b> {this.props.formData?.selectitem?.isSendIsdem
                        ? "Gönderildi"
                        : "Gönderilmedi"}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Revize Durumu</b>
                    </td>
                    <td>
                    <b>:</b> {this.props.formData?.selectitem?.isCorrection
                        ? "Var"
                        : "Yok"}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <b>Firmaya Gönderim</b>
                    </td>
                    <td>
                    <b>:</b> {this.props.formData?.selectitem?.isReported
                        ? "Gönderildi"
                        : "Gönderilmedi"}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <b>KTB'ye Gönderim</b>
                    </td>
                    <td>
                    <b>:</b> {this.props.formData?.selectitem?.isReportedToMinistry
                        ? "Gönderildi"
                        : "Gönderilmedi"}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <b>Cevap Gelme Durumu</b>
                    </td>
                    <td>
                    <b>:</b> {this.props.formData?.selectitem?.isReportedToTga
                        ? "Geldi"
                        : "Gelmedi"}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <b>Isdem ID</b>
                    </td>
                    <td><b>:</b> {this.props.formData?.selectitem?.isdemID}</td>
                  </tr>

                  <tr>
                    <td>
                      <b> Koordinat</b>
                    </td>
                    <td>
                    <b>:</b> {this.props.formData?.selectitem?.latitude}-
                       {this.props.formData?.selectitem?.longitude}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Medya</b>
                    </td>
                    <td><b>:</b> {this.props.formData?.selectitem?.mediaUrl}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Tesis / Araç ID</b>
                    </td>
                    <td><b>:</b> {this.props.formData?.selectitem?.facilityId}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Tesis Adı</b>
                    </td>
                    <td><b>:</b> {this.props.formData?.selectitem?.facilityName}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Güncelleme Tarihi</b>
                    </td>
                    <td><b>:</b> {this.props.formData?.selectitem?.updateDateString}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Tesis Belge Tipi</b>
                    </td>
                    <td>
                    <b>:</b> {this.props.formData?.selectitem?.certificateTypeId
                        ? "TC Kültür ve Turizm Bakanlığı"
                        : "Diğer"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Şikayet Anındaki Denetleyici Firma</b>
                    </td>
                    <td>
                    <b>:</b> {this.props.formData?.selectitem?.firstCompanyUserTitle}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <b>Güncel Denetleyici Firma</b>
                    </td>
                    <td><b>:</b> {this.props.formData?.selectitem?.companyUserTitle}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Atama Yapılan Denetleyici Firma</b>
                    </td>
                    <td>
                    <b>:</b> {
                        this.props.formData?.selectitem
                          ?.assignedCompanyUserTitle
                      }
                    </td>
                  </tr>
                </table>

                <DefaultButton
                  style={{ marginTop: 15 }}
                  text="Tamam"
                  onClick={() => {
                    this.setState({ modelStatus2: false });
                  }}
                />
              </div>
            </div>
          </Modal>
        )}
        {this.state.modelStatus4 && (
          <Modal  className={"warning custom-message-box danger"} isOpen={true}>
            <div className="modal-header row">
              <div className="title">ŞİKAYET AÇIKLAMA</div>
              <span
                onClick={() => this.setState({ modelStatus4: false })}
                className="close"
              >
                <Icon iconName="ChromeClose" />
              </span>
            </div>
            <div
              style={{ paddingBottom: 0 }}
              className="description modal-content"
            >
              <div style={{textAlign:"left"}}className="modal-indicators">
                <table>
                  <tr>
                    <td>
                      <b></b>
                    </td>
                    <td>
                    {this.props.formData?.selectitem?.denunciationIssue} 
                    </td>
                  </tr>
                </table>

                <DefaultButton
                  style={{ marginTop: 15 }}
                  text="Tamam"
                  onClick={() => {
                    this.setState({ modelStatus4: false });
                  }}
                />
              </div>
            </div>
          </Modal>
        )}

        {this.props.formData.hotelModalShow && (
          <div className="modal-overlay">
            <div className="modal">
              <button
                className="btn-close"
                onClick={(x) => this.props.setFormData("hotelModalShow", false)}
              >
                Kapat
              </button>
              <HotelModal history={this.props.history} />
            </div>
          </div>
        )}

        <PageTitle
          title="İncele"
          titleRenderer={() => (
            <span className="edit-title">
              İncele:
              <strong>{this.props.formData?.title || ""}</strong>
            </span>
          )}
          showBack
          backUrl="/Denunciation"
          history={this.props.history}
          subTitle="Değiştirmek istediğiniz alanları aşağıda bulabilirsiniz."
          rightType="edit"
          right={
            <FormButtons
              isAbsolute
              customRenderButtons={() => (
                <React.Fragment>
                  {usertype && (usertype === "2" || usertype === "4") && (
                    <PrimaryButton
                      onClick={() =>
                        this.setState({ modalType: 1, modelStatus: true })
                      }
                      disabled={this.state.denunciationIdListReturn.length <= 0}
                      styles={{
                        rootDisabled: {
                          background: "#626262",
                          borderColor: "#777373 !important",
                        },
                      }}
                      iconProps={{ iconName: "CheckMark" }}
                      text={"Sonucu Gönder"}
                    />
                  )}

                  {usertype === "2" &&
                    this.state.denunciationIdListReturn.length <= 0 && (
                      <div
                        className="tooltip-warning"
                        style={{ top: "45px", right: "20px", width: "130px" }}
                      >
                        Butonun aktif olması için şikayet seçmelisiniz.
                      </div>
                    )}

                  {this.state.denunciationIdListMReturn.length > 0 &&
                    usertype &&
                    usertype !== "2" &&
                    usertype !== "5" &&
                    usertype !== "4" && (
                      <PrimaryButton
                        onClick={() => this.onSentM()}
                        styles={{
                          rootDisabled: {
                            background: "#626262",
                            borderColor: "#777373 !important",
                          },
                        }}
                        iconProps={{ iconName: "CheckMark" }}
                        text={"Şikayeti Kapat"}
                      />
                    )}
                  {this.state.denunciationIdListReturn.length > 0 &&
                    usertype &&
                    usertype !== "2" &&
                    usertype !== "5" &&
                    usertype !== "4" && (
                      <PrimaryButton
                        onClick={() => this.onSentC()}
                        styles={{
                          rootDisabled: {
                            background: "#626262",
                            borderColor: "#777373 !important",
                          },
                        }}
                        iconProps={{ iconName: "CheckMark" }}
                        text={"Cevabı Düzenle"}
                      />
                    )}

                  {this.props.formData &&
                    this.props.formData.data &&
                    this.props.formData.data.unSent.length > 0 &&
                    this.props.formData.data.certificateTypeName !== "5" &&
                    usertype &&
                    usertype !== "2" &&
                    usertype !== "5" &&
                    usertype !== "4" &&
                    this.state.denunciationIdList.length > 0 && (
                      <PrimaryButton
                        onClick={() => this.setState({ modelStatus3: true })}
                        styles={{
                          rootDisabled: {
                            background: "#626262",
                            borderColor: "#777373 !important",
                          },
                        }}
                        iconProps={{ iconName: "CheckMark" }}
                        text={"Firmaya İlet"}
                      />
                    )}

                  {this.props.formData &&
                    this.props.formData.data &&
                    this.props.formData.data.unSent.length > 0 &&
                    this.props.formData.data.certificateTypeName !== "5" &&
                    usertype &&
                    usertype !== "2" &&
                    usertype !== "5" &&
                    usertype !== "4" &&
                    this.state.denunciationIdList.length > 0 && (
                      <PrimaryButton
                        onClick={() =>
                          this.setState({ modalType: 2, modelStatus: true })
                        }
                        styles={{
                          root: {
                            background: "red",
                            borderColor: "red",
                          },
                          rootHovered: {
                            background: "red",
                            borderColor: "red",
                          },
                          rootDisabled: {
                            background: "#fff",
                            borderColor: "#777373 !important",
                          },
                        }}
                        iconProps={{ iconName: "CheckMark" }}
                        text={"KTB'ye İlet"}
                      />
                    )}

                  {this.renderStatusByType()}
                </React.Fragment>
              )}
            />
          }
        />
        {this.renderMessageBar()}
        {this.renderForm()}
        <Label>
          {CATEGORY_TYPE_ENUM.find(
            (x) => x.key.toString() === this.props.FacilityType.toString()
          )
            ?.text.replace("Tesisi", "")
            .replace("Tesis", "")}{" "}
          Tesisine Ait Gönderilmemiş Tüm Şikayet Kayıtları
        </Label>
          <div className="yellow" ></div> <div className="yellowDesc"> İşlem Yapılmamış Şikayetler</div>          
        {usertype && usertype !== "2" && usertype !== "4"
          ? this.renderListUnSentTGA()
          : this.renderListUnSentFirma()}
        <Label>
          {" "}
          {CATEGORY_TYPE_ENUM.find(
            (x) => x.key.toString() === this.props.FacilityType.toString()
          )
            ?.text.replace("Tesisi", "")
            .replace("Tesis", "")}{" "}
          Tesisine Ait Gönderilmiş Tüm Şikayet Kayıtları
        </Label>
        {this.renderListSent()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDenunciation);
