import { HttpClient } from "src/library/HttpClient";
import {
  SeaTourismFacilityTypeChangeStatusRequestModel,
  SeaTourismFacilityTypeGetByIdRequestModel,
  SeaTourismFacilityTypeUpdateRequestModel,
  SeaTourismFacilityTypeSaveRequestModel,
  SeaTourismFacilityTypeGetAllRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class SeaTourismFacilityType {
  async getAll(
    request: SeaTourismFacilityTypeGetAllRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismFacilityType + "GetAll",
      { ...request, token }
    );
  }

  async save(request: SeaTourismFacilityTypeSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismFacilityType + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(
    request: SeaTourismFacilityTypeUpdateRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismFacilityType + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(
    request: SeaTourismFacilityTypeGetByIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismFacilityType + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(
    request: SeaTourismFacilityTypeChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismFacilityType + "ChangeStatus",
      { ...request, token }
    );
  }
}
