import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import WinterTourism from "src/services/WinterTourism/WinterTourismService";
import {
  WinterTourismChangeStatusRequestModel,
  GetWinterTourismListModel,
  WinterTourismGetByIdRequestModel,
  WinterTourismUpdateRequestModel,
  WinterTourismSaveRequestModel,
  GetWinterTourismCertificateListByWinterTourismModel,
} from "src/services/WinterTourism/RequestModel";
import { MessageType } from "./system/types";
import { errorMessageSaveOrUpdate } from "src/helpers/functions";
import { formatDate } from "src/helpers/converts";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllMA(
  request: GetWinterTourismListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const WinterTourismService = new WinterTourism();
    const result = await WinterTourismService.getAllWinterTourism(request);
    dispatch(setFormData("WinterTourismList", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}
export function getAllForPager(
  request: GetWinterTourismListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const WinterTourismService = new WinterTourism();
    const result = await WinterTourismService.getAllWinterTourism(request);
    dispatch(setFormData("dataList", result.dataList));
    dispatch(setFormData("pageCount", result.pageCount));
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAllWinterTourism(
  request: GetWinterTourismListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismService = new WinterTourism();
    const result = await WinterTourismService.getAllWinterTourism(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAllWinterTourismForModal(
  request: GetWinterTourismListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismService = new WinterTourism();
    const result = await WinterTourismService.getAllWinterTourism(request);
    dispatch(setFormData("WinterTourismListForModal", result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}
export function getAllWinterTourismForExcel(
  request: GetWinterTourismListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismService = new WinterTourism();
    const excelName = "KışTurizmTesisi" + formatDate();
    await WinterTourismService.excelDownload(request, excelName);
    dispatch(toggleLoader());

    return false;
  };
}

export function getAllSertificationMA(
  request: GetWinterTourismCertificateListByWinterTourismModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const HotelService = new WinterTourism();
    const result =
      await HotelService.GetWinterTourismCertificateListByWinterTourism(
        request
      );
    dispatch(setFormData("WinterTourismSerticationList", result));
    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: WinterTourismSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismService = new WinterTourism();
    const result = await WinterTourismService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          ""
        )
      );
      return result;
    } else if (result.errorCode === "48") {
      dispatch(
        showMessage(
          "Onaylanmış Kış Turizmi Mekanik Hatlar Tesisi Bilgilerini Güncelleyemezsiniz.",
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          ""
        )
      );
      return false;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}
export function update(
  request: WinterTourismUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismService = new WinterTourism();
    const result = await WinterTourismService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          ""
        )
      );
      return true;
    } else if (result.errorCode === "48") {
      dispatch(
        showMessage(
          "Onaylanmış Kış Turizmi Mekanik Hatlar Tesisi Bilgilerini Güncelleyemezsiniz.",
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          ""
        )
      );
      return false;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}

export function getById(
  request: WinterTourismGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismService = new WinterTourism();
    const result = await WinterTourismService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getByIdForWinterForCert(
  request: WinterTourismGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismService = new WinterTourism();
    const result = await WinterTourismService.getById(request);
    dispatch(setFormData("findWinterByQSId", result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: WinterTourismChangeStatusRequestModel,
  isDelete: boolean = false
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismService = new WinterTourism();
    const result = await WinterTourismService.changeStatus(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(initialFormData(result));
      dispatch(
        showMessage(
          isDelete
            ? "Silme işlemi başarıyla değiştirildi."
            : "Kayıt durumu işlemi başarıyla değiştirildi.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          "",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          ""
        )
      );
      return false;
    }
  };
}
