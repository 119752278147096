import { combineReducers } from "redux";
import { systemReducer } from "./system/reducer";
import { formReducer } from "./form/reducer";
import { modalReducer } from "./modal/reducer";

export const rootReducer = combineReducers({
  system: systemReducer,
  form: formReducer,
  modal: modalReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
