import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import { History } from "history";
import {
  GetCompanyUserStatistic,
  statisticDownload,
} from "src/store/thunkStatistic";
import { toggleConfirmDialog } from "src/store/system/actions";
import { formatDate, formatDate3 } from "src/helpers/converts";
import { castThunkAction } from "src/helpers/casting";
import { GetDetailedSearchCount } from "src/store/thunkStatistic";
// import { PrimaryButton } from "office-ui-fabric-react";
import cookie from "react-cookies";
import PageTitle from "../PageTitle";
// @ts-ignore
import ReactExport from "react-export-excel";
import { PrimaryButton } from "office-ui-fabric-react";
// @ts-ignore
// import ReactToPdf from "react-to-pdf";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let usertype: any = 0;

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        toggleConfirmDialog,
        GetCompanyUserStatistic,
        GetDetailedSearchCount,
        statisticDownload,
      },
      dispatch
    ),
  };
}

type StatisticProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  pagerTotal: number;
  excel: boolean;
  download: boolean;
}

const tref: any = React.createRef();
// const options = {
//   orientation: "landscape",
//   unit: "px",
//   format: [1920, 500],
// };

class List extends Component<StatisticProps, OwnState> {
  state = {
    filterData: {
      searchKey: "",
      isRead: null,
      orderField: "ProcessDate",
      isDescending: true,
    },
    pagerCurrent: 0,
    excel: false,
    download: false,
    pagerTotal: 0,
  };

  UNSAFE_componentWillMount() {
    usertype = cookie.load("systemUserInfo");
    if (usertype === "2") {
      window.location.href = "/";
    }
    const request3: any = {};
    this.props.GetDetailedSearchCount(request3);
  }
  UNSAFE_componentWillUpdate(nextProps: any, nextState: any) {
    if (nextState.pagerTotal !== nextProps.formData.pageCount)
      this.setState({
        ...this.state,
        pagerTotal: this.props.formData.pageCount,
      });
  }

  rederexcel() {
    const excelName = "KonaklamaTesisleri_" + formatDate();
    return this.state.excel ? (
      <ExcelFile
        filename={excelName}
        element={
          <a>
            <label>Denetleyici Firma Raporu İndir</label>
            <label>Denetleyici Firma Bazlı İstatistik Raporu</label>

            {this.state.download && (
              <label style={{ marginLeft: "10px", marginTop: "27px" }}>
                Rapor Oluşturuluyor...
              </label>
            )}
          </a>
        }
      >
        <ExcelSheet
          data={this.props.formData?.GetCompanyUserStatistic?.dataList}
          name="Denetleyici Firma"
        >
          <ExcelColumn label="Firma İsmi" value="companyUserTitle" />
          <ExcelColumn label="Konaklama" value="hotelCount" />
          <ExcelColumn label="Restorant/Kafe" value="restaurantCount" />
          <ExcelColumn label="Taşıt" value="transportCount" />
          <ExcelColumn label="Genel Toplam" value="totalCount" />

          <ExcelColumn
            label="Toplam Konaklama Tesisi"
            value="hotelCountPercentage"
          />
          <ExcelColumn label="Genel Toplam %" value="totalCountPercentage" />
        </ExcelSheet>
      </ExcelFile>
    ) : (
      <>
        <a onClick={() => this.downloadexcel()}>
          <label>Denetleyici Firma Raporu Oluştur</label>
          <label>Denetleyici Firma Bazlı İstatistik Raporu</label>

          {this.state.download && (
            <label style={{ marginLeft: "10px", marginTop: "27px" }}>
              Rapor Oluşturuluyor...
            </label>
          )}
        </a>
      </>
    );
  }

  downloadexcel() {
    this.setState({ download: true });
    const requestexcel: any = {};

    castThunkAction(this.props.GetCompanyUserStatistic(requestexcel)).then(
      (response: any) => {
        const list =
          this.props.formData.HotelCertificationListForExcel?.dataList;
        this.props.formData.RestaurantListForExcel?.dataList.length > 0 &&
          list.push(this.props.formData.RestaurantListForExcel?.dataList);
        this.setState({ excel: true, download: false });
      }
    );
  }

  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="İstatistikler ve Raporlar"
          subTitle="Almak istediğiniz rapor veya istatistik çeşidini aşağıdan seçiniz"
          backUrl="/"
          showBack
          history={this.props.history}
        />

        {/* <ReactToPdf
          targetRef={tref}
          filename={`${"Istatistik - " + Date.now() + ".pdf"}`}
          options={options}
          x={0.5}
          y={0.5}
        >
          {({ toPdf, tref }: any) => (
            <div className="pdfBtn">
              <div style={{ fontWeight: "bold" }}>
                Rapor çekildiği tarih: {formatDate3(new Date())}
              </div>
              <PrimaryButton
                ref={tref}
                style={{ marginBottom: 10, float: "right" }}
                onClick={toPdf}
              >
                PDF'e Aktar
              </PrimaryButton>
            </div>
          )}
        </ReactToPdf>
        */}
        <div className="pdfBtn">
          <div style={{ fontWeight: "bold" }}>
            Rapor Tarihi: {formatDate3(new Date())}
          </div>
          <PrimaryButton
            ref={tref}
            style={{ marginBottom: 10, float: "right" }}
            onClick={() => {
              this.props.statisticDownload();
            }}
          >
            PDF'e Aktar
          </PrimaryButton>
        </div>
        <div ref={tref} className="reportSummary" style={{ marginTop: 10 }}>
          <div className="text">
            Toplam Sertifika Almış Tesis ve Araç Sayısı :{" "}
            <b>
              {(
                this.props.formData?.GetDetailedSearchCount?.allApprovalHotels +
                this.props.formData?.GetDetailedSearchCount
                  ?.allApprovalRestaurant +
                this.props.formData?.GetDetailedSearchCount
                  ?.allApprovalTransport +
                this.props.formData?.GetDetailedSearchCount
                  ?.allApprovalWinterTourism +
                this.props.formData?.GetDetailedSearchCount
                  ?.allApprovalCongress +
                this.props.formData?.GetDetailedSearchCount
                  ?.allApprovalSeaTourism +
                this.props.formData?.GetDetailedSearchCount
                  ?.allApprovalThemedFacility
              ).toLocaleString()}
            </b>
            <br /> Aktif Sertifikalı Tesis ve Araç Sayısı :{" "}
            <b>
              {(
                this.props.formData?.GetDetailedSearchCount?.hotelNonQr +
                this.props.formData?.GetDetailedSearchCount
                  ?.publishedAllRestaurant +
                this.props.formData?.GetDetailedSearchCount
                  ?.publishedAllTransport +
                this.props.formData?.GetDetailedSearchCount
                  ?.publishedAllWinterTourism +
                this.props.formData?.GetDetailedSearchCount
                  ?.publishedAllCongress +
                this.props.formData?.GetDetailedSearchCount
                  ?.publishedAllSeaTourism +
                this.props.formData?.GetDetailedSearchCount
                  ?.publishedAllThemedFacility
              ).toLocaleString()}
            </b>
          </div>
          <div className="reportGreen">
            <div className="row">
              <div className="item">
                <i className="fas fa-bed"></i>
                <br /> <b>Konaklama Tesisi</b>
                <br /> Toplam
                <br />{" "}
                <b>
                  {this.props.formData?.GetDetailedSearchCount?.allApprovalHotels.toLocaleString()}
                </b>
                <br /> Aktif
                <br />{" "}
                <b>
                  {this.props.formData?.GetDetailedSearchCount?.hotelNonQr.toLocaleString()}
                </b>
              </div>
              <div className="item">
                <i className="fas fa-utensils"></i>
                <br /> <b>Restoran / Kafe</b>
                <br /> Toplam
                <br />{" "}
                <b>
                  {this.props.formData?.GetDetailedSearchCount?.allApprovalRestaurant.toLocaleString()}
                </b>
                <br /> Aktif
                <br />{" "}
                <b>
                  {this.props.formData?.GetDetailedSearchCount?.publishedAllRestaurant.toLocaleString()}
                </b>
              </div>
              <div className="item">
                <i className="fas fa-car"></i>
                <br /> <b>Taşıt</b>
                <br /> Toplam
                <br />{" "}
                <b>
                  {this.props.formData?.GetDetailedSearchCount?.allApprovalTransport.toLocaleString()}
                </b>
                <br /> Aktif
                <br />{" "}
                <b>
                  {this.props.formData?.GetDetailedSearchCount?.publishedAllTransport.toLocaleString()}
                </b>
              </div>
              <div className="item">
                <i className="fas fa-tram"></i>
                <br /> <b>Mekanik Hatlar</b>
                <br /> Toplam
                <br />{" "}
                <b>
                  {this.props.formData?.GetDetailedSearchCount?.allApprovalWinterTourism.toLocaleString()}
                </b>
                <br /> Aktif
                <br />{" "}
                <b>
                  {this.props.formData?.GetDetailedSearchCount?.publishedAllWinterTourism.toLocaleString()}
                </b>
              </div>
              <div className="item">
                <i className="fas fa-theater-masks"></i>
                <br /> <b>Kültür & Kongre</b>
                <br /> Toplam
                <br />{" "}
                <b>
                  {this.props.formData?.GetDetailedSearchCount?.allApprovalCongress.toLocaleString()}
                </b>
                <br /> Aktif
                <br />{" "}
                <b>
                  {this.props.formData?.GetDetailedSearchCount?.publishedAllCongress.toLocaleString()}
                </b>
              </div>
              <div className="item">
                <i className="fas fa-ship"></i>
                <br /> <b>Deniz Turizmi</b>
                <br /> Toplam
                <br />{" "}
                <b>
                  {this.props.formData?.GetDetailedSearchCount?.allApprovalSeaTourism.toLocaleString()}
                </b>
                <br /> Aktif
                <br />{" "}
                <b>
                  {this.props.formData?.GetDetailedSearchCount?.publishedAllSeaTourism.toLocaleString()}
                </b>
              </div>
              <div className="item">
                <i className="fab fa-fort-awesome"></i>
                <br /> <b>Temalı Parklar</b>
                <br /> Toplam
                <br />{" "}
                <b>
                  {this.props.formData?.GetDetailedSearchCount?.allApprovalThemedFacility.toLocaleString()}
                </b>
                <br /> Aktif
                <br />{" "}
                <b>
                  {this.props.formData?.GetDetailedSearchCount?.publishedAllThemedFacility.toLocaleString()}
                </b>
              </div>
              <div className="ok">
                <img src="/assets/images/ok.png" alt="OK" />
              </div>
            </div>
            <div className="row">
              <div className="reportWhite">
                <div className="item">
                  Aktif Sertifikalı Bakanlık Belgeli Tesis Sayısı:{" "}
                  <b>
                    {this.props.formData?.GetDetailedSearchCount?.ministryHotelCurrentCertificate.toLocaleString()}
                  </b>
                  <br /> Aktif Sertifikalı Belediye Belgeli Tesis Sayısı:{" "}
                  <b>
                    {this.props.formData?.GetDetailedSearchCount?.otherHotelCurrentCertificate.toLocaleString()}
                  </b>
                </div>
                <div className="item">
                  Aktif Sertifikalı Açık Tesis Sayısı:{" "}
                  <b>
                    {this.props.formData?.GetDetailedSearchCount?.openedHotelCertificate.toLocaleString()}
                  </b>
                  <br /> Aktif Sertifikalı Kapalı Tesis Sayısı:{" "}
                  <b>
                    {this.props.formData?.GetDetailedSearchCount?.closedHotelCertificate.toLocaleString()}
                  </b>
                </div>
                <div className="item">
                  Uygun Görülmedi:{" "}
                  <b>
                    {this.props.formData?.GetDetailedSearchCount?.notSuitableHotelCertificate.toLocaleString()}
                  </b>
                  <br /> Süreçten Ayrıldı:{" "}
                  <b>
                    {this.props.formData?.GetDetailedSearchCount?.leavedHotelCertificate.toLocaleString()}
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="chooseReportType">
          {usertype !== "3" && (
            <>
              {/* <a href="/Statistic/ProvinceBasedReport">
                <label>İl bazlı otel sayısı raporu</label>
                <label>
                  İl bazlı olarak otellerin seçtiğiniz alanlara göre sayılarına
                  ulaşabileceğiniz rapor
                </label>
              </a> */}
              <a href="/Statistic/HospitalBasedReport">
                <label>Sağlık Kuruluşu Bazlı Otel Raporu</label>
                <label>
                  Sağlık Kuruluşu bazlı olarak otellerin seçtiğiniz alanlara
                  göre raporu
                </label>
              </a>
              <a href="/Statistic/GeneralHotelReport">
                <label>Genel Otel Raporu</label>
                <label>
                  Filtreler ile genel otel sertifika raporuna buradan
                  ulaşabilirsiniz.
                </label>
              </a>
              <a href="/Statistic/Chart">
                <label>Grafik</label>
                <label>Grafik işlemlerinizi buradan yapabilirsiniz.</label>
              </a>
            </>
          )}

          <a href="/Statistic/InstantReport">
            <label>Anlık Raporlar</label>
            <label>Bütün modüllerin anlık raporlarını alabilirsiniz.</label>
          </a>
          <a href="/Statistic/FacilityCompanyList">
            <label>Denetim Firması Değişiklikleri</label>
          </a>

          {usertype !== "3" && this.rederexcel()}

          {usertype === "1" && (
            <a href="/Statistic/UserActionReport">
              <label>Kullanıcı İşlem Sayısı Raporu</label>
              <label>
                Filtreler ile kullanıcı işlem raporuna buradan ulaşabilirsiniz.
              </label>
            </a>
          )}

          {usertype === "1" && (
            <a href="/AdditionalAudit">
              <label>Habersiz Denetim Raporu</label>
              <label>
                Filtreler ile Konaklama tesisleri için Habersiz Denetim raporuna buradan erişebilirsiniz
            </label>
            </a>

          )}

          {usertype === "1" && (
            <a href="/CertificationActionReport">
              <label>Sertifika Anlık Raporu</label>
              <label>Bütün modüllerin anlık raporlarını alabilirsiniz.</label>
            </a>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
