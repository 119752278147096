import * as React from "react";
import { Link } from "react-router-dom";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { RootState } from "src/store/reducer";
import { History } from "history";
import { SystemUserSystemUserLoginDataModel } from "../services/SystemUser/ResponseModel";
import { Button } from "office-ui-fabric-react";
import cookie from "react-cookies";
import { setUserInfo, tokenControl } from "src/store/system/actions";

import Notification from "./Notification";
import { baseUrl } from "src/config/apiConfig";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    userInfo: state.system.userInfo as SystemUserSystemUserLoginDataModel,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setUserInfo,
      tokenControl,
    },
    dispatch
  );
}

// const kullanıcısil = new Promise(function (resolve) {
// 	cookie.remove("userId")
// 	cookie.remove("systemUserInfo")
// 	cookie.remove("systemUserName")
// 	resolve(true);

// })
export interface HeaderState {
  showProfile: boolean;
  userMenuDisabled: boolean;
}

type HeaderProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

let usertype: any = 0;
class Header extends React.Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      showProfile: false,
      userMenuDisabled: false,
    };
  }

  UNSAFE_componentWillMount() {
    usertype = cookie.load("systemUserInfo");
  }
  render() {
    const testUrl = window.location.hostname;
    const name = this.props.userInfo ? this.props.userInfo.title || "" : "";
    return (
      <header className="row no-margin">
        <figure className="logo">
          <Link to="/" className="link">
            <strong>TGA Sertifika</strong>
            {/* <i className="icon-studio-logo" /> */}
          </Link>
        </figure>
        {(testUrl.includes('glomil.com') || testUrl.includes('localhost')) && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
          <strong style={{ color: '#ddd',marginLeft:'20px' }}>
            Api URL: {baseUrl}
          </strong>

        </div>}
        <div className="header-tools m-left-auto row">
          {usertype !== "2" && usertype !== "4" && (
            <Notification history={this.props.history} />
          )}

          <div
            onClick={(x) =>
              this.setState({
                ...this.state,
                userMenuDisabled: !this.state.userMenuDisabled,
              })
            }
            className="item header-profile"
          >
            <div className="info">
              {/* <div className="email">onur@formapps.io</div> */}
              <div className="name">{this.props.userInfo?.title}</div>
            </div>
            <span className="profile-icon">
              {(name.toString().includes(" ")
                ? (name as string).split(" ")[0].substr(0, 1)
                : name.toString().substr(0, 2)) +
                "" +
                (name.toString().includes(" ")
                  ? (name as string).split(" ")[1].substr(0, 1)
                  : "")}
            </span>
            <div
              className="usermenu"
              style={{
                display: this.state.userMenuDisabled ? "block" : "none",
              }}
            >
              <Button onClick={(x) => this.props.history?.push("/profile")}>
                Profil Düzenle
              </Button>
              <Button onClick={async (x) => await this.logOut()}>
                Çıkış Yap
              </Button>
            </div>
          </div>
        </div>
      </header>
    );
  }

  async logOut() {
    await cookie.remove("userId", { path: "/" });
    await cookie.remove("systemUserInfo", { path: "/" });
    await cookie.remove("systemUserName", { path: "/" });

    this.props.tokenControl(false);

    window.location.href = "/";

    // setTimeout(() => {
    // 	window.location.reload(false)
    // }, 500);

    // kullanıcısil.then(() => {
    // 	this.props.setUserInfo({})
    // 	setTimeout(() => {
    // 		window.location.reload(false)
    // 	}, 500);
    // }

    // )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
