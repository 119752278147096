import { Component } from "react";

export interface TableColumnProps {
  columnName: string;
  text?: string;
  className?: string;
  col?: string;
  hidden?: boolean;
  onRender?: (item: any) => {};
}

export interface OwnProps {
  className?: string;
  columns: TableColumnProps[];
  items: any[];
  currentVersion?: boolean;
  notFoundText?: string;
  isHotelHistoryModal?: boolean;
}

export default class BasicTable extends Component<OwnProps> {
  findSuggestItem(columnKey: string) {
    return (this.props.items || []).find((item) => item[columnKey]);
  }

  renderColumns() {
    return (this.props.columns || []).map((item, index) =>
      !item.hidden ? (
        <div
          key={index}
          className={item.col ? `list-th ${item.col}` : "col-md-2"}
        >
          {item.text}
        </div>
      ) : null
    );
  }

  renderItems() {
    if (!this.props.items || this.props.items.length <= 0)
      return (
        <div className="table-not-found row">
          {this.props.notFoundText || "Sonuç bulunamadı."}
        </div>
      );
    return (this.props.items || []).map((item, index) =>
      this.props.currentVersion &&
      item.currentVersionStatus !== null &&
      item.currentVersionStatus === true ? (
        <div
          style={{ backgroundColor: "#44da79" }}
          key={index}
          className="item row"
        >
          {(this.props.columns || []).map((column, columnIndex) =>
            !column.hidden ? (
              <div
                key={columnIndex}
                className={`list-td row ${column.col || "col-md-2"} ${
                  column.className || ""
                }`}
              >
                <span className="th-mobile">
                {this.props.columns[columnIndex].text}
             </span>
                {column.onRender
                  ? column.onRender(item)
                  : item[column.columnName]}
              </div>
            ) : null
          )}
        </div>
      ) : item.isApproved !== null &&
        item.isApproved === false &&
        !this.props.isHotelHistoryModal ? (
        <div
          style={{ backgroundColor: "#fff700" }}
          key={index}
          className="item row"
        >
          {(this.props.columns || []).map((column, columnIndex) =>
            !column.hidden ? (
              <div
                key={columnIndex}
                className={`list-td row ${column.col || "col-md-2"} ${
                  column.className || ""
                }`}
              >
                <span className="th-mobile">
                {this.props.columns[columnIndex].text}
             </span>
                {column.onRender
                  ? column.onRender(item)
                  : item[column.columnName]}
              </div>
            ) : null
          )}
        </div>
      ) : item.isReported !== null &&
        item.isReportedToMinistry !== null &&
        item.isReportedToTga !== null &&
        item.isCorrection !== null &&
        item.isReported === false &&
        item.isReportedToMinistry === false &&
        item.isReportedToTga === false &&
        item.isCorrection === false &&
        !this.props.isHotelHistoryModal ? (
        <div
          style={{ backgroundColor: "#fff700" }}
          key={index}
          className="item row"
        >
          {(this.props.columns || []).map((column, columnIndex) =>
            !column.hidden ? (
              <div
                key={columnIndex}
                className={`list-td row ${column.col || "col-md-2"} ${
                  column.className || ""
                }`}
              >
                <span className="th-mobile">
                {this.props.columns[columnIndex].text}
             </span>
                {column.onRender
                  ? column.onRender(item)
                  : item[column.columnName]}
              </div>
            ) : null
          )}
        </div>
      ) : (
        <div key={index} className="item row">
          {(this.props.columns || []).map((column, columnIndex) =>
            !column.hidden ? (
              <div
                key={columnIndex}
                className={`list-td row ${column.col || "col-md-2"} ${
                  column.className || ""
                }`}
              >
                <span className="th-mobile">
                {this.props.columns[columnIndex].text}
             </span>
                {column.onRender
                  ? column.onRender(item)
                  : item[column.columnName]}
              </div>
            ) : null
          )}
        </div>
      )
    );
  }

  render() {
    const { className } = this.props;
    return (
      <div className={className ? `table-list ${className}` : "table-list"}>
        <div className="table-head row">{this.renderColumns()}</div>
        <div className="table-body">{this.renderItems()}</div>
      </div>
    );
  }
}
