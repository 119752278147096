/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { RootState } from "../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "./PageTitle";
import { panels } from "src/utils/constants";
import { Link } from "react-router-dom";
import { History } from "history";
import BasicTable from "./BasicTable";
import { TextField, Dropdown, PrimaryButton } from "office-ui-fabric-react";
import {
  getAllThemedFacilityForModal,
  changeStatus,
} from "src/store/thunkThemedFacility";
import {
  GetThemedFacilityListModel,
  ThemedFacilityChangeStatusRequestModel,
} from "src/services/ThemedFacility/RequestModel";
import { setFormData } from "src/store/form/actions";

import { StatusEnums } from "src/services/BaseEnum";
import { castThunkAction } from "src/helpers/casting";
import {
  toggleConfirmDialog,
  toggleConfirmFilterRemoveDialog,
} from "src/store/system/actions";
import { listCount } from "src/config/otherConfig";
import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import { getAllCR } from "src/store/thunkCertificateStatusType";
import { showMessage } from "src/store/system/actions";

import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";

import cookie from "react-cookies";
// import { MessageType } from "src/store/system/types";
import {
  cityReturnOptions,
  filterModal,
  isNumberValue,
} from "src/helpers/functions";

interface OwnProps {
  history: History;
  onBack?: () => void
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        getAllThemedFacilityForModal,
        changeStatus,
        toggleConfirmDialog,
        toggleConfirmFilterRemoveDialog,
        getAllM,
        getAllC,
        getAllCR,
        setFormData,
        showMessage,
      },
      dispatch
    ),
  };
}

type ThemedFacilityProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  pagerTotal: number;
  selectedType: number;
}

const intitialState = {
  filterData: {
    searchKey: "",
    districtId: 0,
    status: 1,
    cityId: 0,
    themedFacilitySubCategoryId: 0,
    companyUserId: 0,
    correctionType: null,
    orderField: "UpdatedSystemUserDatetime",
    isDescending: true,
    themedFacilityId: 0,
    documentNo: null,
  },
  pagerCurrent: 0,
  pagerTotal: 0,
  selectedType: 0,
};

class ThemedFacility extends Component<ThemedFacilityProps, OwnState> {
  state: any = intitialState;

  componentDidMount() {
    filterModal(this, "themedFacilityModalListState").finally(
      () =>
        this.state.selectedType !== 0 && this.getData(this.state.pagerCurrent)
    );
  }

  getData(
    pagerSelected: number,
    certificateTypeId: number = this.state.selectedType
  ) {
    const request: GetThemedFacilityListModel = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...this.state.filterData,
      certificateTypeId,
      isForAdditionalAudit: this.props.formData.isForAdditionalAudit,
    };

    request["isPublishQr"] =
      request["isPublishQr"] === "1"
        ? true
        : request["isPublishQr"] === "2"
          ? false
          : null;

    castThunkAction(this.props.getAllThemedFacilityForModal(request)).then(
      (response: any) => {
        // if (
        //   this.props.formData?.ThemedFacilityListForModal?.dataList?.length ===
        //   0
        // ) {
        //   this.props.showMessage(
        //     "Firmanıza ait tesis bulunamadı.",
        //     "Bir Sorun Oluştu!",
        //     MessageType.ERROR,
        //     ""
        //   );
        //   this.setState({
        //     selectedType: 0,
        //   });
        // }
        const request2: CityGetAllRequestModel = {
          selectItemList: ["CityId", "CityName"],
          orderField: "CityName",
        };

        this.props.getAllM(request2);


        this.state.filterData.cityId !== 0 &&
          this.state.filterData.cityId !== null &&
          this.CityChange(this.state.filterData.cityId);
      }
    );
  }

  getDataPager(pagerSelected: number) {
    const request: GetThemedFacilityListModel = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...this.state.filterData,
      certificateTypeId: this.state.selectedType,
      isForAdditionalAudit: this.props.formData.isForAdditionalAudit,
    };

    request["isPublishQr"] =
      request["isPublishQr"] === "1"
        ? true
        : request["isPublishQr"] === "2"
          ? false
          : null;

    this.props.getAllThemedFacilityForModal(request);
  }

  onDelete(ThemedFacilityId: number) {
    const request: ThemedFacilityChangeStatusRequestModel = {
      id: ThemedFacilityId,
      status: StatusEnums.Deleted,
    };
    castThunkAction(this.props.changeStatus(request)).then((response: any) => {
      this.getData(this.state.pagerCurrent);
    });
  }

  renderDashboardItems() {
    return (
      <div className="dashboard-items row">
        {panels.map(
          (item, index) =>
            item.children && (
              <React.Fragment>
                <div className="title">
                  {item.text}
                  <div className="title-text">{item.subText}</div>
                </div>
                <div className="childrens row">
                  {item.children.map((children, childrenIndex) => (
                    <div key={index} className={`dashboard-item col-md-3`}>
                      <Link
                        className="row"
                        to={children.url ? children.url : "/"}
                      >
                        <div className="icon">
                          <i className={children.icon}></i>
                        </div>
                        <div className="title">
                          {children.text}
                          <div className="sub-text">{children.subText}</div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )
        )}
      </div>
    );
  }
  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Tümünü Seç" });
    }

    return newArray;
  }

  CityChange(item: any) {
    this.setState(
      {
        filterData: {
          ...this.state.filterData,
          cityId: item,
        },
      },
      () => {
        const request2: CountyGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "cityId",
              value: item,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
            {
              propertyName: "Status",
              value: 1,
              isOr: false,
              conditionOperator: 0,
            },
          ],
          selectItemList: ["DistrictId", "DistrictName"],
          orderField: "DistrictName",
        };
        this.props.getAllC(request2);
      }
    );
  }

  renderFilter() {
    return (
      <div className="table-filter row">
        <TextField
          className="custom-textfield col-md-3"
          label="Tesis Adı"
          value={this.state.filterData?.searchKey || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                searchKey: event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />

        <TextField
          className="custom-textfield col-md-1"
          label="ID"
          value={this.state.filterData?.themedFacilityId || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                themedFacilityId: isNumberValue(event.target.value),
              },
            });
          }}
          type="text"
        />

        <TextField
          className="custom-textfield col-md-2"
          label="Belge Sahibi / Ticari Ünvan"
          value={this.state.filterData?.companyName || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                companyName:
                  event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        <Dropdown
          defaultSelectedKey={this.state.filterData?.cityId || ""}
          onChanged={(event: any) => {
            this.CityChange(event.key);
            this.setState({
              filterData: {
                ...this.state.filterData,
                cityId: event.key,
              },
            });
          }}
          label="İl"
          options={(this.props.formData.GroupList?.dataList
            ? cityReturnOptions(
              this.props.formData.GroupList?.dataList,
              "CityId",
              "CityName"
            )
            : ([] as any)
          ).map((item: any) => ({ key: item.CityId, text: item.CityName }))}
          className="custom-dropdown col-md-2"
        />
        <Dropdown
          defaultSelectedKey={this.state.filterData?.districtId || ""}
          onChanged={(event: any) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                districtId: event.key,
              },
            })
          }
          label="İlçe"
          options={(this.props.formData.GroupListC?.dataList
            ? this.returnOptions(
              this.props.formData.GroupListC?.dataList,
              "DistrictId",
              "DistrictName"
            )
            : ([] as any)
          ).map((item: any) => ({
            key: item.DistrictId,
            text: item.DistrictName,
          }))}
          className="custom-dropdown col-md-2"
        />

        <Dropdown
          options={[
            { key: "BrandName", text: "Tesis Adı" },
            { key: "CreatedSystemUserDatetime", text: "Oluşturma Tarihi" },
            { key: "UpdatedSystemUserDatetime", text: "Güncelleme Tarihi" },
          ]}
          className="custom-dropdown col-md-2"
          label="Sıralama"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.orderField || null}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                orderField: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={(this.state.filterData.orderField === "BrandName"
            ? [
              { key: false, text: "Baştan Sona" },
              { key: true, text: "Sondan Başa" },
            ]
            : [
              { key: false, text: "Eskiden Yeniye" },
              { key: true, text: "Yeniden Eskiye" },
            ]
          ).map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-2"
          label="Sıralama Türü"
          selectedKey={this.state.filterData?.isDescending}
          placeholder="Seçiniz"
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isDescending: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        {/* <Dropdown
          options={[
            { key: StatusEnums.Deleted, text: "Silindi" },
            { key: StatusEnums.Published, text: "Yayımda" },
          ]}
          className="custom-dropdown col-md-2"
          label="Kayıt Durumu"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.status || 1}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                status: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        /> */}
        <TextField
          className="custom-textfield col-md-1"
          label="Belge No"
          value={this.state.filterData?.documentNo || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                documentNo: event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />

        <PrimaryButton
          text="Arama Yap"
          className="custom-button"
          onClick={() => {
            cookie.save(
              "themedFacilityModalListState",
              JSON.stringify(this.state),
              {
                path: "/",
              }
            );
            this.getDataPager(0);
          }}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
      </div>
    );
  }

  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "themedFacilityId",
            text: "ID",
            col: "col-md-1",
          },
          {
            columnName: "cityDistrictName",
            text: "İl / İlçe",
            col: "col-md-1",
          },
          {
            columnName: "companyName",
            text: "Belge Sahibi / Ticari Ünvan",
            col: "col-md-1",
          },

          {
            columnName: "brandName",
            text: "Tesis Adı",
            col: "col-md-1",
          },

          {
            columnName: "address",
            text: "Adresi",
            col: "col-md-3",
          },

          {
            columnName: "themedFacilityId",
            text: "İşlem",
            col: "col-md-3",
            className: "operations",
            onRender: (item: {
              themedFacilityId: any;
              companyName: any;
              brandName: any;
              isCertificateBelong: any;
              isHaveCertificate: any;
              isSelectable: any;
            }) => (
              <React.Fragment>
                {this.props.formData.Additional !== 1 && (
                  <button
                    style={{
                      backgroundColor: item.isCertificateBelong
                        ? "#909396"
                        : !item.isSelectable
                          ? "#e21532"
                          : "#178df7",
                    }}
                    onClick={() =>
                      !item.isCertificateBelong &&
                      item.isSelectable &&
                      this.setSelect(item)
                    }
                  >
                    <i className="icon-trash-outline"></i>{" "}
                    {item.isCertificateBelong
                      ? "Firmanız tarafından sertifikası mevcut"
                      : !item.isSelectable
                        ? "Başka firma tarafından sertifikalı"
                        : "Seçiniz"}
                  </button>
                )}
                {this.props.formData.Additional !== 1 &&
                  item.isCertificateBelong && (
                    <button
                      style={{ background: "#178df7" }}
                      onClick={() =>
                        window.open(
                          "/ThemedFacility/edit/" + item.themedFacilityId,
                          "_blank"
                        )
                      }
                    >
                      <i className="icon-trash-outline"></i> Detay
                    </button>
                  )}
                {this.props.formData.Additional === 1 && (
                  <button onClick={(x) => this.setAdditional(item)}>
                    Denetim Ekle
                  </button>
                )}
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.ThemedFacilityListForModal?.dataList || []}
      />
    );
  }

  setSelect(item: { themedFacilityId: any; companyName: any; cityName?:string; districtName?:string; address?:string; brandName?:string;}): void {
    this.props.setFormData("themedFacilityId", item.themedFacilityId);
    this.props.setFormData("cityName",item.cityName);
    this.props.setFormData("districtName",item.districtName);
    this.props.setFormData("address",item.address);
    this.props.setFormData("brandName",item.brandName);
    this.props.setFormData("themedFacilityName", item.companyName);
    this.props.setFormData("themedFacilityModalShow", false);
  }

  setAdditional(item: { themedFacilityId: any; brandName: any }): void {
    this.props.setFormData("themedFacilityId", item.themedFacilityId);
    this.props.setFormData("brandName", item.brandName);
    this.props.setFormData("themedFacilityModalShow", false);
    window.open("/AdditionalControl/Create/" + item.themedFacilityId + "/" + 8);
  }

  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={
          this.props.formData?.ThemedFacilityListForModal?.pageCount || 1
        }
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }
  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getDataPager(selected);
  };
  onSelectedBack = () => {
    this.setState({
      selectedType: 0
    })
  }
  render() {
    return (
      <>
        {this.state.selectedType !== 0 && (
          <div className="page push-all">
            <PageTitle
              title="Temalı Tesis Seç"
              subTitle="Yönetilebilecek tüm alanları aşağıdan görüntüleyebilirsiniz."
              backUrl="/"
              showBack
              history={this.props.history}
              onBack={this.props.onBack ? this.props.onBack : this.onSelectedBack}
            />
            {this.props.formData.Additional !== 1 && (
              <PrimaryButton
                onClick={() =>
                  this.props.history.push("/themedFacility/create")
                }
                iconProps={{ iconName: "Add" }}
                className="absolute-create"
                text="Temalı Tesis Tesisi Ekle"
                styles={{
                  root: {
                    background: "#62a98e",
                    borderColor: "#5b9e85",
                  },
                  rootHovered: {
                    background: "#5b9e85",
                    borderColor: "#5b9e85",
                  },
                  rootDisabled: {
                    background: "#ececec",
                  },
                }}
              />
            )}
            {this.renderFilter()}

            {this.renderList()}
            {this.renderPager()}
          </div>
        )}

        {this.state.selectedType === 0 && (
          <div className="home-searchs row">
            <div className="col-md-6 col-xs-12">
              <div className="search">
                <button
                  className="searchButton"
                  onClick={() =>
                    this.setState({ selectedType: 4 }, () =>
                      this.getData(this.state.pagerCurrent)
                    )
                  }
                >
                  <a className="home-search-indicator">
                    <i className="fas fa-hotel"></i>
                    T.C. Kültür ve Turizm Bakanlığı Belgeli Temalı Tesisler
                  </a>
                </button>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <div className="search">
                <button
                  className="searchButton"
                  onClick={() =>
                    this.setState({ selectedType: 5 }, () =>
                      this.getData(this.state.pagerCurrent)
                    )
                  }
                >
                  <a className="home-search-indicator">
                    <i className="fas fa-hotel"></i> Belediye Belgeli Temalı
                    Tesisler ve Diğer
                  </a>
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemedFacility);
