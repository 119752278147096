import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import FacilityType from "src/services/FacilityType/FacilityTypeService";
import {
  FacilityTypeGetAllRequestModel,
  FacilityTypeChangeStatusRequestModel,
  FacilityTypeGetByIdRequestModel,
  FacilityTypeUpdateRequestModel,
  FacilityTypeSaveRequestModel,
} from "src/services/FacilityType/RequestModel";
import { MessageType } from "./system/types";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllFA(
  request: FacilityTypeGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const FacilityTypeService = new FacilityType();
    const result = await FacilityTypeService.getAll(request);
    dispatch(setFormData("GroupListFA", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: FacilityTypeGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const FacilityTypeService = new FacilityType();
    const result = await FacilityTypeService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: FacilityTypeSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const FacilityTypeService = new FacilityType();
    const result = await FacilityTypeService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Tesis Sınıfı Yönetimi"
        )
      );
      return result;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Tesis Sınıfı Yönetimi"
        )
      );
      return false;
    }
  };
}
export function update(
  request: FacilityTypeUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const FacilityTypeService = new FacilityType();
    const result = await FacilityTypeService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Tesis Sınıfı Yönetimi"
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Tesis Sınıfı Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getById(
  request: FacilityTypeGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const FacilityTypeService = new FacilityType();
    const result = await FacilityTypeService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: FacilityTypeChangeStatusRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const FacilityTypeService = new FacilityType();
    const result = await FacilityTypeService.changeStatus(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Tesis Sınıfı Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Tesis Sınıfı Yönetimi"
        )
      );
      return false;
    }
  };
}
