import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Header from "../../components/Header";
import LeftPanel from "../../components/LeftPanel";
import { ListFacility } from "src/components/AdditionalControl";

export default class AdditionalControlListFacility extends React.Component<
  RouteComponentProps<any>
> {
  render() {
    return (
      <React.Fragment>
        <Header history={this.props.history} />
        <div className="row">
          <LeftPanel history={this.props.history} />
          <ListFacility
            history={this.props.history}
            filterId={this.props.match.params.Id}
            typeid={this.props.match.params.Type}
          />
        </div>
      </React.Fragment>
    );
  }
}
