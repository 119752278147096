import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { panels } from "src/utils/constants";
import { Link } from "react-router-dom";
import { History } from "history";
import BasicTable from "../BasicTable";
import {
  TextField,
  Dropdown,
  PrimaryButton,
  Label,
} from "office-ui-fabric-react";
import {
  getAll,
  updateNotification,
  getAllNotificationList,
} from "src/store/thunkNotification";
import {
  NotificationGetAllRequestModel,
  NotificationUpdateRequestModel,
} from "src/services/Notification/RequestModel";
import { castThunkAction } from "src/helpers/casting";
import { toggleConfirmDialog } from "src/store/system/actions";
import { CategoryTypes, OperationTypes } from "src/helpers/enums";
import { getCompanyUserList } from "src/store/thunksSystemUser";
import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";
import cookie from "react-cookies";
import { listCount } from "src/config/otherConfig";
// @ts-ignore
import ReactExport from "react-export-excel";
import { formatDate } from "src/helpers/converts";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
let usertype: any = 0;
interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        getAll,
        updateNotification,
        toggleConfirmDialog,
        getCompanyUserList,
        getAllNotificationList,
      },
      dispatch
    ),
  };
}

type NotificationProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  pagerTotal: number;
  excel: boolean;
  download: boolean;
}

class List extends Component<NotificationProps, OwnState> {
  state = {
    filterData: {
      searchKey: "",
      isRead: null,
      orderField: "ProcessDate",
      isDescending: true,
    },
    pagerCurrent: 0,
    excel: false,
    download: false,
    pagerTotal: 0,
  };

  componentDidMount() {
    this.getData(this.state.pagerCurrent);
    usertype = cookie.load("systemUserInfo");
  }
 
  getData(pagerSelected: number) {
    const request: NotificationGetAllRequestModel = {
      ...this.state.filterData,
      pageSize: listCount,
      pageIndex: pagerSelected,
    };
    this.setState({ excel: false });

    castThunkAction(this.props.getAll(request)).then((x: any) => {
      const request6: SystemUserGetAllModel = {
        systemUserType: 2,
      };
      this.props.getCompanyUserList(request6);
    });
  }

  setRead(CategoryType: any, RelatedId: any, NotificationId: any) {
    const request: NotificationUpdateRequestModel = {
      NotificationId,
      CategoryType,
      RelatedId,
      isRead: true,
    };
    castThunkAction(this.props.updateNotification(request)).then(
      (response: any) => {
        window.open(
          "/" +
            CategoryTypes.find((x) => x.key === CategoryType).link +
            RelatedId
        );
      }
    );
  }

  renderDashboardItems() {
    return (
      <div className="dashboard-items row">
        {panels.map(
          (item, index) =>
            item.children && (
              <React.Fragment>
                <div className="title">
                  {item.text}
                  <div className="title-text">{item.subText}</div>
                </div>
                <div className="childrens row">
                  {item.children.map((children, childrenIndex) => (
                    <div key={index} className={`dashboard-item col-md-3`}>
                      <Link
                        className="row"
                        to={children.url ? children.url : "/"}
                      >
                        <div className="icon">
                          <i className={children.icon}></i>
                        </div>
                        <div className="title">
                          {children.text}
                          <div className="sub-text">{children.subText}</div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )
        )}
      </div>
    );
  }

  renderFilter() {
    return (
      <div className="table-filter row">
        <TextField
          className="custom-textfield col-md-3"
          label="Tesis Adı İle Arama"
          value={this.state.filterData?.searchKey || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                searchKey: event.target.value,
              },
            });
          }}
        />
        <Dropdown
          options={[
            { key: false, text: "Okunmadı" },
            { key: true, text: "Okundu" },
          ].map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-3"
          label="Okundu Bilgisi"
          placeholder="Seçiniz"
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isRead: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={CategoryTypes.map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-3"
          label="Sertifika Tipi"
          placeholder="Seçiniz"
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                categoryType: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={OperationTypes.map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-3"
          label="İşlem Tipi"
          placeholder="Seçiniz"
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                operationType: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={(this.props.formData.CompanyUserList?.dataList
            ? this.returnOptions(
                this.props.formData.CompanyUserList?.dataList,
                "systemUserId",
                "title"
              )
            : ([] as any)
          ).map((item: any) => ({
            key: item.systemUserId,
            text: item.title,
          }))}
          className="custom-dropdown col-md-4"
          label="Denetleyici Firma"
          placeholder="Seçiniz"
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                companyUserId: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: false, text: "Eskiden Yeniye" },
            { key: true, text: "Yeniden Eskiye" },
          ].map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-2"
          label="Sıralama Türü"
          placeholder="Seçiniz"
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isDescending: option.key,
              },
            });
          }}
          
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <PrimaryButton
          text="Arama Yap"
          className="custom-button"
          onClick={() => {
            cookie.save("layoverListState", JSON.stringify(this.state), {
              path: "/",
            });
            this.getData(0)
          }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />

        {usertype && usertype !== "2" ? this.rederexcel() : ""}
      </div>
    );
  }
  downloadexcel() {
    this.setState({ download: true });
    const requestexcel: any = {
      ...this.state.filterData,
    };

    castThunkAction(this.props.getAllNotificationList(requestexcel)).then(
      (response: any) => {
        const list = this.props.formData.LayoverListForExcel?.dataList;

        this.props.formData.RestaurantListForExcel?.dataList.length > 0 &&
          list.push(...this.props.formData.RestaurantListForExcel?.dataList);

        this.setState({ excel: true, download: false });
      }
    );
  }

  rederexcel() {
    const excelName = "Bildirimler_" + formatDate();
    return this.state.excel ? (
      <ExcelFile
        filename={excelName}
        element={
          <PrimaryButton
            text="Raporu İndir"
            className="custom-button"
            style={{ marginLeft: "15px" }}
            styles={{
              root: {
                fontSize: "11px",
                marginTop: "27px",
                height: "30px",
              },
            }}
          />
        }
      >
        <ExcelSheet
          data={this.props.formData?.NotificationListForExcel?.dataList}
          name="Tr"
        >
          <ExcelColumn
            label="Sertifika Türü"
            value={(col: any) =>
              col.categoryType
                ? CategoryTypes.find((x) => x.key === col.categoryType).text
                : ""
            }
          />
          <ExcelColumn
            label="İşlem Türü"
            value={(col: any) =>
              col.operationType
                ? OperationTypes.find((x) => x.key === col.operationType).text
                : ""
            }
          />
          <ExcelColumn label="Denetleyici Firma" value="companyUserTitle" />
          <ExcelColumn label="Tesis Adı" value="brandName" />
          <ExcelColumn
            label="İşlem Tarihi"
            value={(col: any) => col.processDateString?.split(" ")[0]}
          />
          <ExcelColumn
            label="İşlem Saati"
            value={(col: any) => col.processDateString?.split(" ")[1]}
          />
          <ExcelColumn
            label="Okunma Bilgisi"
            value={(col: any) => (col.isRead ? "Okundu" : "Okunmadı")}
          />
        </ExcelSheet>
        <ExcelSheet
          data={this.props.formData?.NotificationListForExcel?.dataList}
          name="En"
        >
          <ExcelColumn
            label="Certificate Type"
            value={(col: any) =>
              col.categoryType
                ? CategoryTypes.find((x) => x.key === col.categoryType).text
                : ""
            }
          />
          <ExcelColumn
            label="Operation type"
            value={(col: any) =>
              col.operationType
                ? OperationTypes.find((x) => x.key === col.operationType).text
                : ""
            }
          />
          <ExcelColumn label="Certification Company" value="companyUserTitle" />
          <ExcelColumn label="Facility Name" value="brandName" />
          <ExcelColumn
            label="Transaction date"
            value={(col: any) => col.processDateString?.split(" ")[0]}
          />
          <ExcelColumn
            label="Transaction Time"
            value={(col: any) => col.processDateString?.split(" ")[1]}
          />
          <ExcelColumn
            label="Read Receipts"
            value={(col: any) => (col.isRead ? "Read" : "Unread")}
          />
        </ExcelSheet>
      </ExcelFile>
    ) : (
      <>
        <PrimaryButton
          text="Rapor Oluştur"
          className="custom-button"
          onClick={() => this.downloadexcel()}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
        {this.state.download && (
          <Label style={{ marginLeft: "10px", marginTop: "27px" }}>
            Rapor Oluşturuluyor...
          </Label>
        )}
      </>
    );
  }
  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Tümünü Seç" });
    }

    return newArray;
  }

  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "categoryType",
            text: "Sertifika Türü",
            col: "col-md-3",
            onRender: (item: { categoryType: any }) => (
              <React.Fragment>
                {item.categoryType
                  ? CategoryTypes.find((x) => x.key === item.categoryType)?.text
                  : ""}
              </React.Fragment>
            ),
          },
          {
            columnName: "operationType",
            text: "İşlem Türü",
            col: "col-md-2",
            onRender: (item: { operationType: any }) => (
              <React.Fragment>
                {item.operationType
                  ? OperationTypes.find((x) => x.key === item.operationType)
                      .text
                  : ""}
              </React.Fragment>
            ),
          },

          {
            columnName: "companyUserTitle",
            text: "Denetleyici Firma",
            col: "col-md-1",
          },
          {
            columnName: "brandName",
            text: "Tesis Adı",
            col: "col-md-1",
          },
          {
            columnName: "processDateString",
            text: "İşlem Tarihi",
            col: "col-md-1",
          },
          {
            columnName: "operationType",
            text: "Okunma Bilgisi",
            col: "col-md-2",
            onRender: (item: { isRead: any }) => (
              <React.Fragment>
                {item.isRead ? "Okundu" : "Okunmadı"}
              </React.Fragment>
            ),
          },

          {
            columnName: "notificationId",
            text: "İşlem",
            col: "col-md-2",
            className: "operations",
            onRender: (item: {
              categoryType: any;
              relatedId: any;
              notificationId: any;
            }) => (
              <React.Fragment>
                <button
                  onClick={() =>
                    this.setRead(
                      item.categoryType,
                      item.relatedId,
                      item.notificationId
                    )
                  }
                >
                  <i className="icon-trash-outline"></i> Git
                </button>
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.dataList || []}
      />
    );
  }
  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.props.formData.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }
  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getData(selected);
  };
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Bildirimler"
          subTitle="Yönetilebilecek tüm alanları aşağıdan görüntüleyebilirsiniz."
          backUrl="/"
          showBack
          history={this.props.history}
        />

        <Label className="totalRecord">
          Toplam Kayıt Sayısı: {this.props.formData?.dataCount}
        </Label>
        {this.renderFilter()}
        {this.renderList()}
        {this.renderPager()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
