import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  TextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Dropdown,
  Checkbox,
} from "office-ui-fabric-react";
import {
  setFormData,
  setFormErrors,
  initialFormData,
} from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";
import { save } from "src/store/thunkHospital";
import { castThunkAction } from "src/helpers/casting";
import { HospitalSaveRequestModel } from "src/services/Hospital/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";
import { showErrorDialog } from "src/store/thunkCertificateType";
import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import { requestMapStatusData } from "src/helpers/functions";
interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        initialFormData,
        save,
        getAllM,
        getAllC,
        showErrorDialog,
      },
      dispatch
    ),
  };
}

type CreateHospitalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  messageType: MessageBarType;
}

class CreateHospital extends Component<CreateHospitalProps, OwnState> {
  state = {
    messageType: MessageBarType.warning,
  };

  UNSAFE_componentWillMount() {
    this.props.initialFormData({
      status: StatusEnums.Published,
    });
    const request2: CityGetAllRequestModel = {
      selectItemList: ["CityId", "CityName"],
      orderField: "CityName",
      criteriaItemList: [
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
    };
    this.props.getAllM(request2);
    setFormData("isTestingCovid", false);
    if (this.props.formData.cityId > 0) {
      this.CityChange(this.props.formData.cityId, 0);
    }
  }

  renderTextByMessageType() {
    switch (this.state.messageType) {
      case MessageBarType.success:
        return "Kaydetme işlemi başarıyla gerçekleşmiştir.";
      case MessageBarType.error:
        return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
      default:
        return "Lütfen aşağıdaki bilgileri doldurun, ve oluşturmayı tamamlayın!";
    }
  }

  renderMessageBar() {
    return (
      <MessageBar messageBarType={this.state.messageType} isMultiline={false}>
        {this.renderTextByMessageType()}
      </MessageBar>
    );
  }

  onCreate(isDraft?: boolean) {
    const request: HospitalSaveRequestModel = requestMapStatusData(this.props.formData,isDraft);
    
    castThunkAction(this.props.save(request)).then((response: any) => {
      this.setState(
        {
          messageType: response ? MessageBarType.success : MessageBarType.error,
        },
        () => {
          if (response) {
            this.props.history.push("/Hospital/edit/" + response.id);
          }
        }
      );
    });
  }
  CityChange(item: any, districtId?: any) {
    const request2: CountyGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "cityId",
          value: item,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
      selectItemList: ["DistrictId", "DistrictName"],
      orderField: "DistrictName",
    };
    castThunkAction(this.props.getAllC(request2)).then((response: any) => {
      this.props.setFormData("districtId", districtId);
    });
  }

  filterFileds(val: any) {
    if (
      val !== "" &&
      val !== 0 &&
      val !== "0" &&
      val !== null &&
      val !== "null" &&
      val !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  }

  onSubmit(isDraft?: boolean) {
    let desc = "";
    const message = {
      title: "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
      desc: desc,
    };

    if (!this.filterFileds(this.props.formData.hospitalName)) {
      desc += "Sağlık Kuruluşu Adı,  <br/>";
    }

    if (!this.filterFileds(this.props.formData.hospitalAddress)) {
      desc += "Sağlık Kuruluşu Adresi,  <br/>";
    }

    if (this.props.formData.cityId <= 0) {
      desc += "İl,  <br/>";
    }

    if (!this.filterFileds(this.props.formData.districtId)) {
      desc += "İlçe,  <br/>";
    }

    if (desc !== "") {
      message.desc = desc;
      castThunkAction(
        this.props.showErrorDialog(message)
      ).then((response: any) => { });
    }
    if (desc === "") {
      const { formData, setFormErrors } = this.props;
      const validationSchema = _yup.object({
        hospitalName: _yup.string().required("Bu alan zorunludur!"),
        hospitalAddress: _yup.string().required("Bu alan zorunludur!"),
        // latitude: _yup.string().required("Bu alan zorunludur!"),
        // longitude: _yup.string().required("Bu alan zorunludur!"),
        districtId: _yup.string().required("Bu alan zorunludur!").nullable(),
      });

      let formErrors = {};
      validationSchema
        .validate(formData, {
          abortEarly: false,
        })
        .then(() => {
          setFormErrors({});
          this.onCreate(isDraft);
        })
        .catch((err) => {
          err.inner.forEach((e: any) => {
            const { path, message } = e;
            formErrors[path] = message;
          });
          setFormErrors(formErrors);
        });
    }
  }

  renderForm() {
    const { formData, formErrors, setFormData } = this.props;
    return (
      <div className="form-groups row">
        <div className="form-group col-md-4">
          <div className="title">Genel Bilgiler</div>
          <div className="form-wrapper">
            <div className="item row">
              <TextField
                placeholder="Sağlık Kuruluşu Adı"
                value={formData?.hospitalName || ""}
                label="Sağlık Kuruluşu Adı"
                className="custom-textfield border"
                errorMessage={formErrors.hospitalName}
                onChange={(event: any) =>
                  setFormData("hospitalName", event.target.value)
                }
              />
            </div>

            <div className="item row">
              {formData.isTestingCovid ? (
                <Checkbox
                  label="Covid Testi"
                  defaultChecked
                  onChange={() =>
                    setFormData("isTestingCovid", !formData.isTestingCovid)
                  }
                />
              ) : (
                <Checkbox
                  label="Covid Testi var."
                  onChange={() =>
                    setFormData("isTestingCovid", !formData.isTestingCovid)
                  }
                />
              )}
            </div>

            <div className="item row">
              <TextField
                placeholder="Sağlık Kuruluşu Adresi"
                value={formData?.hospitalAddress}
                label="Sağlık Kuruluşu Adresi"
                className="custom-textfield border"
                errorMessage={formErrors.hospitalAddress}
                onChange={(event: any) =>
                  setFormData("hospitalAddress", event.target.value)
                }
              />
            </div>

            {/* <div className="item row">
              <TextField
                placeholder="X Koordinatı"
                value={formData?.latitude}
                label="X Koordinatı"
                className="custom-textfield border"
                errorMessage={formErrors.latitude}
                onChange={(event: any) =>
                  setFormData("latitude", event.target.value)
                }
              />
            </div>

            <div className="item row">
              <TextField
                placeholder="Y Koordinatı"
                value={formData?.longitude}
                label="Y Koordinatı"
                className="custom-textfield border"
                errorMessage={formErrors.longitude}
                onChange={(event: any) =>
                  setFormData("longitude", event.target.value)
                }
              />
            </div> */}
            <div className="item row">
              <Dropdown
                // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                onChanged={(event: any) => (
                  this.CityChange(event.key), setFormData("cityId", event.key)
                )}
                label="İl"
                options={(
                  this.props.formData.GroupList?.dataList || ([] as any)
                ).map((item: any) => ({
                  key: item.CityId,
                  text: item.CityName,
                }))}
                className="custom-dropdown"
                selectedKey={this.props.formData.cityId}
              />
            </div>
            <div className="item row">
              <Dropdown
                // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                onChanged={(event: any) => { setFormData("districtId", event.key); }}
                label="İlçe"
                options={(formData.GroupListC?.dataList || ([] as any)).map(
                  (item: any) => ({
                    key: item.DistrictId,
                    text: item.DistrictName,
                  })
                )}
                className="custom-dropdown"
                errorMessage={formErrors.districtId}
                selectedKey={this.props.formData.districtId}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Yeni Sağlık Kuruluşu Ekle"
          titleRenderer={() => (
            <span className="edit-title">
              Yönet:
              <strong>Yeni Sağlık Kuruluşu Ekle</strong>
            </span>
          )}
          showBack
          backUrl="/Hospital"
          history={this.props.history}
          subTitle="Yeni bir Sağlık Kuruluşu eklemek için aşağıdaki bilgileri doldurunuz."
          rightType="create"
          right={<FormButtons
            isAbsolute
            onSubmit={() => this.onSubmit()}
            customRenderButtons={() => (
              <React.Fragment>
                <PrimaryButton
                  onClick={() => this.onSubmit()}
                  disabled={
                    this.state.messageType === MessageBarType.success
                      ? true
                      : false
                  }
                  styles={{
                    rootDisabled: {
                      background: "#626262",
                      borderColor: "#777373 !important",
                    },
                  }}
                  iconProps={{ iconName: "CheckMark" }}
                  text="Yayımla"
                />
              </React.Fragment>
            )}
          />}
        />
        {this.renderMessageBar()}

        {this.renderForm()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateHospital);
