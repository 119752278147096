import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import City from "src/services/City/CityService";
import {
  CityGetAllRequestModel,
  CityChangeStatusRequestModel,
  CityGetByIdRequestModel,
  CityUpdateRequestModel,
  CitySaveRequestModel,
} from "src/services/City/RequestModel";
import { MessageType } from "./system/types";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllM(
  request: CityGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const cityService = new City();
    const result = await cityService.GetAllOrdered(request);

    if (result.success) {
      const data = result.dataList.map((d:any) => {
        return {
          CityId: d.cityId,
          CityName: d.cityName,
        };
      });


      dispatch(setFormData("GroupList", {dataList :data}));

      return true;
    }else {
      dispatch(setFormData("GroupList", {dataList :[]}));

    }
    return false;
  };
}

export function getAll(
  request: CityGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const cityService = new City();
    const result = await cityService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(request: CitySaveRequestModel): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const cityService = new City();
    const result = await cityService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "İl Yönetimi"
        )
      );
      return result;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "İl Yönetimi"
        )
      );
      return false;
    }
  };
}
export function update(
  request: CityUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const cityService = new City();
    const result = await cityService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "İl Yönetimi"
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "İl Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getById(
  request: CityGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const cityService = new City();
    const result = await cityService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: CityChangeStatusRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const cityService = new City();
    const result = await cityService.changeStatus(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "İl Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "İl Yönetimi"
        )
      );
      return false;
    }
  };
}
