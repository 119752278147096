export enum errorCodeData {
  Error = 0,
  NullableRequest = 1,
  StatusPropertyNotDefined = 3,
  VerificationCodeWrong = 4,
  ApplicationUserNotFound = 5,
  ApplicationUserCantSave = 6,
  VerificationCodeCantSave = 7,
  QuestionNotFound = 8,
  VerificationCodeExpired = 9,
  SystemUserAlreadyExist = 10,
  SystemUserCantSave = 11,
  SystemUserNotFound = 12,
  QuestionTagStatusUpdateError = 13,
  QuestionTagCantSave = 14,
  QuestionCantSave = 15,
  ApplicationUserExist = 16,
  ApplicationSystemUserNotFound = 17,
  SystemUserGroupNotSelected = 18,
  LoginAgain = 19,
  DoNotHaveAccess = 20,
  ServiceHistoryNotFound = 21,
  AnalysisNotFound = 22,
}

export enum StatusEnums {
  None = 0,
  Published = 1,
  Draft = 2,
  PublishArchive = 3,
  DraftArchive = 4,
  PendingApproval = 5,
  Deleted = 8,
  ActivityStopped = 20,
  All = 9,
  ComplatelyDeleted = 99,
}

export enum Gender {
  Male = 1,
  Female = 2,
  Unkown = 3,
}
export interface ILeaveCertificationProcessRequest {
  id: number;
  endId: number;
}
