import * as React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { setFormData, setFormErrors } from "../store/form/actions";
import { RootState } from "src/store/reducer";
import * as _yup from "yup";
import { History } from "history";
import { SystemUserSystemUserLogin } from "src/services/SystemUser/RequestModel";
import SystemUserService from "src/services/SystemUser/SystemUserService";
import cookie from "react-cookies";
import { setUserInfo, tokenControl } from "src/store/system/actions";
import { baseUrl } from "src/config/apiConfig";
import ReCAPTCHA from "react-google-recaptcha";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    formData: state.form.formData,
    formErrors: state.form.formErrors,
    language: state.system.language,
    userInfo: state.system.userInfo,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setFormData,
      setFormErrors,
      setUserInfo,
      tokenControl,
    },
    dispatch
  );
}

type LoginProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

class Login extends React.Component<LoginProps> {
  state = {
    isError: false,
    recaptchaVerified: false,
  };

  componentDidMount() {
    window.addEventListener("keydown", this.onEnterSubmit, true);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onEnterSubmit, true);
  }

  /**
   * Enter'a basıldığında onSubmit fonksiyonunu çağırır.
   */
  onEnterSubmit = (e: any) => {
    if (e.key === "Enter") {
      this.onSubmit();
    }
  };

  registerCookie(token: string, systemUserInfo: any) {
    cookie.save("userId", token, { path: "/" });
    cookie.save("systemUserInfo", systemUserInfo.systemUserType, { path: "/" });
    cookie.save("systemUserName", systemUserInfo.title, { path: "/" });
    cookie.save("prefix", systemUserInfo.prefix, { path: "/" });
    this.props.setUserInfo({
      ...systemUserInfo.systemUserType,
      token,
      title: systemUserInfo.title,
    });
    this.props.tokenControl(true);

    window.location.reload();
  }

  async onLogin() {
    const service = new SystemUserService();
    const req: SystemUserSystemUserLogin = {
      uid: this.props.formData.username,
      password: this.props.formData.password,
    };
    const data = await service.onLogin(req);
    if (data.errorCode === 0) {
      this.setState(
        {
          isError: false,
        },
        () => {
          this.registerCookie(data.token, data.systemUserInfo);
        }
      );
    } else {
      this.setState({
        isError: true,
      });
    }
  }

  /** Formu kontrol ederek giriş yapılacak servise yönlendirilir. */
  async onSubmit() {
    const { formData, setFormErrors } = this.props;
    const { recaptchaVerified } = this.state;
    const validationSchema = _yup.object({
      username: _yup.string().required("Bu alan zorunludur!"),
      password: _yup.string().required("Bu alan zorunludur!"),
    });
    let formErrors = {} as any;
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        if (recaptchaVerified) {
          setFormErrors({});
          this.onLogin();
        } else {
          setFormErrors({ recaptcha: "CAPTCHA doğrulaması gereklidir!" });
        }
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  render() {
    const testUrl = window.location.hostname;

    const { formData, formErrors, setFormData } = this.props;
    return (
      <div className="login-page">
        <div className="login-wrapper relative">
          <div className="top-text">
            <div className="title">
              <img src="/assets/images/logo.png" alt="TGA Logo" />
              <div className="clear"></div>
            </div>
            {(testUrl.includes("glomil.com") ||
              testUrl.includes("localhost")) && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                }}
              >
                <strong style={{ color: "#222" }}>Api URL: {baseUrl}</strong>
              </div>
            )}
          </div>
          <div className="login-screen">
            <div className="text">
              <div className="title">Kullanıcı Girişi</div>
              <div className="sub-title">
                Aşağıdaki bilgileri doldurarak sisteme giriş yapabilirsiniz.
              </div>
            </div>
            <div className="form-wrapper">
              <div
                className={
                  formErrors.username ? "form-item error" : "form-item"
                }
              >
                <label>Kullanıcı adınız</label>
                <input
                  type="text"
                  placeholder="Kullanıcı adınızı giriniz."
                  value={formData.username}
                  onChange={(event) =>
                    setFormData("username", event.target.value)
                  }
                />
                {formErrors.username && (
                  <span className="error-message">{formErrors.username}</span>
                )}
              </div>
              <div
                className={
                  formErrors.password ? "form-item error" : "form-item"
                }
              >
                <label>Parola</label>
                <input
                  type="password"
                  placeholder="Kişisel parolanızı giriniz"
                  value={formData.password}
                  onChange={(event) =>
                    setFormData("password", event.target.value)
                  }
                />
                {formErrors.password && (
                  <span className="error-message">{formErrors.password}</span>
                )}
              </div>
              <div
                className={
                  formErrors.recaptcha ? "form-item error" : "form-item"
                }
              >
                <ReCAPTCHA
                  sitekey="6Ld82K8eAAAAAL51u6HPEWN_NSOcw8I5Qp7xB-tM"
                  onChange={(token) =>
                    this.setState({ recaptchaVerified: true })
                  }
                />
                {formErrors.recaptcha && (
                  <span className="error-message">{formErrors.recaptcha}</span>
                )}
              </div>
              <div className="submit-wrapper">
                <button onClick={() => this.onSubmit()}>GİRİŞ</button>
              </div>
            </div>
          </div>
          {this.state.isError && (
            <div className="login-message error active">
              Kullanıcı adı veya şifre hatalı. Lütfen bilgileri kontrol ediniz.
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

