import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import Hotel from "src/services/Hotel/HotelService";
import {
  HotelGetAllRequestModel,
  HotelChangeStatusRequestModel,
  GetHotelCertificateListByHotelModel,
  GetHotelListModel,
  HotelGetByIdRequestModel,
  HotelUpdateRequestModel,
  HotelSaveRequestModel,
  IGetHotelHistoryListRequest,
} from "src/services/Hotel/RequestModel";
import { MessageType } from "./system/types";
import { errorMessageSaveOrUpdate } from "src/helpers/functions";
import { formatDate } from "src/helpers/converts";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllMA(
  request: HotelGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const HotelService = new Hotel();
    const result = await HotelService.getAllHotel(request);
    dispatch(setFormData("HotelList", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAllSertificationMA(
  request: GetHotelCertificateListByHotelModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelService = new Hotel();
    const result = await HotelService.GetHotelCertificateListByHotel(request);
    dispatch(setFormData("HotelSerticationList", result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: HotelGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelService = new Hotel();
    const result = await HotelService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAllForPager(
  request: HotelGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelService = new Hotel();
    const result = await HotelService.getAllHotel(request);

    dispatch(setFormData("dataList", result.dataList));
    dispatch(setFormData("pageCount", result.pageCount));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getDeleteAllForPager(
  request: HotelGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelService = new Hotel();
    const result = await HotelService.getDeletedHotelList(request);

    dispatch(setFormData("dataList", result.dataList));
    dispatch(setFormData("pageCount", result.pageCount));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAllHotel(
  request: GetHotelListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelService = new Hotel();
    const result = await HotelService.getAllHotel(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getDeletedHotelList(
  request: GetHotelListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelService = new Hotel();
    const result = await HotelService.getDeletedHotelList(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAllHotelForModal(
  request: GetHotelListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelService = new Hotel();
    const result = await HotelService.getAllHotel(request);
    dispatch(setFormData("HotelListForModal", result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getHotelHistoryList(
  request: IGetHotelHistoryListRequest
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelService = new Hotel();
    const result = await HotelService.getHotelHistoryList(request);
    dispatch(setFormData("hotelDataModal", result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAllHotelForExcel(
  request: GetHotelListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelService = new Hotel();
    const excelName = "KonaklamaTesisleri_" + formatDate();
    await HotelService.excelDownload(request, excelName);
    dispatch(toggleLoader());

    return false;
  };
}

export function save(
  request: HotelSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelService = new Hotel();
    const result = await HotelService.save(request);
    //dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          ""
        )
      );
      return result;
    } else if (result.errorCode === 75) {
      dispatch(
        showMessage(
          "Belirtilen Tesise ait farklı bir kayıt bulunmaktadır.",
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          ""
        )
      );
      return false;
    } else if (result.errorCode === 76) {
      dispatch(
        showMessage(
          "Girmiş olduğunuz bilgilerle eşleşen benzer tesisler bulunmuştur, lütfen girmek istediğiniz tesis aşağıdakilerden biri ise seçerek ilerleyiniz. “Başka bir firma tarafından sertifikalı” ifadesini gördüğünüz bir tesis için sertifika kaydı oluşturmak isterseniz servis sağlayıcınız ile görüşmeniz gerekmektedir.",
          "Uyarı!",
          MessageType.ERROR,
          "Benzer Tesisler",
          false,
          result.data,
          request
        )
      );
      return false;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}
export function update(
  request: HotelUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelService = new Hotel();
    const result = await HotelService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Düzenleme işlemi başarıyla gerçekleşti.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          "",
          true
        )
      );
      return true;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}

export function getById(
  request: HotelGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelService = new Hotel();

    const result = await HotelService.getById(request);

    dispatch(
      initialFormData({ ...result.data, isDelete: result.data.status === 99 })
    );

    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getByIdForCertificate(
  request: HotelGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelService = new Hotel();
    const result = await HotelService.getById(request);
    // // bunu kullanırsan form data baştan olşuturulur eskiyi kaybedersin
    // dispatch(initialFormData(result.data));
    //bunu kullanırsan olan formdataya parametre eklenir okey? ok
    dispatch(setFormData("findHotelByQSId", result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: HotelChangeStatusRequestModel,
  isDelete: boolean = false
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelService = new Hotel();
    const result = await HotelService.changeStatus(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(initialFormData(result));
      dispatch(
        showMessage(
          isDelete
            ? "Silme işlemi başarıyla değiştirildi."
            : "Kayıt durumu işlemi başarıyla değiştirildi.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          "",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          ""
        )
      );
      return false;
    }
  };
}

export function putBlocked(
  request: any,
  isDelete: boolean = false
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HotelService = new Hotel();
    const result = await HotelService.putBlocked(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          request.IsBlocked
            ? "Tesis bloke edilmiştir."
            : "Tesis blokesi kaldırılmıştır.",
          "İşlem tamamlanmıştır.",
          request.IsBlocked ? MessageType.ERROR : MessageType.SUCCESS,
          "",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          ""
        )
      );
      return false;
    }
  };
}
