import { StatusEnums } from "../BaseEnum";

export interface WinterTourismFacilityTypeSaveDataModel {
  WinterTourismFacilityTypeName: string;
  WinterTourismFacilityTypeName_En: string;
  subCategoryId: number;
  isMinistry: boolean;
  status: StatusEnums;
}
export interface WinterTourismFacilityTypeSaveRequestModel {
  data: WinterTourismFacilityTypeSaveDataModel;
}

export interface WinterTourismFacilityTypeUpdateDataModel {
  WinterTourismFacilityTypeId: number;
  WinterTourismFacilityTypeName: string;
  WinterTourismFacilityTypeName_En: string;
  subCategoryId: number;
  isMinistry: boolean;
  status: StatusEnums;
}

export interface WinterTourismFacilityTypeUpdateRequestModel {
  data: WinterTourismFacilityTypeUpdateDataModel;
}

export interface WinterTourismFacilityTypeChangeStatusRequestModel {
  status: StatusEnums;
  id: number;
}

export enum ConditionOperatorEnum {
  Eq = 0,
  NotEq = 1,
  Like = 2,
  Gt = 3,
  Lt = 4,
  Ge = 5,
  Le = 6,
}

export interface criteriaItemListData {
  propertyName: string;
  value: any;
  isOr: boolean;
  conditionOperator: ConditionOperatorEnum;
}

export interface WinterTourismFacilityTypeGetAllRequestModel {
  criteriaItemList?: criteriaItemListData[];
  selectItemList?: string[];
  pageSize?: number;
  pageIndex?: number;
  orderField?: string;
  isDescending?: boolean;
  status?: StatusEnums;
}
export interface WinterTourismFacilityTypeGetByIdRequestModel {
  id: number;
}

export interface WinterTourismFacilityTypeChangeStatusRequestModel {
  id: number;
  status: StatusEnums;
}
