/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { RootState } from "../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "./PageTitle";
import { History } from "history";
import BasicTable from "./BasicTable";
import { getHotelHistoryList } from "src/store/thunkHotel";
import {
  IGetHotelHistoryListRequest,
} from "src/services/Hotel/RequestModel";
import { setFormData } from "src/store/form/actions";
import { showMessage } from "src/store/system/actions";
import { listCount } from "src/config/otherConfig";



interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    loading: state.system.loading
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        getHotelHistoryList,
        setFormData,
        showMessage,
      },
      dispatch
    ),
  };
}

type HotelHistoryListModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  pagerCurrent: number;
  pagerTotal: number;
}

const intitialState = {
  pagerCurrent: 0,
  pagerTotal: 0,
}

class HotelHistoryListModal extends Component<HotelHistoryListModalProps, OwnState> {

  state: any = intitialState;

  componentDidMount() {
    this.getData(this.state.pagerCurrent)
  }

  getData(
    pagerSelected: number,
  ) {
    const request: IGetHotelHistoryListRequest = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      hotelId: this.props.formData.hotelId,
    };

    this.props.getHotelHistoryList(request)
  }




  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "createDateString",
            text: "İşlem Tarihi",
            col: "col-md-2",
          },
          {
            columnName: "isOpen",
            text: "Tesis Faaliyet Durumu",
            col: "col-md-2",
            onRender: (item: { isOpen: any }) => (
              item.isOpen ? "Tesis Açık" : "Tesis Kapalı"
            ),
          },
          {
            columnName: "openingDateString",
            text: "Tesis Açılış Tarihi",
            col: "col-md-2",
          },
          {
            columnName: "closingDateString",
            text: "Tesis Kapanış Tarihi",
            col: "col-md-2",
          },
          {
            columnName: "createdUserName",
            text: "Güncelleyen",
            col: "col-md-2",
          },
          
        ]}
        items={this.props.formData?.hotelDataModal?.dataList || []}
      />
    );
  }




  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.props.formData?.hotelDataModal?.pageCount | 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }
  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getData(selected);
  };
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title={`${this.props.formData.brandName} Faaliyet Geçmişi`}
          subTitle=""
          backUrl="/"
          history={this.props.history}
        />

        {this.props.loading ? <div style={{ textAlign: 'center' }}>Yükleniyor...</div> : this.renderList()}
        {this.renderPager()}
      </div>

    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HotelHistoryListModal);
