import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { showMessage, toggleLoader } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import Notification from "src/services/Notification/NotificationService";
import {
  NotificationGetAllRequestModel,
  NotificationUpdateRequestModel,
} from "src/services/Notification/RequestModel";
import { MessageType } from "./system/types";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function NotificationCount(
  request: NotificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    request["isRead"] = false;
    const NotificationService = new Notification();
    try {
      const result = await NotificationService.getAll(request);
      if (result) {
        dispatch(setFormData("NotificationCount", result.dataCount));
      } else {
        dispatch(setFormData("NotificationCount", 0));
      }
      if (result.success) {
        return result.dataCount;
      }
    } catch (error) {
      dispatch(setFormData("NotificationCount", 0));
    }

    return 0;
  };
}

export function getAll(
  request: NotificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const NotificationService = new Notification();
    const result = await NotificationService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function updateNotification(
  request: NotificationUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const NotificationService = new Notification();
    const result = await NotificationService.setRead(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    } else {
      return false;
    }
  };
}

export function updateNotificationUnRead(
  request: NotificationUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const NotificationService = new Notification();
    const result = await NotificationService.setRead(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Bildirimleri okunmadı yapma işlemi başarı ile gerçekleşti",
          "Tebrikler!",
          MessageType.SUCCESS,
          ""
        )
      );
      return true;
    } else {
      return false;
    }
  };
}

export function getAllNotificationList(
  request: NotificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const LayoverService = new Notification();
    const resultLayover = await LayoverService.getAll(request);
    dispatch(setFormData("NotificationListForExcel", resultLayover));

    dispatch(toggleLoader());
    if (resultLayover.success) {
      return true;
    }
    return false;
  };
}
