import {
  SystemStore,
  SystemActionTypes,
  SHOW_MESSAGE_AT,
  SHOW_MESSAGE,
  MessageType,
  HIDE_MESSAGE,
  TOGGLE_LOADER,
  SET_LANGUAGE,
  TOGGLE_VERIFICATION_LOADER,
  SET_USER_INFO,
  OPEN_TAB,
  CLOSE_TAB,
  CLOSE_ALL_TAB,
  TOGGLE_ADVANCED,
  SET_LEFT_PANEL,
  TOGGLE_CONFIRM,
  TOGGLE_CONFIRM_FILTER,
  TOKEN_CONTROL,
  TOGGLE_CONFIRM_MODAL,
} from "./types";
import _ from "lodash";

const initialState: SystemStore = {
  loading: false,
  verificationLoading: false,
  userInfo: {},
  message: {
    show: false,
    showAt: false,
    messageContent: "",
    windowReload: false,
  },
  language: "en",
  advancedMode: true,
  tabs: [],
  leftPanel: "",
  tokenStatus: false,
  toggleConfirm: {
    show: false,
    text: "",
    callback: () => {},
    reject: () => {},
  },
  toggleConfirmFilter: {
    show: false,
    text: "",
    callback: () => {},
  },
  toggleConfirmModal: {
    show: false,
    text: "",
    title: "",
    callback: () => {},
    reject: () => {},
  },
};

export function systemReducer(
  state = initialState,
  action: SystemActionTypes
): SystemStore {
  switch (action.type) {
    case SHOW_MESSAGE_AT:
      return {
        ...state,
        message: {
          show: false,
          showAt: true,
          messageContent: action.content,
          messageTitle: action.title,
          messageType: action.messageType || MessageType.SUCCESS,
          messageSubTitle: action.messageSubTitle,
          windowReload: action.windowReload || false,
        },
      };
    case SHOW_MESSAGE:
      return {
        ...state,
        message: {
          show: true,
          showAt: false,
          messageContent: action.content,
          messageTitle: action.title,
          messageType: action.messageType || MessageType.SUCCESS,
          messageSubTitle: action.messageSubTitle,
          windowReload: action.windowReload || false,
          resultData: action.resultData || [],
          oldRequest: action.oldRequest,
        },
      };
    case HIDE_MESSAGE:
      return {
        ...state,
        message: {
          showAt: false,
          show: false,
          messageContent: "",
          windowReload: false,
        },
      };
    case TOGGLE_LOADER:
      return {
        ...state,
        loading: !state.loading,
      };
    case TOGGLE_ADVANCED:
      return {
        ...state,
        advancedMode: !state.advancedMode,
      };
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case SET_LEFT_PANEL:
      return {
        ...state,
        leftPanel: action.leftPanel || "",
      };
    case TOGGLE_VERIFICATION_LOADER:
      return {
        ...state,
        verificationLoading: !state.verificationLoading,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    case OPEN_TAB: {
      const tabs = _.cloneDeep(state.tabs);
      const foundIndex = tabs.findIndex(
        (x) => x.id === action.id && x.tabType === action.tabType
      );

      if (foundIndex >= 0) {
        tabs[foundIndex].active = true;
      } else {
        tabs.push({
          active: true,
          id: action.id,
          tabType: action.tabType,
          datasourceId: action.datasourceId,
          reference: action?.reference || {},
        });
      }

      tabs.filter((x) => x.id !== action.id).forEach((x) => (x.active = false));

      return {
        ...state,
        tabs,
      };
    }
    case CLOSE_TAB: {
      const tabs = _.cloneDeep(state.tabs);
      const foundIndex = tabs.findIndex(
        (x) => x.id === action.id && x.tabType === action.tabType
      );

      tabs.splice(foundIndex, 1);

      return {
        ...state,
        tabs,
      };
    }
    case CLOSE_ALL_TAB:
      return {
        ...state,
        tabs: [],
      };
    case TOGGLE_CONFIRM:
      return {
        ...state,
        toggleConfirm: {
          show: action.show,
          text: action.text,
          callback: action.callback,
        },
      };
    case TOGGLE_CONFIRM_MODAL:
      return {
        ...state,
        toggleConfirmModal: {
          show: action.show,
          text: action.text,
          title: action.title,
          callback: action.callback,
          reject: action.reject,
          yesTitle: action.yesTitle,
          noTitle: action.noTitle,
        },
      };
    case TOGGLE_CONFIRM_FILTER:
      return {
        ...state,
        toggleConfirmFilter: {
          show: action.show,
          text: action.text,
          callback: action.callback,
        },
      };
    case TOKEN_CONTROL:
      const currentUrl = window.location.href.split("/");
      if (
        !action.tokenStatus &&
        currentUrl[currentUrl.length - 1] !== "/" &&
        currentUrl[currentUrl.length - 1] !== ""
      ) {
        window.location.href = "/";
      }
      return {
        ...state,
        tokenStatus: action.tokenStatus,
      };
    default:
      return state;
  }
}
