import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  TextField,
  // MessageBar,
  MessageBarType,
  PrimaryButton,
  Dropdown,
  Label,
  Modal,
  Icon,
  DefaultButton,
  Checkbox,
} from "office-ui-fabric-react";
import {
  setFormData,
  setFormErrors,
  initialFormData,
} from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";
import BasicTable from "../BasicTable";
import {
  getById,
  update,
  changeStatus,
  getAllSertificationMA,
} from "src/store/thunkWinterTourism";
import { castThunkAction } from "src/helpers/casting";

import {
  WinterTourismGetByIdRequestModel,
  WinterTourismUpdateRequestModel,
  WinterTourismChangeStatusRequestModel,
  GetWinterTourismCertificateListByWinterTourismModel,
} from "src/services/WinterTourism/RequestModel";
import {
  toggleConfirmDialog,
  toggleConfirmModalDialog,
} from "src/store/system/actions";

import { StatusEnums } from "src/services/BaseEnum";
import { qrUrl, apiConfig } from "src/config/apiConfig";
import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import {
  getAllTy,
  showErrorDialog,
  showChangesFieldDialog,
  showAtDialog,
} from "src/store/thunkCertificateType";

import { getAllHA } from "src/store/thunkHospital";
import { GetHospitalList } from "src/services/Hospital/RequestModel";

import { getAllCompany } from "src/store/thunksCompany";
import _ from "lodash";

import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";
import { CertificateTypeGetAllRequestModel } from "src/services/CertificateType/RequestModel";

import cookie from "react-cookies";

import { FileUpload } from "../FileUpload";

import { RECORD_STATUS } from "src/helpers/enums";
import HospitalModal from "../HospitalModal";
// @ts-ignore

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toDate } from "src/helpers/converts";

import {
  formatSize,
  getChangesFieldGlobal,
  getRecordStatus,
  onSaveText,
  removeArrayItem,
  requestMapStatusData,
} from "src/helpers/functions";
import ActivityStatusOpenCloseDate from "../ActivityStatusOpenCloseDate";
let token = "";

interface OwnProps {
  history: History;
  WinterTourismId: number;
}
function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,

        setFormErrors,
        getAllSertificationMA,
        initialFormData,
        getById,
        getAllM,
        getAllC,
        toggleConfirmModalDialog,
        getAllTy,
        update,
        getAllCompany,
        showErrorDialog,
        showAtDialog,
        showChangesFieldDialog,
        changeStatus,
        getAllHA,
        toggleConfirmDialog,
      },
      dispatch
    ),
  };
}

type CreateWinterTourismProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  messageType: MessageBarType;
  infoMessageGetDocumentNo: string;
  isFileUploading: boolean;
  fileUploadContainer: any;
  changesFieldHtml: any;
  modalshow: boolean;
  modalanswer: boolean;
  modelattachmentId: number;
  modalType: number;
  isHaveHealthKit: boolean;
  onlyCountry: string[];
  isOpenStatus:string;
}
let oldData: any = {};
let usertype: any = 0;
let hasCertificateStatusTypeId = 0;
let hasCorrectionTypeId = 0;

class EditWinterTourism extends Component<CreateWinterTourismProps, OwnState> {
  state = {
    messageType: MessageBarType.warning,
    infoMessageGetDocumentNo: "",
    isFileUploading: false,
    fileUploadContainer: "",
    changesFieldHtml: "",
    modalshow: false,
    modalanswer: false,
    modelattachmentId: 0,
    modalType: 0,
    isHaveHealthKit: this.props.formData.isHaveHealthKit,
    onlyCountry: ["tr"],
    isOpenStatus:"0",
  };

  UNSAFE_componentWillMount() {
    token = cookie.load("userId");
    this.props.initialFormData({
      status: StatusEnums.Published,
    });
    usertype = cookie.load("systemUserInfo");

    if (this.props.WinterTourismId) {
      castThunkAction(
        this.props.getById({
          id: this.props.WinterTourismId,
        } as WinterTourismGetByIdRequestModel)
      ).then((response: any) => {
        this.props.setFormData("email2", "");
        this.props.setFormData("phoneNumber2", "");
        this.props.setFormData("managerName2", "");

        hasCertificateStatusTypeId =
          this.props.formData.certificateStatusTypeId;
        hasCorrectionTypeId = this.props.formData.correctionType;
        const request10: GetWinterTourismCertificateListByWinterTourismModel = {
          id: this.props.WinterTourismId,
        };
        this.props.getAllSertificationMA(request10);

        this.renderFileUpload();
        this.props.setFormData(
          "certificateNo",
          this.props.formData?.certificateNo?.slice(
            this.props.formData.certificateNo.indexOf("-") + 1
          )
        );
        oldData = Object.assign(this.props.formData, {});

        // this.props.formData.webSite === ""
        //   ? this.props.setFormData("webSite", null)
        //   : this.props.setFormData("webSite", this.props.formData.webSite);

        const request2: CityGetAllRequestModel = {
          selectItemList: ["CityId", "CityName"],
          orderField: "CityName",
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllM(request2);

        const request7: GetHospitalList = {
          orderField: "HospitalName",
        };
        this.props.getAllHA(request7);

        const request4: CertificateTypeGetAllRequestModel = {
          selectItemList: ["CertificateTypeId", "Title"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllTy(request4);

        this.CityChange(
          this.props.formData?.cityId,
          this.props.formData?.districtId
        );
        this.props.formData.appealDate === 0 &&
          this.props.setFormData("appealDate", null);
        this.props.formData.confirmationDate === 0 &&
          this.props.setFormData("confirmationDate", null);
      });
    }
  }
  componentDidUpdate(nextprops: any) {
    if (this.props.formData.certificateTypeId !== 0) {
      this.setLabelDescription(
        "companyName",
        "<span id='companyName-span' class='descSpan'>Tesis sahibi olan firmanın ticari ünvanı</span>"
      );

      if (this.props.formData.certificateTypeId === 4) {
        this.setLabelDescription(
          "documentNoDesc",
          "<span id='documentNoDesc-span' class='descSpan'>TC Kültür ve Turizm Bakanlığı tarafından tesise verilen çalışma belge numarası</span>"
        );
      }
    }
    if (
      this.props.formData.certificateStatusTypeId === 2 &&
      this.props.formData.prefix
    ) {
      this.setPrefix();
    }
  }
  setPrefix(): any {
    const el = document.createElement("span");
    el.className = "CertificateNo-span prefixSpan";
    el.id = "CertificateNo-span";
    el.innerHTML =
      usertype === "2"
        ? cookie.load("prefix") + "-"
        : this.props.formData.prefix + "-";
    const div = document.getElementById("CertificateNo");

    if (!document.getElementById("CertificateNo-span")) {
      this.insertBefore(div, el);
    } else {
      document.getElementById("CertificateNo-span")?.remove();
      this.insertBefore(div, el);
    }
    return false;
  }
  insertAfter(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  insertBefore(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode);
  }

  componentDidMount() {
    this.setDdlDescription(
      "certificateTypeIdDesc-label",
      "<br/> <span id='certificateTypeIdDesc-label-span' class='descSpan'>Tesisin kayıt edileceği belge tipini seçiniz. Tesise ait <b>T.C Kültür ve Turizm Bakanlığı</b> Belgesi bulunuyorsa bu belge tipini seçip ilgili belgeyi yüklemeniz ve belgeye uygun bilgileri doldurmanız gerekmektedir. Eğer tesise ait bu belge bulunmuyorsa lütfen <b>Diğer</b> seçerek ilerleyeniz.</b></span>"
    );
  }

  setDdlDescription(id: string, message: string) {
    const el = document.createElement("span");
    el.innerHTML = message;
    const div = document.getElementById(id);
    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div, el);
    }
  }

  setLabelDescription(id: string, message: string) {
    const el2 = document.createElement("span");
    el2.innerHTML = message;
    const div2 = document.querySelectorAll('[for="' + id + '"]');

    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div2[0], el2);
    }
  }
  // renderTextByMessageType() {
  // 	switch (this.state.messageType) {
  // 		case MessageBarType.success:
  // 			return "Kaydetme işlemi başarıyla gerçekleşmiştir."
  // 		case MessageBarType.error:
  // 			return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin."
  // 		default:
  // 			return "Lütfen aşağıdaki bilgileri doldurun ve formu oluşturun!"
  // 	}
  // }

  // renderMessageBar() {
  // 	return (
  // 		<MessageBar
  // 			messageBarType={this.state.messageType}
  // 			isMultiline={false}
  // 		>
  // 			{this.renderTextByMessageType()}
  // 		</MessageBar>
  // 	)
  // }

  onSave(isDraft?: boolean) {
    const request: WinterTourismUpdateRequestModel = requestMapStatusData(this.props.formData, isDraft);
    if (
      this.props.formData.attachmentList &&
      this.props.formData.attachmentList.length > 0
    ) {
      request.data["attachmentIdList"] = (
        this.props.formData.attachmentList || []
      ).map((item: any) => item.attachmentId);
    }
    if (
      this.props.formData.attachmentCertificateList &&
      this.props.formData.attachmentCertificateList.length > 0
    ) {
      request.data["attachmentCertificateIdList"] = (
        this.props.formData.attachmentCertificateList || []
      ).map((item: any) => item.attachmentId);
    }
    // hasCertificateStatusTypeId = this.props.formData.certificateStatusTypeId
    // hasIsPublishWeb = this.props.formData.isPublishWeb
    if (this.props.formData.confirmationDate === null) {
      this.props.formData.confirmationDate = 0;
    }
    hasCertificateStatusTypeId = this.props.formData.certificateStatusTypeId;
    hasCorrectionTypeId = this.props.formData.correctionType;

    if (this.props.formData.email2 !== "" && usertype === "2") {
      request.data.email = this.props.formData.email2;
    } else {
      request.data.email = this.props.formData.email;
    }

    if (this.props.formData.phoneNumber2 !== "" && usertype === "2") {
      request.data.phoneNumber = this.props.formData.phoneNumber2;
    } else {
      request.data.phoneNumber = this.props.formData.phoneNumber;
    }

    if (this.props.formData.managerName2 !== "" && usertype === "2") {
      request.data.managerName = this.props.formData.managerName2;
    } else {
      request.data.managerName = this.props.formData.managerName;
    }

    getChangesFieldGlobal(usertype, oldData, this.props.formData).then(
      (x: any) => {
        this.props.setFormData("changesField", JSON.stringify(x));
        request.data["changesField"] = JSON.stringify(x);
        castThunkAction(this.props.update(request)).then((response: any) => {
          this.props.setFormData(
            "initialAttachmentList",
            this.props.formData.attachmentList
          );
          this.props.setFormData(
            "initialAttachmentCertificateList",
            this.props.formData.attachmentCertificateList
          );
          this.setState(
            {
              messageType: response
                ? MessageBarType.success
                : MessageBarType.error,
            },
            () => {
              this.props.setFormData(
                "status",
                isDraft ? StatusEnums.Draft : this.props.formData.status
              );
            }
          );
        });
      }
    );
  }

  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  removeElement(id: any) {
    var elem = document.getElementById(id);
    return elem?.parentNode?.removeChild(elem);
  }
  filterFileds(val: any) {
    if (
      val !== "" &&
      val !== 0 &&
      val !== "0" &&
      val !== null &&
      val !== "null" &&
      val !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  }
  onSubmit(isDraft?: boolean) {
    let desc = "";
    const webSite =
      /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    if (this.props.formData.webSite) {
      if (
        this.props.formData.webSite !== "" &&
        !this.props.formData.webSite &&
        !webSite.test(this.props.formData.webSite)
      ) {
        desc += "Geçersiz Web Sitesi,  <br/>";
      }
    }

    const mail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!mail.test(this.props.formData.email)) {
      desc += "Geçersiz E-posta Adresi,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.certificateTypeId)) {
      desc += "Tesis Belge Tipi, <br/>";
    }
    if (!this.filterFileds(this.props.formData.companyName)) {
      desc += "Belge Sahibi / Ticari Ünvan,  <br/>";
    }
    if (this.props.formData.certificateTypeId === 4) {
      if ((!this.filterFileds(this.props.formData.documentNo)) || this.props.formData.documentNo === "0") {
        desc += "Belge No,  <br/>";
      }
    }

    if (this.props.formData.isOpenStatus === "0") {
      desc += "Tesis Faaliyet Durumu,  <br/>";
    }
    //https://b24-z1nwey.bitrix24.com.tr/workgroups/group/8/tasks/task/view/2042/ madde revize.
    if (this.props.formData.certificateTypeId === 5) {
      this.props.setFormData("documentNo", "");
    }

    if (!this.filterFileds(this.props.formData.brandName)) {
      desc += "Tesis Adı,  <br/>";
    }

    if (!this.filterFileds(this.props.formData.cityId)) {
      desc += "İl,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.districtId)) {
      desc += "İlçe,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.address)) {
      desc += "Tesis Adresi,  <br/>";
    }
    // if(!this.filterFileds(this.props.formData.latitude)){
    //   desc+= "X Koordinatı, <br/>";
    // }
    // if(!this.filterFileds(this.props.formData.longitude)){
    //   desc+= "Y Koordinatı, <br/>";
    // }
    if (!this.filterFileds(this.props.formData.email)) {
      desc += "Tesis E-posta Adresi,  <br/>";
    }
    const phoneControl = /........../;
    if (!phoneControl.test(this.props.formData.phoneNumber)) {
      desc += "Tesis Telefon Numarası,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.managerName)) {
      desc += "Tesis Genel Müdürünün Adı ve Soyadı,  <br/>";
    }
    if (!this.props.formData.vkNo || this.props.formData.vkNo.length < 10) {
      desc += "Vergi numarası 10 hane ve üzeri olmalıdır. <br/>";
    }
    const request = {
      title: "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
      desc: desc,
    };
    if (desc !== "") {
      castThunkAction(this.props.showErrorDialog(request)).then(
        (response: any) => {}
      );
    }

    if (desc === "") {
      const { formData, setFormErrors } = this.props;
      const validationSchema = _yup.object({
        companyName: _yup.string().required("Bu alan zorunludur!"),
        brandName: _yup.string().required("Bu alan zorunludur!"),
        cityId: _yup.string().required("Bu alan zorunludur!"),
        districtId: _yup.string().required("Bu alan zorunludur!"),
        vkNo: _yup.string().required("Bu alan zorunludur!"),

        webSite: _yup
          .string()
          .matches(
            // eslint-disable-next-line
            /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
            "Geçersiz Web Sitesi"
          )
          .notRequired()
          .nullable(),
        email: _yup
          .string()
          .matches(
            // eslint-disable-next-line
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            "Geçersiz E-posta Adresi"
          )
          .notRequired()
          .nullable(),
        phoneNumber: _yup
          .string()
          .required("Bu alan zorunludur!")
          .notRequired()
          .nullable(),
        managerName: _yup.string().required("Bu alan zorunludur!"),
        documentNo:
          this.props.formData.certificateTypeId === 4
            ? _yup.string().nullable().required("Bu alan zorunludur!")
            : _yup.string().nullable(),
        address: _yup.string().required("Bu alan zorunludur!"),

      });
      let formErrors = {};
      validationSchema
        .validate(formData, {
          abortEarly: false,
        })
        .then(() => {
          setFormErrors({});
          this.onSave(isDraft);
        })
        .catch((err) => {
          err.inner.forEach((e: any) => {
            const { path, message } = e;
            formErrors[path] = message;
          });
          setFormErrors(formErrors);
        });
    }
  }

  CityChange(item: any, districtId?: any) {
    const request2: CountyGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "cityId",
          value: item,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
      selectItemList: ["DistrictId", "DistrictName"],
      orderField: "DistrictName",
    };
    castThunkAction(this.props.getAllC(request2)).then((response: any) => {
      this.props.setFormData("districtId", districtId);
    });
  }

  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Seçiniz" });
    }

    return newArray;
  }

  renderFileUpload() {
    let fileuploadStatus = false;
    hasCorrectionTypeId === 1
      ? (fileuploadStatus = true)
      : usertype === "2" && hasCertificateStatusTypeId === 2
      ? (fileuploadStatus = false)
      : (fileuploadStatus = true);

    if (fileuploadStatus) {
      this.setState({
        ...this.state,
        fileUploadContainer: (
          <FileUpload
            onProcessStartFile={() => {
              this.setState({
                isFileUploading: true,
              });
            }}
            onProcessEndFile={(queue: boolean) => {
              if (!queue) {
                this.setState({
                  isFileUploading: false,
                });
              }
            }}
            onTransferedFile={(file: any, promise?: boolean) => {
              this.props.setFormData("attachmentList", [
                ...(this.props.formData?.attachmentList || []),
                file.attachmentIdList[0],
              ]);
            }}
            requestKey="files"
            token={token}
            endpointUrl={apiConfig.prefixes.attachment + "UploadFile"}
          />
        ),
      });
    }
  }

  renderForm() {
    const { formData, formErrors, setFormData } = this.props;

    return (
      <div className="form-groups row">
        <div className="form-group col-md-3" style={{ paddingBottom: 0 }}>
          <div className="form-wrapper">
            <div className="item row">
              <Dropdown
                disabled={
                  usertype === "2" &&
                  this.props.formData.correctionType !== 1 &&
                  this.props.formData.correctionType !== null &&
                  this.props.formData.correctionType !== 0
                    ? true
                    : false
                }
                id="certificateTypeIdDesc"
                selectedKey={this.props.formData.certificateTypeId}
                onChanged={(event: any) =>
                  setFormData("certificateTypeId", event.key)
                }
                errorMessage={formErrors.certificateTypeId}
                label="Tesis Belge Tipi"
                options={(this.props.formData.GroupListTY?.dataList
                  ? this.returnOptions(
                      this.props.formData.GroupListTY?.dataList,
                      "CertificateTypeId",
                      "Title"
                    )
                  : ([] as any)
                ).map((item: any) => ({
                  key: item.CertificateTypeId,
                  text: item.Title,
                }))}
                className="custom-dropdown"
              />
            </div>

            {this.props.formData.certificateTypeId === 4 ? (
              <div className="item row">
                <TextField
                  disabled={
                    usertype === "2" &&
                    this.props.formData.correctionType !== 1 &&
                    this.props.formData.correctionType !== null &&
                    this.props.formData.correctionType !== 0
                      ? true
                      : false
                  }
                  id="documentNoDesc"
                  placeholder="Belge No"
                  value={formData.documentNo || ""}
                  label="Belge No "
                  className="custom-textfield border"
                  errorMessage={formErrors.documentNo}
                  onChange={(event: any) =>
                    setFormData("documentNo", event.target.value)
                  }
                />
              </div>
            ) : (
              ""
            )}
            <div className="item row">
              <TextField
                disabled={
                  usertype === "2" &&
                  this.props.formData.correctionType !== 1 &&
                  this.props.formData.correctionType !== null &&
                  this.props.formData.correctionType !== 0
                    ? true
                    : false
                }
                placeholder="Tesis Vergi Numarası"
                value={formData.vkNo || ""}
                label="Tesis Vergi Numarası"
                type="number"
                maxLength={10}
                minLength={10}
                className="custom-textfield border"
                errorMessage={formErrors.vkNo}
                onChange={(event: any) =>
                  setFormData("vkNo", event.target.value)
                }
              />
            </div>
            <div className="form-wrapper">
              <div className="title">
                T.C Kültür ve Turizm Bakanlığı İşletme Belgesi / İşyeri Açma
                Ruhsatı ve Tesise Ait Diğer Resmi Evraklar
                <br />
                <small>
                  Lütfen sadece T.C Kültür ve Turizm Bakanlığı Belgesi, İşyeri
                  Açma Çaıştırma Ruhsatı ve TGA tarafından onaylanmış/talep
                  edilmiş evrakları yükleyiniz.
                </small>
              </div>
              {usertype === "2" && this.props.formData.correctionType === 1 && (
                <FileUpload
                  onProcessStartFile={() => {
                    this.setState({
                      isFileUploading: true,
                    });
                  }}
                  onProcessEndFile={(queue: boolean) => {
                    if (!queue) {
                      this.setState({
                        isFileUploading: false,
                      });
                    }
                  }}
                  onTransferedFile={(file: any, promise?: boolean) => {
                    this.props.setFormData("attachmentList", [
                      ...(this.props.formData?.attachmentList || []),
                      file.attachmentIdList[0],
                    ]);
                  }}
                  requestKey="files"
                  token={token}
                  endpointUrl={apiConfig.prefixes.attachment + "UploadFile"}
                />
              )}
              {usertype !== "2" && (
                <FileUpload
                  onProcessStartFile={() => {
                    this.setState({
                      isFileUploading: true,
                    });
                  }}
                  onProcessEndFile={(queue: boolean) => {
                    if (!queue) {
                      this.setState({
                        isFileUploading: false,
                      });
                    }
                  }}
                  onTransferedFile={(file: any, promise?: boolean) => {
                    this.props.setFormData("attachmentList", [
                      ...(this.props.formData?.attachmentList || []),
                      file.attachmentIdList[0],
                    ]);
                  }}
                  requestKey="files"
                  token={token}
                  endpointUrl={apiConfig.prefixes.attachment + "UploadFile"}
                />
              )}
              {this.props.formData.attachmentList &&
                this.props.formData.attachmentList.length > 0 && (
                  <React.Fragment>
                    <div className="title">Yüklenen Belgeler</div>
                    {JSON.stringify(
                      this.props.formData.initialAttachmentList
                    ) !==
                      JSON.stringify(this.props.formData.attachmentList) && (
                      <div className="file-save-warning">
                        Düzenlemeleri ve yüklenen belgeleri kaydetmek için, sağ
                        üst kısımdan "Kaydet veya Yayımla"ya basın.
                      </div>
                    )}
                    <div className="uploaded-files">
                      {(this.props.formData?.attachmentList || []).map(
                        (
                          item: {
                            createDateString: string;
                            attachmentId: number;
                            attachmentPath: string;
                            fileName: string;
                            fileSize: number;
                          },
                          index: number
                        ) => (
                          <div key={index} className="uploaded-file">
                            <div className="name row">
                              <span className="text col no-padding">
                                {item.fileName}-{item.createDateString}
                                <strong className="size m-left-auto">
                                  {formatSize(item.fileSize)}
                                </strong>
                              </span>
                              <div className="buttons m-left-auto row">
                                <button
                                  onClick={() =>
                                    window.open(
                                      qrUrl + item.attachmentPath,
                                      "_blank"
                                    )
                                  }
                                  className="view"
                                >
                                  <i className="icon-explore"></i> Görüntüle
                                </button>
                                {(usertype === "2" &&
                                  this.props.formData.correctionType !== 1 &&
                                  this.props.formData.correctionType !== null &&
                                  this.props.formData.correctionType !== 0) ||
                                  (usertype !== "5" && (
                                    <button
                                      onClick={() => {
                                        this.setState({
                                          modalshow: true,
                                          modelattachmentId: item.attachmentId,
                                          modalType: 1,
                                        });
                                      }}
                                      className="remove"
                                    >
                                      <i className="icon-close"></i>
                                    </button>
                                  ))}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </React.Fragment>
                )}
            </div>
            {usertype && usertype !== "2" ? (
              <div className="item row">
                <Dropdown
                  disabled={
                    usertype === "2" &&
                    this.props.formData.correctionType !== 1 &&
                    this.props.formData.correctionType !== null &&
                    this.props.formData.correctionType !== 0
                      ? true
                      : false
                  }
                  options={[
                    { key: true, text: "Onaylanmış" },
                    { key: false, text: "Onaylanmamış" },
                  ].map((item: any) => ({
                    key: item.key,
                    text: item.text,
                  }))}
                  label="Tesis Onay Durumu"
                  placeholder="Seçiniz"
                  selectedKey={this.props.formData?.isApproved}
                  onChanged={(event: any) =>
                    setFormData("isApproved", event.key)
                  }
                  calloutProps={{
                    className: "custom-dropdown-callout",
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="form-group col-md-3">
          <div className="form-wrapper">
            <div className="item row">
              <TextField
                disabled={
                  usertype === "2" &&
                  this.props.formData.correctionType !== 1 &&
                  this.props.formData.correctionType !== null &&
                  this.props.formData.correctionType !== 0
                    ? true
                    : false
                }
                id="companyName"
                // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                placeholder="Belge Sahibi / Ticari Ünvan"
                value={formData.companyName || ""}
                label="Belge Sahibi / Ticari Ünvan"
                className="custom-textfield border"
                errorMessage={formErrors.companyName}
                onChange={(event: any) =>
                  setFormData("companyName", event.target.value)
                }
              />
            </div>

            <div className="item row">
              <TextField
                disabled={
                  usertype === "2" &&
                  this.props.formData.correctionType !== 1 &&
                  this.props.formData.correctionType !== null &&
                  this.props.formData.correctionType !== 0
                    ? true
                    : false
                }
                // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                placeholder="Tesis Adı"
                value={formData.brandName || ""}
                label="Tesis Adı"
                className="custom-textfield border"
                errorMessage={formErrors.brandName}
                onChange={(event: any) =>
                  setFormData("brandName", event.target.value)
                }
              />
            </div>

            <div className="item row">
              <Dropdown
                disabled={
                  usertype === "2" &&
                  this.props.formData.correctionType !== 1 &&
                  this.props.formData.correctionType !== null &&
                  this.props.formData.correctionType !== 0
                    ? true
                    : false
                }
                // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                onChanged={(event: any) => (
                  this.CityChange(event.key), setFormData("cityId", event.key)
                )}
                label="İl"
                options={(
                  this.props.formData.GroupList?.dataList || ([] as any)
                ).map((item: any) => ({
                  key: item.CityId,
                  text: item.CityName,
                }))}
                className="custom-dropdown"
                selectedKey={this.props.formData.cityId}
                errorMessage={formErrors.cityId}
              />
            </div>

            <div className="item row">
              <Dropdown
                disabled={
                  usertype === "2" &&
                  this.props.formData.correctionType !== 1 &&
                  this.props.formData.correctionType !== null &&
                  this.props.formData.correctionType !== 0
                    ? true
                    : false
                }
                // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                onChanged={(event: any) => setFormData("districtId", event.key)}
                label="İlçe"
                options={(formData.GroupListC?.dataList || ([] as any)).map(
                  (item: any) => ({
                    key: item.DistrictId,
                    text: item.DistrictName,
                  })
                )}
                className="custom-dropdown"
                selectedKey={this.props.formData.districtId}
                errorMessage={formErrors.districtId}
              />
            </div>

            <div className="item row">
              <TextField
                disabled={
                  usertype === "2" &&
                  this.props.formData.correctionType !== 1 &&
                  this.props.formData.correctionType !== null &&
                  this.props.formData.correctionType !== 0
                    ? true
                    : false
                }
                placeholder="Tesis Adresi"
                value={formData.address || ""}
                label="Tesis Adresi"
                className="custom-textfield border"
                errorMessage={formErrors.address}
                onChange={(event: any) =>
                  setFormData("address", event.target.value)
                }
              />
            </div>
            <div className="item row">
							<TextField
								placeholder="X Koordinatı"
								value={formData.latitude || ""}
								label="X Koordinatı"
                type="number"
								className="custom-textfield border"
								errorMessage={formErrors.latitude}
								onChange={(event: any) => setFormData("latitude", event.target.value)}
							/>
						</div>

						<div className="item row">
							<TextField
								placeholder="Y Koordinatı"
								value={formData.longitude || ""}
								label="Y Koordinatı"
								className="custom-textfield border"
                type="number"
								errorMessage={formErrors.longitude}
								onChange={(event: any) => setFormData("longitude", event.target.value)}
							/>
						</div>

            <div className="item row">
              <TextField
                disabled={
                  usertype === "2" &&
                  this.props.formData.correctionType !== 1 &&
                  this.props.formData.correctionType !== null &&
                  this.props.formData.correctionType !== 0
                    ? true
                    : false
                }
                placeholder="Tesis E-posta Adresi"
                value={formData.email}
                label="Tesis E-posta Adresi"
                className="custom-textfield border"
                errorMessage={
                  usertype !== "2" ? formErrors.email : formErrors.email2
                }
                onChange={(event: any) =>
                  setFormData("email", event.target.value)
                }
              />
            </div>

            <div className="item row">
              <Label>Tesis Telefon Numarası</Label>
              <PhoneInput
                onlyCountries={this.state.onlyCountry}
                country={"tr"}
                disabled={
                  usertype === "2" &&
                  this.props.formData.correctionType !== 1 &&
                  this.props.formData.correctionType !== null &&
                  this.props.formData.correctionType !== 0
                    ? true
                    : false
                }
                placeholder="Tesis Telefon Numarası"
                value={
                  (formData.phoneNumber &&
                  formData.phoneNumber.charAt(0) === "0"
                    ? formData.phoneNumber.substring(1, 20)
                    : formData.phoneNumber) || ""
                }
                onChange={(phone: any) => setFormData("phoneNumber", phone)}
                disableDropdown={true}
                disableCountryCode={true}
                masks={{ tr: "(...) ...-..-.." }}
                inputClass={"customizephone"}
                defaultErrorMessage={formErrors.phoneNumber}
              />
            </div>
          </div>
        </div>

        <div className="form-group col-md-3">
          <div className="form-wrapper">
            <div className="item row">
              <TextField
                disabled={
                  usertype === "2" &&
                  this.props.formData.correctionType !== 1 &&
                  this.props.formData.correctionType !== null &&
                  this.props.formData.correctionType !== 0
                    ? true
                    : false
                }
                placeholder="Tesis Genel Müdürünün Adı ve Soyadı"
                value={formData.managerName}
                label="Tesis Genel Müdürünün Adı ve Soyadı"
                className="custom-textfield border"
                errorMessage={
                  usertype !== "2"
                    ? formErrors.managerName
                    : formErrors.managerName
                }
                onChange={(event: any) =>
                  setFormData("managerName", event.target.value)
                }
              />
            </div>
            <div className="item row">
              <TextField
                disabled={
                  usertype === "2" &&
                  this.props.formData.correctionType !== 1 &&
                  this.props.formData.correctionType !== null &&
                  this.props.formData.correctionType !== 0
                    ? true
                    : false
                }
                placeholder="Tesisin Web Sitesi"
                value={formData.webSite || ""}
                label="Tesisin Web Sitesi"
                className="custom-textfield border"
                errorMessage={formErrors.webSite}
                onChange={(event: any) =>
                  setFormData(
                    "webSite",
                    event.target.value === "" ? null : event.target.value
                  )
                }
              />
            </div>

            {this.props.formData.hospitalModalShow && (
              <div className="modal-overlay">
                <div className="modal">
                  <button
                    className="btn-close"
                    onClick={(x) =>
                      this.props.setFormData("hospitalModalShow", false)
                    }
                  >
                    Kapat
                  </button>
                  <HospitalModal history={this.props.history} />
                </div>
              </div>
            )}
            <div className="item row">
              <PrimaryButton
                onClick={(x) =>
                  this.props.setFormData("hospitalModalShow", true)
                }
                iconProps={{ iconName: "Add" }}
                className=""
                disabled={
                  usertype === "2" &&
                  this.props.formData.correctionType !== 1 &&
                  this.props.formData.correctionType !== null &&
                  this.props.formData.correctionType !== 0
                    ? true
                    : false
                }
                text="Tesisin anlaşmalı olduğu sağlık kuruluşunu ekle"
                style={{ marginTop: "20px", marginBottom: "5px" }}
                styles={{
                  root: {
                    background: "#aea64c",
                    borderColor: "#aea64c",
                  },
                  rootHovered: {
                    background: "#aea64c",
                    borderColor: "#aea64c",
                  },
                  rootDisabled: {
                    background: "#aea64c",
                  },
                }}
              />

              {this.props.formData.hospitalId ? (
                <Label className="hotelName full-width-label">
                  Seçili Sağlık Kuruluşu : {this.props.formData.hospitalName}
                </Label>
              ) : (
                <Label className="hotelName alert full-width-label">
                  Tesisin anlaşmalı olduğu sağlık kuruluşu bulunmuyor.
                </Label>
              )}
            </div>
            <div className="item row">
              <Checkbox
                label="Covid Testi Kiti var."
                checked={formData.isHaveHealthKit}
                disabled={
                  usertype === "2" &&
                  this.props.formData.correctionType !== 1 &&
                  this.props.formData.correctionType !== null &&
                  this.props.formData.correctionType !== 0
                    ? true
                    : false
                }
                onChange={() =>
                  this.props.formData.isHaveHealthKit
                    ? this.props.setFormData("isHaveHealthKit", false)
                    : this.setState({ modalshow: true, modalType: 2 })
                }
              />
            </div>

            <ActivityStatusOpenCloseDate type="edit"/>
          </div>
        </div>
        <div className="form-group col-md-3">
          <div className="form-wrapper">
            <h3>
              Kış Turizmi Mekanik Hatlar Tesisi ile alakalı tüm sertifika
              belgeleri
            </h3>
            <div className="item row">
              <div className="uploaded-files">
                {(
                  this.props.formData?.allVersionAttachmentCertificateList || []
                ).map(
                  (
                    item: {
                      createDateString: string;
                      attachmentId: number;
                      attachmentPath: string;
                      fileName: string;
                      fileSize: number;
                    },
                    index: number
                  ) => (
                    <div key={index} className="uploaded-file">
                      <div className="name row">
                        <span className="text col no-padding">
                          {item.fileName}-{item.createDateString}
                          <strong className="size m-left-auto">
                            {formatSize(item.fileSize)}
                          </strong>
                        </span>
                        <div className="buttons m-left-auto row">
                          <button
                            onClick={() =>
                              window.open(qrUrl + item.attachmentPath, "_blank")
                            }
                            className="view"
                          >
                            <i className="icon-explore"></i> Görüntüle
                          </button>
                          {usertype === "1" && (
                            <button
                              onClick={() => {
                                this.props.toggleConfirmModalDialog(
                                  true,
                                  "Onayla",
                                  "Dosyayı kaldırmak istediğinize emin misin?",
                                  () =>
                                    this.onRemoveAttachmentCertificateList(
                                      index,
                                      item.attachmentId
                                    )
                                );
                              }}
                              className="remove"
                            >
                              <i className="icon-close"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>

        <aside className="col-md-12 row" style={{ padding: 0 }}>
          <div className="form-group col-md-4"></div>
        </aside>
      </div>
    );
  }
  getDate(date: any): any {
    if (date !== 0 && date !== undefined) {
      return toDate(date, "");
    }
  }
  showDialog(req: any): any {
    this.props.showErrorDialog(req);
  }

  renderStatusByType() {
    switch (this.props.formData.status) {
      case StatusEnums.Published:
        return (
          <div className="status">
            Durum: <strong>Yayımda</strong>
          </div>
        );
      case StatusEnums.Draft:
        return (
          <div className="status">
            Durum: <strong>Firma Değişti</strong>
          </div>
        );
      case StatusEnums.Deleted:
        return (
          <div className="status">
            Durum: <strong>Silindi</strong>
          </div>
        );
      case StatusEnums.DraftArchive:
        return (
          <div className="status">
            Durum: <strong>Uygun Görülmedi</strong>
          </div>
        );
      case StatusEnums.PendingApproval:
        return (
          <div className="status">
            Durum: <strong>Sertifika Süresi Bitenler</strong>
          </div>
        );
      case StatusEnums.ActivityStopped:
        return (
          <div className="status">
            Durum: <strong>Faaliyetini Durdurdu</strong>
          </div>
        );
      default:
        return null;
    }
  }
  onChangeStatus(status: StatusEnums) {
    const request: WinterTourismChangeStatusRequestModel = {
      id: this.props.WinterTourismId,
      status: status,
    };
    castThunkAction(this.props.changeStatus(request)).then((response: any) => {
      this.setState(
        {
          messageType: response ? MessageBarType.success : MessageBarType.error,
        },
        () => {
          if (response) this.props.setFormData("status", status);
        }
      );
    });
  }

  onRemoveAttachmentCertificateList = (index: number, attachmentId: number) => {
    let allVersionAttachmentCertificateList =
      this.props.formData?.allVersionAttachmentCertificateList || [];

    let certificateAttachmentIdList =
      this.props.formData?.certificateAttachmentIdList || [];
    if (
      allVersionAttachmentCertificateList &&
      allVersionAttachmentCertificateList.length > 0
    )
      allVersionAttachmentCertificateList = removeArrayItem(
        index,
        allVersionAttachmentCertificateList
      );

    certificateAttachmentIdList.push(attachmentId);

    this.props.setFormData(
      "certificateAttachmentIdList",
      certificateAttachmentIdList
    );
    this.props.setFormData(
      "allVersionAttachmentCertificateList",
      allVersionAttachmentCertificateList
    );
  };

  render() {
    return (
      <div className="page push-all">
        {this.state.modalshow && (
          <Modal className={"warning custom-message-box danger"} isOpen={true}>
            <div className="modal-header row">
              <div className="title">
                {this.state.modalType === 1
                  ? "Dosya Siliyorsunuz Emin misiniz?"
                  : "Tesis dahilinde Covid Testi Kiti bulunduğunu onaylıyor musunuz?"}
              </div>
              <span
                onClick={() => this.setState({ modalshow: false })}
                className="close"
              >
                <Icon iconName="ChromeClose" />
              </span>
            </div>
            <div
              style={{ paddingBottom: 0 }}
              className="description modal-content"
            >
              <div className="modal-indicators">
                {this.state.modalType === 1 && (
                  <DefaultButton
                    style={{ marginTop: 15 }}
                    text="EVET"
                    onClick={() => {
                      let files = _.cloneDeep(
                        this.props.formData?.attachmentList
                      );
                      let findIndex = (files || []).findIndex(
                        (x: any) =>
                          x.attachmentId === this.state.modelattachmentId
                      );
                      if (findIndex !== -1) {
                        files.splice(findIndex, 1);
                      }
                      this.props.setFormData("attachmentList", files);
                      this.setState({ modalshow: false });
                    }}
                  />
                )}
                {this.state.modalType === 2 && (
                  <DefaultButton
                    style={{ marginTop: 15 }}
                    text="EVET"
                    onClick={() => {
                      {
                        this.props.setFormData("isHaveHealthKit", true);
                        this.setState({
                          modalshow: false,
                          isHaveHealthKit: true,
                        });
                      }
                    }}
                  />
                )}
                {this.state.modalType === 1 && (
                  <DefaultButton
                    style={{ marginTop: 15 }}
                    text="İPTAL"
                    onClick={() => this.setState({ modalshow: false })}
                  />
                )}
                {this.state.modalType === 2 && (
                  <DefaultButton
                    style={{ marginTop: 15 }}
                    text="HAYIR"
                    onClick={() => {
                      this.setState({
                        modalshow: false,
                        isHaveHealthKit: false,
                      });
                      setFormData("isHaveHealthKit", false);
                    }}
                  />
                )}
              </div>
            </div>
          </Modal>
        )}

        <PageTitle
          titleRenderer={() => (
            <span className="edit-title">
              <strong>Kış Turizmi Mekanik Hatlar Tesisi Düzenle</strong>
              <strong>{this.props.formData?.title || ""}</strong>
            </span>
          )}
          showBack
          backUrl={
            usertype === "2" ? "/WinterTourismCertification" : "/WinterTourism"
          }
          history={this.props.history}
          subTitle="Değiştirmek istediğiniz alanları aşağıda bulabilirsiniz."
          rightType="create"
          right={
            <FormButtons
              isAbsolute
              onSubmit={() => this.onSubmit()}
              customRenderButtons={() => (
                <React.Fragment>
                  {/* {
								//firma ise
								usertype === "2" ? (
									//belge verildi ise
									hasCertificateStatusTypeId === 2 ?
										// webde yayınlandı ise
										(hasIsPublishWeb ?
											// revize istendi ise koy
											(this.props.formData.correctionType === 1 || this.props.formData.correctionType === 2 ?
												this.getSaveButton()
												// revize istenmedi ise koyma
												: "")
											// webde yayınlanmadı ise
											: this.getSaveButton())
										//belge verilmedi ise koy
										: this.getSaveButton()
								)
									// firma değilse koy
									: this.getSaveButton()
							} */}

                  {this.getSaveButton()}

                  {this.state.isFileUploading && (
                    <div className="tooltip-warning">
                      Bazı dosyalar yükleniyor, lütfen dosyalar yüklenene kadar
                      bekleyiniz.
                    </div>
                  )}

                  {usertype !== "5" && usertype !== "2" ? (
                    <>
                      <Dropdown
                        disabled={
                          usertype === "2" &&
                          this.props.formData.correctionType !== 1 &&
                          this.props.formData.correctionType !== null &&
                          this.props.formData.correctionType !== 0
                            ? true
                            : false
                        }
                        style={{ width: "180px", marginRight: "10px" }}
                        options={RECORD_STATUS}
                        className="custom-dropdown "
                        selectedKey={this.props.formData?.status}
                        onChange={(event: any, option: any) => {
                          this.props.toggleConfirmDialog(
                            true,
                            "Kayıt durumunu değiştirmek istiyor musunuz?",
                            () => this.onChangeStatus(option.key)
                          );
                        }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {this.renderStatusByType()}
                </React.Fragment>
              )}
            />
          }
        />

        {/* {this.renderMessageBar()} */}

        <div className="record-info">
          {this.props.formData.createdUserName && (
            <span>
              Ekleyen <label>{this.props.formData.createdUserName}</label>
            </span>
          )}
          {this.props.formData.updatedUserName &&
            this.props.formData.updatedUserName !== "" && (
              <span>
                Son Güncelleyen
                <label>{this.props.formData.updatedUserName}</label>
              </span>
            )}
        </div>
        <PrimaryButton
          onClick={() => this.onAdditional()}
          className="AdditionalButton"
          styles={{
            rootDisabled: {
              background: "#ececec",
              borderColor: "#ccc !important",
              opacity: 0.5,
            },
          }}
          text="Denetim Raporları"
        />
        {this.renderForm()}
        {usertype !== "2" && this.renderList()}
      </div>
    );
  }

  showChangesField(): any {
    let desc = [];
    const changesField2: any[] = this.props.formData.changesField
      ? JSON.parse(this.props.formData.changesField)
      : [];
    for (let index = 0; index < changesField2.length; index++) {
      desc.push(<span>{changesField2[index].tr} </span>);
    }
    if (desc.length === 0) {
      desc.push(<span>Değişiklik Yok</span>);
    }
    return desc;
  }

  renderList() {
    return (
      <BasicTable
        currentVersion
        columns={[
          {
            columnName: "sequenceId",
            text: "Kayıt No",
            col: "col-md-1",
          },
          {
            columnName: "createdUserName",
            text: "Kayıt Yapan",
            col: "col-md-1",
          },
          {
            columnName: "createDateString",
            text: "Kayıt Tarihi",
            col: "col-md-1",
          },
          {
            columnName: "updatedUserName",
            text: "Güncelleyen",
            col: "col-md-1",
          },
          {
            columnName: "updateDateString",
            text: "Güncelleme Tarihi",
            col: "col-md-1",
          },

          {
            columnName: "companyUserTitle",
            text: "Denetleyici Firma",
            col: "col-md-1",
          },

          {
            columnName: "certificateStatusTypeName",
            text: "Durum",
            col: "col-md-1",
          },

          {
            columnName: "certificateNo",
            text: "Sertifika No",
            col: "col-md-1",
            onRender: (item: {
              certificateNo: any;
              certificateStatusTypeId: any;
            }) => (
              <React.Fragment>
                {item.certificateStatusTypeId === 2 ? item.certificateNo : ""}
              </React.Fragment>
            ),
          },

          {
            columnName: "appealDateString",
            text: "Sertifika Başvuru Tarihi",
            col: "col-md-1",
          },
          {
            columnName: "confirmationDateString",
            text: "Sertifika Düzenleme Tarihi",
            col: "col-md-1",
            onRender: (item: {
              confirmationDateString: any;
              certificateStatusTypeId: any;
            }) => (
              <React.Fragment>
                {item.certificateStatusTypeId === 2
                  ? item.confirmationDateString
                  : ""}
              </React.Fragment>
            ),
          },
          {
            columnName: "status",
            text: "Kayıt Durumu",
            col: "col-md-1",
            onRender: (item: any) => getRecordStatus(item.status),
          },
          {
            columnName: "endId",
            text: "İşlem",
            col: "col-md-1",
            className: "operations",
            onRender: (item: {
              endId: any;
              sequenceId: any;
              Status: StatusEnums;
              currentBySequenceId: boolean;
            }) => (
              <React.Fragment>
                {
                  <>
                    <button onClick={(x) => this.goEdit(item.endId)}>
                      <i className="icon-edit-outline"></i> İncele
                    </button>
                    {item.currentBySequenceId && (
                      <button
                        onClick={(x) =>
                          this.goCertificate(item.endId, item.sequenceId)
                        }
                      >
                        <i className="icon-edit-outline"></i> Sertifikaya Git
                      </button>
                    )}
                  </>
                }
              </React.Fragment>
            ),
          },
        ]}
        items={
          this.props.formData?.WinterTourismSerticationList?.dataList || []
        }
      />
    );
  }
  goEdit(endId: any): any {
    window.open("/WinterTourismCertification/editendId/" + endId);
  }
  goCertificate(endId: any, sequenceId: any): any {
    window.open(
      "/WinterTourismCertification/edit/" + endId + "/sequence/" + sequenceId
    );
  }
  onAdditional() {
    window.open("/Additional/" + this.props.formData.winterTourismId + "/" + 9);
  }
  getSaveButton(): any {
    return (
      usertype !== "5" && (
        <PrimaryButton
          onClick={() => this.onSubmit()}
          disabled={this.state.isFileUploading ? true : false}
          styles={{
            rootDisabled: {
              background: "#ececec",
              borderColor: "#ccc !important",
              opacity: 0.5,
            },
          }}
          iconProps={{ iconName: "CheckMark" }}
          text={onSaveText(this.props.formData.status)}
        />
      )
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditWinterTourism);
