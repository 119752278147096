import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { panels } from "src/utils/constants";
import { Link } from "react-router-dom";
import { History } from "history";
import BasicTable from "../BasicTable";
import {
  TextField,
  Dropdown,
  PrimaryButton,
  Label,
  ComboBox,
} from "office-ui-fabric-react";
import {
  getAll,
  changeStatus,
  getAllPage,
} from "src/store/thunkCertificateAppeal";
import {
  CertificateAppealGetAllRequestModel,
  CertificateAppealChangeStatusRequestModel,
} from "src/services/CertificateAppeal/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";
import { castThunkAction } from "src/helpers/casting";
import { toggleConfirmDialog } from "src/store/system/actions";
import { listCount } from "src/config/otherConfig";
import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import { getAllCR } from "src/store/thunkCertificateStatusType";
import { getCompanyUserList } from "src/store/thunksSystemUser";

import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";
import { getAllMA } from "src/store/thunkMainCategory";
import { MainCategoryGetAllRequestModel } from "src/services/MainCategory/RequestModel";

import { getAllSU } from "src/store/thunkSubCategory";
import { SubCategoryGetAllRequestModel } from "src/services/SubCategory/RequestModel";
import { CertificateStatusTypeGetAllRequestModel } from "src/services/CertificateStatusType/RequestModel";
import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";
import cookie from "react-cookies";
// @ts-ignore
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        getAll,
        changeStatus,
        toggleConfirmDialog,
        getAllM,
        getAllC,
        getAllMA,
        getAllSU,
        getAllCR,
        getAllPage,
        getCompanyUserList,
      },
      dispatch
    ),
  };
}

type CertificateAppealProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  excel: boolean;
  pagerCurrent: number;
  pagerTotal: number;
  download: boolean;
}
let usertype: any = 0;

class CertificateAppeal extends Component<CertificateAppealProps, OwnState> {
  state = {
    filterData: {
      searchKey: "",
      districtId: 0,
      status: 1,
      cityId: 0,
      mainCategoryId: 0,
      subCategoryId: 0,
      isPublishWeb: null,
      certificateStatusTypeId: 0,
      companyUserId: 0,
    },
    pagerCurrent: 0,
    pagerTotal: 0,
    excel: false,
    download: false,
  };

  UNSAFE_componentWillMount() {
    this.getData(this.state.pagerCurrent);
    usertype = cookie.load("systemUserInfo");
  }

  UNSAFE_componentWillUpdate(nextProps: any, nextState: any) {
    if (nextState.pagerTotal !== nextProps.formData.pageCount)
      this.setState({
        ...this.state,
        pagerTotal: this.props.formData.pageCount,
      });
  }
  getData(pagerSelected: number) {
    const request: CertificateAppealGetAllRequestModel = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...this.state.filterData,
    };

    this.setState({ excel: false });
    castThunkAction(this.props.getAll(request)).then((response: any) => {
      const request2: CityGetAllRequestModel = {
        selectItemList: ["CityId", "CityName"],
        orderField: "CityName",
      };

      this.props.getAllM(request2);

      const request3: MainCategoryGetAllRequestModel = {
        criteriaItemList: [
          {
            propertyName: "Status",
            value: 1,
            isOr: false,
            conditionOperator: 0,
          },
        ],
        selectItemList: ["MainCategoryId", "Title"],
      };
      this.props.getAllMA(request3);

      const request4: CertificateStatusTypeGetAllRequestModel = {
        criteriaItemList: [
          {
            propertyName: "Status",
            value: 1,
            isOr: false,
            conditionOperator: 0,
          },
        ],
        selectItemList: ["CertificateStatusTypeId", "Title"],
      };
      this.props.getAllCR(request4);

      const request6: SystemUserGetAllModel = {
        systemUserType: 2,
      };
      this.props.getCompanyUserList(request6);

      this.state.filterData.cityId !== 0 &&
        this.CityChange(this.state.filterData.cityId);
      this.state.filterData.mainCategoryId !== 0 &&
        this.CategoryChange(this.state.filterData.mainCategoryId);
    });
  }

  onDelete(CertificateAppealId: number) {
    const request: CertificateAppealChangeStatusRequestModel = {
      id: CertificateAppealId,
      status: StatusEnums.Deleted,
    };
    castThunkAction(this.props.changeStatus(request)).then((response: any) => {
      this.getData(this.state.pagerCurrent);
    });
  }

  renderDashboardItems() {
    return (
      <div className="dashboard-items row">
        {panels.map(
          (item, index) =>
            item.children && (
              <React.Fragment>
                <div className="title">
                  {item.text}
                  <div className="title-text">{item.subText}</div>
                </div>
                <div className="childrens row">
                  {item.children.map((children, childrenIndex) => (
                    <div key={index} className={`dashboard-item col-md-3`}>
                      <Link
                        className="row"
                        to={children.url ? children.url : "/"}
                      >
                        <div className="icon">
                          <i className={children.icon}></i>
                        </div>
                        <div className="title">
                          {children.text}
                          <div className="sub-text">{children.subText}</div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )
        )}
      </div>
    );
  }
  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Tümünü Seç" });
    }

    return newArray;
  }

  CityChange(item: any) {
    this.setState(
      {
        filterData: {
          ...this.state.filterData,
          cityId: item,
        },
      },
      () => {
        const request2: CountyGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "cityId",
              value: item,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
            {
              propertyName: "Status",
              value: 1,
              isOr: false,
              conditionOperator: 0,
            },
          ],
          selectItemList: ["DistrictId", "DistrictName"],
          orderField: "DistrictName",
        };
        this.props.getAllC(request2);
      }
    );
  }
  CategoryChange(item: any) {
    this.setState(
      {
        filterData: {
          ...this.state.filterData,
          mainCategoryId: item,
        },
      },
      () => {
        const request2: SubCategoryGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "MainCategoryId",
              value: item,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
            {
              propertyName: "Status",
              value: 1,
              isOr: false,
              conditionOperator: 0,
            },
          ],
          selectItemList: ["SubCategoryId", "SubCategoryName"],
        };
        this.props.getAllSU(request2);
      }
    );
  }

  renderFilter() {
    return (
      <div className="table-filter row">
        <TextField
          className="custom-textfield col-md-8"
          label="Tesis Adı"
          value={this.state.filterData?.searchKey || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                searchKey: event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        <Dropdown
          defaultSelectedKey={this.state.filterData?.cityId || ""}
          onChanged={(event: any) => (
            this.CityChange(event.key),
            this.setState({
              filterData: {
                ...this.state.filterData,
                cityId: event.key,
              },
            })
          )}
          label="İl"
          options={(this.props.formData.GroupList?.dataList
            ? this.returnOptions(
              this.props.formData.GroupList?.dataList,
              "CityId",
              "CityName"
            )
            : ([] as any)
          ).map((item: any) => ({ key: item.CityId, text: item.CityName }))}
          className="custom-dropdown col-md-2"
        />
        <Dropdown
          defaultSelectedKey={this.state.filterData?.districtId || ""}
          onChanged={(event: any) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                districtId: event.key,
              },
            })
          }
          label="İlçe"
          options={(this.props.formData.GroupListC?.dataList
            ? this.returnOptions(
              this.props.formData.GroupListC?.dataList,
              "DistrictId",
              "DistrictName"
            )
            : ([] as any)
          ).map((item: any) => ({
            key: item.DistrictId,
            text: item.DistrictName,
          }))}
          className="custom-dropdown col-md-2"
        />
        <Dropdown
          onChanged={(event: any) => (
            this.CategoryChange(event.key),
            this.setState({
              filterData: {
                ...this.state.filterData,
                mainCategoryId: event.key,
              },
            })
          )}
          defaultSelectedKey={this.state.filterData?.mainCategoryId || ""}
          label="Tesis Türü"
          options={(this.props.formData.GroupListMA?.dataList
            ? this.returnOptions(
              this.props.formData.GroupListMA?.dataList,
              "MainCategoryId",
              "Title"
            )
            : ([] as any)
          ).map((item: any) => ({
            key: item.MainCategoryId,
            text: item.Title,
          }))}
          className="custom-dropdown col-md-2"
        />

        <Dropdown
          defaultSelectedKey={this.state.filterData?.subCategoryId || ""}
          onChanged={(event: any) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                subCategoryId: event.key,
              },
            })
          }
          label="Tesis türü"
          options={(this.props.formData.GroupListSU?.dataList
            ? this.returnOptions(
              this.props.formData.GroupListSU?.dataList,
              "SubCategoryId",
              "SubCategoryName"
            )
            : ([] as any)
          ).map((item: any) => ({
            key: item.SubCategoryId,
            text: item.SubCategoryName,
          }))}
          className="custom-dropdown col-md-2"
        />

        <Dropdown
          options={[
            { key: "", text: "Seçiniz" },
            { key: "false", text: "Hayır" },
            { key: "true", text: "Evet" },
          ].map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-2"
          label="Webde Yayımlanma"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData.isPublishWeb}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isPublishWeb: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={(this.props.formData.GroupListCR?.dataList
            ? this.returnOptions(
              this.props.formData.GroupListCR?.dataList,
              "CertificateStatusTypeId",
              "Title"
            )
            : ([] as any)
          ).map((item: any) => ({
            key: item.CertificateStatusTypeId,
            text: item.Title,
          }))}
          className="custom-dropdown col-md-2"
          label="Sertifika Durumu"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData.certificateStatusTypeId}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                certificateStatusTypeId: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />

        {usertype && usertype !== "2" ? (
          <ComboBox
            options={(this.props.formData.CompanyUserList?.dataList
              ? this.returnOptions(
                this.props.formData.CompanyUserList?.dataList,
                "systemUserId",
                "title"
              )
              : ([] as any)
            ).map((item: any) => ({
              key: item.systemUserId,
              text: item.title,
            }))}
            allowFreeform={true}
            autoComplete="on"
            className="custom-dropdown col-md-2"
            label="Denetleyici Firma"
            placeholder="Seçiniz"
            selectedKey={this.state.filterData.companyUserId}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  companyUserId: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />
        ) : (
          ""
        )}

        <Dropdown
          options={[
            { key: 1, text: "Yayımda" },
            { key: 2, text: "Taslak" },
            { key: 8, text: "Silindi" },
          ]}
          className="custom-dropdown col-md-2"
          label="Kayıt Durumu"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.status || 1}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                status: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />

        <PrimaryButton
          text="Arama Yap"
          className="custom-button"
          onClick={() => this.getData(0)}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />

        {usertype && usertype !== "2" ? this.rederexcel() : ""}
      </div>
    );
  }

  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "cityDistrictName",
            text: "İl / İlçe",
            col: "col-md-1",
          },
          {
            columnName: "companyName",
            text: "Belge Sahibi / Ticari Ünvan",
            col: "col-md-1",
          },

          {
            columnName: "brandName",
            text: "Tesis Adı",
            col: "col-md-1",
          },
          {
            columnName: "mainSubCategoryName",
            text: "Tesis / Tesis türü",
            col: "col-md-2",
          },
          {
            columnName: "companyUserTitle",
            text: "Denetleyici Firma",
            col: "col-md-1",
          },
          {
            columnName: "webSite",
            text: "Tesisin Web Sitesi",
            col: "col-md-1",
            className: "operations",
            onRender: (item: { webSite: any }) => (
              <React.Fragment>
                {item.webSite !== "" &&
                  item.webSite !== "null" &&
                  item.webSite !== null ? (
                  <a
                    href={
                      item.webSite?.includes("http")
                        ? item.webSite
                        : "http://" + item.webSite
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon-edit-outline"></i> Link
                  </a>
                ) : (
                  ""
                )}
              </React.Fragment>
            ),
          },
          {
            columnName: "certificateStatusTypeName",
            text: "Durum",
            col: "col-md-1",
          },
          {
            columnName: "certificateNo",
            text: "Sertifika No",
            col: "col-md-1",
          },
          {
            columnName: "appealDateString",
            text: "Sertifika Başvuru Tarihi",
            col: "col-md-1",
          },
          {
            columnName: "confirmationDateString",
            text: "Sertifika Düzenleme Tarihi",
            col: "col-md-1",
          },
          {
            columnName: "certificateAppealId",
            text: "İşlem",
            col: "col-md-1",
            className: "operations",
            onRender: (item: {
              certificateAppealId: any;
              Status: StatusEnums;
            }) => (
              <React.Fragment>
                <Link
                  to={`/CertificateAppeal/edit/${item.certificateAppealId}`}
                >
                  <i className="icon-edit-outline"></i> Düzenle
                </Link>
                {item.Status !== StatusEnums.Deleted && usertype !== "2" && (
                  <button
                    onClick={() =>
                      this.props.toggleConfirmDialog(true, "Silme işlemini gerçekleştirmek istiyor musunuz?", () =>
                        this.onDelete(item.certificateAppealId)
                      )
                    }
                  >
                    <i className="icon-trash-outline"></i> Sil
                  </button>
                )}
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.dataList || []}
      />
    );
  }
  downloadexcel() {
    this.setState({ download: true });
    const requestexcel: CertificateAppealGetAllRequestModel = {
      ...this.state.filterData,
    };

    castThunkAction(this.props.getAllPage(requestexcel)).then(
      (response: any) => {
        this.setState({ excel: true, download: false });
      }
    );
  }

  rederexcel() {
    return this.state.excel ? (
      <ExcelFile
        filename="Sertifika"
        element={
          <PrimaryButton
            text="Raporu İndir"
            className="custom-button"
            style={{ marginLeft: "15px" }}
            styles={{
              root: {
                fontSize: "11px",
                marginTop: "27px",
                height: "30px",
              },
            }}
          />
        }
      >
        <ExcelSheet
          data={this.props.formData?.excel?.dataList}
          name="Sertifika"
        >
          <ExcelColumn label="İl" value="cityName" />
          <ExcelColumn label="İlçe" value="districtName" />
          <ExcelColumn label="Belge Sahibi" value="companyName" />
          <ExcelColumn label="Tesis Adı" value="brandName" />
          <ExcelColumn label="Tesis Türü" value="mainCategoryName" />
          <ExcelColumn label="Tesis Alt Türü" value="mainSubCategoryName" />
          <ExcelColumn label="Denetleyici Firma" value="companyUserTitle" />
          <ExcelColumn label="Web Sitesi" value="webSite" />
          <ExcelColumn label="Durum" value="certificateStatusTypeName" />
          <ExcelColumn label="Sertifika No" value="certificateNo" />
          <ExcelColumn label="Başvuru Tarihi" value="appealDateString" />
          <ExcelColumn
            label="Sertifika Düzenleme Tarihi"
            value="confirmationDateString"
          />
        </ExcelSheet>
      </ExcelFile>
    ) : (
      <>
        <PrimaryButton
          text="Rapor Oluştur"
          className="custom-button"
          onClick={() => this.downloadexcel()}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
        {this.state.download && (
          <Label style={{ marginLeft: "10px", marginTop: "27px" }}>
            Rapor Oluşturuluyor...
          </Label>
        )}
      </>
    );
  }
  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.state.pagerTotal}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }
  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getData(selected);
  };
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Sertifika Yönetimi"
          subTitle="Yönetilebilecek tüm alanları aşağıdan görüntüleyebilirsiniz."
          backUrl="/"
          showBack
          history={this.props.history}
        />
        <PrimaryButton
          onClick={() => this.props.history.push("/CertificateAppeal/create")}
          iconProps={{ iconName: "Add" }}
          className="absolute-create"
          text="Sertifika Ekle"
          styles={{
            root: {
              background: "#62a98e",
              borderColor: "#5b9e85",
            },
            rootHovered: {
              background: "#5b9e85",
              borderColor: "#5b9e85",
            },
            rootDisabled: {
              background: "#ececec",
            },
          }}
        />

        <Label className="totalRecord">
          Toplam Kayıt Sayısı: {this.props.formData?.dataCount}
        </Label>

        {this.renderFilter()}

        {this.renderList()}
        {this.renderPager()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CertificateAppeal);
