import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import County from "src/services/County/CountyService";
import {
  CountyGetAllRequestModel,
  CountyChangeStatusRequestModel,
  CountyGetByIdRequestModel,
  CountyUpdateRequestModel,
  CountySaveRequestModel,
} from "src/services/County/RequestModel";
import { MessageType } from "./system/types";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllC(
  request: CountyGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const CountyService = new County();
    const result = await CountyService.GetAllOrdered(request);

    if (result.success) {
      const data = result.dataList.map((d: any) => {
        return {
          DistrictId: d.districtId,
          DistrictName: d.districtName,
        };
      });

      dispatch(setFormData("GroupListC", { dataList: data }));

      return true;
    } else {
      dispatch(setFormData("GroupListC", { dataList: [] }));
    }
    return false;
  };
}

export function getAll(
  request: CountyGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const countyService = new County();
    const result = await countyService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: CountySaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const countyService = new County();
    const result = await countyService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "İlçe Yönetimi"
        )
      );
      return result;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "İlçe Yönetimi"
        )
      );
      return false;
    }
  };
}
export function update(
  request: CountyUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const countyService = new County();
    const result = await countyService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "İlçe Yönetimi"
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "İlçe Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getById(
  request: CountyGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const countyService = new County();
    const result = await countyService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: CountyChangeStatusRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const countyService = new County();
    const result = await countyService.changeStatus(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "İlçe Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "İlçe Yönetimi"
        )
      );
      return false;
    }
  };
}
