import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage, showMessageAt } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import CertificateType from "src/services/CertificateType/CertificateTypeService";
import {
  CertificateTypeGetAllRequestModel,
  CertificateTypeChangeStatusRequestModel,
  CertificateTypeGetByIdRequestModel,
  CertificateTypeUpdateRequestModel,
  CertificateTypeSaveRequestModel,
} from "src/services/CertificateType/RequestModel";
import { MessageType } from "./system/types";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllTy(
  request: CertificateTypeGetAllRequestModel,
  isHotel = 1
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const CertificateTypeService = new CertificateType();

    const result = await CertificateTypeService.getAll({
      ...request,
      isHotel: isHotel,
    });
    dispatch(setFormData("GroupListTY", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}
// export function getAllForHotel(
//   request: CertificateTypeGetAllRequestModel,
//   isHotel: 1
// ): ThunkResult<Promise<any>> {
//   return async (dispatch) => {
//     const CertificateTypeService = new CertificateType();
//     const result = await CertificateTypeService.getAll({ ...request });
//     dispatch(setFormData("GroupListTY", result));

//     if (result.success) {
//       return true;
//     }
//     return false;
//   };
// }

export function getAll(
  request: CertificateTypeGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CertificateTypeService = new CertificateType();
    const result = await CertificateTypeService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: CertificateTypeSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CertificateTypeService = new CertificateType();
    const result = await CertificateTypeService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Tipi Yönetimi"
        )
      );
      return result;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Tipi Yönetimi"
        )
      );
      return false;
    }
  };
}
export function update(
  request: CertificateTypeUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CertificateTypeService = new CertificateType();
    const result = await CertificateTypeService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Tipi Yönetimi"
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Tipi Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getById(
  request: CertificateTypeGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CertificateTypeService = new CertificateType();
    const result = await CertificateTypeService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: CertificateTypeChangeStatusRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CertificateTypeService = new CertificateType();
    const result = await CertificateTypeService.changeStatus(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Tipi Yönetimi"
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Tipi Yönetimi"
        )
      );
      return false;
    }
  };
}

export function showErrorDialog(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(
      showMessage(
        request.desc,
        request.subDesc || "Lütfen zorunlu alanları doldurun",
        request.messageType || MessageType.ERROR,
        request.title
      )
    );
    return false;
  };
}

export function showAtDialog(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(
      showMessageAt(
        request.desc,
        "Dosya Siliyorsunuz Emin misiniz ?",
        MessageType.ERROR,
        request.title
      )
    );
    return false;
  };
}

export function showDocument(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(
      showMessage(
        request.desc,
        "Belge Silme İşlemini Onaylıyor musunuz? ",
        MessageType.ERROR,
        request.title
      )
    );
    return false;
  };
}

export function showChangesFieldDialog(
  request: any
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(showMessage(request.desc, request.title, MessageType.WARNING, ""));
    return false;
  };
}
