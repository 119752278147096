import { HttpClient } from "src/library/HttpClient";
import {
  LayoverSubCategoryChangeStatusRequestModel,
  LayoverSubCategoryGetByIdRequestModel,
  LayoverSubCategoryUpdateRequestModel,
  LayoverSubCategorySaveRequestModel,
  LayoverSubCategoryGetAllRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class LayoverSubCategory {
  async getAll(request: LayoverSubCategoryGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.LayoverSubCategory + "GetAll",
      { ...request, token }
    );
  }

  async save(request: LayoverSubCategorySaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.LayoverSubCategory + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: LayoverSubCategoryUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.LayoverSubCategory + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(request: LayoverSubCategoryGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.LayoverSubCategory + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(
    request: LayoverSubCategoryChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.LayoverSubCategory + "ChangeStatus",
      { ...request, token }
    );
  }
}
