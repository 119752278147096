import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import Company from "src/services/Company/CompanyService";
import { setFormData } from "./form/actions";
import { GetCompanyByDocumentNoRequest } from "src/services/Company/RequestModel";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllCompany(
  request: GetCompanyByDocumentNoRequest
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const CompanyService = new Company();
    const result = await CompanyService.getAllCompany(request);
    dispatch(setFormData("CompanyInfo", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}
