import { Component } from "react";
import {
  Modal,
  Icon,
  DefaultButton,
  PrimaryButton,
} from "office-ui-fabric-react/lib/index";
import { connect } from "react-redux";
import { RootState } from "src/store/reducer";
import { bindActionCreators, Dispatch } from "redux";
import { hideMessage } from "../store/system/actions";
import { MessageType } from "src/store/system/types";
import { setFormData } from "src/store/form/actions";
import { getAll } from "src/store/thunkHotel";
import { castThunkAction } from "src/helpers/casting";
import { save } from "src/store/thunkHotel";

function mapStateToProps(state: RootState) {
  return {
    show: state.system.message.show,
    messageTitle: state.system.message.messageTitle,
    messageContent: state.system.message.messageContent,
    messageType: state.system.message.messageType,
    messageSubTitle: state.system.message.messageSubTitle,
    windowReload: state.system.message.windowReload,
    resultData: state.system.message.resultData,
    formData: state.form.formData,
    oldRequest: state.system.message.oldRequest,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        getAll,
        hideMessage,
        setFormData,
        save,
      },
      dispatch
    ),
  };
}

class MessageBox extends Component<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> {
  static defaultProps = {
    show: false,
  };

  renderTypeStringByNumber(messageType: number) {
    switch (messageType) {
      case MessageType.ERROR:
        return "error";
      case MessageType.SUCCESS:
        return "success";
      case MessageType.WARNING:
        return "warning";
      default:
        return "";
    }
  }

  goView(hotelId: any): any {
    window.open("/hotel/view/" + hotelId);
  }

  render() {
    const {
      show,
      messageType,
      hideMessage,
      messageTitle,
      messageContent,
      messageSubTitle,
    } = this.props;
    if (!show) {
      return null;
    }
    return (
      <Modal
        className={`warning custom-message-box ${this.props.resultData &&
          this.props.resultData.length > 0 &&
          "benzerTesis"
          } ${this.renderTypeStringByNumber(messageType as number)}`}
        isOpen={true}
        onDismiss={() => hideMessage()}
      >
        <div className="modal-header row">
          <div className="title">
            {messageTitle}
            <div className="sub-title">{messageSubTitle}</div>
          </div>
          <span onClick={() => hideMessage()} className="close">
            <Icon iconName="ChromeClose" />
          </span>
        </div>
        <div style={{ paddingBottom: 0 }} className="description modal-content">
          <div dangerouslySetInnerHTML={{ __html: messageContent }}></div>
          {this.props.resultData &&
            this.props.resultData.length > 0 &&
            this.props.resultData.map((x: any) => {
              return (
                <div className="table-list">
                  <div className="table-head row">
                    <div className="list-th col-md-1">Tesis ID No</div>
                    <div className="list-th col-md-2">Tesis İli</div>
                    <div className="list-th col-md-2">Tesis İlçesi</div>
                    <div className="list-th col-md-2">Tesis Adı</div>
                    <div className="list-th col-md-2">
                      Belge Sahibi / Ticari Ünvan
                    </div>
                    <div className="list-th col-md-1">Detay Görüntüle</div>
                    <div className="list-th col-md-2">Tesis Seç</div>
                  </div>
                  <div className="table-body">
                    <div className="item row">
                      <div className="list-td row col-md-1">{x.hotelId}</div>
                      <div className="list-td row col-md-2">{x.cityName}</div>
                      <div className="list-td row col-md-2">
                        {x.discritName}
                      </div>
                      <div className="list-td row col-md-2">{x.brandName}</div>
                      <div className="list-td row col-md-2">
                        <div
                          style={{
                            width: "25%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {x.companyName}
                        </div>
                      </div>
                      <div className="list-td row col-md-1">
                        <button onClick={(y) => this.goView(x.hotelId)}>
                          <i className="icon-edit-outline"></i> Detay
                        </button>
                      </div>
                      <div className="list-td row col-md-2">
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          onClick={() =>
                            x.isCertificateBelong
                              ? ""
                              : x.isSelectable
                                ? "" : x.isBlocked ? ""
                                  : this.setSelect(x)
                          }
                        >
                          <i className="icon-trash-outline"></i>
                          {x.isCertificateBelong ? (
                            <span
                              style={{ color: "#c04b4b", fontWeight: "bold" }}
                            >
                              Firmanız tarafından sertifikası mevcut
                            </span>
                          ) : x.isSelectable ? (
                            <span
                              style={{ color: "#c04b4b", fontWeight: "bold" }}
                            >
                              Başka Firma Tarafından Sertifikalı
                            </span>
                          ) : x.isBlocked ? <span
                            style={{ color: "#c04b4b", fontWeight: "bold" }}
                          >
                            Uygunsuzluk nedeni ile tesis blokelidir, seçilemez.
                          </span> : (
                            <span
                              style={{
                                background: "rgb(174, 166, 76)",
                                color: "#fff",
                                borderRadius: "3px",
                                padding: "4px",
                              }}
                            >
                              Tesisi Seç
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          <div className="modal-indicators">
            {this.props.resultData && this.props.resultData.length > 0 && (
              <PrimaryButton
                text="Yeni Tesis Oluşturmaya Devam Et"
                className="custom-button"
                onClick={() =>
                  castThunkAction(
                    this.props.save({
                      data: {
                        ...this.props.oldRequest.data,
                        isImportantSave: true,
                      },
                    })
                  ).then((response: any) => {
                    window.location.href =
                      "/HotelCertification/create/" + response.data.id;
                  })
                }
                style={{ marginLeft: "15px" }}
                styles={{
                  root: {
                    fontSize: "11px",
                    marginTop: "27px",
                    height: "30px",
                  },
                }}
              />
            )}
            <DefaultButton
              style={{ marginTop: 15 }}
              text="TAMAM"
              onClick={() =>
                this.props.windowReload
                  ? window.location.reload()
                  : hideMessage()
              }
            />
          </div>
        </div>
      </Modal>
    );
  }
  setSelect(x: { hotelId: any; brandName: any }): void {
    this.props.setFormData("hotelId", x.hotelId);
    this.props.setFormData("hotelName", x.brandName);
    window.open("/HotelCertification/create/" + x.hotelId);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageBox);
