import { StatusEnums } from "../BaseEnum";

export interface CitySaveDataModel {
  title: string;
  cityName: string;
  status: StatusEnums;
}
export interface CitySaveRequestModel {
  data: CitySaveDataModel;
}

export interface CityUpdateDataModel {
  cityId: number;
  title: string;
  cityName: string;
  status: StatusEnums;
}

export interface CityUpdateRequestModel {
  data: CityUpdateDataModel;
}

export interface CityChangeStatusRequestModel {
  status: StatusEnums;
  id: number;
}

export enum ConditionOperatorEnum {
  Eq = 0,
  NotEq = 1,
  Like = 2,
  Gt = 3,
  Lt = 4,
  Ge = 5,
  Le = 6,
}

export interface criteriaItemListData {
  propertyName: string;
  value: any;
  isOr: boolean;
  conditionOperator: ConditionOperatorEnum;
}

export interface CityGetAllRequestModel {
  criteriaItemList?: criteriaItemListData[];
  selectItemList?: string[];
  pageSize?: number;
  pageIndex?: number;
  orderField?: string;
  isDescending?: boolean;
  status?: StatusEnums;
}
export interface CityGetByIdRequestModel {
  id: number;
}

export interface CityChangeStatusRequestModel {
  id: number;
  status: StatusEnums;
}
