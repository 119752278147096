import { StatusEnums } from "../BaseEnum";

export interface LayoverFacilityTypeSaveDataModel {
  layoverFacilityTypeName: string;
  layoverFacilityTypeName_En: string;
  layoverFacilityTypeName_Ru: string;
  layoverFacilityTypeName_De: string;
  subCategoryId: number;
  isMinistry: boolean;
  status: StatusEnums;
}
export interface LayoverFacilityTypeSaveRequestModel {
  data: LayoverFacilityTypeSaveDataModel;
}

export interface LayoverFacilityTypeUpdateDataModel {
  layoverFacilityTypeId: number;
  layoverFacilityTypeName: string;
  layoverFacilityTypeName_En: string;
  layoverFacilityTypeName_Ru: string;
  layoverFacilityTypeName_De: string;
  subCategoryId: number;
  isMinistry: boolean;
  status: StatusEnums;
}

export interface LayoverFacilityTypeUpdateRequestModel {
  data: LayoverFacilityTypeUpdateDataModel;
}

export interface LayoverFacilityTypeChangeStatusRequestModel {
  status: StatusEnums;
  id: number;
}

export enum ConditionOperatorEnum {
  Eq = 0,
  NotEq = 1,
  Like = 2,
  Gt = 3,
  Lt = 4,
  Ge = 5,
  Le = 6,
}

export interface criteriaItemListData {
  propertyName: string;
  value: any;
  isOr: boolean;
  conditionOperator: ConditionOperatorEnum;
}

export interface LayoverFacilityTypeGetAllRequestModel {
  criteriaItemList?: criteriaItemListData[];
  selectItemList?: string[];
  pageSize?: number;
  pageIndex?: number;
  orderField?: string;
  isDescending?: boolean;
  status?: StatusEnums;
}
export interface LayoverFacilityTypeGetByIdRequestModel {
  id: number;
}

export interface LayoverFacilityTypeChangeStatusRequestModel {
  id: number;
  status: StatusEnums;
}
