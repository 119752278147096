import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { panels } from "src/utils/constants";
import { Link } from "react-router-dom";
import { History } from "history";
import BasicTable from "../BasicTable";
import {
  TextField,
  Dropdown,
  PrimaryButton,
  ComboBox,
} from "office-ui-fabric-react";
import {
  changeStatus,
  getAllCongress,
  getAllCongressForExcel,
  getAllForPager,
} from "src/store/thunkCongress";
import {
  GetCongressListModel,
  CongressChangeStatusRequestModel,
} from "src/services/Congress/RequestModel";
import { setFormData } from "src/store/form/actions";
import { StatusEnums } from "src/services/BaseEnum";
import { castThunkAction } from "src/helpers/casting";
import {
  toggleConfirmDialog,
  toggleConfirmFilterRemoveDialog,
} from "src/store/system/actions";
import { listCount } from "src/config/otherConfig";
import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import { getAllCR } from "src/store/thunkCertificateStatusType";

import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";

import { getAllLSU } from "src/store/thunkCongressSubCategory";

import cookie from "react-cookies";

import { CongressSubCategoryGetAllRequestModel } from "src/services/CongressSubCategory/RequestModel";

import { CertificateStatusTypeGetAllRequestModel } from "src/services/CertificateStatusType/RequestModel";
import {
  cityReturnOptions,
  filterModal,
  isNumberValue,
} from "src/helpers/functions";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        getAllCongress,
        changeStatus,
        toggleConfirmDialog,
        getAllM,
        getAllC,
        getAllLSU,
        getAllCR,
        toggleConfirmFilterRemoveDialog,
        getAllCongressForExcel,
        getAllForPager,
        setFormData,
      },
      dispatch
    ),
  };
}

type CongressProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  download: boolean;
  excel: boolean;
  showfilter:boolean
}

const initialState = {
  filterData: {
    isApproved: null,
    searchKey: "",
    districtId: 0,
    status: 1,
    cityId: 0,
    congressSubCategoryId: 0,
    companyUserId: 0,
    correctionType: null,
    orderField: "UpdatedSystemUserDatetime",
    isDescending: true,
    CongressId: 0,
    documentNo: null,
    isHaveBusinessCertificate: null,
    certificateStatusTypeId: 0,
    certificateTypeId: 0,
    showfilter:true
  },
  pagerCurrent: 0,
  download: false,
  excel: false,
};
let usertype: any = 0;

class Congress extends Component<CongressProps, OwnState> {
  state: any = initialState;

  componentDidMount() {
    filterModal(this, "CongressListState").finally(() =>
      this.getData(this.state.pagerCurrent)
    );
    usertype = cookie.load("systemUserInfo");
  }

  getData(pagerSelected: number) {
    const request: GetCongressListModel = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...this.state.filterData,
    };

    request["isPublishQr"] =
      request["isPublishQr"] === "1"
        ? true
        : request["isPublishQr"] === "2"
        ? false
        : null;

    castThunkAction(this.props.getAllCongress(request)).then(
      (response: any) => {
        const request2: CityGetAllRequestModel = {
          selectItemList: ["CityId", "CityName"],
          orderField: "CityName",
        };

        this.props.getAllM(request2);

        const request3: CongressSubCategoryGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "Status",
              value: 1,
              isOr: false,
              conditionOperator: 0,
            },
          ],
          selectItemList: ["CongressSubCategoryId", "CongressSubCategoryName"],
        };
        this.props.getAllLSU(request3);
        const requestCr: CertificateStatusTypeGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "Status",
              value: 1,
              isOr: false,
              conditionOperator: 0,
            },
          ],
          selectItemList: ["CertificateStatusTypeId", "Title"],
        };
        this.props.getAllCR(requestCr);

        this.state.filterData.cityId !== 0 &&
          this.state.filterData.cityId !== null &&
          this.CityChange(this.state.filterData.cityId);
      }
    );
  }

  getDataPager(pagerSelected: number) {
    this.setState({ excel: false });
    const request: GetCongressListModel = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...this.state.filterData,
    };

    request["isPublishQr"] =
      request["isPublishQr"] === "1"
        ? true
        : request["isPublishQr"] === "2"
        ? false
        : null;

    this.props.getAllForPager(request);
  }

  onDelete(congressId: number) {
    const request: CongressChangeStatusRequestModel = {
      id: congressId,
      status: StatusEnums.Deleted,
    };
    castThunkAction(this.props.changeStatus(request, true)).then(
      (response: any) => {
        this.getData(this.state.pagerCurrent);
      }
    );
  }

  renderDashboardItems() {
    return (
      <div className="dashboard-items row">
        {panels.map(
          (item, index) =>
            item.children && (
              <React.Fragment>
                <div className="title">
                  {item.text}
                  <div className="title-text">{item.subText}</div>
                </div>
                <div className="childrens row">
                  {item.children.map((children, childrenIndex) => (
                    <div key={index} className={`dashboard-item col-md-3`}>
                      <Link
                        className="row"
                        to={children.url ? children.url : "/"}
                      >
                        <div className="icon">
                          <i className={children.icon}></i>
                        </div>
                        <div className="title">
                          {children.text}
                          <div className="sub-text">{children.subText}</div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )
        )}
      </div>
    );
  }
  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Tümünü Seç" });
    }

    if (
      key === "CertificateStatusTypeId" &&
      newArray.filter((x) => x[key] === 9).length === 0
    ) {
      newArray.push({ [key]: 9, [text]: "Sertifikasız" });
    }

    return newArray;
  }

  cityOnChange(event: any, option: any, value: any) {
    if (value !== undefined) {
      this.CityChange(option.key);
      this.setState({
        filterData: {
          ...this.state.filterData,
          cityId: option.key,
          districtId: 0,
        },
      });
    } else {
      this.setState({
        filterData: {
          ...this.state.filterData,
          cityId: 0,
          districtId: 0,
        },
      });
      this.props.setFormData("GroupListC", []);
    }
  }

  CityChange(item: any) {
    this.setState(
      {
        filterData: {
          ...this.state.filterData,
          cityId: item,
        },
      },
      () => {
        const request2: CountyGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "cityId",
              value: item,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
            {
              propertyName: "Status",
              value: 1,
              isOr: false,
              conditionOperator: 0,
            },
          ],
          selectItemList: ["DistrictId", "DistrictName"],
          orderField: "DistrictName",
        };
        this.props.getAllC(request2);
      }
    );
  }

  renderFilter() {
    return (
      <div className="table-filter row">
        <Dropdown
          options={[
            { key: 0, text: "Tümü" },
            { key: 4, text: "TC Kültür ve Turizm Bakanlığı" },
            { key: 5, text: "Diğer" },
          ]}
          className="custom-dropdown col-md-2"
          label="Tesis Belge Tipi"
          selectedKey={this.state.filterData?.certificateTypeId || 0}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                certificateTypeId: option.key === 2 ? null : option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <TextField
          className="custom-textfield col-md-3"
          label="Tesis Adı"
          value={this.state.filterData?.searchKey || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                searchKey: event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        <TextField
          className="custom-textfield col-md-1"
          label="ID"
          type="text"
          value={this.state.filterData?.CongressId || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                CongressId: isNumberValue(event.target.value),
              },
            });
          }}
        />
        <TextField
          className="custom-textfield col-md-2"
          label="Belge Sahibi / Ticari Ünvan"
          value={this.state.filterData?.companyName || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                companyName:
                  event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        <ComboBox
          selectedKey={
            this.state.filterData && this.state.filterData.cityId
              ? this.state.filterData.cityId
              : ""
          }
          label="İl"
          allowFreeform={true}
          autoComplete="on"
          multiSelect={false}
          className="custom-dropdown col-md-2"
          onChange={(event: any, option: any, value: any) =>
            this.cityOnChange(event, option, value)
          }
          options={(this.props.formData.GroupList?.dataList
            ? cityReturnOptions(
                this.props.formData.GroupList?.dataList,
                "CityId",
                "CityName"
              )
            : ([] as any)
          ).map((item: any) => ({ key: item.CityId, text: item.CityName }))}
        />
        <Dropdown
          defaultSelectedKey={this.state.filterData?.districtId || ""}
          onChanged={(event: any) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                districtId: event.key,
              },
            })
          }
          label="İlçe"
          options={(this.props.formData.GroupListC?.dataList
            ? this.returnOptions(
                this.props.formData.GroupListC?.dataList,
                "DistrictId",
                "DistrictName"
              )
            : ([] as any)
          ).map((item: any) => ({
            key: item.DistrictId,
            text: item.DistrictName,
          }))}
          className="custom-dropdown col-md-2"
        />

        <Dropdown
          defaultSelectedKey={
            this.state.filterData?.CongressSubCategoryId || ""
          }
          onChanged={(event: any) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                CongressSubCategoryId: event.key,
              },
            })
          }
          label="Tesis Kategorisi"
          selectedKey={this.state.filterData?.CongressSubCategoryId}
          options={(this.props.formData.GroupListSU?.dataList
            ? this.returnOptions(
                this.props.formData.GroupListSU?.dataList,
                "CongressSubCategoryId",
                "CongressSubCategoryName"
              )
            : ([] as any)
          ).map((item: any) => ({
            key: item.CongressSubCategoryId,
            text: item.CongressSubCategoryName,
          }))}
          className="custom-dropdown col-md-2"
        />

        <Dropdown
          options={[
            { key: 0, text: "Seçiniz" },
            { key: 1, text: "İşletme Belgesi Yüklü" },
            { key: 2, text: "İşletme Belgesi Yok" },
          ]}
          className="custom-dropdown col-md-2"
          label="İşletme Belgesi"
          placeholder="Seçiniz"
          selectedKey={
            this.state.filterData?.isHaveBusinessCertificate === true
              ? 1
              : this.state.filterData?.isHaveBusinessCertificate === false
              ? 2
              : 0 || null
          }
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isHaveBusinessCertificate:
                  option.key === 1 ? true : option.key === 2 ? false : null,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />

        <Dropdown
          options={[
            { key: "BrandName", text: "Tesis Adı" },
            { key: "CreatedSystemUserDatetime", text: "Oluşturma Tarihi" },
            { key: "UpdatedSystemUserDatetime", text: "Güncelleme Tarihi" },
          ]}
          className="custom-dropdown col-md-2"
          label="Sıralama"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.orderField || null}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                orderField: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={(this.state.filterData.orderField === "BrandName"
            ? [
                { key: false, text: "Baştan Sona" },
                { key: true, text: "Sondan Başa" },
              ]
            : [
                { key: false, text: "Eskiden Yeniye" },
                { key: true, text: "Yeniden Eskiye" },
              ]
          ).map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-2"
          label="Sıralama Türü"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.isDescending}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isDescending: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: StatusEnums.All, text: "Tümü" },
            { key: StatusEnums.Published, text: "Yayımda" },
            { key: StatusEnums.Deleted, text: "Silindi" },
            // { key: StatusEnums.PendingApproval, text: "Sertifika Süresi Bitti" },
            // { key: StatusEnums.DraftArchive, text: "Uygun Görülmedi" },
            // { key: StatusEnums.Draft, text: "Firma Değişti" },
            // { key: StatusEnums.ActivityStopped, text: "Faaliyetini Durdurdu" },
          ]}
          className="custom-dropdown col-md-2"
          label="Kayıt Durumu"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.status || 1}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                status: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />

        <Dropdown
          options={[
            { key: 2, text: "Tümü" },
            { key: 1, text: "Onaylanmış" },
            { key: 0, text: "Onaylanmamış" },
          ]}
          className="custom-dropdown col-md-2"
          label="Tesis Onay Durumu"
          selectedKey={
            this.state.filterData?.isApproved === 0
              ? 0
              : this.state.filterData?.isApproved === 1
              ? 1
              : 2 || 2
          }
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isApproved: option.key === 2 ? null : option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <TextField
          className="custom-textfield col-md-2"
          label="Belge No"
          value={this.state.filterData?.documentNo || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                documentNo: event.target.value !== "" ? event.target.value : 0,
              },
            });
          }}
        />
        <Dropdown
          options={(this.props.formData.GroupListCR?.dataList
            ? this.returnOptions(
                this.props.formData.GroupListCR?.dataList,
                "CertificateStatusTypeId",
                "Title"
              )
            : ([] as any)
          ).map((item: any) => ({
            key: item.CertificateStatusTypeId,
            text: item.Title,
          }))}
          className="custom-dropdown col-md-2"
          label="Sertifika Durumu"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData.certificateStatusTypeId}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                certificateStatusTypeId: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <TextField
          className="custom-textfield col-md-2"
          label="Sertifika No"
          value={this.state.filterData?.certificateNo || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                certificateNo:
                  event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        <PrimaryButton
          text="Arama Yap"
          className="custom-button"
          onClick={() => {
            cookie.save("CongressListState", JSON.stringify(this.state), {
              path: "/",
            });
            this.getDataPager(0);
          }}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
        {this.rederexcel()}

        <PrimaryButton
          text="Tüm Filtreleri Temizle"
          className="custom-button"
          iconProps={{ iconName: "trash" }}
          onClick={() =>
            this.setState(
              {
                ...this.state,
                filterData: initialState.filterData,
              },
              () => {
                cookie.save("CongressListState", JSON.stringify(this.state), {
                  path: "/",
                });
                this.CityChange(0);
                this.getDataPager(0);
              }
            )
          }
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
              background: "#FF4343",
              borderColor: "#FF4350",
            },
          }}
        />
      </div>
    );
  }

  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "congressId",
            text: "ID",
            col: "col-md-1-2",
          },
          {
            columnName: "cityDistrictName",
            text: "İl / İlçe",
            col: "col-md-1-2",
          },
          {
            columnName: "companyName",
            text: "Belge Sahibi / Ticari Ünvan",
            col: "col-md-1-2",
          },

          {
            columnName: "brandName",
            text: "Tesis Adı",
            col: "col-md-1-2",
          },

          {
            columnName: "personCapacityCount",
            text: "Tesis Kapasitesi",
            col: "col-md-1-2",
          },

          {
            columnName: "congressSubCategoryName",
            text: "Tesis Kategorisi",
            col: "col-md-1-2",
          },
          {
            columnName: "phoneNumber",
            text: "Tesis Telefon Numarası",
            col: "col-md-1-2",
            onRender: (item: { phoneNumber: any }) => (
              <React.Fragment>
                {usertype !== "2" && item.phoneNumber}
              </React.Fragment>
            ),
          },

          {
            columnName: "email",
            text: "Tesis E-posta Adresi",
            col: "col-md-1",
            onRender: (item: { email: any }) => (
              <React.Fragment>{usertype !== "2" && item.email}</React.Fragment>
            ),
          },
          {
            columnName: "certificateNo",
            text: "Sertifika No",
            col: "col-md-1-2",
            onRender: (item: {
              certificateNo: any;
              certificateStatusTypeId: any;
            }) => (
              <React.Fragment>
                {item.certificateStatusTypeId === 2 ? item.certificateNo : ""}
              </React.Fragment>
            ),
          },
          {
            columnName: "certificateStatusTypeName",
            text: "Sertifika Durumu",
            col: "col-md-1-2",
          },
          {
            columnName: "confirmationDateString",
            text: "Sertifika Düzenleme Tarihi",
            col: "col-md-1-2",
          },
          {
            columnName: "extendedClosedDateString",
            text: "Sertifika Geçerlilik Tarihi",
            col: "col-md-1",
          },
          {
            columnName: "CongressId",
            text: "İşlem",
            col: "col-md-1-2",
            className: "operations",
            onRender: (item: { congressId: any; status: StatusEnums }) => (
              <React.Fragment>
                {
                  <button onClick={(x) => this.goEdit(item.congressId)}>
                    <i className="icon-edit-outline"></i> Düzenle
                  </button>
                }
                {usertype !== "5" &&
                  item.status !== StatusEnums.Deleted &&
                  usertype !== "2" && (
                    <button
                      onClick={() =>
                        this.props.toggleConfirmDialog(
                          true,
                          "Silme işlemini gerçekleştirmek istiyor musunuz?",
                          () => this.onDelete(item.congressId)
                        )
                      }
                    >
                      <i className="icon-trash-outline"></i> Sil
                    </button>
                  )}
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.dataList || []}
      />
    );
  }

  rederexcel() {
    return (
      <>
        <PrimaryButton
          text="Rapor Oluştur ve İndir"
          className="custom-button"
          onClick={() => this.downloadexcel()}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
      </>
    );
  }

  downloadexcel() {
    this.setState({ download: true });
    const requestexcel: GetCongressListModel = {
      ...this.state.filterData,
    };

    castThunkAction(this.props.getAllCongressForExcel(requestexcel));
  }
  renderListFirmaIcin() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "cityDistrictName",
            text: "İl / İlçe",
            col: "col-md-1",
          },
          {
            columnName: "companyName",
            text: "Belge Sahibi / Ticari Ünvan",
            col: "col-md-1",
          },

          {
            columnName: "brandName",
            text: "Tesis Adı",
            col: "col-md-1",
          },

          {
            columnName: "personCapacityCount",
            text: "Tesis Kapasitesi",
            col: "col-md-1",
          },

          {
            columnName: "congressSubCategoryName",
            text: "Tesis Kategorisi",
            col: "col-md-1",
          },

          {
            columnName: "certificateStatusTypeName",
            text: "Sertifika Durumu",
            col: "col-md-2",
          },
          {
            columnName: "confirmationDateString",
            text: "Sertifika Düzenleme Tarihi",
            col: "col-md-1",
          },
          {
            columnName: "extendedClosedDateString",
            text: "Sertifika Geçerlilik Tarihi",
            col: "col-md-1",
          },
          {
            columnName: "CongressId",
            text: "İşlem",
            col: "col-md-1",
            className: "operations",
            onRender: (item: {
              CongressId: any;
              Status: StatusEnums;
              isCertificateBelong: boolean;
            }) => (
              <React.Fragment>
                {item.isCertificateBelong && (
                  <button onClick={(x) => this.goEdit(item.CongressId)}>
                    <i className="icon-edit-outline"></i> Düzenle
                  </button>
                )}
                {item.Status !== StatusEnums.Deleted && usertype !== "2" && (
                  <button
                    onClick={() =>
                      this.props.toggleConfirmDialog(
                        true,
                        "Silme işlemini gerçekleştirmek istiyor musunuz?",
                        () => this.onDelete(item.CongressId)
                      )
                    }
                  >
                    <i className="icon-trash-outline"></i> Sil
                  </button>
                )}
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.dataList || []}
      />
    );
  }

  goEdit(CongressId: any): any {
    window.open("/Congress/edit/" + CongressId);
  }

  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.props.formData.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }
  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getDataPager(selected);
  };
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Kongre ve Sanat Tesisi Yönetimi"
          subTitle="Yönetilebilecek tüm alanları aşağıdan görüntüleyebilirsiniz."
          backUrl="/"
          showBack
          history={this.props.history}
        />
        {usertype !== "5" && (
          <PrimaryButton
            onClick={() => this.props.history.push("/Congress/create")}
            iconProps={{ iconName: "Add" }}
            className="absolute-create"
            text="Kongre ve Sanat Tesisi Ekle"
            styles={{
              root: {
                background: "#62a98e",
                borderColor: "#5b9e85",
              },
              rootHovered: {
                background: "#5b9e85",
                borderColor: "#5b9e85",
              },
              rootDisabled: {
                background: "#ececec",
              },
            }}
          />
        )}
{
         window.innerWidth < 1100 && (
           <PrimaryButton
             text={this.state.showfilter ? "Filtreyi Gizle" : "Filtreyi Göster"}
             className="custom-button"
             onClick={() => (
               this.setState({ showfilter: !this.state.showfilter })
             )}
             styles={{  
               root: {
                 fontSize: "11px",
                 marginTop: "27px",
                 height: "30px",
               },
             }}
           />
         )
       }
       { window.innerWidth < 1100 ? (this.state.showfilter && this.renderFilter()):this.renderFilter()}
        {usertype === "2" ? this.renderListFirmaIcin() : this.renderList()}
        {this.renderPager()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Congress);
