import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import SystemUserService from "src/services/SystemUser/SystemUserService";
import {
  SystemUserChangeStatusModel,
  SystemUserUpdateRequestModel,
  SystemUserSaveRequestModel,
  SystemUserGetByIdRequestModel,
  SystemUserGetAllModel,
  SystemUserChangePasswordRequestModel,
} from "src/services/SystemUser/RequestModel";
import { MessageType } from "./system/types";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

/** Sistem Kullanıcıları Listesini Döner. */
export function getSystemUserList(
  request: SystemUserGetAllModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const systemUserService = new SystemUserService();
    const result = await systemUserService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    return false;
  };
}

export function getSystemUserDropdownList(
  request: SystemUserGetAllModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const systemUserService = new SystemUserService();
    const result = await systemUserService.getAll(request);
    if (result.success) {
      dispatch(setFormData("userList", result.dataList));
    } else {
      dispatch(setFormData("userList", []));
    }
    dispatch(toggleLoader());
    return false;
  };
}

/** Sistem Kullanıcıları  Listesi içerisinden, ilgili ID'ye ait kaydı siler*/
export function onChangeSystemUserStatus(
  request: SystemUserChangeStatusModel
): ThunkResult<Promise<boolean>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const systemUserService = new SystemUserService();
    const result = await systemUserService.changeStatus(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sistem Kullanıcıları Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sistem Kullanıcıları Yönetimi"
        )
      );
    }
    return false;
  };
}

/** Sistem Kullanıcıları  Listesine yeni bir ekleme yapar veya düzenler. */
export function UpdateSystemUser(
  request: SystemUserUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const systemUserService = new SystemUserService();
    const result = await systemUserService.saveOrUpdate(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sistem Kullanıcıları Yönetimi",
          true
        )
      );
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sistem Kullanıcıları Yönetimi"
        )
      );
    }
    return result;
  };
}

export function saveOrUpdateSystemUser(
  request: SystemUserSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const systemUserService = new SystemUserService();
    const result = await systemUserService.saveOrUpdate(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sistem Kullanıcıları Yönetimi"
        )
      );
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sistem Kullanıcıları Yönetimi"
        )
      );
    }
    return result;
  };
}

/** Sistem Kullanıcılarını  ID'ye göre ilgili Sistem Kullanıcısını döner.  */
export function getSystemUserById(
  request: SystemUserGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const systemUserService = new SystemUserService();
    const result = await systemUserService.getById(request);

    if (result.success) {
      const emailList = [];

      if (
        result.data.email &&
        result.data.email.length > 0 &&
        result.data.emailAddressList.indexOf(result.data.email)
      ) {
        emailList.push(result.data.email);
      }

      if (
        result.data.emailAddressList &&
        result.data.emailAddressList.length > 0
      ) {
        result.data.emailAddressList.map((email: string) => {
          emailList.push(email);
        });
      }

      dispatch(
        initialFormData({
          ...result.data,
          changesHistory:
            result.data &&
            result.data.changesHistory &&
            result.data.changesHistory.length > 0
              ? result.data.changesHistory
              : [],
          emailAddressList: emailList,
          password: null,
        })
      );
    }

    dispatch(toggleLoader());
    return result;
  };
}

/** Sistem Kullanıcıları  Listesine yeni bir ekleme yapar veya düzenler. */
export function ChangePassword(
  request: SystemUserChangePasswordRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const systemUserService = new SystemUserService();
    const result = await systemUserService.ChangePassword(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Şifre güncellendi.",
          "İşlem Tamamlandı",
          MessageType.SUCCESS,
          "Sistem Kullanıcıları Yönetimi"
        )
      );
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sistem Kullanıcıları Yönetimi"
        )
      );
    }
    return result;
  };
}

export function getCompanyUserList(
  request: SystemUserGetAllModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const systemUserService = new SystemUserService();
    const result = await systemUserService.getAll(request);
    dispatch(setFormData("CompanyUserList", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}
