import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import Restaurant from "src/services/Restaurant/RestaurantService";
import {
  RestaurantGetAllRequestModel,
  RestaurantChangeStatusRequestModel,
  RestaurantGetByIdRequestModel,
  RestaurantUpdateRequestModel,
  RestaurantSaveRequestModel,
} from "src/services/Restaurant/RequestModel";
import { MessageType } from "./system/types";
import { errorMessageSaveOrUpdate } from "src/helpers/functions";
import { formatDate } from "src/helpers/converts";
import { HotelCertificationGetAllRequestModel } from "src/services/HotelCertification/RequestModel";
import { ILeaveCertificationProcessRequest } from "src/services/BaseEnum";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllPage(
  request: RestaurantGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const RestaurantService = new Restaurant();
    const result = await RestaurantService.getAll(request);
    dispatch(setFormData("excel", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: RestaurantGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const RestaurantService = new Restaurant();
    const result = await RestaurantService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: RestaurantSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const RestaurantService = new Restaurant();
    const result = await RestaurantService.save(request);
    // dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return result;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}
export function update(
  request: RestaurantUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const RestaurantService = new Restaurant();
    const result = await RestaurantService.update(request);
    // dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}

export function GetRestaurantCount(
  request: RestaurantGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const RestaurantService = new Restaurant();
    const result = await RestaurantService.GetRestaurantCount(request);
    dispatch(setFormData("GetRestaurantCertificateList", result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getById(
  request: RestaurantGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const RestaurantService = new Restaurant();
    const result = await RestaurantService.getById(request);
    dispatch(
      initialFormData({
        ...result.data,
        intitialAppealDate: result.data.appealDate,
        initialAttachmentList: result.data.attachmentList,
        initialAttachmentCertificateList: result.data.attachmentCertificateList,
      })
    );
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: RestaurantChangeStatusRequestModel,
  isDelete: boolean = false
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const RestaurantService = new Restaurant();
    const result = await RestaurantService.changeStatus(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(initialFormData(result));
      dispatch(
        showMessage(
          isDelete
            ? "Silme işlemi başarıyla değiştirildi."
            : "Kayıt durumu işlemi başarıyla değiştirildi.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          "Restaurant Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getAllForPager(
  request: RestaurantGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const RestaurantService = new Restaurant();
    const result = await RestaurantService.getAll(request);
    dispatch(setFormData("dataList", result.dataList));
    dispatch(setFormData("pageCount", result.pageCount));
    dispatch(setFormData("dataCount", result.dataCount));

    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}
export function excelDownload(
  request: HotelCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const CongressService = new Restaurant();
    const excelName = "Restoran_" + formatDate();
    await CongressService.excelDownload(request, excelName);
    dispatch(toggleLoader());

    return false;
  };
}

export function leaveCertificationProcess(
  request: ILeaveCertificationProcessRequest
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const RestaurantService = new Restaurant();
    const result = await RestaurantService.leaveCertificationProcess(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "İşleminiz başarı ile gerçekleşmiştir.",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}
