import { Component } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../store/reducer'
import { Dispatch, bindActionCreators } from 'redux'
import PageTitle from '../PageTitle'
import { History } from 'history'
import { toggleConfirmDialog } from 'src/store/system/actions'
import {
  GetFacilitySummaryCount,
  GetDetailedSearchCount,
  GetFacilitySummaryList,
  GetDetailedSearch,
} from 'src/store/thunkStatistic'
import { setFormData } from 'src/store/form/actions'

import { downloadFileFormatDate, formatDate2 } from 'src/helpers/converts'
import { castThunkAction } from 'src/helpers/casting'

interface OwnProps {
  history: History
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        toggleConfirmDialog,
        GetFacilitySummaryCount,
        setFormData,
        GetDetailedSearchCount,
        GetFacilitySummaryList,
        GetDetailedSearch,
      },
      dispatch,
    ),
  }
}

type HospitalBasedReportProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps

interface OwnState {
  filterData: any
}

class InstantReport extends Component<HospitalBasedReportProps, OwnState> {
  state = {
    filterData: {
      detailedStatistic: 0,
      detailedCount: 0,
    },
  }

  RaporList(
    detailedStatistic: any = this.state.filterData.detailedStatistic,
    name: string,
  ) {
    const request: any = {
      detailedStatistic,
    }
    this.props.GetFacilitySummaryList(request, name)
  }

  RaporCount(
    detailedStatistic: any = this.state.filterData.detailedCount,
    name: string,
  ) {
    const request4: any = {
      detailedStatistic,
    }
    castThunkAction(this.props.GetFacilitySummaryList(request4, name))
  }

  UNSAFE_componentWillMount() {
    const request: any = {}
    this.props.GetDetailedSearchCount(request)
    const request2: any = {}
    this.props.GetFacilitySummaryCount(request2)
  }

  getReport(
    detailedStatistic: any = this.state.filterData.detailedStatistic,
    name: string,
  ) {
    const request: any = {
      detailedStatistic,
    }
    castThunkAction(this.props.GetDetailedSearch(request, name))
  }

  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Anlık Raporlar"
          subTitle="Bütün modüllerin anlık raporlarını alabilirsiniz."
          backUrl="/"
          showBack
          history={this.props.history}
        />
        <div className="table-filter row">
          <div className="col-md-12">
            <table
              className="table-list"
              id="InstantTable"
              style={{ width: '100%' }}
            >
              <thead className="table-head row">
                <tr
                  className="item row"
                  style={{ width: '100%', textAlign: 'left' }}
                >
                  <th className="list-th col-md-2">Konaklama Tesis Statüsü</th>
                  <th className="list-th col-md-2">Bakanlık Belgeli</th>
                  <th className="list-th col-md-2">Basit Konaklama Belgeli</th>
                  <th className="list-th col-md-2">Diğer</th>
                  <th className="list-th col-md-2">Toplam</th>
                </tr>
              </thead>
              <tbody
                className="table-body instantDetailedTable"
                style={{ width: '100%' }}
              >
                <tr
                  className="item row"
                  onClick={() => (
                    this.setState({ filterData: { detailedStatistic: 6 } }),
                    this.RaporCount(
                      6,
                      `SertifikaRapor_BirAylıkAktifSertifikalı_${downloadFileFormatDate()}`,
                    )
                  )}
                >
                  <td className="list-td row col-md-2">
                    1 Aylık Aktif Sertifikalı
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.ministryOneMonthCertificated
                    }
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.simpleFacilityOneMonthCertificated
                    }
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.allOneMonthCertificated
                    }
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.allOneMonthCertificated
                    }
                  </td>
                </tr>
                <tr
                  className="item row"
                  onClick={() => (
                    this.setState({ filterData: { detailedStatistic: 7 } }),
                    this.RaporCount(
                      7,
                      `SertifikaRapor_SertifikaUzatmalı(Kapalı)_${downloadFileFormatDate()}`,
                    )
                  )}
                >
                  <td className="list-td row col-md-2">
                    Sertifika Uzatmalı (Kapalı)
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.ministyExtendedClosedCertificated
                    }
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.simpleFacilityExtendedClosedCertificated
                    }
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.otherExtendedClosedCertificated
                    }
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.allExtendedClosedCertificated
                    }
                  </td>
                </tr>
                <tr
                  className="item row"
                  onClick={() => (
                    this.setState({ filterData: { detailedStatistic: 8 } }),
                    this.RaporCount(
                      8,
                      `SertifikaRapor_BelgelendirmeSürecinde_${downloadFileFormatDate()}`,
                    )
                  )}
                >
                  <td className="list-td row col-md-2">
                    Belgelendirme Sürecinde
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.ministryCertificationProcess
                    }
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.simpleFacilityCertificationProcess
                    }
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.otherCertificationProcess
                    }
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.allCertificationProcess
                    }
                  </td>
                </tr>
                <tr
                  className="item row"
                  onClick={() => (
                    this.setState({ filterData: { detailedStatistic: 9 } }),
                    this.RaporCount(
                      9,
                      `SertifikaRapor_UygunGörülmedi_${downloadFileFormatDate()}`,
                    )
                  )}
                >
                  <td className="list-td row col-md-2">Uygun Görülmedi</td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.ministryNotSuitableCertificates
                    }
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.simpleFacilityNotSuitableCertificates
                    }
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.allNotSuitableCertificates
                    }
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.allNotSuitableCertificates
                    }
                  </td>
                </tr>
                <tr
                  className="item row"
                  onClick={() => (
                    this.setState({ filterData: { detailedStatistic: 10 } }),
                    this.RaporCount(
                      10,
                      `SertifikaRapor_SertifikaSürecindenAyrıldı_${downloadFileFormatDate()}`,
                    )
                  )}
                >
                  <td className="list-td row col-md-2">
                    Sertifika Sürecinden Ayrıldı
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.ministryLeftCertificationProcess
                    }
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.simpleFacilityLeftCertificationProcess
                    }
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.otherLeftCertificationProcess
                    }
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData?.GetFacilitySummaryCount
                        ?.allLeftCertificationProcess
                    }
                  </td>
                </tr>
                <tr
                  className="item row"
                  onClick={() => (
                    this.setState({ filterData: { detailedStatistic: 11 } }),
                    this.RaporCount(
                      11,
                      `SertifikaRapor_QrKodsuzSertifikalı_${downloadFileFormatDate()}`,
                    )
                  )}
                >
                  <td className="list-td row col-md-2">
                    Qr Kodsuz Sertifikalı
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData.GetFacilitySummaryCount
                        ?.ministryWithoutQrCodeCertificate
                    }
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData.GetFacilitySummaryCount
                        ?.simpleFacilityWithoutQrCodeCertificate
                    }
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData.GetFacilitySummaryCount
                        ?.otherWithoutQrCodeCertificate
                    }
                  </td>
                  <td className="list-td row col-md-2">
                    {
                      this.props.formData.GetFacilitySummaryCount
                        ?.allWithoutQrCodeCertificate
                    }
                  </td>
                </tr>
                <tr
                  className="item row"
                  onClick={() => (
                    this.setState({ filterData: { detailedStatistic: 12 } }),
                    this.RaporCount(
                      12,
                      `SertifikaRapor_Tesis Statüsü_${downloadFileFormatDate()}`,
                    )
                  )}
                >
                  <td className="list-td row col-md-2">
                    <b>Toplam</b>
                  </td>
                  <td className="list-td row col-md-2"></td>
                  <td className="list-td row col-md-2"></td>
                  <td className="list-td row col-md-2"></td>
                  <td className="list-td row col-md-2">
                    {this.props.formData?.GetFacilitySummaryCount?.total}
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              className="instantButtonDownload download-table-xls-button"
              onClick={() => (
                this.setState({ filterData: { detailedStatistic: 12 } }),
                this.RaporCount(
                  12,
                  `SertifikaRapor_Tesis Statüsü_${downloadFileFormatDate()}`,
                )
              )}
            >
              Rapor İndir
            </button>
          </div>
        </div>

        <div className="table-filter row">
          <div className="col-md-12">
            <table
              className="table-list"
              id="InstantTable"
              style={{ width: '100%' }}
            >
              <thead className="table-head row">
                <tr
                  className="item row"
                  style={{ width: '100%', textAlign: 'left' }}
                >
                  <th className="list-th col-md-10">Özet</th>
                  <th className="list-th col-md-2"></th>
                </tr>
              </thead>
              <tbody className="table-body" style={{ width: '100%' }}>
                <tr className="item row">
                  <td className="list-td row col-md-10">
                    Toplam
                    <b>
                      {
                        this.props.formData?.GetDetailedSearchCount
                          ?.totalCurrentCertificate
                      }
                    </b>
                    konaklama tesisi, restoran / kafe ve taşıt{' '}
                    {formatDate2() + ' '}
                    itibarıyla aktif sertifikalıdır.
                  </td>
                  <td className="list-td row col-md-2">
                    <button
                      className="instantButtonDownload download-table-xls-button"
                      onClick={() => (
                        this.setState({ filterData: { detailedStatistic: 1 } }),
                        this.getReport(
                          1,
                          `SertifikaRapor_Tüm Tesisler_Aktif Sertifikalı_${downloadFileFormatDate()}`,
                        )
                      )}
                    >
                      Rapor İndir
                    </button>
                  </td>
                </tr>
                <tr className="item row">
                  <td className="list-td row col-md-10">
                    <b>
                      {this.props.formData?.GetDetailedSearchCount?.hotelNonQr +
                        ''}
                    </b>
                    Konaklama Tesisi,
                    <b>
                      {
                        this.props.formData?.GetDetailedSearchCount
                          ?.publishedAllRestaurant
                      }
                    </b>
                    Restoran/Kafe ve
                    <b>
                      {
                        this.props.formData?.GetDetailedSearchCount
                          ?.publishedAllTransport
                      }
                    </b>
                    taşıt aktif sertifikalıdır.
                  </td>
                  <td className="list-td row col-md-2">
                    <button
                      className="instantButtonDownload download-table-xls-button"
                      onClick={() => (
                        this.setState({ filterData: { detailedStatistic: 2 } }),
                        this.getReport(
                          2,
                          `SertifikaRapor_Tüm Tesisler_Aktif Sertifikalı_${downloadFileFormatDate()}`,
                        )
                      )}
                    >
                      Rapor İndir
                    </button>
                  </td>
                </tr>
                <tr className="item row">
                  <td className="list-td row col-md-10">
                    Aktif sertifikalı
                    <b>
                      {this.props.formData?.GetDetailedSearchCount?.hotelNonQr}
                    </b>
                    konaklama tesisinden
                    <b>
                      {
                        this.props.formData?.GetDetailedSearchCount
                          ?.openedHotelCertificate
                      }
                    </b>
                    faaliyet gösterirken,
                    <b>
                      {
                        this.props.formData?.GetDetailedSearchCount
                          ?.closedHotelCertificate
                      }
                    </b>
                    'i kapalı beyan edilmiştir.
                  </td>
                  <td className="list-td row col-md-2">
                    <button
                      className="instantButtonDownload download-table-xls-button"
                      onClick={() => (
                        this.setState({ filterData: { detailedStatistic: 3 } }),
                        this.getReport(
                          3,
                          `SertifikaRapor_Konaklama_Aktif Sertifikalı_Faaliyet Durumu_${downloadFileFormatDate()}`,
                        )
                      )}
                    >
                      Rapor İndir
                    </button>
                  </td>
                </tr>
                <tr className="item row">
                  <td className="list-td row col-md-10">
                    <b>
                      {
                        this.props.formData?.GetDetailedSearchCount
                          ?.notSuitableHotelCertificate
                      }
                    </b>
                    Konaklama tesisi denetim sonucu uygun görülmemiş,
                    <b>
                      {
                        this.props.formData?.GetDetailedSearchCount
                          ?.leavedHotelCertificate
                      }
                    </b>
                    tesis süreçten ayrılmıştır.
                  </td>
                  <td className="list-td row col-md-2">
                    <button
                      className="instantButtonDownload download-table-xls-button"
                      onClick={() => (
                        this.setState({ filterData: { detailedStatistic: 4 } }),
                        this.getReport(
                          4,
                          `SertifikaRapor_Konaklama_Uygun Görülmedi ve Süreçten Ayrılanlar_${downloadFileFormatDate()}`,
                        )
                      )}
                    >
                      Rapor İndir
                    </button>
                  </td>
                </tr>
                <tr className="item row">
                  <td className="list-td row col-md-10">
                    Şuana kadar;
                    <b>
                      {
                        this.props.formData?.GetDetailedSearchCount
                          ?.ministryApprovaledHotels
                      }
                    </b>
                    Bakanlık Belgeli
                    <b>
                      {
                        this.props.formData?.GetDetailedSearchCount
                          ?.otherApprovalHotels
                      }
                    </b>
                    Belediye Belgeli olmak üzere toplam
                    <b>
                      {
                        this.props.formData?.GetDetailedSearchCount
                          ?.allApprovalHotels
                      }
                    </b>
                    konaklama tesisi,
                    <b>
                      {
                        this.props.formData?.GetDetailedSearchCount
                          ?.allApprovalRestaurant
                      }
                    </b>
                    restoran/kafe ve
                    <b>
                      {
                        this.props.formData?.GetDetailedSearchCount
                          ?.allApprovalTransport
                      }
                    </b>
                    taşıt olmak üzere toplam
                    <b>
                      {this.props.formData?.GetDetailedSearchCount
                        ?.allApprovalHotels +
                        this.props.formData?.GetDetailedSearchCount
                          ?.allApprovalRestaurant +
                        this.props.formData?.GetDetailedSearchCount
                          ?.allApprovalTransport}
                    </b>
                    tesis ve araca sertifika verilmiştir.
                  </td>
                  <td className="list-td row col-md-2">
                    <button
                      className="instantButtonDownload download-table-xls-button"
                      onClick={() => (
                        this.setState({ filterData: { detailedStatistic: 5 } }),
                        this.getReport(
                          5,
                          `SertifikaRapor_Konaklama_Tüm Tesisler_Tüm Belge Verildiler_${downloadFileFormatDate()}`,
                        )
                      )}
                    >
                      Rapor İndir
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstantReport)
