import { StatusEnums } from "../BaseEnum";

export interface SystemUserSystemUserLogin {
  uid: string;
  password: string;
}

export interface SystemUserCheckTokenModel {
  token?: string;
}
export enum ConditionOperatorEnum {
  Eq = 0,
  NotEq = 1,
  Like = 2,
  Gt = 3,
  Lt = 4,
  Ge = 5,
  Le = 6,
}

export interface SystemUserGetAllCriteriaItemList {
  propertyName: string;
  value: any;
  isOr: boolean;
  conditionOperator: ConditionOperatorEnum;
}

export interface SystemUserGetAllModel {
  title?: string;
  systemUserType: number;
  pageSize?: number;
  pageIndex?: number;
  orderField?: string;
  isDescending?: boolean;
  status?: StatusEnums;
}

export interface SystemUserSaveDataModel {
  password: string;
  title: string;
  status?: StatusEnums;
  systemUserType: number;
  prefix: string;
}

export interface SystemUserSaveRequestModel {
  data: SystemUserSaveDataModel;
}

export interface SystemUserUpdateDataModel {
  systemUserId: number;
  uid: string;
  password: string;
  title: string;
  status: number;
  systemUserType: number;
  prefix: string;
  email?: string | null;
  emailAddressList: any;
}

export interface SystemUserUpdateRequestModel {
  data: SystemUserUpdateDataModel;
}
export interface SystemUserGetByIdRequestModel {
  id: number;
  token: string;
}

export interface SystemUserChangeStatusModel {
  status: number;
  id: number;
}
export interface SystemUserChangePasswordRequestModel {
  password: string;

  oldPassword: string;
}
