import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  TextField,
  // MessageBar,
  MessageBarType,
  PrimaryButton,
  Dropdown,
  DatePicker,
  Checkbox,
  ComboBox,
} from "office-ui-fabric-react";
import {
  setFormData,
  setFormErrors,
  initialFormData,
} from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";
import { save } from "src/store/thunkAirport";
import { castThunkAction } from "src/helpers/casting";
import { StatusEnums } from "src/services/BaseEnum";

import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import { getAllTy, showErrorDialog } from "src/store/thunkCertificateType";
import { getAllCR } from "src/store/thunkCertificateStatusType";
import { getAllCompany } from "src/store/thunksCompany";
import { getCompanyUserList } from "src/store/thunksSystemUser";
import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";
import { CertificateTypeGetAllRequestModel } from "src/services/CertificateType/RequestModel";
import { CertificateStatusTypeGetAllRequestModel } from "src/services/CertificateStatusType/RequestModel";
import cookie from "react-cookies";
import {
  DayPickerStrings,
  firstDayOfWeek,
  onFormatDate,
} from "src/config/otherConfig";
import { toTimeStamp, toDate } from "src/helpers/converts";
import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";
import _ from "lodash";
import { FileUpload } from "../FileUpload";
import { apiConfig, qrUrl } from "src/config/apiConfig";
import { AirportSaveRequestModel } from "src/services/Airport/RequestModel";
import { formatSize, certificateAppealStatusDate, sertifikaTarihiBayram, requestMapData } from "src/helpers/functions";
import ValidityDate from "../ValidityDate";

let token = "";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        initialFormData,
        save,
        getAllM,
        getAllC,
        getAllTy,
        getAllCR,
        getCompanyUserList,
        getAllCompany,
        showErrorDialog,
      },
      dispatch
    ),
  };
}

type CreateAirportProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  showFields: boolean;
  messageType: MessageBarType;
  isFileUploading: boolean;
  infoMessageGetDocumentNo: string;
  certificateStatusSifir: any;
  certificateStatusBir: any;
  certificateStatusIki: any;
  certificateStatusUc: any;
}
let usertype: any = 0;
class CreateAirport extends Component<CreateAirportProps, OwnState> {
  state = {
    messageType: MessageBarType.warning,
    showFields: true,
    isFileUploading: false,
    infoMessageGetDocumentNo: "",
    certificateStatusSifir: 0,
    certificateStatusBir: 0,
    certificateStatusIki: 0,
    certificateStatusUc: 0,
  };

  UNSAFE_componentWillMount() {
    token = cookie.load("userId");
    this.props.initialFormData({
      status: StatusEnums.Published,
    });

    usertype = cookie.load("systemUserInfo");
    const request6: SystemUserGetAllModel = {
      systemUserType: 2,
    };
    usertype === "2"
      ? this.props.setFormData("prefix", cookie.load("prefix"))
      : this.props.setFormData("prefix", null);
    castThunkAction(this.props.getCompanyUserList(request6)).then(
      (response: any) => {
        if (usertype && usertype === "2") {
          this.props.setFormData("companyUserId", 0);
        }

        const request2: CityGetAllRequestModel = {
          selectItemList: ["CityId", "CityName"],
          isDescending: false,
          orderField: "CityName",
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllM(request2);

        const request4: CertificateTypeGetAllRequestModel = {
          selectItemList: ["CertificateTypeId", "Title"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllTy(request4);

        const request5: CertificateStatusTypeGetAllRequestModel = {
          selectItemList: ["CertificateStatusTypeId", "Title"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        castThunkAction(this.props.getAllCR(request5)).then((e: any) => {
          this.props.setFormData("certificateStatusTypeId", 1);
        });
      }
    );
  }

  componentDidUpdate(nextprops: any) {
    if (this.props.formData.certificateTypeId !== 0) {
      this.setLabelDescription(
        "companyName",
        "<br /> <span  id='companyName-span'  class='descSpan'>Tesis sahibi olan firmanın ticari ünvanı</span>"
      );
      this.setDdlDescription(
        "certificateStatusTypeId-label",
        "<br /> <span  id='certificateStatusTypeId-label-span'  class='descSpan'>Denetim için sözleşme yapıldığında <b>Belgelendirme Sürecinde</b>, belge verildikten sonra ise <b>Belge Verildi</b> seçeneğinin işaretlenmesi gerekmektedir.</span>"
      );
      if (this.props.formData.certificateTypeId === 4) {
        this.setLabelDescription(
          "documentNoDesc",
          "<br /> <span  id='documentNoDesc-span'  class='descSpan'>TC Kültür ve Turizm Bakanlığı tarafından tesise verilen çalışma belge numarası</span>"
        );
      }
    }
    if (
      this.props.formData.certificateStatusTypeId === 2 &&
      this.props.formData.prefix
    ) {
      this.setPrefix();
    }
  }

  insertAfter(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  insertBefore(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode);
  }

  componentDidMount() {
    this.setDdlDescription(
      "certificateTypeIdDesc-label",
      "<br/> <span id='certificateTypeIdDesc-label-span' class='descSpan'>Tesisin kayıt edileceği belge tipini seçiniz. Tesise ait <b>T.C Kültür ve Turizm Bakanlığı</b> Belgesi bulunuyorsa bu belge tipini seçip ilgili belgeyi yüklemeniz ve belgeye uygun bilgileri doldurmanız gerekmektedir. Eğer tesise ait bu belge bulunmuyorsa lütfen <b>Diğer</b> seçerek ilerleyeniz.</b></span>"
    );
  }

  setDdlDescription(id: string, message: string) {
    const el = document.createElement("span");
    el.innerHTML = message;
    const div = document.getElementById(id);
    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div, el);
    }
  }

  setLabelDescription(id: string, message: string) {
    const el2 = document.createElement("span");
    el2.innerHTML = message;
    const div2 = document.querySelectorAll('[for="' + id + '"]');

    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div2[0], el2);
    }
  }

  // renderTextByMessageType() {
  // 	switch (this.state.messageType) {
  // 		case MessageBarType.success:
  // 			return "Kaydetme işlemi başarıyla gerçekleşmiştir."
  // 		case MessageBarType.error:
  // 			return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin."
  // 		default:
  // 			return "Lütfen aşağıdaki bilgileri doldurun ve formu oluşturun!"
  // 	}
  // }

  // renderMessageBar() {
  // 	return (
  // 		<MessageBar
  // 			messageBarType={this.state.messageType}
  // 			isMultiline={false}
  // 		>
  // 			{this.renderTextByMessageType()}
  // 		</MessageBar>
  // 	)
  // }

  onCreate(isDraft?: boolean) {
    let request: AirportSaveRequestModel = {
      data: requestMapData({
        ...this.props.formData,
        status: isDraft ? StatusEnums.Draft : this.props.formData.status
      })
    };
    if (
      this.props.formData.attachmentList &&
      this.props.formData.attachmentList.length > 0
    ) {
      request.data["attachmentIdList"] = (
        this.props.formData.attachmentList || []
      ).map((item: any) => item.attachmentId);
    }
    if (
      this.props.formData.attachmentCertificateList &&
      this.props.formData.attachmentCertificateList.length > 0
    ) {
      request.data["attachmentCertificateIdList"] = (
        this.props.formData.attachmentCertificateList || []
      ).map((item: any) => item.attachmentId);
    }
    if (!request.data.documentNo) {
      request.data.documentNo = 0;
    }
    castThunkAction(this.props.save(request)).then((response: any) => {
      this.props.setFormData(
        "initialAttachmentList",
        this.props.formData.attachmentList
      );
      this.props.setFormData(
        "initialAttachmentCertificateList",
        this.props.formData.attachmentCertificateList
      );
      this.setState(
        {
          messageType: response ? MessageBarType.success : MessageBarType.error,
        },
        () => {
          if (response) {
            this.props.history.push("/Airport/edit/" + response.id);
          } else {
            setTimeout(() => {
              //window.location.reload(false)
            }, 1000);
          }
        }
      );
    });
  }

  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  removeElement(id: any) {
    var elem = document.getElementById(id);
    return elem?.parentNode?.removeChild(elem);
  }
  filterFileds(val: any) {
    if (
      val !== "" &&
      val !== 0 &&
      val !== "0" &&
      val !== null &&
      val !== "null" &&
      val !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  }
  onSubmit(isDraft?: boolean) {
    let desc = "";
    if (
      this.state.certificateStatusBir === 1 &&
      this.state.certificateStatusUc === 1
    ) {
      this.props.setFormData("certificateStatus", 4);
    } else if (
      this.state.certificateStatusBir === 1 &&
      this.state.certificateStatusUc === 0
    ) {
      this.props.setFormData("certificateStatus", 1);
    } else if (
      this.state.certificateStatusBir === 0 &&
      this.state.certificateStatusUc === 1
    ) {
      this.props.setFormData("certificateStatus", 3);
    } else if (
      this.state.certificateStatusBir === 0 &&
      this.state.certificateStatusUc === 0
    ) {
      this.props.setFormData("certificateStatus", 0);
    }

    if (!this.filterFileds(this.props.formData.certificateTypeId)) {
      desc += "Tesis Belge Tipi, <br/>";
    }
    if (!this.filterFileds(this.props.formData.companyName)) {
      desc += "İşletme Sahibi,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.brandName)) {
      desc += "Tesis Adı TR,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.brandName_En)) {
      desc += "Tesis Adı ENG,  <br/>";
    }

    if (!this.filterFileds(this.props.formData.cityId)) {
      desc += "İl,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.districtId)) {
      desc += "İlçe,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.appealDate)) {
      desc += "Sertifika Başvuru Tarihi,  <br/>";
    }
    if (usertype && usertype !== "2") {
      if (!this.filterFileds(this.props.formData.companyUserId)) {
        desc += "Denetleyici Firma <br/> ";
      }
    }
    if(this.state.certificateStatusBir === 1 && this.props.formData.certificateStatusTypeId!== 2){
      if (this.props.formData.extendedClosedDate) {
          } else {
            desc += "Belge Verildi durumu dışındaki sertifikalar Sertifika Uzatmalı Kapalı olarak seçilemez,  <br/>";
          }
    }
    if (this.props.formData.certificateStatusTypeId === 2) {
      if (!this.filterFileds(this.props.formData.confirmationDate)) {
        desc += "Sertifika Düzenleme Tarihi,  <br/>";
      }
      if (!this.filterFileds(this.props.formData.CertificateNo)) {
        desc += "Sertifika No,  <br/>";
      }
      if (!this.filterFileds(this.props.formData.extendedClosedDate)) {
        desc += "Sertifika Geçerlilik Tarihi boş bırakılamaz.  <br/>";
      }
    }
    if (this.props.formData.certificateTypeId === 4) {
      if ((!this.filterFileds(this.props.formData.documentNo)) || this.props.formData.documentNo === "0") {
        desc += "Belge No,  <br/>";
      }
    }

    if (
      !this.props.formData.attachmentList ||
      this.props.formData.attachmentList.length === 0
    ) {
      desc += "İşletme belgesi yükleyiniz,  <br/>";
    }

    const request = {
      title: "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
      desc: desc,
    };
    if (desc !== "") {
      castThunkAction(this.props.showErrorDialog(request)).then(
        (response: any) => {}
      );
    }

    //https://b24-z1nwey.bitrix24.com.tr/workgroups/group/8/tasks/task/view/2042/ madde revize.
    if (this.props.formData.certificateTypeId === 5) {
      this.props.setFormData("documentNo", "");
    }

    if (
      this.props.formData.appealDate &&
      this.props.formData.appealDate !== "" &&
      this.props.formData.appealDate !== null &&
      this.props.formData.appealDate !== 0 &&
      this.props.formData.appealDate !== "null" &&
      this.props.formData.appealDate !== "0"
    ) {
      this.removeElement("appealDate-span");
    } else {
      const el = document.createElement("span");
      el.className = "errorspan";
      el.innerHTML =
        "<br/> <span id='appealDate-span' class='descSpan errorspan'>Bu alan zorunludur!</span>";
      const div = document.getElementById("appealDate");
      if (!document.getElementById("appealDate-span")) {
        this.insertAfter(div, el);
      }
    }

    if (this.props.formData.certificateStatusTypeId === 2) {
      if (
        this.props.formData.confirmationDate &&
        this.props.formData.confirmationDate !== "" &&
        this.props.formData.confirmationDate !== null &&
        this.props.formData.confirmationDate !== 0 &&
        this.props.formData.confirmationDate !== "null" &&
        this.props.formData.confirmationDate !== "0"
      ) {
        this.removeElement("confirmationDate-span");
      } else {
        const el = document.createElement("span");
        el.className = "errorspan";
        el.innerHTML =
          "<br/> <span id='confirmationDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
        const div = document.getElementById("confirmationDate");
        if (!document.getElementById("confirmationDate-span")) {
          this.insertAfter(div, el);
        }
      }
    } else {
      this.removeElement("confirmationDate-span");
    }

    const { formData, setFormErrors } = this.props;
    const validationSchema = _yup.object({
      webSite: _yup
        .string()
        .matches(
          // eslint-disable-next-line
          /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "Geçersiz Web Sitesi"
        )
        .notRequired()
        .nullable(),
      companyName: _yup.string().required("Bu alan zorunludur!"),

      brandName: _yup.string().required("Bu alan zorunludur!"),

      brandName_En: _yup.string().required("Bu alan zorunludur!"),
      documentNo:
        this.props.formData.certificateTypeId === 4
          ? _yup.string().required("Bu alan zorunludur!")
          : _yup.string().nullable(),
      certificateTypeId: _yup.string().required("Bu alan zorunludur!"),
      certificateStatusTypeId: _yup.string().required("Bu alan zorunludur!"),
      cityId: _yup.string().required("Bu alan zorunludur!"),
      districtId: _yup.string().required("Bu alan zorunludur!"),
      companyUserId: _yup.string().required("Bu alan zorunludur!"),
      CertificateNo:
        this.props.formData.certificateStatusTypeId === 2
          ? _yup.string().required("Bu alan zorunludur!")
          : _yup.string().nullable(),
      extendedClosedDate:
        this.state.certificateStatusBir === 1
          ? _yup.string().required("Bu alan zorunludur!")
          : _yup.string().nullable(),
    });

    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});
        if (
          this.props.formData.appealDate &&
          this.props.formData.appealDate !== "" &&
          this.props.formData.appealDate !== null &&
          this.props.formData.appealDate !== 0 &&
          this.props.formData.appealDate !== "null" &&
          this.props.formData.appealDate !== "0"
        ) {
          this.removeElement("appealDate-span");

          if (this.props.formData.certificateStatusTypeId === 2) {
            if (
              this.props.formData.confirmationDate &&
              this.props.formData.confirmationDate !== "" &&
              this.props.formData.confirmationDate !== null &&
              this.props.formData.confirmationDate !== 0 &&
              this.props.formData.confirmationDate !== "null" &&
              this.props.formData.confirmationDate !== "0"
            ) {
              this.removeElement("appealDate-span");
              this.removeElement("confirmationDate-span");
              if (
                this.props.formData.attachmentList &&
                this.props.formData.attachmentList.length > 0
              ) {
                desc === "" &&  this.onCreate(isDraft);
              } else {
                castThunkAction(
                  this.props.showErrorDialog({
                    title:
                      "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
                    desc: "İşletme belgesi yükleyiniz",
                  })
                ).then((response: any) => {});
              }
            } else {
              const el = document.createElement("span");
              el.className = "errorspan";
              el.innerHTML =
                "<br/> <span id='confirmationDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
              const div = document.getElementById("confirmationDate");
              if (!document.getElementById("confirmationDate-span")) {
                this.insertAfter(div, el);
              }
            }
          } else {
            this.removeElement("appealDate-span");
            this.removeElement("confirmationDate-span");
            if (
              this.props.formData.attachmentList &&
              this.props.formData.attachmentList.length > 0
            ) {
              desc === "" &&  this.onCreate(isDraft);
            } else {
              castThunkAction(
                this.props.showErrorDialog({
                  title:
                    "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
                  desc: "İşletme belgesi yükleyiniz",
                })
              ).then((response: any) => {});
            }
          }
        } else {
          const el = document.createElement("span");
          el.className = "errorspan";
          el.innerHTML =
            "<br/> <span id='appealDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
          const div = document.getElementById("appealDate");
          if (!document.getElementById("appealDate-span")) {
            this.insertAfter(div, el);
          }
        }
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  CityChange(item: any, districtId?: any) {
    const request2: CountyGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "cityId",
          value: item,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
      selectItemList: ["DistrictId", "DistrictName"],
      orderField: "DistrictName",
    };
    castThunkAction(this.props.getAllC(request2)).then((response: any) => {
      this.props.setFormData("districtId", districtId);
    });
  }

  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Seçiniz" });
    }

    return newArray;
  }
  getDate(date: any): any {
    if (date !== 0 && date !== undefined) {
      return toDate(date, "");
    }
  }

  renderForm() {
    const { formData, formErrors, setFormData } = this.props;
    return (
      <div className="form-groups row">
        <div className="form-group col-md-4" style={{ paddingBottom: 0 }}>
          <div className="form-wrapper">
            <div className="item row">
              <Dropdown
                id="certificateTypeIdDesc"
                onChanged={(event: any) =>
                  setFormData("certificateTypeId", event.key)
                }
                errorMessage={formErrors.certificateTypeId}
                label="Tesis Belge Tipi"
                options={(this.props.formData.GroupListTY?.dataList
                  ? this.returnOptions(
                      this.props.formData.GroupListTY?.dataList,
                      "CertificateTypeId",
                      "Title"
                    )
                  : ([] as any)
                ).map((item: any, key: any) => ({
                  key: item.CertificateTypeId,
                  text: item.Title,
                  keys: key,
                }))}
                className="custom-dropdown"
              />
            </div>
            {this.props.formData.certificateTypeId === 4 ? (
              <div className="item row">
                <TextField
                  id="documentNoDesc"
                  placeholder="Belge No"
                  value={formData.documentNo || ""}
                  label="Belge No "
                  className="custom-textfield border"
                  errorMessage={formErrors.documentNo}
                  onChange={(event: any) =>
                    setFormData("documentNo", event.target.value)
                  }
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="title">
              T.C Kültür ve Turizm Bakanlığı İşletme Belgesi / İşyeri Açma
              Ruhsatı ve Tesise Ait Diğer Resmi Evraklar
            </div>
            <FileUpload
              onProcessStartFile={() => {
                this.setState({
                  isFileUploading: true,
                });
              }}
              onProcessEndFile={(queue: boolean) => {
                if (!queue) {
                  this.setState({
                    isFileUploading: false,
                  });
                }
              }}
              onTransferedFile={(file: any, promise?: boolean) => {
                this.props.setFormData("attachmentList", [
                  ...(this.props.formData?.attachmentList || []),
                  file.attachmentIdList[0],
                ]);
              }}
              requestKey="files"
              token={token}
              endpointUrl={apiConfig.prefixes.attachment + "UploadFile"}
            />
            {this.props.formData.attachmentList &&
              this.props.formData.attachmentList.length > 0 && (
                <React.Fragment>
                  <div className="title">Yüklenen Belgeler</div>
                  {JSON.stringify(this.props.formData.initialAttachmentList) !==
                    JSON.stringify(this.props.formData.attachmentList) && (
                    <div className="file-save-warning">
                      Düzenlemeleri ve yüklenen belgeleri kaydetmek için, sağ
                      üst kısımdan "Kaydet veya Yayımla"ya basın.
                    </div>
                  )}
                  <div className="uploaded-files">
                    {(this.props.formData?.attachmentList || []).map(
                      (
                        item: {
                          attachmentId: number;
                          attachmentPath: string;
                          fileName: string;
                          fileSize: number;
                        },
                        index: number
                      ) => (
                        <div key={index} className="uploaded-file">
                          <div className="name row">
                            <span className="text col no-padding">
                              {item.fileName}
                              <strong className="size m-left-auto">
                                {formatSize(item.fileSize)}
                              </strong>
                            </span>
                            <div className="buttons m-left-auto row">
                              <button
                                onClick={() =>
                                  window.open(
                                    qrUrl + item.attachmentPath,
                                    "_blank"
                                  )
                                }
                                className="view"
                              >
                                <i className="icon-explore"></i> Görüntüle
                              </button>
                              <button
                                onClick={() => {
                                  let files = _.cloneDeep(
                                    this.props.formData?.attachmentList
                                  );
                                  let findIndex = (files || []).findIndex(
                                    (x: any) =>
                                      x.attachmentId === item.attachmentId
                                  );
                                  if (findIndex !== -1) {
                                    files.splice(findIndex, 1);
                                  }
                                  this.props.setFormData(
                                    "attachmentList",
                                    files
                                  );
                                }}
                                className="remove"
                              >
                                <i className="icon-close"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </React.Fragment>
              )}
          </div>
        </div>
        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="title">Sertifika Belgesi</div>
            <FileUpload
              onProcessStartFile={() => {
                this.setState({
                  isFileUploading: true,
                });
              }}
              onProcessEndFile={(queue: boolean) => {
                if (!queue) {
                  this.setState({
                    isFileUploading: false,
                  });
                }
              }}
              onTransferedFile={(file: any, promise?: boolean) => {
                this.props.setFormData("attachmentCertificateList", [
                  ...(this.props.formData?.attachmentCertificateList || []),
                  file.attachmentIdList[0],
                ]);
              }}
              requestKey="files"
              token={token}
              endpointUrl={
                apiConfig.prefixes.attachment + "UploadCertificateFile"
              }
            />
            {this.props.formData.attachmentCertificateList &&
              this.props.formData.attachmentCertificateList.length > 0 && (
                <React.Fragment>
                  <div className="title">Yüklenen Belgeler</div>
                  {JSON.stringify(
                    this.props.formData.initialAttachmentCertificateList
                  ) !==
                    JSON.stringify(
                      this.props.formData.attachmentCertificateList
                    ) && (
                    <div className="file-save-warning">
                      Düzenlemeleri ve yüklenen belgeleri kaydetmek için, sağ
                      üst kısımdan "Kaydet veya Yayımla"ya basın.
                    </div>
                  )}
                  <div className="uploaded-files">
                    {(this.props.formData?.attachmentCertificateList || []).map(
                      (
                        item: {
                          attachmentId: number;
                          attachmentPath: string;
                          fileName: string;
                          fileSize: number;
                        },
                        index: number
                      ) => (
                        <div key={index} className="uploaded-file">
                          <div className="name row">
                            <span className="text col no-padding">
                              {item.fileName}
                              <strong className="size m-left-auto">
                                {formatSize(item.fileSize)}
                              </strong>
                            </span>
                            <div className="buttons m-left-auto row">
                              <button
                                onClick={() =>
                                  window.open(
                                    qrUrl + item.attachmentPath,
                                    "_blank"
                                  )
                                }
                                className="view"
                              >
                                <i className="icon-explore"></i> Görüntüle
                              </button>
                              <button
                                onClick={() => {
                                  let files = _.cloneDeep(
                                    this.props.formData
                                      ?.attachmentCertificateList
                                  );
                                  let findIndex = (files || []).findIndex(
                                    (x: any) =>
                                      x.attachmentId === item.attachmentId
                                  );
                                  if (findIndex !== -1) {
                                    files.splice(findIndex, 1);
                                  }
                                  this.props.setFormData(
                                    "attachmentCertificateList",
                                    files
                                  );
                                }}
                                className="remove"
                              >
                                <i className="icon-close"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </React.Fragment>
              )}
          </div>
        </div>

        <aside className="col-md-12 row" style={{ padding: 0 }}>
          <div className="form-group col-md-4">
            <div className="form-wrapper">
              <div className="item row">
                <TextField
                  id="companyName"
                  // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                  placeholder="İşletme Sahibi"
                  value={formData.companyName || ""}
                  label="İşletme Sahibi"
                  className="custom-textfield border"
                  errorMessage={formErrors.companyName}
                  onChange={(event: any) =>
                    setFormData("companyName", event.target.value)
                  }
                />
              </div>

              <div className="item row">
                <TextField
                  id="brandName"
                  // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                  placeholder="Tesis Adı TR"
                  value={formData.brandName || ""}
                  label="Tesis Adı TR"
                  className="custom-textfield border"
                  errorMessage={formErrors.brandName}
                  onChange={(event: any) =>
                    setFormData("brandName", event.target.value)
                  }
                />
              </div>
              <div className="item row">
                <TextField
                  id="brandName_En"
                  // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                  placeholder="Tesis Adı ENG"
                  value={formData.brandName_En || ""}
                  label="Tesis Adı ENG"
                  className="custom-textfield border"
                  errorMessage={formErrors.brandName_En}
                  onChange={(event: any) =>
                    setFormData("brandName_En", event.target.value)
                  }
                />
              </div>

              <div className="item row">
                <Dropdown
                  // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                  onChanged={(event: any) => (
                    this.CityChange(event.key), setFormData("cityId", event.key)
                  )}
                  label="İl"
                  options={(
                    this.props.formData.GroupList?.dataList || ([] as any)
                  ).map((item: any) => ({
                    key: item.CityId,
                    text: item.CityName,
                  }))}
                  className="custom-dropdown"
                  selectedKey={this.props.formData.cityId}
                  errorMessage={formErrors.cityId}
                />
              </div>

              <div className="item row">
                <Dropdown
                  // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                  onChanged={(event: any) =>
                    setFormData("districtId", event.key)
                  }
                  label="İlçe"
                  options={(formData.GroupListC?.dataList || ([] as any)).map(
                    (item: any) => ({
                      key: item.DistrictId,
                      text: item.DistrictName,
                    })
                  )}
                  className="custom-dropdown"
                  selectedKey={this.props.formData.districtId}
                  errorMessage={formErrors.districtId}
                />
              </div>

              {usertype && usertype !== "2" ? (
                <>
                  <div className="item row">
                    <Dropdown
                      onChanged={(event: any) =>
                        setFormData("isPublishWeb", event.key)
                      }
                      label="Web Sitesinde Yayımlansın mı?"
                      options={[
                        { key: false, text: "Hayır" },
                        { key: true, text: "Evet" },
                      ].map((item: any) => ({
                        key: item.key,
                        text: item.text,
                      }))}
                      className="custom-dropdown"
                    />
                  </div>
                  <div className="item row">
                    <ComboBox
                      onChange={(event: any, option: any) => {
                        setFormData("companyUserId", option.key);
                        setFormData("prefix", option.prefix);
                      }}
                      allowFreeform={true}
                      autoComplete="on"
                      label="Denetleyici Firma"
                      selectedKey={this.props.formData.companyUserId || 0}
                      options={(
                        this.props.formData.CompanyUserList?.dataList ||
                        ([] as any)
                      ).map((item: any) => ({
                        key: item.systemUserId,
                        text: item.title,
                        prefix: item.prefix,
                      }))}
                      className="custom-dropdown"
                      errorMessage={formErrors.companyUserId}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="form-group col-md-4">
            <div className="form-wrapper">
              <div className="item row">
                <TextField
                  placeholder="Tesisin Web Sitesi"
                  value={formData.webSite || ""}
                  label="Tesisin Web Sitesi"
                  className="custom-textfield border"
                  errorMessage={formErrors.webSite}
                  onChange={(event: any) =>
                    setFormData(
                      "webSite",
                      event.target.value === "" ? null : event.target.value
                    )
                  }
                />
              </div>

              <div className="item row">
                <DatePicker
                  strings={DayPickerStrings}
                  firstDayOfWeek={firstDayOfWeek}
                  onSelectDate={(date) =>
                    setFormData("appealDate", toTimeStamp(date?.toString()))
                  }
                  label="Sertifika Başvuru Tarihi"
                  placeholder="gün.ay.yıl"
                  formatDate={onFormatDate}
                  value={this.getDate(this.props.formData.appealDate)}
                  maxDate={new Date()}
                  minDate={certificateAppealStatusDate(usertype, false)}
                />

                <PrimaryButton
                  id="appealDate"
                  onClick={(x) => this.props.setFormData("appealDate", 0)}
                  iconProps={{ iconName: "Trash" }}
                  text="Tarihi Sil"
                  className="deleteDateBtn"
                  styles={{
                    root: {
                      marginTop: 5,
                      background: "#ac6262",
                      borderColor: "#ac6262",
                    },
                    rootHovered: {
                      background: "#a34c4c",
                      borderColor: "#a34c4c",
                    },
                    rootPressed: {
                      background: "#a34c4c",
                      borderColor: "#a34c4c",
                    },
                    rootDisabled: {
                      background: "#ececec",
                    },
                  }}
                />
              </div>
              <div className="item row">
                <Checkbox
                  checked={this.state.certificateStatusBir === 1}
                  onChange={() =>
                    this.state.certificateStatusBir === 1
                      ? this.setState({
                          certificateStatusBir: 0,
                        })
                      : this.setState({
                          certificateStatusBir: 1,
                        })
                  }
                  label="Sertifika Uzatmalı Kapalı"
                  className="custom-checkbox"
                />
              </div>

              <div className="item row">
                <Checkbox
                  checked={this.state.certificateStatusUc === 1}
                  onChange={() =>
                    this.state.certificateStatusUc === 1
                      ? this.setState({
                          certificateStatusUc: 0,
                        })
                      : this.setState({
                          certificateStatusUc: 1,
                        })
                  }
                  // disabled={usertype && usertype === "2"}
                  className="custom-checkbox"
                  label="Kare Kodsuz Sertifikalı"
                />
              </div>
            </div>
          </div>
          <div className="form-group col-md-4">
            <div className="form-wrapper">
              <div className="item row">
                <Dropdown
                  id="certificateStatusTypeId"
                  onChanged={(event: any) => {
                    if (event.key !== 2) {
                      setFormData("CertificateNo", "");
                      setFormData("confirmationDate", 0);
                    }
                    setFormData("certificateStatusTypeId", event.key);
                  }}
                  errorMessage={formErrors.certificateStatusTypeId}
                  label="Sertifika Durumu"
                  options={(
                    this.props.formData.GroupListCR?.dataList || ([] as any)
                  ).map((item: any) => ({
                    key: item.CertificateStatusTypeId,
                    text: item.Title,
                  }))}
                  className="custom-dropdown"
                  selectedKey={this.props.formData.certificateStatusTypeId}
                />
              </div>

              {this.props.formData.certificateStatusTypeId === 2 ? (
                this.props.formData.prefix ? (
                  <>
                    <div className="item row">
                      <DatePicker
                        strings={DayPickerStrings}
                        firstDayOfWeek={firstDayOfWeek}
                        onSelectDate={(date) =>
                          setFormData(
                            "confirmationDate",
                            toTimeStamp(date?.toString())
                          )
                        }
                        label="Sertifika Düzenleme Tarihi"
                        placeholder="gün.ay.yıl"
                        formatDate={onFormatDate}
                        value={this.getDate(formData.confirmationDate)}
                        maxDate={sertifikaTarihiBayram(usertype)}
                      />
                      <PrimaryButton
                        id="confirmationDate"
                        onClick={(x) =>
                          this.props.setFormData("confirmationDate", 0)
                        }
                        iconProps={{ iconName: "Trash" }}
                        text="Tarihi Sil"
                        className="deleteDateBtn"
                        styles={{
                          root: {
                            marginTop: 5,
                            background: "#ac6262",
                            borderColor: "#ac6262",
                          },
                          rootHovered: {
                            background: "#a34c4c",
                            borderColor: "#a34c4c",
                          },
                          rootPressed: {
                            background: "#a34c4c",
                            borderColor: "#a34c4c",
                          },
                          rootDisabled: {
                            background: "#ececec",
                          },
                        }}
                      />
                    </div>

                    <div className="item row">
                      <TextField
                        id="CertificateNo"
                        placeholder="Sertifika No"
                        value={formData.CertificateNo || ""}
                        label="Sertifika No"
                        className="custom-textfield border"
                        onChange={(event: any) =>
                          setFormData("CertificateNo", event.target.value)
                        }
                        errorMessage={formErrors.CertificateNo}
                      />
                    </div>
                    {usertype && usertype !== "2" && (
                      <div className="item row">
                        <Dropdown
                          onChanged={(event: any) =>
                            setFormData("isPublishQr", event.key)
                          }
                          label="Sertifika QR Kodu Yayımlansın mı?"
                          options={[
                            { key: false, text: "Hayır" },
                            { key: true, text: "Evet" },
                          ].map((item: any) => ({
                            key: item.key,
                            text: item.text,
                          }))}
                          className="custom-dropdown"
                        />
                      </div>
                    )}
                  </>
                ) : (
                  this.showDialog({
                    title: "Uyarı",
                    desc: "Sertifika Numarası için firma kodu Yok <br/> Denetleyici Firma Seçiniz",
                  })
                )
              ) : (
                ""
              )}
              <ValidityDate />
            </div>
          </div>
        </aside>
      </div>
    );
  }
  showDialog(req: any): any {
    this.props.showErrorDialog(req);
  }
  setPrefix(): any {
    const el = document.createElement("span");
    el.className = "CertificateNo-span prefixSpan";
    el.id = "CertificateNo-span";
    el.innerHTML =
      usertype === "2"
        ? cookie.load("prefix") + "-"
        : this.props.formData.prefix + "-";
    const div = document.getElementById("CertificateNo");
    if (!document.getElementById("CertificateNo-span")) {
      this.insertBefore(div, el);
    } else {
      document.getElementById("CertificateNo-span")?.remove();
      this.insertBefore(div, el);
    }
    return false;
  }

  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Yeni Havalimanı Sertifika Ekle"
          titleRenderer={() => (
            <span className="edit-title">
              Yönet:
              <strong>Yeni Havalimanı Sertifika Ekle</strong>
            </span>
          )}
          showBack
          backUrl="/Airport"
          history={this.props.history}
          subTitle="Yeni bir Havalimanı Sertifikası eklemek için aşağıdaki bilgileri doldurunuz."
          right={
            <FormButtons
              isAbsolute
              onSubmit={() => this.onSubmit()}
              customRenderButtons={() => (
                <React.Fragment>
                  <PrimaryButton
                    onClick={() => this.onSubmit()}
                    disabled={
                      this.state.messageType === MessageBarType.success ||
                      this.state.isFileUploading
                        ? this.state.isFileUploading
                          ? true
                          : false
                        : false
                    }
                    styles={{
                      rootDisabled: {
                        background: "#626262",
                        borderColor: "#777373 !important",
                      },
                    }}
                    iconProps={{ iconName: "CheckMark" }}
                    text="Yayımla"
                  />
                  {this.state.isFileUploading && (
                    <div className="tooltip-warning">
                      Bazı dosyalar yükleniyor, lütfen dosyalar yüklenene kadar
                      bekleyiniz.
                    </div>
                  )}
                </React.Fragment>
              )}
            />
          }
          rightType="create"
        />
        {/* {this.renderMessageBar()} */}

        {this.renderForm()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAirport);
