import { HttpClient } from "src/library/HttpClient";
import {
  WinterTourismSubCategoryChangeStatusRequestModel,
  WinterTourismSubCategoryGetByIdRequestModel,
  WinterTourismSubCategoryUpdateRequestModel,
  WinterTourismSubCategorySaveRequestModel,
  WinterTourismSubCategoryGetAllRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class WinterTourismSubCategory {
  async getAll(
    request: WinterTourismSubCategoryGetAllRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismSubCategory + "GetAll",
      { ...request, token }
    );
  }

  async save(request: WinterTourismSubCategorySaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismSubCategory + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(
    request: WinterTourismSubCategoryUpdateRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismSubCategory + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(
    request: WinterTourismSubCategoryGetByIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismSubCategory + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(
    request: WinterTourismSubCategoryChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismSubCategory + "ChangeStatus",
      { ...request, token }
    );
  }
}
