import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { panels } from "src/utils/constants";
import { Link } from "react-router-dom";
import { History } from "history";
import BasicTable from "../BasicTable";
import {
  TextField,
  Dropdown,
  PrimaryButton,
  Label,
  ComboBox,
} from "office-ui-fabric-react";
import {
  getAll,
  changeStatus,
  getAllPage,
  getAllForPager,
  GetHotelCertificationAndRestaurantCount,
  getAllHotelCertificationANDRestaurant,
  getComparisonAll,
} from "src/store/thunkHotelCertification";
import {
  HotelCertificationGetAllRequestModel,
  HotelCertificationChangeStatusRequestModel,
} from "src/services/HotelCertification/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";
import { castThunkAction } from "src/helpers/casting";
import { toggleConfirmDialog } from "src/store/system/actions";
import { listCount } from "src/config/otherConfig";
import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import { getAllCR } from "src/store/thunkCertificateStatusType";
import { getCompanyUserList } from "src/store/thunksSystemUser";

import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";

import { getAllLSU } from "src/store/thunkLayoverSubCategory";
import { CertificateStatusTypeGetAllRequestModel } from "src/services/CertificateStatusType/RequestModel";
import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";
import cookie from "react-cookies";
// @ts-ignore
import ReactExport from "react-export-excel";
import { LayoverSubCategoryGetAllRequestModel } from "src/services/LayoverSubCategory/RequestModel";
import { formatDate } from "src/helpers/converts";
import { updateNotification } from "src/store/thunkNotification";
import { NotificationUpdateRequestModel } from "src/services/Notification/RequestModel";
import { filterModal, getRecordStatusAll, RECORD_STATUS_ALL_ENUM } from "src/helpers/functions";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
let cityRegions: any[] = [];
let regionList: any[] = [
  "MARMARA",
  "KARADENİZ",
  "EGE",
  "İÇ ANADOLU",
  "DOĞU ANADOLU",
  "AKDENİZ",
  "GÜNEYDOĞU ANADOLU",
  "GENEL TOPLAM",
];
interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}
const intitialState = {
  filterData: {
    searchKey: "",
    districtId: 0,
    status: 1,
    cityId: 0,
    LayoverSubCategoryId: 0,
    isPublishWeb: null,
    certificateStatusTypeId: 0,
    companyUserId: 0,
    correctionType: null,
    isPublishQr: 0,
    orderField: "UpdatedSystemUserDatetime",
    isDescending: true,
    hotelId: 0,
    certificateStatus: null,
  },
  pagerCurrent: 0,
  pagerTotal: 0,
  excel: false,
  download: false,
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        getAll,
        changeStatus,
        toggleConfirmDialog,
        getAllM,
        getAllC,
        getAllLSU,
        getAllCR,
        getAllPage,
        getCompanyUserList,
        getAllForPager,
        GetHotelCertificationAndRestaurantCount,
        updateNotification,
        getAllHotelCertificationANDRestaurant,
        getComparisonAll,
      },
      dispatch
    ),
  };
}

type HotelCertificationProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  excel: boolean;
  pagerCurrent: number;
  pagerTotal: number;
  download: boolean;
}
let usertype: any = 0;

class HotelCertification extends Component<HotelCertificationProps, OwnState> {
  state: any = intitialState;


  componentDidMount() {

    filterModal(this, "HotelCertificationListStateData").finally(() =>
      this.getData(this.state.pagerCurrent)

    );
    usertype = cookie.load("systemUserInfo");
    cityRegions = require("../../cityRegions.json");
  }




  getData(pagerSelected: number) {
    const request: HotelCertificationGetAllRequestModel = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...this.state.filterData,
    };

    this.setState({ excel: false });
    request["isPublishQr"] =
      request["isPublishQr"] === "1"
        ? true
        : request["isPublishQr"] === "2"
          ? false
          : null;

    castThunkAction(this.props.getComparisonAll(request)).then(
      (response: any) => {
        castThunkAction(
          this.props.GetHotelCertificationAndRestaurantCount(request)
        ).then((response: any) => { });
        // castThunkAction(this.props.getAllHotelCertificationANDRestaurant(request)).then((response: any) => {
        // })

        const request2: CityGetAllRequestModel = {
          selectItemList: ["CityId", "CityName"],
          orderField: "CityName",
        };

        this.props.getAllM(request2);

        const request3: LayoverSubCategoryGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "Status",
              value: 1,
              isOr: false,
              conditionOperator: 0,
            },
          ],
          selectItemList: ["LayoverSubCategoryId", "LayoverSubCategoryName"],
        };
        this.props.getAllLSU(request3);

        const request4: CertificateStatusTypeGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "Status",
              value: 1,
              isOr: false,
              conditionOperator: 0,
            },
          ],
          selectItemList: ["CertificateStatusTypeId", "Title"],
        };
        this.props.getAllCR(request4);

        const request6: SystemUserGetAllModel = {
          systemUserType: 2,
        };
        this.props.getCompanyUserList(request6);

        this.state.filterData.cityId !== 0 &&
          this.state.filterData.cityId !== null &&
          this.CityChange(this.state.filterData.cityId);
      }
    );
  }

  getDataPager(pagerSelected: number) {
    const request: HotelCertificationGetAllRequestModel = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...this.state.filterData,
    };

    this.setState({ excel: false });
    request["isPublishQr"] =
      request["isPublishQr"] === "1"
        ? true
        : request["isPublishQr"] === "2"
          ? false
          : null;

    castThunkAction(this.props.getAllForPager(request)).then(
      (response: any) => {
        castThunkAction(
          this.props.GetHotelCertificationAndRestaurantCount(request)
        ).then((response: any) => { });
        // castThunkAction(this.props.getAllHotelCertificationANDRestaurant(request)).then((response: any) => {
        // })
      }
    );
  }

  onDelete(HotelCertificationId: number) {
    const request: HotelCertificationChangeStatusRequestModel = {
      id: HotelCertificationId,
      status: StatusEnums.Deleted,
    };
    castThunkAction(this.props.changeStatus(request)).then((response: any) => {
      this.getData(this.state.pagerCurrent);
    });
  }

  renderDashboardItems() {
    return (
      <div className="dashboard-items row">
        {panels.map(
          (item, index) =>
            item.children && (
              <React.Fragment>
                <div className="title">
                  {item.text}
                  <div className="title-text">{item.subText}</div>
                </div>
                <div className="childrens row">
                  {item.children.map((children, childrenIndex) => (
                    <div key={index} className={`dashboard-item col-md-3`}>
                      <Link
                        className="row"
                        to={children.url ? children.url : "/"}
                      >
                        <div className="icon">
                          <i className={children.icon}></i>
                        </div>
                        <div className="title">
                          {children.text}
                          <div className="sub-text">{children.subText}</div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )
        )}
      </div>
    );
  }
  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Tümünü Seç" });
    }

    return newArray;
  }

  CityChange(item: any) {
    this.setState(
      {
        filterData: {
          ...this.state.filterData,
          cityId: item,
        },
      },
      () => {
        const request2: CountyGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "cityId",
              value: item,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
            {
              propertyName: "Status",
              value: 1,
              isOr: false,
              conditionOperator: 0,
            },
          ],
          selectItemList: ["DistrictId", "DistrictName"],
          orderField: "DistrictName",
        };
        this.props.getAllC(request2);
      }
    );
  }

  renderFilter() {
    return (
      <div className="table-filter row">
        <TextField
          className="custom-textfield col-md-3"
          label="Tesis Adı"
          value={this.state.filterData?.searchKey || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                searchKey: event.target.value,
              },
            });
          }}
        />
        <TextField
          className="custom-textfield col-md-1"
          label="Tesis ID"
          value={this.state.filterData?.hotelId || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                hotelId: event.target.value === "" ? 0 : event.target.value,
              },
            });
          }}
        />

        <TextField
          className="custom-textfield col-md-2"
          label="Belge Sahibi / Ticari Ünvan"
          value={this.state.filterData?.companyName || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                companyName: event.target.value,
              },
            });
          }}
        />
        <Dropdown
          defaultSelectedKey={this.state.filterData?.cityId || ""}
          onChanged={(event: any) => {
            this.CityChange(event.key)
            this.setState({
              filterData: {
                ...this.state.filterData,
                cityId: event.key,
              },
            })
          }}
          label="İl"
          options={(this.props.formData.GroupList?.dataList
            ? this.returnOptions(
              this.props.formData.GroupList?.dataList,
              "CityId",
              "CityName"
            )
            : ([] as any)
          ).map((item: any) => ({ key: item.CityId, text: item.CityName }))}
          className="custom-dropdown col-md-2"
        />
        <Dropdown
          defaultSelectedKey={this.state.filterData?.districtId || ""}
          onChanged={(event: any) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                districtId: event.key,
              },
            })
          }
          label="İlçe"
          options={(this.props.formData.GroupListC?.dataList
            ? this.returnOptions(
              this.props.formData.GroupListC?.dataList,
              "DistrictId",
              "DistrictName"
            )
            : ([] as any)
          ).map((item: any) => ({
            key: item.DistrictId,
            text: item.DistrictName,
          }))}
          className="custom-dropdown col-md-2"
        />

        <Dropdown
          defaultSelectedKey={this.state.filterData?.LayoverSubCategoryId || ""}
          onChanged={(event: any) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                LayoverSubCategoryId: event.key,
              },
            })
          }
          label="Tesis Kategorisi"
          options={(this.props.formData.GroupListSU?.dataList
            ? this.returnOptions(
              this.props.formData.GroupListSU?.dataList,
              "LayoverSubCategoryId",
              "LayoverSubCategoryName"
            )
            : ([] as any)
          ).map((item: any) => ({
            key: item.LayoverSubCategoryId,
            text: item.LayoverSubCategoryName,
          }))}
          className="custom-dropdown col-md-2"
        />

        <Dropdown
          options={(this.props.formData.GroupListCR?.dataList
            ? this.returnOptions(
              this.props.formData.GroupListCR?.dataList,
              "CertificateStatusTypeId",
              "Title"
            )
            : ([] as any)
          ).map((item: any) => ({
            key: item.CertificateStatusTypeId,
            text: item.Title,
          }))}
          className="custom-dropdown col-md-2"
          label="Sertifika Durumu"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData.certificateStatusTypeId}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                certificateStatusTypeId: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />

        {usertype && usertype !== "2" && usertype !== "5" ? (
          <ComboBox
            options={(this.props.formData.CompanyUserList?.dataList
              ? this.returnOptions(
                this.props.formData.CompanyUserList?.dataList,
                "systemUserId",
                "title"
              )
              : ([] as any)
            ).map((item: any) => ({
              key: item.systemUserId,
              text: item.title,
            }))}
            allowFreeform={true}
            autoComplete="on"
            className="custom-dropdown col-md-2"
            label="Denetleyici Firma"
            placeholder="Seçiniz"
            selectedKey={this.state.filterData.companyUserId}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  companyUserId: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />
        ) : (
          ""
        )}

        <Dropdown
          options={[
            { key: "", text: "Seçiniz" },
            { key: 1, text: "Revize İstendi" },
            { key: 2, text: "Revize Yapıldı" },
            { key: 3, text: "Revize Onaylandı" },
            { key: 4, text: "Revize Kaydı Olanlar" },
            { key: 5, text: "Revizeye Girmemişler" },
          ]}
          className="custom-dropdown col-md-2"
          label="Revize Durumu"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.correctionType || null}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                correctionType: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: 0, text: "Seçiniz" },
            { key: 1, text: "Sertifika Uzatmalı Kapalı" },
            { key: 2, text: "Firma Sertifika Sürecinden Ayrıldı" },
            { key: 3, text: "Kare Kodsuz Sertifikalı" },
          ]}
          className="custom-dropdown col-md-2"
          label="Durum"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.certificateStatus || null}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                certificateStatus: option.key === 0 ? null : option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />

        <Dropdown
          options={[
            { key: "BrandName", text: "Tesis Adı" },
            { key: "CreatedSystemUserDatetime", text: "Oluşturma Tarihi" },
            { key: "UpdatedSystemUserDatetime", text: "Güncelleme Tarihi" },
          ]}
          className="custom-dropdown col-md-2"
          label="Sıralama"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.orderField || null}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                orderField: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={(this.state.filterData.orderField === "BrandName"
            ? [
              { key: false, text: "Baştan Sona" },
              { key: true, text: "Sondan Başa" },
            ]
            : [
              { key: false, text: "Eskiden Yeniye" },
              { key: true, text: "Yeniden Eskiye" },
            ]
          ).map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-2"
          label="Sıralama Türü"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData.isDescending}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isDescending: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={RECORD_STATUS_ALL_ENUM}
          className="custom-dropdown col-md-2"
          label="Kayıt Durumu"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.status || 1}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                status: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: "", text: "Seçiniz" },
            { key: "false", text: "Hayır" },
            { key: "true", text: "Evet" },
          ].map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-2"
          label="Son Kayıtta Webde Yayımlanma"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData.isPublishWeb}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isPublishWeb: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: "false", text: "Webde Gösterilmeyenler" },
            { key: "true", text: "Webde göster olanlar" },
          ].map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-2"
          label="Eski Kayıtlar Dahil Webde Göster"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.isHistoryPublishWeb || ''}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isHistoryPublishWeb: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: "0", text: "Seçiniz" },
            { key: "1", text: "Evet" },
            { key: "2", text: "Hayır" },
          ]}
          className="custom-dropdown col-md-2"
          label="Son Kayıtta QR Yayımlanma"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.isPublishQr || null}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isPublishQr: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: "false", text: "QR Yayımda Olmayanlar" },
            { key: "true", text: "QR Yayımda Olanlar" },
          ].map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-2"
          label="Eski Kayıtlar Dahil QR Yayım"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData.isHistoryPublishQr}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isHistoryPublishQr: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <TextField
          className="custom-textfield col-md-1"
          label="Kayıt No"
          value={this.state.filterData?.sequenceId || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                sequenceId: event.target.value !== "" ? event.target.value : 0,
              },
            });
          }}
        />
        <PrimaryButton
          text="Arama Yap"
          className="custom-button"
          onClick={() => {
            cookie.save(
              "HotelCertificationListStateData",
              JSON.stringify(this.state),
              {
                path: "/",
              }
            )
            this.getDataPager(0)
          }}
          style={{ marginLeft: "12px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
        {this.rederexcel()}

        <PrimaryButton
          text="Tüm Filtreleri Temizle"
          className="custom-button"
          iconProps={{ iconName: "trash" }}
          onClick={() =>
            this.setState({
              filterData: {
                searchKey: "",
                districtId: 0,
                status: 1,
                cityId: 0,
                LayoverSubCategoryId: 0,
                isPublishWeb: null,
                certificateStatusTypeId: 0,
                companyUserId: 0,
                correctionType: null,
                isPublishQr: 0,
                orderField: "UpdatedSystemUserDatetime",
                isDescending: true,
                hotelId: 0,
                certificateStatus: null,
              },
            }, () => {
              cookie.save(
                "HotelCertificationListStateData",
                JSON.stringify(this.state),
                {
                  path: "/",
                }
              );
              this.getDataPager(0)
            })
          }
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
              background: "#FF4343",
              borderColor: "#FF4350",
            },
          }}
        />
        {/* {usertype && usertype !== "2" && usertype !== "5" ? this.rederexcel() : ""} */}
      </div>
    );
  }

  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "hotelId",
            text: "Kayıt No",
            col: "col-md-1",
          },
          {
            columnName: "cityDistrictName",
            text: "İl / İlçe",
            col: "col-md-1",
          },
          {
            columnName: "companyName",
            text: "Belge Sahibi / Ticari Ünvan",
            col: "col-md-1",
          },

          {
            columnName: "brandName",
            text: "Tesis Adı",
            col: "col-md-1",
          },
          {
            columnName: "certificateNo",
            text: "Tesis Kategorisi / Sınıfı",
            col: "col-md-1",
            onRender: (item: {
              layoverSubCategoryName: any;
              layoverFacilityTypeName: any;
            }) => (
              <React.Fragment>
                {item.layoverSubCategoryName +
                  (item.layoverFacilityTypeName !== ""
                    ? " / " + item.layoverFacilityTypeName
                    : "")}
              </React.Fragment>
            ),
          },
          {
            columnName: "companyUserTitle",
            text: "Denetleyici Firma",
            col: "col-md-1",
          },
          {
            columnName: "webSite",
            text: "Tesisin Web Sitesi",
            col: "col-md-1",
            className: "operations",
            onRender: (item: { webSite: any }) => (
              <React.Fragment>
                {item.webSite !== "" &&
                  item.webSite !== "null" &&
                  item.webSite !== null ? (
                  <a
                    href={
                      item.webSite?.includes("http")
                        ? item.webSite
                        : "http://" + item.webSite
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon-edit-outline"></i> Link
                  </a>
                ) : (
                  ""
                )}
              </React.Fragment>
            ),
          },
          {
            columnName: "certificateStatusTypeName",
            text: "Durum",
            col: "col-md-1",
          },

          {
            columnName: "certificateNo",
            text: "Sertifika No",
            col: "col-md-1",
            onRender: (item: {
              certificateNo: any;
              certificateStatusTypeId: any;
            }) => (
              <React.Fragment>
                {item.certificateStatusTypeId === 2 ? item.certificateNo : ""}
              </React.Fragment>
            ),
          },

          {
            columnName: "firstConfirmationDateString",
            text: "İlk Sertifika Düzenleme Tarihi",
            col: "col-md-1",
          },
          {
            columnName: "confirmationDateString",
            text: "Sertifika Düzenleme Tarihi",
            col: "col-md-1",
            onRender: (item: {
              confirmationDateString: any;
              certificateStatusTypeId: any;
            }) => (
              <React.Fragment>
                {item.certificateStatusTypeId === 2
                  ? item.confirmationDateString
                  : ""}
              </React.Fragment>
            ),
          },
          {
            columnName: "hotelCertificateId",
            text: "İşlem",
            col: "col-md-1",
            className: "operations",
            onRender: (item: {
              hotelCertificateId: any;
              Status: StatusEnums;
            }) => (
              <React.Fragment>
                {
                  <button onClick={(x) => this.goEdit(item.hotelCertificateId)}>
                    <i className="icon-edit-outline"></i> Düzenle
                  </button>
                }
                {item.Status !== StatusEnums.Deleted &&
                  usertype !== "2" &&
                  usertype !== "5" && (
                    <button
                      onClick={() =>
                        this.props.toggleConfirmDialog(true, "Silme işlemini gerçekleştirmek istiyor musunuz?", () =>
                          this.onDelete(item.hotelCertificateId)
                        )
                      }
                    >
                      <i className="icon-trash-outline"></i> Sil
                    </button>
                  )}
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.dataList || []}
      />
    );
  }
  goEdit(HotelCertificationCertificateId: any): any {
    const request: NotificationUpdateRequestModel = {
      CategoryType: "1",
      RelatedId: HotelCertificationCertificateId,
      isRead: true,
    };
    castThunkAction(this.props.updateNotification(request)).then(
      (response: any) => {
        window.open(
          "/HotelCertification/edit/" + HotelCertificationCertificateId
        );
      }
    );
  }
  downloadexcel() {
    this.setState({ download: true });
    const requestexcel: HotelCertificationGetAllRequestModel = {
      ...this.state.filterData,
    };
    requestexcel["isPublishQr"] =
      requestexcel["isPublishQr"] === "1"
        ? true
        : requestexcel["isPublishQr"] === "2"
          ? false
          : null;

    castThunkAction(
      this.props.getAllHotelCertificationANDRestaurant(requestexcel)
    ).then((response: any) => {
      const list = this.props.formData.HotelCertificationListForExcel?.dataList;
      this.props.formData.RestaurantListForExcel?.dataList.length > 0 &&
        list.push(...this.props.formData.RestaurantListForExcel?.dataList);
      this.setState({ excel: true, download: false });
    });
  }

  rederexcel() {
    const excelName = "KonaklamaTesisleriVeRestoran_" + formatDate();
    return this.state.excel ? (
      <ExcelFile
        filename={excelName}
        element={
          <PrimaryButton
            text="Raporu İndir"
            className="custom-button"
            style={{ marginLeft: "15px" }}
            styles={{
              root: {
                fontSize: "11px",
                marginTop: "27px",
                height: "30px",
              },
            }}
          />
        }
      >
        <ExcelSheet
          data={this.props.formData?.HotelCertificationListForExcel?.dataList}
          name="Tr"
        >
          <ExcelColumn label="Kayıt No" value="sequenceId" />
          <ExcelColumn
            label="Bölge"
            value={(col: any) =>
              col.cityName && col.cityName !== ""
                ? cityRegions.filter((x) => x.city === col.cityName)[0]?.region
                : ""
            }
          />
          <ExcelColumn label="İl" value="cityName" />
          <ExcelColumn label="İlçe" value="districtName" />
          <ExcelColumn label="Tesis E-posta Adresi" value="hotelEmail" />
          <ExcelColumn label="Tesis Telefon Numarası" value="hotelPhone" />
          <ExcelColumn
            label="Tesis Genel Müdürünün Adı ve Soyadı"
            value="hotelManagerName"
          />
          <ExcelColumn
            label="Belge Sahibi / Ticari Ünvan"
            value="companyName"
          />
          <ExcelColumn label="Belge No" value="documentNo" />
          <ExcelColumn label="Tesis Adı" value="brandName" />
          <ExcelColumn label="Tesis Türü" value="categoryName" />
          <ExcelColumn
            label="Sertifika Uzatmalı Kapalı"
            value={(col: any) =>
              col.certificateStatus === 1 ||
                col.certificateStatus === 4 ||
                col.certificateStatus === 5 ||
                col.certificateStatus === 7
                ? "Evet"
                : ""
            }
          />
          <ExcelColumn
            label="Firma Sertifika Sürecinden Ayrıldı"
            value={(col: any) =>
              col.certificateStatus === 2 ||
                col.certificateStatus === 5 ||
                col.certificateStatus === 6 ||
                col.certificateStatus === 7
                ? "Evet"
                : ""
            }
          />
          <ExcelColumn
            label="Kare Kodsuz Sertifikalı"
            value={(col: any) =>
              col.certificateStatus === 3 ||
                col.certificateStatus === 4 ||
                col.certificateStatus === 6 ||
                col.certificateStatus === 7
                ? "Evet"
                : ""
            }
          />
          <ExcelColumn
            label="Tesis Kategorisi"
            value="layoverSubCategoryName"
          />
          <ExcelColumn
            label="Kayıt Durumu"
            value={(col: any) => getRecordStatusAll(col.status)

            }
          />
          <ExcelColumn label="Tesis Sınıfı" value="layoverFacilityTypeName" />
          <ExcelColumn label="Tesis Belge Tipi" value="certificateTypeName" />
          <ExcelColumn label="Tesis Oda Sayısı" value="roomCount" />
          <ExcelColumn label="Denetleyici Firma" value="companyUserTitle" />
          <ExcelColumn label="Tesisin Web Sitesi" value="webSite" />
          <ExcelColumn label="Durum" value="certificateStatusTypeName" />
          <ExcelColumn
            label="Sertifika Uzatmalı Kapalı Bitiş Tarihi"
            value="extendedClosedDateString"
          />
          <ExcelColumn
            label="Sertifika No"
            value={(col: any) =>
              col.certificateStatusTypeId === 2 ? col.certificateNo : ""
            }
          />
          <ExcelColumn
            label="Sertifika Başvuru Tarihi"
            value="appealDateString"
          />
          <ExcelColumn
            label="Sertifika Düzenleme Tarihi"
            value={(col: any) =>
              col.certificateStatusTypeId === 2
                ? col.confirmationDateString
                : ""
            }
          />
          <ExcelColumn
            label="Webde Yayımlanma"
            value={(col: any) => (col.isPublishWeb ? "Evet" : "Hayır")}
          />
          <ExcelColumn label="Güncelleyen Kullanıcı" value="updatedUserName" />
          <ExcelColumn
            label="Güncelleme Tarihi"
            value={(col: any) => col.updateDateString?.split(" ")[0]}
          />
          <ExcelColumn
            label="Güncelleme Saati"
            value={(col: any) => col.updateDateString?.split(" ")[1]}
          />
          <ExcelColumn
            label="Belge"
            value={(col: any) =>
              col.attachmentList
                ? col.attachmentList.length > 0
                  ? "Var"
                  : "Yok"
                : "Yok"
            }
          />
          <ExcelColumn
            label="Revize Durumu"
            value={(col: any) =>
              [
                { key: 1, text: "Revize İstendi" },
                { key: 2, text: "Revize Yapıldı" },
                { key: 3, text: "Revize Onaylandı" },
                { key: null, text: "Revize Yok" },
              ].filter((x) => x.key === col.correctionType)[0].text
            }
          />
          <ExcelColumn
            label="Revize Açıklaması"
            value="correctionDescription"
          />
          <ExcelColumn
            label="Covid Testi Kiti"
            value={(col: any) => (col.isHaveHealthKit ? "Var" : "Yok")}
          />
          <ExcelColumn
            label="Tesis Faaliyet Durumu"
            value={(col: any) => (col.isOpen ? "Açık" : "Kapalı")}
          />
        </ExcelSheet>
        <ExcelSheet
          data={this.props.formData?.HotelCertificationListForExcel?.dataList}
          name="En"
        >
          <ExcelColumn label="Registration Number" value="sequenceId" />
          <ExcelColumn
            label="Region"
            value={(col: any) =>
              col.cityName && col.cityName !== ""
                ? cityRegions.filter((x) => x.city === col.cityName)[0]?.region
                : ""
            }
          />
          <ExcelColumn
            label="Sertifika Uzatmalı Kapalı"
            value={(col: any) =>
              col.certificateStatus === 1 ||
                col.certificateStatus === 4 ||
                col.certificateStatus === 5 ||
                col.certificateStatus === 7
                ? "Evet"
                : ""
            }
          />
          <ExcelColumn
            label="Firma Sertifika Sürecinden Ayrıldı"
            value={(col: any) =>
              col.certificateStatus === 2 ||
                col.certificateStatus === 5 ||
                col.certificateStatus === 6 ||
                col.certificateStatus === 7
                ? "Evet"
                : ""
            }
          />
          <ExcelColumn
            label="Kare Kodsuz Sertifikalı"
            value={(col: any) =>
              col.certificateStatus === 3 ||
                col.certificateStatus === 4 ||
                col.certificateStatus === 6 ||
                col.certificateStatus === 7
                ? "Evet"
                : ""
            }
          />
          <ExcelColumn label="City" value="cityName" />
          <ExcelColumn label="District" value="districtName" />
          <ExcelColumn label="Company Name" value="companyName" />
          <ExcelColumn label="Document No" value="documentNo" />
          <ExcelColumn label="Facility Name" value="brandName" />
          <ExcelColumn label="Category" value="categoryName_En" />
          <ExcelColumn
            label="Facility Category"
            value="layoverSubCategoryName_En"
          />
          <ExcelColumn
            label="Facility Type"
            value="layoverFacilityTypeName_En"
          />
          <ExcelColumn label="Document Type" value="certificateTypeName_En" />
          <ExcelColumn label="Certification Company" value="companyUserTitle" />
          <ExcelColumn label="Website" value="webSite" />
          <ExcelColumn label="Status" value="certificateStatusTypeName_En" />
          <ExcelColumn
            label="Certificate Extension Closed Date"
            value="extendedClosedDateString"
          />
          <ExcelColumn
            label="Certificate Number"
            value={(col: any) =>
              col.certificateStatusTypeId === 2 ? col.certificateNo : ""
            }
          />
          <ExcelColumn label="Appeal Date" value="appealDateString" />
          <ExcelColumn
            label="Certificate Date"
            value={(col: any) =>
              col.certificateStatusTypeId === 2
                ? col.confirmationDateString
                : ""
            }
          />
          <ExcelColumn
            label="Web Published"
            value={(col: any) => (col.isPublishWeb ? "Yes" : "No")}
          />
          <ExcelColumn label="Updated User" value="updatedUserName" />
          <ExcelColumn
            label="Updated Date"
            value={(col: any) => col.updateDateString?.split(" ")[0]}
          />
          <ExcelColumn
            label="Updated Time"
            value={(col: any) => col.updateDateString?.split(" ")[1]}
          />
          <ExcelColumn
            label="Attachment"
            value={(col: any) =>
              col.attachmentList
                ? col.attachmentList.length > 0
                  ? "Yes"
                  : "No"
                : "No"
            }
          />
          <ExcelColumn
            label="Revised Status"
            value={(col: any) =>
              [
                { key: 1, text: "Revised Requested" },
                { key: 2, text: "Revised" },
                { key: 3, text: "Revised Approved" },
                { key: null, text: "Non Revision" },
              ].filter((x) => x.key === col.correctionType)[0].text
            }
          />
          <ExcelColumn
            label="Revised Description"
            value="correctionDescription"
          />
        </ExcelSheet>

        <ExcelSheet
          data={regionList}
          name="Bakanlık Belgeli Konaklama Tesis Sayısı"
        >
          <ExcelColumn label="BÖLGE" value={(col: any) => col} />

          <ExcelColumn
            label="BELGE VERİLDİ"
            value={(col: any) =>
              col === "GENEL TOPLAM"
                ? this.props.formData?.HotelCertificationListForExcel?.dataList
                  .filter((x: any) => x.categoryName === "KONAKLAMA TESİSİ")
                  .filter(
                    (x: any) =>
                      x.certificateStatusTypeId === 2 &&
                      x.certificateTypeId === 4
                  ).length
                : this.props.formData?.HotelCertificationListForExcel?.dataList
                  .filter((x: any) => x.categoryName === "KONAKLAMA TESİSİ")
                  .filter(
                    (x: any) =>
                      x.region === col &&
                      x.certificateStatusTypeId === 2 &&
                      x.certificateTypeId === 4
                  ).length
            }
          />
          <ExcelColumn
            label="BELGELENDİRME SÜRECİNDE"
            value={(col: any) =>
              col === "GENEL TOPLAM"
                ? this.props.formData?.HotelCertificationListForExcel?.dataList
                  .filter((x: any) => x.categoryName === "KONAKLAMA TESİSİ")
                  .filter(
                    (x: any) =>
                      x.certificateStatusTypeId === 1 &&
                      x.certificateTypeId === 4
                  ).length
                : this.props.formData?.HotelCertificationListForExcel?.dataList
                  .filter((x: any) => x.categoryName === "KONAKLAMA TESİSİ")
                  .filter(
                    (x: any) =>
                      x.region === col &&
                      x.certificateStatusTypeId === 1 &&
                      x.certificateTypeId === 4
                  ).length
            }
          />
          <ExcelColumn
            label="UYGUN GÖRÜLMEDİ"
            value={(col: any) =>
              col === "GENEL TOPLAM"
                ? this.props.formData?.HotelCertificationListForExcel?.dataList
                  .filter((x: any) => x.categoryName === "KONAKLAMA TESİSİ")
                  .filter(
                    (x: any) =>
                      x.certificateStatusTypeId === 5 &&
                      x.certificateTypeId === 4
                  ).length
                : this.props.formData?.HotelCertificationListForExcel?.dataList
                  .filter((x: any) => x.categoryName === "KONAKLAMA TESİSİ")
                  .filter(
                    (x: any) =>
                      x.region === col &&
                      x.certificateStatusTypeId === 5 &&
                      x.certificateTypeId === 4
                  ).length
            }
          />
          <ExcelColumn
            label="Genel Toplam"
            value={(col: any) =>
              col === "GENEL TOPLAM"
                ? this.props.formData?.HotelCertificationListForExcel?.dataList
                  .filter((x: any) => x.categoryName === "KONAKLAMA TESİSİ")
                  .filter((x: any) => x.certificateTypeId === 4).length
                : this.props.formData?.HotelCertificationListForExcel?.dataList
                  .filter((x: any) => x.categoryName === "KONAKLAMA TESİSİ")
                  .filter(
                    (x: any) => x.region === col && x.certificateTypeId === 4
                  ).length
            }
          />

          {/*  <ExcelColumn label="Region"
              value={(col: any) => col.cityName && col.cityName != "" ? cityRegions.filter(x => x.city === col.cityName)[0]?.region : ""} /> */}
        </ExcelSheet>
        <ExcelSheet
          data={regionList}
          name="Belediye ve Diğer Belgeli Konaklama Tesis Sayısı"
        >
          <ExcelColumn label="BÖLGE" value={(col: any) => col} />

          <ExcelColumn
            label="BELGE VERİLDİ"
            value={(col: any) =>
              col === "GENEL TOPLAM"
                ? this.props.formData?.HotelCertificationListForExcel?.dataList
                  .filter((x: any) => x.categoryName === "KONAKLAMA TESİSİ")
                  .filter(
                    (x: any) =>
                      x.certificateStatusTypeId === 2 &&
                      x.certificateTypeId === 5
                  ).length
                : this.props.formData?.HotelCertificationListForExcel?.dataList
                  .filter((x: any) => x.categoryName === "KONAKLAMA TESİSİ")
                  .filter(
                    (x: any) =>
                      x.region === col &&
                      x.certificateStatusTypeId === 2 &&
                      x.certificateTypeId === 5
                  ).length
            }
          />
          <ExcelColumn
            label="BELGELENDİRME SÜRECİNDE"
            value={(col: any) =>
              col === "GENEL TOPLAM"
                ? this.props.formData?.HotelCertificationListForExcel?.dataList
                  .filter((x: any) => x.categoryName === "KONAKLAMA TESİSİ")
                  .filter(
                    (x: any) =>
                      x.certificateStatusTypeId === 1 &&
                      x.certificateTypeId === 5
                  ).length
                : this.props.formData?.HotelCertificationListForExcel?.dataList
                  .filter((x: any) => x.categoryName === "KONAKLAMA TESİSİ")
                  .filter(
                    (x: any) =>
                      x.region === col &&
                      x.certificateStatusTypeId === 1 &&
                      x.certificateTypeId === 5
                  ).length
            }
          />
          <ExcelColumn
            label="UYGUN GÖRÜLMEDİ"
            value={(col: any) =>
              col === "GENEL TOPLAM"
                ? this.props.formData?.HotelCertificationListForExcel?.dataList
                  .filter((x: any) => x.categoryName === "KONAKLAMA TESİSİ")
                  .filter(
                    (x: any) =>
                      x.certificateStatusTypeId === 5 &&
                      x.certificateTypeId === 5
                  ).length
                : this.props.formData?.HotelCertificationListForExcel?.dataList
                  .filter((x: any) => x.categoryName === "KONAKLAMA TESİSİ")
                  .filter(
                    (x: any) =>
                      x.region === col &&
                      x.certificateStatusTypeId === 5 &&
                      x.certificateTypeId === 5
                  ).length
            }
          />
          <ExcelColumn
            label="Genel Toplam"
            value={(col: any) =>
              col === "GENEL TOPLAM"
                ? this.props.formData?.HotelCertificationListForExcel?.dataList
                  .filter((x: any) => x.categoryName === "KONAKLAMA TESİSİ")
                  .filter((x: any) => x.certificateTypeId === 5).length
                : this.props.formData?.HotelCertificationListForExcel?.dataList
                  .filter((x: any) => x.categoryName === "KONAKLAMA TESİSİ")
                  .filter(
                    (x: any) => x.region === col && x.certificateTypeId === 5
                  ).length
            }
          />

          {/*  <ExcelColumn label="Region"
              value={(col: any) => col.cityName && col.cityName != "" ? cityRegions.filter(x => x.city === col.cityName)[0]?.region : ""} /> */}
        </ExcelSheet>
        <ExcelSheet
          data={this.props.formData?.HotelCertificationListForExcel?.dataList
            .filter((x: any) => x.categoryName === "KONAKLAMA TESİSİ")
            .filter((x: any) => x.certificateTypeId === 4)}
          name="Bakanlık Belgeli Tesisler Liste"
        >
          <ExcelColumn label="İl" value="cityName" />
          <ExcelColumn label="İlçe" value="districtName" />
          <ExcelColumn label="Tesis Adı" value="brandName" />
          <ExcelColumn label="Web Sitesi" value="webSite" />
          <ExcelColumn label="Durum" value="certificateStatusTypeName" />
        </ExcelSheet>
        <ExcelSheet
          data={this.props.formData?.HotelCertificationListForExcel?.dataList
            .filter((x: any) => x.categoryName === "KONAKLAMA TESİSİ")
            .filter((x: any) => x.certificateTypeId === 5)}
          name="Belediye Belgeli Tesisler Liste"
        >
          <ExcelColumn label="İl" value="cityName" />
          <ExcelColumn label="İlçe" value="districtName" />
          <ExcelColumn label="Tesis Adı" value="brandName" />
          <ExcelColumn label="Web Sitesi" value="webSite" />
          <ExcelColumn label="Durum" value="certificateStatusTypeName" />
        </ExcelSheet>
      </ExcelFile>
    ) : (
      <>
        <PrimaryButton
          text="Rapor Oluştur"
          className="custom-button"
          onClick={() => this.downloadexcel()}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
        {this.state.download && (
          <Label style={{ marginLeft: "10px", marginTop: "27px" }}>
            Rapor Oluşturuluyor...
          </Label>
        )}
      </>
    );
  }

  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.props.formData.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }
  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getDataPager(selected);
  };
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Sertifika İşlemleri 1"
          subTitle="Yönetilebilecek tüm alanları aşağıdan görüntüleyebilirsiniz."
          backUrl="/"
          showBack
          history={this.props.history}
        />

        <Label className="totalRecord">
          Konaklama Sertifika Sayısı:
          {
            this.props.formData?.HotelCertificationAndRestaurantCount
              ?.layoverCount
          }
        </Label>
        <Label className="totalRecord">
          Restoran Sertifika Sayısı:
          {
            this.props.formData?.HotelCertificationAndRestaurantCount
              ?.restaurantCount
          }
        </Label>
        <Label className="totalRecord">
          Toplam Kayıt Sayısı:
          {
            this.props.formData?.HotelCertificationAndRestaurantCount
              ?.totalCount
          }
        </Label>

        {/* <Label className="totalRecord">Konaklama Sertifika Sayısı: {this.props.formData?.HotelCertificationListForExcel?.dataCount || 0}</Label>
        <Label className="totalRecord">Restoran Sertifika Sayısı: {this.props.formData?.RestaurantListForExcel?.dataCount || 0}</Label>
        <Label className="totalRecord">Toplam Kayıt Sayısı: {this.props.formData?.HotelCertificationListForExcel?.dataCount || 0 + this.props.formData?.RestaurantListForExcel?.dataCount || 0}</Label> */}

        {this.renderFilter()}

        {this.renderList()}
        {this.renderPager()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HotelCertification);
