import { HttpClient } from "src/library/HttpClient";
import {
  MainCategoryGetAllRequestModel,
  MainCategorySaveRequestModel,
  MainCategoryUpdateRequestModel,
  MainCategoryGetByIdRequestModel,
  MainCategoryChangeStatusRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class MainCategory {
  async getAll(request: MainCategoryGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.mainCategory + "GetAll",
      { ...request, token }
    );
  }

  async save(request: MainCategorySaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.mainCategory + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: MainCategoryUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.mainCategory + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(request: MainCategoryGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.mainCategory + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(
    request: MainCategoryChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.mainCategory + "ChangeStatus",
      { ...request, token }
    );
  }
}
