import { HttpClient } from "src/library/HttpClient";
import {
  HotelCertificationGetAllRequestModel,
  MergeHotelCertificate,
  GetHotelCertificateListFourtyFourDay,
  HotelCertificationGetByIdEndIdRequestModel,
  HotelCertificationSaveRequestModel,
  HotelCertificationUpdateRequestModel,
  HotelCertificationGetByIdRequestModel,
  HotelCertificationChangeStatusRequestModel,
  RemovePublish,
  HotelCertificationUpdateBySequenceIdRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";
import { ILeaveCertificationProcessRequest } from "../BaseEnum";

export default class HotelCertification {
  async getAll(request: HotelCertificationGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification + "GetHotelCertificateList",
      { ...request, token }
    );
  }

  async getComparisonAll(
    request: GetHotelCertificateListFourtyFourDay
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification +
        "GetHotelCertificateListFourtyFourDay",
      { ...request, token }
    );
  }
  async save(request: HotelCertificationSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: HotelCertificationUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification + "SaveOrUpdate",
      { ...request, token }
    );
  }
  async updateBySequenceId(
    request: HotelCertificationUpdateBySequenceIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification + "UpdateBySequenceId",
      { ...request, token }
    );
  }

  async getById(request: HotelCertificationGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification + "GetById",
      { ...request, token }
    );
  }
  async getBySequenceId(
    request: HotelCertificationGetByIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification + "GetBySequenceId",
      { ...request, token }
    );
  }

  async getByEndId(
    request: HotelCertificationGetByIdEndIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification + "GetByEntityId",
      { ...request, token }
    );
  }

  async changeStatus(
    request: HotelCertificationChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification + "ChangeStatus",
      { ...request, token }
    );
  }
  async changeStatusBySequenceId(
    request: HotelCertificationChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification + "ChangeStatusBySequenceId",
      { ...request, token }
    );
  }

  async GetHotelCertificationAndRestaurantCount(
    request: HotelCertificationGetAllRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification + "GetHotelCertificateList",
      { ...request, token }
    );
  }

  async excelDownload(
    request: HotelCertificationGetAllRequestModel,
    name: string
  ) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.hotelCertification + "GenerateExcel",
      name,
      { ...request, token }
    );
  }
  async RemoveWebPublish(request: RemovePublish): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification + "RemoveWebPublish",
      { ...request, token }
    );
  }
  async RemoveQrPublish(request: RemovePublish): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification + "RemoveQrPublish",
      { ...request, token }
    );
  }
  async ChangeCompanyUser(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification + "ChangeCompanyUser",
      { ...request, token }
    );
  }
  async MergeHotelCertificate(request: MergeHotelCertificate): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.MergedCertificate + "MergeHotelCertificate",
      { ...request, token }
    );
  }

  async leaveCertificationProcess(
    request: ILeaveCertificationProcessRequest
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification + "LeaveCertificationProcess",
      { ...request, token }
    );
  }

}
