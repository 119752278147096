import { HttpClient } from "src/library/HttpClient";
import {
  GetHospitalList,
  HospitalSaveRequestModel,
  HospitalUpdateRequestModel,
  HospitalGetByIdRequestModel,
  HospitalChangeStatusRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class Hospital {
  async getAll(request: GetHospitalList): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Hospital + "GetHospitalList",
      { ...request, token }
    );
  }

  async save(request: HospitalSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Hospital + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: HospitalUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Hospital + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(request: HospitalGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Hospital + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(request: HospitalChangeStatusRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Hospital + "ChangeStatus",
      { ...request, token }
    );
  }
}
