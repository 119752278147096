import { HttpClient } from "src/library/HttpClient";
import {
  VehicleTypeGetAllRequestModel,
  VehicleTypeSaveRequestModel,
  VehicleTypeUpdateRequestModel,
  VehicleTypeGetByIdRequestModel,
  VehicleTypeChangeStatusRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class VehicleType {
  async getAll(request: VehicleTypeGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.VehicleType + "GetAll",
      { ...request, token }
    );
  }

  async save(request: VehicleTypeSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.VehicleType + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: VehicleTypeUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.VehicleType + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(request: VehicleTypeGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.VehicleType + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(
    request: VehicleTypeChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.VehicleType + "ChangeStatus",
      { ...request, token }
    );
  }
}
