import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  TextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Checkbox,
  Dropdown,
} from "office-ui-fabric-react";
import { setFormData, setFormErrors } from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";
import { getById, changeStatus } from "../../store/thunkHospital";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";
import {
  HospitalGetByIdRequestModel,
  HospitalUpdateRequestModel,
} from "src/services/Hospital/RequestModel";
import BasicTable from "../BasicTable";
import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { castThunkAction } from "src/helpers/casting";
import { StatusEnums } from "src/services/BaseEnum";
import { update } from "src/store/thunkHospital";
import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import cookie from "react-cookies";
import { onSaveText, requestMapData, requestMapStatusData } from "src/helpers/functions";
let usertype: any = 0;
interface OwnProps {
  history: History;
  HospitalId: number;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        getById,
        changeStatus,
        update,
        getAllM,
        getAllC,
      },
      dispatch
    ),
  };
}

type EditHospitalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  messageType: MessageBarType;
}

class EditHospital extends Component<EditHospitalProps, OwnState> {
  state = {
    messageType: MessageBarType.warning,
  };

  UNSAFE_componentWillMount() {
    if (this.props.HospitalId) {
      castThunkAction(
        this.props.getById({
          id: this.props.HospitalId,
        } as HospitalGetByIdRequestModel)
      ).then((response: any) => {
        const request2: CityGetAllRequestModel = {
          selectItemList: ["CityId", "CityName"],
          orderField: "CityName",
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllM(request2);
        this.CityChange(
          this.props.formData.cityId,
          this.props.formData.districtId
        );
      });
    }
    usertype = cookie.load("systemUserInfo");
  }

  renderTextByMessageType() {
    switch (this.state.messageType) {
      case MessageBarType.success:
        return "Kaydetme işlemi başarıyla gerçekleşmiştir.";
      case MessageBarType.error:
        return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
      default:
        return "Lütfen aşağıdaki bilgileri doldurun, ve oluşturmayı tamamlayın!";
    }
  }

  renderMessageBar() {
    return (
      <MessageBar messageBarType={this.state.messageType} isMultiline={false}>
        {this.renderTextByMessageType()}
      </MessageBar>
    );
  }

  onSave(isDraft?: boolean) {
    const request: HospitalUpdateRequestModel = requestMapStatusData(this.props.formData, isDraft);
    castThunkAction(this.props.update(request)).then((response: any) => {
      this.setState(
        {
          messageType: response ? MessageBarType.success : MessageBarType.error,
        },
        () => {
          this.props.setFormData(
            "status",
            isDraft ? StatusEnums.Draft : this.props.formData.status
          );
        }
      );
    });
  }

  onSubmit(isDraft?: boolean) {
    const { formData, setFormErrors } = this.props;

    const validationSchema = _yup.object({
      hospitalName: _yup.string().required("Bu alan zorunludur!"),
      hospitalAddress: _yup.string().required("Bu alan zorunludur!"),
      // latitude: _yup.string().required("Bu alan zorunludur!"),
      // longitude: _yup.string().required("Bu alan zorunludur!"),
      districtId: _yup.string().required("Bu alan zorunludur!"),
    });
    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});
        this.onSave(isDraft);
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  onChangeStatus(status: StatusEnums) {
    const request: HospitalUpdateRequestModel = {
      data: requestMapData({ ...this.props.formData, status: StatusEnums.Deleted }),
    };
    castThunkAction(this.props.update(request, true)).then((response: any) => {
      this.setState(
        {
          messageType: response ? MessageBarType.success : MessageBarType.error,
        },
        () => {
          this.props.setFormData("status", StatusEnums.Deleted);
        }
      );
    });
  }

  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "brandName",
            text: "Tesis Adı",
            col: "col-md-2",
          },
          {
            columnName: "companyName",
            text: "Şirket Adı",
            col: "col-md-2",
          },
          {
            columnName: "cityDistrictName",
            text: "İl / İlçe",
            col: "col-md-2",
          },
          {
            columnName: "createdUserName",
            text: "Kayıt Yapan",
            col: "col-md-2",
          },
          {
            columnName: "createDateString",
            text: "Kayıt Tarihi",
            col: "col-md-2",
          },
          {
            columnName: "updatedUserName",
            text: "Güncelleyen",
            col: "col-md-1",
          },
          {
            columnName: "updateDateString",
            text: "Güncelleme Tarihi",
            col: "col-md-1",
          },
        ]}
        items={this.props.formData?.hotels || []}
      />
    );
  }
  renderListFirma() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "brandName",
            text: "Tesis Adı",
            col: "col-md-4",
          },
          {
            columnName: "companyName",
            text: "Şirket Adı",
            col: "col-md-4",
          },
          {
            columnName: "cityDistrictName",
            text: "İl / İlçe",
            col: "col-md-4",
          },
        ]}
        items={this.props.formData?.hotels || []}
      />
    );
  }
  renderForm() {
    const { formData, formErrors, setFormData } = this.props;
    return (
      <div className="form-groups row">
        <div className="form-group col-md-4">
          <div className="title">Genel Bilgiler</div>
          <div className="form-wrapper">
            <div className="item row">
              <TextField
                placeholder="Sağlık Kuruluşu Adı"
                value={formData?.hospitalName || ""}
                label="Sağlık Kuruluşu Adı"
                className="custom-textfield border"
                errorMessage={formErrors.hospitalName}
                onChange={(event: any) =>
                  setFormData("hospitalName", event.target.value)
                }
              />
            </div>

            <div className="item row">
              <Checkbox
                label="Covid Testi"
                checked={formData.isTestingCovid ? true : false}
                onChange={() =>
                  setFormData("isTestingCovid", !formData.isTestingCovid)
                }
              />
            </div>

            <div className="item row">
              <TextField
                placeholder="Sağlık Kuruluşu Adresi"
                value={formData?.hospitalAddress}
                label="Sağlık Kuruluşu Adresi"
                className="custom-textfield border"
                onChange={(event: any) =>
                  setFormData("hospitalAddress", event.target.value)
                }
              />
            </div>

            {/* <div className="item row">
              <TextField
                placeholder="X Koordinatı"
                value={formData?.latitude}
                label="X Koordinatı"
                className="custom-textfield border"
                errorMessage={formErrors.latitude}
                onChange={(event: any) =>
                  setFormData("latitude", (event.target.value))
                }
              />
            </div>

            <div className="item row">
              <TextField
                placeholder="Y Koordinatı"
                value={formData?.longitude}
                label="Y Koordinatı"
                className="custom-textfield border"
                errorMessage={formErrors.longitude}
                onChange={(event: any) =>
                  setFormData("longitude", (event.target.value))
                }
              />
            </div> */}
            <div className="item row">
              <Dropdown
                // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                onChanged={(event: any) => (
                  this.CityChange(event.key), setFormData("cityId", event.key)
                )}
                label="İl"
                options={(
                  this.props.formData.GroupList?.dataList || ([] as any)
                ).map((item: any) => ({
                  key: item.CityId,
                  text: item.CityName,
                }))}
                className="custom-dropdown"
                selectedKey={this.props.formData.cityId}
              />
            </div>
            <div className="item row">
              <Dropdown
                // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                onChanged={(event: any) => setFormData("districtId", event.key)}
                label="İlçe"
                options={(formData.GroupListC?.dataList || ([] as any)).map(
                  (item: any) => ({
                    key: item.DistrictId,
                    text: item.DistrictName,
                  })
                )}
                className="custom-dropdown"
                errorMessage={formErrors.districtId}
                selectedKey={this.props.formData.districtId}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  CityChange(item: any, districtId?: any) {
    const request2: CountyGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "cityId",
          value: item,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
      selectItemList: ["DistrictId", "DistrictName"],
      orderField: "DistrictName",
    };
    castThunkAction(this.props.getAllC(request2)).then((response: any) => {
      this.props.setFormData("districtId", districtId);
    });
  }
  renderStatusByType() {
    switch (this.props.formData.status) {
      case StatusEnums.Published:
        return (
          <div className="status">
            Durum: <strong>Yayımda</strong>
          </div>
        );
      case StatusEnums.Draft:
        return (
          <div className="status">
            Durum: <strong>Taslak</strong>
          </div>
        );
      case StatusEnums.Deleted:
        return (
          <div className="status">
            Durum: <strong>Silindi</strong>
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="page push-all">
        <PageTitle
          titleRenderer={() => (
            <span className="edit-title">
              Düzenle:
              <strong>{this.props.formData?.hospitalName || ""}</strong>
            </span>
          )}
          showBack
          backUrl="/Hospital"
          history={this.props.history}
          subTitle="Değiştirmek istediğiniz alanları aşağıda bulabilirsiniz."
          rightType="create"
          right={
            <FormButtons
              isAbsolute
              onSubmit={() => this.onSubmit()}
              customRenderButtons={() => (
                <React.Fragment>
                  {usertype !== "5" &&
                    usertype !== "2" &&
                    this.props.formData.status !== StatusEnums.Deleted && (
                      <PrimaryButton
                        onClick={() => this.onChangeStatus(StatusEnums.Deleted)}
                        iconProps={{ iconName: "Trash" }}
                        text="Sil"
                        styles={{
                          root: {
                            background: "#ac6262",
                            borderColor: "#ac6262",
                          },
                          rootHovered: {
                            background: "#a34c4c",
                            borderColor: "#a34c4c",
                          },
                          rootPressed: {
                            background: "#a34c4c",
                            borderColor: "#a34c4c",
                          },
                          rootDisabled: {
                            background: "#ececec",
                          },
                        }}
                      />
                    )}

                  {usertype !== "5" && (
                    <PrimaryButton
                      onClick={() => this.onSubmit()}
                      styles={{
                        rootDisabled: {
                          background: "#626262",
                          borderColor: "#777373 !important",
                        },
                      }}
                      iconProps={{ iconName: "CheckMark" }}
                      text={
                        onSaveText(this.props.formData.status)
                      }
                    />
                  )}
                  {this.renderStatusByType()}
                </React.Fragment>
              )}
            />
          }
        />
        {this.renderMessageBar()}

        {this.renderForm()}
        {usertype && usertype !== "2" && this.renderList()}
        {usertype && usertype === "2" && this.renderListFirma()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditHospital);
