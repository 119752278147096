import { HttpClient } from "src/library/HttpClient";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";
import { formatDate } from "src/helpers/converts";
import { ICertificationActionReportRequest } from "./RequestModel";

export default class Statistic {
  async GetHotelCountByCity(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Statistic + "GetHotelCountByCity",
      { ...request, token }
    );
  }
  async GetHotelByHospital(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Statistic + "GetHotelByHospital",
      { ...request, token }
    );
  }

  async GetCompanyUserStatistic(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Statistic + "GetCompanyUserStatistic",
      { ...request, token }
    );
  }

  async GetFacilitySummaryList(request: any, name: string): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().reportFileDownload(
      apiConfig.prefixes.Statistic + "GetFacilitySummaryList",
      { ...request, token },
      name
    );
  }
  async GetDetailedSearch(request: any, name: string): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().reportFileDownload(
      `${apiConfig.prefixes.Statistic}GetDetailedSearch`,
      { ...request, token },
      name
    );
  }
  async GetFacilitySummaryCount(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Statistic + "GetFacilitySummaryCount",
      { ...request, token }
    );
  }
  async GetDetailedSearchCount(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Statistic + "GetDetailedSearchCount",
      { ...request, token }
    );
  }
  async GetFacilityCompanyList(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Statistic + "GetFacilityCompanyUserStatisticList",
      { ...request, token }
    );
  }
  async GetFacilityCompanyUserStatistic(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().postReq(
      apiConfig.prefixes.Statistic + "GetFacilityCompanyUserStatisticList",
      { ...request, token }
    );
  }
  async GetUserLogCount(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Statistic + "GetUserLogCount",
      { ...request, token }
    );
  }

  async getGetCertificateReport(
    request: ICertificationActionReportRequest
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Statistic + "GetCertificateReport",
      { ...request, token }
    );
  }
  // async UserLogCountForExcel(request: any): Promise<any> {
  //   const token = cookie.load("userId");
  //   return HttpClient.getDefaultClient().post(
  //     apiConfig.prefixes.Statistic + "UserLogCountForExcel",
  //     { ...request, token }
  //   );
  // }

  async download(): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.Statistic + "GeneratePdf",
      "Sertifika Programı Genel Tablo_" + formatDate(),
      { token },
      true
    );
  }

  async excelDownload(
    request: ICertificationActionReportRequest,
    name: string
  ) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.Statistic + "GetUserLogCountExcel",
      name,
      { ...request, token }
    );
  }


  async excelDownloadCertificateReport(
    request: ICertificationActionReportRequest,
    name: string
  ) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.Statistic + "CertificateReportExcel",
      name,
      { ...request, token }
    );
  }

  async excelDownloadCompany(request: any, name: string) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.Statistic + "GetFacilityCompanyUserStatistic",
      name,
      { ...request, token }
    );
  }
}
