import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import ThemedFacilityCertification from "src/services/ThemedFacilityCertification/ThemedFacilityCertification";
import {
  ThemedFacilityCertificationGetAllRequestModel,
  ThemedFacilityCertificationChangeStatusRequestModel,
  ThemedFacilityCertificationGetByIdEndIdRequestModel,
  ThemedFacilityCertificationGetByIdRequestModel,
  ThemedFacilityCertificationUpdateRequestModel,
  ThemedFacilityCertificationSaveRequestModel,
  RemovePublish,
  ThemedFacilityCertificationUpdateBySequenceIdRequestModel,
} from "src/services/ThemedFacilityCertification/RequestModel";
import { MessageType } from "./system/types";
import { errorMessageSaveOrUpdate } from "src/helpers/functions";
import { formatDate } from "src/helpers/converts";
import { ILeaveCertificationProcessRequest } from "src/services/BaseEnum";
let cityRegions: any[] = [];

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllPage(
  request: ThemedFacilityCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const ThemedFacilityCertificationService =
      new ThemedFacilityCertification();
    const result = await ThemedFacilityCertificationService.getAll(request);
    dispatch(setFormData("excel", result));
    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: ThemedFacilityCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const ThemedFacilityCertificationService =
      new ThemedFacilityCertification();
    const result = await ThemedFacilityCertificationService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: ThemedFacilityCertificationSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const ThemedFacilityCertificationService =
      new ThemedFacilityCertification();
    const result = await ThemedFacilityCertificationService.save(request);
    // dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return result;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}
export function update(
  request:
    | ThemedFacilityCertificationUpdateRequestModel
    | ThemedFacilityCertificationUpdateBySequenceIdRequestModel,
  sequence?: boolean
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const ThemedFacilityCertificationService =
      new ThemedFacilityCertification();
    const result = sequence
      ? await ThemedFacilityCertificationService.updateBySequenceId(request)
      : await ThemedFacilityCertificationService.update(request);
    // dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}

export function getById(
  request: ThemedFacilityCertificationGetByIdRequestModel,
  sequence?: boolean
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const ThemedFacilityCertificationService =
      new ThemedFacilityCertification();
    const result = !!sequence
      ? await ThemedFacilityCertificationService.getBySequenceId(request)
      : await ThemedFacilityCertificationService.getById(request);
    dispatch(
      initialFormData({
        ...result.data,
        intitialAppealDate: result?.data?.appealDate,
        initialAttachmentList: result?.data?.attachmentList,
        initialAttachmentCertificateList:
          result?.data?.attachmentCertificateList,
        initialAttachmentUnCertificateList:
          result?.data?.attachmentUnCertificateList,
      })
    );
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getByEndId(
  request: ThemedFacilityCertificationGetByIdEndIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const ThemedFacilityCertificationService =
      new ThemedFacilityCertification();
    const result = await ThemedFacilityCertificationService.getByEndId(request);
    dispatch(
      initialFormData({
        ...result.data,
        initialAttachmentList: result.data.attachmentList,
        initialAttachmentCertificateList: result.data.attachmentCertificateList,
        initialAttachmentUnCertificateList:
          result.data.attachmentUnCertificateList,
      })
    );
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: ThemedFacilityCertificationChangeStatusRequestModel,
  isDelete: boolean = false
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const ThemedFacilityCertificationService =
      new ThemedFacilityCertification();
    const result = await ThemedFacilityCertificationService.changeStatus(
      request
    );
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(initialFormData(result));
      dispatch(
        showMessage(
          isDelete
            ? "Silme işlemi başarıyla değiştirildi."
            : "Kayıt durumu işlemi başarıyla değiştirildi.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getAllThemedFacilityCertificationANDRestaurant(
  request: ThemedFacilityCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    cityRegions = require("../cityRegions.json");

    const ThemedFacilityCertificationService =
      new ThemedFacilityCertification();
    const resultThemedFacilityCertification =
      await ThemedFacilityCertificationService.getAll(request);
    for (
      let index = 0;
      index < resultThemedFacilityCertification.dataList.length;
      index++
    ) {
      resultThemedFacilityCertification.dataList[index]["region"] =
        resultThemedFacilityCertification.dataList[index].cityName &&
        resultThemedFacilityCertification.dataList[index].cityName !== ""
          ? cityRegions.filter(
              (x: any) =>
                x.city ===
                resultThemedFacilityCertification.dataList[index].cityName
            )[0]?.region
          : "";
      resultThemedFacilityCertification.dataList[index]["categoryName"] =
        "KONGRE MERKEZİ";
      resultThemedFacilityCertification.dataList[index]["categoryName_En"] =
        "CONGRESS CENTER";
    }
    dispatch(
      setFormData(
        "ThemedFacilityCertificationListForExcel",
        resultThemedFacilityCertification
      )
    );

    // let resultRestaurant: any = null
    // if (request["ThemedFacilityCertificationSubCategoryId"] === 0 || request["ThemedFacilityCertificationSubCategoryId"] === null || request["ThemedFacilityCertificationSubCategoryId"] === undefined) {
    // 	const RestaurantService = new Restaurant();
    // 	resultRestaurant = await RestaurantService.getAll(request);
    // 	for (let index = 0; index < resultRestaurant.dataList.length; index++) {
    // 		resultRestaurant.dataList[index]["region"] = resultRestaurant.dataList[index].cityName && resultRestaurant.dataList[index].cityName != "" ? cityRegions.filter((x: any) => x.city === resultRestaurant.dataList[index].cityName)[0]?.region : ""

    // 		resultRestaurant.dataList[index]["categoryName"] = "RESTORAN / KAFE"
    // 		resultRestaurant.dataList[index]["categoryName_En"] = "Restaurant / Cafe"
    // 	}
    // 	dispatch(setFormData("RestaurantListForExcel", resultRestaurant));
    // }

    dispatch(toggleLoader());
    if (resultThemedFacilityCertification.success) {
      return true;
    }
    return false;
  };
}

export function excelDownload(
  request: ThemedFacilityCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const ThemedFacilityService = new ThemedFacilityCertification();
    const excelName = "TemalıTesisler" + formatDate();
    await ThemedFacilityService.excelDownload(request, excelName);
    dispatch(toggleLoader());

    return false;
  };
}
export function getAllForPager(
  request: ThemedFacilityCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const ThemedFacilityCertificationService =
      new ThemedFacilityCertification();
    const result = await ThemedFacilityCertificationService.getAll(request);
    dispatch(setFormData("dataList", result.dataList));
    dispatch(setFormData("pageCount", result.pageCount));
    dispatch(setFormData("dataCount", result.dataCount));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function GetThemedFacilityCertificationAndRestaurantCount(
  request: ThemedFacilityCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const ThemedFacilityCertificationService =
      new ThemedFacilityCertification();
    const result =
      await ThemedFacilityCertificationService.GetThemedFacilityCertificationAndRestaurantCount(
        request
      );
    dispatch(
      setFormData("ThemedFacilityCertificationAndRestaurantCount", result)
    );
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function RemoveWebPublish(
  request: RemovePublish
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const ThemedFacilityCertificationService =
      new ThemedFacilityCertification();
    const result = await ThemedFacilityCertificationService.RemoveWebPublish(
      request
    );
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Sertifika Web Sitesinde Yayımdan Kaldırıldı",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}
export function RemoveQrPublish(
  request: RemovePublish
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const ThemedFacilityCertificationService =
      new ThemedFacilityCertification();
    const result = await ThemedFacilityCertificationService.RemoveQrPublish(
      request
    );
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "QR Yayımdan Kaldırıldı",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}

export function ChangeCompanyUser(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const ThemedFacilityService = new ThemedFacilityCertification();
    const result = await ThemedFacilityService.ChangeCompanyUser(request);
    //dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Ana Kategori Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Ana Kategori Yönetimi"
        )
      );
      return false;
    }
  };
}

export function leaveCertificationProcess(
  request: ILeaveCertificationProcessRequest
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const ThemedFacilityService = new ThemedFacilityCertification();
    const result = await ThemedFacilityService.leaveCertificationProcess(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "İşleminiz başarı ile gerçekleşmiştir.",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}