export interface GetUserLogCountListModel { }

export interface ICertificationActionReportRequest {
  pageSize?: number;
  pageIndex?: number;
  certificateStartDate: number;
  categoryType: number;
  certificateStatusType: any;
  facilityId: number
  cityId: number
  districtId: number
  brandName: string
  companyName: string
  companyUserId: number
  certificateStatusTypeId: any
  certificateNo: string
}

export enum CategoryTypeEnum {
  // "Restoran / Kafe Tesisi"
  Restaurant = 2,
  //"Tur ve Transfer"
  Transport = 3,
  //"Konaklama Tesisi"
  Hotel = 5,
  //"Kongre ve Sanat Tesisi"
  Congress = 6,
  //"Deniz Turizm Tesisi"
  SeaTourism = 7,
  //"Temalı Tesis"
  ThemedFacility = 8,
  //"Kış Turizm Tesisi"
  WinterTourism = 9,
}

export enum CertificateStatusTypeEnum {
  Certificated = 1,
  //"Sertifikasız"
  UnCertificated = 2,
  //"Belgelendirme Sürecinde ve Sertifika Uzatmalı Kapalı Olmayanlar"
  CertificationProcessAndNotExtendedClosed = 3,
  //"Belgelendirme Sürecinde ve Sertifika Uzatmalı Kapalı Olanlar"
  CertificationProcessAndExtendedClosed = 4,
}
export enum CertificateStatusTypeEnum_V2 {
  Certificated = 1,
  //"Sertifikasız"
  UnCertificated = 2,
  //"Belgelendirme Sürecinde"
  CertificationProcess = 3,
  //"Uygun görülmedi"
  NotSuitable = 4,
}

export interface ICertificationActionReport {
  sequenceId: string;
  cityName: string;
  districtName: string;
  companyName: string;
  brandName: string;
  companyUserTitle: string;
  status: number;
  certificateNo: string;
  confirmationDate: string;
  certificateStatus: number;
  certificateStatusString: string;
  facilityId: number;
  relatedId: number;
}
