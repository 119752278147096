import {
  SystemActionTypes,
  SHOW_MESSAGE_AT,
  MessageType,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  TOGGLE_LOADER,
  SET_LANGUAGE,
  TOGGLE_VERIFICATION_LOADER,
  SET_USER_INFO,
  CLOSE_ALL_TAB,
  TabTypeEnum,
  CLOSE_TAB,
  OPEN_TAB,
  TOGGLE_ADVANCED,
  SET_LEFT_PANEL,
  TOGGLE_CONFIRM,
  TOKEN_CONTROL,
  TOGGLE_CONFIRM_FILTER,
  TOGGLE_CONFIRM_MODAL,
} from "./types";

/**
 * Verilen Tiplere Göre Message Bar Gösterir.
 */
export function showMessage(
  content: string,
  title?: string,
  type?: MessageType,
  messageSubTitle?: string,
  windowReload: boolean = false,
  resultData: [] = [],
  oldRequest?: any
): SystemActionTypes {
  return {
    type: SHOW_MESSAGE,
    content,
    title,
    messageType: type,
    messageSubTitle,
    windowReload: windowReload,
    resultData,
    oldRequest,
  };
}

export function showMessageAt(
  content: string,
  title?: string,
  type?: MessageType,
  messageSubTitle?: string,
  windowReload: boolean = false
): SystemActionTypes {
  return {
    type: SHOW_MESSAGE_AT,
    content,
    title,
    messageType: type,
    messageSubTitle,
    windowReload: windowReload,
  };
}

/** Message Kutusunu Gizler. */
export function hideMessage(): SystemActionTypes {
  return {
    type: HIDE_MESSAGE,
  };
}

/** Loader Toggle Eder. */
export function toggleLoader(): SystemActionTypes {
  return {
    type: TOGGLE_LOADER,
  };
}

/** Advanced Mod'u toggle eder. */
export function toggleAdvanced(): SystemActionTypes {
  return {
    type: TOGGLE_ADVANCED,
  };
}

/** Auth Control Anında Verfication Loader Toggle Eder. */
export function toggleVerificationLoader(): SystemActionTypes {
  return {
    type: TOGGLE_VERIFICATION_LOADER,
  };
}

/** Sistemin Dilini Set Eder. */
export function setLanguage(language: string): SystemActionTypes {
  return {
    type: SET_LANGUAGE,
    language,
  };
}

/** Sistemin Sol Panelini Set Eder. */
export function setLeftPanel(leftPanel?: string): SystemActionTypes {
  return {
    type: SET_LEFT_PANEL,
    leftPanel,
  };
}

/** Sistem Kullanıcı Bilgilerini Set Eder. */
export function setUserInfo(userInfo: any): SystemActionTypes {
  return {
    type: SET_USER_INFO,
    userInfo,
  };
}

/** Tab Tipi ve DatasourceId'ye göre, tab'i aktifleştirir. */
export function openTab(
  id: string,
  tabType: TabTypeEnum,
  datasourceId: string | null = null,
  reference?: any
): SystemActionTypes {
  return {
    type: OPEN_TAB,
    id,
    tabType,
    datasourceId,
    reference,
  };
}

/** İlgili tab'i kapatır. */
export function closeTab(id: string, tabType: TabTypeEnum): SystemActionTypes {
  return {
    type: CLOSE_TAB,
    id,
    tabType,
  };
}

/** Var olan tüm tableri kapatır. */
export function closeAllTab(): SystemActionTypes {
  return {
    type: CLOSE_ALL_TAB,
  };
}

/** Confirm modal'ı toggle eder. */
export function toggleConfirmDialog(
  show: boolean,
  text: string,
  callback: (x: any) => any,
  reject?: (x: any) => any
): SystemActionTypes {
  return {
    type: TOGGLE_CONFIRM,
    callback,
    reject,
    show,
    text,
  };
}
/** Confirm modal'ı toggle eder. */
export function toggleConfirmFilterRemoveDialog(
  show: boolean,
  text: string,
  callback: (x: any) => any
): SystemActionTypes {
  return {
    type: TOGGLE_CONFIRM_FILTER,
    callback,
    show,
    text,
  };
}
export function toggleConfirmModalDialog(
  show: boolean,
  title: string,
  text: string,
  callback: (x: any) => any,
  reject?: (x: any) => any,
  yesTitle?: string,
  noTitle?: string,
): SystemActionTypes {
  return {
    type: TOGGLE_CONFIRM_MODAL,
    callback,
    reject,
    show,
    text,
    title,
    yesTitle,
    noTitle
  };
}
/** Token'in geçerlilik durmunu kontrol eder. */
export function tokenControl(tokenStatus: boolean): SystemActionTypes {
  return {
    type: TOKEN_CONTROL,
    tokenStatus,
  };
}
