import { HttpClient } from "src/library/HttpClient";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";
import { AdditionalChangeStatusRequestModel } from "./RequestModel";

export default class AdditionalService {
  async GetAll(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Additional + "GetAdditionalAuditList",
      { ...request, token }
    );
  }
  async GetById(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Additional + "GetById",
      { ...request, token }
    );
  }
  async save(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Additional + "SaveOrUpdate",
      { ...request, token }
    );
  }
  async update(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Additional + "SaveOrUpdate",
      { ...request, token }
    );
  }
  async changeStatus(
    request: AdditionalChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Additional + "ChangeStatus",
      { ...request, token }
    );
  }

  async excelDownload(request: any, name: string) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.Additional + "GenerateExcel",
      name,
      { ...request, token }
    );
  }
}
