import { StatusEnums } from "../BaseEnum";

export interface VehicleTypeSaveDataModel {
  vehicleTypeName: string;
  vehicleTypeName_En: string;
  vehicleTypeName_Ru: string;
  vehicleTypeName_De: string;
  status: StatusEnums;
}
export interface VehicleTypeSaveRequestModel {
  data: VehicleTypeSaveDataModel;
}

export interface VehicleTypeUpdateDataModel {
  vehicleTypeId: number;
  vehicleTypeName: string;
  vehicleTypeName_En: string;
  vehicleTypeName_Ru: string;
  vehicleTypeName_De: string;
  status: StatusEnums;
}

export interface VehicleTypeUpdateRequestModel {
  data: VehicleTypeUpdateDataModel;
}

export interface VehicleTypeChangeStatusRequestModel {
  status: StatusEnums;
  id: number;
}

export enum ConditionOperatorEnum {
  Eq = 0,
  NotEq = 1,
  Like = 2,
  Gt = 3,
  Lt = 4,
  Ge = 5,
  Le = 6,
}

export interface criteriaItemListData {
  propertyName: string;
  value: any;
  isOr: boolean;
  conditionOperator: ConditionOperatorEnum;
}

export interface VehicleTypeGetAllRequestModel {
  criteriaItemList?: criteriaItemListData[];
  selectItemList?: string[];
  pageSize?: number;
  pageIndex?: number;
  orderField?: string;
  isDescending?: boolean;
  status?: StatusEnums;
}
export interface VehicleTypeGetByIdRequestModel {
  id: number;
}

export interface VehicleTypeChangeStatusRequestModel {
  id: number;
  status: StatusEnums;
}
