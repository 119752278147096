import { HttpClient } from "src/library/HttpClient";
import {
  SeaTourismCertificationGetAllRequestModel,
  SeaTourismCertificationGetByIdEndIdRequestModel,
  SeaTourismCertificationSaveRequestModel,
  SeaTourismCertificationUpdateRequestModel,
  SeaTourismCertificationGetByIdRequestModel,
  SeaTourismCertificationChangeStatusRequestModel,
  RemovePublish,
  SeaTourismCertificationUpdateBySequenceIdRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";
import { ILeaveCertificationProcessRequest } from "../BaseEnum";

export default class SeaTourismCertification {
  async getAll(
    request: SeaTourismCertificationGetAllRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismCertification +
        "GetSeaTourismCertificateList",
      { ...request, token }
    );
  }

  async excelDownload(request: any, name: string) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.SeaTourismCertification + "GenerateExcel",
      name,
      { ...request, token }
    );
  }

  async save(request: SeaTourismCertificationSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismCertification + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(
    request: SeaTourismCertificationUpdateRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismCertification + "SaveOrUpdate",
      { ...request, token }
    );
  }
  async updateBySequenceId(
    request: SeaTourismCertificationUpdateBySequenceIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismCertification + "UpdateBySequenceId",
      { ...request, token }
    );
  }

  async getById(
    request: SeaTourismCertificationGetByIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismCertification + "GetById",
      { ...request, token }
    );
  }

  async getBySequenceId(
    request: SeaTourismCertificationGetByIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismCertification + "GetBySequenceId",
      { ...request, token }
    );
  }

  async getByEndId(
    request: SeaTourismCertificationGetByIdEndIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismCertification + "GetByEntityId",
      { ...request, token }
    );
  }

  async changeStatus(
    request: SeaTourismCertificationChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismCertification + "ChangeStatus",
      { ...request, token }
    );
  }

  async GetSeaTourismCertificationAndRestaurantCount(
    request: SeaTourismCertificationGetAllRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismCertification +
        "GetSeaTourismAndRestaurantCount",
      { ...request, token }
    );
  }
  async RemoveWebPublish(request: RemovePublish): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification + "RemoveWebPublish",
      { ...request, token }
    );
  }
  async RemoveQrPublish(request: RemovePublish): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification + "RemoveQrPublish",
      { ...request, token }
    );
  }
  async ChangeCompanyUser(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismCertification + "ChangeCompanyUser",
      { ...request, token }
    );
  }

  async leaveCertificationProcess(
    request: ILeaveCertificationProcessRequest
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismCertification + "LeaveCertificationProcess",
      { ...request, token }
    );
  }

}
