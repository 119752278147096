import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import SeaTourismCertification from "src/services/SeaTourismCertification/SeaTourismCertification";
import {
  SeaTourismCertificationGetAllRequestModel,
  SeaTourismCertificationChangeStatusRequestModel,
  SeaTourismCertificationGetByIdEndIdRequestModel,
  SeaTourismCertificationGetByIdRequestModel,
  SeaTourismCertificationUpdateRequestModel,
  SeaTourismCertificationSaveRequestModel,
  RemovePublish,
  SeaTourismCertificationUpdateBySequenceIdRequestModel,
} from "src/services/SeaTourismCertification/RequestModel";
import { MessageType } from "./system/types";
import { errorMessageSaveOrUpdate } from "src/helpers/functions";
import { formatDate } from "src/helpers/converts";
import { ILeaveCertificationProcessRequest } from "src/services/BaseEnum";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllPage(
  request: SeaTourismCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const SeaTourismCertificationService = new SeaTourismCertification();
    const result = await SeaTourismCertificationService.getAll(request);
    dispatch(setFormData("excel", result));
    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: SeaTourismCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const SeaTourismCertificationService = new SeaTourismCertification();
    const result = await SeaTourismCertificationService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: SeaTourismCertificationSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismCertificationService = new SeaTourismCertification();
    const result = await SeaTourismCertificationService.save(request);
    // dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return result;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}
export function update(
  request:
    | SeaTourismCertificationUpdateRequestModel
    | SeaTourismCertificationUpdateBySequenceIdRequestModel,
  sequence?: boolean
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const SeaTourismCertificationService = new SeaTourismCertification();
    const result = sequence
      ? await SeaTourismCertificationService.updateBySequenceId(
          request as SeaTourismCertificationUpdateBySequenceIdRequestModel
        )
      : await SeaTourismCertificationService.update(request);
    // dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}

export function getById(
  request: SeaTourismCertificationGetByIdRequestModel,
  sequence?: boolean
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismCertificationService = new SeaTourismCertification();
    const result = sequence
      ? await SeaTourismCertificationService.getBySequenceId(request)
      : await SeaTourismCertificationService.getById(request);
    dispatch(
      initialFormData({
        ...result.data,
        intitialAppealDate: result.data?.appealDate,
        initialAttachmentList: result.data?.attachmentList,
        initialAttachmentCertificateList:
          result.data?.attachmentCertificateList,
        initialAttachmentUnCertificateList:
          result.data?.attachmentUnCertificateList,
      })
    );
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getByEndId(
  request: SeaTourismCertificationGetByIdEndIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismCertificationService = new SeaTourismCertification();
    const result = await SeaTourismCertificationService.getByEndId(request);
    dispatch(
      initialFormData({
        ...result.data,
        initialAttachmentList: result.data.attachmentList,
        initialAttachmentCertificateList: result.data.attachmentCertificateList,
        initialAttachmentUnCertificateList:
          result.data.attachmentUnCertificateList,
      })
    );
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: SeaTourismCertificationChangeStatusRequestModel,
  isDelete: boolean = false
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismCertificationService = new SeaTourismCertification();
    const result = await SeaTourismCertificationService.changeStatus(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(initialFormData(result));
      dispatch(
        showMessage(
          isDelete
            ? "Silme işlemi başarıyla değiştirildi."
            : "Kayıt durumu işlemi başarıyla değiştirildi.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}

export function excelDownload(
  request: SeaTourismCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismCertificationService = new SeaTourismCertification();
    const excelName = "DenizTurizmTesisArac" + formatDate();
    await SeaTourismCertificationService.excelDownload(request, excelName);
    dispatch(toggleLoader());

    return false;
  };
}

export function getAllForPager(
  request: SeaTourismCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismCertificationService = new SeaTourismCertification();
    const result = await SeaTourismCertificationService.getAll(request);
    dispatch(setFormData("dataList", result.dataList));
    dispatch(setFormData("pageCount", result.pageCount));
    dispatch(setFormData("dataCount", result.dataCount));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function GetSeaTourismCertificationAndRestaurantCount(
  request: SeaTourismCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismCertificationService = new SeaTourismCertification();
    const result =
      await SeaTourismCertificationService.GetSeaTourismCertificationAndRestaurantCount(
        request
      );
    dispatch(setFormData("SeaTourismCertificationAndRestaurantCount", result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function RemoveWebPublish(
  request: RemovePublish
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismCertificationService = new SeaTourismCertification();
    const result = await SeaTourismCertificationService.RemoveWebPublish(
      request
    );
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Sertifika Web Sitesinde Yayımdan Kaldırıldı",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}
export function RemoveQrPublish(
  request: RemovePublish
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismCertificationService = new SeaTourismCertification();
    const result = await SeaTourismCertificationService.RemoveQrPublish(
      request
    );
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "QR Yayımdan Kaldırıldı",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}

export function ChangeCompanyUser(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismService = new SeaTourismCertification();
    const result = await SeaTourismService.ChangeCompanyUser(request);
    //dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Ana Kategori Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Ana Kategori Yönetimi"
        )
      );
      return false;
    }
  };
}

export function leaveCertificationProcess(
  request: ILeaveCertificationProcessRequest
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismService = new SeaTourismCertification();
    const result = await SeaTourismService.leaveCertificationProcess(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "İşleminiz başarı ile gerçekleşmiştir.",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}
