import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import Airport from "src/services/Airport/AirportService";
import {
  AirportGetAllRequestModel,
  AirportChangeStatusRequestModel,
  AirportGetByIdRequestModel,
  AirportUpdateRequestModel,
  AirportSaveRequestModel,
} from "src/services/Airport/RequestModel";
import { MessageType } from "./system/types";
import { errorMessageSaveOrUpdate } from "src/helpers/functions";
import { formatDate } from "src/helpers/converts";
import { ILeaveCertificationProcessRequest } from "src/services/BaseEnum";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllPage(
  request: AirportGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const AirportService = new Airport();
    const excelName = "Havalimanı_" + formatDate();
    await AirportService.excelDownload(request, excelName);
    dispatch(toggleLoader());

    return false;
  };
}

export function getAll(
  request: AirportGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const AirportService = new Airport();
    const result = await AirportService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: AirportSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const AirportService = new Airport();
    const result = await AirportService.save(request);
    // dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return result;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}
export function update(
  request: AirportUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const AirportService = new Airport();
    const result = await AirportService.update(request);
    // dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}

export function getById(
  request: AirportGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const AirportService = new Airport();
    const result = await AirportService.getById(request);
    dispatch(
      initialFormData({
        ...result.data,
        intitialAppealDate: result.data.appealDate,
        initialAttachmentList: result.data.attachmentList,
        initialAttachmentCertificateList: result.data.attachmentCertificateList,
      })
    );
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: AirportChangeStatusRequestModel,
  isDelete: boolean = true
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const AirportService = new Airport();
    const result = await AirportService.changeStatus(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(initialFormData(result));
      dispatch(
        showMessage(
          isDelete
            ? "Silme işlemi başarıyla değiştirildi."
            : "Kayıt durumu işlemi başarıyla değiştirildi.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getAllForPager(
  request: AirportGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const AirportService = new Airport();
    const result = await AirportService.getAll(request);
    dispatch(setFormData("dataList", result.dataList));
    dispatch(setFormData("pageCount", result.pageCount));
    dispatch(setFormData("dataCount", result.dataCount));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function leaveCertificationProcess(
  request: ILeaveCertificationProcessRequest
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const AirportService = new Airport();
    const result = await AirportService.leaveCertificationProcess(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "İşleminiz başarı ile gerçekleşmiştir.",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}
