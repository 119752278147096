import { HttpClient } from "src/library/HttpClient";
import {
  SeaTourismSubCategoryChangeStatusRequestModel,
  SeaTourismSubCategoryGetByIdRequestModel,
  SeaTourismSubCategoryUpdateRequestModel,
  SeaTourismSubCategorySaveRequestModel,
  SeaTourismSubCategoryGetAllRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class SeaTourismSubCategory {
  async getAll(request: SeaTourismSubCategoryGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismSubCategory + "GetAll",
      { ...request, token }
    );
  }

  async save(request: SeaTourismSubCategorySaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismSubCategory + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: SeaTourismSubCategoryUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismSubCategory + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(
    request: SeaTourismSubCategoryGetByIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismSubCategory + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(
    request: SeaTourismSubCategoryChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SeaTourismSubCategory + "ChangeStatus",
      { ...request, token }
    );
  }
}
