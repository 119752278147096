import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import Hospital from "src/services/Hospital/HospitalService";
import {
  GetHospitalList,
  HospitalChangeStatusRequestModel,
  HospitalGetByIdRequestModel,
  HospitalUpdateRequestModel,
  HospitalSaveRequestModel,
} from "src/services/Hospital/RequestModel";
import { MessageType } from "./system/types";
import { errorMessageSaveOrUpdate } from "src/helpers/functions";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllHA(request: GetHospitalList): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const HospitalService = new Hospital();
    const result = await HospitalService.getAll(request);
    dispatch(setFormData("HospitalList", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(request: GetHospitalList): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HospitalService = new Hospital();
    const result = await HospitalService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAllForModal(
  request: GetHospitalList
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HospitalService = new Hospital();
    const result = await HospitalService.getAll(request);
    dispatch(setFormData("HospitalListForModal", result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: HospitalSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HospitalService = new Hospital();
    const result = await HospitalService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Sağlık kuruluşu eklendi.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          ""
        )
      );
      return result;
    } else {
      errorMessageSaveOrUpdate(dispatch,result)

      return false;
    }
  };
}
export function update(
  request: HospitalUpdateRequestModel,
  isDeletedRequest?: any
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HospitalService = new Hospital();
    const result = await HospitalService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          isDeletedRequest
            ? "Sağlık Kuruluşu silme işlemi başarıyla gerçekleşti."
            : "Geçerli işlem başarıyla gerçekleşti.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          ""
        )
      );
      return true;
    } else {
      errorMessageSaveOrUpdate(dispatch,result)

      return false;
    }
  };
}

export function getById(
  request: HospitalGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HospitalService = new Hospital();
    const result = await HospitalService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: HospitalChangeStatusRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const HospitalService = new Hospital();
    const result = await HospitalService.changeStatus(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Kayıt durumu işlemi başarıyla değiştirildi.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          "",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          ""
        )
      );
      return false;
    }
  };
}
