export interface FormStore {
  formData: any;
  formErrors: any;
}

export const INITIAL_FORM_DATA = "INITIAL_FORM_DATA";
export const SET_FORM_DATA = "SET_FORM_DATA";
export const SET_FORM_ERRORS = "SET_FORM_ERRORS";
export const RESET_FORM = "RESET_FORM";

interface InitialFormData {
  type: typeof INITIAL_FORM_DATA;
  formData: any;
}

interface SetFormData {
  type: typeof SET_FORM_DATA;
  key: string;
  value: string;
}

interface SetFormErrors {
  type: typeof SET_FORM_ERRORS;
  formErrors: any;
}

interface ResetForm {
  type: typeof RESET_FORM;
}

export type FormActionTypes =
  | InitialFormData
  | SetFormData
  | SetFormErrors
  | ResetForm;
