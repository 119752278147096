/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { RootState } from "../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "./PageTitle";
import { History } from "history";
import BasicTable from "./BasicTable";
import { TextField, PrimaryButton, Dropdown } from "office-ui-fabric-react";
import { getAllVehicleForModal, changeStatus } from "src/store/thunkVehicle";

import { setFormData } from "src/store/form/actions";
import { showMessage } from "src/store/system/actions";

import { castThunkAction } from "src/helpers/casting";
import { toggleConfirmDialog, toggleConfirmFilterRemoveDialog } from "src/store/system/actions";
import { listCount } from "src/config/otherConfig";
import { getAllCR } from "src/store/thunkCertificateStatusType";

import cookie from "react-cookies";

// import { MessageType } from "src/store/system/types";
import { filterModal } from "src/helpers/functions";
import { VehicleGetAllRequestModel } from "src/services/Vehicle/RequestModel";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        getAllVehicleForModal,
        changeStatus,
        toggleConfirmDialog,
        toggleConfirmFilterRemoveDialog,
        getAllCR,
        setFormData,
        showMessage,
      },
      dispatch
    ),
  };
}

type VehicleModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  pagerTotal: number;
  selectedType: number;
}

const intitialState = {
  filterData: {
    orderField: "UpdatedSystemUserDatetime",
    isDescending: true,
    companyName: '',
    vehiclePlaque: '',
    status: 1
  },
  pagerCurrent: 0,
  pagerTotal: 0,
  selectedType: 0,
}

class VehicleModal extends Component<VehicleModalProps, OwnState> {

  state: any = intitialState;

  componentDidMount() {

    filterModal(this, "vehicleListModalState").finally(() =>
      this.state.selectedType !== 0 && this.getData(this.state.pagerCurrent)
    );
  }

  getData(
    pagerSelected: number,
    certificateTypeId: number = this.state.selectedType
  ) {
    const request: VehicleGetAllRequestModel = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...this.state.filterData,
      certificateTypeId,
      isForAdditionalAudit: this.props.formData.isForAdditionalAudit,
    };

    castThunkAction(this.props.getAllVehicleForModal(request));
  }

  getDataPager(pagerSelected: number) {
    const request: VehicleGetAllRequestModel = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...this.state.filterData,
      certificateTypeId: this.state.selectedType,
      isForAdditionalAudit: this.props.formData.isForAdditionalAudit,
    };
    this.props.getAllVehicleForModal(request);
  }



  renderFilter() {
    return (
      <div className="table-filter row">
        <TextField
          className="custom-textfield col-md-3"
          label="Plaka No"
          value={this.state.filterData?.vehiclePlaque || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                vehiclePlaque: event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />


        <TextField
          className="custom-textfield col-md-2"
          label="Belge Sahibi / Ticari Ünvan"
          value={this.state.filterData?.companyName || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                companyName:
                  event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />

         <Dropdown
          options={[
            { key: "CompanyName", text: "Belge Sahibi / Ticari Ünvan" },
            { key: "CreatedSystemUserDatetime", text: "Oluşturma Tarihi" },
            { key: "UpdatedSystemUserDatetime", text: "Güncelleme Tarihi" },
          ]}
          className="custom-dropdown col-md-2"
          label="Sıralama Kriteri"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.orderField || null}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                orderField: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={(this.state.filterData.orderField === "CompanyName"
          ? [
            { key: false, text: "Baştan Sona" },
            { key: true, text: "Sondan Başa" },
          ]
          : [
            { key: false, text: "Eskiden Yeniye" },
            { key: true, text: "Yeniden Eskiye" },
          ]
        ).map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-2"
          label="Sıralama Türü"
          selectedKey={this.state.filterData?.isDescending}
          placeholder="Seçiniz"
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isDescending: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        /> 


        <PrimaryButton
          text="Arama Yap"
          className="custom-button"
          onClick={() => {
            cookie.save("vehicleListModalState", JSON.stringify(this.state), {
              path: "/",
            })
            this.getDataPager(0)
          }}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
      </div>
    );
  }

  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "vehiclePlaque",
            text: "Plaka No",
            col: "col-md-2",
          },
          {
            columnName: "companyName",
            text: "Belge Sahibi",
            col: "col-md-2",
          },

          {
            columnName: "seatCount",
            text: "Koltuk Sayısı",
            col: "col-md-1",
          },
          {
            columnName: "certificateTypeName",
            text: "Taşıt Belge Tipi",
            col: "col-md-3",
          },
          {
            columnName: "vehicleTypeName",
            text: "Araç Cinsi",
            col: "col-md-2",
          },

          {
            columnName: "transportId",
            text: "İşlem",
            col: "col-md-2",
            className: "operations",
            onRender: (item: {
              transportId: any;
              companyName: any;
              isCertificateBelong: any;
              isHaveCertificate: any;
              isSelectable: any;
            }) => (
              <React.Fragment>
                {this.props.formData.Additional !== 1 && (
                  <button
                    style={{
                      backgroundColor: item.isCertificateBelong
                        ? "#909396"
                        : !item.isSelectable
                          ? "#e21532"
                          : "#178df7",
                    }}
                    onClick={() =>
                      !item.isCertificateBelong &&
                      item.isSelectable &&
                      this.setSelect(item)
                    }
                  >
                    <i className="icon-trash-outline"></i>
                    {item.isCertificateBelong
                      ? "Firmanız tarafından sertifikası mevcut"
                      : !item.isSelectable
                        ? "Başka firma tarafından sertifikalı"
                        : "Seçiniz"}
                  </button>
                )}

                {this.props.formData.Additional !== 1 &&
                  item.isCertificateBelong && (
                    <button
                      style={{ background: "#178df7" }}
                      onClick={() =>
                        window.open("/Transport/edit/" + item.transportId, "_blank")
                      }
                    >
                      <i className="icon-trash-outline"></i> Detay
                    </button>
                  )}

                {this.props.formData.Additional === 1 && (
                  <button onClick={(x) => this.setAdditional(item)}>
                    Denetim Ekle
                  </button>
                )}
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.vehicleListForModal?.dataList || []}
      />
    );
  }

  setSelect(item: { transportId: any; companyName: any; seatCount?:string; certificateTypeName?:string; vehicleTypeName?:string;vehiclePlaque?:string; }): void {
    this.props.setFormData("transportId", item.transportId);
    this.props.setFormData("vehiclePlaque",item.vehiclePlaque);
    this.props.setFormData("transportName", item.companyName);
    this.props.setFormData("seatCount",item.seatCount);
    this.props.setFormData("certificateTypeName",item.certificateTypeName);
    this.props.setFormData("vehicleTypeName",item.vehicleTypeName);
    this.props.setFormData("vehicleModalShow", false);
  }

  setAdditional(item: { transportId: any; companyName: any }): void {

    this.props.setFormData("transportId", item.transportId);
    this.props.setFormData("transportName", item.companyName);
    this.props.setFormData("vehicleModalShow", false);
    window.open(
      "/AdditionalControl/Create/" + item.transportId + "/" + 5,
      "_blank"
    );
  }

  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.props.formData?.vehicleListForModal?.pageCount || 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }
  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getDataPager(selected);
  };
  render() {
    return (
      <>
        {this.state.selectedType !== 0 && (
          <div className="page push-all">
            <PageTitle
              title="Taşıt Seç"
              subTitle="Yönetilebilecek tüm alanları aşağıdan görüntüleyebilirsiniz."
              backUrl="/"
              showBack
              history={this.props.history}
            />
            {this.props.formData.Additional !== 1 && (
              <PrimaryButton
                onClick={() => this.props.history.push("/Transport/create")}
                iconProps={{ iconName: "Add" }}
                className="absolute-create"
                text="Yeni Taşıt"
                styles={{
                  root: {
                    background: "#62a98e",
                    borderColor: "#5b9e85",
                  },
                  rootHovered: {
                    background: "#5b9e85",
                    borderColor: "#5b9e85",
                  },
                  rootDisabled: {
                    background: "#ececec",
                  },
                }}
              />
            )}

            {this.renderFilter()}
            {this.renderList()}
            {this.renderPager()}
          </div>
        )}

        {this.state.selectedType === 0 && (
          <div className="home-searchs row">
            <div className="col-md-6 col-xs-12">
              <div className="search">
                <button
                  className="searchButton"
                  onClick={() => this.setState({ selectedType: 4 }, () => {
                    this.getData(this.state.pagerCurrent)
                  })}
                >
                  <a className="home-search-indicator">
                    <i className="fas fa-hotel"></i>
                    T.C. Kültür ve Turizm Bakanlığı Belgeli Taşıtlar
                  </a>
                </button>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <div className="search">
                <button
                  className="searchButton"
                  onClick={() => this.setState({ selectedType: 5 }, () => {
                    this.getData(this.state.pagerCurrent)
                  })}
                >
                  <a className="home-search-indicator">
                    <i className="fas fa-hotel"></i> Belediye Belgeli ve Diğer Taşıtlar
                  </a>
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleModal);
