import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  TextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Dropdown,
} from "office-ui-fabric-react";
import {
  setFormData,
  setFormErrors,
  initialFormData,
} from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";
import { save } from "src/store/thunkCounty";
import { getAllM } from "src/store/thunksCity";
import { castThunkAction } from "src/helpers/casting";
import { CountySaveRequestModel } from "src/services/County/RequestModel";
import { CityGetAllRequestModel } from "src/services/City/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";
import { requestMapStatusData } from "src/helpers/functions";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        initialFormData,
        save,
        getAllM,
      },
      dispatch
    ),
  };
}

type CreateCountyProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  messageType: MessageBarType;
}

class CreateCounty extends Component<CreateCountyProps, OwnState> {
  state = {
    messageType: MessageBarType.warning,
  };

  UNSAFE_componentWillMount() {
    this.props.initialFormData({
      status: StatusEnums.Published,
    });

    const request: CityGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "Status",
          value: 1,
          isOr: false,
          conditionOperator: 0,
        },
      ],
      selectItemList: ["CityId", "CityName"],
    };
    this.props.getAllM(request);
  }

  renderTextByMessageType() {
    switch (this.state.messageType) {
      case MessageBarType.success:
        return "Kaydetme işlemi başarıyla gerçekleşmiştir.";
      case MessageBarType.error:
        return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
      default:
        return "Lütfen aşağıdaki bilgileri doldurun, ve oluşturmayı tamamlayın!";
    }
  }

  renderMessageBar() {
    return (
      <MessageBar messageBarType={this.state.messageType} isMultiline={false}>
        {this.renderTextByMessageType()}
      </MessageBar>
    );
  }

  onCreate(isDraft?: boolean) {
    const request: CountySaveRequestModel = requestMapStatusData(this.props.formData,isDraft);
    
    castThunkAction(this.props.save(request)).then((response: any) => {
      this.setState(
        {
          messageType: response ? MessageBarType.success : MessageBarType.error,
        },
        () => {
          if (response) {
            this.props.history.push("/County/edit/" + response.id);
          }
        }
      );
    });
  }

  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  onSubmit(isDraft?: boolean) {
    const { formData, setFormErrors } = this.props;
    const validationSchema = _yup.object({
      districtName: _yup.string().required("Bu alan zorunludur!"),
      cityId: _yup.string().required("Bu alan zorunludur!"),
    });

    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});
        this.onCreate(isDraft);
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  renderForm() {
    const { formData, formErrors, setFormData } = this.props;
    return (
      <div className="form-groups row">
        <div className="form-group col-md-4">
          <div className="title">Genel Bilgiler</div>
          <div className="form-wrapper">
            <div className="item row">
              <TextField
                placeholder="İlçe Adı"
                value={formData.districtName || ""}
                label="İlçe Adı"
                className="custom-textfield border"
                errorMessage={formErrors.districtName}
                onChange={(event: any) =>
                  setFormData("districtName", event.target.value)
                }
              />
            </div>

            <div className="item row">
              <TextField
                placeholder="Kod"
                value={formData.districtCode || ""}
                label="Kod"
                className="custom-textfield border"
                onChange={(event: any) =>
                  setFormData("districtCode", event.target.value)
                }
              />
            </div>

            <div className="item row">
              <Dropdown
                onChanged={(event: any) => setFormData("cityId", event.key)}
                errorMessage={formErrors.cityId}
                label="İl"
                options={(
                  formData.GroupList?.dataList || ([] as any)
                ).map((item: any) => ({
                  key: item.CityId,
                  text: item.CityName,
                }))}
                className="custom-dropdown"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Yeni İlçe Ekle"
          titleRenderer={() => (
            <span className="edit-title">
              Yönet:
              <strong>Yeni İlçe Ekle</strong>
            </span>
          )}
          showBack
          backUrl="/County"
          history={this.props.history}
          subTitle="Yeni bir İlçe eklemek için aşağıdaki bilgileri doldurunuz."
          rightType="create"
          right={<FormButtons
            isAbsolute
            onSubmit={() => this.onSubmit()}
            customRenderButtons={() => (
              <React.Fragment>
                <PrimaryButton
                  onClick={() => this.onSubmit()}
                  disabled={
                    this.state.messageType === MessageBarType.success
                      ? true
                      : false
                  }
                  styles={{
                    rootDisabled: {
                      background: "#626262",
                      borderColor: "#777373 !important",
                    },
                  }}
                  iconProps={{ iconName: "CheckMark" }}
                  text="Yayımla"
                />
              </React.Fragment>
            )}
          />}
        />
        {this.renderMessageBar()}
        
        {this.renderForm()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCounty);
