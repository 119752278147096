import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import LayoverSubCategory from "src/services/LayoverSubCategory/LayoverSubCategoryService";
import {
  LayoverSubCategoryGetAllRequestModel,
  LayoverSubCategoryChangeStatusRequestModel,
  LayoverSubCategoryGetByIdRequestModel,
  LayoverSubCategoryUpdateRequestModel,
  LayoverSubCategorySaveRequestModel,
} from "src/services/LayoverSubCategory/RequestModel";
import { MessageType } from "./system/types";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllLSU(
  request: LayoverSubCategoryGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const LayoverSubCategoryService = new LayoverSubCategory();
    const result = await LayoverSubCategoryService.getAll(request);
    dispatch(setFormData("GroupListSU", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: LayoverSubCategoryGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const LayoverSubCategoryService = new LayoverSubCategory();
    const result = await LayoverSubCategoryService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: LayoverSubCategorySaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const LayoverSubCategoryService = new LayoverSubCategory();
    const result = await LayoverSubCategoryService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Alt Kategori Yönetimi"
        )
      );
      return result;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Alt Kategori Yönetimi"
        )
      );
      return false;
    }
  };
}
export function update(
  request: LayoverSubCategoryUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const LayoverSubCategoryService = new LayoverSubCategory();
    const result = await LayoverSubCategoryService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Alt Kategori Yönetimi"
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Alt Kategori Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getById(
  request: LayoverSubCategoryGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const LayoverSubCategoryService = new LayoverSubCategory();
    const result = await LayoverSubCategoryService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: LayoverSubCategoryChangeStatusRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const LayoverSubCategoryService = new LayoverSubCategory();
    const result = await LayoverSubCategoryService.changeStatus(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Alt Kategori Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Alt Kategori Yönetimi"
        )
      );
      return false;
    }
  };
}
