import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";

import { History } from "history";
import BasicTable from "../BasicTable";
import {
  TextField,
  Dropdown,
  PrimaryButton,
  Label,
} from "office-ui-fabric-react";
import { getAll, changeStatus } from "src/store/thunkHospital";
import {
  GetHospitalList,
  HospitalChangeStatusRequestModel,
} from "src/services/Hospital/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";
import { castThunkAction } from "src/helpers/casting";
import { toggleConfirmDialog } from "src/store/system/actions";
import { listCount } from "src/config/otherConfig";
import cookie from "react-cookies";
let usertype: any = 0;
interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        getAll,
        changeStatus,
        toggleConfirmDialog,
      },
      dispatch
    ),
  };
}

type HospitalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  pagerTotal: number;
  showfilter:boolean
}

class Hospital extends Component<HospitalProps, OwnState> {
  state = {
    filterData: {
      hospitalName: "",
      status: 1,
    },
    pagerCurrent: 0,
    pagerTotal: 0,
    showfilter:true
  };

  UNSAFE_componentWillMount() {
    this.getData(this.state.pagerCurrent);
    usertype = cookie.load("systemUserInfo");
  }

  getData(pagerSelected: number) {
    const request: GetHospitalList = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      orderField: "HospitalName",
      ...this.state.filterData,
    };
    this.props.getAll(request);
  }

  onDelete(HospitalId: number) {
    const request: HospitalChangeStatusRequestModel = {
      id: HospitalId,
      status: StatusEnums.Deleted,
    };
    castThunkAction(this.props.changeStatus(request)).then((response: any) => {
      this.getData(this.state.pagerCurrent);
    });
  }

  renderFilter() {
    return (
      <div className="table-filter row">
        <TextField
          className="custom-textfield col-md-3"
          label="Sağlık Kuruluşu Adı"
          value={this.state.filterData?.hospitalName || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                hospitalName:
                  event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        <Dropdown
          options={[
            { key: 1, text: "Yayımda" },
            // { key: 2, text: "Taslak" },
            { key: 8, text: "Silindi" },
          ]}
          className="custom-dropdown col-md-3"
          label="Duruma Göre Arama"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.status || 1}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                status: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <PrimaryButton
          text="Arama Yap"
          className="custom-button"
          onClick={() => this.getData(0)}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />

        <PrimaryButton
          text="Tüm Filtreleri Temizle"
          className="custom-button"
          iconProps={{ iconName: "trash" }}
          onClick={() =>
            this.setState(
              {
                filterData: {
                  hospitalName: "",
                  status: 1,
                },
              },
              () => this.getData(0)
            )
          }
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
              background: "#FF4343",
              borderColor: "#FF4350",
            },
          }}
        />
      </div>
    );
  }

  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "hospitalName",
            text: "Sağlık Kuruluşu Adı",
            col: "col-md-3",
          },
          {
            columnName: "cityDistrictName",
            text: "İl / İlçe",
            col: "col-md-2",
          },
          {
            columnName: "createdUserName",
            text: "Kayıt Yapan",
            col: "col-md-1",
          },
          {
            columnName: "createDateString",
            text: "Kayıt Tarihi",
            col: "col-md-1",
          },

          {
            columnName: "updatedUserName",
            text: "Güncelleme Yapan",
            col: "col-md-1",
          },

          {
            columnName: "updateDateString",
            text: "Güncelleme Tarihi",
            col: "col-md-2",
          },
          {
            columnName: "hospitalId",
            text: "İşlem",
            col: "col-md-2",
            className: "operations",
            onRender: (item: { hospitalId: any; Status: StatusEnums }) => (
              <React.Fragment>
                <button
                  onClick={(x) =>
                    window.open("/Hospital/edit/" + item.hospitalId)
                  }
                >
                  <i className="icon-edit-outline"></i> Düzenle
                </button>

                {usertype !== "5" &&
                  usertype !== "2" &&
                  item.Status !== StatusEnums.Deleted && (
                    <button
                      onClick={() =>
                        this.props.toggleConfirmDialog(
                          true,
                          "Silme işlemini gerçekleştirmek istiyor musunuz?",
                          () => this.onDelete(item.hospitalId)
                        )
                      }
                    >
                      <i className="icon-trash-outline"></i> Sil
                    </button>
                  )}
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.dataList || []}
      />
    );
  }
  renderListFirma() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "hospitalName",
            text: "Sağlık Kuruluşu Adı",
            col: "col-md-4",
          },
          {
            columnName: "cityDistrictName",
            text: "İl / İlçe",
            col: "col-md-4",
          },
          {
            columnName: "hospitalId",
            text: "İşlem",
            col: "col-md-4",
            className: "operations",
            onRender: (item: { hospitalId: any; Status: StatusEnums }) => (
              <React.Fragment>
                <button
                  onClick={(x) =>
                    window.open("/Hospital/edit/" + item.hospitalId)
                  }
                >
                  <i className="icon-edit-outline"></i> Düzenle
                </button>

                {usertype !== "2" && item.Status !== StatusEnums.Deleted && (
                  <button
                    onClick={() =>
                      this.props.toggleConfirmDialog(
                        true,
                        "Silme işlemini gerçekleştirmek istiyor musunuz?",
                        () => this.onDelete(item.hospitalId)
                      )
                    }
                  >
                    <i className="icon-trash-outline"></i> Sil
                  </button>
                )}
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.dataList || []}
      />
    );
  }
  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.props.formData.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }
  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getData(selected);
  };
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Anlaşmalı Sağlık Kuruluşu Yönetimi"
          subTitle="Yönetilebilecek tüm alanları aşağıdan görüntüleyebilirsiniz."
          backUrl="/"
          showBack
          history={this.props.history}
        />
        {usertype !== "5" && (
          <PrimaryButton
            onClick={() => this.props.history.push("/Hospital/create")}
            iconProps={{ iconName: "Add" }}
            className="absolute-create"
            text="Yeni Sağlık Kuruluşu Ekle"
            styles={{
              root: {
                background: "#62a98e",
                borderColor: "#5b9e85",
              },
              rootHovered: {
                background: "#5b9e85",
                borderColor: "#5b9e85",
              },
              rootDisabled: {
                background: "#ececec",
              },
            }}
          />
        )}
        <Label className="totalRecord">
          Toplam Kayıt Sayısı: {this.props.formData?.dataCount}
        </Label>
        {
         window.innerWidth < 1100 && (
           <PrimaryButton
             text={this.state.showfilter ? "Filtreyi Gizle" : "Filtreyi Göster"}
             className="custom-button"
             onClick={() => (
               this.setState({ showfilter: !this.state.showfilter })
             )}
             styles={{  
               root: {
                 fontSize: "11px",
                 marginTop: "27px",
                 height: "30px",
               },
             }}
           />
         )
       }
       { window.innerWidth < 1100 ? (this.state.showfilter && this.renderFilter()):this.renderFilter()}        
        {usertype && usertype !== "2" && this.renderList()}
        {usertype && usertype === "2" && this.renderListFirma()}
        {this.renderPager()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Hospital);
