import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { setFormData } from "./form/actions";
import AdditionalAudit from "src/services/AdditionalAudit/AdditionalAuditService";
import {
  toggleLoader,
} from "./system/actions";
import { formatDate } from "src/helpers/converts";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function Report(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const AdditionalAuditService = new AdditionalAudit();
    const result = await AdditionalAuditService.Report(request);
    dispatch(setFormData("Report", result));
    dispatch(setFormData("pageCount", result.pageCount));
    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}
export function ExcelReport(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const AdditionalAuditService = new AdditionalAudit();
    const result = await AdditionalAuditService.Report(request);
    dispatch(setFormData("ExcelReport", result));

    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function excelDownload(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const excelName = "HabersizDenetimRaporu" + formatDate();

    const AdditionalAuditService = new AdditionalAudit();
    await AdditionalAuditService.excelDownload(request, excelName);

    dispatch(toggleLoader());

    return true;
  };
}
