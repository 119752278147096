import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { panels } from "src/utils/constants";
import { Link } from "react-router-dom";
import { History } from "history";
import BasicTable from "../BasicTable";
import {
  Dropdown,
  PrimaryButton,
  TextField,
  Label,
  ComboBox,
  Icon,
  Modal,
  DefaultButton,
} from "office-ui-fabric-react";
import { getList, excelDownload } from "src/store/thunkDenunciation";
import { GetDenunciationListGroupingRequestModel } from "src/services/Denunciation/RequestModel";
import { setFormData } from "src/store/form/actions";
import { StatusEnums } from "src/services/BaseEnum";
import { castThunkAction } from "src/helpers/casting";
import {
  toggleConfirmDialog,
  toggleConfirmFilterRemoveDialog,
} from "src/store/system/actions";
import { listCount } from "src/config/otherConfig";
import { getCompanyUserList } from "src/store/thunksSystemUser";
import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";
import HotelModal from "../HotelModalDenu";
import cookie from "react-cookies";

import {
  CATEGORY_TYPE_ENUM,
  cityReturnOptions,
  filterModal,
  getCategoryType,
  isNumberValue,
} from "src/helpers/functions";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";
import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { CategoryTypeEnum } from "src/services/Statistic/RequestModel";

let usertype: any = 0;

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        excelDownload,
        getList,
        toggleConfirmDialog,
        getCompanyUserList,
        setFormData,
        toggleConfirmFilterRemoveDialog,
        getAllC,
        getAllM,
      },
      dispatch
    ),
  };
}

type LayoverProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;
interface OwnState {
  filterData: any;
  pagerCurrent: number;
  pagerTotal: number;
  excel: boolean;
  download: boolean;
  hotelId: number;
  selectedFilterData: any;
  showfilter: boolean;
  hotelTransferModal: {
    isVisible: boolean;
    fromHotelId: string;
    toHotelId: string;
  };
}

const initialState = {
  filterData: {
    companyUserId: 0,
    firstCompanyUserId: 0,
    denunciationStatus: null,
    orderField: "CreatedSystemUserDatetime",
    isDescending: true,
    status: 0,
    isReported: null,
    isReportedToTga: null,
    isReportedToMinistry: null,
    isOnlyNonCompanyUser: null,
    isCorrection: null,
    isSendIsdem: null,
    searchKey: "",
    isOnlyNonFirstCompanyUser: null,
    assignedCompanyUserId: 0,
    isOnlyNonAssignedCompanyUser: null,
    denunciationChannel: null,
    facilityType: 12,
    certificateTypeId: 0,
    vehiclePlaque: null,
    districtId: 0,
    cityId: 0,
    isActionStatus: null,
    showfilter: true,
  },
  selectedFilterData: {
    companyUserId: 0,
    firstCompanyUserId: 0,
    denunciationStatus: null,
    orderField: "CreatedSystemUserDatetime",
    isDescending: true,
    status: 0,
    isReported: null,
    isReportedToTga: null,
    isReportedToMinistry: null,
    isOnlyNonCompanyUser: null,
    isCorrection: null,
    isSendIsdem: null,
    searchKey: "",
    isOnlyNonFirstCompanyUser: null,
    assignedCompanyUserId: 0,
    isOnlyNonAssignedCompanyUser: null,
    denunciationChannel: null,
    facilityType: 12,
    certificateTypeId: 0,
    vehiclePlaque: null,
    districtId: 0,
    cityId: 0,
    isActionStatus: null,
    showfilter: true,
  },
  pagerCurrent: 0,
  pagerTotal: 0,
  excel: false,
  download: false,
  hotelId: 0,
  hotelTransferModal: {
    isVisible: false,
    fromHotelId: "",
    toHotelId: "",
  },
};
class ListDenunciation extends Component<LayoverProps, OwnState> {
  state: any = initialState;

  componentDidMount() {
    filterModal(this, "denunciationState").finally(() =>
      this.getData(this.state.pagerCurrent)
    );
    const request2: CityGetAllRequestModel = {
      selectItemList: ["CityId", "CityName"],
      orderField: "CityName",
    };

    this.props.getAllM(request2);

    usertype = cookie.load("systemUserInfo");
  }

  rederexcel() {
    return (
      <>
        <PrimaryButton
          text="Rapor Oluştur ve İndir"
          className="custom-button"
          onClick={() => this.downloadexcel()}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
        {this.state.download && (
          <Label style={{ marginLeft: "10px", marginTop: "27px" }}>
            Rapor Oluşturuluyor...
          </Label>
        )}
      </>
    );
  }

  downloadexcel() {
    this.setState({ download: true });

    const request: GetDenunciationListGroupingRequestModel = {
      hotelId: this.props.formData.hotelId,
      ...this.state.filterData,
    };

    castThunkAction(this.props.excelDownload(request)).then((response: any) => {
      this.setState({ excel: true, download: false });
    });

    // const requestexcel: TransportGetAllRequestModel = {
    //   ...this.state.filterData
    // };
    // requestexcel["isPublishQr"] = requestexcel["isPublishQr"] === "1" ? true : (requestexcel["isPublishQr"] === "2" ? false : null)

    // castThunkAction(this.props.getAllPage(requestexcel)).then((response: any) => {

    //   this.setState({ excel: true, download: false })

    // })
  }

  getData(pagerSelected: number) {
    this.setState({ pagerCurrent: pagerSelected }, () => {
      const request: GetDenunciationListGroupingRequestModel = {
        pageSize: listCount,
        pageIndex: pagerSelected,
        hotelId: this.props.formData.hotelId,
        ...this.state.selectedFilterData,
      };

      request["isPublishQr"] =
        request["isPublishQr"] === "1"
          ? true
          : request["isPublishQr"] === "2"
          ? false
          : null;

      castThunkAction(this.props.getList(request)).then((response: any) => {
        const request6: SystemUserGetAllModel = {
          systemUserType: 2,
          orderField: "Title",
          isDescending: false,
        };
        this.props.getCompanyUserList(request6);
      });
    });
  }

  getDataPager(pagerSelected: number) {
    this.setState({
      excel: false,
      download: false,
    });
    const request: GetDenunciationListGroupingRequestModel = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      hotelId: this.props.formData.hotelId,
      ...this.state.selectedFilterData,
    };

    request["isPublishQr"] =
      request["isPublishQr"] === "1"
        ? true
        : request["isPublishQr"] === "2"
        ? false
        : null;

    castThunkAction(this.props.getList(request));
  }

  renderDashboardItems() {
    return (
      <div className="dashboard-items row">
        {panels.map(
          (item, index) =>
            item.children && (
              <React.Fragment>
                <div className="title">
                  {item.text}
                  <div className="title-text">{item.subText}</div>
                </div>
                <div className="childrens row">
                  {item.children.map((children, childrenIndex) => (
                    <div key={index} className={`dashboard-item col-md-3`}>
                      <Link
                        className="row"
                        to={children.url ? children.url : "/"}
                      >
                        <div className="icon">
                          <i className={children.icon}></i>
                        </div>
                        <div className="title">
                          {children.text}
                          <div className="sub-text">{children.subText}</div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )
        )}
      </div>
    );
  }

  filterlist(item: any) {
    switch (item) {
      case 0:
        this.setState({
          filterData: {
            ...this.state.filterData,
            isReported: null,
            isReportedToTga: null,
            isReportedToMinistry: null,
            isCorrection: null,
            isSendIsdem: null,
            isActionStatus: null,
          },
        });
        break;
      case 1:
        this.setState({
          filterData: {
            ...this.state.filterData,
            isReported: null,
            isReportedToTga: null,
            isReportedToMinistry: true,
            isCorrection: null,
            isActionStatus: null,
            isSendIsdem: null,
          },
        });
        break;
      case 2:
        this.setState({
          filterData: {
            ...this.state.filterData,
            isReported: true,
            isReportedToTga: null,
            isReportedToMinistry: null,
            isCorrection: null,
            isActionStatus: null,
            isSendIsdem: null,
          },
        });
        break;
      case 3:
        this.setState({
          filterData: {
            ...this.state.filterData,
            isReported: true,
            isReportedToTga: true,
            isReportedToMinistry: null,
            isCorrection: null,
            isActionStatus: null,
            isSendIsdem: null,
          },
        });
        break;
      case 4:
        this.setState({
          filterData: {
            ...this.state.filterData,
            isReported: null,
            isReportedToTga: true,
            isReportedToMinistry: true,
            isCorrection: null,
            isActionStatus: null,
            isSendIsdem: null,
          },
        });
        break;
      case 5:
        this.setState({
          filterData: {
            ...this.state.filterData,
            isReported: null,
            isReportedToTga: null,
            isReportedToMinistry: null,
            isCorrection: true,
            isActionStatus: null,
            isSendIsdem: null,
          },
        });
        break;
      case 6:
        this.setState({
          filterData: {
            ...this.state.filterData,
            isReported: null,
            isReportedToTga: true,
            isReportedToMinistry: null,
            isCorrection: true,
            isActionStatus: null,
            isSendIsdem: null,
          },
        });
        break;
      case 7:
        this.setState({
          filterData: {
            ...this.state.filterData,
            isSendIsdem: true,
            isActionStatus: null,
            isReported: null,
            isReportedToTga: null,
            isReportedToMinistry: null,
            isCorrection: null,
          },
        });
        break;

      case 8:
        this.setState({
          filterData: {
            ...this.state.filterData,
            isReported: false,
            isReportedToTga: false,
            isReportedToMinistry: false,
            isCorrection: false,
            isSendIsdem: false,
            isActionStatus: null,
          },
        });
        break;

      case 9:
        this.setState({
          filterData: {
            ...this.state.filterData,
            isSendIsdem: false,
            isActionStatus: null,
            isReported: null,
            isReportedToTga: null,
            isReportedToMinistry: null,
            isCorrection: null,
          },
        });
        break;
      case 10:
        this.setState({
          filterData: {
            ...this.state.filterData,
            isReported: false,
            isReportedToTga: false,
            isReportedToMinistry: true,
            isCorrection: null,
            isActionStatus: null,
            isSendIsdem: null,
          },
        });
        break;
      case 11:
        this.setState({
          filterData: {
            ...this.state.filterData,
            isReported: true,
            isReportedToTga: false,
            isReportedToMinistry: false,
            isCorrection: null,
            isActionStatus: null,
            isSendIsdem: null,
          },
        });
        break;
      case 12:
        this.setState({
          filterData: {
            ...this.state.filterData,
            isReported: null,
            isReportedToTga: null,
            isReportedToMinistry: null,
            isCorrection: null,
            isActionStatus: true,
            isSendIsdem: null,
          },
        });
        break;
      default:
        break;
    }
  }
  filterlistKTB(item: any) {
    switch (item) {
      case 0:
        this.setState({
          filterData: {
            ...this.state.filterData,
            isReported: null,
            isReportedToTga: null,
            isReportedToMinistry: null,
            isCorrection: null,
            isSendIsdem: null,
            isActionStatus: null,
          },
        });
        break;
      case 3:
        this.setState({
          filterData: {
            ...this.state.filterData,
            isReported: null,
            isReportedToTga: true,
            isReportedToMinistry: true,
            isCorrection: null,
            isSendIsdem: null,
            isActionStatus: null,
          },
        });
        break;
      // case 7:
      //   this.setState({
      //     filterData: {
      //       isReportedToTga: null,
      //       isCorrection: null,
      //       isSendIsdem: true,
      //       isActionStatus: null,
      //       isReported: null,
      //       IsReportedToMinistry: true,
      //     },
      //   });
      //   break;
      case 11:
        this.setState({
          filterData: {
            ...this.state.filterData,
            isSendIsdem: null,
            isReported: null,
            isReportedToTga: false,
            isReportedToMinistry: true,
            isCorrection: null,
            isActionStatus: null,
          },
        });
        break;
      // case 12:
      //   this.setState({
      //     filterData: {
      //       isReported: null,
      //       isSendIsdem: null,
      //       isReportedToTga: null,
      //       isReportedToMinistry: null,
      //       isCorrection: null,
      //       isActionStatus: true,
      //     },
      //   });
      //   break;
      // default:
      //   break;
    }
  }

  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Tümünü Seçiniz" });
    }

    return newArray;
  }
  cityOnChange(event: any, option: any, value: any) {
    if (value !== undefined) {
      this.CityChange(option.key);
      this.setState({
        filterData: {
          ...this.state.filterData,
          cityId: option.key,
          districtId: 0,
        },
      });
    } else {
      this.setState({
        filterData: {
          ...this.state.filterData,
          cityId: 0,
          districtId: 0,
        },
      });
      this.props.setFormData("GroupListC", []);
    }
  }

  CityChange(item: any) {
    this.setState(
      {
        filterData: {
          ...this.state.filterData,
          cityId: item,
        },
      },
      () => {
        const request2: CountyGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "cityId",
              value: item,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
            {
              propertyName: "Status",
              value: 1,
              isOr: false,
              conditionOperator: 0,
            },
          ],
          selectItemList: ["DistrictId", "DistrictName"],
          orderField: "DistrictName",
        };
        this.props.getAllC(request2);
      }
    );
  }

  renderFilter() {
    return (
      <div className="table-filter row">
        {((usertype && usertype === "1") || usertype === "3") && (
          <Dropdown
            options={[
              { key: null, text: "Tümünü Seç" },
              { key: 1, text: "HES" },
              { key: 2, text: "Sertifika Mobil" },
            ].map((item: any) => ({
              key: item.key,
              text: item.text,
            }))}
            className="custom-dropdown col-md-2"
            label="Şikayet Kaynağı"
            placeholder="Tümünü Seç"
            selectedKey={this.state.filterData?.denunciationChannel}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  denunciationChannel: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />
        )}

        <TextField
          className="custom-textfield col-md-1"
          label="Tesis ID"
          value={this.state.filterData?.facilityId || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                facilityId: isNumberValue(event.target.value),
              },
            });
          }}
          type="text"
        />

        <Dropdown
          options={CATEGORY_TYPE_ENUM}
          className="custom-dropdown col-md-2"
          label="Tesis Kategorisi"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.facilityType}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                facilityType: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
       
          <Dropdown
            options={[
              { key: 0, text: "Tümü" },
              { key: 4, text: "TC Kültür ve Turizm Bakanlığı" },
              { key: 5, text: "Diğer" },
            ]}
            className="custom-dropdown col-md-2"
            label="Tesis Belge Tipi"
            selectedKey={this.state.filterData?.certificateTypeId || 0}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  certificateTypeId: option.key === 2 ? null : option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />
        
        <TextField
          className="custom-textfield col-md-2"
          label="Tesis Adı"
          value={this.state.filterData?.searchKey || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                searchKey: event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        {usertype && usertype === "2" && usertype === "4" && (
          <Dropdown
            options={[
              { key: null, text: "Tümünü Seç" },
              { key: false, text: "Cevaplanmamış" },
              { key: true, text: "Cevaplanmış" },
            ].map((item: any) => ({
              key: item.key,
              text: item.text,
            }))}
            className="custom-dropdown col-md-2"
            label="Şikayet Durumu"
            placeholder="Tümünü Seç"
            selectedKey={this.state.filterData?.isReportedToTga}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  isReportedToTga: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />
        )}
        {usertype !== "2" && usertype !== "4" && usertype !== "5" && (
          <Dropdown
            options={[
              { key: null, text: "Tümünü Seç" },

              {
                key: 3,
                text: "Denetleyici Firması Değiştiği için Aksiyon Alınamaz",
              },
              { key: 20, text: "Birinci İhlal Uyarı" },
              { key: 30, text: "İkinci İhlal İdari Para Cezası Teklifi" },
              {
                key: 40,
                text: "Üçüncü İhlal 1 (Bir) Gün Faaliyet Durdurma Teklifi",
              },
              {
                key: 50,
                text: "Dördüncü İhlal 3 (Üç) Gün Faaliyet Durdurma Teklifi",
              },
              { key: 60, text: "Aykırılık tespit edilmemiştir" },
              { key: 70, text: "Mükerrer İhbar" },
              { key: 100, text: "Eksik bilgiden dolayı işlem yapılamamıştır." },
              { key: 110, text: "Asılsız İhbar" },
            ].map((item: any) => ({
              key: item.key,
              text: item.text,
            }))}
            className="custom-dropdown col-md-3"
            label="Şikayet Sonuç Durumu"
            placeholder="Tümünü Seç"
            selectedKey={this.state.filterData?.denunciationStatus || null}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  denunciationStatus: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />
        )}
        <ComboBox
          selectedKey={
            this.state.filterData && this.state.filterData.cityId
              ? this.state.filterData.cityId
              : ""
          }
          label="İl"
          allowFreeform={true}
          autoComplete="on"
          multiSelect={false}
          className="custom-dropdown col-md-2"
          onChange={(event: any, option: any, value: any) =>
            this.cityOnChange(event, option, value)
          }
          options={(this.props.formData.GroupList?.dataList
            ? cityReturnOptions(
                this.props.formData.GroupList?.dataList,
                "CityId",
                "CityName"
              )
            : ([] as any)
          ).map((item: any) => ({ key: item.CityId, text: item.CityName }))}
        />
        <Dropdown
          defaultSelectedKey={this.state.filterData?.districtId || ""}
          onChanged={(event: any) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                districtId: event.key,
              },
            })
          }
          label="İlçe"
          options={(this.props.formData.GroupListC?.dataList
            ? this.returnOptions(
                this.props.formData.GroupListC?.dataList,
                "DistrictId",
                "DistrictName"
              )
            : ([] as any)
          ).map((item: any) => ({
            key: item.DistrictId,
            text: item.DistrictName,
          }))}
          className="custom-dropdown col-md-2"
        />
        <TextField
          className="custom-textfield col-md-1"
          label="Plaka No"
          value={this.state.filterData?.vehiclePlaque || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                vehiclePlaque:
                  event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        {usertype && usertype !== "2" && usertype !== "4" && (
          <Dropdown
            options={[
              { key: 0, text: "Tümünü Seç" },
              { key: 8, text: "Hiçbir İşlem Yapılmamış" },
              { key: 1, text: "KTB'ye Gönderilmiş" },
              { key: 2, text: "Firmaya Gönderilmiş" },
              { key: 3, text: "Firmaya Cevaplandırmış" },
              { key: 4, text: "KTB Cevaplandırmış" },
              { key: 5, text: "Revize  İstenmiş" },
              { key: 6, text: "Revize Cevaplandırılmış" },
              { key: 12, text: "İşlem Yapılamadı" },
            ].map((item: any) => ({
              key: item.key,
              text: item.text,
            }))}
            className="custom-dropdown col-md-2"
            label="Şikayet İşlem Durumu"
            placeholder="Tümünü Seç"
            onChange={(event: any, option: any) => {
              this.filterlist(option.key);
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />
        )}

        <Dropdown
          options={[
            { key: 0, text: "Seçiniz" },
            { key: 3, text: "Şikayet Oluşturma Tarihine Göre Yeniden Eskiye" },
            { key: 4, text: "Şikayet Oluşturma Tarihine Göre Eskiden Yeniye" },
            { key: 1, text: "Olay Tarihine Göre Yeniden Eskiye" },
            { key: 2, text: "Olay Tarihine Göre Eskiden Yeniye" },
          ].map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-3"
          label="Sıralama"
          placeholder="Şikayet Oluşturma Tarihine Göre Yeniden Eskiye"
          onChange={(event: any, option: any) => {
            this.order(option.key);
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />

        {usertype && usertype !== "2" && usertype !== "4" && (
          <Dropdown
            options={[
              { key: 0, text: "Tümünü Seç" },
              { key: 7, text: "Kapatıldı" },
              { key: 9, text: "Değerlendirme Sürecinde" },
            ].map((item: any) => ({
              key: item.key,
              text: item.text,
            }))}
            className="custom-dropdown col-md-2"
            label="Kapatılma Durumu"
            placeholder="Tümünü Seç"
            onChange={(event: any, option: any) => {
              this.filterlist(option.key);
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />
        )}

        {usertype && usertype === "2" && (
          <Dropdown
            options={[
              { key: 0, text: "Tümünü Seç" },
              { key: 11, text: "Cevap Bekleniyor" },
              { key: 3, text: "Cevaplandırılmış" },
              // { key: 7, text: "İçişleri Bakanlığına İletilmiş" },
              // { key: 12, text: "İşlem Yapılamadı" },
            ].map((item: any) => ({
              key: item.key,
              text: item.text,
            }))}
            className="custom-dropdown col-md-2"
            label="Şikayet İşlem Durumu"
            placeholder="Tümünü Seç"
            onChange={(event: any, option: any) => {
              this.filterlist(option.key);
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />
        )}
        {usertype && usertype === "4" && (
          <Dropdown
            options={[
              { key: 0, text: "Tümünü Seç" },
              { key: 11, text: "Cevap Bekleniyor" },
              { key: 3, text: "Cevaplandırılmış" },
              // { key: 7, text: "İçişleri Bakanlığına İletilmiş" },
              // { key: 12, text: "İşlem Yapılamadı" },
            ].map((item: any) => ({
              key: item.key,
              text: item.text,
            }))}
            className="custom-dropdown col-md-2"
            label="Şikayet İşlem Durumu"
            placeholder="Tümünü Seç"
            onChange={(event: any, option: any) => {
              this.filterlistKTB(option.key);
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />
        )}
        {usertype && usertype !== "2" && usertype !== "4" ? (
          <ComboBox
            placeholder="Seçiniz"
            label="Önceki Denetleyici Firma"
            allowFreeform
            autoComplete="on"
            className="custom-dropdown col-md-2"
            selectedKey={
              this.state.filterData.isOnlyNonFirstCompanyUser === true
                ? 100000
                : this.state.filterData.firstCompanyUserId
            }
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  firstCompanyUserId:
                    option && option.key
                      ? option.key === 100000
                        ? 0
                        : option?.key
                      : 0,
                  isOnlyNonFirstCompanyUser:
                    option && option.key
                      ? option.key === 100000
                        ? true
                        : null
                      : null,
                },
              });
            }}
            options={(this.props.formData.CompanyUserList?.dataList
              ? this.returnOptions(
                  this.props.formData.CompanyUserList?.dataList,
                  "systemUserId",
                  "title"
                )
              : ([] as any)
            ).map((item: any) => ({
              key: item.systemUserId,
              text: item.title,
            }))}
          />
        ) : (
          ""
        )}

        {usertype && usertype !== "2" && usertype !== "4" ? (
          <ComboBox
            placeholder="Seçiniz"
            label="Güncel Denetleyici Firma"
            allowFreeform
            autoComplete="on"
            className="custom-dropdown col-md-2"
            selectedKey={
              this.state.filterData.isOnlyNonCompanyUser === true
                ? 100000
                : this.state.filterData.companyUserId
            }
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  companyUserId:
                    option && option.key
                      ? option.key === 100000
                        ? 0
                        : option?.key
                      : 0,
                  isOnlyNonCompanyUser:
                    option && option.key
                      ? option.key === 100000
                        ? true
                        : null
                      : null,
                },
              });
            }}
            options={(this.props.formData.CompanyUserList?.dataList
              ? this.returnOptions(
                  this.props.formData.CompanyUserList?.dataList,
                  "systemUserId",
                  "title"
                )
              : ([] as any)
            ).map((item: any) => ({
              key: item.systemUserId,
              text: item.title,
            }))}
          />
        ) : (
          ""
        )}

        {usertype && usertype !== "2" && usertype !== "4" ? (
          <ComboBox
            placeholder="Seçiniz"
            label="Atama Yapılan Denetleyici Firma"
            allowFreeform
            autoComplete="on"
            className="custom-dropdown col-md-2"
            selectedKey={
              this.state.filterData.isOnlyNonAssignedCompanyUser === true
                ? 100000
                : this.state.filterData.assignedCompanyUserId
            }
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  assignedCompanyUserId:
                    option && option.key
                      ? option.key === 100000
                        ? 0
                        : option.key
                      : 0,
                  isOnlyNonAssignedCompanyUser:
                    option && option.key
                      ? option.key === 100000
                        ? true
                        : null
                      : null,
                },
              });
            }}
            options={(this.props.formData.CompanyUserList?.dataList
              ? this.returnOptions(
                  this.props.formData.CompanyUserList?.dataList,
                  "systemUserId",
                  "title"
                )
              : ([] as any)
            ).map((item: any) => ({
              key: item.systemUserId,
              text: item.title,
            }))}
          />
        ) : (
          ""
        )}
        {/* <PrimaryButton
          text={
            this.props.formData.hotelName
              ? this.props.formData.hotelName
              : "Tesis Seç"
          }
          className="custom-button"
          onClick={(x) => this.props.setFormData("hotelModalShow", true)}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
              background: "#aea64c",
              borderColor: "#aea64c",
            },
            rootHovered: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
              background: "#aea64c",
              borderColor: "#aea64c",
            },
            rootDisabled: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
              background: "#aea64c",
            },
          }}
        /> */}
        <PrimaryButton
          text="Arama Yap"
          className="custom-button"
          onClick={() => {
            this.setState(
              { ...this.state, selectedFilterData: this.state.filterData },
              () => {
                cookie.save("denunciationState", JSON.stringify(this.state), {
                  path: "/",
                });
                this.getData(0);
              }
            );
          }}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
        {this.rederexcel()}
        <PrimaryButton
          text="Tüm Filtreleri Temizle"
          className="custom-button"
          onClick={() => {
            this.setState(
              {
                ...this.state,
                filterData: initialState.filterData,
                selectedFilterData: initialState.selectedFilterData,
              },
              () => {
                cookie.remove("denunciationState");
                window.location.reload();
              }
            );
          }}
          iconProps={{ iconName: "trash" }}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
              background: "#FF4343",
              borderColor: "#FF4350",
            },
          }}
        />
      </div>
    );
  }

  order(item: any) {
    switch (item) {
      case 1:
        this.setState({
          filterData: {
            ...this.state.filterData,
            orderField: "EventTime",
            isDescending: true,
          },
        });
        break;
      case 2:
        this.setState({
          filterData: {
            ...this.state.filterData,
            orderField: "EventTime",
            isDescending: false,
          },
        });
        break;

      case 3:
        this.setState({
          filterData: {
            ...this.state.filterData,
            orderField: "CreatedSystemUserDatetime",
            isDescending: true,
          },
        });
        break;

      case 4:
        this.setState({
          filterData: {
            ...this.state.filterData,
            orderField: "CreatedSystemUserDatetime",
            isDescending: false,
          },
        });
        break;

      default:
        break;
    }
  }
  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "facilityId",
            text: "ID",
            col: "col-md-1",
          },
          {
            columnName: "cityName",
            text: "İl",
            col: "col-md-1",
          },

          {
            columnName: "districtName",
            text: "İlçe",
            col: "col-md-1",
          },
          {
            columnName: "brandName",
            text: "Tesis Adı",
            col: "col-md-4",
          },
          {
            columnName: "facilityType",
            text: "Tesis Türü",
            col: "col-md-1",
            onRender: (item: any) => getCategoryType(item.facilityType),
          },
          {
            columnName: "denunciationCount",
            text: "Şikayet Sayısı",
            col: "col-md-2",
          },
          {
            columnName: "Action",
            text: "İşlem",
            col: "col-md-2",
            className: "operations",
            onRender: (item: {
              facilityId: any;
              Status: StatusEnums;
              facilityType: number;
            }) => (
              <React.Fragment>
                {
                  <button
                    onClick={(x) =>
                      this.goEdit(item.facilityId, item.facilityType)
                    }
                  >
                    <i className="icon-edit-outline"></i> İncele
                  </button>
                }
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.data?.dataList || []}
      />
    );
  }

  renderListFull() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "facilityId",
            text: "ID",
            col: "col-md-1-4",
          },
          // {
          //   columnName: "cityDistrictName",
          //   text: "İl / İlçe",
          //   col: "col-md-1-3",
          // },

          {
            columnName: "cityName",
            text: "İl",
            col: "col-md-1-4",
          },

          {
            columnName: "districtName",
            text: "İlçe",
            col: "col-md-1-4",
          },
          {
            columnName: "vehiclePlaque",
            text: "Plaka No",
            col: "col-md-1-4",
          },
          {
            columnName: "brandName",
            text: "Tesis Adı",
            col: "col-md-1-4",
          },
          {
            columnName: "facilityType",
            text: "Tesis Türü",
            col: "col-md-1",
            onRender: (item: any) => getCategoryType(item.facilityType),
          },
          {
            columnName: "firstCompanyUserTitle",
            text: "Önceki Denetleyici Firma",
            col: "col-md-1-4",
          },
          {
            columnName: "companyUserTitle",
            text: "Güncel Denetleyici Firma",
            col: "col-md-1-4",
          },
          {
            columnName: "assignedCompanyUserTitle",
            text: "Atama Yapılan Denetleyici Firma",
            col: "col-md-2",
          },
          {
            columnName: "denunciationCount",
            text: "Şikayet Sayısı",
            col: "col-md-1-4",
          },
          {
            columnName: "hotelId",
            text: "İşlem",
            col: "col-md-1-4",
            className: "operations",
            onRender: (item: {
              facilityId: any;
              Status: StatusEnums;
              facilityType: number;
            }) => (
              <React.Fragment>
                <button
                  onClick={(x) =>
                    this.goEdit(item.facilityId, item.facilityType)
                  }
                >
                  <i className="icon-edit-outline"></i> İncele
                </button>
              </React.Fragment>
            ),
          },
          {
            columnName: "toHotelId",
            text: "Tesis Aktarım Bilgisi",
            col: "col-md-1-4",
            className: "operations",
            hidden: usertype === "5" || usertype === "4",
            onRender: (item: {
              facilityId: any;
              Status: StatusEnums;
              toHotelId: string;
              fromHotelId: string;
            }) => (
              <React.Fragment>
                {item.toHotelId && (
                  <button
                    onClick={(x) =>
                      this.setState({
                        hotelTransferModal: {
                          isVisible: true,
                          fromHotelId: item.fromHotelId,
                          toHotelId: item.toHotelId,
                        },
                      })
                    }
                  >
                    <i className="icon-edit-outline"></i>
                    Detay
                  </button>
                )}
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.data?.dataList || []}
      />
    );
  }

  goEdit(facilityId: any, facilityType: number): any {
    window.open("/denunciation/edit/" + facilityId + "/" + facilityType);
  }

  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.props.formData.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
        forcePage={this.state.pagerCurrent || 0}
      />
    );
  }
  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState(
      {
        ...this.state,
        pagerCurrent: selected,
        filterData: this.state.selectedFilterData,
      },
      () => {
        this.getDataPager(selected);
      }
    );
  };
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Şikayet Yönetimi"
          subTitle="Yönetilebilecek tüm alanları aşağıdan görüntüleyebilirsiniz."
          backUrl="/"
          showBack
          history={this.props.history}
        />

        {this.props.formData.hotelModalShow && (
          <div className="modal-overlay">
            <div className="modal">
              <button
                className="btn-close"
                onClick={(x) => this.props.setFormData("hotelModalShow", false)}
              >
                Kapat
              </button>
              <HotelModal history={this.props.history} />
            </div>
          </div>
        )}
        <Label className="totalRecord">
          Toplam Şikayet Alan Tesis Sayısı:
          {this.props.formData?.data?.dataCount}
        </Label>
        <Label className="totalRecord">
          Toplam Şikayet Sayısı:
          {this.props.formData?.data?.totalDenunciationCount}
        </Label>
        {window.innerWidth < 1100 && (
          <PrimaryButton
            text={this.state.showfilter ? "Filtreyi Gizle" : "Filtreyi Göster"}
            className="custom-button"
            onClick={() =>
              this.setState({ showfilter: !this.state.showfilter })
            }
            styles={{
              root: {
                fontSize: "11px",
                marginTop: "27px",
                height: "30px",
              },
            }}
          />
        )}
        {window.innerWidth < 1100
          ? this.state.showfilter && this.renderFilter()
          : this.renderFilter()}

        {usertype !== "2" && this.renderListFull()}
        {usertype === "2" && this.renderList()}
        {this.renderPager()}
        {this.renderHotelTransferModal()}
      </div>
    );
  }

  renderHotelTransferModal() {
    const {
      isVisible = false,
      fromHotelId = "",
      toHotelId = "",
    } = this.state.hotelTransferModal;
    return isVisible ? (
      <Modal className={"warning custom-message-box danger"} isOpen={true}>
        <div className="modal-header row">
          <div className="title">Aktarım Bilgisi!</div>
          <span
            onClick={() =>
              this.setState({
                hotelTransferModal: {
                  isVisible: false,
                  fromHotelId: "",
                  toHotelId: "",
                },
              })
            }
            className="close"
          >
            <Icon iconName="ChromeClose" />
          </span>
        </div>
        <div style={{ paddingBottom: 0 }} className="description modal-content">
          <div className="modal-indicators">
            <div
              dangerouslySetInnerHTML={{
                __html: `<a href="/denunciation/edit/${fromHotelId}/${CategoryTypeEnum.Hotel}" target='blank' style="color:#178df7">${fromHotelId}</a> ID numaralı tesis bilgileri <a href="/denunciation/edit/${toHotelId}/${CategoryTypeEnum.Hotel}" target='blank' style="color:#178df7">${toHotelId}</a> ID numaralı tesise
                aktarılmıştır.`,
              }}
            ></div>
            <DefaultButton
              style={{
                marginTop: 15,
                marginRight: 10,
              }}
              text="Kapat"
              onClick={() => {
                this.setState({
                  hotelTransferModal: {
                    isVisible: false,
                    fromHotelId: "",
                    toHotelId: "",
                  },
                });
              }}
            />
          </div>
        </div>
      </Modal>
    ) : null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListDenunciation);
