import { HttpClient } from "src/library/HttpClient";
import {
  LayoverFacilityTypeChangeStatusRequestModel,
  LayoverFacilityTypeGetByIdRequestModel,
  LayoverFacilityTypeUpdateRequestModel,
  LayoverFacilityTypeSaveRequestModel,
  LayoverFacilityTypeGetAllRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class LayoverFacilityType {
  async getAll(request: LayoverFacilityTypeGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.LayoverFacilityType + "GetAll",
      { ...request, token }
    );
  }

  async save(request: LayoverFacilityTypeSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.LayoverFacilityType + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: LayoverFacilityTypeUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.LayoverFacilityType + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(request: LayoverFacilityTypeGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.LayoverFacilityType + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(
    request: LayoverFacilityTypeChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.LayoverFacilityType + "ChangeStatus",
      { ...request, token }
    );
  }
}
