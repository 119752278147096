import * as React from "react";
import {
  Dialog,
  DialogType,
  DialogFooter,
} from "office-ui-fabric-react/lib/Dialog";
import {
  PrimaryButton,
  DefaultButton,
} from "office-ui-fabric-react/lib/Button";
import { getId } from "office-ui-fabric-react/lib/Utilities";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RootState } from "src/store/reducer";
import { toggleConfirmFilterRemoveDialog } from "src/store/system/actions";

function mapStateToProps(state: RootState) {
  return {
    show: state.system.toggleConfirmFilter.show,
    text: state.system.toggleConfirmFilter.text,
    callback: state.system.toggleConfirmFilter.callback,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      toggleConfirmFilterRemoveDialog,
    },
    dispatch
  );
}

type ConfirmProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class ConfirmFilter extends React.Component<ConfirmProps> {
  _labelId: string = getId("dialogLabel");
  _subTextId: string = getId("subTextLabel");

  render() {
    return (
      <div>
        <Dialog
          hidden={!this.props.show}
          onDismiss={() => this.props.toggleConfirmFilterRemoveDialog(false, "", () => { })}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Eski Filtre Bulundu",
            closeButtonAriaLabel: "Close",
            subText: this.props.text,
          }}
          modalProps={{
            titleAriaId: this._labelId,
            subtitleAriaId: this._subTextId,
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                this.props.callback(true);
                this.props.toggleConfirmFilterRemoveDialog(false, "", () => { });
              }}
              text="Evet"
            />
            <DefaultButton
              onClick={() => {
                this.props.callback(false);
                this.props.toggleConfirmFilterRemoveDialog(false, "", () => { })
              }}
              text="Hayır"
            />
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmFilter);
