import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  TextField,
  // MessageBar,
  MessageBarType,
  PrimaryButton,
  Dropdown,
  Label,
} from "office-ui-fabric-react";
import {
  setFormData,
  setFormErrors,
  initialFormData,
} from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";

import { save } from "src/store/thunkVehicle";
import { castThunkAction } from "src/helpers/casting";
import { StatusEnums } from "src/services/BaseEnum";
import { getAllTy, showErrorDialog } from "src/store/thunkCertificateType";

import {
  ConditionOperatorEnum,
  CityGetAllRequestModel
} from "src/services/City/RequestModel";
import { CertificateTypeGetAllRequestModel } from "src/services/CertificateType/RequestModel";
import cookie from "react-cookies";

import _ from "lodash";
import { getAllVD } from "src/store/thunkVehicleDocumentType";
import { getAllVT } from "src/store/thunkVehicleType";
import { FileUpload } from "../FileUpload";
import { apiConfig, qrUrl } from "src/config/apiConfig";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toDate } from "src/helpers/converts";
import { VehicleDocumentTypeGetAllRequestModel } from "src/services/VehicleDocumentType/RequestModel";
import { VehicleTypeGetAllRequestModel } from "src/services/VehicleType/RequestModel";
import { VehicleSaveRequestModel } from "src/services/Vehicle/RequestModel";
import { formatSize, isNumberValue } from "src/helpers/functions";
import { getAllC } from "src/store/thunkCounty";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";
import { getAllM } from "src/store/thunksCity";




let token = "";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        initialFormData,
        save,
        getAllVD,
        getAllC,
        getAllM,
        getAllVT,
        getAllTy,
        showErrorDialog,
      },
      dispatch
    ),
  };
}

type CreateVehicleProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  showFields: boolean;
  messageType: MessageBarType;
  isFileUploading: boolean;
  infoMessageGetDocumentNo: string;
  phone: string;
  onlyCountry: string[];
}
let usertype: any = 0;
class CreateVehicle extends Component<CreateVehicleProps, OwnState> {
  state = {
    messageType: MessageBarType.warning,
    showFields: true,
    isFileUploading: false,
    infoMessageGetDocumentNo: "",
    phone: "",
    onlyCountry: ["tr"],
  };

  UNSAFE_componentWillMount() {
    token = cookie.load("userId");
    this.props.initialFormData({
      status: StatusEnums.Published,
    });

    usertype = cookie.load("systemUserInfo");


    const request4: CertificateTypeGetAllRequestModel = {
      selectItemList: ["CertificateTypeId", "Title"],
      criteriaItemList: [
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
    };
    this.props.getAllTy(request4);
    const request2: CityGetAllRequestModel = {
      selectItemList: ["CityId", "CityName"],
      isDescending: false,
      orderField: "CityName",
      criteriaItemList: [
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
    };
    this.props.getAllM(request2);




    const request8: VehicleDocumentTypeGetAllRequestModel = {
      selectItemList: ["VehicleDocumentTypeId", "VehicleDocumentTypeName"],
      criteriaItemList: [
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
    };
    this.props.getAllVD(request8);

    const request7: VehicleTypeGetAllRequestModel = {
      selectItemList: ["VehicleTypeId", "VehicleTypeName"],
      criteriaItemList: [
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
    };
    this.props.getAllVT(request7);
  }

  componentDidUpdate(nextprops: any) {
    if (this.props.formData.certificateTypeId !== 0) {
      this.setLabelDescription(
        "companyName",
        "<span id='companyName-span' class='descSpan'>Taşıt sahibi olan firmanın ticari ünvanı</span>"
      );

      if (this.props.formData.certificateTypeId === 4) {
        this.setLabelDescription(
          "documentNoDesc",
          "<span id='documentNoDesc-span' class='descSpan'>TC Kültür ve Turizm Bakanlığı tarafından Taşıte verilen çalışma belge numarası</span>"
        );
      }
    }

  }

  insertAfter(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  insertBefore(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode);
  }

  componentDidMount() {
    this.setDdlDescription(
      "certificateTypeIdDesc-label",
      "<br/> <span id='certificateTypeIdDesc-label-span' class='descSpan'>Taşıtin kayıt edileceği belge tipini seçiniz. Taşıte ait <b>T.C Kültür ve Turizm Bakanlığı</b> Belgesi bulunuyorsa bu belge tipini seçip ilgili belgeyi yüklemeniz ve belgeye uygun bilgileri doldurmanız gerekmektedir. Eğer Taşıte ait bu belge bulunmuyorsa lütfen <b>Diğer</b> seçerek ilerleyeniz.</span>"
    );
  }

  setDdlDescription(id: string, message: string) {
    const el = document.createElement("span");
    el.innerHTML = message;
    const div = document.getElementById(id);
    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div, el);
    }
  }

  setLabelDescription(id: string, message: string) {
    const el2 = document.createElement("span");
    el2.innerHTML = message;
    const div2 = document.querySelectorAll('[for="' + id + '"]');

    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div2[0], el2);
    }
  }

  // renderTextByMessageType() {
  //   switch (this.state.messageType) {
  //     case MessageBarType.success:
  //       return "Kaydetme işlemi başarıyla gerçekleşmiştir.";
  //     case MessageBarType.error:
  //       return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
  //     default:
  //       return "Lütfen aşağıdaki bilgileri doldurun ve formu oluşturun";
  //   }
  // }

  // renderMessageBar() {
  //   return (
  //     <MessageBar messageBarType={this.state.messageType} isMultiline={false}>
  //       {this.renderTextByMessageType()}
  //     </MessageBar>
  //   );
  // }

  onCreate(isDraft?: boolean) {
    let request: VehicleSaveRequestModel = {
      data: isDraft
        ? {
          ...this.props.formData,
          status: StatusEnums.Draft,
          GroupListCR: {},
          GroupListTY: {},
          GroupListVD: {},
          GroupListVT: {}
        }
        : {
          ...this.props.formData,
          GroupListCR: {},
          GroupListTY: {},
          GroupListVD: {},
          GroupListVT: {}
        },
    };
    if (
      this.props.formData.attachmentList &&
      this.props.formData.attachmentList.length > 0
    ) {

      request.data["attachmentIdList"] = (
        this.props.formData.attachmentList || []
      ).map((item: any) => item.attachmentId);
    }
    // if (
    //   this.props.formData.attachmentCertificateList &&
    //   this.props.formData.attachmentCertificateList.length > 0
    // ) {
    //   request.data["attachmentCertificateIdList"] = (
    //     this.props.formData.attachmentCertificateList || []
    //   ).map((item: any) => item.attachmentId);
    // }
    // if (!request.data.documentNo) {
    //   request.data.documentNo = 0;
    // }


    if (
      !this.props.formData.attachmentList ||
      this.props.formData.attachmentList.length <= 2
    ) {

      castThunkAction(
        this.props.showErrorDialog({
          title: "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
          desc: "Ruhsat - Taşıt Kartı - Yetki Belgesi (3 dosyayı da) Yüklemeniz Gerekmektedir",
        })
      ).then((response: any) => {});
    } else {
      castThunkAction(this.props.save(request)).then((response: any) => {
        this.props.setFormData(
          "initialAttachmentList",
          this.props.formData.attachmentList
        );

        this.setState(
          {
            messageType: response ? MessageBarType.success : MessageBarType.error,
          },
          () => {
            if (response) {
              this.props.history.push("/Transport/edit/" + response.id);
            } else {
              setTimeout(() => {
                //window.location.reload(false)
              }, 1000);
            }
          }
        );
      });
    }
  }

  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  removeElement(id: any) {
    var elem = document.getElementById(id);
    return elem?.parentNode?.removeChild(elem);
  }
  filterFileds(val: any) {
    if (
      val !== "" &&
      val !== 0 &&
      val !== "0" &&
      val !== null &&
      val !== "null" &&
      val !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  }
  onSubmit(isDraft?: boolean) {
    let desc = "";
    // eslint-disable-next-line
    const webSite = /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    if (this.props.formData.webSite) {
      if (!webSite.test(this.props.formData.webSite)) {
        desc += "Geçersiz web sitesi,  <br/>";
      }
    }


    if (!this.filterFileds(this.props.formData.vehiclePlaque)) {
      desc += "Taşıt Plaka No,  <br/>";
    }

    if (!this.filterFileds(this.props.formData.certificateTypeId)) {
      desc += "Taşıt Belge Tipi, <br/>";
    }
    if (this.props.formData.certificateTypeId === 4) {
      if ((!this.filterFileds(this.props.formData.documentNo)) || this.props.formData.documentNo === "0") {
        desc += "Belge No,  <br/>";
      }
    }

    //https://b24-z1nwey.bitrix24.com.tr/workgroups/group/8/tasks/task/view/2042/ madde revize.
    if (this.props.formData.certificateTypeId === 5) {
      this.props.setFormData("documentNo", "");
    }

    if (!this.filterFileds(this.props.formData.companyName)) {
      desc += "Belge Sahibi / Ticari Ünvan,  <br/>";
    }


    if (!this.filterFileds(this.props.formData.email)) {
      desc += "Taşıt E-Posta Adresi,  <br/>";
    }
    // eslint-disable-next-line
    const mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!mail.test(this.props.formData.email)) {
      desc += "Geçersiz E-posta adresi,  <br/>";
    }
    const phoneControl = /........../;
    if (!phoneControl.test(this.props.formData.phoneNumber)) {
      desc += "Taşıt Telefon Numarası,  <br/>";
    }


    if (!this.filterFileds(this.props.formData.vehicleDocumentTypeId)) {
      desc += "Aracın Sahip Olduğu Yetki Belgesi Sınıfı,  <br/>";
    }


    if (!this.filterFileds(this.props.formData.vehicleTypeId)) {
      desc += "Araç Cinsi,  <br/>";
    }

    if (!this.filterFileds(this.props.formData.cityId)) {
      desc += "İl,  <br/>";
    }

    if (!this.filterFileds(this.props.formData.districtId)) {
      desc += "İlçe,  <br/>";
    }

    if (!this.filterFileds(this.props.formData.address)) {
      desc += "Tesis Adresi,  <br/>";
    }


    if (!this.filterFileds(this.props.formData.seatCount)) {
      desc += "Koltuk Sayısı,  <br/>";
    }


    if (!this.props.formData.vkNo || this.props.formData.vkNo.length < 10) {
      desc += "Vergi numarası 10 hane ve üzeri olmalıdır. <br/>";
    }
    if (this.props.formData.certificateStatusTypeId === 2) {
      if (!this.filterFileds(this.props.formData.confirmationDate)) {
        desc += "Sertifika Tarihi,  <br/>";
      }
      if (!this.filterFileds(this.props.formData.CertificateNo)) {
        desc += "Sertifika No,  <br/>";
      }
    }

    if (
      !this.props.formData.attachmentList ||
      this.props.formData.attachmentList.length <= 2
    ) {
      desc +=
        "Ruhsat - Taşıt Kartı - Yetki Belgesi (3 dosyayı da) Yüklemeniz Gerekmektedir,  <br/>";
    }


    const request = {
      title: "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
      desc: desc,
    };
    if (desc !== "") {
      castThunkAction(this.props.showErrorDialog(request)).then(
        (response: any) => {}
      );
    }



    const { formData, setFormErrors } = this.props;
    const validationSchema = _yup.object({
      webSite: _yup
        .string()
        .matches(
          // eslint-disable-next-line
          /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "Geçersiz Web Sitesi"
        )
        .notRequired()
        .nullable(),
      companyName: _yup.string().required("Bu alan zorunludur!"),
      vkNo: _yup.string().required("Bu alan zorunludur!"),
      documentNo: this.props.formData.certificateTypeId === 4
        ? _yup.string().required("Bu alan zorunludur!")
        : _yup.string().nullable(),

      CertificateNo:
        this.props.formData.certificateStatusTypeId === 2
          ? _yup.string().required("Bu alan zorunludur!")
          : _yup.string().nullable(),
      email: _yup
        .string()
        .matches(
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Geçersiz E-posta Adresi"
        )
        .required('Bu alan zorunludur!')
        .nullable(),
      vehiclePlaque: _yup.string().required("Bu alan zorunludur!"),
      phoneNumber: _yup.string().required("Bu alan zorunludur!"),
      vehicleDocumentTypeId: _yup.string().required("Bu alan zorunludur!"),
      vehicleTypeId: _yup.string().required("Bu alan zorunludur!"),
      seatCount: _yup.string().required("Bu alan zorunludur!"),
      certificateTypeId: _yup.string().required("Bu alan zorunludur!"),
      cityId: _yup.string().required("Bu alan zorunludur!"),
      districtId: _yup.string().required("Bu alan zorunludur!"),
      address: _yup.string().required("Bu alan zorunludur!"),
    });

    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});

        if (desc === "") {
          if (
            this.props.formData.attachmentList &&
            this.props.formData.attachmentList.length > 0
          ) {
            this.onCreate(isDraft);
          } else {
            castThunkAction(
              this.props.showErrorDialog({
                title:
                  "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
                desc: "İşletme belgesi yükleyiniz",
              })
            ).then((response: any) => { });
          }
        }
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });

  }
  
  CityChange(item: any, districtId?: any) {
    const request2: CountyGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "cityId",
          value: item,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
      selectItemList: ["DistrictId", "DistrictName"],
      orderField: "DistrictName",
    };
    castThunkAction(this.props.getAllC(request2)).then((response: any) => {
      this.props.setFormData("districtId", districtId);
    });
  }


  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Seçiniz" });
    }

    return newArray;
  }

  handleMapClick = (ref: any, map: any, ev: any) => {
    const location = ev.latLng;

    map.panTo(location);
  };
  renderForm() {
    const { formData, formErrors, setFormData } = this.props;
    return (
      <div className="form-groups row">
        <div className="form-group col-md-4" style={{ paddingBottom: 0 }}>
          <div className="form-wrapper">
            <div className="item row">
              <TextField
                placeholder="Taşıt Plaka No"
                value={formData.vehiclePlaque || ""}
                label="Taşıt Plaka No"
                type="text"
                className="custom-textfield border"
                errorMessage={formErrors.vehiclePlaque}
                onChange={(event: any) =>
                  setFormData("vehiclePlaque", event.target.value.replace(/\s/g, ''))
                }
              />
            </div>
            <div className="item row">
              <Dropdown
                id="certificateTypeIdDesc"
                onChanged={(event: any) => (
                  setFormData("certificateTypeId", event.key)
                )}
                errorMessage={formErrors.certificateTypeId}
                label="Taşıt Belge Tipi"
                options={(this.props.formData.GroupListTY?.dataList
                  ? this.returnOptions(
                      this.props.formData.GroupListTY?.dataList,
                      "CertificateTypeId",
                      "Title"
                    )
                  : ([] as any)
                ).map((item: any, key: any) => ({
                  key: item.CertificateTypeId,
                  text: item.Title,
                  keys: key,
                }))}
                selectedKey={formData.certificateTypeId || 0}
                className="custom-dropdown"
              />
            </div>

            {this.props.formData.certificateTypeId === 4 ? (
              <div className="item row">
                <TextField
                  id="documentNoDesc"
                  placeholder="Belge No"
                  value={formData.documentNo || ""}
                  label="Belge No "
                  className="custom-textfield border"
                  errorMessage={formErrors.documentNo}
                  onChange={(event: any) =>
                    setFormData("documentNo", event.target.value)
                  }
                />
              </div>
            ) : (
              ""
            )}
            <div className="item row">
              <TextField
                placeholder="Taşıt Vergi Numarası"
                value={formData.vkNo || ""}
                label="Taşıt Vergi Numarası"
                type="number"
                className="custom-textfield border"
                errorMessage={formErrors.vkNo}
                onChange={(event: any) =>
                  setFormData("vkNo", event.target.value)
                }
              />
            </div>

          </div>
        </div>
        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="item row">
              <TextField
                id="companyName"
                // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                placeholder="Belge Sahibi / Ticari Ünvan"
                value={formData.companyName || ""}
                label="Belge Sahibi / Ticari Ünvan"
                className="custom-textfield border"
                errorMessage={formErrors.companyName}
                onChange={(event: any) =>
                  setFormData("companyName", event.target.value)
                }
              />
            </div>
            <div className="item row">
              <Dropdown
                onChanged={(event: any) =>
                  setFormData("vehicleDocumentTypeId", event.key)
                }
                label="Aracın Sahip Olduğu Yetki Belgesi Sınıfı"
                options={(
                  (this.props.formData.GroupListVD && this.props.formData.GroupListVD?.dataList) ? this.returnOptions(this.props.formData.GroupListVD?.dataList, 'VehicleDocumentTypeId', 'VehicleDocumentTypeName') : ([] as any)
                ).map((item: any) => ({
                  key: item.VehicleDocumentTypeId,
                  text: item.VehicleDocumentTypeName,
                }))}
                selectedKey={formData.vehicleDocumentTypeId || 0}
                className="custom-dropdown"
                errorMessage={formErrors.vehicleDocumentTypeId}
              />
            </div>
            <div className="item row">
              <Dropdown
                onChanged={(event: any) =>
                  setFormData("vehicleTypeId", event.key)
                }
                label="Araç Cinsi"
                options={(
                  this.props.formData.GroupListVT && this.props.formData.GroupListVT?.dataList ? this.returnOptions(this.props.formData.GroupListVT?.dataList, 'VehicleTypeId', 'VehicleTypeName') : ([] as any)
                ).map((item: any) => ({
                  key: item.VehicleTypeId,
                  text: item.VehicleTypeName,
                }))}
                selectedKey={formData.vehicleTypeId || 0}

                className="custom-dropdown"
                errorMessage={formErrors.vehicleTypeId}
              />
            </div>
            <div className="item row">
               <Dropdown
                  onChanged={(event: any) => (
                  this.CityChange(event.key), setFormData("cityId", event.key)
                )}
                label="İl"
                options={(
                  this.props.formData.GroupList?.dataList || ([] as any)
                ).map((item: any) => ({
                  key: item.CityId,
                  text: item.CityName,
                }))}
                className="custom-dropdown"
                selectedKey={this.props.formData.cityId}
                errorMessage={formErrors.cityId}
               />
            </div>

            <div className="item row">
              <Dropdown
                onChanged={(event: any) => setFormData("districtId", event.key)}
                label="İlçe"
                options={(formData.GroupListC?.dataList || ([] as any)).map(
                  (item: any) => ({
                    key: item.DistrictId,
                    text: item.DistrictName,
                  })
                )}
                className="custom-dropdown"
                selectedKey={this.props.formData.districtId}
                errorMessage={formErrors.districtId}
              />
            </div>
            <div className="item row">
              <TextField
                placeholder="Tesis Adresi"
                value={formData.address || ""}
                label="Tesis Adresi"
                className="custom-textfield border"
                errorMessage={formErrors.address}
                onChange={(event: any) =>
                  setFormData("address", event.target.value)
                }
              />
            </div>
          </div>
        </div>
        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="title">
              Ruhsat - Taşıt Kartı - Yetki Belgesi (3 belgeyi yüklemeden kayıt
              işlemi yapamazsınız)
            </div>
            <FileUpload
              onProcessStartFile={() => {
                this.setState({
                  isFileUploading: true,
                });
              }}
              onProcessEndFile={(queue: boolean) => {
                if (!queue) {
                  this.setState({
                    isFileUploading: false,
                  });
                }
              }}
              onTransferedFile={(file: any, promise?: boolean) => {
                this.props.setFormData("attachmentList", [
                  ...(this.props.formData?.attachmentList || []),
                  file.attachmentIdList[0],
                ]);
              }}
              requestKey="files"
              token={token}
              endpointUrl={apiConfig.prefixes.attachment + "UploadFile"}
            />
            {this.props.formData.attachmentList &&
              this.props.formData.attachmentList.length > 0 && (
                <React.Fragment>
                  <div className="title">Yüklenen Belgeler</div>
                  {JSON.stringify(this.props.formData.initialAttachmentList) !==
                    JSON.stringify(this.props.formData.attachmentList) && (
                    <div className="file-save-warning">
                      Düzenlemeleri ve yüklenen belgeleri kaydetmek için, sağ
                      üst kısımdan "Kaydet veya Yayımla"ya basın.
                    </div>
                  )}
                  <div className="uploaded-files">
                    {(this.props.formData?.attachmentList || []).map(
                      (
                        item: {
                          attachmentId: number;
                          attachmentPath: string;
                          fileName: string;
                          fileSize: number;
                        },
                        index: number
                      ) => (
                        <div key={index} className="uploaded-file">
                          <div className="name row">
                            <span className="text col no-padding">
                              {item.fileName}
                              <strong className="size m-left-auto">
                                {formatSize(item.fileSize)}
                              </strong>
                            </span>
                            <div className="buttons m-left-auto row">
                              <button
                                onClick={() =>
                                  window.open(
                                    qrUrl + item.attachmentPath,
                                    "_blank"
                                  )
                                }
                                className="view"
                              >
                                <i className="icon-explore"></i> Görüntüle
                              </button>
                              <button
                                onClick={() => {
                                  let files = _.cloneDeep(
                                    this.props.formData?.attachmentList
                                  );
                                  let findIndex = (files || []).findIndex(
                                    (x: any) =>
                                      x.attachmentId === item.attachmentId
                                  );
                                  if (findIndex !== -1) {
                                    files.splice(findIndex, 1);
                                  }
                                  this.props.setFormData(
                                    "attachmentList",
                                    files
                                  );
                                }}
                                className="remove"
                              >
                                <i className="icon-close"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </React.Fragment>
              )}
          </div>
        </div>

        <div className="form-group col-md-4">
          <div className="form-wrapper">


            <div className="item row">
              <TextField
                placeholder="Taşıt E-posta Adresi"
                value={formData.email || ""}
                label="Taşıt E-posta Adresi"
                className="custom-textfield border"
                errorMessage={formErrors.email}
                onChange={(event: any) =>
                  setFormData("email", event.target.value)
                }
              />
            </div>

            <div className="item row">
              <Label>Taşıt Telefon Numarası</Label>
              <PhoneInput
                onlyCountries={this.state.onlyCountry}
                country={"tr"}
                placeholder="Taşıt Telefon Numarası"
                value={formData.phoneNumber || ""}
                onChange={(phone) => setFormData("phoneNumber", phone)}
                disableDropdown={true}
                disableCountryCode={true}
                masks={{ tr: "(...) ...-..-.." }}
                inputClass={"customizephone"}
                defaultErrorMessage={formErrors.phoneNumber}
              />

              {formErrors.phoneNumber && (
                <p className="ms-TextField-errorMessage errorMessage-63">
                  <span>{formErrors.phoneNumber}</span>
                </p>
              )}
            </div>
          </div>
        </div>



        <div className="form-group col-md-4">
          <div className="form-wrapper">

            <div className="item row">
              <TextField
                placeholder="Taşıtın Web Sitesi"
                value={formData.webSite || ""}
                label="Taşıtın Web Sitesi"
                className="custom-textfield border"
                errorMessage={formErrors.webSite}
                onChange={(event: any) =>
                  setFormData(
                    "webSite",
                    event.target.value === "" ? null : event.target.value
                  )
                }
              />
            </div>





            <div className="item row">
              <TextField
                placeholder="Koltuk Sayısı"
                value={formData.seatCount || ""}
                label="Koltuk Sayısı"
                className="custom-textfield border"
                errorMessage={formErrors.seatCount}
                onChange={(event: any) =>
                  setFormData(
                    "seatCount",
                    isNumberValue(event.target.value === "" ? null : event.target.value)
                  )
                }
              />
            </div>

            {usertype && usertype !== "2" ? (
              <div className="item row">
                <Dropdown
                  options={[
                    { key: 1, text: "Onaylanmış" },
                    { key: 0, text: "Onaylanmamış" },
                  ]}
                  label="Taşıt Onay Durumu"
                  placeholder="Seçiniz"
                  selectedKey={this.props.formData?.isApproved || 0}
                  onChanged={(event: any) =>
                    setFormData("isApproved", event.key)
                  }
                  calloutProps={{
                    className: "custom-dropdown-callout",
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  }
  getDate(date: any): any {
    if (date !== 0 && date !== undefined) {
      return toDate(date, "");
    }
  }
  showDialog(req: any): any {
    this.props.showErrorDialog(req);
  }


  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Yeni Taşıt Ekle"
          titleRenderer={() => (
            <span className="edit-title">
              Yönet:
              <strong>Yeni Taşıt</strong>
            </span>
          )}
          showBack
          backUrl={usertype === "2" ? "/TransportCertification" : "/Transport"}
          history={this.props.history}
          subTitle="Yeni bir taşıt eklemek için aşağıdaki bilgileri doldurunuz."
          rightType="create"
          right={
            <FormButtons
              isAbsolute
              onSubmit={() => this.onSubmit()}
              customRenderButtons={() => (
                <React.Fragment>
                  <PrimaryButton
                    onClick={() => this.onSubmit()}
                    disabled={
                      this.state.messageType === MessageBarType.success ||
                      this.state.isFileUploading
                        ? this.state.isFileUploading
                          ? true
                          : false
                        : false
                    }
                    styles={{
                      rootDisabled: {
                        background: "#626262",
                        borderColor: "#777373 !important",
                      },
                    }}
                    iconProps={{ iconName: "CheckMark" }}
                    text="Yayımla"
                  />
                  {this.state.isFileUploading && (
                    <div className="tooltip-warning">
                      Bazı dosyalar yükleniyor, lütfen dosyalar yüklenene kadar
                      bekleyiniz.
                    </div>
                  )}
                </React.Fragment>
              )}
            />
          }
        />
        {/* {this.renderMessageBar()} */}

        {this.renderForm()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateVehicle);
