import { StatusEnums } from "../BaseEnum";

export interface CongressSubCategorySaveDataModel {
  mainCategoryId: number;
  title: string;
  title_En: string;
  status: StatusEnums;
  isMinistry: boolean;
}
export interface CongressSubCategorySaveRequestModel {
  data: CongressSubCategorySaveDataModel;
}

export interface CongressSubCategoryUpdateDataModel {
  CongressSubCategoryId: number;
  mainCategoryId: number;
  title_En: string;
  title: string;
  status: StatusEnums;
  isMinistry: boolean;
}

export interface CongressSubCategoryUpdateRequestModel {
  data: CongressSubCategoryUpdateDataModel;
}

export interface CongressSubCategoryChangeStatusRequestModel {
  status: StatusEnums;
  id: number;
}

export enum ConditionOperatorEnum {
  Eq = 0,
  NotEq = 1,
  Like = 2,
  Gt = 3,
  Lt = 4,
  Ge = 5,
  Le = 6,
}

export interface criteriaItemListData {
  propertyName: string;
  value: any;
  isOr: boolean;
  conditionOperator: ConditionOperatorEnum;
}

export interface CongressSubCategoryGetAllRequestModel {
  criteriaItemList?: criteriaItemListData[];
  selectItemList?: string[];
  pageSize?: number;
  pageIndex?: number;
  orderField?: string;
  isDescending?: boolean;
  status?: StatusEnums;
}
export interface CongressSubCategoryGetByIdRequestModel {
  id: number;
}

export interface CongressSubCategoryChangeStatusRequestModel {
  id: number;
  status: StatusEnums;
}
