import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { panels } from "src/utils/constants";
import { Link } from "react-router-dom";
import { History } from "history";
import BasicTable from "../BasicTable";
import {
  TextField,
  Dropdown,
  PrimaryButton,
  Label,
  ComboBox,
} from "office-ui-fabric-react";
import {
  getAll,
  changeStatus,
  getAllPage,
  getAllForPager,
  GetCongressCertificationAndRestaurantCount,
  getAllCongressCertificationANDRestaurant,
  excelDownload,
} from "src/store/thunkCongressCertification";
import {
  CongressCertificationGetAllRequestModel,
  CongressCertificationChangeStatusRequestModel,
} from "src/services/CongressCertification/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";
import { setFormData } from "src/store/form/actions";
import { castThunkAction } from "src/helpers/casting";
import {
  toggleConfirmDialog,
  toggleConfirmFilterRemoveDialog,
} from "src/store/system/actions";
import { listCount } from "src/config/otherConfig";
import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import { getAllCR } from "src/store/thunkCertificateStatusType";
import { getCompanyUserList } from "src/store/thunksSystemUser";

import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";

import { getAllLSU } from "src/store/thunkCongressSubCategory";
import { CertificateStatusTypeGetAllRequestModel } from "src/services/CertificateStatusType/RequestModel";
import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";
import cookie from "react-cookies";
// @ts-ignore
import ReactExport from "react-export-excel";
import { CongressSubCategoryGetAllRequestModel } from "src/services/CongressSubCategory/RequestModel";

import { updateNotification } from "src/store/thunkNotification";
import { NotificationUpdateRequestModel } from "src/services/Notification/RequestModel";
import {
  cityReturnOptions,
  filterModal,
  isNumberValue,
  RECORD_STATUS_ALL_ENUM,
} from "src/helpers/functions";
import ValidityDateFilter from "../ValidityDateFilter";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        getAll,
        changeStatus,
        toggleConfirmDialog,
        getAllM,
        getAllC,
        getAllLSU,
        getAllCR,
        getAllPage,
        toggleConfirmFilterRemoveDialog,
        getCompanyUserList,
        getAllForPager,
        GetCongressCertificationAndRestaurantCount,
        updateNotification,
        getAllCongressCertificationANDRestaurant,
        setFormData,
        excelDownload,
      },
      dispatch
    ),
  };
}

type CongressCertificationProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  excel: boolean;
  pagerCurrent: number;
  pagerTotal: number;
  download: boolean;
  showfilter:boolean;
}
let usertype: any = 0;
const initialState = {
  filterData: {
    searchKey: "",
    districtId: 0,
    status: 1,
    cityId: 0,
    CongressSubCategoryId: 0,
    isPublishWeb: null,
    certificateStatusTypeId: 0,
    companyUserId: 0,
    correctionType: null,
    isPublishQr: 0,
    orderField: "UpdatedSystemUserDatetime",
    isDescending: true,
    congressId: 0,
    certificateStatus: null,
    isHistoryPublishWeb: "",
    isHistoryPublishQr: "",
    extendedClosedStartDate: 0,
    extendedClosedEndDate: 0,
    showfilter:true
  },
  pagerCurrent: 0,
  pagerTotal: 0,
  excel: false,
  download: false,
};
class CongressCertification extends Component<
  CongressCertificationProps,
  OwnState
> {
  state: any = initialState;

  componentDidMount() {
    filterModal(this, "CongressCertificationListState").finally(() =>
      this.getData(this.state.pagerCurrent)
    );
    usertype = cookie.load("systemUserInfo");
  }

  getData(pagerSelected: number) {
    const request: CongressCertificationGetAllRequestModel = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...this.state.filterData,
    };

    this.setState({ excel: false });
    request["isPublishQr"] =
      request["isPublishQr"] === "1"
        ? true
        : request["isPublishQr"] === "2"
        ? false
        : null;

    castThunkAction(this.props.getAll(request)).then((response: any) => {
      // castThunkAction(
      //   this.props.GetCongressCertificationAndRestaurantCount(request)
      // ).then((response: any) => {});

      const request2: CityGetAllRequestModel = {
        selectItemList: ["CityId", "CityName"],
        orderField: "CityName",
      };

      this.props.getAllM(request2);

      const request3: CongressSubCategoryGetAllRequestModel = {
        criteriaItemList: [
          {
            propertyName: "Status",
            value: 1,
            isOr: false,
            conditionOperator: 0,
          },
        ],
        selectItemList: ["CongressSubCategoryId", "CongressSubCategoryName"],
      };
      this.props.getAllLSU(request3);

      const request4: CertificateStatusTypeGetAllRequestModel = {
        criteriaItemList: [
          {
            propertyName: "Status",
            value: 1,
            isOr: false,
            conditionOperator: 0,
          },
        ],
        selectItemList: ["CertificateStatusTypeId", "Title"],
      };
      this.props.getAllCR(request4);

      const request6: SystemUserGetAllModel = {
        systemUserType: 2,
      };
      this.props.getCompanyUserList(request6);

      this.state.filterData.cityId !== 0 &&
        this.state.filterData.cityId !== null &&
        this.CityChange(this.state.filterData.cityId);
    });
  }

  getDataPager(pagerSelected: number) {
    const request: CongressCertificationGetAllRequestModel = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...this.state.filterData,
    };

    this.setState({ excel: false });
    request["isPublishQr"] =
      request["isPublishQr"] === "1"
        ? true
        : request["isPublishQr"] === "2"
        ? false
        : null;

    castThunkAction(this.props.getAllForPager(request)).then(
      (response: any) => {}
    );
  }

  onDelete(CongressCertificationId: number) {
    const request: CongressCertificationChangeStatusRequestModel = {
      id: CongressCertificationId,
      status: StatusEnums.Deleted,
    };
    castThunkAction(this.props.changeStatus(request, true)).then(
      (response: any) => {
        this.getData(this.state.pagerCurrent);
      }
    );
  }

  renderDashboardItems() {
    return (
      <div className="dashboard-items row">
        {panels.map(
          (item, index) =>
            item.children && (
              <React.Fragment>
                <div className="title">
                  {item.text}
                  <div className="title-text">{item.subText}</div>
                </div>
                <div className="childrens row">
                  {item.children.map((children, childrenIndex) => (
                    <div key={index} className={`dashboard-item col-md-3`}>
                      <Link
                        className="row"
                        to={children.url ? children.url : "/"}
                      >
                        <div className="icon">
                          <i className={children.icon}></i>
                        </div>
                        <div className="title">
                          {children.text}
                          <div className="sub-text">{children.subText}</div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )
        )}
      </div>
    );
  }
  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Tümünü Seç" });
    }
    return newArray;
  }

  cityOnChange(event: any, option: any, value: any) {
    if (value !== undefined) {
      this.CityChange(option.key);
      this.setState({
        filterData: {
          ...this.state.filterData,
          cityId: option.key,
          districtId: 0,
        },
      });
    } else {
      this.setState({
        filterData: {
          ...this.state.filterData,
          cityId: 0,
          districtId: 0,
        },
      });
      this.props.setFormData("GroupListC", []);
    }
  }

  CityChange(item: any) {
    this.setState(
      {
        filterData: {
          ...this.state.filterData,
          cityId: item,
        },
      },
      () => {
        const request2: CountyGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "cityId",
              value: item,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
            {
              propertyName: "Status",
              value: 1,
              isOr: false,
              conditionOperator: 0,
            },
          ],
          selectItemList: ["DistrictId", "DistrictName"],
          orderField: "DistrictName",
        };
        this.props.getAllC(request2);
      }
    );
  }

  renderFilter() {
    return (
      <div className="table-filter row">
        <TextField
          className="custom-textfield col-md-3"
          label="Tesis Adı"
          value={this.state.filterData?.searchKey || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                searchKey: event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        <TextField
          className="custom-textfield col-md-1"
          label="Tesis ID"
          value={this.state.filterData?.congressId || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                congressId: isNumberValue(event.target.value),
              },
            });
          }}
          type="text"
        />

        <TextField
          className="custom-textfield col-md-2"
          label="Belge Sahibi / Ticari Ünvan"
          value={this.state.filterData?.companyName || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                companyName:
                  event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        <ComboBox
          selectedKey={this.state.filterData.cityId || ""}
          label="İl"
          allowFreeform={true}
          autoComplete="on"
          multiSelect={false}
          className="custom-dropdown col-md-2"
          onChange={(event: any, option: any, value: any) =>
            this.cityOnChange(event, option, value)
          }
          options={(this.props.formData.GroupList?.dataList
            ? cityReturnOptions(
                this.props.formData.GroupList?.dataList,
                "CityId",
                "CityName"
              )
            : ([] as any)
          ).map((item: any) => ({ key: item.CityId, text: item.CityName }))}
        />
        <Dropdown
          defaultSelectedKey={this.state.filterData?.districtId || ""}
          onChanged={(event: any) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                districtId: event.key,
              },
            })
          }
          label="İlçe"
          options={(this.props.formData.GroupListC?.dataList
            ? this.returnOptions(
                this.props.formData.GroupListC?.dataList,
                "DistrictId",
                "DistrictName"
              )
            : ([] as any)
          ).map((item: any) => ({
            key: item.DistrictId,
            text: item.DistrictName,
          }))}
          className="custom-dropdown col-md-2"
        />

        <Dropdown
          defaultSelectedKey={
            this.state.filterData?.CongressSubCategoryId || ""
          }
          onChanged={(event: any) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                CongressSubCategoryId: event.key,
              },
            })
          }
          label="Tesis Kategorisi"
          options={(this.props.formData.GroupListSU?.dataList
            ? this.returnOptions(
                this.props.formData.GroupListSU?.dataList,
                "CongressSubCategoryId",
                "CongressSubCategoryName"
              )
            : ([] as any)
          ).map((item: any) => ({
            key: item.CongressSubCategoryId,
            text: item.CongressSubCategoryName,
          }))}
          className="custom-dropdown col-md-2"
        />

        <Dropdown
          options={(this.props.formData.GroupListCR?.dataList
            ? this.returnOptions(
                this.props.formData.GroupListCR?.dataList,
                "CertificateStatusTypeId",
                "Title"
              )
            : ([] as any)
          ).map((item: any) => ({
            key: item.CertificateStatusTypeId,
            text: item.Title,
          }))}
          className="custom-dropdown col-md-2"
          label="Sertifika Durumu"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData.certificateStatusTypeId}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                certificateStatusTypeId: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />

        {usertype && usertype !== "2" ? (
          <ComboBox
            options={(this.props.formData.CompanyUserList?.dataList
              ? this.returnOptions(
                  this.props.formData.CompanyUserList?.dataList,
                  "systemUserId",
                  "title"
                )
              : ([] as any)
            ).map((item: any) => ({
              key: item.systemUserId,
              text: item.title,
            }))}
            allowFreeform={true}
            autoComplete="on"
            className="custom-dropdown col-md-2"
            label="Denetleyici Firma"
            placeholder="Seçiniz"
            selectedKey={this.state.filterData.companyUserId}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  companyUserId: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />
        ) : (
          ""
        )}

        <Dropdown
          options={[
            { key: "", text: "Seçiniz" },
            { key: 1, text: "Revize İstendi" },
            { key: 2, text: "Revize Yapıldı" },
            { key: 3, text: "Revize Onaylandı" },
            { key: 4, text: "Revize Kaydı Olanlar" },
            { key: 5, text: "Revizeye Girmemişler" },
          ]}
          className="custom-dropdown col-md-2"
          label="Revize Durumu"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.correctionType || null}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                correctionType: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: 0, text: "Seçiniz" },
            { key: 1, text: "Sertifika Uzatmalı Kapalı" },
            { key: 2, text: "Firma Sertifika Sürecinden Ayrıldı" },
            { key: 3, text: "Kare Kodsuz Sertifikalı" },
          ]}
          className="custom-dropdown col-md-2"
          label="Sertifika Statüsü"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.certificateStatus || null}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                certificateStatus: option.key === 0 ? null : option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />

        <Dropdown
          options={[
            { key: "BrandName", text: "Tesis Adı" },
            { key: "CreatedSystemUserDatetime", text: "Oluşturma Tarihi" },
            { key: "UpdatedSystemUserDatetime", text: "Güncelleme Tarihi" },
          ]}
          className="custom-dropdown col-md-2"
          label="Sıralama"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.orderField || null}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                orderField: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={(this.state.filterData.orderField === "BrandName"
            ? [
                { key: false, text: "Baştan Sona" },
                { key: true, text: "Sondan Başa" },
              ]
            : [
                { key: false, text: "Eskiden Yeniye" },
                { key: true, text: "Yeniden Eskiye" },
              ]
          ).map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-2"
          label="Sıralama Türü"
          selectedKey={this.state.filterData?.isDescending}
          placeholder="Seçiniz"
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isDescending: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={
            usertype && usertype !== "2"
              ? RECORD_STATUS_ALL_ENUM
              : [{ key: StatusEnums.Published, text: "Yayımda" }]
          }
          className="custom-dropdown col-md-2"
          label="Kayıt Durumu"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.status || 1}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                status: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: "", text: "Seçiniz" },
            { key: "false", text: "Hayır" },
            { key: "true", text: "Evet" },
          ].map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-2"
          label="Son Kayıtta Webde Yayımlanma"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData.isPublishWeb}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isPublishWeb: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: "", text: "Seçiniz" },
            { key: "false", text: "Webde Gösterilmeyenler" },
            { key: "true", text: "Webde göster olanlar" },
          ].map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-2"
          label="Eski Kayıtlar Dahil Webde Göster"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.isHistoryPublishWeb || ""}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isHistoryPublishWeb: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: "0", text: "Seçiniz" },
            { key: "1", text: "Evet" },
            { key: "2", text: "Hayır" },
          ]}
          className="custom-dropdown col-md-2"
          label="Son Kayıtta QR Yayımlanma"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.isPublishQr || null}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isPublishQr: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: "", text: "Seçiniz" },
            { key: "false", text: "QR Yayımda Olmayanlar" },
            { key: "true", text: "QR Yayımda Olanlar" },
          ].map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-2"
          label="Eski Kayıtlar Dahil QR Yayım"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData.isHistoryPublishQr || ""}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isHistoryPublishQr: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <TextField
          className="custom-textfield col-md-2"
          label="Kayıt No"
          value={this.state.filterData?.sequenceId || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                sequenceId: event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        <TextField
          className="custom-textfield col-md-2"
          label="Sertifika No"
          value={this.state.filterData?.certificateNo || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                certificateNo:
                  event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        <ValidityDateFilter
          onChangeStartDate={(value: number) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                extendedClosedStartDate: value,
              },
            })
          }
          onChangeEndDate={(value: number) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                extendedClosedEndDate: value,
              },
            })
          }
          extendedClosedStartDate={
            this.state.filterData.extendedClosedStartDate
          }
          extendedClosedEndDate={this.state.filterData.extendedClosedEndDate}
        />
        <PrimaryButton
          text="Arama Yap"
          className="custom-button"
          onClick={() => {
            cookie.save(
              "CongressCertificationListState",
              JSON.stringify(this.state),
              {
                path: "/",
              }
            );
            this.getDataPager(0);
          }}
          style={{ marginLeft: "12px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
        {this.rederexcel()}

        <PrimaryButton
          text="Tüm Filtreleri Temizle"
          className="custom-button"
          iconProps={{ iconName: "trash" }}
          onClick={() =>
            this.setState(
              {
                ...this.state,
                filterData: initialState.filterData,
              },
              () => {
                cookie.save(
                  "CongressCertificationListState",
                  JSON.stringify(this.state),
                  {
                    path: "/",
                  }
                );
                this.CityChange(0);
                this.getDataPager(0);
              }
            )
          }
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
              background: "#FF4343",
              borderColor: "#FF4350",
            },
          }}
        />
        {/* {usertype && usertype !== "2" ? this.rederexcel() : ""} */}
      </div>
    );
  }

  goExaminer(endId: any): any {
    window.open("/CongressCertification/editendId/" + endId);
  }

  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "sequenceId",
            text: "Kayıt No",
            col: "col-md-1-2",
          },
          {
            columnName: "cityDistrictName",
            text: "İl / İlçe",
            col: "col-md-1-2",
          },
          {
            columnName: "companyName",
            text: "Belge Sahibi / Ticari Ünvan",
            col: "col-md-1-2",
          },

          {
            columnName: "brandName",
            text: "Tesis Adı",
            col: "col-md-1-2",
          },
          {
            columnName: "certificateNo",
            text: "Tesis Kategorisi / Sınıfı",
            col: "col-md-1-2",
            onRender: (item: {
              congressSubCategoryName: any;
              congressFacilityTypeName: any;
            }) => (
              <React.Fragment>
                {item.congressSubCategoryName +
                  (item.congressFacilityTypeName !== ""
                    ? " / " + item.congressFacilityTypeName
                    : "")}
              </React.Fragment>
            ),
          },
          {
            columnName: "companyUserTitle",
            text: "Denetleyici Firma",
            col: "col-md-1-2",
          },
          {
            columnName: "webSite",
            text: "Tesisin Web Sitesi",
            col: "col-md-1-2",
            className: "operations",
            onRender: (item: { webSite: any }) => (
              <React.Fragment>
                {item.webSite !== "" &&
                item.webSite !== "null" &&
                item.webSite !== null ? (
                  <a
                    href={
                      item.webSite?.includes("http")
                        ? item.webSite
                        : "http://" + item.webSite
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon-edit-outline"></i> Link
                  </a>
                ) : (
                  ""
                )}
              </React.Fragment>
            ),
          },
          {
            columnName: "certificateStatusTypeName",
            text: "Durum",
            col: "col-md-1-2",
          },

          {
            columnName: "certificateNo",
            text: "Sertifika No",
            col: "col-md-1-2",
            onRender: (item: {
              certificateNo: any;
              certificateStatusTypeId: any;
            }) => (
              <React.Fragment>
                {item.certificateStatusTypeId === 2 ? item.certificateNo : ""}
              </React.Fragment>
            ),
          },

          {
            columnName: "firstConfirmationDateString",
            text: "İlk Sertifika Düzenleme Tarihi",
            col: "col-md-1-2",
          },
          {
            columnName: "confirmationDateString",
            text: "Sertifika Düzenleme Tarihi",
            col: "col-md-1-2",
            onRender: (item: {
              confirmationDateString: any;
              certificateStatusTypeId: any;
            }) => (
              <React.Fragment>
                {item.certificateStatusTypeId === 2
                  ? item.confirmationDateString
                  : ""}
              </React.Fragment>
            ),
          },
          {
            columnName: "extendedClosedDateString",
            text: "Sertifika Geçerlilik Tarihi",
            col: "col-md-1-2",
          },
          {
            columnName: "congressCertificateId",
            text: "İşlem",
            col: "col-md-1-2",
            className: "operations",
            onRender: (item: {
              congressCertificateId: any;
              Status: StatusEnums;
              endId: any;
              currentVersionStatus: any;
            }) => (
              <React.Fragment>
                {usertype !== "2" && item.currentVersionStatus === false ? (
                  <>
                    <button onClick={(x) => this.goExaminer(item.endId)}>
                      <i className="icon-edit-outline"></i> İncele
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={(x) => this.goEdit(item.congressCertificateId)}
                    >
                      <i className="icon-edit-outline"></i> Düzenle
                    </button>

                    {usertype !== "5" &&
                      item.Status !== StatusEnums.Deleted &&
                      usertype !== "2" && (
                        <button
                          onClick={() =>
                            this.props.toggleConfirmDialog(
                              true,
                              "Silme işlemini gerçekleştirmek istiyor musunuz?",
                              () => this.onDelete(item.congressCertificateId)
                            )
                          }
                        >
                          <i className="icon-trash-outline"></i> Sil
                        </button>
                      )}
                  </>
                )}
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.dataList || []}
      />
    );
  }
  goEdit(CongressCertificationCertificateId: any): any {
    const request: NotificationUpdateRequestModel = {
      CategoryType: "1",
      RelatedId: CongressCertificationCertificateId,
      isRead: true,
    };
    castThunkAction(this.props.updateNotification(request)).then(
      (response: any) => {
        window.open(
          "/CongressCertification/edit/" + CongressCertificationCertificateId
        );
      }
    );
  }
  downloadexcel() {
    this.setState({ download: true });
    const requestexcel: CongressCertificationGetAllRequestModel = {
      ...this.state.filterData,
    };

    castThunkAction(this.props.excelDownload(requestexcel));
  }

  rederexcel() {
    return (
      <>
        <PrimaryButton
          text="Rapor Oluştur ve İndir"
          className="custom-button"
          onClick={() => this.downloadexcel()}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
      </>
    );
  }

  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.props.formData.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }
  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getDataPager(selected);
  };
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Kongre ve Sanat Tesisi Sertifika Yönetimi"
          subTitle="Yönetilebilecek tüm alanları aşağıdan görüntüleyebilirsiniz."
          backUrl="/"
          showBack
          history={this.props.history}
        />
        {usertype !== "5" && (
          <PrimaryButton
            onClick={() =>
              this.props.history.push("/CongressCertification/create")
            }
            iconProps={{ iconName: "Add" }}
            className="absolute-create"
            text="Kongre ve Sanat Tesisi Sertifika Ekle"
            styles={{
              root: {
                background: "#62a98e",
                borderColor: "#5b9e85",
              },
              rootHovered: {
                background: "#5b9e85",
                borderColor: "#5b9e85",
              },
              rootDisabled: {
                background: "#ececec",
              },
            }}
          />
        )}

        <Label className="totalRecord">
          Kongre ve Sanat Tesisi Sertifika Sayısı:{" "}
          {this.props.formData?.dataCount}
        </Label>
        <Label className="totalRecord">
          Toplam Kayıt Sayısı: {this.props.formData?.totalCount}
        </Label>

        {
         window.innerWidth < 1100 && (
           <PrimaryButton
             text={this.state.showfilter ? "Filtreyi Gizle" : "Filtreyi Göster"}
             className="custom-button"
             onClick={() => (
               this.setState({ showfilter: !this.state.showfilter })
             )}
             styles={{  
               root: {
                 fontSize: "11px",
                 marginTop: "27px",
                 height: "30px",
               },
             }}
           />
         )
       }
       { window.innerWidth < 1100 ? (this.state.showfilter && this.renderFilter()):this.renderFilter()}

        {this.renderList()}
        {this.renderPager()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CongressCertification);
