import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Header from "../../components/Header";
import LeftPanel from "../../components/LeftPanel";
import { CreateLayoverFacilityTypep } from "../../components/LayoverFacilityType";

export default class CreateLayoverFacilityType extends React.Component<RouteComponentProps> {
  render() {
    return (
      <React.Fragment>
        <Header history={this.props.history} />
        <div className="row">
          <LeftPanel history={this.props.history} />
          <CreateLayoverFacilityTypep history={this.props.history} />
        </div>
      </React.Fragment>
    );
  }
}
