/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { bindActionCreators, Dispatch } from "redux";
import { History } from "history";
import PageTitle from "../PageTitle";
import { setFormData } from "src/store/form/actions";
import HotelModal from "../HotelModal";
import CongressModal from "../CongressModal";
import SeaTourismModal from "../SeaTourismModal";
import WinterTourismModal from "../WinterTourismModal";
import ThemedFacilityModal from "../ThemedFacilityModal";
let certificateDate = new Date();
certificateDate.setDate(certificateDate.getDate() + 3);

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
      },
      dispatch
    ),
  };
}

type AdditionalCreateTypeProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

class AdditionalCreateType extends Component<AdditionalCreateTypeProps> {
  render() {
    return (
      <div className="page push-all">
        {this.props.formData.hotelModalShow && (
          <div className="modal-overlay">
            <div className="modal">
              <button
                className="btn-close"
                onClick={(x) => this.props.setFormData("hotelModalShow", false)}
              >
                Kapat
              </button>
              <HotelModal history={this.props.history} />
            </div>
          </div>
        )}
        {this.props.formData.congressModalShow && (
          <div className="modal-overlay">
            <div className="modal">
              <button
                className="btn-close"
                onClick={(x) =>
                  this.props.setFormData("congressModalShow", false)
                }
              >
                Kapat
              </button>
              <CongressModal history={this.props.history} />
            </div>
          </div>
        )}
        {this.props.formData.winterTourismModalShow && (
          <div className="modal-overlay">
            <div className="modal">
              <button
                className="btn-close"
                onClick={(x) =>
                  this.props.setFormData("winterTourismModalShow", false)
                }
              >
                Kapat
              </button>
              <WinterTourismModal history={this.props.history} />
            </div>
          </div>
        )}
        {this.props.formData.seaTourismModalShow && (
          <div className="modal-overlay">
            <div className="modal">
              <button
                className="btn-close"
                onClick={(x) =>
                  this.props.setFormData("seaTourismModalShow", false)
                }
              >
                Kapat
              </button>
              <SeaTourismModal history={this.props.history} />
            </div>
          </div>
        )}
        {this.props.formData.themedFacilityModalShow && (
          <div className="modal-overlay">
            <div className="modal">
              <button
                className="btn-close"
                onClick={(x) =>
                  this.props.setFormData("themedFacilityModalShow", false)
                }
              >
                Kapat
              </button>
              <ThemedFacilityModal history={this.props.history} />
            </div>
          </div>
        )}
        <PageTitle
          title="Ek Denetim Ekle"
          titleRenderer={() => (
            <span className="edit-title">
              Yönet: <strong>Ek Denetim Ekle</strong>
            </span>
          )}
          showBack
          backUrl="/AdditionalControl"
          history={this.props.history}
          subTitle="Tesis tipi seçiniz."
        />
        <div className="dashboard-items row">
          <div className="title">
            Tesisler Tipleri
            <div className="title-text">
              Aşağıdaki tesis tiplerine ek denetim ekleyebilirsiniz.
            </div>
          </div>
          <div className="childrens row">
            <div className="dashboard-item col-md-3">
              <a
                className="row"
                onClick={(x) => (
                  this.props.setFormData("hotelModalShow", true),
                  this.props.setFormData("isForAdditionalAudit", true),
                  this.props.setFormData("Additional", 1)
                )}
                
              >
                <div className="icon">
                  <i className="fas fa-building"></i>
                </div>
                <div className="title">Konaklama Tesisleri</div>
              </a>
            </div>

            <div className="dashboard-item col-md-3">
              <a
                className="row"
                onClick={(x) => (
                  this.props.setFormData("congressModalShow", true),
                  this.props.setFormData("isForAdditionalAudit", true),
                  this.props.setFormData("Additional", 1)
                )}
              >
                <div className="icon">
                  <i className="fas fa-building"></i>
                </div>
                <div className="title">Kongre ve Sanat Tesisleri</div>
              </a>
            </div>

            <div className="dashboard-item col-md-3">
              <a
                className="row"
                onClick={(x) => (
                  this.props.setFormData("seaTourismModalShow", true),
                  this.props.setFormData("isForAdditionalAudit", true),
                  this.props.setFormData("Additional", 1)
                )}
              >
                <div className="icon">
                  <i className="fas fa-building"></i>
                </div>
                <div className="title">Deniz Turizm Tesisleri / Araçları</div>
              </a>
            </div>

            <div className="dashboard-item col-md-3">
              <a
                className="row"
                onClick={(x) => (
                  this.props.setFormData("winterTourismModalShow", true),
                  this.props.setFormData("isForAdditionalAudit", true),
                  this.props.setFormData("Additional", 1)
                )}
              >
                <div className="icon">
                  <i className="fas fa-building"></i>
                </div>
                <div className="title">Kış Turizmi Mekanik Hat Tesisleri</div>
              </a>
            </div>

            <div className="dashboard-item col-md-3">
              <a
                className="row"
                onClick={(x) => (
                  this.props.setFormData("themedFacilityModalShow", true),
                  this.props.setFormData("isForAdditionalAudit", true),
                  this.props.setFormData("Additional", 1)
                )}
              >
                <div className="icon">
                  <i className="fas fa-building"></i>
                </div>
                <div className="title">Temalı Tesisler</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdditionalCreateType);
