import { StatusEnums } from "src/services/BaseEnum";

export enum SystemUserTypeEnum {
  SuperAdmin = 1,
  CompanyUser = 2,
}

export const CategoryTypes: any[] = [
  {
    key: 5,
    text: "Konaklama Sertifika Yönetimi",
    link: "HotelCertification/edit/",
  },
  {
    key: 2,
    text: "Restoran / Kafe Sertifika Yönetimi",
    link: "Restaurant/edit/",
  },
  {
    key: 3,
    text: "Tur ve Transfer Araçları Sertifika Yönetimi",
    link: "TransportCertification/edit/",
  },
  {
    key: 4,
    text: "Havalimanı Sertifika Yönetimi",
    link: "Airport/edit/",
  },
  {
    key: 6,
    text: "Kongre ve Sanat Tesisi",
    link: "CongressCertification/edit/",
  },
  {
    key: 7,
    text: "Deniz Turizm Tesisi",
    link: "SeaTourismCertification/edit/",
  },
  {
    key: 8,
    text: "Temalı Tesis",
    link: "ThemedFacilityCertification/edit/",
  },
  {
    key: 9,
    text: "Kış Turizmi Mekanik Hatlar Tesisi",
    link: "WinterTourismCertification/edit/",
  },
];
export const OperationTypes: any[] = [
  {
    key: 3,
    text: "Ekleme İşlemi",
  },
  {
    key: 4,
    text: "Güncelleme İşlemi",
  },
];

export const certificateStatusEnum: any[] = [
  {
    key: 1,
    list: [1],
    text: "Sertifika Uzatmalı Kapalı",
  },
  {
    key: 2,
    list: [2],
    text: "Otel Sertifika Sürecinden Ayrıldı",
  },
  {
    key: 3,
    list: [3],
    text: "Kare Kodsuz Sertifikalı",
  },
  {
    key: 4,
    list: [1, 3],
    text: "Sertifika Uzatmalı Kapalı ve Kare Kodsuz Sertifikalı",
  },
  {
    key: 5,
    list: [1, 2],
    text: "Sertifika Uzatmalı Kapalı ve Otel Sertifika Sürecinden Ayrıldı",
  },
  {
    key: 6,
    list: [2, 3],
    text: "Otel Sertifika Sürecinden Ayrıldı ve Kare Kodsuz Sertifikalı",
  },
  {
    key: 7,
    list: [1, 2, 3],
    text: " Tüm Seçenekler Seçildi",
  },
];

export const certificateKeys: any[] = [
  { en: "layoverCertificateId", tr: "Konaklama Sertifika" },
  { en: "companyName", tr: "Belge Sahibi / Ticari Ünvan" },
  { en: "brandName", tr: "Tesis Adı" },
  { en: "brandName_En", tr: "Tesis Adı En" },
  { en: "webSite", tr: "Web Site Adresi" },
  { en: "roomCount", tr: "Oda Sayısı" },
  { en: "bedCount", tr: "Yatak Sayısı" },
  { en: "companyUserId", tr: "Denetleyici Firma" },
  { en: "companyUserTitle", tr: "Denetleyici Firma" },
  { en: "districtId", tr: "İlçe" },
  { en: "districtName", tr: "İlçe" },
  { en: "cityId", tr: "İl" },
  { en: "cityName", tr: "İl" },
  { en: "layoverSubCategoryName", tr: "Tesis Kategorisi" },
  { en: "layoverSubCategoryId", tr: "Tesis Kategorisi" },
  { en: "layoverSubCategoryName_En", tr: "Tesis Kategorisi En" },
  { en: "layoverFacilityTypeId", tr: "Konaklama Tesis Sınıfı" },
  { en: "layoverFacilityTypeName", tr: "Konaklama Tesis Sınıfı" },
  { en: "layoverFacilityTypeName_En", tr: "Konaklama Tesis Sınıfı En" },
  { en: "certificateTypeId", tr: "Tesis Belge Tipi" },
  { en: "certificateTypeName", tr: "Tesis Belge Tipi" },
  { en: "certificateTypeName_En", tr: "Tesis Belge Tipi En" },
  { en: "certificateStatusTypeId", tr: "Sertifika Durumu" },
  { en: "certificateStatusTypeName", tr: "Setifika Durumu" },
  { en: "certificateStatusTypeName_En", tr: "Setifika Durumu En" },
  { en: "certificateStatusTypeName_De", tr: "Setifika Durumu De" },
  { en: "certificateStatusTypeName_Ru", tr: "Setifika Durumu Ru" },
  { en: "certificateNo", tr: "Sertifika No" },
  { en: "appealDate", tr: "Başvuru Tarihi" },
  { en: "confirmationDate", tr: "Sertifika Düzenleme Tarihi" },
  { en: "appealDateString", tr: "Sertifika Tarihi" },
  { en: "confirmationDateString", tr: "Onay Tarihi" },
  { en: "isPublishWeb", tr: "Web Sitesinde Yayım Durumu" },
  { en: "isPublishQr", tr: "QR Yayım Durumu" },
  { en: "documentNo", tr: "Belge No" },
  { en: "qrCodePath", tr: "Qr Kod Adresi" },
  { en: "correctionType", tr: "Revize Durumu" },
  { en: "correctionDescription", tr: "Revize Açıklama" },
  { en: "cityDistrictName", tr: "Şehir ilçe Adı" },
  { en: "subFacilityTypeName", tr: "Sub Tesis Türü Adı" },
  { en: "subFacilityTypeName_En", tr: "Facility Type Name" },
  { en: "attachmentList", tr: "İşletme Belgeleri" },
  { en: "attachmentUnCertificateList", tr: "Uygunsuzluk / Kapalılık Belgesi" },
  { en: "attachmentCertificateList", tr: "Sertifika Belgesi" },
  { en: "status", tr: "Durum" },
  { en: "createdUserName", tr: "Oluşturan kullanıcı" },
  { en: "updatedUserName", tr: "Güncelleyen Kullanıcı" },
  { en: "createDateString", tr: "Oluşturma Tarihi" },
  { en: "createdDate", tr: "Oluşturma Tarihi" },
  { en: "updateDateString", tr: "Güncelleme Tarihi" },
  { en: "updatedDate", tr: "Güncelleme Tarihi" },
  { en: "initialAttachmentList", tr: "Ek Listesi" },
  { en: "initialAttachmentCertificateList", tr: "Sertifika Listesi" },
  {
    en: "vehicleDocumentTypeId",
    tr: "Aracın Sahip Olduğu Yetki Belgesi Sınıfı",
  },
  { en: "vehicleTypeId", tr: "Araç Cinsi" },
  { en: "seatCount", tr: "Koltuk Sayısı" },
  { en: "vehiclePlaque", tr: "Araç Plaka" },
  { en: "cityCode", tr: "İl Kodu" },
  { en: "countyCode", tr: "İlçe Kodu" },
  { en: "closingDate", tr: "Kapanış Tarihi" },
  { en: "closingDateString", tr: "Kapanış Tarihi" },
  { en: "correctionPerson", tr: "" },
  { en: "hospitalId", tr: "Anlaşmalı Sağlık Kuruluşu Adı" },
  { en: "hospitalName", tr: "Anlaşmalı Sağlık Kuruluşu Adı" },
  { en: "hotelAddress", tr: "Konaklama Adresi" },
  { en: "hotelEmail", tr: "Konaklama E-posta Adresi" },
  { en: "hotelId", tr: "Konaklama ID" },
  { en: "hotelManagerName", tr: "Konaklama Yöneticisinin Adı ve Soyadı" },
  { en: "hotelPhone", tr: "Konaklama Telefon Numarası" },
  { en: "isApproved", tr: "Onay Durumu" },
  { en: "isBlocked", tr: "Bloke Durumu" },
  { en: "isCertificateBelong", tr: "Sertifikaya Ait Mi?" },
  { en: "isHaveBusinessCertificate", tr: "Sertifika İşletmesi Var Mı?" },
  { en: "isHaveCertificate", tr: "Sertifikası Var Mı?" },
  { en: "isHaveHealthKit", tr: "Sağlık Kiti Var Mı?" },
  { en: "isOpen", tr: "Tesis Faaliyet Durumu" },
  { en: "isSelectable", tr: "Seçilebilir Mi?" },
  { en: "latitude", tr: "Enlem" },
  { en: "longitude", tr: "Boylam" },
  { en: "openingDate", tr: "Açılış Tarihi" },
  { en: "openingDateString", tr: "Açılış Tarihi" },
  { en: "systemUserId", tr: "Sistem Kullanıcı ID" },
  { en: "vkNo", tr: "Vergi Kimlik Numarası" },
  { en: "address", tr: "Adres" },
  { en: "congressFacilityTypeId", tr: "Kongre ve Sanat Tesisi Tipi" },
  { en: "congressFacilityTypeName", tr: "Kongre ve Sanat Tesisi Tipi" },
  { en: "congressFacilityTypeName_En", tr: "Kongre ve Sanat Tesisi Tipi En" },
  { en: "congressId", tr: "Tesis ID" },
  { en: "congressSubCategoryId", tr: "Tesis Kategorisi" },
  { en: "congressSubCategoryName", tr: "Tesis Kategorisi" },
  { en: "congressSubCategoryName_En", tr: "Tesis Kategorisi En" },
  { en: "email", tr: "E-posta Adresi" },
  { en: "managerName", tr: "Yöneticinin Adı ve Soyadı" },
  { en: "personCapacityCount", tr: "Kişi Kapasite Sayısı" },
  { en: "phoneNumber", tr: "Telefon Numarası" },
  { en: "saloonCount", tr: "Salon Sayısı" },
  { en: "allVersionAttachmentCertificateList", tr: "İşletme Versiyonlarının Sertifika Listesi" },
  { en: "seaTourismFacilityTypeId", tr: "Deniz Turizm Tesisi Tipi" },
  { en: "seaTourismFacilityTypeName", tr: "Deniz Turizm Tesisi Tipi" },
  { en: "seaTourismFacilityTypeName_En", tr: "Deniz Turizm Tesisi Tipi En" },
  { en: "seaTourismId", tr: "Deniz Turizm ID" },
  { en: "seaTourismSubCategoryId", tr: "Tesis Kategorisi" },
  { en: "seaTourismSubCategoryName", tr: "Tesis Kategorisi" },
  { en: "seaTourismSubCategoryName_En", tr: "Tesis Kategorisi En" },
  { en: "winterTourismFacilityTypeId", tr: "Kış Turizmi Mekanik Hatlar Tesisi Tipi" },
  { en: "winterTourismFacilityTypeName", tr: "Kış Turizmi Mekanik Hatlar Tesisi Tipi" },
  { en: "winterTourismFacilityTypeName_En", tr: "Kış Turizmi Mekanik Hatlar Tesisi Tipi En" },
  { en: "winterTourismId", tr: "Kış Turizmi Mekanik Hatlar Tesis Id" },
  { en: "winterTourismSubCategoryId", tr: "Tesis Kategorisi" },
  { en: "winterTourismSubCategoryName", tr: "Tesis Kategorisi" },
  { en: "winterTourismSubCategoryName_En", tr: "Tesis Kategorisi En" },
  { en: "themedFacilityFacilityTypeId", tr: "Temalı Tesisi Tipi" },
  { en: "themedFacilityFacilityTypeName", tr: "Temalı Tesisi Tipi" },
  { en: "themedFacilityFacilityTypeName_En", tr: "Temalı Tesisi Tipi En" },
  { en: "themedFacilityId", tr: "Temalı Tesisi ID" },
  { en: "themedFacilitySubCategoryId", tr: "Tesis Kategorisi" },
  { en: "themedFacilitySubCategoryName", tr: "Tesis Kategorisi" },
  { en: "themedFacilitySubCategoryName_En", tr: "Tesis Kategorisi En" },
  { en: "certificateStatus", tr: "Sertifika Statüsü" },
  { en: "certificateStatusString", tr: "Sertifika Statüsü" },
  // { en: "changesField", tr: "Değişen Alanlar" },
  { en: "companyUserHistoryList", tr: "Şirket Kullanıcı Geçmişi Listesi" },
  { en: "cssClass", tr: "CSS Class" },
  { en: "currentVersionStatus", tr: "Mevcut Versiyon Durumu" },
  { en: "deletedReason", tr: "Silinme Nedeni" },
  { en: "endId", tr: "Kaydın Eski Versiyonu" },
  { en: "extendedClosedDate", tr: "Sertifika Uzatılmış Bitiş Tarihi" },
  { en: "extendedClosedDateString", tr: "Sertifika Uzatılmış Bitiş Tarihi" },
  { en: "firstConfirmationDate", tr: "İlk Onay Tarihi" },
  { en: "firstConfirmationDateString", tr: "İlk Onay Tarihi" },
  { en: "isHistoryPublishQr", tr: "QR Yayımlanma Geçmişi" },
  { en: "isHistoryPublishWeb", tr: "Web'de Yayımlanma Geçmişi" },
  { en: "isHistoryUpdate", tr: "Geçmiş Kayııta Güncelleme" },
  { en: "regionName", tr: "Bölge Adı" },
  { en: "sequenceId", tr: "Kayıt No" },
  { en: "restaurantFacilityTypeId", tr: "Restoran Tesisi Tipi" },
  { en: "restaurantFacilityTypeName", tr: "Restoran Tesisi Tipi" },
  { en: "restaurantFacilityTypeName_En", tr: "Restoran Tesisi Tipi En" },
  { en: "restaurantSubCategoryId", tr: "Tesis Kategorisi" },
  { en: "restaurantSubCategoryName", tr: "Tesis Kategorisi" },
  { en: "restaurantSubCategoryName_En", tr: "Tesis Kategorisi" },
  { en: "vehicleDocumentTypeName", tr: "Araç Belgesi Tipi Adı" },
  { en: "vehicleTypeName", tr: "Araç Cinsi" },
  { en: "vehicleTypeName_En", tr: "Araç Cinsi En" },
  { en: "restaurantCertificateId", tr: "Restoran Sertifika ID" },
  { en: "transportCertificateId", tr: "Tur ve Transfer Sertifika ID" },
  { en: "hotelCertificateId", tr: "Konaklama Sertifika ID" },
  { en: "airportCertificateId", tr: "Havalimanı Sertifika ID" },
  { en: "congressCertificateId", tr: "Kongre Sertifika ID" },
  { en: "seaTourismCertificateId", tr: "Deniz Turizm Sertifika ID" },
  { en: "winterTourismCertificateId", tr: "Kış Turizm Sertifika ID" },
  { en: "themedFacilityCertificateId", tr: "Temalı Sertifika ID" },
];


export const dynamicReportOption = [
  {
    key: 1,
    text: "Konaklama,Restoran,Taşıt -> Güncel belge verildi ",
    count: "totalCurrentCertificate",
  },
  {
    key: 2,
    text:
      "Konaklama -> Güncel belge verildi - Kayıt durumu yayında - Tesis Statüsü Boş ve Sertifika uzatmalı kapalı",
    count: "hotelNonQr",
  },
  {
    key: 3,
    text: "Restoran-> Güncel belge verildi - Kayıt durumu yayında",
    count: "publishedAllRestaurant",
  },
  {
    key: 4,
    text: "Taşıt -> Güncel belge verildi - Kayıt durumu yayında",
    count: "publishedAllTransport",
  },
  {
    key: 5,
    text:
      "Konaklama -> Güncel belge verildi - Kayıt durumu yayında - Tesis Statüsü Boş ve Sertifika uzatmalı kapalı (Karekodsuz sertifikalar hariç) - Faaliyet Durumu açık",
    count: "openedHotelCertificate",
  },
  {
    key: 6,
    text:
      "Konaklama -> Güncel belge verildi - Kayıt durumu yayında - Tesis Statüsü Boş ve Sertifika uzatmalı kapalı (Karekodsuz sertifikalar hariç) - Faaliyet Durumu kapalı",
    count: "closedHotelCertificate",
  },
  {
    key: 7,
    text: "Konaklama -> Güncel uygun görülmedi.",
    count: "notSuitableHotelCertificate",
  },
  {
    key: 8,
    text:
      "Konaklama -> Kayıt durumu sertifika sürecinden ayrıldı - beyan , Tesis Statüsü Boş veya Sertifika uzatmalı kapalı",
    count: "leavedHotelCertificate",
  },
  {
    key: 9,
    text:
      "Konaklama -> Şuana kadar en az 1 kez belge verildi statüsü almış bakanlık belgeli konaklama tesisler",
    count: "ministryApprovaledHotels",
  },
  {
    key: 10,
    text:
      "Konaklama -> Şuana kadar en az 1 kez belge verildi statüsü almış Belediye Belgeli konaklama tesisler",
    count: "otherApprovalHotels",
  },
  {
    key: 11,
    text:
      "Konaklama -> Şuana kadar en az 1 kez belge verildi statüsü almış tüm konaklama tesislerinin sayısı",
    count: "allApprovalHotels",
  },
  {
    key: 12,
    text:
      "Restoran -> Şuana kadar belge verildi olan tüm restoran sertifika kayıtları sayısı",
    count: "allApprovalRestaurant",
  },
  {
    key: 13,
    text: "Taşıt -> Şuana kadar en az 1 kez belge verildi statüsü almışlar",
    count: "allApprovalTransport",
  },
];

export const CategoryTypeAdditional: any[] = [
  {
    key: 5,
    text: "Konaklama Tesisi",
  },
  {
    key: 6,
    text: "Kongre ve Sanat Tesisi",
  },
  {
    key: 7,
    text: "Deniz Turizm Tesisi",
  },
  {
    key: 8,
    text: "Temalı Tesisler",
  },
  {
    key: 9,
    text: "Kış Turizmi Mekanik Hat Tesisleri",
  },
];

export const AuditTypeEnum: any[] = [
  {
    key: 1,
    text: "Haberli Denetim",
  },
  {
    key: 2,
    text: "Habersiz Denetim",
  },
  {
    key: 3,
    text: "Gizli Müşteri Denetim",
  },
  {
    key: 4,
    text: "Şikayet Denetim",
  },
];

export const AuditResultEnum: any[] = [
  {
    key: 1,
    text: "Uygunsuzluk Tespit Edilmedi.",
  },
  {
    key: 2,
    text: "Uygunsuzluk Tespit Edildi ve Kapama Süresi Verildi.",
  },
  {
    key: 3,
    text: "Uygunsuzluk Tespit Edildi ve Belge İptal Edildi.",
  },
];
export const FinalResultEnum: any[] = [
  {
    key: 1,
    text: "Uygunsuzluk Kapama Süreci Devam Ediyor.",
  },
  {
    key: 2,
    text: "Uygunsuzluk Giderildi.",
  },
  {
    key: 3,
    text: "Uygunsuzluk Giderilmedi ve Belge İptal Edildi.",
  },
];
export const RECORD_STATUS = [
  { key: StatusEnums.Deleted, text: "Silindi" },
  { key: StatusEnums.Published, text: "Yayımda" },
]

export const RECORD_CERTIFICATION_STATUS = [
  {
    key: StatusEnums.PendingApproval,
    text: "Sertifika Sürecinden Ayrıldı – Süre Bitti",
  },
  {
    key: 30,
    text: "Sertifika Sürecinden Ayrıldı - Diğer",
  },
  { key: StatusEnums.Deleted, text: "Silindi" },
  { key: StatusEnums.DraftArchive, text: "Uygun Görülmemiş" },
  { key: StatusEnums.Draft, text: "Firma Değişmiş" },
  { key: StatusEnums.Published, text: "Yayımda" },
  {
    key: StatusEnums.ActivityStopped,
    text: "Faaliyetini Durduranlar",
  },

  // {
  //   key: StatusEnums.PendingApproval,
  //   text: "Sertifika Süresi Bitti",
  // },
  // {
  //   key: 30,
  //   text: "Sertifikasız-Firma Düşürüldü",
  // },
  // { key: StatusEnums.Deleted, text: "Silinmiş" },
  // {
  //   key: StatusEnums.DraftArchive,
  //   text: "Uygun Görülmedi",
  // },
  // { key: StatusEnums.Draft, text: "Firma Değişti" },
  // { key: StatusEnums.Published, text: "Yayımda" },
  // {
  //   key: StatusEnums.ActivityStopped,
  //   text: "Faaliyetini Durdurdu",
  // },
]
