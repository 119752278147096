import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import CertificateStatusType from "src/services/CertificateStatusType/CertificateStatusTypeService";
import {
  CertificateStatusTypeGetAllRequestModel,
  CertificateStatusTypeChangeStatusRequestModel,
  CertificateStatusTypeGetByIdRequestModel,
  CertificateStatusTypeUpdateRequestModel,
  CertificateStatusTypeSaveRequestModel,
} from "src/services/CertificateStatusType/RequestModel";
import { MessageType } from "./system/types";
import { errorMessageSaveOrUpdate } from "src/helpers/functions";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllCR(
  request: CertificateStatusTypeGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const CertificateStatusTypeService = new CertificateStatusType();
    const result = await CertificateStatusTypeService.getAll(request);
    dispatch(setFormData("GroupListCR", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: CertificateStatusTypeGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CertificateStatusTypeService = new CertificateStatusType();
    const result = await CertificateStatusTypeService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: CertificateStatusTypeSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CertificateStatusTypeService = new CertificateStatusType();
    const result = await CertificateStatusTypeService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Durumu Yönetimi"
        )
      );
      return result;
    } else {
      errorMessageSaveOrUpdate(dispatch,result)

      return false;
    }
  };
}
export function update(
  request: CertificateStatusTypeUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CertificateStatusTypeService = new CertificateStatusType();
    const result = await CertificateStatusTypeService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Durumu Yönetimi"
        )
      );
      return true;
    } else {
      errorMessageSaveOrUpdate(dispatch,result)

      return false;
    }
  };
}

export function getById(
  request: CertificateStatusTypeGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CertificateStatusTypeService = new CertificateStatusType();
    const result = await CertificateStatusTypeService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: CertificateStatusTypeChangeStatusRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CertificateStatusTypeService = new CertificateStatusType();
    const result = await CertificateStatusTypeService.changeStatus(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Durumu Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Durumu Yönetimi"
        )
      );
      return false;
    }
  };
}
