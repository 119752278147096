import { HttpClient } from "src/library/HttpClient";
import {
  CongressSubCategoryChangeStatusRequestModel,
  CongressSubCategoryGetByIdRequestModel,
  CongressSubCategoryUpdateRequestModel,
  CongressSubCategorySaveRequestModel,
  CongressSubCategoryGetAllRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class CongressSubCategory {
  async getAll(request: CongressSubCategoryGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressSubCategory + "GetAll",
      { ...request, token }
    );
  }

  async save(request: CongressSubCategorySaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressSubCategory + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: CongressSubCategoryUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressSubCategory + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(request: CongressSubCategoryGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressSubCategory + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(
    request: CongressSubCategoryChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressSubCategory + "ChangeStatus",
      { ...request, token }
    );
  }
}
