import * as React from "react";
import {
  Dialog,
  DialogType,
  DialogFooter,
} from "office-ui-fabric-react/lib/Dialog";
import {
  PrimaryButton,
  DefaultButton,
} from "office-ui-fabric-react/lib/Button";
import { getId } from "office-ui-fabric-react/lib/Utilities";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RootState } from "src/store/reducer";
import { toggleConfirmDialog } from "src/store/system/actions";
import {
  setFormData,
} from "src/store/form/actions";
import cookie from "react-cookies";

function mapStateToProps(state: RootState) {
  return {
    show: state.system.toggleConfirm.show,
    text: state.system.toggleConfirm.text,
    callback: state.system.toggleConfirm.callback,
    reject: state.system.toggleConfirm.reject,
    isDeletedReason: state.form.formData.isDeletedReason,
    deletedReason: state.form.formData.deletedReason 
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      toggleConfirmDialog,
      setFormData
    },
    dispatch
  );
}

type ConfirmModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
  let usertype: any = 0;

class ConfirmModal extends React.Component<ConfirmModalProps> {
  _labelId: string = getId("dialogLabel");
  _subTextId: string = getId("subTextLabel");

  componentDidMount(){
    usertype = cookie.load("systemUserInfo");

  }

  render() {

    return (
      <div>
        <Dialog
          hidden={!this.props.show}
          onDismiss={() => this.props.toggleConfirmDialog(false, "", () => { })}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Uyarı",
            closeButtonAriaLabel: "Close",
            subText: this.props.text,
          }}
          modalProps={{
            titleAriaId: this._labelId,
            subtitleAriaId: this._subTextId,
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
          }}
        >
           {(usertype === "1" || usertype === "3") && this.props.isDeletedReason && <div className="form-wrapper">
            <div>
              <label className="correctionDescriptionLabel">
                Silinme Sebebi
              </label>
              <textarea
                placeholder="Silinme açıklamasını giriniz"
                value={this.props.deletedReason || ""}
                rows={8}
                className="custom-textfield border correctionDescription"
                onChange={(event: any) => {
                  this.props.setFormData(
                    "deletedReason",
                    event.target.value === "" ? null : event.target.value
                  );
                }}
              ></textarea>
            </div>
          </div>}
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                this.props.callback(true);
                this.props.toggleConfirmDialog(false, "", () => { });
              }}
              text="Onayla"
            />
            <DefaultButton
              onClick={() => {
                  this.props.reject && this.props.reject(true);
                  this.props.toggleConfirmDialog(false, "", () => { })}}
              text="Vazgeç"
            />
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);
