import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Header from "../../components/Header";
import LeftPanel from "../../components/LeftPanel";
import { List } from "../../components/Fair";

export default class FairRoute extends React.Component<RouteComponentProps> {
  render() {
    return (
      <React.Fragment>
        <Header history={this.props.history} />
        <div className="row">
          <LeftPanel history={this.props.history} />
          <List history={this.props.history} />
        </div>
      </React.Fragment>
    );
  }
}
