import { Component } from "react";
import { Modal, Icon, DefaultButton } from "office-ui-fabric-react/lib/index";
import { connect } from "react-redux";
import { RootState } from "src/store/reducer";
import { bindActionCreators, Dispatch } from "redux";
import { hideMessage } from "../store/system/actions";
import { MessageType } from "src/store/system/types";

function mapStateToProps(state: RootState) {
  return {
    showAt: state.system.message.showAt,
    messageTitle: state.system.message.messageTitle,
    messageContent: state.system.message.messageContent,
    messageType: state.system.message.messageType,
    messageSubTitle: state.system.message.messageSubTitle,
    windowReload: state.system.message.windowReload,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        hideMessage,
      },
      dispatch
    ),
  };
}

class MessageBoxConfirme extends Component<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> {
  static defaultProps = {
    showAt: false,
  };

  renderTypeStringByNumber(messageType: number) {
    switch (messageType) {
      case MessageType.ERROR:
        return "error";
      case MessageType.SUCCESS:
        return "success";
      case MessageType.WARNING:
        return "warning";
      default:
        return "";
    }
  }

  render() {
    const {
      showAt,
      messageType,
      hideMessage,
      messageTitle,
      messageContent,
      messageSubTitle,
    } = this.props;
    if (!showAt) {
      return null;
    }
    return (
      <Modal
        className={`warning custom-message-box ${this.renderTypeStringByNumber(
          messageType as number
        )}`}
        isOpen={true}
        onDismiss={() => hideMessage()}
      >
        <div className="modal-header row">
          <div className="title">
            {messageTitle}
            <div className="sub-title">{messageSubTitle}</div>
          </div>
          <span onClick={() => hideMessage()} className="close">
            <Icon iconName="ChromeClose" />
          </span>
        </div>
        <div style={{ paddingBottom: 0 }} className="description modal-content">
          <div dangerouslySetInnerHTML={{ __html: messageContent }}></div>
          <div className="modal-indicators">
            <DefaultButton
              style={{ marginTop: 15 }}
              text="EVET"
              onClick={() =>
                this.props.windowReload
                  ? window.location.reload()
                  : hideMessage()
              }
            />
            <DefaultButton
              style={{ marginTop: 15 }}
              text="iPTAL"
              onClick={() =>
                this.props.windowReload
                  ? window.location.reload()
                  : hideMessage()
              }
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageBoxConfirme);
