import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import CongressCertification from "src/services/CongressCertification/CongressCertification";
import {
  CongressCertificationGetAllRequestModel,
  CongressCertificationChangeStatusRequestModel,
  CongressCertificationGetByIdEndIdRequestModel,
  CongressCertificationGetByIdRequestModel,
  CongressCertificationUpdateRequestModel,
  CongressCertificationSaveRequestModel,
  RemovePublish,
  CongressCertificationUpdateBySequenceIdRequestModel,
} from "src/services/CongressCertification/RequestModel";
import { MessageType } from "./system/types";
import { errorMessageSaveOrUpdate } from "src/helpers/functions";
import { formatDate } from "src/helpers/converts";
import { ILeaveCertificationProcessRequest } from "src/services/BaseEnum";
let cityRegions: any[] = [];

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllPage(
  request: CongressCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const CongressCertificationService = new CongressCertification();
    const result = await CongressCertificationService.getAll(request);
    dispatch(setFormData("excel", result));
    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: CongressCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const CongressCertificationService = new CongressCertification();
    const result = await CongressCertificationService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: CongressCertificationSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressCertificationService = new CongressCertification();
    const result = await CongressCertificationService.save(request);
    // dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return result;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}
export function update(
  request:
    | CongressCertificationUpdateRequestModel
    | CongressCertificationUpdateBySequenceIdRequestModel,
  sequence?: boolean
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const CongressCertificationService = new CongressCertification();
    const result = sequence
      ? await CongressCertificationService.updateBySequenceId(request)
      : await CongressCertificationService.update(request);
    // dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getById(
  request: CongressCertificationGetByIdRequestModel,
  sequence?: boolean
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressCertificationService = new CongressCertification();
    const result = sequence
      ? await CongressCertificationService.getBySequenceId(request)
      : await CongressCertificationService.getById(request);
    dispatch(
      initialFormData({
        ...result.data,
        intitialAppealDate: result.data?.appealDate,
        initialAttachmentList: result.data?.attachmentList,
        initialAttachmentCertificateList:
          result.data?.attachmentCertificateList,
        initialAttachmentUnCertificateList:
          result.data?.attachmentUnCertificateList,
      })
    );
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getByEndId(
  request: CongressCertificationGetByIdEndIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressCertificationService = new CongressCertification();
    const result = await CongressCertificationService.getByEndId(request);
    dispatch(
      initialFormData({
        ...result.data,
        initialAttachmentList: result.data.attachmentList,
        initialAttachmentCertificateList: result.data.attachmentCertificateList,
        initialAttachmentUnCertificateList:
          result.data.attachmentUnCertificateList,
      })
    );
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: CongressCertificationChangeStatusRequestModel,
  isDelete: boolean = false
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressCertificationService = new CongressCertification();
    const result = await CongressCertificationService.changeStatus(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(initialFormData(result));
      dispatch(
        showMessage(
          isDelete
            ? "Silme işlemi başarıyla değiştirildi."
            : "Kayıt durumu işlemi başarıyla değiştirildi.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}

export function excelDownload(
  request: CongressCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressService = new CongressCertification();
    const excelName = "KongreveSanatSalonu" + formatDate();
    await CongressService.excelDownload(request, excelName);
    dispatch(toggleLoader());

    return false;
  };
}

export function getAllCongressCertificationANDRestaurant(
  request: CongressCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const CongressCertificationService = new CongressCertification();
    const resultCongressCertification =
      await CongressCertificationService.getAll(request);
    for (
      let index = 0;
      index < resultCongressCertification.dataList.length;
      index++
    ) {
      resultCongressCertification.dataList[index]["region"] =
        resultCongressCertification.dataList[index].cityName &&
        resultCongressCertification.dataList[index].cityName !== ""
          ? cityRegions.filter(
              (x: any) =>
                x.city === resultCongressCertification.dataList[index].cityName
            )[0]?.region
          : "";
      resultCongressCertification.dataList[index]["categoryName"] =
        "KONGRE MERKEZİ";
      resultCongressCertification.dataList[index]["categoryName_En"] =
        "CONGRESS CENTER";
    }
    dispatch(
      setFormData(
        "CongressCertificationListForExcel",
        resultCongressCertification
      )
    );

    // let resultRestaurant: any = null
    // if (request["CongressCertificationSubCategoryId"] === 0 || request["CongressCertificationSubCategoryId"] === null || request["CongressCertificationSubCategoryId"] === undefined) {
    // 	const RestaurantService = new Restaurant();
    // 	resultRestaurant = await RestaurantService.getAll(request);
    // 	for (let index = 0; index < resultRestaurant.dataList.length; index++) {
    // 		resultRestaurant.dataLiFst[index]["region"] = resultRestaurant.dataList[index].cityName && resultRestaurant.dataList[index].cityName != "" ? cityRegions.filter((x: any) => x.city === resultRestaurant.dataList[index].cityName)[0]?.region : ""

    // 		resultRestaurant.dataList[index]["categoryName"] = "RESTORAN / KAFE"
    // 		resultRestaurant.dataList[index]["categoryName_En"] = "Restaurant / Cafe"
    // 	}
    // 	dispatch(setFormData("RestaurantListForExcel", resultRestaurant));
    // }

    dispatch(toggleLoader());
    if (resultCongressCertification.success) {
      return true;
    }
    return false;
  };
}

export function getAllForPager(
  request: CongressCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressCertificationService = new CongressCertification();
    const result = await CongressCertificationService.getAll(request);
    dispatch(setFormData("dataList", result.dataList));
    dispatch(setFormData("pageCount", result.pageCount));
    dispatch(setFormData("dataCount", result.dataCount));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function GetCongressCertificationAndRestaurantCount(
  request: CongressCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressCertificationService = new CongressCertification();
    const result =
      await CongressCertificationService.GetCongressCertificationAndRestaurantCount(
        request
      );
    dispatch(setFormData("CongressCertificationAndRestaurantCount", result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function RemoveWebPublish(
  request: RemovePublish
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressCertificationService = new CongressCertification();
    const result = await CongressCertificationService.RemoveWebPublish(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Sertifika Web Sitesinde Yayımdan Kaldırıldı",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}
export function RemoveQrPublish(
  request: RemovePublish
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressCertificationService = new CongressCertification();
    const result = await CongressCertificationService.RemoveQrPublish(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "QR Yayımdan Kaldırıldı",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}

export function ChangeCompanyUser(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressService = new CongressCertification();
    const result = await CongressService.ChangeCompanyUser(request);
    //dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Ana Kategori Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Ana Kategori Yönetimi"
        )
      );
      return false;
    }
  };
}


export function leaveCertificationProcess(
  request: ILeaveCertificationProcessRequest
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CongressService = new CongressCertification();
    const result = await CongressService.leaveCertificationProcess(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "İşleminiz başarı ile gerçekleşmiştir.",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}