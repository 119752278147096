import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { panels } from "src/utils/constants";
import { Link } from "react-router-dom";
import { History } from "history";
import BasicTable from "../BasicTable";
import { PrimaryButton, Label } from "office-ui-fabric-react";
import { getAll, del } from "src/store/thunksFair";
import {
  FairDeleteRequestModel,
  FairGetAllRequestModel,
} from "src/services/Fair/RequestModel";
import { toggleConfirmDialog } from "src/store/system/actions";
import { listCount } from "src/config/otherConfig";
import { castThunkAction } from "src/helpers/casting";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        getAll,
        toggleConfirmDialog,
        del,
      },
      dispatch
    ),
  };
}

type FairProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  pagerTotal: number;
}
// let title = "";

class Fair extends Component<FairProps, OwnState> {
  state = {
    filterData: {
      title: "",
      fairOrder: 0,
    },
    pagerCurrent: 0,
    pagerTotal: 0,
  };

  UNSAFE_componentWillMount() {
    this.getData(this.state.pagerCurrent);
  }
  UNSAFE_componentWillUpdate(nextProps: any, nextState: any) {
    if (nextState.pagerTotal !== nextProps.formData.pageCount)
      this.setState({
        ...this.state,
        pagerTotal: this.props.formData.pageCount,
      });
  }
  getData(pagerSelected: number) {
    const request: FairGetAllRequestModel = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      title: this.state.filterData.title,
    };
    this.props.getAll(request);
  }

  onDelete(FairOrder: number) {
    const request: FairDeleteRequestModel = {
      id: FairOrder,
    };
    castThunkAction(this.props.del(request)).then((response: any) => {
      this.getData(this.state.pagerCurrent);
    });
  }

  renderDashboardItems() {
    return (
      <div className="dashboard-items row">
        {panels.map(
          (item, index) =>
            item.children && (
              <React.Fragment>
                <div className="title">
                  {item.text}
                  <div className="title-text">{item.subText}</div>
                </div>
                <div className="childrens row">
                  {item.children.map((children) => (
                    <div key={index} className={`dashboard-item col-md-3`}>
                      <Link
                        className="row"
                        to={children.url ? children.url : "/"}
                      >
                        <div className="icon">
                          <i className={children.icon}></i>
                        </div>
                        <div className="title">
                          {children.text}
                          <div className="sub-text">{children.subText}</div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )
        )}
      </div>
    );
  }

  // renderFilter() {
  //   return (
  //     <div className="table-filter row">
  //       <Dropdown
  //         className="custom-dropdown col-md-3"
  //         label="Fuarın Adı"
  //         placeholder="Seçiniz"
  //         onChange={(event: any, option: any) => {
  //           this.setState({
  //             filterData: {
  //               ...this.state.filterData,
  //               title: option.key,
  //             },
  //           });
  //         }}
  //         options={this.props.formData.dataList?.map((item: any) => ({
  //           key: item.title,
  //           text: item.title,
  //         }))}
  //         calloutProps={{
  //           className: "custom-dropdown-callout",
  //         }}
  //       />
  //       {/* <PrimaryButton
  //         text="Arama Yap"
  //         className="custom-button"
  //         onClick={() => this.getData(0)}
  //         styles={{
  //           root: {
  //             fontSize: "11px",
  //             marginTop: "27px",
  //             height: "30px",
  //           },
  //         }}
  //       /> */}
  //     </div>
  //   );
  // }

  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "fairOrder",
            text: "No",
            col: "col-md-1",
          },
          {
            columnName: "title",
            text: "Fuarın Adı",
            col: "col-md-10",
          },

          {
            columnName: "fairOrder",
            text: "İşlem",
            col: "col-md-1",
            className: "operations",
            onRender: (item: any) => (
              <React.Fragment>
                {
                  <button
                    onClick={(x) =>
                      (window.location.href = `/Fair/edit/${item.fairId}`)
                    }
                  >
                    <i className="icon-edit-outline"></i> Düzenle
                  </button>
                }

                <button
                  style={{ backgroundColor: "#178df7" }}
                  onClick={() =>
                    this.props.toggleConfirmDialog(
                      true,
                      "Silme işlemini gerçekleştirmek istiyor musunuz?",
                      () => this.onDelete(item.fairId)
                    )
                  }
                >
                  <i className="icon-trash-outline"></i> Sil
                </button>
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.dataList || []}
      />
    );
  }
  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.state.pagerTotal}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }
  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getData(selected);
  };
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Fuar Yönetimi"
          subTitle="Yönetilebilecek tüm alanları aşağıdan görüntüleyebilirsiniz."
          backUrl="/"
          showBack
          history={this.props.history}
        />
        <PrimaryButton
          onClick={() => this.props.history.push("/Fair/create")}
          iconProps={{ iconName: "Add" }}
          className="absolute-create"
          text="Fuar Ekle"
          styles={{
            root: {
              background: "#62a98e",
              borderColor: "#5b9e85",
            },
            rootHovered: {
              background: "#5b9e85",
              borderColor: "#5b9e85",
            },
            rootDisabled: {
              background: "#ececec",
            },
          }}
        />

        <Label style={{ marginTop: "-21px", float: "right" }}>
          Toplam Kayıt Sayısı: {this.props.formData?.dataCount}
        </Label>
        {/* {this.renderFilter()} */}
        {this.renderList()}
        {this.renderPager()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Fair);
