import { HttpClient } from "src/library/HttpClient";
import {
  TransportGetAllRequestModel,
  TransportSaveRequestModel,
  TransportUpdateRequestModel,
  TransportGetByIdRequestModel,
  TransportChangeStatusRequestModel,
  RemovePublish,
  TransportCertificationUpdateBySequenceIdRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";
import { ILeaveCertificationProcessRequest } from "../BaseEnum";

export default class Transport {
  async getAll(request: TransportGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Transport + "GetTransportCertificateList",
      { ...request, token }
    );
  }

  async save(request: TransportSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Transport + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: TransportUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Transport + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(request: TransportGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Transport + "GetById",
      { ...request, token }
    );
  }

  async getByEndId(request: TransportGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Transport + "GetByEntityId",
      { ...request, token }
    );
  }

  async changeStatus(request: TransportChangeStatusRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Transport + "ChangeStatus",
      { ...request, token }
    );
  }

  async RemoveWebPublish(request: RemovePublish): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Transport + "RemoveWebPublish",
      { ...request, token }
    );
  }
  async RemoveQrPublish(request: RemovePublish): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Transport + "RemoveQrPublish",
      { ...request, token }
    );
  }

  async getBySequenceId(request: TransportGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Transport + "GetBySequenceId",
      { ...request, token }
    );
  }

  async updateBySequenceId(
    request: TransportCertificationUpdateBySequenceIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Transport + "UpdateBySequenceId",
      { ...request, token }
    );
  }

  async changeStatusBySequenceId(
    request: TransportChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Transport + "ChangeStatusBySequenceId",
      { ...request, token }
    );
  }

  async excelDownload(request: any, name: string) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.Transport + "GenerateExcel",
      name,
      { ...request, token }
    );
  }
  async leaveCertificationProcess(
    request: ILeaveCertificationProcessRequest
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Transport + "LeaveCertificationProcess",
      { ...request, token }
    );
  }
  async ChangeCompanyUser(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Transport + "ChangeCompanyUser",
      { ...request, token }
    );
  }

}
