import { StatusEnums } from "../BaseEnum";

export interface SeaTourismSubCategorySaveDataModel {
  mainCategoryId: number;
  title: string;
  title_En: string;
  status: StatusEnums;
  isMinistry: boolean;
}
export interface SeaTourismSubCategorySaveRequestModel {
  data: SeaTourismSubCategorySaveDataModel;
}

export interface SeaTourismSubCategoryUpdateDataModel {
  SeaTourismSubCategoryId: number;
  mainCategoryId: number;
  title_En: string;
  title: string;
  status: StatusEnums;
  isMinistry: boolean;
}

export interface SeaTourismSubCategoryUpdateRequestModel {
  data: SeaTourismSubCategoryUpdateDataModel;
}

export interface SeaTourismSubCategoryChangeStatusRequestModel {
  status: StatusEnums;
  id: number;
}

export enum ConditionOperatorEnum {
  Eq = 0,
  NotEq = 1,
  Like = 2,
  Gt = 3,
  Lt = 4,
  Ge = 5,
  Le = 6,
}

export interface criteriaItemListData {
  propertyName: string;
  value: any;
  isOr: boolean;
  conditionOperator: ConditionOperatorEnum;
}

export interface SeaTourismSubCategoryGetAllRequestModel {
  criteriaItemList?: criteriaItemListData[];
  selectItemList?: string[];
  pageSize?: number;
  pageIndex?: number;
  orderField?: string;
  isDescending?: boolean;
  status?: StatusEnums;
}
export interface SeaTourismSubCategoryGetByIdRequestModel {
  id: number;
}

export interface SeaTourismSubCategoryChangeStatusRequestModel {
  id: number;
  status: StatusEnums;
}
