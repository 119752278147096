import { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { History } from "history";
import { toggleConfirmDialog } from "src/store/system/actions";
import cookie from "react-cookies";
import { getAllM } from "src/store/thunksCity";
import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";
import { GetHotelCountByCity } from "src/store/thunkStatistic";
import {
  TextField,
  PrimaryButton,
  Label,
  ComboBox,
  Checkbox,
} from "office-ui-fabric-react";
import { setFormData } from "src/store/form/actions";

// @ts-ignore
import ReactExport from "react-data-export";
import { formatDate } from "src/helpers/converts";
import { castThunkAction } from "src/helpers/casting";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let selectableFieldsConst = [
  { field: "totalHotelCount", text: "TOPLAM TESİS SAYISI" },
  { field: "upRoomCount", text: "+X ODALI" },
  { field: "downRoomCount", text: "-X ODALI" },
  { field: "certificateGivenCount", text: "BELGE ALAN" },
  { field: "certificateProcessCount", text: "BELGE MÜRACATI" },
  { field: "totalAppealCount", text: "TOPLAM BAŞVURU" },
  { field: "certificateGivenUpRoomCount", text: "BELGE ALAN +X ODA" },
  { field: "certificateGivenDownRoomCount", text: "BELGE ALAN -X ODA" },
  { field: "unCertificateUpRoomCount", text: "BELGE ALAMAYAN +X OTEL SAYISI" },
  {
    field: "unCertificateDownRoomCount",
    text: "BELGE ALAMAYAN -X OTEL SAYISI",
  },
  {
    field: "municipalityCertificateCount",
    text: "BELEDİYE BELGELİ OTEL SAYISI",
  },
  { field: "ministryCertificateCount", text: "KTB BELGELİ OTEL SAYISI" },
  {
    field: "municipalityUpRoomCount",
    text: "+X ODA BELEDİYE BELGELİ OTEL SAYISI",
  },
  {
    field: "municipalityDownRoomCount",
    text: "-X ODA BELEDİYE BELGELİ OTEL SAYISI",
  },
  { field: "ministryUpRoomCount", text: "+X ODA KTB BELGELİ OTEL SAYISI" },
  { field: "ministryDownRoomCount", text: "-X ODA KTB BELGELİ OTEL SAYISI" },
  {
    field: "ministryCertificateGivenCount",
    text: "KTB BELGELİ BELGE VERİLEN OTEL SAYISI",
  },
  {
    field: "ministryCertificateProcessCount",
    text: "KTB BELGELİ BELGELENDİRME SÜRECİNDEKİ OTEL SAYISI",
  },
  {
    field: "municipalityCertificateGivenCount",
    text: "BELEDİYE BELGELİ BELGE VERİLEN OTEL SAYISI",
  },
  {
    field: "municipalityCertificateProcessCount",
    text: "BELEDİYE BELGELİ BELGELENDİRME SÜRECİNDEKİ OTEL SAYISI",
  },
  {
    field: "ministryCertificateGivenUpRoomCount",
    text: "KTB BELGE ALAN +X ODA",
  },
  {
    field: "ministryCertificateGivenDownRoomCount",
    text: "KTB BELGE ALAN -X ODA",
  },
  {
    field: "ministryUnCertificateUpRoomCount",
    text: "KTB BELGE ALAMAYAN +X OTEL SAYISI",
  },
  {
    field: "ministryUnCertificateDownRoomCount",
    text: "KTB BELGE ALAMAYAN -X OTEL SAYISI",
  },
  {
    field: "municipalityCertificateGivenUpRoomCount",
    text: "BELEDİYE BELGE ALAN +X ODA",
  },
  {
    field: "municipalityCertificateGivenDownRoomCount",
    text: "BELEDİYE BELGE ALAN -X ODA",
  },
  {
    field: "municipalityUnCertificateUpRoomCount",
    text: "BELEDİYE BELGE ALAMAYAN +X OTEL SAYISI",
  },
  {
    field: "municipalityUnCertificateDownRoomCount",
    text: "BELEDİYE BELGE ALAMAYAN -X OTEL SAYISI",
  },
];

let usertype: any = 0;
interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        toggleConfirmDialog,
        getAllM,
        GetHotelCountByCity,
        setFormData,
      },
      dispatch
    ),
  };
}

type ProvinceBasedReportProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  pagerTotal: number;
  excel: boolean;
  download: boolean;
  selectableFields: any[];
  selectedFields: any[];
}

class List extends Component<ProvinceBasedReportProps, OwnState> {
  state = {
    filterData: {
      searchKey: "",
      isRead: null,
      orderField: "ProcessDate",
      isDescending: true,
    },
    pagerCurrent: 0,
    excel: false,
    download: false,
    pagerTotal: 0,
    selectableFields: selectableFieldsConst,
    selectedFields: selectableFieldsConst,
  };

  UNSAFE_componentWillMount() {
    this.props.setFormData("cityId", 0);
    usertype = cookie.load("systemUserInfo");
    if (usertype === "2") {
      window.location.href = "/";
    }
    const request2: CityGetAllRequestModel = {
      selectItemList: ["CityId", "CityName"],
      isDescending: false,
      orderField: "CityName",
      criteriaItemList: [
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
    };
    this.props.getAllM(request2);
    this.props.setFormData("roomCount", 50);
  }
  UNSAFE_componentWillUpdate(nextProps: any, nextState: any) {
    if (nextState.pagerTotal !== nextProps.formData.pageCount)
      this.setState({
        ...this.state,
        pagerTotal: this.props.formData.pageCount,
      });
  }

  dataMapForExcel(dataList: any, fieldList: any) {
    const mappedData = [];
    if (dataList) {
      for (let index = 0; index < dataList.length; index++) {
        mappedData.push([...this.mapField(dataList[index], fieldList)]);
      }
    }

    return mappedData;
  }
  mapField(data: any, fieldList: any) {
    const list = [{ value: data.districtName }];
    for (let index = 0; index < fieldList.length; index++) {
      list.push({
        value: data[fieldList[index].field],
      });
    }
    return list;
  }

  rederexcel() {
    const excelName = "ilBazlıOtelSayısıRaporu_" + formatDate();

    let multiDataSet: any = [];

    if (this.state.excel) {
      multiDataSet = [
        {
          // columns: [
          // 	{ title: this.props.formData?.HotelCountByCity?.cityName || "", width: { wpx: 90 } },//pixels width
          // 	{ title: "TOPLAM TESİS SAYISI", width: { wpx: 90 } },
          // 	{ title: "+" + this.props.formData.roomCount + " ODALI", width: { wpx: 90 } },
          // 	selectedFields.filter(x => x.field === "municipalityDownRoomCount").length > 0 && { title: "-" + this.props.formData.roomCount + " ODALI", width: { wpx: 90 } }
          // ],
          columns: [
            {
              title: this.props.formData?.HotelCountByCity?.cityName || "",
              width: { wpx: 190 },
            },
            ...this.state.selectedFields.map((x: any) => {
              return { title: x.text, width: { wpx: 190 } };
            }),
          ],
          data: this.dataMapForExcel(
            this.props.formData?.HotelCountByCity?.districts,
            this.state.selectedFields
          ),
        },
      ];
    }
    return this.state.excel ? (
      <ExcelFile
        filename={excelName}
        element={
          <PrimaryButton
            text="Raporu İndir"
            className="custom-button"
            style={{ marginLeft: "15px" }}
            styles={{
              root: {
                fontSize: "11px",
                marginTop: "27px",
                height: "30px",
              },
            }}
          />
        }
      >
        <ExcelSheet dataSet={multiDataSet} name="Organization" />
      </ExcelFile>
    ) : (
      // <ExcelFile
      // 	filename={excelName}
      // 	element={
      // 		<PrimaryButton
      // 			text="Raporu İndir"
      // 			className="custom-button"
      // 			style={{ marginLeft: "15px" }}
      // 			styles={{
      // 				root: {
      // 					fontSize: "11px",
      // 					marginTop: "27px",
      // 					height: "30px"
      // 				}
      // 			}}
      // 		/>
      // 	}
      // >
      // 	<ExcelSheet
      // 		data={this.props.formData?.HotelCountByCity?.districts}
      // 		name="Rapor 1"
      // 	>
      // 		<ExcelColumn label={this.props.formData?.HotelCountByCity.cityName} value={(col: any) =>
      // 			col.districtName
      // 		} />
      // 		<ExcelColumn label="TOPLAM TESİS SAYISI" value="totalHotelCount" />
      // 		<ExcelColumn label={"+" + this.props.formData.roomCount + " ODALI"} value="municipalityUpRoomCount" />
      // 		<ExcelColumn label={"-" + this.props.formData.roomCount + " ODALI"} value="municipalityDownRoomCount" />
      // 		<ExcelColumn label="BELGE ALAN" value="certificateGivenCount" />
      // 		<ExcelColumn label="BELGE MÜRACATI" value="certificateProcessCount" />
      // 		<ExcelColumn label="TOPLAM BAŞVURU" value="totalAppealCount" />

      // 	</ExcelSheet>
      // 	<ExcelSheet
      // 		data={this.props.formData?.HotelCountByCity?.districts}
      // 		name="Rapor 2"
      // 	>
      // 		<ExcelColumn label={this.props.formData?.HotelCountByCity.cityName} value={(col: any) =>
      // 			col.districtName
      // 		} />
      // 		<ExcelColumn label="TOPLAM TESİS SAYISI" value="totalHotelCount" />
      // 		<ExcelColumn label={"BELGE ALAN +" + this.props.formData.roomCount + " ODA"} value="certificateGivenUpRoomCount" />
      // 		<ExcelColumn label={"BELGE ALAN -" + this.props.formData.roomCount + " ODA"} value="certificateGivenDownRoomCount" />
      // 		<ExcelColumn label={"BELGE ALAMAYAN +" + this.props.formData.roomCount + " OTEL SAYISI"} value="unCertificateUpRoomCount " />
      // 		<ExcelColumn label={"BELGE ALAMAYAN -" + this.props.formData.roomCount + " OTEL SAYISI"} value="unCertificateDownRoomCount" />

      // 		{/* <ExcelColumn
      // 			label="Bölge"
      // 			value={(col: any) =>
      // 				""
      // 			}
      // 		/> */}

      // 	</ExcelSheet>

      // </ExcelFile>
      <>
        {this.state.download && (
          <Label style={{ marginLeft: "10px", marginTop: "27px" }}>
            Rapor Oluşturuluyor...
          </Label>
        )}
      </>
    );
  }

  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="İl bazlı otel sayısı raporu"
          subTitle="İl bazlı otel sayısı raporunu bu sayfada alabilirsiniz"
          backUrl="/"
          showBack
          history={this.props.history}
        />
        <div className="form-groups row">
          <aside className="col-md-12 row" style={{ padding: 0 }}>
            <div className="form-group col-md-6">
              <div className="form-wrapper">
                <PrimaryButton
                  text="Bütün şehirler için Getir"
                  className="custom-button"
                  onClick={() => this.getReport(0)}
                  styles={{
                    root: {
                      fontSize: "11px",
                      marginTop: "27px",
                      height: "30px",
                    },
                  }}
                />
                <div className="item row">
                  <ComboBox
                    label="Rapor almak istediğiniz şehri seçin"
                    allowFreeform={true}
                    autoComplete="on"
                    multiSelect={false}
                    options={(
                      this.props.formData.GroupList?.dataList || ([] as any)
                    ).map((item: any) => ({
                      key: item.CityId,
                      text: item.CityName,
                    }))}
                    onChange={(event: any, option: any) =>
                      this.props.setFormData("cityId", option.key)
                    }
                    selectedKey={this.props.formData.cityId}
                  />
                </div>
                <div className="item row">
                  <TextField
                    placeholder="Tesis Oda Sayısı"
                    value={this.props.formData.roomCount || ""}
                    label="Tesis Oda Sayısı"
                    className="custom-textfield border"
                    onChange={(event: any) =>
                      this.props.setFormData("roomCount", event.target.value)
                    }
                  />
                </div>
                <div className="item row">
                  <label
                    style={{
                      float: "left",
                      width: "100%",
                      fontSize: "20px",
                      marginTop: "7px",
                      marginBottom: "15px",
                    }}
                  >
                    Raporda istediğiniz alanları seçiniz
                  </label>
                  {this.state.selectableFields.map((x) => (
                    <div
                      style={{ float: "left", width: "48%", marginRight: "2%" }}
                    >
                      <div style={{ marginBottom: "15px" }}>
                        <Checkbox
                          label={x.text}
                          checked={this.getBool(x)}
                          onChange={() => (
                            this.state.selectedFields.length > 0
                              ? this.state.selectedFields.filter(
                                (y) => y.field === x.field
                              ).length > 0
                                ? this.setState({
                                  ...this.state,
                                  selectedFields: this.state.selectedFields.filter(
                                    (y) => y.field !== x.field
                                  ),
                                })
                                : this.setState({
                                  ...this.state,
                                  selectedFields: [
                                    ...this.state.selectedFields,
                                    x,
                                  ],
                                })
                              : this.setState({
                                ...this.state,
                                selectedFields: [
                                  ...this.state.selectedFields,
                                  x,
                                ],
                              })
                          )}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <PrimaryButton
                  text="Rapor Oluştur"
                  className="custom-button"
                  onClick={() => this.getReport()}
                  styles={{
                    root: {
                      fontSize: "11px",
                      marginTop: "27px",
                      height: "30px",
                    },
                  }}
                />
                {this.rederexcel()}
              </div>
            </div>
          </aside>
        </div>
      </div>
    );
  }
  getBool(x: any) {
    return this.state.selectedFields.filter((y: any) => y.field === x.field)
      .length > 0
      ? true
      : false;
  }
  getReport(cityId = this.props.formData.cityId) {
    if (this.props.formData.roomCount !== "") {
      const request: any = {
        cityId,
        roomCount: this.props.formData.roomCount,
      };

      castThunkAction(this.props.GetHotelCountByCity(request)).then(
        (x: any) => {
          this.setState({ excel: true, download: false });
        }
      );
    } else {
      alert("Lütfen referans oda sayısı girin");
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
