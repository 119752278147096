import { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { History } from "history";
import { toggleConfirmDialog } from "src/store/system/actions";
import cookie from "react-cookies";
import { getAllM } from "src/store/thunksCity";

import { GetHotelCountByCity } from "src/store/thunkStatistic";
import { setFormData } from "src/store/form/actions";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import {
  ComboBox,
  TextField,
  Checkbox,
  PrimaryButton,
} from "office-ui-fabric-react";
import { castThunkAction } from "src/helpers/casting";
import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";

let selectableFieldsConst = [
  { field: "totalHotelCount", text: "TOPLAM TESİS SAYISI" },
  { field: "upRoomCount", text: "+X ODALI" },
  { field: "downRoomCount", text: "-X ODALI" },
  { field: "certificateGivenCount", text: "BELGE ALAN" },
  { field: "certificateProcessCount", text: "BELGE MÜRACATI" },
  { field: "totalAppealCount", text: "TOPLAM BAŞVURU" },
  { field: "certificateGivenUpRoomCount", text: "BELGE ALAN +X ODA" },
  { field: "certificateGivenDownRoomCount", text: "BELGE ALAN -X ODA" },
  { field: "unCertificateUpRoomCount", text: "BELGE ALAMAYAN +X OTEL SAYISI" },
  {
    field: "unCertificateDownRoomCount",
    text: "BELGE ALAMAYAN -X OTEL SAYISI",
  },
  {
    field: "municipalityCertificateCount",
    text: "BELEDİYE BELGELİ OTEL SAYISI",
  },
  { field: "ministryCertificateCount", text: "KTB BELGELİ OTEL SAYISI" },
  {
    field: "municipalityUpRoomCount",
    text: "+X ODA BELEDİYE BELGELİ OTEL SAYISI",
  },
  {
    field: "municipalityDownRoomCount",
    text: "-X ODA BELEDİYE BELGELİ OTEL SAYISI",
  },
  { field: "ministryUpRoomCount", text: "+X ODA KTB BELGELİ OTEL SAYISI" },
  { field: "ministryDownRoomCount", text: "-X ODA KTB BELGELİ OTEL SAYISI" },
  {
    field: "ministryCertificateGivenCount",
    text: "KTB BELGELİ BELGE VERİLEN OTEL SAYISI",
  },
  {
    field: "ministryCertificateProcessCount",
    text: "KTB BELGELİ BELGELENDİRME SÜRECİNDEKİ OTEL SAYISI",
  },
  {
    field: "municipalityCertificateGivenCount",
    text: "BELEDİYE BELGELİ BELGE VERİLEN OTEL SAYISI",
  },
  {
    field: "municipalityCertificateProcessCount",
    text: "BELEDİYE BELGELİ BELGELENDİRME SÜRECİNDEKİ OTEL SAYISI",
  },
  {
    field: "ministryCertificateGivenUpRoomCount",
    text: "KTB BELGE ALAN +X ODA",
  },
  {
    field: "ministryCertificateGivenDownRoomCount",
    text: "KTB BELGE ALAN -X ODA",
  },
  {
    field: "ministryUnCertificateUpRoomCount",
    text: "KTB BELGE ALAMAYAN +X OTEL SAYISI",
  },
  {
    field: "ministryUnCertificateDownRoomCount",
    text: "KTB BELGE ALAMAYAN -X OTEL SAYISI",
  },
  {
    field: "municipalityCertificateGivenUpRoomCount",
    text: "BELEDİYE BELGE ALAN +X ODA",
  },
  {
    field: "municipalityCertificateGivenDownRoomCount",
    text: "BELEDİYE BELGE ALAN -X ODA",
  },
  {
    field: "municipalityUnCertificateUpRoomCount",
    text: "BELEDİYE BELGE ALAMAYAN +X OTEL SAYISI",
  },
  {
    field: "municipalityUnCertificateDownRoomCount",
    text: "BELEDİYE BELGE ALAMAYAN -X OTEL SAYISI",
  },
];

let usertype: any = 0;
interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        toggleConfirmDialog,
        getAllM,
        GetHotelCountByCity,
        setFormData,
      },
      dispatch
    ),
  };
}

type ProvinceBasedReportProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  pagerTotal: number;
  excel: boolean;
  download: boolean;
  selectableFields: any[];
  selectedFields: any[];
}

class List extends Component<ProvinceBasedReportProps, OwnState> {
  state = {
    filterData: {
      searchKey: "",
      isRead: null,
      orderField: "ProcessDate",
      isDescending: true,
    },
    pagerCurrent: 0,
    excel: false,
    download: false,
    pagerTotal: 0,
    selectableFields: selectableFieldsConst,
    selectedFields: selectableFieldsConst,
  };
  chart: am4charts.XYChart;

  UNSAFE_componentWillMount() {
    usertype = cookie.load("systemUserInfo");
    if (usertype === "2") {
      window.location.href = "/";
    }
    const request2: CityGetAllRequestModel = {
      selectItemList: ["CityId", "CityName"],
      isDescending: false,
      orderField: "CityName",
      criteriaItemList: [
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
    };
    this.props.getAllM(request2);
    this.props.setFormData("roomCount", 50);

    if (this.chart) {
      this.chart.dispose();
    }
  }
  getBool(x: any) {
    return this.state.selectedFields.filter((y: any) => y.field === x.field)
      .length > 0
      ? true
      : false;
  }
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Grafik"
          subTitle="Grafik çıktılarını bu sayfada alabilirsiniz"
          backUrl="/"
          showBack
          history={this.props.history}
        />
        <div className="form-groups row">
          <aside className="col-md-12 row" style={{ padding: 0 }}>
            <div className="form-group col-md-12">
              <div className="form-wrapper">
                <div className="item row">
                  <ComboBox
                    label="Rapor almak istediğiniz şehri seçin"
                    allowFreeform={true}
                    autoComplete="on"
                    multiSelect={false}
                    options={(
                      this.props.formData.GroupList?.dataList || ([] as any)
                    ).map((item: any) => ({
                      key: item.CityId,
                      text: item.CityName,
                    }))}
                    onChange={(event: any, option: any) =>
                      this.props.setFormData("cityId", option.key)
                    }
                    selectedKey={this.props.formData.cityId}
                  />
                </div>
                <div className="item row">
                  <TextField
                    placeholder="Tesis Oda Sayısı"
                    value={this.props.formData.roomCount || ""}
                    label="Tesis Oda Sayısı"
                    className="custom-textfield border"
                    onChange={(event: any) =>
                      this.props.setFormData("roomCount", event.target.value)
                    }
                  />
                </div>
                <div className="item row">
                  <PrimaryButton
                    text="Tümünü Seç"
                    className="custom-button"
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        selectedFields: selectableFieldsConst,
                      })
                    }
                    style={{ marginLeft: "15px" }}
                    styles={{
                      root: {
                        fontSize: "11px",
                        marginTop: "27px",
                        height: "30px",
                      },
                    }}
                  />
                  <PrimaryButton
                    text="Tümünü Kaldır"
                    className="custom-button"
                    onClick={() =>
                      this.setState({ ...this.state, selectedFields: [] })
                    }
                    style={{ marginLeft: "15px" }}
                    styles={{
                      root: {
                        fontSize: "11px",
                        marginTop: "27px",
                        height: "30px",
                      },
                    }}
                  />
                  <label
                    style={{
                      float: "left",
                      width: "100%",
                      fontSize: "20px",
                      marginTop: "7px",
                      marginBottom: "15px",
                    }}
                  >
                    Raporda istediğiniz alanları seçiniz
                  </label>
                  {this.state.selectableFields.map((x) => (
                    <div
                      style={{ float: "left", width: "48%", marginRight: "2%" }}
                    >
                      <div style={{ marginBottom: "15px" }}>
                        <Checkbox
                          label={x.text}
                          checked={this.getBool(x)}
                          onChange={() => (
                            this.state.selectedFields.length > 0
                              ? this.state.selectedFields.filter(
                                (y) => y.field === x.field
                              ).length > 0
                                ? this.setState({
                                  ...this.state,
                                  selectedFields: this.state.selectedFields.filter(
                                    (y) => y.field !== x.field
                                  ),
                                })
                                : this.setState({
                                  ...this.state,
                                  selectedFields: [
                                    ...this.state.selectedFields,
                                    x,
                                  ],
                                })
                              : this.setState({
                                ...this.state,
                                selectedFields: [
                                  ...this.state.selectedFields,
                                  x,
                                ],
                              })
                          )}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <PrimaryButton
                  text="Rapor Oluştur"
                  className="custom-button"
                  onClick={() => this.getReport()}
                  styles={{
                    root: {
                      fontSize: "11px",
                      marginTop: "27px",
                      height: "30px",
                    },
                  }}
                />
              </div>
            </div>
            <div className="form-group col-md-12">
              <div className="form-wrapper">
                <div
                  id="chartdiv"
                  style={{ width: "100%", height: "1000px" }}
                ></div>
              </div>
            </div>
          </aside>
        </div>
      </div>
    );
  }

  getReport(): void {
    if (
      this.props.formData.cityId &&
      this.props.formData.roomCount &&
      this.props.formData.cityId !== 0 &&
      this.props.formData.roomCount !== ""
    ) {
      const request: any = {
        cityId: this.props.formData.cityId,
        roomCount: this.props.formData.roomCount,
      };

      castThunkAction(this.props.GetHotelCountByCity(request)).then(
        (x: any) => {
          let chart = am4core.create("chartdiv", am4charts.XYChart);
          // Add data
          chart.data = this.props.formData?.HotelCountByCity?.districts;
          chart.exporting.menu = new am4core.ExportMenu();
          chart.exporting.menu.align = "left";
          chart.exporting.menu.verticalAlign = "top";
          // Create axes
          var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
          categoryAxis.dataFields.category = "districtName";
          categoryAxis.title.text = "İlçe";
          categoryAxis.renderer.grid.template.location = 0;
          categoryAxis.renderer.minGridDistance = 20;

          var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
          valueAxis.title.text = "Sayı";

          for (
            let index = 0;
            index < this.state.selectedFields.length;
            index++
          ) {
            // Create series
            window["value" + index] = chart.series.push(
              new am4charts.ColumnSeries()
            );
            window[
              "value" + index
            ].dataFields.valueX = this.state.selectedFields[index].field;
            window["value" + index].dataFields.categoryY = "districtName";
            window["value" + index].name = this.state.selectedFields[
              index
            ].text;
            window["value" + index].tooltipText = "{name}: [bold]{valueX}[/]";
            // This has no effect
            // series.stacked = true;
          }

          // Add cursor
          chart.cursor = new am4charts.XYCursor();

          // Add legend
          chart.legend = new am4charts.Legend();
        }
      );
    } else {
      alert("Lütfen şehir seçin ve referans oda sayısı girin");
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
