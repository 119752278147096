import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Header from "../../components/Header";
import LeftPanel from "../../components/LeftPanel";
import { EditCertificateStatusTypep } from "../../components/CertificateStatusType";

export default class EditCertificateStatusTypeRoute extends React.Component<
  RouteComponentProps<any>
> {
  render() {
    return (
      <React.Fragment>
        <Header history={this.props.history} />
        <div className="row">
          <LeftPanel history={this.props.history} />
          <EditCertificateStatusTypep
            history={this.props.history}
            CertificateStatusTypeId={
              this.props.match.params.CertificateStatusTypeId
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
