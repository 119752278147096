// const baseUrl: string =
//   process.env.NODE_ENV === "development"
//     ? "http://167.172.107.160:1454/api"
//     : "http://167.172.107.160:1454/api";
// export const qrUrl =
//   process.env.NODE_ENV === "development"
//     ? "http://167.172.107.160:1454/"
//     : "http://167.172.107.160:1454/";

export const baseUrl = (window as any)._env_.REACT_APP_API;
/* window.location.host !== "sertifika.tga.gov.tr"
    ? "http://test-api.tga-certification.glomil.com/api"
    : "https://api.tga.gov.tr/api";*/

export const qrUrl = (window as any)._env_.REACT_APP_QR_URL;

/*
  window.location.host !== "sertifika.tga.gov.tr"
    ? "http://test-api.tga-certification.glomil.com/"
    : "https://api.tga.gov.tr/";
    */

// const baseUrl: string = process.env.NODE_ENV === "development" ? "http://167.172.107.160:1454/api" : "http://167.172.107.160:1454/api"
// export const qrUrl = process.env.NODE_ENV === "development" ? "http://167.172.107.160:1454/" : "http://167.172.107.160:1454/"

// const baseUrl: string = process.env.NODE_ENV === "development" ? "http://212.174.179.185:5001/api" : "http://212.174.179.185:5001/api"
// export const qrUrl = process.env.NODE_ENV === "development" ? "http://212.174.179.185:5001" : "http://212.174.179.185:5001"

const prefixes = {
  systemUser: baseUrl + "/SystemUser/",
  systemUserGroup: baseUrl + "/SystemUserGroup/",
  city: baseUrl + "/City/",
  fair: baseUrl + "/Fair/",
  county: baseUrl + "/District/",
  systemUserAuthorization: baseUrl + "/SystemUserAuthorization/",
  certificateType: baseUrl + "/CertificateType/",
  mainCategory: baseUrl + "/MainCategory/",
  subCategory: baseUrl + "/SubCategory/",
  certificateAppeal: baseUrl + "/CertificateAppeal/",
  certificateStatusType: baseUrl + "/CertificateStatusType/",
  facilityType: baseUrl + "/FacilityType/",
  company: baseUrl + "/Company/",
  attachment: baseUrl + "/Attachment/",
  Layover: baseUrl + "/LayoverCertificate/",
  Restaurant: baseUrl + "/RestaurantCertificate/",
  Transport: baseUrl + "/TransportCertificate/",
  Vehicle: baseUrl + "/Transport/",
  LayoverSubCategory: baseUrl + "/LayoverSubCategory/",
  LayoverFacilityType: baseUrl + "/LayoverFacilityType/",
  RestaurantSubCategory: baseUrl + "/RestaurantSubCategory/",
  RestaurantFacilityType: baseUrl + "/RestaurantFacilityType",
  TransportSubCategory: baseUrl + "/TransportSubCategory/",
  TransportFacilityType: baseUrl + "/TransportFacilityType/",
  VehicleDocumentType: baseUrl + "/VehicleDocumentType/",
  VehicleType: baseUrl + "/VehicleType/",
  Airport: baseUrl + "/Airport/",
  SystemSetting: baseUrl + "/SystemSetting/",
  Notification: baseUrl + "/Notification/",
  Hotel: baseUrl + "/Hotel/",
  Hospital: baseUrl + "/Hospital/",
  Statistic: baseUrl + "/Statistic/",
  AdditionalAudit: baseUrl + "/AdditionalAudit/",
  hotelCertification: baseUrl + "/HotelCertificate/",
  denunciation: baseUrl + "/Denunciation/",
  CongressFacilityType: baseUrl + "/CongressFacilityType/",
  CongressSubCategory: baseUrl + "/CongressSubCategory/",
  Congress: baseUrl + "/Congress/",
  CongressCertification: baseUrl + "/CongressCertificate/",
  SeaTourism: baseUrl + "/SeaTourism/",
  SeaTourismCertification: baseUrl + "/SeaTourismCertificate/",
  SeaTourismFacilityType: baseUrl + "/SeaTourismFacilityType/",
  SeaTourismSubCategory: baseUrl + "/SeaTourismSubCategory/",
  WinterTourism: baseUrl + "/WinterTourism/",
  WinterTourismCertification: baseUrl + "/WinterTourismCertificate/",
  WinterTourismFacilityType: baseUrl + "/WinterTourismFacilityType/",
  WinterTourismSubCategory: baseUrl + "/WinterTourismSubCategory/",
  ThemedFacility: baseUrl + "/ThemedFacility/",
  ThemedFacilityCertification: baseUrl + "/ThemedFacilityCertificate/",
  ThemedFacilityFacilityType: baseUrl + "/ThemedFacilityFacilityType/",
  ThemedFacilitySubCategory: baseUrl + "/ThemedFacilitySubCategory/",
  Additional: baseUrl + "/AdditionalAudit/",
  MergedCertificate: baseUrl + "/MergedCertificate/",
};

export const apiConfig = {
  prefixes,
};
