import * as React from "react";
import { SearchBox } from "office-ui-fabric-react";
import { RootState } from "../store/reducer";
import { History } from "history";
import { Dispatch, bindActionCreators } from "redux";
import { setLeftPanel } from "../store/system/actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { panels, PanelOptions } from "src/utils/constants";
import cookie from "react-cookies";
import {
  ChangeTokenEncryptor,
  OpenOrCloseSystemForCompanyUser,
  companyUserStatus,
} from "src/store/thunksSystemSetting";
import { castThunkAction } from "src/helpers/casting";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
  return {
    language: state.system.language,
    leftPanel: state.system.leftPanel as PanelOptions,
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setLeftPanel,
      ChangeTokenEncryptor,
      OpenOrCloseSystemForCompanyUser,
      companyUserStatus,
    },
    dispatch
  );
}

interface LeftPanelState {
  searchValue: string;
  searchValue2: string;
  searchValue3: string;
  searchValueOtherPages: string;
  leftp: string;
  companyStatus: boolean;
}

type LeftPanelProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

class LeftPanel extends React.Component<LeftPanelProps, LeftPanelState> {
  containerRefs: (HTMLDivElement | null)[] = [];

  constructor(props: LeftPanelProps) {
    super(props);

    this.state = {
      searchValue: "",
      searchValue2: "",
      searchValue3: "",
      searchValueOtherPages: "",
      leftp: "",
      companyStatus: false,
    };
  }

  UNSAFE_componentWillMount() {
    castThunkAction<boolean>(this.props.companyUserStatus()).then((status) =>
      this.setState({ companyStatus: status })
    );
  }

  renderSystemPages() {
    const usertype = cookie.load("systemUserInfo");
    const findList =
      panels.find((item) => item.name === "system-pagess")?.children || [];
    let filtered2 =
      this.state.searchValue.length > 0
        ? findList.filter(
            (x) => x.text.toLowerCase().indexOf(this.state.searchValue) >= 0
          )
        : findList;
    return (
      <div className="panel-inner">
        <div className="panel-head row">
          <div className="title">
            Sistem Sayfaları
            <span className="sub-title">{findList.length || 0} Öğe</span>
          </div>
          <span
            className="close"
            onClick={() => {
              this.setState({ leftp: "" });
              this.props.setLeftPanel("");
            }}
          >
            <i className="icon-close"></i>
          </span>
        </div>
        <div className="panel-search">
          <SearchBox
            className="custom-searchbox"
            placeholder="Search"
            value={this.state.searchValue}
            onChange={(e: any) =>
              this.setState({
                searchValue: e?.target.value.toLowerCase() || "",
              })
            }
          />
        </div>
        <div className="panel-list">
          {filtered2.map((item, index) => {
            return (
              ((item.user === 1 && usertype === "1") || item.user !== 1) && (
                <div className="item" key={item.name}>
                  <Link to={item.url ? item.url : "/"}>
                    <i className="fas fa-file"></i>
                    {item.text}
                  </Link>
                </div>
              )
            );
          })}
        </div>
      </div>
    );
  }

  renderAdressPages() {
    const findList =
      panels.find((item) => item.name === "adres-pages")?.children || [];
    let filtered5 =
      this.state.searchValue2.length > 0
        ? findList.filter(
            (x) => x.text.toLowerCase().indexOf(this.state.searchValue2) >= 0
          )
        : findList;
    return (
      <div className="panel-inner">
        <div className="panel-head row">
          <div className="title">
            Adres Sayfaları
            <span className="sub-title">{findList.length || 0} Öğe</span>
          </div>
          <span
            className="close"
            onClick={() => {
              this.setState({ leftp: "" });
              this.props.setLeftPanel("");
            }}
          >
            <i className="icon-close"></i>
          </span>
        </div>
        <div className="panel-search">
          <SearchBox
            className="custom-searchbox"
            placeholder="Search"
            value={this.state.searchValue2}
            onChange={(e: any) =>
              this.setState({
                searchValue2: e?.target.value.toLowerCase() || "",
              })
            }
          />
        </div>
        <div className="panel-list">
          {filtered5.map((item, index) => {
            return (
              <div className="item" key={item.name}>
                <Link to={item.url ? item.url : "/"}>
                  <i className="fas fa-file"></i>
                  {item.text}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderOtherPages() {
    const findList =
      panels.find((item) => item.name === "other-pages")?.children || [];
    let filtered5 =
      this.state.searchValueOtherPages.length > 0
        ? findList.filter(
            (x) =>
              x.text.toLowerCase().indexOf(this.state.searchValueOtherPages) >=
              0
          )
        : findList;
    return (
      <div className="panel-inner">
        <div className="panel-head row">
          <div className="title">
            Diğer Yönetim İşlemleri
            <span className="sub-title">{findList.length || 0} Öğe</span>
          </div>
          <span
            className="close"
            onClick={() => {
              this.setState({ leftp: "" });
              this.props.setLeftPanel("");
            }}
          >
            <i className="icon-close"></i>
          </span>
        </div>
        <div className="panel-search">
          <SearchBox
            className="custom-searchbox"
            placeholder="Search"
            value={this.state.searchValueOtherPages}
            onChange={(e: any) =>
              this.setState({
                searchValueOtherPages: e?.target.value.toLowerCase() || "",
              })
            }
          />
        </div>
        <div className="panel-list">
          {filtered5.map((item, index) => {
            return (
              <div className="item" key={item.name}>
                <Link to={item.url ? item.url : "/"}>
                  <i className="fas fa-file"></i>
                  {item.text}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  rendercertificateHotelPages() {
    const usertype = cookie.load("systemUserInfo");
    const findList =
      panels.find((item) => item.name === "hotel-page")?.children || [];
    let filtered4 =
      this.state.searchValue3.length > 0
        ? findList.filter(
            (x) => x.text.toLowerCase().indexOf(this.state.searchValue3) >= 0
          )
        : findList;
    return (
      <div className="panel-inner">
        <div className="panel-head row">
          <div className="title">
            Tesis / Araç Yönetim İşlemleri
            <span className="sub-title">{findList.length || 0} Öğe</span>
          </div>
          <span
            className="close"
            onClick={() => {
              this.setState({ leftp: "" });
              this.props.setLeftPanel("");
            }}
          >
            <i className="icon-close"></i>
          </span>
        </div>
        <div className="panel-search">
          <SearchBox
            className="custom-searchbox"
            placeholder="Search"
            value={this.state.searchValue3}
            onChange={(e: any) =>
              this.setState({
                searchValue3: e?.target.value.toLowerCase() || "",
              })
            }
          />
        </div>
        <div className="panel-list">
          {filtered4.map((item, index) => {
            return (
              ((usertype === "2" && item.user === 2) || usertype !== "2") && (
                <div className="item" key={item.name}>
                  <Link to={item.url ? item.url : "/"}>
                    <i className="fas fa-file"></i>
                    {item.text}
                  </Link>
                </div>
              )
            );
          })}
        </div>
      </div>
    );
  }

  rendercertificatePages() {
    const usertype = cookie.load("systemUserInfo");
    const findList =
      panels.find((item) => item.name === "certificate-page")?.children || [];
    let filtered3 =
      this.state.searchValue3.length > 0
        ? findList.filter(
            (x) => x.text.toLowerCase().indexOf(this.state.searchValue3) >= 0
          )
        : findList;
    return (
      <div className="panel-inner">
        <div className="panel-head row">
          <div className="title">
            Sertifika Sayfaları
            <span className="sub-title">{findList.length || 0} Öğe</span>
          </div>
          <span
            className="close"
            onClick={() => {
              this.setState({ leftp: "" });
              this.props.setLeftPanel("");
            }}
          >
            <i className="icon-close"></i>
          </span>
        </div>
        <div className="panel-search">
          <SearchBox
            className="custom-searchbox"
            placeholder="Search"
            value={this.state.searchValue3}
            onChange={(e: any) =>
              this.setState({
                searchValue3: e?.target.value.toLowerCase() || "",
              })
            }
          />
        </div>
        <div className="panel-list">
          {filtered3.map((item, index) => {
            return (
              ((usertype === "2" && item.user === 2) || usertype !== "2") && (
                <div className="item" key={item.name}>
                  <Link to={item.url ? item.url : "/"}>
                    <i className="fas fa-file"></i>
                    {item.text}
                  </Link>
                </div>
              )
            );
          })}
        </div>
      </div>
    );
  }

  renderPanel() {
    const usertype = cookie.load("systemUserInfo");
    switch (this.state.leftp) {
      case "system-pagess":
        return usertype === "2" ? null : this.renderSystemPages();
        break;
      case "adres-pages":
        return usertype === "2" ? null : this.renderAdressPages();
        break;
      case "certificate-page":
        return this.rendercertificatePages();
        break;

      case "hotel-page":
        return this.rendercertificateHotelPages();
        break;
      case "other-pages":
        return this.renderOtherPages();
        break;

      default:
        return null;
    }
  }

  render() {
    const { companyStatus } = this.state;
    const usertype = cookie.load("systemUserInfo");
    return (
      <div className="left-panel">
        <div className="left-panel-wrapper row">
          <div className="panel-side">
            {usertype !== "4" &&
              panels.map((p, index) => {
                return (
                  ((usertype === "2" && p.user === 2) || usertype !== "2") && (
                    <div
                      key={`${p.name}_${index}`}
                      className={
                        "item " +
                        (this.props.leftPanel && this.props.leftPanel === p.name
                          ? "active tooltip-wrapper"
                          : "tooltip-wrapper")
                      }
                      onClick={() =>
                        p.url
                          ? this.props.history.push(p.url)
                          : (this.props.setLeftPanel(p.name),
                            this.setState({ leftp: p.name }))
                      }
                    >
                      <i className={p.icon} />
                      {<div className="tooltip">{p.text}</div>}
                    </div>
                  )
                );
              })}
            {/* {usertype === "4" && (
              <>
                <div
                  onClick={() => (window.location.href = "/")}
                  className="item tooltip-wrapper"
                >
                  <i className="icon-anasayfa"></i>
                  <div className="tooltip">Anasayfa</div>
                </div>
                <div
                  className="item tooltip-wrapper"
                  onClick={() => (window.location.href = "/denunciation")}
                >
                  <i className="fa fa-ban"></i>
                  <div className="tooltip">Şikayet Yönetimi</div>
                </div>
              </>
            )} */}
            {usertype === "1" && (
              <>
                <a
                  onClick={(x) => this.setSystemSetting(true, "", "")}
                  className={"item tooltip-wrapper "}
                  style={{
                    backgroundColor: companyStatus ? "red" : "green",
                  }}
                >
                  FK
                  <div className="tooltip">
                    {companyStatus
                      ? "Firma Kullanıcılarına Aç"
                      : "Firma Kullanıcılarına Kapat"}
                  </div>
                </a>
                <a
                  onClick={(x) =>
                    this.setSystemSetting(
                      false,
                      "Token değiştirildi.",
                      "Token değiştirilirken hata oluştu. "
                    )
                  }
                  className="item tooltip-wrapper"
                >
                  T<div className="tooltip">Token Düşür</div>
                </a>
              </>
            )}
          </div>
          {this.renderPanel()}
        </div>
      </div>
    );
  }
  setSystemSetting(type: any, successMessage: any, errorMessage: any): any {
    if (type) {
      castThunkAction(
        this.props.OpenOrCloseSystemForCompanyUser(
          {},
          successMessage,
          errorMessage
        )
      ).then((x: any) => {
        if (x) {
          castThunkAction<boolean>(
            this.props.companyUserStatus()
          ).then((status) => this.setState({ companyStatus: status }));
        }
      });
    } else {
      castThunkAction(this.props.ChangeTokenEncryptor({})).then((x: any) => {
        setTimeout(() => {
          window.location.href = "/";
        }, 1);
      });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftPanel);
