import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import SeaTourism from "src/services/SeaTourism/SeaTourismService";
import {
  SeaTourismChangeStatusRequestModel,
  GetSeaTourismListModel,
  SeaTourismGetByIdRequestModel,
  SeaTourismUpdateRequestModel,
  SeaTourismSaveRequestModel,
  GetSeaTourismCertificateListBySeaTourismModel,
} from "src/services/SeaTourism/RequestModel";
import { MessageType } from "./system/types";
import { errorMessageSaveOrUpdate } from "src/helpers/functions";
import { formatDate } from "src/helpers/converts";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllMA(
  request: GetSeaTourismListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const SeaTourismService = new SeaTourism();
    const result = await SeaTourismService.getAllSeaTourism(request);
    dispatch(setFormData("SeaTourismList", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}
export function getAllForPager(
  request: GetSeaTourismListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismService = new SeaTourism();
    const result = await SeaTourismService.getAllSeaTourism(request);
    dispatch(setFormData("dataList", result.dataList));
    dispatch(setFormData("pageCount", result.pageCount));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAllSeaTourism(
  request: GetSeaTourismListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismService = new SeaTourism();
    const result = await SeaTourismService.getAllSeaTourism(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAllSeaTourismForModal(
  request: GetSeaTourismListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismService = new SeaTourism();
    const result = await SeaTourismService.getAllSeaTourism(request);
    dispatch(setFormData("SeaTourismListForModal", result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}
export function getAllSeaTourismForExcel(
  request: GetSeaTourismListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismService = new SeaTourism();
    const excelName = "DenizTurizmTesisiArac" + formatDate();
    await SeaTourismService.excelDownload(request, excelName);
    dispatch(toggleLoader());

    return false;
  };
}

export function getAllSertificationMA(
  request: GetSeaTourismCertificateListBySeaTourismModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const HotelService = new SeaTourism();
    const result = await HotelService.GetSeaTourismCertificateListBySeaTourism(
      request
    );
    dispatch(setFormData("SeaTourismSerticationList", result));
    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: SeaTourismSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismService = new SeaTourism();
    const result = await SeaTourismService.save(request);

    dispatch(toggleLoader());
    if (result.success) {
      dispatch(initialFormData(result));
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          ""
        )
      );
      return result;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}
export function update(
  request: SeaTourismUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismService = new SeaTourism();
    const result = await SeaTourismService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          ""
        )
      );
      return true;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}

export function getById(
  request: SeaTourismGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismService = new SeaTourism();
    const result = await SeaTourismService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getByIdForSeaForCert(
  request: SeaTourismGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismService = new SeaTourism();
    const result = await SeaTourismService.getById(request);
    // // bunu kullanırsan form data baştan olşuturulur eskiyi kaybedersin
    // dispatch(initialFormData(result.data));
    //bunu kullanırsan olan formdataya parametre eklenir okey? ok
    dispatch(setFormData("findSeaByQSId", result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: SeaTourismChangeStatusRequestModel,
  isDelete: boolean = false
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismService = new SeaTourism();
    const result = await SeaTourismService.changeStatus(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(initialFormData(result));
      dispatch(
        showMessage(
          isDelete
            ? "Silme işlemi başarıyla değiştirildi."
            : "Kayıt durumu işlemi başarıyla değiştirildi.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          "",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          ""
        )
      );
      return false;
    }
  };
}
