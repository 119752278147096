import { HttpClient } from "src/library/HttpClient";
import {
  FairGetAllRequestModel,
  FairSaveRequestModel,
  FairUpdateRequestModel,
  FairGetByIdRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class Fair {
  async getAll(request: FairGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.fair + "GetAll",
      { ...request, token }
    );
  }
  async save(request: FairSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.fair + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: FairUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.fair + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(request: FairGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.fair + "GetById",
      { ...request, token }
    );
  }
  async del(request: any): Promise<any> {
    //Request degıstır
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.fair + "Delete",
      { ...request, token }
    );
  }
}
