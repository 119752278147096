import { HttpClient } from "src/library/HttpClient";
import {
  NotificationGetAllRequestModel,
  NotificationUpdateRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class Notification {
  async getAll(request: NotificationGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Notification + "GetNotificationList",
      { ...request, token }
    );
  }

  async setRead(request: NotificationUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Notification + "SetNotificationRead",
      { ...request, token }
    );
  }
}
