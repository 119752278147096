import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import SeaTourismSubCategory from "../services/SeaTourismSubCategory/SeaTourismSubCategoryService";
import {
  SeaTourismSubCategoryGetAllRequestModel,
  SeaTourismSubCategoryChangeStatusRequestModel,
  SeaTourismSubCategoryGetByIdRequestModel,
  SeaTourismSubCategoryUpdateRequestModel,
  SeaTourismSubCategorySaveRequestModel,
} from "../services/SeaTourismSubCategory/RequestModel";
import { MessageType } from "./system/types";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllLSU(
  request: SeaTourismSubCategoryGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const SeaTourismSubCategoryService = new SeaTourismSubCategory();
    const result = await SeaTourismSubCategoryService.getAll(request);
    dispatch(setFormData("GroupListSU", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: SeaTourismSubCategoryGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismSubCategoryService = new SeaTourismSubCategory();
    const result = await SeaTourismSubCategoryService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: SeaTourismSubCategorySaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismSubCategoryService = new SeaTourismSubCategory();
    const result = await SeaTourismSubCategoryService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Alt Kategori Yönetimi"
        )
      );
      return result;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Alt Kategori Yönetimi"
        )
      );
      return false;
    }
  };
}
export function update(
  request: SeaTourismSubCategoryUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismSubCategoryService = new SeaTourismSubCategory();
    const result = await SeaTourismSubCategoryService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Alt Kategori Yönetimi"
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Alt Kategori Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getById(
  request: SeaTourismSubCategoryGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismSubCategoryService = new SeaTourismSubCategory();
    const result = await SeaTourismSubCategoryService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: SeaTourismSubCategoryChangeStatusRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const SeaTourismSubCategoryService = new SeaTourismSubCategory();
    const result = await SeaTourismSubCategoryService.changeStatus(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Alt Kategori Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Alt Kategori Yönetimi"
        )
      );
      return false;
    }
  };
}
