import { HttpClient } from "src/library/HttpClient";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class SystemSettingService {
  /** Login İşlemi */
  async ChangeTokenEncryptor(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SystemSetting + "ChangeTokenEncryptor",
      { ...request, token }
    );
  }
  //  Tüm Listeyi Getiri
  async OpenOrCloseSystemForCompanyUser(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SystemSetting + "OpenOrCloseSystemForCompanyUser",
      { ...request, token }
    );
  }
  async GetByKey(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.SystemSetting + "GetByKey",
      { ...request, token }
    );
  }
}
