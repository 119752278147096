import { Component } from "react";
import { History } from "history";

export type TitleRenderer = () => void;

export interface createdInfo {
  createdUser: string;
  createdDate: string;
  updatedUser: string;
  updatedDate: string;
}

export interface OwnProps {
  title?: string;
  subTitle: string;
  right?: any;
  showBack?: boolean;
  titleRenderer?: TitleRenderer;
  history?: History;
  backUrl?: string;
  createdInfo?: createdInfo;
  rightType?: "edit" | "create";
  onBack?: () => void;
}

class PageTitle extends Component<OwnProps> {
  getCreatedInfo() {
    const { createdInfo } = this.props;
    if (createdInfo) {
      return (
        <div className="createdInfo">
          {createdInfo.createdUser !== "" &&
          createdInfo.createdUser !== null ? (
            <span>Oluşturan : {createdInfo.createdUser}</span>
          ) : (
            ""
          )}
          {createdInfo.createdDate !== "" &&
          createdInfo.createdDate !== null ? (
            <span>Oluşturulma Tarihi : {createdInfo.createdDate}</span>
          ) : (
            ""
          )}
          {createdInfo.updatedUser !== "" &&
          createdInfo.updatedUser !== null ? (
            <span>Güncelleyen : {createdInfo.updatedUser}</span>
          ) : (
            ""
          )}
          {createdInfo.updatedDate !== "" &&
          createdInfo.updatedDate !== null ? (
            <span>Güncelleme Tarihi : {createdInfo.updatedDate}</span>
          ) : (
            ""
          )}
        </div>
      );
    } else {
      return "";
    }
  }
  render() {
    const { showBack, title, history, subTitle, backUrl } = this.props;
    return (
      <div className={showBack ? "page-title show-back row" : "page-title row"}>
        {showBack && (
          <button
            onClick={() =>
              this.props.onBack
                ? this.props.onBack()
                : backUrl
                ? history?.push(backUrl)
                : history?.goBack()
            }
            className="backButton"
          >
            <span className="line"></span>
            <span className="line"></span>
          </button>
        )}
        <div className="title">
          {this.props.titleRenderer ? this.props.titleRenderer() : title}
          <div className="text">
            {subTitle}
            {this.getCreatedInfo()}
          </div>
        </div>
        <div className={`button-${this.props.rightType}`}>
          {this.props.right}
        </div>
      </div>
    );
  }
}

export default PageTitle;
