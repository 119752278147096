export function castThunkAction<T>(val: any) {
  return val as Promise<T>;
}

export function CallCenterDisabledInput(userType: any) {
  let elements = ["input", "select", "checkbox", "span", "label", "a", "i"];

  if (userType === "5") {
    setTimeout(() => {
      for (
        let elementIndex = 0;
        elementIndex < elements.length;
        elementIndex++
      ) {
        const element = elements[elementIndex];
        let inputs: any = document.getElementsByTagName(element);
        for (let index = 0; index < inputs.length; index++) {
          const element = inputs[index];
          const el = document.createElement("span");
          el.innerHTML = "";
          el.classList.add("custom-dropdown-disable");
          element.setAttribute("disabled", "disabled");
        }
      }
      let dropdowns: any = document.getElementsByClassName(
        "ms-Dropdown-container"
      );
      for (let index = 0; index < dropdowns.length; index++) {
        const element = dropdowns[index];

        const el = document.createElement("span");
        el.innerHTML = "";
        el.classList.add("custom-dropdown-disable");
        element.setAttribute("disabled", "disabled");
        element.appendChild(el);
      }
      let buttons: any = document.getElementsByClassName("ms-Button");
      for (let index = 0; index < buttons.length; index++) {
        const element = buttons[index];

        const el = document.createElement("span");
        el.innerHTML = "";
        el.classList.add("custom-dropdown-disable");
        element.setAttribute("disabled", "disabled");
        element.appendChild(el);
      }
    }, 1000);
  }
}
