import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import WinterTourismCertification from "src/services/WinterTourismCertification/WinterTourismCertification";
import {
  WinterTourismCertificationGetAllRequestModel,
  WinterTourismCertificationChangeStatusRequestModel,
  WinterTourismCertificationGetByIdEndIdRequestModel,
  WinterTourismCertificationGetByIdRequestModel,
  WinterTourismCertificationUpdateRequestModel,
  WinterTourismCertificationSaveRequestModel,
  RemovePublish,
  WinterTourismCertificationUpdateBySequenceIdRequestModel,
} from "src/services/WinterTourismCertification/RequestModel";
import { MessageType } from "./system/types";
import { errorMessageSaveOrUpdate } from "src/helpers/functions";
import { ILeaveCertificationProcessRequest } from "src/services/BaseEnum";
import { formatDate } from "src/helpers/converts";

let cityRegions: any[] = [];

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllPage(
  request: WinterTourismCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const WinterTourismCertificationService = new WinterTourismCertification();
    const result = await WinterTourismCertificationService.getAll(request);
    dispatch(setFormData("excel", result));
    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: WinterTourismCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const WinterTourismCertificationService = new WinterTourismCertification();
    const result = await WinterTourismCertificationService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function excelDownload(
  request: WinterTourismCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismCertificationService = new WinterTourismCertification();
    const excelName = "KışTurizmTesisi" + formatDate();
    await WinterTourismCertificationService.excelDownload(request, excelName);
    dispatch(toggleLoader());

    return false;
  };
}

export function save(
  request: WinterTourismCertificationSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismCertificationService = new WinterTourismCertification();
    const result = await WinterTourismCertificationService.save(request);
    // dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return result;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}
export function update(
  request:
    | WinterTourismCertificationUpdateRequestModel
    | WinterTourismCertificationUpdateBySequenceIdRequestModel,
  sequence?: boolean
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const WinterTourismCertificationService = new WinterTourismCertification();
    const result = sequence
      ? await WinterTourismCertificationService.updateBySequenceId(
          request as WinterTourismCertificationUpdateBySequenceIdRequestModel
        )
      : await WinterTourismCertificationService.update(request);
    // dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}

export function getById(
  request: WinterTourismCertificationGetByIdRequestModel,
  sequence?: boolean
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismCertificationService = new WinterTourismCertification();
    const result = !!sequence
      ? await WinterTourismCertificationService.getBySequenceId(request)
      : await WinterTourismCertificationService.getById(request);

    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        initialFormData({
          ...result.data,
          intitialAppealDate: result.data.appealDate,
          initialAttachmentList: result.data.attachmentList,
          initialAttachmentCertificateList:
            result.data.attachmentCertificateList,
          initialAttachmentUnCertificateList:
            result.data.attachmentUnCertificateList,
        })
      );
      return true;
    }
    return false;
  };
}

export function getByEndId(
  request: WinterTourismCertificationGetByIdEndIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismCertificationService = new WinterTourismCertification();
    const result = await WinterTourismCertificationService.getByEndId(request);
    dispatch(
      initialFormData({
        ...result.data,
        initialAttachmentList: result.data.attachmentList,
        initialAttachmentCertificateList: result.data.attachmentCertificateList,
        initialAttachmentUnCertificateList:
          result.data.attachmentUnCertificateList,
      })
    );
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: WinterTourismCertificationChangeStatusRequestModel,
  isDelete: boolean = false
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismCertificationService = new WinterTourismCertification();
    const result = await WinterTourismCertificationService.changeStatus(
      request
    );
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(initialFormData(result));
      dispatch(
        showMessage(
          isDelete
            ? "Silme işlemi başarıyla değiştirildi."
            : "Kayıt durumu işlemi başarıyla değiştirildi.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getAllWinterTourismCertificationANDRestaurant(
  request: WinterTourismCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    cityRegions = require("../cityRegions.json");

    const WinterTourismCertificationService = new WinterTourismCertification();
    const resultWinterTourismCertification =
      await WinterTourismCertificationService.getAll(request);
    for (
      let index = 0;
      index < resultWinterTourismCertification.dataList.length;
      index++
    ) {
      resultWinterTourismCertification.dataList[index]["region"] =
        resultWinterTourismCertification.dataList[index].cityName &&
        resultWinterTourismCertification.dataList[index].cityName !== ""
          ? cityRegions.filter(
              (x: any) =>
                x.city ===
                resultWinterTourismCertification.dataList[index].cityName
            )[0]?.region
          : "";
      resultWinterTourismCertification.dataList[index]["categoryName"] =
        "KONGRE MERKEZİ";
      resultWinterTourismCertification.dataList[index]["categoryName_En"] =
        "CONGRESS CENTER";
    }
    dispatch(
      setFormData(
        "WinterTourismCertificationListForExcel",
        resultWinterTourismCertification
      )
    );

    dispatch(toggleLoader());
    if (resultWinterTourismCertification.success) {
      return true;
    }
    return false;
  };
}

export function getAllForPager(
  request: WinterTourismCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismCertificationService = new WinterTourismCertification();
    const result = await WinterTourismCertificationService.getAll(request);
    dispatch(setFormData("dataList", result.dataList));
    dispatch(setFormData("pageCount", result.pageCount));
    dispatch(setFormData("dataCount", result.dataCount));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function GetWinterTourismCertificationAndRestaurantCount(
  request: WinterTourismCertificationGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismCertificationService = new WinterTourismCertification();
    const result =
      await WinterTourismCertificationService.GetWinterTourismCertificationAndRestaurantCount(
        request
      );
    dispatch(
      setFormData("WinterTourismCertificationAndRestaurantCount", result)
    );
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function RemoveWebPublish(
  request: RemovePublish
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismCertificationService = new WinterTourismCertification();
    const result = await WinterTourismCertificationService.RemoveWebPublish(
      request
    );
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Sertifika Web Sitesinde Yayımdan Kaldırıldı",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}
export function RemoveQrPublish(
  request: RemovePublish
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismCertificationService = new WinterTourismCertification();
    const result = await WinterTourismCertificationService.RemoveQrPublish(
      request
    );
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "QR Yayımdan Kaldırıldı",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}

export function ChangeCompanyUser(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismService = new WinterTourismCertification();
    const result = await WinterTourismService.ChangeCompanyUser(request);
    //dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Ana Kategori Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Ana Kategori Yönetimi"
        )
      );
      return false;
    }
  };
}

export function leaveCertificationProcess(
  request: ILeaveCertificationProcessRequest
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismService = new WinterTourismCertification();
    const result = await WinterTourismService.leaveCertificationProcess(
      request
    );
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "İşleminiz başarı ile gerçekleşmiştir.",
          "İşlem Başarılı",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}
