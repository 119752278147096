import { HttpClient } from "src/library/HttpClient";
import {
  RestaurantGetAllRequestModel,
  RestaurantSaveRequestModel,
  RestaurantUpdateRequestModel,
  RestaurantGetByIdRequestModel,
  RestaurantChangeStatusRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";
import { ILeaveCertificationProcessRequest } from "../BaseEnum";

export default class Restaurant {
  async getAll(request: RestaurantGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Restaurant + "GetRestaurantCertificateList",
      { ...request, token }
    );
  }

  async GetRestaurantCount(
    request: RestaurantGetAllRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Restaurant + "GetRestaurantCertificateList",
      { ...request, token }
    );
  }

  async save(request: RestaurantSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Restaurant + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: RestaurantUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Restaurant + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(request: RestaurantGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Restaurant + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(
    request: RestaurantChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Restaurant + "ChangeStatus",
      { ...request, token }
    );
  }

  async excelDownload(request: any, name: string) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.Restaurant + "GenerateExcel",
      name,
      { ...request, token }
    );
  }

  async leaveCertificationProcess(
    request: ILeaveCertificationProcessRequest
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Restaurant + "LeaveCertificationProcess",
      { ...request, token }
    );
  }

}
