import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import {
  FairGetAllRequestModel,
  FairGetByIdRequestModel,
  FairUpdateRequestModel,
  FairSaveRequestModel,
  FairDeleteRequestModel,
} from "src/services/Fair/RequestModel";
import { MessageType } from "./system/types";
import Fair from "src/services/Fair/FairService";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAll(
  request: FairGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const fairService = new Fair();
    const result = await fairService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(request: FairSaveRequestModel): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const fairService = new Fair();
    const result = await fairService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Fuar Yönetimi"
        )
      );
      return result;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Fuar Yönetimi"
        )
      );
      return false;
    }
  };
}
export function update(
  request: FairUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const fairService = new Fair();
    const result = await fairService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Fuar Yönetimi"
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Fuar Yönetimi"
        )
      );
      return false;
    }
  };
}

export function del(
  request: FairDeleteRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const fairService = new Fair();
    const result = await fairService.del(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(initialFormData(result));
      dispatch(
        showMessage(
          "Silme işlemi başarıyla gerçekleşti.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          "Fuar Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Fuar Yönetimi"
        )
      );
      return false;
    }
  };
}
export function getById(
  request: FairGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const fairService = new Fair();
    const result = await fairService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}
