import { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { History } from "history";
import { toggleConfirmDialog } from "src/store/system/actions";
import cookie from "react-cookies";
import { getAllM } from "src/store/thunksCity";
// @ts-ignore
import ReactExport from "react-data-export";

import { GetHotelByHospital } from "src/store/thunkStatistic";
import { setFormData } from "src/store/form/actions";
import { formatDate } from "src/helpers/converts";

import { getAllC } from "src/store/thunkCounty";

import { getAllLSU } from "src/store/thunkLayoverSubCategory";

import {
  PrimaryButton,
  Label,
  Dropdown,
  TextField,
  Checkbox,
  ComboBox,
} from "office-ui-fabric-react";
import {
  CountyGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/County/RequestModel";
import { LayoverSubCategoryGetAllRequestModel } from "src/services/LayoverSubCategory/RequestModel";
import { CertificateStatusTypeGetAllRequestModel } from "src/services/CertificateStatusType/RequestModel";
import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";
import { CityGetAllRequestModel } from "src/services/City/RequestModel";

import { getAllCR } from "src/store/thunkCertificateStatusType";
import { getCompanyUserList } from "src/store/thunksSystemUser";
import { castThunkAction } from "src/helpers/casting";
import { getAllPage } from "../../store/thunkHotelCertification";
import { HotelCertificationGetAllRequestModel } from "src/services/HotelCertification/RequestModel";
import { RECORD_STATUS_ALL_ENUM } from "src/helpers/functions";

let cityRegions: any[] = [];

let selectableFieldsConst = [
  { field: "sequenceId", text: "Kayıt No" },
  { field: "region", text: "Bölge" },
  { field: "cityName", text: "İl" },
  { field: "districtName", text: "İlçe" },
  { field: "hotelEmail", text: "Tesis E-posta Adresi" },
  { field: "hotelPhone", text: "Tesis Telefon Numarası" },
  { field: "hotelManagerName", text: "Tesis Genel Müdürünün Adı ve Soyadı" },
  { field: "companyName", text: "Belge Sahibi / Ticari Ünvan" },
  { field: "documentNo", text: "Belge No" },
  { field: "brandName", text: "Tesis Adı" },
  { field: "categoryName", text: "Tesis Türü" },
  { field: "layoverSubCategoryName", text: "Tesis Kategorisi" },
  { field: "layoverFacilityTypeName", text: "Tesis Sınıfı" },
  { field: "certificateTypeName", text: "Belge Tipi" },
  { field: "roomCount", text: "Oda Sayısı" },
  { field: "companyUserTitle", text: "Denetleyici Firma" },
  { field: "webSite", text: "Web Sitesi" },
  { field: "certificateStatusTypeName", text: "Durum" },
  { field: "certificateNo", text: "Sertifika No" },
  { field: "appealDateString", text: "Başvuru Tarihi" },
  { field: "confirmationDateString", text: "Sertifika Düzenleme Tarihi" },
  { field: "isPublishWeb", text: "Webde Yayımlanma" },
  { field: "updatedUserName", text: "Güncelleyen Kullanıcı" },
  { field: "updateDateStringDate", text: "Güncelleme Tarihi" },
  { field: "updateDateStringTime", text: "Güncelleme Saati" },
  { field: "correctionType", text: "Revize Durumu" },
  { field: "correctionDescription", text: "Revize Açıklaması" },
];

let selectableFieldsConstEN = [
  { field: "sequenceId", text: "Registration Number" },
  { field: "region", text: "Region" },
  { field: "cityName", text: "City" },
  { field: "districtName", text: "District" },
  { field: "hotelEmail", text: "Hotel Email" },
  { field: "hotelPhone", text: "Hotel Phone" },
  { field: "hotelManagerName", text: "Hotel Manager Name" },
  { field: "companyName", text: "Company Name" },
  { field: "documentNo", text: "Document No" },
  { field: "brandName", text: "Facility Name" },
  { field: "categoryName_En", text: "Category" },
  { field: "layoverSubCategoryName_En", text: "Facility Category" },
  { field: "layoverFacilityTypeName_En", text: "Facility Type" },
  { field: "certificateTypeName_En", text: "Document Type" },
  { field: "roomCount", text: "Room Count" },
  { field: "companyUserTitle", text: "Certification Company" },
  { field: "webSite", text: "Website" },
  { field: "certificateStatusTypeName_En", text: "Status" },
  { field: "certificateNo", text: "Certificate No" },
  { field: "appealDateString", text: "Appeal Date" },
  { field: "confirmationDateString", text: "Certificate Date" },
  { field: "isPublishWeb", text: "Web Published" },
  { field: "updatedUserName", text: "Updated User" },
  { field: "updateDateStringDate", text: "Updated Date" },
  { field: "updateDateStringTime", text: "Updated Time" },
  { field: "correctionType", text: "Revised Status" },
  { field: "correctionDescription", text: "Revised Description" },
];

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let usertype: any = 0;
interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        toggleConfirmDialog,
        getAllM,
        GetHotelByHospital,
        setFormData,
        getAllC,
        getAllLSU,
        getAllCR,
        getCompanyUserList,
        getAllPage,
      },
      dispatch
    ),
  };
}

type GeneralHotelReportProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  pagerTotal: number;
  excel: boolean;
  download: boolean;
  selectableFields: any[];
  selectedFields: any[];
  selectableFieldsEN: any[];
  selectedFieldsEN: any[];
}

class List extends Component<GeneralHotelReportProps, OwnState> {
  state = {
    filterData: {
      searchKey: "",
      districtId: 0,
      status: 1,
      cityId: 0,
      layoverSubCategoryId: 0,
      isPublishWeb: null,
      certificateStatusTypeId: 0,
      companyUserId: 0,
      correctionType: null,
      isPublishQr: 0,
      orderField: "UpdatedSystemUserDatetime",
      isDescending: true,
      companyName: "",
      isHistoryPublishWeb: "",
      isHistoryPublishQr: "",
      sequenceId: "",
    },
    pagerCurrent: 0,
    excel: false,
    download: false,
    pagerTotal: 0,
    selectableFields: selectableFieldsConst,
    selectedFields: selectableFieldsConst,
    selectableFieldsEN: selectableFieldsConstEN,
    selectedFieldsEN: selectableFieldsConstEN,
  };

  UNSAFE_componentWillMount() {
    usertype = cookie.load("systemUserInfo");
    if (usertype === "2") {
      window.location.href = "/";
    }
    cityRegions = require("../../cityRegions.json");

    const request2: CityGetAllRequestModel = {
      selectItemList: ["CityId", "CityName"],
      orderField: "CityName",
    };

    this.props.getAllM(request2);

    const request3: LayoverSubCategoryGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "Status",
          value: 1,
          isOr: false,
          conditionOperator: 0,
        },
      ],
      selectItemList: ["LayoverSubCategoryId", "LayoverSubCategoryName"],
    };
    this.props.getAllLSU(request3);

    const request4: CertificateStatusTypeGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "Status",
          value: 1,
          isOr: false,
          conditionOperator: 0,
        },
      ],
      selectItemList: ["CertificateStatusTypeId", "Title"],
    };
    this.props.getAllCR(request4);

    const request6: SystemUserGetAllModel = {
      systemUserType: 2,
    };
    this.props.getCompanyUserList(request6);
  }

  UNSAFE_componentWillUpdate(nextProps: any, nextState: any) {
    if (nextState.pagerTotal !== nextProps.formData.pageCount)
      this.setState({
        ...this.state,
        pagerTotal: this.props.formData.pageCount,
      });
  }

  CityChange(item: any) {
    this.setState(
      {
        filterData: {
          ...this.state.filterData,
          cityId: item,
        },
      },
      () => {
        const request2: CountyGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "cityId",
              value: item,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
            {
              propertyName: "Status",
              value: 1,
              isOr: false,
              conditionOperator: 0,
            },
          ],
          selectItemList: ["DistrictId", "DistrictName"],
          orderField: "DistrictName",
        };
        this.props.getAllC(request2);
      }
    );
  }
  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Tümünü Seç" });
    }

    return newArray;
  }

  renderFilter() {
    return (
      <div className="table-filter row">
        <TextField
          className="custom-textfield col-md-4"
          label="Tesis Adı"
          value={this.state.filterData?.searchKey || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                searchKey: event.target.value,
              },
            });
          }}
        />

        <TextField
          className="custom-textfield col-md-2"
          label="Belge Sahibi / Ticari Ünvan"
          value={this.state.filterData?.companyName || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                companyName: event.target.value,
              },
            });
          }}
        />
        <Dropdown
          defaultSelectedKey={this.state.filterData?.cityId || ""}
          onChanged={(event: any) => (
            this.CityChange(event.key),
            this.setState({
              filterData: {
                ...this.state.filterData,
                cityId: event.key,
              },
            })
          )}
          label="İl"
          options={(this.props.formData.GroupList?.dataList
            ? this.returnOptions(
              this.props.formData.GroupList?.dataList,
              "CityId",
              "CityName"
            )
            : ([] as any)
          ).map((item: any) => ({ key: item.CityId, text: item.CityName }))}
          className="custom-dropdown col-md-2"
        />
        <Dropdown
          defaultSelectedKey={this.state.filterData?.districtId || ""}
          onChanged={(event: any) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                districtId: event.key,
              },
            })
          }
          label="İlçe"
          options={(this.props.formData.GroupListC?.dataList
            ? this.returnOptions(
              this.props.formData.GroupListC?.dataList,
              "DistrictId",
              "DistrictName"
            )
            : ([] as any)
          ).map((item: any) => ({
            key: item.DistrictId,
            text: item.DistrictName,
          }))}
          className="custom-dropdown col-md-2"
        />

        <Dropdown
          defaultSelectedKey={this.state.filterData?.layoverSubCategoryId || ""}
          onChanged={(event: any) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                layoverSubCategoryId: event.key,
              },
            })
          }
          label="Tesis Kategorisi"
          options={(this.props.formData.GroupListSU?.dataList
            ? this.returnOptions(
              this.props.formData.GroupListSU?.dataList,
              "LayoverSubCategoryId",
              "LayoverSubCategoryName"
            )
            : ([] as any)
          ).map((item: any) => ({
            key: item.LayoverSubCategoryId,
            text: item.LayoverSubCategoryName,
          }))}
          className="custom-dropdown col-md-2"
        />

        <Dropdown
          options={(this.props.formData.GroupListCR?.dataList
            ? this.returnOptions(
              this.props.formData.GroupListCR?.dataList,
              "CertificateStatusTypeId",
              "Title"
            )
            : ([] as any)
          ).map((item: any) => ({
            key: item.CertificateStatusTypeId,
            text: item.Title,
          }))}
          className="custom-dropdown col-md-2"
          label="Sertifika Durumu"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData.certificateStatusTypeId}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                certificateStatusTypeId: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />

        {usertype && usertype !== "2" ? (
          <ComboBox
            options={(this.props.formData.CompanyUserList?.dataList
              ? this.returnOptions(
                this.props.formData.CompanyUserList?.dataList,
                "systemUserId",
                "title"
              )
              : ([] as any)
            ).map((item: any) => ({
              key: item.systemUserId,
              text: item.title,
            }))}
            className="custom-dropdown col-md-2"
            label="Denetleyici Firma"
            allowFreeform={true}
            autoComplete="on"
            placeholder="Seçiniz"
            selectedKey={this.state.filterData.companyUserId || 0}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  companyUserId: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />
        ) : (
          ""
        )}

        <Dropdown
          options={[
            { key: "", text: "Seçiniz" },
            { key: 1, text: "Revize İstendi" },
            { key: 2, text: "Revize Yapıldı" },
            { key: 3, text: "Revize Onaylandı" },
            { key: 4, text: "Revize Kaydı Olanlar" },
            { key: 5, text: "Revizeye Girmemişler" },
          ]}
          className="custom-dropdown col-md-2"
          label="Revize Durumu"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.correctionType || null}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                correctionType: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />

        <Dropdown
          options={[
            { key: "BrandName", text: "Tesis Adı" },
            { key: "CreatedSystemUserDatetime", text: "Oluşturma Tarihi" },
            { key: "UpdatedSystemUserDatetime", text: "Güncelleme Tarihi" },
          ]}
          className="custom-dropdown col-md-2"
          label="Sıralama"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.orderField || null}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                orderField: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={(this.state.filterData.orderField === "BrandName"
            ? [
              { key: false, text: "Baştan Sona" },
              { key: true, text: "Sondan Başa" },
            ]
            : [
              { key: false, text: "Eskiden Yeniye" },
              { key: true, text: "Yeniden Eskiye" },
            ]
          ).map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-2"
          label="Sıralama Türü"
          placeholder="Seçiniz"
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isDescending: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={RECORD_STATUS_ALL_ENUM}
          className="custom-dropdown col-md-2"
          label="Kayıt Durumu"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.status || 1}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                status: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: "", text: "Seçiniz" },
            { key: "false", text: "Hayır" },
            { key: "true", text: "Evet" },
          ].map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-2"
          label="Son Kayıtta Webde Yayımlanma"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData.isPublishWeb}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isPublishWeb: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: "false", text: "Webde Gösterilmeyenler" },
            { key: "true", text: "Webde göster olanlar" },
          ].map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-2"
          label="Eski Kayıtlar Dahil Webde Göster"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData.isHistoryPublishWeb}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isHistoryPublishWeb: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: "0", text: "Seçiniz" },
            { key: "1", text: "Evet" },
            { key: "2", text: "Hayır" },
          ]}
          className="custom-dropdown col-md-2"
          label="Son Kayıtta QR Yayımlanma"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.isPublishQr || null}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isPublishQr: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={[
            { key: "false", text: "QR Yayımda Olmayanlar" },
            { key: "true", text: "QR Yayımda Olanlar" },
          ].map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-2"
          label="Eski Kayıtlar Dahil QR Yayım"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData.isHistoryPublishQr}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isHistoryPublishQr: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <TextField
          className="custom-textfield col-md-2"
          label="Kayıt No"
          value={this.state.filterData?.sequenceId || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                sequenceId: event.target.value !== "" ? event.target.value : 0,
              },
            });
          }}
        />
        {this.rederexcel()}
        {/* {usertype && usertype !== "2" ? this.rederexcel() : ""} */}
      </div>
    );
  }

  getReport() {
    const request: HotelCertificationGetAllRequestModel = {
      ...this.state.filterData,
    };
    request["isPublishQr"] =
      request["isPublishQr"] === "1"
        ? true
        : request["isPublishQr"] === "2"
          ? false
          : null;

    castThunkAction(this.props.getAllPage(request)).then((x: any) => {
      this.setState({ excel: true, download: false });
    });
  }
  dataMapForExcel(dataList: any, fieldList: any, lang: string) {
    const mappedData = [];
    if (dataList) {
      for (let index = 0; index < dataList.length; index++) {
        mappedData.push([...this.mapField(dataList[index], fieldList, lang)]);
      }
    }

    return mappedData;
  }
  mapField(data: any, fieldList: any, lang: string) {
    console.log(data,"mapField",fieldList,lang)
    const list = [];
    for (let index = 0; index < fieldList.length; index++) {
      list.push(this.excelFieldSwich(data, fieldList, index, lang));
    }
    return list;
  }
  excelFieldSwich(data: any, fieldList: any, index: any, lang: string) {
    let val = "";
    switch (fieldList[index].field) {
      case "region":
        val =
          data["cityName"] && data["cityName"] !== ""
            ? cityRegions.filter((x) => x.city === data["cityName"])[0]?.region
            : "";
        break;

      // case "certificateStatusTypeName":
      //   val =
      //     data["certificateStatusTypeId"] === 2 ? data["certificateNo"] : "";
      //   break;

      case "confirmationDateString":
        val =
          data["certificateStatusTypeId"] === 2
            ? data["confirmationDateString"]
            : "";
        break;
      case "isPublishWeb":
        if (lang === "TR") {
          val = data["isPublishWeb"] === 2 ? "Evet" : "Hayır";
        } else {
          val = data["isPublishWeb"] === 2 ? "YES" : "NO";
        }
        break;

      case "updateDateStringDate":
        val = data["updateDateString"]?.split(" ")[0];
        break;

      case "updateDateStringTime":
        val = data["updateDateString"]?.split(" ")[1];
        break;
      case "correctionType":
        if (lang === "TR") {
          val = [
            { key: 1, text: "Revize İstendi" },
            { key: 2, text: "Revize Yapıldı" },
            { key: 3, text: "Revize Onaylandı" },
            { key: null, text: "Revize Yok" },
          ].filter((x) => x.key === data["correctionType"])[0].text;
        } else {
          val = [
            { key: 1, text: "Revised Requested" },
            { key: 2, text: "Revised" },
            { key: 3, text: "Revised Approved" },
            { key: null, text: "Non Revision" },
          ].filter((x) => x.key === data["correctionType"])[0].text;
        }
        break;

      default:
        val = data[fieldList[index].field] ? data[fieldList[index].field] : " ";
        break;
    }

    return {
      value: val,
    };
  }

  rederexcel() {
    const excelName = "KonaklamaTesisleriVeRestoran_" + formatDate();

    let multiDataSet: any = [];
    let multiDataSetEN: any = [];

    if (this.state.excel) {
      multiDataSet = [
        {
          columns: [
            ...this.state.selectedFields.map((x: any) => {
              return { title: x.text, width: { wpx: 190 } };
            }),
          ],
          data: this.dataMapForExcel(
            this.props.formData?.excel?.dataList,
            this.state.selectedFields,
            "TR"
          ),
        },
      ];
    }
    if (this.state.excel) {
      multiDataSetEN = [
        {
          columns: [
            ...this.state.selectedFieldsEN.map((x: any) => {
              return { title: x.text, width: { wpx: 190 } };
            }),
          ],
          data: this.dataMapForExcel(
            this.props.formData?.excel?.dataList,
            this.state.selectedFieldsEN,
            "EN"
          ),
        },
      ];
    }
    return this.state.excel ? (
      <ExcelFile
        filename={excelName}
        element={
          <PrimaryButton
            text="Raporu İndir"
            className="custom-button"
            style={{ marginLeft: "15px" }}
            styles={{
              root: {
                fontSize: "11px",
                marginTop: "27px",
                height: "30px",
              },
            }}
          />
        }
      >
        <ExcelSheet dataSet={multiDataSet} name="TR" />
        <ExcelSheet dataSet={multiDataSetEN} name="EN" />
      </ExcelFile>
    ) : (
      <>
        <PrimaryButton
          text="Rapor Oluştur"
          className="custom-button"
          onClick={() => this.getReport()}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
        {this.state.download && (
          <Label style={{ marginLeft: "10px", marginTop: "27px" }}>
            Rapor Oluşturuluyor...
          </Label>
        )}
      </>
    );
  }

  getBool(x: any) {
    return this.state.selectedFields.filter((y: any) => y.field === x.field)
      .length > 0
      ? true
      : false;
  }
  getBoolEN(x: any) {
    return this.state.selectedFieldsEN.filter((y: any) => y.field === x.field)
      .length > 0
      ? true
      : false;
  }
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Genel Otel Raporu"
          subTitle="Filtreler ile genel otel sertifika raporuna buradan ulaşabilirsiniz"
          backUrl="/"
          showBack
          history={this.props.history}
        />
        <div className="form-groups row">
          <aside className="col-md-12 row" style={{ padding: 0 }}>
            <div className="form-group col-md-12">
              {this.renderFilter()}
              <div className="form-wrapper">
                <PrimaryButton
                  text="Tümünü Seç"
                  className="custom-button"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      selectedFields: selectableFieldsConst,
                      selectedFieldsEN: selectableFieldsConstEN,
                    })
                  }
                  style={{ marginLeft: "15px" }}
                  styles={{
                    root: {
                      fontSize: "11px",
                      marginTop: "27px",
                      height: "30px",
                    },
                  }}
                />
                <PrimaryButton
                  text="Tümünü Kaldır"
                  className="custom-button"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      selectedFields: [],
                      selectedFieldsEN: [],
                    })
                  }
                  style={{ marginLeft: "15px" }}
                  styles={{
                    root: {
                      fontSize: "11px",
                      marginTop: "27px",
                      height: "30px",
                    },
                  }}
                />
                <div className="item row">
                  <label
                    style={{
                      float: "left",
                      width: "100%",
                      fontSize: "20px",
                      marginTop: "7px",
                      marginBottom: "15px",
                    }}
                  >
                    Türkçe raporda istediğiniz alanları seçiniz
                  </label>
                  {this.state.selectableFields.map((x) => (
                    <div
                      style={{ float: "left", width: "48%", marginRight: "2%" }}
                    >
                      <div style={{ marginBottom: "15px" }}>
                        <Checkbox
                          label={x.text}
                          checked={this.getBool(x)}
                          onChange={() => (
                            this.state.selectedFields.length > 0
                              ? this.state.selectedFields.filter(
                                (y) => y.field === x.field
                              ).length > 0
                                ? this.setState({
                                  ...this.state,
                                  selectedFields: this.state.selectedFields.filter(
                                    (y) => y.field !== x.field
                                  ),
                                })
                                : this.setState({
                                  ...this.state,
                                  selectedFields: [
                                    ...this.state.selectedFields,
                                    x,
                                  ],
                                })
                              : this.setState({
                                ...this.state,
                                selectedFields: [
                                  ...this.state.selectedFields,
                                  x,
                                ],
                              })
                          )}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="item row">
                  <label
                    style={{
                      float: "left",
                      width: "100%",
                      fontSize: "20px",
                      marginTop: "7px",
                      marginBottom: "15px",
                    }}
                  >
                    İngilizce raporda istediğiniz alanları seçiniz
                  </label>
                  {this.state.selectableFieldsEN.map((x) => (
                    <div
                      style={{ float: "left", width: "48%", marginRight: "2%" }}
                    >
                      <div style={{ marginBottom: "15px" }}>
                        <Checkbox
                          label={x.text}
                          checked={this.getBoolEN(x)}
                          onChange={() => (
                            this.state.selectableFieldsEN.length > 0
                              ? this.state.selectableFieldsEN.filter(
                                (y) => y.field === x.field
                              ).length > 0
                                ? this.setState({
                                  ...this.state,
                                  selectableFieldsEN: this.state.selectableFieldsEN.filter(
                                    (y) => y.field !== x.field
                                  ),
                                })
                                : this.setState({
                                  ...this.state,
                                  selectableFieldsEN: [
                                    ...this.state.selectableFieldsEN,
                                    x,
                                  ],
                                })
                              : this.setState({
                                ...this.state,
                                selectableFieldsEN: [
                                  ...this.state.selectableFieldsEN,
                                  x,
                                ],
                              })
                          )}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
