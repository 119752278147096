/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import { setFormData } from "src/store/form/actions";
import { DatePicker, PrimaryButton } from "office-ui-fabric-react";
import {
    DayPickerStrings,
    firstDayOfWeek,
    onFormatDate,
} from "src/config/otherConfig";
import { toDate, toTimeStamp } from "src/helpers/converts";

interface OwnProps { }

function mapStateToProps(state: RootState) {
    return {
        language: state.system.language,
        formData: state.form.formData,
        formErrors: state.form.formErrors,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        ...bindActionCreators(
            {
                setFormData,
            },
            dispatch
        ),
    };
}

type ValidityDateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    OwnProps;

interface OwnState {
    ValidityDateTypeId: number;
    initialValidityDateTypeId: number | null;
}


class ValidityDate extends Component<ValidityDateProps, OwnState> {
    state = {
        ValidityDateTypeId: 0,
        initialValidityDateTypeId: null,
    };

    getDate(date: any): any {
        if (date !== 0) {
            return toDate(date, "");
        }
    }


    render() {
        const { formData, setFormData } = this.props;

        return this.props.formData.certificateStatusTypeId === 2 &&
            this.props.formData.prefix ? (
            <div className="item row">
                <DatePicker
                    strings={DayPickerStrings}
                    firstDayOfWeek={firstDayOfWeek}
                    onSelectDate={(date) =>
                        setFormData(
                            "extendedClosedDate",
                            toTimeStamp(date?.toString())
                        )
                    }
                    label="Sertifika Geçerlilik Tarihi"
                    placeholder="gün.ay.yıl"
                    formatDate={onFormatDate}
                    isRequired={
                        this.props.formData.certificateStatusTypeId === 2
                    }
                    value={this.getDate(
                        formData.extendedClosedDate && formData.extendedClosedDate !== null
                            ? formData.extendedClosedDate
                            : 0
                    )}
                    minDate={this.getDate(
                        formData.confirmationDate && formData.confirmationDate !== null
                            ? formData.confirmationDate
                            : 0
                    )}
                />
                <PrimaryButton
                    id="extendedClosedDate"
                    onClick={(x) =>
                        this.props.setFormData("extendedClosedDate", 0)
                    }
                    iconProps={{ iconName: "Trash" }}
                    text="Tarihi Sil"
                    className="deleteDateBtn"
                    styles={{
                        root: {
                            marginTop: 5,
                            background: "#ac6262",
                            borderColor: "#ac6262",
                        },
                        rootHovered: {
                            background: "#a34c4c",
                            borderColor: "#a34c4c",
                        },
                        rootPressed: {
                            background: "#a34c4c",
                            borderColor: "#a34c4c",
                        },
                        rootDisabled: {
                            background: "#ececec",
                        },
                    }}
                />
            </div>
        ) : null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ValidityDate);
