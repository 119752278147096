import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Dropdown,
  DatePicker,
  Label,
  TextField,
  ComboBox,
} from "office-ui-fabric-react";

import {
  setFormData,
  setFormErrors,
  initialFormData,
} from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";
import { update, getById } from "src/store/thunkAdditionalControl";
import { castThunkAction } from "src/helpers/casting";
import { StatusEnums } from "src/services/BaseEnum";
import { showErrorDialog } from "src/store/thunkCertificateType";
import { getAllCompany } from "src/store/thunksCompany";
import { getCompanyUserList } from "src/store/thunksSystemUser";
import cookie from "react-cookies";
import {
  DayPickerStrings,
  firstDayOfWeek,
  onFormatDate,
} from "src/config/otherConfig";
import { toTimeStamp, toDate } from "src/helpers/converts";
import _ from "lodash";
import { apiConfig, qrUrl } from "src/config/apiConfig";
import {
  AuditResultEnum,
  AuditTypeEnum,
  FinalResultEnum,
} from "src/helpers/enums";
import { AdditionalUpdateRequestModel } from "src/services/AdditionalControl/RequestModel";
import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";
import { FileUpload } from "../FileUpload";
import { formatSize } from "src/helpers/functions";
let token = "";
let AuditMaxDate = new Date();
AuditMaxDate.setDate(AuditMaxDate.getDate() + 7);

interface OwnProps {
  history: History;
  relatedId: number;
}
function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}
function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        initialFormData,
        update,
        getCompanyUserList,
        getAllCompany,
        showErrorDialog,
        getById,
      },
      dispatch
    ),
  };
}

type CreateHotelCertificationProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  showFields: boolean;
  messageType: MessageBarType;
  isFileUploading: boolean;
  infoMessageGetDocumentNo: string;
}
let usertype: any = 0;
class CreateHotelCertification extends Component<
  CreateHotelCertificationProps,
  OwnState
> {
  state = {
    messageType: MessageBarType.warning,
    showFields: true,
    isFileUploading: false,
    infoMessageGetDocumentNo: "",
  };

  UNSAFE_componentWillMount() {
    token = cookie.load("userId");
    usertype = cookie.load("systemUserInfo");
    this.props.initialFormData({
      status: StatusEnums.Published,
    });

    castThunkAction(
      this.props.getById({
        id: +this.props.relatedId,
      } as any)
    ).then((response: any) => {
      this.props.setFormData(
        "finalResultOnPage",
        this.props.formData.finalResult
      );
      const request6: SystemUserGetAllModel = {
        systemUserType: 2,
      };
      castThunkAction(this.props.getCompanyUserList(request6)).then();
    });
  }

  renderTextByMessageType() {
    switch (this.state.messageType) {
      case MessageBarType.success:
        return "Kaydetme işlemi başarıyla gerçekleşmiştir.";
      case MessageBarType.error:
        return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
      default:
        return "Lütfen aşağıdaki bilgileri doldurun ve formu oluşturun";
    }
  }
  renderMessageBar() {
    return (
      <MessageBar messageBarType={this.state.messageType} isMultiline={false}>
        {this.renderTextByMessageType()}
      </MessageBar>
    );
  }

  onUpdate() {
    let request: AdditionalUpdateRequestModel = {
      data: {
        additionalAuditId: this.props.formData.additionalAuditId,
        companyUserId: this.props.formData.companyUserId,
        relatedId: this.props.formData.relatedId,
        facilityName: this.props.formData.facilityName,
        facilityType: this.props.formData.facilityType,
        auditDate: this.props.formData.auditDate,
        auditType: this.props.formData.auditType,
        notSuitableCloseEndDate: this.props.formData.notSuitableCloseEndDate,
        auditResult: this.props.formData.auditResult,
        auditNo: this.props.formData.auditNo,
        finalResult: this.props.formData.finalResult,
        auditResultDescription: this.props.formData.auditResultDescription,
        finalResultDescription: this.props.formData.finalResultDescription,
        roomCount:
          this.props.formData.roomCount == "" ||
            this.props.formData.roomCount == null
            ? 0
            : parseInt(this.props.formData.roomCount),
        cityId: this.props.formData.cityId,
        districtId: this.props.formData.districtId,
      },
    };

    let desc = "";
    if (!this.filterFileds(this.props.formData.auditType)) {
      desc += "Tesis Tipi, <br/>";
    }
    if (!this.filterFileds(this.props.formData.auditDate)) {
      desc += "Denetim Tarihi,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.auditResult)) {
      desc += "Denetim Sonucu,  <br/>";
    }
    if (this.props.formData.auditResult === 2) {
      if (!this.filterFileds(this.props.formData.notSuitableCloseEndDate)) {
        desc += "Kapama Tarihi,  <br/>";
      }
      if (!this.filterFileds(this.props.formData.finalResult)) {
        desc += "Nihai Sonuç,  <br/>";
      }
    }
    if (this.props.formData.auditResult !== 1) {
      if (!this.filterFileds(this.props.formData.auditResultDescription)) {
        desc += "Denetim Sonucu Açıklama,  <br/>";
      }
    }
    if (this.props.formData.finalResult === 3) {
      if (!this.filterFileds(this.props.formData.finalResultDescription)) {
        desc += "Nihai Sonuc Açıklama,  <br/>";
      }
    }

    if (
      !this.props.formData.auditReportAttachmentList ||
      this.props.formData.auditReportAttachmentList.length === 0
    ) {
      desc += "Denetim raporu yükleyiniz,  <br/>";
    }

    // if (
    //   (this.props.formData.auditResult === 2 &&
    //     this.props.formData.finalResult === 3) ||
    //   this.props.formData.auditResult === 3
    // ) {
    // if(this.props.formData.auditResult !== 1 )
    // {
    //   if (
    //     !this.props.formData.auditNotSuitableAttachmentList ||
    //     this.props.formData.auditNotSuitableAttachmentList.length === 0
    //   ) {
    //     desc += "Uygunsuzluk raporu yükleyiniz.  <br/>";
    //   }
    // }

    // }

    if (desc !== "") {
      const request = {
        title: "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
        desc: desc,
      };
      castThunkAction(
        this.props.showErrorDialog(request)
      ).then((response: any) => { });
    } else {
      if (
        this.props.formData.auditReportAttachmentList &&
        this.props.formData.auditReportAttachmentList.length > 0
      ) {
        request.data["auditReportAttachmentList"] = (
          this.props.formData.auditReportAttachmentList || 0
        ).map((item: any) => item.attachmentId);
      }
      if (
        this.props.formData.auditNotSuitableAttachmentList &&
        this.props.formData.auditNotSuitableAttachmentList.length > 0
      ) {
        request.data["auditNotSuitableAttachmentList"] = (
          this.props.formData.auditNotSuitableAttachmentList || 0
        ).map((item: any) => item.attachmentId);
      }
      castThunkAction(this.props.update(request)).then((response: any) => {
        this.props.setFormData(
          "initialauditReportAttachmentList",
          this.props.formData.auditReportAttachmentList
        );
        this.props.setFormData(
          "initialauditNotSuitableAttachmentList",
          this.props.formData.auditNotSuitableAttachmentList
        );
        this.setState(
          {
            messageType: response
              ? MessageBarType.success
              : MessageBarType.error,
          },
          () => {
            if (response) {
              this.props.history.push(
                "/AdditionalControl/Edit/" +
                this.props.formData.additionalAuditId
              );
            } else {
              setTimeout(() => { }, 1000);
            }
          }
        );
      });
    }
  }
  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  removeElement(id: any) {
    var elem = document.getElementById(id);
    return elem?.parentNode?.removeChild(elem);
  }
  filterFileds(val: any) {
    if (
      val !== "" &&
      val !== 0 &&
      val !== "0" &&
      val !== null &&
      val !== "null" &&
      val !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  }
  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Seçiniz" });
    }

    return newArray;
  }
  getDate(date: any): any {
    if (date !== 0 && date !== undefined) {
      return toDate(date, "");
    }
  }


  renderForm() {
    const { formData, formErrors, setFormData } = this.props;

    return (
      <div className="form-groups row">
        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="item row">
              {this.props.formData.facilityType === 5 && (
                <Label className="hotelName">
                  Seçili Tesis:{" "}
                  <a
                    href={"/hotel/edit/" + this.props.formData.relatedId}
                    target="_blank"
                  >
                    {this.props.formData.facilityName}
                  </a>
                </Label>
              )}
              {this.props.formData.facilityType === 6 && (
                <Label className="hotelName">
                  Seçili Tesis:{" "}
                  <a
                    href={"/Congress/edit/" + this.props.formData.relatedId}
                    target="_blank"
                  >
                    {this.props.formData.facilityName}
                  </a>
                </Label>
              )}
              {this.props.formData.facilityType === 7 && (
                <Label className="hotelName">
                  Seçili Tesis:{" "}
                  <a
                    href={"/SeaTourism/edit/" + this.props.formData.relatedId}
                    target="_blank"
                  >
                    {this.props.formData.facilityName}
                  </a>
                </Label>
              )}
              {this.props.formData.facilityType === 8 && (
                <Label className="hotelName">
                  Seçili Tesis:{" "}
                  <a
                    href={
                      "/ThemedFacility/edit/" + this.props.formData.relatedId
                    }
                    target="_blank"
                  >
                    {this.props.formData.facilityName}
                  </a>
                </Label>
              )}
              {this.props.formData.facilityType === 9 && (
                <Label className="hotelName">
                  Seçili Tesis:{" "}
                  <a
                    href={
                      "/WinterTourism/edit/" + this.props.formData.relatedId
                    }
                    target="_blank"
                  >
                    {this.props.formData.facilityName}
                  </a>
                </Label>
              )}
            </div>
            <div className="item row">
              <Dropdown
                onChanged={(event: any) => setFormData("auditType", event.key)}
                label="Denetim Türü"
                selectedKey={formData.auditType}
                disabled={usertype === "2" ? true : false}
                options={AuditTypeEnum.map((item: any) => ({
                  key: item.key,
                  text: item.text,
                }))}
                className="custom-dropdown"
              />
            </div>
            <div className="item row">
              <DatePicker
                onSelectDate={(date) =>
                  setFormData(
                    "auditInformationDate",
                    toTimeStamp(date?.toString())
                  )
                }
                disabled={usertype === "2" ? true : false}
                formatDate={onFormatDate}
                label="Denetim Bildirim Tarihi"
                value={this.getDate(this.props.formData.auditInformationDate)}
              />
            </div>
            <div className="item row">
              <DatePicker
                strings={DayPickerStrings}
                firstDayOfWeek={firstDayOfWeek}
                onSelectDate={(date) =>
                  setFormData("auditDate", toTimeStamp(date?.toString()))
                }
                label="Denetim Tarihi"
                placeholder="gün.ay.yıl"
                disabled={usertype === "2" ? true : false}
                formatDate={onFormatDate}
                maxDate={new Date()}
                value={this.getDate(this.props.formData.auditDate)}
              />

              <PrimaryButton
                id="auditDate"
                onClick={(x) => this.props.setFormData("auditDate", 0)}
                iconProps={{ iconName: "Trash" }}
                text="Tarihi Sil"
                className="deleteDateBtn"
                disabled={usertype === "2" ? true : false}
                styles={{
                  root: {
                    marginTop: 5,
                    background: "#ac6262",
                    borderColor: "#ac6262",
                  },
                  rootHovered: {
                    background: "#a34c4c",
                    borderColor: "#a34c4c",
                  },
                  rootPressed: {
                    background: "#a34c4c",
                    borderColor: "#a34c4c",
                  },
                  rootDisabled: {
                    background: "#ececec",
                  },
                }}
              />
            </div>
            <div className="item row">
              <ComboBox
                onChange={(event: any, option: any) =>
                  setFormData("companyUserId", option.key)
                }
                allowFreeform={true}
                autoComplete="on"
                selectedKey={formData.companyUserId || 0}
                label="Denetleyici Firma"
                disabled={usertype === "2" ? true : false}
                options={(
                  this.props.formData.CompanyUserList?.dataList || ([] as any)
                ).map((item: any) => ({
                  key: item.systemUserId,
                  text: item.title,
                }))}
                className="custom-dropdown"
                errorMessage={formErrors.companyUserId}
              />
            </div>

            <div className="item row">
              <TextField
                className="custom-textfield"
                label="Denetim Seri Numarası"
                disabled={usertype === "2" ? true : false}
                onChange={(event: any) =>
                  setFormData("auditNo", event.target.value)
                }
                value={formData.auditNo}
              />
            </div>
          </div>
        </div>

        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="item row">
              <Dropdown
                onChanged={(event: any) =>
                  setFormData("auditResult", event.key)
                }
                label="Denetim Sonucu"
                disabled={usertype === "2" ? true : false}
                options={AuditResultEnum.map((item: any) => ({
                  key: item.key,
                  text: item.text,
                }))}
                selectedKey={formData.auditResult}
                className="custom-dropdown"
                errorMessage={formErrors.AuditResult}
              />
            </div>
            {formData.auditResult === 2 && (
              <div className="item row">
                <DatePicker
                  strings={DayPickerStrings}
                  firstDayOfWeek={firstDayOfWeek}
                  onSelectDate={(date) =>
                    setFormData(
                      "notSuitableCloseEndDate",
                      toTimeStamp(date?.toString())
                    )
                  }
                  label="Kapama Süresi Bitiş Tarihi"
                  placeholder="gün.ay.yıl"
                  disabled={usertype === "2" ? true : false}
                  formatDate={onFormatDate}
                  maxDate={AuditMaxDate}
                  minDate={this.getDate(this.props.formData.AuditDate)}
                  initialPickerDate={this.getDate(
                    this.props.formData.AuditDate
                  )}
                  value={this.getDate(
                    this.props.formData.notSuitableCloseEndDate
                  )}
                />
                {/* İstemekleri için kapadım. İsterlerse açarız. <PrimaryButton
                  id="notSuitableCloseEndDate"
                  onClick={(x) =>
                    this.props.setFormData("notSuitableCloseEndDate", 0)
                  }
                  iconProps={{ iconName: "Trash" }}
                  text="Tarihi Sil"
                  className="deleteDateBtn"
                  styles={{
                    root: {
                      marginTop: 5,
                      background: "#ac6262",
                      borderColor: "#ac6262",
                    },
                    rootHovered: {
                      background: "#a34c4c",
                      borderColor: "#a34c4c",
                    },
                    rootPressed: {
                      background: "#a34c4c",
                      borderColor: "#a34c4c",
                    },
                    rootDisabled: {
                      background: "#ececec",
                    },
                  }}
                /> */}
              </div>
            )}
            <div className="item row">
              <TextField
                className="custom-textfield"
                label="Denetim Sonucu Açıklama"
                multiline
                disabled={usertype === "2" ? true : false}
                onChange={(event: any) =>
                  setFormData("auditResultDescription", event.target.value)
                }
                value={formData.auditResultDescription}
              />
            </div>

            {formData.auditResult === 2 && (
              <div className="item row">
                <Dropdown
                  onChanged={(event: any) =>
                    setFormData("finalResult", event.key)
                  }
                  label="Nihai Sonuç"
                  selectedKey={formData.finalResult}
                  options={FinalResultEnum.map((item: any) => ({
                    key: item.key,
                    text: item.text,
                  }))}
                  className="custom-dropdown"
                  disabled={
                    usertype == 2 &&
                      (formData.finalResultOnPage === 3 ||
                        formData.finalResultOnPage === 2)
                      ? true
                      : false
                  }
                  errorMessage={formErrors.FinalResult}
                />
              </div>
            )}
            {formData.auditResult === 2 && (
              <div className="item row">
                <TextField
                  className="custom-textfield"
                  disabled={
                    usertype == 2 &&
                      (formData.finalResultOnPage === 3 ||
                        formData.finalResultOnPage === 2)
                      ? true
                      : false
                  }
                  label="Nihai Sonucu Açıklama"
                  multiline
                  onChange={(event: any) =>
                    setFormData("finalResultDescription", event.target.value)
                  }
                  value={formData.finalResultDescription}
                />
              </div>
            )}
          </div>
        </div>

        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="title">Denetim Raporu</div>
            {(usertype == 1 || usertype == 3) && (
              <FileUpload
                onProcessStartFile={() => {
                  this.setState({
                    isFileUploading: true,
                  });
                }}
                onProcessEndFile={(queue: boolean) => {
                  if (!queue) {
                    this.setState({
                      isFileUploading: false,
                    });
                  }
                }}
                onTransferedFile={(file: any, promise?: boolean) => {
                  this.props.setFormData("auditReportAttachmentList", [
                    ...(this.props.formData?.auditReportAttachmentList || []),
                    file.attachmentIdList[0],
                  ]);
                }}
                requestKey="files"
                fileTypes={[
                  {
                    key: "image/jpeg",
                    text: "jpeg",
                  },
                  {
                    key: "image/jpg",
                    text: "jpg",
                  },
                  {
                    key: "image/png",
                    text: "png",
                  },
                  {
                    key: "application/pdf",
                    text: "pdf",
                  },
                  {
                    key: "application/vnd.ms-excel",
                    text: "xls",
                  },
                  {
                    key: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    text: "xlsx",
                  },
                  {
                    key: "application/msword",
                    text: "doc",
                  },
                  {
                    key: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    text: "docx",
                  },
                ]}  
                token={token}
                maxFileSize="20MB"
                endpointUrl={
                  apiConfig.prefixes.attachment + "UploadCertificateFile"
                }
                allowFileSizeValidation={true}
              />
            )}
            
            {this.props.formData.auditReportAttachmentList &&
              this.props.formData.auditReportAttachmentList.length > 0 && (
                <React.Fragment>
                  <div className="title">Yüklenen Belgeler</div>
                  {JSON.stringify(
                    this.props.formData.initialauditReportList
                  ) !== JSON.stringify(this.props.formData.auditReportList) && (
                      <div className="file-save-warning">
                        Düzenlemeleri ve yüklenen belgeleri kaydetmek için, sağ
                        üst kısımdan "Kaydet veya Yayımla"ya basın.
                      </div>
                    )}
                  <div className="uploaded-files">
                    {(this.props.formData?.auditReportAttachmentList || []).map(
                      (
                        item: {
                          attachmentId: number;
                          attachmentPath: string;
                          fileName: string;
                          fileSize: number;
                        },
                        index: number
                      ) => (
                        <div key={index} className="uploaded-file">
                          <div className="name row">
                            <span className="text col no-padding">
                              {item.fileName}
                              <strong className="size m-left-auto">
                                {formatSize(item.fileSize)}
                              </strong>
                            </span>
                            <div className="buttons m-left-auto row">
                              <button
                                onClick={() =>
                                  window.open(
                                    qrUrl + item.attachmentPath,
                                    "_blank"
                                  )
                                }
                                className="view"
                              >
                                <i className="icon-explore"></i> Görüntüle
                              </button>
                              {(usertype === "1" || usertype === "3") && (
                                <button
                                  onClick={() => {
                                    let files = _.cloneDeep(
                                      this.props.formData
                                        ?.auditReportAttachmentList
                                    );
                                    let findIndex = (files || []).findIndex(
                                      (x: any) =>
                                        x.attachmentId === item.attachmentId
                                    );
                                    if (findIndex !== -1) {
                                      files.splice(findIndex, 1);
                                    }
                                    this.props.setFormData(
                                      "auditReportAttachmentList",
                                      files
                                    );
                                  }}
                                  className="remove"
                                >
                                  <i className="icon-close"></i>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </React.Fragment>
              )}
          </div>
          {formData.auditResult && formData.auditResult !== 1 && (
            <div className="form-wrapper">
              <div className="title">Uygunsuzluk Raporu</div>
              {(usertype == 1 ||
                usertype == 3 ||
                (usertype == 2 && formData.finalResultOnPage === 1)) && (
                  <FileUpload
                    onProcessStartFile={() => {
                      this.setState({
                        isFileUploading: true,
                      });
                    }}
                    fileTypes={[
                      {
                        key: "image/jpeg",
                        text: "jpeg",
                      },
                      {
                        key: "image/jpg",
                        text: "jpg",
                      },
                      {
                        key: "image/png",
                        text: "png",
                      },
                      {
                        key: "application/pdf",
                        text: "pdf",
                      },
                      {
                        key: "application/vnd.ms-excel",
                        text: "xls",
                      },
                      {
                        key: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        text: "xlsx",
                      },
                      {
                        key: "application/msword",
                        text: "doc",
                      },
                      {
                        key: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        text: "docx",
                      },
                    ]}      
                    onProcessEndFile={(queue: boolean) => {
                      if (!queue) {
                        this.setState({
                          isFileUploading: false,
                        });
                      }
                    }}
                    onTransferedFile={(file: any, promise?: boolean) => {
                      this.props.setFormData("auditNotSuitableAttachmentList", [
                        ...(this.props.formData?.auditNotSuitableAttachmentList ||
                          []),
                        file.attachmentIdList[0],
                      ]);
                    }}
                    requestKey="files"
                    token={token}
                    maxFileSize="20MB"
                    endpointUrl={
                      apiConfig.prefixes.attachment + "UploadCertificateFile"
                    }
                  />
                )}

              <React.Fragment>
                <div className="title">Yüklenen Belgeler</div>
                {JSON.stringify(
                  this.props.formData.initialauditNotSuitableAttachmentList
                ) !==
                  JSON.stringify(
                    this.props.formData.auditNotSuitableAttachmentList
                  ) && (
                    <div className="file-save-warning">
                      Düzenlemeleri ve yüklenen belgeleri kaydetmek için, sağ üst
                      kısımdan "Kaydet veya Yayımla"ya basın.
                    </div>
                  )}
                <div className="uploaded-files">
                  {(
                    this.props.formData?.auditNotSuitableAttachmentList || []
                  ).map(
                    (
                      item: {
                        attachmentId: number;
                        attachmentPath: string;
                        fileName: string;
                        fileSize: number;
                      },
                      index: number
                    ) => (
                      <div key={index} className="uploaded-file">
                        <div className="name row">
                          <span className="text col no-padding">
                            {item.fileName}
                            <strong className="size m-left-auto">
                              {formatSize(item.fileSize)}
                            </strong>
                          </span>
                          <div className="buttons m-left-auto row">
                            <button
                              onClick={() =>
                                window.open(
                                  qrUrl + item.attachmentPath,
                                  "_blank"
                                )
                              }
                              className="view"
                            >
                              <i className="icon-explore"></i> Görüntüle
                            </button>
                            {(usertype == 1 || usertype == 3) && (
                              <button
                                onClick={() => {
                                  let files = _.cloneDeep(
                                    this.props.formData
                                      ?.auditNotSuitableAttachmentList
                                  );
                                  let findIndex = (files || []).findIndex(
                                    (x: any) =>
                                      x.attachmentId === item.attachmentId
                                  );
                                  if (findIndex !== -1) {
                                    files.splice(findIndex, 1);
                                  }
                                  this.props.setFormData(
                                    "auditNotSuitableAttachmentList",
                                    files
                                  );
                                }}
                                className="remove"
                              >
                                <i className="icon-close"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </React.Fragment>
            </div>
          )}
        </div>
      </div>
    );
  }
  showDialog(req: any): any {
    this.props.showErrorDialog(req);
  }
  onEditFacility() {
    this.props.formData.facilityType === 5 &&
      window.open("/hotel/edit/" + this.props.formData.relatedId);
    this.props.formData.facilityType === 6 &&
      window.open("/Congress/edit/" + this.props.formData.relatedId);
    this.props.formData.facilityType === 7 &&
      window.open("/SeaTourism/edit/" + this.props.formData.relatedId);
    this.props.formData.facilityType === 8 &&
      window.open("/ThemedFacility/edit/" + this.props.formData.relatedId);
    this.props.formData.facilityType === 9 &&
      window.open("/WinterTourism/edit/" + this.props.formData.relatedId);
  }
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Ek Denetim Ekle"
          titleRenderer={() => (
            <span className="edit-title">
              Yönet:
              <strong>Ek Denetim Düzenle</strong>
            </span>
          )}
          showBack
          backUrl="/AdditionalControl"
          history={this.props.history}
          subTitle="Yeni bir ek denetim düzenlemek için aşağıdaki alanları doldurunuz."
        />
        {this.renderMessageBar()}
        {((this.props.formData.auditResult !== 1 && usertype === "2") ||
          usertype !== "2") && (
            <FormButtons
              isAbsolute
              onSubmit={() => this.onUpdate()}
              customRenderButtons={() => (
                <React.Fragment>
                  {usertype != "5" && (
                    <PrimaryButton
                      onClick={() => this.onUpdate()}
                      disabled={
                        this.state.messageType === MessageBarType.success ||
                          this.state.isFileUploading
                          ? this.state.isFileUploading
                            ? true
                            : false
                          : false
                      }
                      styles={{
                        rootDisabled: {
                          background: "#626262",
                          borderColor: "#777373 !important",
                        },
                      }}
                      iconProps={{ iconName: "CheckMark" }}
                      text="Yayımla"
                    />
                  )}
                  {this.state.isFileUploading && (
                    <div className="tooltip-warning">
                      Bazı dosyalar yükleniyor, lütfen dosyalar yüklenene kadar
                      bekleyiniz.
                    </div>
                  )}
                </React.Fragment>
              )}
            />
          )}
        <PrimaryButton
          onClick={() => this.onEditFacility()}
          className="AdditionalButton"
          styles={{
            rootDisabled: {
              background: "#ececec",
              borderColor: "#ccc !important",
              opacity: 0.5,
            },
          }}
          text={"Tesis Bilgilerini Görüntüle"}
        />
        {this.renderForm()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateHotelCertification);
