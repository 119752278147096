import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { panels } from "src/utils/constants";
import { Link } from "react-router-dom";
import { History } from "history";
import BasicTable from "../BasicTable";
import {
  TextField,
  PrimaryButton,
  Modal,
  Icon,
  DefaultButton,
} from "office-ui-fabric-react";
import {
  getAllHotel,
  getDeletedHotelList,
  changeStatus,
  getAllForPager,
  getAllHotelForExcel,
  getDeleteAllForPager,
} from "src/store/thunkHotel";
import { MergeHotel } from "src/store/thunkHotelCertification";
import {
  getMergedCertificateList,
  restoreMergedHotelCertificate,
} from "src/store/thunkHotelTransferBack";
import { GetHotelListModel } from "src/services/Hotel/RequestModel";

import { StatusEnums } from "src/services/BaseEnum";
import { castThunkAction } from "src/helpers/casting";
import { toggleConfirmDialog } from "src/store/system/actions";

import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import { getAllCR } from "src/store/thunkCertificateStatusType";
import { getCompanyUserList } from "src/store/thunksSystemUser";

import { CityGetAllRequestModel } from "src/services/City/RequestModel";

import { getAllLSU } from "src/store/thunkLayoverSubCategory";

import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";

import { LayoverSubCategoryGetAllRequestModel } from "src/services/LayoverSubCategory/RequestModel";

import {
  HotelTransferBackCertificationRequestGetMergedCertificateListModel,
  HotelTransferBackCertificationRequestRestoreMergedHotelCertificateModel,
} from "src/services/HotelTransferBackCertification/RequestModel";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        getAllHotel,
        changeStatus,
        toggleConfirmDialog,
        getAllM,
        getAllC,
        getAllLSU,
        getAllCR,
        getCompanyUserList,
        getAllHotelForExcel,
        getAllForPager,
        getDeletedHotelList,
        getDeleteAllForPager,
        MergeHotel,
        getMergedCertificateList,
        restoreMergedHotelCertificate,
      },
      dispatch
    ),
  };
}

type LayoverProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  pagerTotal: number;
  download: boolean;
  excel: boolean;
  oldVersionHotelId: number;
  oldVersionHotelName: string;
  section: number;
  modalShow: boolean;
  takeBack: number;
  mergedHotelId: number;
  hotelName: string;
  transferId: number;
  transferHotelName: string;
  name: string;
}
// let usertype: any = 0;

const intitialState = {
  takeBack: 0,
  oldVersionHotelId: 0,
  section: 1,
  modalShow: false,
  filterData: {
    orderField: "UpdatedSystemUserDatetime",
    isDescending: true,
    mergedHotelId: 0,
    mergedHotelBrandName: "",
    transferId: 0,
    hotelBrandName: "",
  },
  pagerCurrent: 0,
  pagerTotal: 0,
  download: false,
  excel: false,
}

class Layover extends Component<LayoverProps, OwnState> {
  state: any = intitialState;


  componentDidMount() {

    this.getData(this.state.pagerCurrent)

  }

  getData(pagerSelected: number) {
    const request: HotelTransferBackCertificationRequestGetMergedCertificateListModel = {
      pageSize: 5,
      pageIndex: pagerSelected,
      mergedHotelId: 0,
      transferredHotelId: 0,
      status: 1,
      ...this.state.filterData,
    };
    castThunkAction(
      this.props.getMergedCertificateList(request)
    ).then((response: any) => { });
  }

  getDeleteData(pagerSelected: number) {
    const request: GetHotelListModel = {
      pageSize: 5,
      pageIndex: pagerSelected,
      ...this.state.filterData,
    };

    castThunkAction(this.props.getDeletedHotelList(request)).then(
      (response: any) => {
        const request2: CityGetAllRequestModel = {
          selectItemList: ["CityId", "CityName"],
          orderField: "CityName",
        };

        this.props.getAllM(request2);

        const request3: LayoverSubCategoryGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "Status",
              value: 1,
              isOr: false,
              conditionOperator: 0,
            },
          ],
          selectItemList: ["LayoverSubCategoryId", "LayoverSubCategoryName"],
        };
        this.props.getAllLSU(request3);

        const request6: SystemUserGetAllModel = {
          systemUserType: 2,
        };
        this.props.getCompanyUserList(request6);
      }
    );
  }

  getDeleteDataPager(pagerSelected: number) {
    this.setState({ excel: false });
    const request: GetHotelListModel = {
      pageSize: 5,
      pageIndex: pagerSelected,
      ...this.state.filterData,
    };

    this.props.getDeleteAllForPager(request);
  }

  restoreMergedHotelCertificate() {
    const request: HotelTransferBackCertificationRequestRestoreMergedHotelCertificateModel = {
      id: this.state.transferId,
    };
    castThunkAction(this.props.restoreMergedHotelCertificate(request)).then(
      (response: any) => {
       if(response){
        this.getData(this.state.pagerCurrent);
       }
        // window.location.reload();
      }
    );
  }

  renderDashboardItems() {
    return (
      <div className="dashboard-items row">
        {panels.map(
          (item, index) =>
            item.children && (
              <React.Fragment>
                <div className="title">
                  {item.text}
                  <div className="title-text">{item.subText}</div>
                </div>
                <div className="childrens row">
                  {item.children.map((children, childrenIndex) => (
                    <div key={index} className={`dashboard-item col-md-3`}>
                      <Link
                        className="row"
                        to={children.url ? children.url : "/"}
                      >
                        <div className="icon">
                          <i className={children.icon}></i>
                        </div>
                        <div className="title">
                          {children.text}
                          <div className="sub-text">{children.subText}</div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )
        )}
      </div>
    );
  }
  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Tümünü Seç" });
    }

    return newArray;
  }

  Merge() {
    this.setState({ modalShow: false });
    // const request2: MergeHotelCertificate = {
    //   hotelId: this.state.hotelId,
    //   oldVersionHotelId: this.state.oldVersionHotelId,
    // };
    // this.props.MergeHotel(request2);

    this.restoreMergedHotelCertificate();
    // this.getData(1);
  }

  renderFilter() {
    return (
      <div className="table-filter row">
        <TextField
          className="custom-textfield col-md-2"
          label="Silinen Tesis ID"
          value={this.state.filterData?.transferredHotelId || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                transferredHotelId: Number(event.target.value),
              },
            });
          }}
        />
        <TextField
          className="custom-textfield col-md-2"
          label="Silinen Tesis Adı"
          value={this.state.filterData?.hotelBrandName || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                hotelBrandName: event.target.value !== "" ? event.target.value : "",

              },
            });
          }}
        />
        <TextField
          className="custom-textfield col-md-2"
          label="Yeni Esas Tesis ID"
          value={this.state.filterData?.mergedHotelId || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                mergedHotelId: Number(event.target.value),
              },
            });
          }}
        />
        <TextField
          className="custom-textfield col-md-2"
          label="Yeni Esas Tesis Adı"
          value={this.state.filterData?.mergedHotelBrandName || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                mergedHotelBrandName: event.target.value !== "" ? event.target.value : "",

              },
            });
          }}
        />
        <PrimaryButton
          text="Arama Yap"
          className="custom-button"
          onClick={() => this.getData(0)}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />

        {/* {usertype === "3" ||
          (usertype === "1" && (
            <PrimaryButton
              text="Silindi Otel Kayıtlarında Arama Yap"
              className="custom-button"
              onClick={() => (
                cookie.save("hotelListState", JSON.stringify(this.state), {
                  path: "/",
                }),
                this.getDeleteDataPager(0)
              )}
              style={{ marginLeft: "15px" }}
              styles={{
                root: {
                  fontSize: "11px",
                  marginTop: "27px",
                  height: "30px",
                },
              }}
            />
          ))} */}

        <PrimaryButton
          text="Tüm Filtreleri Temizle"
          className="custom-button"
          iconProps={{ iconName: "trash" }}
          onClick={() =>
            this.setState(intitialState, () => this.getData(0))
          }
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
              background: "#FF4343",
              borderColor: "#FF4350",
            },
          }}
        />
      </div>
    );
  }

  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "transferredHotelId",
            text: "Silinen Tesis ID",
            col: "col-md-1",
          },
          {
            columnName: "transferredHotelCityName",
            text: "İl / İlçe",
            col: "col-md-1",
          },
          {
            columnName: "transferredHotelCompanyName",
            text: "Belge Sahibi / Ticari Ünvan",
            col: "col-md-2",
          },

          {
            columnName: "transferredHotelBrandName",
            text: "Silinen Tesis Adı",
            col: "col-md-2",
          },

          {
            columnName: "mergedHotelId",
            text: "Yeni Esas Tesis ID",
            col: "col-md-1",
          },
          {
            columnName: "mergedCityName",
            text: "İl / İlçe",
            col: "col-md-1",
          },
          {
            columnName: "mergedCompanyName",
            text: "Belge Sahibi / Ticari Ünvan",
            col: "col-md-2",
          },

          {
            columnName: "mergedBrandName",
            text: "Yeni Esas Tesis Adı",
            col: "col-md-1",
          },
          {
            columnName: "hotelId",
            text: "İşlem",
            col: "col-md-1",
            className: "operations",
            onRender: (item: {
              hotelId: any;
              Status: StatusEnums;
              mergedBrandName: string;
              transferredHotelId: number;
              transferredHotelBrandName: string;
              mergedHotelBrandName: string;
              transferredHotelCompanyName: string;
              mergedHotelId: number;
            }) => (
              <React.Fragment>
                <button
                  style={{
                    backgroundColor:
                      this.state.section === 1
                        ? this.state.oldVersionHotelId === item.hotelId
                          ? "red"
                          : "red"
                        : this.state.hotelId === item.hotelId
                          ? "red"
                          : "red",
                  }}
                  onClick={(x) =>
                    this.setState({
                      transferId: item.transferredHotelId,
                      mergedHotelId: item.mergedHotelId,
                      hotelName: item.mergedBrandName,
                      modalShow: true,
                      name: item.transferredHotelCompanyName,
                    })
                  }
                >
                  <i className="icon-edit-outline" /> Geri Aktar
                </button>
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.dataList || []}
      />
    );
  }

  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.props.formData.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }
  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getData(selected);
  };
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Otel Kayıtları Aktarımını Geri Alma"
          subTitle="Buradan Aktarılan Otel Kayıtlarınızı Geri Alabilirsiniz"
          backUrl="/"
          showBack
          history={this.props.history}
        />

        {this.renderFilter()}
        {this.renderList()}
        {this.renderPager()}

        {this.state.oldVersionHotelId !== 0 && this.state.section === 1 && (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <React.Fragment>
              {
                <PrimaryButton
                  text="Aktarımı Geri Alınacak Otelin Seçim Ekranına Git"
                  className="custom-button"
                  onClick={(x) => {
                    this.setState({ section: 2 });
                    this.setState({ takeBack: 1 });
                  }}
                  style={{ marginLeft: "15px" }}
                  styles={{
                    root: {
                      fontSize: "16px",
                      marginTop: "27px",
                      height: "30px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    },
                  }}
                />
              }
            </React.Fragment>
          </div>
        )}

        {this.state.modalShow && (
          <Modal className={"warning custom-message-box danger"} isOpen={true}>
            <div className="modal-header row">
              <div className="title">Geri Aktarım Yapılacak Uyarı!</div>
              <span
                onClick={() => this.setState({ modalShow: false })}
                className="close"
              >
                <Icon iconName="ChromeClose" />
              </span>
            </div>
            <div
              style={{ paddingBottom: 0 }}
              className="description modal-content"
            >
              <div className="modal-indicators">
                <h3>
                  {this.state.transferId &&
                    this.state.hotelName &&
                    this.state.hotelName +
                    " (ID " +
                    this.state.mergedHotelId +
                    ") => Data Geri Aktarılacak Tesis: " +
                    this.state.name +
                    " (ID " +
                    this.state.transferId +
                    ")"}
                </h3>
                <h2>Onaylıyor musunuz?</h2>

                <DefaultButton
                  style={{
                    marginTop: 15,
                    marginRight: 10,
                  }}
                  text="Evet"
                  onClick={() => {
                    this.Merge();
                  }}
                />
                <DefaultButton
                  style={{
                    marginTop: 15,
                  }}
                  text="Hayır"
                  onClick={() => {
                    this.setState({ modalShow: false });
                  }}
                />
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layover);
