import React from "react";
import Loader from "./Loader";
import { Dispatch, bindActionCreators } from "redux";
import { RootState } from "../store/reducer";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { History } from "history";
import Login from "./Login";
import SystemUserService from "src/services/SystemUser/SystemUserService";
import {
  tokenControl,
  setUserInfo,
  toggleVerificationLoader,
} from "src/store/system/actions";
import cookie from "react-cookies";

// import SystemUserService from 'src/services/SystemUser/SystemUserService';

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    userInfo: state.system.userInfo,
    tokenStatus: state.system.tokenStatus,
    verificationLoader: state.system.verificationLoading,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        tokenControl,
        setUserInfo,
        toggleVerificationLoader,
      },
      dispatch
    ),
  };
}

const systemUserInfo = cookie.load("systemUserInfo");
const title = cookie.load("systemUserName");
const token = cookie.load("userId");

type AuthVerificationProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

// var checkToken = true;

class AuthVerification extends React.Component<AuthVerificationProps> {
  async UNSAFE_componentWillMount() {

    if (token && systemUserInfo) {
      const systemUserService = new SystemUserService();
      this.props.tokenControl(true);
      this.props.toggleVerificationLoader();
      const result = await systemUserService.checkToken();
      this.props.toggleVerificationLoader();
      this.props.setUserInfo({ ...systemUserInfo, token, title: title });

      if (!result.success) {
        this.props.tokenControl(false);
      }
    }
  }

  renderChildren() {
    if (this.props.tokenStatus) {
      return this.props.children;
    } else {
      return <Login history={this.props.history} />;
    }
  }

  render() {
    return (
      <>
        <Loader
          show={this.props.verificationLoader}
          className="verification-loader"
          label="Kullanıcı doğrulanıyor, lütfen bekleyiniz.."
        />
        {/* 
				{
					setTimeout(() => {
						if (!checkToken) {
							this.returnLogin()
						} else {
							this.renderChildren()
						}
					}, 2000)
				} */}

        {this.renderChildren()}
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthVerification)
);
