import { HttpClient } from "src/library/HttpClient";
import {
  VehicleDocumentTypeGetAllRequestModel,
  VehicleDocumentTypeSaveRequestModel,
  VehicleDocumentTypeUpdateRequestModel,
  VehicleDocumentTypeGetByIdRequestModel,
  VehicleDocumentTypeChangeStatusRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class VehicleDocumentType {
  async getAll(request: VehicleDocumentTypeGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.VehicleDocumentType + "GetAll",
      { ...request, token }
    );
  }

  async save(request: VehicleDocumentTypeSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.VehicleDocumentType + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: VehicleDocumentTypeUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.VehicleDocumentType + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(request: VehicleDocumentTypeGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.VehicleDocumentType + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(
    request: VehicleDocumentTypeChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.VehicleDocumentType + "ChangeStatus",
      { ...request, token }
    );
  }
}
