import cookie from "react-cookies";
import _ from "lodash";
import {
  CategoryTypeEnum,
  CertificateStatusTypeEnum,
  CertificateStatusTypeEnum_V2,
} from "src/services/Statistic/RequestModel";
import { showMessage } from "src/store/system/actions";
import { MessageType } from "src/store/system/types";
import { StatusEnums } from "src/services/BaseEnum";
import { toDate } from "./converts";
import { certificateKeys, RECORD_CERTIFICATION_STATUS } from "./enums";

export function filterModal(_this: any, cookieFilterName: any) {
  return new Promise((resolve, reject) => {
    const data = cookie.load(cookieFilterName);

    if (data !== undefined) {
      _this.props.toggleConfirmFilterRemoveDialog(
        true,
        "Eski Filtre Datası Kullanılsın mı ?",
        (x: any) => {
          if (x) {
            _this.setState(data, () => {
              resolve(true);
            });
          } else {
            cookie.remove(cookieFilterName);
            resolve(true);
          }
        }
      );
    } else {
      reject(false);
    }
  });
}

export function capitalize(str: string): string {
  return str && str.length > 0
    ? turkishToUpper(str.charAt(0)).concat(str.slice(1))
    : str;
}
export const turkishToUpper = function (string: string) {
  var letters: any = { i: "i", ş: "Ş", ğ: "Ğ", ü: "Ü", ö: "Ö", ç: "Ç", ı: "I" };
  string = string.replace(/(([iışğüçö]))/g, function (letter) {
    return letters[letter];
  });
  return string.toUpperCase();
};

export function convertToQueryString(obj: Object): string {
  return _.keys(obj)
    .filter((key) => (obj[key] !== undefined && obj[key]) || (obj[key] === false))
    .map((key) => {
      if (obj[key] || (obj[key] === false)) {
        return capitalize(key) + "=" + obj[key];
      } else {
        return "";
      }
    })
    .join("&");
}

export function removeArrayItem(index: number, array: any) {
  array.splice(index, 1);
  return array;
}

export const cityReturnOptions = (array: any[], key: string, text: string) => {
  let newArray: any[] = array.filter((item: any) => item.CityId !== 84);
  if (newArray.filter((x) => x[key] === 0).length === 0) {
    //newArray.unshift({ [key]: 84, [text]: "BELİRTİLMEMİŞ" });
    newArray.unshift({ [key]: 0, [text]: "Tümünü Seç" });
  }

  return newArray;
};


export const dropDownReturnOptions = (array: any[], key: string, text: string) => {
  let newArray: any[] = array.filter((item: any) => item.CityId !== 84);
  if (newArray.filter((x) => x[key] === 0).length === 0) {
    //newArray.unshift({ [key]: 84, [text]: "BELİRTİLMEMİŞ" });
    newArray.unshift({ [key]: 0, [text]: "Seçiniz" });
  }

  return newArray;
};

export const getExcelRevizeStatus = (key: any) => {
  return [
    { key: 1, text: "Revize İstendi" },
    { key: 2, text: "Revize Yapıldı" },
    { key: 3, text: "Revize Onaylandı" },
    { key: null, text: "Revize Yok" },
  ].filter((x) => x.key === key)[0].text;
};

export const getExcelRevizeStatusEn = (key: any) => {
  return [
    { key: 1, text: "Revised Requested" },
    { key: 2, text: "Revised" },
    { key: 3, text: "Revised Approved" },
    { key: null, text: "Non Revision" },
  ].filter((x) => x.key === key)[0].text;
};

function escapeRegExp(string: string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}
export function replaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}

export const isNumberValue = (value: any) => {
  if (typeof value === 'string' && value !== "") {
    const re = /^\+?(0|[1-9]\d*)$/gm;

    let valueTrim = replaceAll(value, "e", "");
    valueTrim = replaceAll(value, "-", "");
    if (valueTrim !== "" && re.test(valueTrim)) {
      return Number(parseInt(valueTrim));
    } else {
      return valueTrim ? parseInt(valueTrim) : 0;
    }
  }
  else {
    return 0
  }
};

// if value is not blank, then test the regex

export const CATEGORY_TYPE_ENUM = [
  {
    key: 12,
    text: "Tümü",
  },
  {
    key: CategoryTypeEnum.Restaurant,
    text: "Restoran / Kafe Tesisi",
  },
  {
    key: CategoryTypeEnum.Transport,
    text: "Tur ve Transfer",
  },
  {
    key: CategoryTypeEnum.Hotel,
    text: "Konaklama Tesisi",
  },
  {
    key: CategoryTypeEnum.Congress,
    text: "Kongre ve Sanat Tesisi",
  },
  {
    key: CategoryTypeEnum.SeaTourism,
    text: "Deniz Turizm Tesisi",
  },
  {
    key: CategoryTypeEnum.ThemedFacility,
    text: "Temalı Tesis",
  },
  {
    key: CategoryTypeEnum.WinterTourism,
    text: "Kış Turizm Tesisi",
  },
];

export const CERTIFICATE_STATUS_TYPE_ENUM = [
  {
    key: CertificateStatusTypeEnum.Certificated,
    text: "Sertifikalı",
  },
  {
    key: CertificateStatusTypeEnum.UnCertificated,
    text: "Sertifikasız",
  },
  {
    key: CertificateStatusTypeEnum.CertificationProcessAndNotExtendedClosed,
    text: "Belgelendirme Sürecinde ve Sertifika Uzatmalı Kapalı Olmayanlar",
  },
  {
    key: CertificateStatusTypeEnum.CertificationProcessAndExtendedClosed,
    text: "Belgelendirme Sürecinde ve Sertifika Uzatmalı Kapalı Olanlar",
  },
];
export const CERTIFICATE_STATUS_TYPE_ENUM_V2 = [
  {
    key: 5,
    text: "Tümü",
  },
  {
    key: CertificateStatusTypeEnum_V2.Certificated,
    text: "Sertifikalı",
  },
  {
    key: CertificateStatusTypeEnum_V2.UnCertificated,
    text: "Sertifikasız",
  },
  {
    key: CertificateStatusTypeEnum_V2.CertificationProcess,
    text: "Belgelendirme Sürecinde",
  },
  {
    key: CertificateStatusTypeEnum_V2.NotSuitable,
    text: "Uygun görülmedi",
  },
];

export const getCertificateStatusType = (key: any) => {
  return CERTIFICATE_STATUS_TYPE_ENUM.filter((x) => x.key === key)[0].text;
};

export const getCategoryType = (key: any) => {
  return CATEGORY_TYPE_ENUM.filter((x) => x.key === key)[0].text;
};
export const getDenunciation = (key: any) => {
  if (key === 1)
    return "HES";
  else
    return "Sertifika Mobil";
};

export const errorMessageSaveOrUpdate = (dispatch: any, result: any) => {
  if (result.errorCode === 87) {
    dispatch(
      showMessage(
        result.errorMessage,
        "Uyarı!",
        MessageType.ERROR,
        "Sertifika No Benzerliği"
      )
    );
  } else {
    dispatch(showMessage(result.errorMessage, "Hata!", MessageType.ERROR, ""));
  }
};

export const onSaveText = (status: number) => {
  //task https://b24-z1nwey.bitrix24.com.tr/workgroups/group/8/tasks/task/view/1306/
  // return status === StatusEnums.Published ? "Kaydet" : "Kaydet";
  return "Kaydet";
};

export const requestMapStatusData = (formData: any, isDraft?: boolean) => {
  return {
    data: requestMapData({
      ...formData,
      status: isDraft ? StatusEnums.Draft : formData.status,
    }),
  };
};

export const certificateAppealStatusDate = (
  userType: number | string = "",
  edit: boolean = false,
  editDate: any = null
) => {
  if (edit) {
    if (editDate !== null) {
      return toDate(editDate, "");
    }
    return;
  } else {
    if (userType === "2") {
      return new Date(Date.now() - 12096e5);
    } else {
      return;
    }
  }
};

export const RECORD_STATUS_ALL_ENUM = [
  { key: StatusEnums.All, text: "Tümü" },
  ...RECORD_CERTIFICATION_STATUS,
];

export const REPORT_TYPE_ENUM = [
  { key: 0, text: "Tümü" },
  { key: 1, text: "Yapıldı" },
  { key: 2, text: "Yapılmadı" },
  { key: 3, text: "Bekleniyor" },
];

export const getReportType = (key: number) => {
  return REPORT_TYPE_ENUM.filter((x) => x.key === key)[0].text;
};

export const getRecordStatusAll = (key: number) => {
  return RECORD_STATUS_ALL_ENUM.filter((x) => x.key === key)[0].text;
};

export const getRecordStatus = (key: any) => {
  return RECORD_STATUS_ALL_ENUM.filter((x) => x.key === key)[0].text;
};
export const RECORD_STATUS_ENUM = [
  { key: StatusEnums.All, text: "Tümü" },
  { key: StatusEnums.Published, text: "Yayımda" },
  { key: StatusEnums.Deleted, text: "Silindi" },
];

export const sertifikaTarihiBayram = (user: string | number) => {
  let certificateDate = new Date();

  if (String(user) === "1" || String(user) === "3") {
    certificateDate.setDate(certificateDate.getDate() + 365);
  } else if (String(user) === "2") {
    certificateDate.setDate(certificateDate.getDate() + 3);
  }

  console.log(certificateDate, "certificateDate", user);

  return certificateDate;
};

export function getChangesFieldGlobal(
  usertype: any,
  oldData: any,
  formData: any
): any {
  var promise = new Promise((resolve: any, reject) => {
    let changesFiled = [];

    for (let index = 0; index < Object.keys(oldData).length; index++) {
      if (
        oldData[Object.keys(oldData)[index]] !==
        formData[Object.keys(formData)[index]]
      ) {
        certificateKeys.filter((x) => x.en === Object.keys(oldData)[index])
          .length > 0 &&
          changesFiled.push({
            ...certificateKeys.find(
              (x) => x.en === Object.keys(oldData)[index]
            ),
          });
      }
    }

    resolve(changesFiled);
  });
  return promise;
}

export const formatSize = (n: number): string => {
  if (n < 1e3) return `${n} Byte`;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "KB";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "MB";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "GB";
  return `${n}`;
}
export const requestMapData = (formData: any) => {
  return {
    ...formData,
    vehicleListForModal: {},
    GroupListVD: {},
    GroupListTY: {},
    GroupListCR: {},
    CompanyUserList: [],
    GroupListVT: {},
    result: {},
    excel: {},
    CongressList: {},
    CongressListForModal: {},
    GroupListC: {},
    GroupListFA: {},
    GroupListSU: {},
    GroupListMA: {},
    GroupList: {},
    CompanyInfo: {},
    ThemedFacilityList: {},
    HospitalList: {},
    HotelSerticationList: {},
    allVersionAttachmentCertificateList: {},
    attachmentList: {},
    attachmentCertificateList: {}
  };
};
