import { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { History } from "history";
import { toggleConfirmDialog } from "src/store/system/actions";
import { setFormData } from "src/store/form/actions";
// @ts-ignore
import {
  add3Hour,
  toDate,
  toTimeStamp,
  toTimeStampNow,
  toTimeStampWithDate,
} from "src/helpers/converts";
import {
  ComboBox,
  DatePicker,
  Dropdown,
  Label,
  PrimaryButton,
  TextField,
} from "office-ui-fabric-react";
import BasicTable from "../BasicTable";
import ReactPaginate from "react-paginate";
import {
  DayPickerStrings,
  firstDayOfWeek,
  listCount,
  onFormatDate,
} from "src/config/otherConfig";
import { castThunkAction } from "src/helpers/casting";
import {
  getGetCertificateReport,
  excelDownloadCertificateReport,
} from "src/store/thunkStatistic";
import cookie from "react-cookies";
import { ICertificationActionReportRequest } from "src/services/Statistic/RequestModel";
// @ts-ignore
import ReactExport from "react-export-excel";
import {
  CATEGORY_TYPE_ENUM,
  cityReturnOptions,
  isNumberValue,
  // CERTIFICATE_STATUS_TYPE_ENUM_V2,
} from "src/helpers/functions";
import {
  ConditionOperatorEnum,
  CountyGetAllRequestModel,
} from "src/services/County/RequestModel";
import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import { getCompanyUserList } from "src/store/thunksSystemUser";
import { getAllCR } from "src/store/thunkCertificateStatusType";

import { CityGetAllRequestModel } from "src/services/City/RequestModel";
import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";
import { CertificateStatusTypeGetAllRequestModel } from "src/services/CertificateStatusType/RequestModel";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        toggleConfirmDialog,
        setFormData,
        getGetCertificateReport,
        excelDownloadCertificateReport,
        getAllM,
        getAllC,
        getCompanyUserList,
        getAllCR,
      },
      dispatch
    ),
  };
}

type StatisticProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  pagerTotal: number;
  download: boolean;
  excel: boolean;
}

const initialState = {
  filterData: {
    certificateStartDate: toTimeStampNow(),
    categoryType: 12,
    certificateStatusType: 5,
    startDate: new Date(),
    facilityId: 0,
    cityId: 0,
    districtId: 0,
    brandName: "",
    companyName: "",
    companyUserId: 0,
    certificateStatusTypeId: 0,
    certificateNo: "",
  },
  pagerCurrent: 0,
  pagerTotal: 0,
  download: false,
  excel: false,
};

class CertificationActionReport extends Component<StatisticProps, OwnState> {
  state = initialState;

  UNSAFE_componentWillMount() {
    this.getData(this.state.pagerCurrent);

    const request2: CityGetAllRequestModel = {
      selectItemList: ["CityId", "CityName"],
      orderField: "CityName",
    };

    this.props.getAllM(request2);

    const request6: SystemUserGetAllModel = {
      systemUserType: 2,
    };
    this.props.getCompanyUserList(request6);

    const request4: CertificateStatusTypeGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "Status",
          value: 1,
          isOr: false,
          conditionOperator: 0,
        },
      ],
      selectItemList: ["CertificateStatusTypeId", "Title"],
    };
    this.props.getAllCR(request4);
  }

  UNSAFE_componentWillUpdate(nextProps: any, nextState: any) {
    if (nextState.pagerTotal !== nextProps.formData.pageCount)
      this.setState({
        ...this.state,
        pagerTotal: this.props.formData.pageCount,
      });
  }

  getDate(date: any): any {
    if (date !== 0 && date !== undefined) {
      return toDate(date, "");
    }
  }

  getData(pagerSelected: number) {
    const request: ICertificationActionReportRequest = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...this.state.filterData,
    };

    castThunkAction(this.props.getGetCertificateReport(request)).then(() => {
      let lbl =
        this.state.filterData?.certificateStatusType === 5
          ? "Toplam Sertifika Almış " +
            (this.state.filterData?.categoryType === 12
              ? "Tesis ve Araç"
              : CATEGORY_TYPE_ENUM.find(
                  (x) => x.key === this.state.filterData?.categoryType
                )?.text) +
            " Sayısı "
          : "Aktif Sertifikalı " +
            (this.state.filterData?.categoryType === 12
              ? "Tesis ve Araç"
              : CATEGORY_TYPE_ENUM.find(
                  (x) => x.key === this.state.filterData?.categoryType
                )?.text) +
            " Sayısı ";
      console.log("lblb", lbl);
      this.props.setFormData("dataCountLabelText", lbl);
    });
  }

  getDataPager(pagerSelected: number) {
    const request: ICertificationActionReportRequest = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...this.state.filterData,
    };
    this.setState({
      excel: false,
      download: false,
    });
    castThunkAction(this.props.getGetCertificateReport(request)).then(() => {
      let lbl =
        this.state.filterData?.certificateStatusType === 5
          ? "Toplam Sertifika Almış " +
            (this.state.filterData?.categoryType === 12
              ? "Tesis ve Araç"
              : CATEGORY_TYPE_ENUM.find(
                  (x) => x.key === this.state.filterData?.categoryType
                )?.text) +
            " Sayısı "
          : "Aktif Sertifikalı " +
            (this.state.filterData?.categoryType === 12
              ? "Tesis ve Araç"
              : CATEGORY_TYPE_ENUM.find(
                  (x) => x.key === this.state.filterData?.categoryType
                )?.text) +
            " Sayısı ";
      console.log("lblb", lbl);
      this.props.setFormData("dataCountLabelText", lbl);
    });
  }

  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.props.formData?.GetCertificateReport?.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }

  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getDataPager(selected);
  };

  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "facilityId",
            text: "Tesis ID",
            col: "col-md-1",
          },
          {
            columnName: "cityDistrictName",
            text: "İl/İlçe",
            col: "col-md-1",
          },

          {
            columnName: "brandName",
            text: "Tesis adı/Plaka No",
            col: "col-md-2",
          },
          {
            columnName: "companyName",
            text: "Belge Sahibi/Ticari Unvan",
            col: "col-md-2",
          },

          {
            columnName: "companyUserTitle",
            text: "Denetleyici Firma",
            col: "col-md-3",
          },
          {
            columnName: "certificateTypeString",
            text: "Sertifika Durumu",
            col: "col-md-1",
          },
          {
            columnName: "certificateNo",
            text: "Sertifika No",
            col: "col-md-1",
          },
        ]}
        items={this.props.formData?.GetCertificateReport?.dataList || []}
      />
    );
  }
  rederexcel() {
    return (
      <>
        <PrimaryButton
          text="Rapor Oluştur ve İndir"
          className="custom-button"
          onClick={() => this.downloadexcel()}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
        {this.state.download && (
          <Label style={{ marginLeft: "10px", marginTop: "27px" }}>
            Rapor Oluşturuluyor...
          </Label>
        )}
      </>
    );
  }
  downloadexcel() {
    this.setState({ download: true });
    const requestexcel: ICertificationActionReportRequest = {
      ...this.state.filterData,
    };
    castThunkAction(
      this.props.excelDownloadCertificateReport(
        requestexcel,
        this.state.filterData.startDate
      )
    ).then((response: any) => {
      this.setState({ excel: true, download: false });
    });
  }
  cityOnChange(event: any, option: any, value: any) {
    if (value !== undefined) {
      this.CityChange(option.key);
      this.setState({
        filterData: {
          ...this.state.filterData,
          cityId: option.key,
          districtId: 0,
        },
      });
    } else {
      this.setState({
        filterData: {
          ...this.state.filterData,
          cityId: 0,
          districtId: 0,
        },
      });
      this.props.setFormData("GroupListC", []);
    }
  }
  CityChange(item: any) {
    this.setState(
      {
        filterData: {
          ...this.state.filterData,
          cityId: item,
        },
      },
      () => {
        const request2: CountyGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "CityId",
              value: item,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
            {
              propertyName: "Status",
              value: 1,
              isOr: false,
              conditionOperator: 0,
            },
          ],
          selectItemList: ["DistrictId", "DistrictName"],
          orderField: "DistrictName",
        };
        this.props.getAllC(request2);
      }
    );
  }
  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Tümünü Seç" });
    }

    // if (
    //   key === "CertificateStatusTypeId" &&
    //   newArray.filter((x) => x[key] === 9).length === 0
    // ) {
    //   newArray.push({ [key]: 9, [text]: "Sertifikasız" });
    // }

    return newArray;
  }
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Sertifika Anlık Raporu"
          subTitle="Sertifika anlık raporunu filterelere göre görüntüleyebilir veya erişebilirsiniz."
          backUrl="/Statistic"
          showBack
          history={this.props.history}
        />
        <Label className="totalRecord">
          {this.props.formData.dataCountLabelText || ""}:
          {this.props.formData?.GetCertificateReport?.dataCount}
        </Label>
        <div className="table-filter row">
          <Dropdown
            options={[
              { key: 5, text: "Tümü" },
              { key: 1, text: "Aktif Sertifikalı" },
            ]}
            className="custom-dropdown col-md-3"
            label="Rapor Tipi"
            placeholder="Seçiniz"
            selectedKey={this.state.filterData?.certificateStatusType || 5}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  certificateStatusType: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />
          <div
            className="statistic-filter-item"
            style={{ marginLeft: "15px", marginRight: "5px" }}
          >
            <DatePicker
              strings={DayPickerStrings}
              firstDayOfWeek={firstDayOfWeek}
              onSelectDate={(date) =>
                this.setState({
                  filterData: {
                    ...this.state.filterData,
                    startDate: date,
                    certificateStartDate: toTimeStampWithDate(
                      add3Hour(toDate(toTimeStamp(date?.toString())))
                    ),
                  },
                })
              }
              label="Rapor Tarihi"
              placeholder="gün.ay.yıl"
              formatDate={onFormatDate}
              value={this.getDate(this.state.filterData.certificateStartDate)}
              maxDate={new Date()}
            />
          </div>
          <div
            className="statistic-filter-item"
            style={{ marginLeft: "10px", marginRight: "5px" }}
          ></div>

          <Dropdown
            options={CATEGORY_TYPE_ENUM}
            className="custom-dropdown col-md-2"
            label="Kategori"
            placeholder="Seçiniz"
            selectedKey={this.state.filterData?.categoryType}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  categoryType: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />

          <TextField
            className="custom-textfield col-md-1"
            label="Tesis ID"
            value={
              this.state.filterData?.facilityId !== 0
                ? this.state.filterData?.facilityId.toString()
                : ""
            }
            onChange={(event: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  facilityId: isNumberValue(event.target.value),
                },
              });
            }}
            type="text"
          />
          <ComboBox
            selectedKey={
              this.state.filterData && this.state.filterData.cityId
                ? this.state.filterData.cityId
                : ""
            }
            label="İl"
            allowFreeform={true}
            autoComplete="on"
            multiSelect={false}
            className="custom-dropdown col-md-2"
            onChange={(event: any, option: any, value: any) =>
              this.cityOnChange(event, option, value)
            }
            options={(this.props.formData.GroupList?.dataList
              ? cityReturnOptions(
                  this.props.formData.GroupList?.dataList,
                  "CityId",
                  "CityName"
                )
              : ([] as any)
            ).map((item: any) => ({ key: item.CityId, text: item.CityName }))}
          />
          <Dropdown
            defaultSelectedKey={this.state.filterData?.districtId || ""}
            onChanged={(event: any) =>
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  districtId: event.key,
                },
              })
            }
            label="İlçe"
            options={(this.props.formData.GroupListC?.dataList
              ? this.returnOptions(
                  this.props.formData.GroupListC?.dataList,
                  "DistrictId",
                  "DistrictName"
                )
              : ([] as any)
            ).map((item: any) => ({
              key: item.DistrictId,
              text: item.DistrictName,
            }))}
            className="custom-dropdown col-md-2"
          />

          <TextField
            className="custom-textfield col-md-3"
            label="Tesis Adı / Plaka No"
            value={this.state.filterData?.brandName || ""}
            onChange={(event: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  brandName:
                    event.target.value !== "" ? event.target.value : "",
                },
              });
            }}
          />

          <TextField
            className="custom-textfield col-md-2"
            label="Belge Sahibi / Ticari Ünvan"
            value={this.state.filterData?.companyName || ""}
            onChange={(event: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  companyName:
                    event.target.value !== "" ? event.target.value : "",
                },
              });
            }}
          />

          <ComboBox
            options={(this.props.formData.CompanyUserList?.dataList
              ? this.returnOptions(
                  this.props.formData.CompanyUserList?.dataList,
                  "systemUserId",
                  "title"
                )
              : ([] as any)
            ).map((item: any) => ({
              key: item.systemUserId,
              text: item.title,
            }))}
            allowFreeform={true}
            autoComplete="on"
            className="custom-dropdown col-md-2"
            label="Denetleyici Firma"
            placeholder="Seçiniz"
            selectedKey={this.state.filterData.companyUserId}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  companyUserId: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />

          <Dropdown
            options={(this.props.formData.GroupListCR?.dataList
              ? this.returnOptions(
                  this.props.formData.GroupListCR?.dataList,
                  "CertificateStatusTypeId",
                  "Title"
                )
              : ([] as any)
            ).map((item: any) => ({
              key: item.CertificateStatusTypeId,
              text: item.Title,
            }))}
            className="custom-dropdown col-md-2"
            label="Sertifika Durumu"
            placeholder="Seçiniz"
            selectedKey={this.state.filterData.certificateStatusTypeId}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  certificateStatusTypeId: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />
          <TextField
            className="custom-textfield col-md-3"
            label="Sertifika No"
            value={this.state.filterData?.certificateNo || ""}
            onChange={(event: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  certificateNo:
                    event.target.value !== "" ? event.target.value : "",
                },
              });
            }}
          />

          <div
            className="statistic-filter-item"
            style={{ marginRight: "15px", marginLeft: "15px" }}
          >
            <PrimaryButton
              text="Arama Yap"
              className="custom-button"
              onClick={() => {
                cookie.save(
                  "CertificationActionReport",
                  JSON.stringify(this.state),
                  {
                    path: "/",
                  }
                );
                this.getDataPager(0);
              }}
              styles={{
                root: {
                  fontSize: "11px",
                  marginTop: "27px",
                  height: "30px",
                },
              }}
            />
          </div>
          {this.rederexcel()}

          <PrimaryButton
            text="Tüm Filtreleri Temizle"
            className="custom-button"
            iconProps={{ iconName: "trash" }}
            onClick={() =>
              this.setState(
                {
                  ...this.state,
                  filterData: initialState.filterData,
                },
                () => {
                  cookie.save(
                    "CertificationActionReport",
                    JSON.stringify(this.state),
                    {
                      path: "/",
                    }
                  );
                  this.getDataPager(0);
                }
              )
            }
            style={{ marginLeft: "15px" }}
            styles={{
              root: {
                fontSize: "11px",
                marginTop: "27px",
                height: "30px",
                background: "#FF4343",
                borderColor: "#FF4350",
              },
            }}
          />
        </div>
        <div style={{ marginTop: "30px" }}>{this.renderList()}</div>
        {this.renderPager()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificationActionReport);
