import { HttpClient } from "src/library/HttpClient";
import {
  CongressSaveRequestModel,
  GetCongressListModel,
  CongressUpdateRequestModel,
  CongressGetByIdRequestModel,
  CongressChangeStatusRequestModel,
  GetCongressCertificateListByCongressModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class Congress {
  async getAllCongress(request: GetCongressListModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Congress + "GetCongressList",
      { ...request, token }
    );
  }

  async excelDownload(request: any, name: string) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.Congress + "GenerateExcel",
      name,
      { ...request, token }
    );
  }

  async save(request: CongressSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Congress + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: CongressUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Congress + "SaveOrUpdate",
      { ...request, token }
    );
  }
  async GetCongressCertificateListByCongress(
    request: GetCongressCertificateListByCongressModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressCertification +
        "GetCongressCertificateListByCongress",
      { ...request, token }
    );
  }
  async getById(request: CongressGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Congress + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(request: CongressChangeStatusRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.Congress + "ChangeStatus",
      { ...request, token }
    );
  }
}
