import {
  FormActionTypes,
  FormStore,
  INITIAL_FORM_DATA,
  SET_FORM_ERRORS,
  RESET_FORM,
  SET_FORM_DATA,
} from "./types";

const initialState: FormStore = {
  formData: { isOpenStatus: "0" },
  formErrors: {},
};

export function formReducer(
  state = initialState,
  action: FormActionTypes
): FormStore {
  switch (action.type) {
    case INITIAL_FORM_DATA:
      return {
        formData: { ...action.formData, isOpenStatus: "0" },
        formErrors: {},
      };
    case SET_FORM_ERRORS:
      return {
        ...state,
        formErrors: action.formErrors,
      };
    case RESET_FORM:
      return {
        formData: {},
        formErrors: {},
      };
    case SET_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.key]: action.value,
        },
      };
    default:
      return state;
  }
}
