import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import ThemedFacility from "src/services/ThemedFacility/ThemedFacilityService";
import {
  ThemedFacilityChangeStatusRequestModel,
  GetThemedFacilityListModel,
  ThemedFacilityGetByIdRequestModel,
  ThemedFacilityUpdateRequestModel,
  ThemedFacilitySaveRequestModel,
  GetThemedFacilityCertificateListByThemedFacilityModel,
} from "src/services/ThemedFacility/RequestModel";
import { MessageType } from "./system/types";
import { errorMessageSaveOrUpdate } from "src/helpers/functions";
import { formatDate } from "src/helpers/converts";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllMA(
  request: GetThemedFacilityListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const ThemedFacilityService = new ThemedFacility();
    const result = await ThemedFacilityService.getAllThemedFacility(request);
    dispatch(setFormData("ThemedFacilityList", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}
export function getAllForPager(
  request: GetThemedFacilityListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const ThemedFacilityService = new ThemedFacility();
    const result = await ThemedFacilityService.getAllThemedFacility(request);
    dispatch(setFormData("dataList", result.dataList));
    dispatch(setFormData("pageCount", result.pageCount));
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAllThemedFacility(
  request: GetThemedFacilityListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const ThemedFacilityService = new ThemedFacility();
    const result = await ThemedFacilityService.getAllThemedFacility(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAllThemedFacilityForModal(
  request: GetThemedFacilityListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const ThemedFacilityService = new ThemedFacility();
    const result = await ThemedFacilityService.getAllThemedFacility(request);
    dispatch(setFormData("ThemedFacilityListForModal", result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}
export function getAllThemedFacilityForExcel(
  request: GetThemedFacilityListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const ThemedFacilityService = new ThemedFacility();
    const excelName = "TemalıTesisler" + formatDate();
    await ThemedFacilityService.excelDownload(request, excelName);
    dispatch(toggleLoader());

    return false;
  };
}

export function getAllSertificationMA(
  request: GetThemedFacilityCertificateListByThemedFacilityModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const HotelService = new ThemedFacility();
    const result =
      await HotelService.GetThemedFacilityCertificateListByThemedFacility(
        request
      );
    dispatch(setFormData("ThemedFacilitySerticationList", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: ThemedFacilitySaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const ThemedFacilityService = new ThemedFacility();
    const result = await ThemedFacilityService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          ""
        )
      );
      return result;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}
export function update(
  request: ThemedFacilityUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const ThemedFacilityService = new ThemedFacility();
    const result = await ThemedFacilityService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          ""
        )
      );
      return true;
    } else {
      errorMessageSaveOrUpdate(dispatch, result);

      return false;
    }
  };
}

export function getById(
  request: ThemedFacilityGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const ThemedFacilityService = new ThemedFacility();
    const result = await ThemedFacilityService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getByIdForThemedForCert(
  request: ThemedFacilityGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const ThemedFacilityService = new ThemedFacility();
    const result = await ThemedFacilityService.getById(request);
    dispatch(setFormData("findThemedByQSId", result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: ThemedFacilityChangeStatusRequestModel,
  isDelete: boolean = false
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const ThemedFacilityService = new ThemedFacility();
    const result = await ThemedFacilityService.changeStatus(request);
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(initialFormData(result));
      dispatch(
        showMessage(
          isDelete
            ? "Silme işlemi başarıyla değiştirildi."
            : "Kayıt durumu işlemi başarıyla değiştirildi.",
          "İşlem tamamlandı.",
          MessageType.SUCCESS,
          "",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          ""
        )
      );
      return false;
    }
  };
}
