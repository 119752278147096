import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Header from "../../components/Header";
import LeftPanel from "../../components/LeftPanel";
import { EditLayoverSubCategoryp } from "../../components/LayoverSubCategory";

export default class EditLayoverSubCategory extends React.Component<
  RouteComponentProps<any>
> {
  render() {
    return (
      <React.Fragment>
        <Header history={this.props.history} />
        <div className="row">
          <LeftPanel history={this.props.history} />
          <EditLayoverSubCategoryp
            history={this.props.history}
            LayoverSubCategoryId={this.props.match.params.LayoverSubCategoryId}
          />
        </div>
      </React.Fragment>
    );
  }
}
