import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { History } from "history";
import BasicTable from "../BasicTable";
import { TextField, Dropdown, PrimaryButton, ComboBox } from "office-ui-fabric-react";
import {
  getAll,
  changeStatus,
  getAllForPager,
  excelDownloadHotel,
} from "src/store/thunkVehicle";
import { VehicleChangeStatusRequestModel } from "src/services/Vehicle/RequestModel";
import { setFormData } from "src/store/form/actions";
import { StatusEnums } from "src/services/BaseEnum";
import { castThunkAction } from "src/helpers/casting";
import { filterModal, isNumberValue,cityReturnOptions } from "src/helpers/functions";
import {
  toggleConfirmDialog,
  toggleConfirmFilterRemoveDialog,
} from "src/store/system/actions";
import { listCount } from "src/config/otherConfig";
import { getAllCR } from "src/store/thunkCertificateStatusType";
import { getAllC } from "src/store/thunkCounty";
import { ConditionOperatorEnum,CityGetAllRequestModel} from "src/services/City/RequestModel";

import { getAllLSU } from "src/store/thunkLayoverSubCategory";

import cookie from "react-cookies";

import { LayoverSubCategoryGetAllRequestModel } from "src/services/LayoverSubCategory/RequestModel";
import { getAllVD } from "src/store/thunkVehicleDocumentType";
import { getAllVT } from "src/store/thunkVehicleType";
import { getAllM } from "src/store/thunksCity";
import { CertificateStatusTypeGetAllRequestModel } from "src/services/CertificateStatusType/RequestModel";
import { VehicleTypeGetAllRequestModel } from "src/services/VehicleType/RequestModel";
import { VehicleDocumentTypeGetAllRequestModel } from "src/services/VehicleDocumentType/RequestModel";
import { VehicleGetAllRequestModel } from "src/services/Vehicle/RequestModel";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";

// import { filterModal } from "src/helpers/functions";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        getAll,
        changeStatus,
        toggleConfirmDialog,
        toggleConfirmFilterRemoveDialog,
        getAllLSU,
        getAllCR,
        getAllC,
        getAllM,
        getAllVD,
        getAllVT,
        getAllForPager,
        setFormData,
        excelDownloadHotel,
      },
      dispatch
    ),
  };
}

type VehicleListProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  download: boolean;
  excel: boolean;
  showfilter:boolean
}

const initialState = {
  filterData: {
    searchKey: "",
    status: 1,
    isPublishWeb: null,
    certificateStatusTypeId: 0,
    companyUserId: 0,
    vehicleTypeId: 0,
    vehicleDocumentTypeId: 0,
    correctionType: null,
    isPublishQr: 0,
    vehiclePlaque: "",
    orderField: "UpdatedSystemUserDatetime",
    isDescending: true,
    isHistoryPublishWeb: "",
    isHistoryPublishQr: "",
    isApproved: null,
    transportId:0,
    showfilter:true,
    cityId:0,
    disctrictId:0
  },
  pagerCurrent: 0,
  download: false,
  excel: false,
};
let usertype: any = 0;

class VehicleList extends Component<VehicleListProps, OwnState> {
  state: any = initialState;

  componentDidMount() {
    filterModal(this, "vehicleListState").finally(() =>
      this.getData(this.state.pagerCurrent)
    );
    
    usertype = cookie.load("systemUserInfo");
  }

  getData(pagerSelected: number) {
    const request: VehicleGetAllRequestModel = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...this.state.filterData,
      isApproved:
        this.state.filterData.isApproved === null
          ? null
          : this.state.filterData?.isApproved === 1
          ? true
          : false,
    };

    castThunkAction(this.props.getAll(request)).then((response: any) => {
      
      const request2: CityGetAllRequestModel = {
        selectItemList: ["CityId", "CityName"],
        orderField: "CityName",
      };

      this.props.getAllM(request2);
      const requestCr: CertificateStatusTypeGetAllRequestModel = {
        criteriaItemList: [
          {
            propertyName: "Status",
            value: 1,
            isOr: false,
            conditionOperator: 0,
          },
        ],
        selectItemList: ["CertificateStatusTypeId", "Title"],
      };
      this.props.getAllCR(requestCr);
      const request3: LayoverSubCategoryGetAllRequestModel = {
        criteriaItemList: [
          {
            propertyName: "Status",
            value: 1,
            isOr: false,
            conditionOperator: 0,
          },
        ],
        selectItemList: ["LayoverSubCategoryId", "LayoverSubCategoryName"],
      };
      this.props.getAllLSU(request3);

      const request8: VehicleDocumentTypeGetAllRequestModel = {
        selectItemList: ["VehicleDocumentTypeId", "VehicleDocumentTypeName"],
        criteriaItemList: [
          {
            propertyName: "Status",
            value: StatusEnums.Published,
            isOr: false,
            conditionOperator: ConditionOperatorEnum.Eq,
          },
        ],
      };
      this.props.getAllVD(request8);

      const request7: VehicleTypeGetAllRequestModel = {
        selectItemList: ["VehicleTypeId", "VehicleTypeName"],
        criteriaItemList: [
          {
            propertyName: "Status",
            value: StatusEnums.Published,
            isOr: false,
            conditionOperator: ConditionOperatorEnum.Eq,
          },
        ],
      };
      this.props.getAllVT(request7);
    });
  }

  getDataPager(pagerSelected: number) {
    this.setState({ excel: false });
    const request: VehicleGetAllRequestModel = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...this.state.filterData,
      isApproved:
        this.state.filterData.isApproved === null
          ? null
          : this.state.filterData?.isApproved === 1
          ? true
          : false,
    };

    request["isPublishQr"] =
      request["isPublishQr"] === "1"
        ? true
        : request["isPublishQr"] === "2"
        ? false
        : null;

    this.props.getAllForPager(request);
  }

  onDelete(LayoverId: number) {
    const request: VehicleChangeStatusRequestModel = {
      id: LayoverId,
      status: StatusEnums.Deleted,
    };
    castThunkAction(this.props.changeStatus(request, true)).then(
      (response: any) => {
        this.getData(this.state.pagerCurrent);
      }
    );
  }

  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Tümünü Seç" });
    }

    if (
      key === "CertificateStatusTypeId" &&
      newArray.filter((x) => x[key] === 9).length === 0
    ) {
      newArray.push({ [key]: 9, [text]: "Sertifikasız" });
    }

    return newArray;
  }
  cityOnChange(event: any, option: any, value: any) {
    if (value !== undefined) {
      this.CityChange(option.key);
      this.setState({
        filterData: {
          ...this.state.filterData,
          cityId: option.key,
          districtId: 0,
        },
      });
    } else {
      this.setState({
        filterData: {
          ...this.state.filterData,
          cityId: 0,
          districtId: 0,
        },
      });
      this.props.setFormData("GroupListC", []);
    }
  }

  CityChange(item: any) {
    this.setState(
      {
        filterData: {
          ...this.state.filterData,
          cityId: item,
        },
      },
      () => {
        const request2: CountyGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "cityId",
              value: item,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
            {
              propertyName: "Status",
              value: 1,
              isOr: false,
              conditionOperator: 0,
            },
          ],
          selectItemList: ["DistrictId", "DistrictName"],
          orderField: "DistrictName",
        };
        this.props.getAllC(request2);
      }
    );
  }

  renderFilter() {
    return (
      <div className="table-filter row">
        
        <TextField
          className="custom-textfield col-md-2"
          label="Belge Sahibi / Ticari Ünvan"
          value={this.state.filterData?.companyName || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                companyName:
                  event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        <Dropdown
          options={(this.props.formData.GroupListVD?.dataList
            ? this.returnOptions(
                this.props.formData.GroupListVD?.dataList,
                "VehicleDocumentTypeId",
                "VehicleDocumentTypeName"
              )
            : ([] as any)
          ).map((item: any) => ({
            key: item.VehicleDocumentTypeId,
            text: item.VehicleDocumentTypeName,
          }))}
          className="custom-dropdown col-md-2"
          label="Aracın Sahip Olduğu Yetki Belgesi Sınıfı"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData.vehicleDocumentTypeId}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                vehicleDocumentTypeId: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        
        <Dropdown
          options={(this.props.formData.GroupListVT?.dataList
            ? this.returnOptions(
                this.props.formData.GroupListVT?.dataList,
                "VehicleTypeId",
                "VehicleTypeName"
              )
            : ([] as any)
          ).map((item: any) => ({
            key: item.VehicleTypeId,
            text: item.VehicleTypeName,
          }))}
          className="custom-dropdown col-md-2"
          label="Araç Cinsi"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData.vehicleTypeId}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                vehicleTypeId: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />

        <TextField
          className="custom-textfield col-md-1"
          label="Plaka ile Sorgulama"
          value={this.state.filterData?.vehiclePlaque || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                vehiclePlaque:
                  event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        <TextField
          className="custom-textfield col-md-1"
          label="Taşıt ID"
          value={this.state.filterData?.transportId || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                transportId: isNumberValue(event.target.value),
              },
            });
          }}
        />
        <Dropdown
          options={[
            { key: "CompanyName", text: "Belge Sahibi / Ticari Ünvan" },
            { key: "CreatedSystemUserDatetime", text: "Oluşturma Tarihi" },
            { key: "UpdatedSystemUserDatetime", text: "Güncelleme Tarihi" },
          ]}
          className="custom-dropdown col-md-2"
          label="Sıralama Kriteri"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.orderField || null}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                orderField: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <Dropdown
          options={(this.state.filterData.orderField === "CompanyName"
            ? [
                { key: false, text: "Baştan Sona" },
                { key: true, text: "Sondan Başa" },
              ]
            : [
                { key: false, text: "Eskiden Yeniye" },
                { key: true, text: "Yeniden Eskiye" },
              ]
          ).map((item: any) => ({
            key: item.key,
            text: item.text,
          }))}
          className="custom-dropdown col-md-2"
          label="Sıralama Türü"
          selectedKey={this.state.filterData?.isDescending}
          placeholder="Seçiniz"
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isDescending: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <ComboBox
          selectedKey={
            this.state.filterData && this.state.filterData.cityId
              ? this.state.filterData.cityId
              : ""
          }
          label="İl"
          allowFreeform={true}
          autoComplete="on"
          multiSelect={false}
          className="custom-dropdown col-md-2"
          onChange={(event: any, option: any, value: any) =>
            this.cityOnChange(event, option, value)
          }
          options={(this.props.formData.GroupList?.dataList
            ? cityReturnOptions(
                this.props.formData.GroupList?.dataList,
                "CityId",
                "CityName"
              )
            : ([] as any)
          ).map((item: any) => ({ key: item.CityId, text: item.CityName }))}
        />
        <Dropdown
          defaultSelectedKey={this.state.filterData?.districtId || ""}
          onChanged={(event: any) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                districtId: event.key,
              },
            })
          }
          label="İlçe"
          options={(this.props.formData.GroupListC?.dataList
            ? this.returnOptions(
                this.props.formData.GroupListC?.dataList,
                "DistrictId",
                "DistrictName"
              )
            : ([] as any)
          ).map((item: any) => ({
            key: item.DistrictId,
            text: item.DistrictName,
          }))}
          className="custom-dropdown col-md-2"
        />

        {usertype !== "2" && (
          <Dropdown
            options={[
              { key: StatusEnums.All, text: "Tümü" },
              { key: StatusEnums.Published, text: "Yayımda" },
              { key: StatusEnums.Deleted, text: "Silindi" },
              // { key: StatusEnums.PendingApproval, text: "Sertifika Süresi Bitti" },
              // { key: StatusEnums.DraftArchive, text: "Uygun Görülmedi" },
              // { key: StatusEnums.Draft, text: "Firma Değişti" },
              // { key: StatusEnums.ActivityStopped, text: "Faaliyetini Durdurdu" },
            ]}
            className="custom-dropdown col-md-2"
            label="Kayıt Durumu"
            placeholder="Seçiniz"
            selectedKey={this.state.filterData?.status || 1}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  status: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />
        )}

        <Dropdown
          options={[
            { key: 0, text: "Tümü" },
            { key: 4, text: "TC Kültür ve Turizm Bakanlığı" },
            { key: 5, text: "Diğer" },
          ]}
          className="custom-dropdown col-md-2"
          selectedKey={this.state.filterData?.certificateTypeId || 0}
          label="Taşıt Belge Tipi"
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                certificateTypeId: option.key === 2 ? null : option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />

        <TextField
          className="custom-textfield col-md-1"
          label="Belge No"
          value={this.state.filterData?.documentNo || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                documentNo: event.target.value,
              },
            });
          }}
        />
        <Dropdown
          options={(this.props.formData.GroupListCR?.dataList
            ? this.returnOptions(
                this.props.formData.GroupListCR?.dataList,
                "CertificateStatusTypeId",
                "Title"
              )
            : ([] as any)
          ).map((item: any) => ({
            key: item.CertificateStatusTypeId,
            text: item.Title,
          }))}
          className="custom-dropdown col-md-2"
          label="Sertifika Durumu"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.certificateStatusTypeId}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                certificateStatusTypeId: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />

        <TextField
          className="custom-textfield col-md-1"
          label="Sertifika No"
          value={this.state.filterData?.certificateNo || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                certificateNo:
                  event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />
        <Dropdown
          options={[
            { key: 2, text: "Tümü" },
            { key: 1, text: "Onaylanmış" },
            { key: 0, text: "Onaylanmamış" },
          ]}
          className="custom-dropdown col-md-2"
          label="Taşıt Onay Durumu"
          selectedKey={
            this.state.filterData?.isApproved === 0
              ? 0
              : this.state.filterData?.isApproved === 1
              ? 1
              : 2 || 2
          }
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                isApproved: option.key === 2 ? null : option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />

        <PrimaryButton
          text="Arama Yap"
          className="custom-button"
          onClick={() => {
            cookie.save("vehicleListState", JSON.stringify(this.state), {
              path: "/",
            });
            this.getDataPager(0);
          }}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />

        {this.rederexcel()}
        <PrimaryButton
          text="Tüm Filtreleri Temizle"
          className="custom-button"
          iconProps={{ iconName: "trash" }}
          onClick={() =>
            this.setState(
              {
                ...this.state,
                filterData: initialState.filterData,
              },
              () => {
                cookie.save("vehicleListState", JSON.stringify(this.state), {
                  path: "/",
                });
                this.getDataPager(0);
              }
            )
          }
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
              background: "#FF4343",
              borderColor: "#FF4350",
            },
          }}
        />  <style>
        {`.table-list .table-body .list-td{text-align: left;}.table-list .row .list-th:last-child,.table-list .table-body .list-td:last-child{padding-right:0}.table-list .table-head .list-th,.table-list .table-body .list-td { flex: 0 0 7.41% ;max-width: 7.41%; }
        .table-list .table-body .item,.table-list .table-head{padding-right:0}`}
      </style>
      </div>
      
    );
  }

  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "transportId",
            text: "Taşıt ID",
            col: "col-md-1-2",
          },
          {
            columnName: "vehiclePlaque",
            text: "Plaka No",
            col: "col-md-1-2",
          },
          {
            columnName: "cityDistrictName",
            text: "İl / İlçe",
            col: "col-md-1-2",
          },
          {
            columnName: "companyName",
            text: "Belge Sahibi / Ticari Ünvan",
            col: "col-md-1-2",
          },

          {
            columnName: "seatCount",
            text: "Koltuk Sayısı",
            col: "col-md-1-2",
          },

          {
            columnName: "roomCount",
            text: "Aracın Sahip Olduğu Yetki Belgesi Sınıfı / Araç Cinsi",
            col: "col-md-1-2",
            onRender: (item: {
              vehicleDocumentTypeName: any;
              vehicleTypeName: any;
            }) => (
              <React.Fragment>
                {`${item.vehicleDocumentTypeName} / ${item.vehicleTypeName}`}
              </React.Fragment>
            ),
          },

          {
            columnName: "phoneNumber",
            text: "Telefon No",
            col: "col-md-1-2",
          },
          // {
          //   columnName: "webSite",
          //   text: "Web sitesi",
          //   col: "col-md-1-2",
          //   onRender: (item: { webSite: any }) => (
          //     <React.Fragment>
          //       {usertype !== "2" && item.webSite}
          //     </React.Fragment>
          //   ),
          // },

          {
            columnName: "email",
            text: "E-posta Adresi",
            col: "ccol-md-1-2",
            onRender: (item: { email: any }) => (
              <React.Fragment>{usertype !== "2" && item.email}</React.Fragment>
            ),
          },
          {
            columnName: "certificateNo",
            text: "Sertifika No",
            col: "col-md-1-2",
          },
          {
            columnName: "certificateStatusTypeName",
            text: "Sertifika Durumu",
            col: "col-md-1-2",
          },
          {
            columnName: "confirmationDateString",
            text: "Sertifika Düzenleme Tarihi",
            col: "col-md-1-2",
          },
          {
            columnName: "extendedClosedDateString",
            text: "Sertifika Geçerlilik Tarihi",
            col: "col-md-1-2",
          },
          {
            columnName: "transportId",
            text: "İşlem",
            col: "col-md-1-2",
            className: "operations",
            onRender: (item: { transportId: any; status: StatusEnums }) => (
              <React.Fragment>
                {
                  <button onClick={(x) => this.goEdit(item.transportId)}>
                    <i className="icon-edit-outline"></i> Düzenle
                  </button>
                }
                {usertype !== "5" &&
                  item.status !== StatusEnums.Deleted &&
                 usertype !== "2" && (
                    <button
                      onClick={() =>
                        this.props.toggleConfirmDialog(
                          true,
                          "Silme işlemini gerçekleştirmek istiyor musunuz?",
                          () => this.onDelete(item.transportId)
                        )
                      }
                    >
                      <i className="icon-trash-outline"></i> Sil
                    </button>
                  )}
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.dataList || []}
      />
    );
  }

  rederexcel() {
    return (
      <>
        <PrimaryButton
          text="Rapor Oluştur ve İndir"
          className="custom-button"
          onClick={() => this.downloadexcel()}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
      </>
    );
  }

  downloadexcel() {
    this.setState({ download: true });
    const requestexcel: VehicleGetAllRequestModel = {
      ...this.state.filterData,
    };

    castThunkAction(this.props.excelDownloadHotel(requestexcel));
  }

  goEdit(vehicleId: any): any {
    window.open("/Transport/edit/" + vehicleId);
  }

  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.props.formData.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }
  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getDataPager(selected);
  };
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Taşıt Yönetimi"
          subTitle="Yönetilebilecek tüm alanları aşağıdan görüntüleyebilirsiniz."
          backUrl="/"
          showBack
          history={this.props.history}
        />
        {usertype !== "5" && (
          <PrimaryButton
            onClick={() => this.props.history.push("/Transport/create")}
            iconProps={{ iconName: "Add" }}
            className="absolute-create"
            text="Yeni Taşıt Ekle"
            styles={{
              root: {
                background: "#62a98e",
                borderColor: "#5b9e85",
              },
              rootHovered: {
                background: "#5b9e85",
                borderColor: "#5b9e85",
              },
              rootDisabled: {
                background: "#ececec",
              },
            }}
          />
        )}

{
         window.innerWidth < 1100 && (
           <PrimaryButton
             text={this.state.showfilter ? "Filtreyi Gizle" : "Filtreyi Göster"}
             className="custom-button"
             onClick={() => (
               this.setState({ showfilter: !this.state.showfilter })
             )}
             styles={{  
               root: {
                 fontSize: "11px",
                 marginTop: "27px",
                 height: "30px",
               },
             }}
           />
         )
       }
       { window.innerWidth < 1100 ? (this.state.showfilter && this.renderFilter()):this.renderFilter()}
        {this.renderList()}
        {this.renderPager()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleList);
