import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  TextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Dropdown,
  Label,
  Checkbox,
} from "office-ui-fabric-react";
import {
  setFormData,
  setFormErrors,
  initialFormData,
} from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";

import { save } from "src/store/thunkThemedFacility";
import { castThunkAction } from "src/helpers/casting";
import { StatusEnums } from "src/services/BaseEnum";
import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import { getAllTy, showErrorDialog } from "src/store/thunkCertificateType";
import { getAllCR } from "src/store/thunkCertificateStatusType";
import { getAllHA } from "src/store/thunkHospital";
import { getAllCompany } from "src/store/thunksCompany";
import { getCompanyUserList } from "src/store/thunksSystemUser";
import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";
import { GetHospitalList } from "src/services/Hospital/RequestModel";
import { CertificateTypeGetAllRequestModel } from "src/services/CertificateType/RequestModel";
import { CertificateStatusTypeGetAllRequestModel } from "src/services/CertificateStatusType/RequestModel";
import cookie from "react-cookies";

import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";
import _ from "lodash";

import { FileUpload } from "../FileUpload";
import { apiConfig, qrUrl } from "src/config/apiConfig";
import { ThemedFacilitySaveRequestModel } from "src/services/ThemedFacility/RequestModel";
import HospitalModal from "../HospitalModal";

// @ts-ignore

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toDate} from "src/helpers/converts";
import { formatSize, requestMapStatusData } from "src/helpers/functions";
import ActivityStatusOpenCloseDate from "../ActivityStatusOpenCloseDate";

let token = "";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        initialFormData,
        save,
        getAllM,
        getAllC,
        getAllTy,
        getAllCR,
        getCompanyUserList,
        getAllCompany,
        showErrorDialog,
        getAllHA,
      },
      dispatch
    ),
  };
}

type CreateThemedFacilityProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  showFields: boolean;
  messageType: MessageBarType;
  isFileUploading: boolean;
  infoMessageGetDocumentNo: string;
  phone: string;
  onlyCountry: string[];
  isOpenStatus:string;
}
let usertype: any = 0;
class CreateThemedFacility extends Component<
  CreateThemedFacilityProps,
  OwnState
> {
  state = {
    messageType: MessageBarType.warning,
    showFields: true,
    isFileUploading: false,
    infoMessageGetDocumentNo: "",
    phone: "",
    onlyCountry: ["tr"],
    isOpenStatus:"0",
  };

  UNSAFE_componentWillMount() {
    token = cookie.load("userId");
    this.props.initialFormData({
      status: StatusEnums.Published,
    });

    usertype = cookie.load("systemUserInfo");
    const request6: SystemUserGetAllModel = {
      systemUserType: 2,
    };
    usertype === "2"
      ? this.props.setFormData("prefix", cookie.load("prefix"))
      : this.props.setFormData("prefix", null);
    castThunkAction(this.props.getCompanyUserList(request6)).then(
      (response: any) => {
        if (usertype && usertype === "2") {
          this.props.setFormData("companyUserId", 0);
        }

        const request2: CityGetAllRequestModel = {
          selectItemList: ["CityId", "CityName"],
          isDescending: false,
          orderField: "CityName",
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllM(request2);

        const request4: CertificateTypeGetAllRequestModel = {
          selectItemList: ["CertificateTypeId", "Title"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllTy(request4);

        const request7: GetHospitalList = {
          orderField: "HospitalName",
        };
        this.props.getAllHA(request7);

        const request5: CertificateStatusTypeGetAllRequestModel = {
          selectItemList: ["CertificateStatusTypeId", "Title"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        castThunkAction(this.props.getAllCR(request5)).then((e: any) => {
          this.props.setFormData("certificateStatusTypeId", 1);
        });
      }
    );
  }

  componentDidUpdate(nextprops: any) {
    if (this.props.formData.certificateTypeId !== 0) {
      this.setLabelDescription(
        "companyName",
        "<span id='companyName-span' class='descSpan'>Tesis sahibi olan firmanın ticari ünvanı</span>"
      );

      if (this.props.formData.certificateTypeId === 4) {
        this.setLabelDescription(
          "documentNoDesc",
          "<span id='documentNoDesc-span' class='descSpan'>TC Kültür ve Turizm Bakanlığı tarafından tesise verilen çalışma belge numarası</span>"
        );
      }
    }
    if (
      this.props.formData.certificateStatusTypeId === 2 &&
      this.props.formData.prefix
    ) {
      this.setPrefix();
    }
  }

  insertAfter(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  insertBefore(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode);
  }

  componentDidMount() {
    this.setDdlDescription(
      "certificateTypeIdDesc-label",
      "<br/> <span id='certificateTypeIdDesc-label-span' class='descSpan'>Tesisin kayıt edileceği belge tipini seçiniz. Tesise ait <b>T.C Kültür ve Turizm Bakanlığı</b> Belgesi bulunuyorsa bu belge tipini seçip ilgili belgeyi yüklemeniz ve belgeye uygun bilgileri doldurmanız gerekmektedir. Eğer tesise ait bu belge bulunmuyorsa lütfen <b>Diğer</b> seçerek ilerleyeniz.</b></span>"
    );
  }

  setDdlDescription(id: string, message: string) {
    const el = document.createElement("span");
    el.innerHTML = message;
    const div = document.getElementById(id);
    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div, el);
    }
  }

  setLabelDescription(id: string, message: string) {
    const el2 = document.createElement("span");
    el2.innerHTML = message;
    const div2 = document.querySelectorAll('[for="' + id + '"]');

    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div2[0], el2);
    }
  }

  renderTextByMessageType() {
    switch (this.state.messageType) {
      case MessageBarType.success:
        return "Kaydetme işlemi başarıyla gerçekleşmiştir.";
      case MessageBarType.error:
        return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
      default:
        return "Lütfen aşağıdaki bilgileri doldurun ve formu oluşturun";
    }
  }

  renderMessageBar() {
    return (
      <MessageBar messageBarType={this.state.messageType} isMultiline={false}>
        {this.renderTextByMessageType()}
      </MessageBar>
    );
  }

  onCreate(isDraft?: boolean) {
    let request: ThemedFacilitySaveRequestModel = requestMapStatusData(this.props.formData,isDraft) 
    
    if (
      this.props.formData.attachmentList &&
      this.props.formData.attachmentList.length > 0
    ) {
      request.data["attachmentIdList"] = (
        this.props.formData.attachmentList || []
      ).map((item: any) => item.attachmentId);
    }
    if (
      this.props.formData.attachmentCertificateList &&
      this.props.formData.attachmentCertificateList.length > 0
    ) {
      request.data["attachmentCertificateIdList"] = (
        this.props.formData.attachmentCertificateList || []
      ).map((item: any) => item.attachmentId);
    }
    if (!request.data.documentNo) {
      request.data.documentNo = "0";
    }
    castThunkAction(this.props.save(request)).then((response: any) => {
      this.props.setFormData(
        "initialAttachmentList",
        this.props.formData.attachmentList
      );
      this.props.setFormData(
        "initialAttachmentCertificateList",
        this.props.formData.attachmentCertificateList
      );
      this.setState(
        {
          messageType: response ? MessageBarType.success : MessageBarType.error,
        },
        () => {
          if (response) {
            this.props.history.push("/ThemedFacility/edit/" + response.id);
          } else {
            setTimeout(() => {
              //window.location.reload(false)
            }, 1000);
          }
        }
      );
    });
  }

  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  removeElement(id: any) {
    var elem = document.getElementById(id);
    return elem?.parentNode?.removeChild(elem);
  }
  filterFileds(val: any) {
    if (
      val !== "" &&
      val !== 0 &&
      val !== "0" &&
      val !== null &&
      val !== "null" &&
      val !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  }
  onSubmit(isDraft?: boolean) {
    let desc = "";

    // eslint-disable-next-line
    const webSite =
      /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    if (this.props.formData.webSite) {
      if (
        this.props.formData.webSite !== "" &&
        !this.props.formData.webSite &&
        !webSite.test(this.props.formData.webSite)
      ) {
        desc += "Geçersiz Web Sitesi,  <br/>";
      }
    }

    if (!this.props.formData.vkNo || this.props.formData.vkNo.length < 10) {
      desc += "Vergi numarası 10 hane ve üzeri olmalıdır. <br/>";
    }

    const mail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!mail.test(this.props.formData.email)) {
      desc += "Geçersiz E-posta adresi,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.certificateTypeId)) {
      desc += "Tesis Belge Tipi boş bırakılamaz , <br/>";
    }
    if (!this.filterFileds(this.props.formData.companyName)) {
      desc += "Belge Sahibi / Ticari Ünvan,  <br/>";
    }
    if (this.props.formData.certificateTypeId === 4) {
      if (
        !this.filterFileds(this.props.formData.documentNo) ||
        this.props.formData.documentNo === "0"
      ) {
        desc += "Belge No,  <br/>";
      }
    }
    if (this.props.formData.isOpenStatus === "0") {
      desc += "Tesis Faaliyet Durumu,  <br/>";
    }

    //https://b24-z1nwey.bitrix24.com.tr/workgroups/group/8/tasks/task/view/2042/ madde revize.
    if (this.props.formData.certificateTypeId === 5) {
      this.props.setFormData("documentNo", "");
    }

    if (!this.filterFileds(this.props.formData.brandName)) {
      desc += "Tesis Adı,  <br/>";
    }

    if (!this.filterFileds(this.props.formData.cityId)) {
      desc += "İl,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.districtId)) {
      desc += "İlçe,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.address)) {
      desc += "Tesis Adresi,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.email)) {
      desc += "Tesis E-posta Adresi,  <br/>";
    }
    const phoneControl = /........../;
    if (!phoneControl.test(this.props.formData.phoneNumber)) {
      desc += "Tesis Telefon Numarası,  <br/>";
    }

    if (!this.filterFileds(this.props.formData.ManagerName)) {
      desc += "Tesis Genel Müdürünün Adı ve Soyadı,  <br/>";
    }
    // if(!this.filterFileds(this.props.formData.latitude)){
    //   desc += "X Koordinatı, <br/>";
    // }
    // if(!this.filterFileds(this.props.formData.longitude)){
    //   desc += "Y Koordinatı, <br/>";
    // }
    if (this.props.formData.certificateStatusTypeId === 2) {
      if (!this.filterFileds(this.props.formData.confirmationDate)) {
        desc += "Sertifika Düzenleme Tarihi,  <br/>";
      }
      if (!this.filterFileds(this.props.formData.CertificateNo)) {
        desc += "Sertifika No,  <br/>";
      }
    }

    if (
      !this.props.formData.attachmentList ||
      this.props.formData.attachmentList.length === 0
    ) {
      desc += "İşletme belgesi yükleyiniz,  <br/>";
    }

    const request = {
      title: "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
      desc: desc,
    };
    if (desc !== "") {
      castThunkAction(this.props.showErrorDialog(request)).then(
        (response: any) => {}
      );
    }

    if (this.props.formData.certificateStatusTypeId === 2) {
      if (
        this.props.formData.confirmationDate &&
        this.props.formData.confirmationDate !== "" &&
        this.props.formData.confirmationDate !== null &&
        this.props.formData.confirmationDate !== 0 &&
        this.props.formData.confirmationDate !== "null" &&
        this.props.formData.confirmationDate !== "0"
      ) {
        this.removeElement("confirmationDate-span");
      } else {
        const el = document.createElement("span");
        el.className = "errorspan";
        el.innerHTML =
          "<br/> <span id='confirmationDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
        const div = document.getElementById("confirmationDate");
        if (!document.getElementById("confirmationDate-span")) {
          this.insertAfter(div, el);
        }
      }
    } else {
      this.removeElement("confirmationDate-span");
    }

    if (desc === "") {
      const { formData, setFormErrors } = this.props;
      const validationSchema = _yup.object({
        webSite: _yup
          .string()
          .matches(
            // eslint-disable-next-line
            /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
            "Geçersiz Web Sitesi"
          )
          .notRequired()
          .nullable(),
        companyName: _yup.string().required("Bu alan zorunludur!"),
        brandName: _yup.string().required("Bu alan zorunludur!"),
        cityId: _yup.string().required("Bu alan zorunludur!"),
        districtId: _yup.string().required("Bu alan zorunludur!"),
        vkNo: _yup.string().required("Bu alan zorunludur!"),

        CertificateNo:
          this.props.formData.certificateStatusTypeId === 2
            ? _yup.string().required("Bu alan zorunludur!")
            : _yup.string().nullable(),
        email: _yup
          .string()
          .matches(
            // eslint-disable-next-line
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            "Geçersiz E-posta Adresi"
          )
          .notRequired()
          .nullable(),
        phoneNumber: _yup.string().required("Bu alan zorunludur!"),
        ManagerName: _yup.string().required("Bu alan zorunludur!"),
        address: _yup.string().required("Bu alan zorunludur!"),
        documentNo:
          this.props.formData.certificateTypeId === 4
            ? _yup.string().required("Bu alan zorunludur!")
            : _yup.string().nullable(),
        

      });

      let formErrors = {};
      validationSchema
        .validate(formData, {
          abortEarly: false,
        })
        .then(() => {
          setFormErrors({});

          if (
            this.props.formData.attachmentList &&
            this.props.formData.attachmentList.length > 0
          ) {
            this.onCreate(isDraft);
          } else {
            castThunkAction(
              this.props.showErrorDialog({
                title:
                  "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
                desc: "İşletme belgesi yükleyiniz",
              })
            ).then((response: any) => {});
          }
        })
        .catch((err) => {
          err.inner.forEach((e: any) => {
            const { path, message } = e;
            formErrors[path] = message;
          });
          setFormErrors(formErrors);
        });
    }
  }

  CityChange(item: any, districtId?: any) {
    const request2: CountyGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "cityId",
          value: item,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
      selectItemList: ["DistrictId", "DistrictName"],
      orderField: "DistrictName",
    };
    castThunkAction(this.props.getAllC(request2)).then((response: any) => {
      this.props.setFormData("districtId", districtId);
    });
  }

  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      /* newArray.unshift({ [key]: 0, [text]: "Seçiniz" });*/
    }

    return newArray;
  }

  handleMapClick = (ref: any, map: any, ev: any) => {
    const location = ev.latLng;

    map.panTo(location);
  };
  renderForm() {
    const { formData, formErrors, setFormData } = this.props;
    return (
      <div className="form-groups row">
        <div className="form-group col-md-4" style={{ paddingBottom: 0 }}>
          <div className="form-wrapper">
            <div className="item row">
              <Dropdown
                id="certificateTypeIdDesc"
                onChanged={(event: any) =>
                  setFormData("certificateTypeId", event.key)
                }
                errorMessage={formErrors.certificateTypeId}
                label="Tesis Belge Tipi"
                placeholder="Seçiniz"
                options={(this.props.formData.GroupListTY?.dataList
                  ? this.returnOptions(
                      this.props.formData.GroupListTY?.dataList,
                      "CertificateTypeId",
                      "Title"
                    )
                  : ([] as any)
                ).map((item: any, key: any) => ({
                  key: item.CertificateTypeId,
                  text: item.Title,
                  keys: key,
                }))}
                className="custom-dropdown"
              />
            </div>

            {this.props.formData.certificateTypeId === 4 ? (
              <div className="item row">
                <TextField
                  id="documentNoDesc"
                  placeholder="Belge No"
                  value={formData.documentNo || ""}
                  label="Belge No "
                  className="custom-textfield border"
                  errorMessage={formErrors.documentNo}
                  onChange={(event: any) =>
                    setFormData("documentNo", event.target.value)
                  }
                />
              </div>
            ) : (
              ""
            )}

            <div className="item row">
              <TextField
                placeholder="Tesis Vergi Numarası"
                value={formData.vkNo || ""}
                label="Tesis Vergi Numarası"
                type="number"
                maxLength={10}
                minLength={10}
                className="custom-textfield border"
                errorMessage={formErrors.vkNo}
                onChange={(event: any) =>
                  setFormData("vkNo", event.target.value)
                }
              />
            </div>
            <div className="form-wrapper">
              <div className="title">
                T.C Kültür ve Turizm Bakanlığı İşletme Belgesi / İşyeri Açma
                Ruhsatı ve Tesise Ait Diğer Resmi Evraklar
                <br />
                <small>
                  Lütfen sadece T.C Kültür ve Turizm Bakanlığı Belgesi, İşyeri
                  Açma Çaıştırma Ruhsatı ve TGA tarafından onaylanmış/talep
                  edilmiş evrakları yükleyiniz.
                </small>
              </div>
              <FileUpload
                onProcessStartFile={() => {
                  this.setState({
                    isFileUploading: true,
                  });
                }}
                onProcessEndFile={(queue: boolean) => {
                  if (!queue) {
                    this.setState({
                      isFileUploading: false,
                    });
                  }
                }}
                onTransferedFile={(file: any, promise?: boolean) => {
                  this.props.setFormData("attachmentList", [
                    ...(this.props.formData?.attachmentList || []),
                    file.attachmentIdList[0],
                  ]);
                }}
                requestKey="files"
                token={token}
                endpointUrl={apiConfig.prefixes.attachment + "UploadFile"}
              />
              {this.props.formData.attachmentList &&
                this.props.formData.attachmentList.length > 0 && (
                  <React.Fragment>
                    <div className="title">Yüklenen Belgeler</div>
                    {JSON.stringify(
                      this.props.formData.initialAttachmentList
                    ) !==
                      JSON.stringify(this.props.formData.attachmentList) && (
                      <div className="file-save-warning">
                        Düzenlemeleri ve yüklenen belgeleri kaydetmek için, sağ
                        üst kısımdan "Kaydet veya Yayımla"ya basın.
                      </div>
                    )}
                    <div className="uploaded-files">
                      {(this.props.formData?.attachmentList || []).map(
                        (
                          item: {
                            attachmentId: number;
                            attachmentPath: string;
                            fileName: string;
                            fileSize: number;
                          },
                          index: number
                        ) => (
                          <div key={index} className="uploaded-file">
                            <div className="name row">
                              <span className="text col no-padding">
                                {item.fileName}
                                <strong className="size m-left-auto">
                                  {formatSize(item.fileSize)}
                                </strong>
                              </span>
                              <div className="buttons m-left-auto row">
                                <button
                                  onClick={() =>
                                    window.open(
                                      qrUrl + item.attachmentPath,
                                      "_blank"
                                    )
                                  }
                                  className="view"
                                >
                                  <i className="icon-explore"></i> Görüntüle
                                </button>
                                <button
                                  onClick={() => {
                                    let files = _.cloneDeep(
                                      this.props.formData?.attachmentList
                                    );
                                    let findIndex = (files || []).findIndex(
                                      (x: any) =>
                                        x.attachmentId === item.attachmentId
                                    );
                                    if (findIndex !== -1) {
                                      files.splice(findIndex, 1);
                                    }
                                    this.props.setFormData(
                                      "attachmentList",
                                      files
                                    );
                                  }}
                                  className="remove"
                                >
                                  <i className="icon-close"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </React.Fragment>
                )}
            </div>
            {usertype && usertype !== "2" ? (
              <div className="item row">
                <Dropdown
                  options={[
                    { key: 1, text: "Onaylanmış" },
                    { key: 0, text: "Onaylanmamış" },
                  ]}
                  label="Tesis Onay Durumu"
                  placeholder="Seçiniz"
                  selectedKey={this.props.formData?.isApproved || 0}
                  onChanged={(event: any) => (
                    this.CityChange(event.key),
                    setFormData("isApproved", event.key)
                  )}
                  calloutProps={{
                    className: "custom-dropdown-callout",
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="item row">
              <TextField
                id="companyName"
                // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                placeholder="Belge Sahibi / Ticari Ünvan"
                value={formData.companyName || ""}
                label="Belge Sahibi / Ticari Ünvan"
                className="custom-textfield border"
                errorMessage={formErrors.companyName}
                onChange={(event: any) =>
                  setFormData("companyName", event.target.value)
                }
              />
            </div>

            <div className="item row">
              <TextField
                // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                placeholder="Tesis Adı"
                value={formData.brandName || ""}
                label="Tesis Adı"
                className="custom-textfield border"
                errorMessage={formErrors.brandName}
                onChange={(event: any) =>
                  setFormData("brandName", event.target.value)
                }
              />
            </div>

            <div className="item row">
              <Dropdown
                // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                onChanged={(event: any) => (
                  this.CityChange(event.key), setFormData("cityId", event.key)
                )}
                label="İl"
                options={(
                  this.props.formData.GroupList?.dataList || ([] as any)
                ).map((item: any) => ({
                  key: item.CityId,
                  text: item.CityName,
                }))}
                className="custom-dropdown"
                selectedKey={this.props.formData.cityId}
                errorMessage={formErrors.cityId}
              />
            </div>

            <div className="item row">
              <Dropdown
                // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                onChanged={(event: any) => setFormData("districtId", event.key)}
                label="İlçe"
                options={(formData.GroupListC?.dataList || ([] as any)).map(
                  (item: any) => ({
                    key: item.DistrictId,
                    text: item.DistrictName,
                  })
                )}
                className="custom-dropdown"
                selectedKey={this.props.formData.districtId}
                errorMessage={formErrors.districtId}
              />
            </div>

            <div className="item row">
              <TextField
                placeholder="Tesis Adresi"
                value={formData.address || ""}
                label="Tesis Adresi"
                className="custom-textfield border"
                errorMessage={formErrors.address}
                onChange={(event: any) =>
                  setFormData("address", event.target.value)
                }
              />
            </div>

            <div className="item row">
              <TextField
                placeholder="Tesis E-posta Adresi"
                value={formData.email || ""}
                label="Tesis E-posta Adresi"
                className="custom-textfield border"
                errorMessage={formErrors.email}
                onChange={(event: any) =>
                  setFormData("email", event.target.value)
                }
              />
            </div>
            <div className="item row">
							<TextField
								placeholder="X Koordinatı"
								value={formData.latitude || ""}
								label="X Koordinatı"
                type="number"
								className="custom-textfield border"
								errorMessage={formErrors.latitude}
								onChange={(event: any) => setFormData("latitude", event.target.value)}
							/>
						</div>

						<div className="item row">
							<TextField
								placeholder="Y Koordinatı"
								value={formData.longitude || ""}
								label="Y Koordinatı"
								className="custom-textfield border"
                type="number"
								errorMessage={formErrors.longitude}
								onChange={(event: any) => setFormData("longitude", event.target.value)}
							/>
						</div>
            <div className="item row">
              <Label>Tesis Telefon Numarası</Label>
              <PhoneInput
                onlyCountries={this.state.onlyCountry}
                country={"tr"}
                placeholder="Tesis Telefon Numarası"
                value={formData.phoneNumber || ""}
                onChange={(phone) => setFormData("phoneNumber", phone)}
                disableDropdown={true}
                disableCountryCode={true}
                masks={{ tr: "(...) ...-..-.." }}
                inputClass={"customizephone"}
                defaultErrorMessage={formErrors.phoneNumber}
              />
            </div>
          </div>
        </div>
        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="item row">
              <TextField
                placeholder="Tesis Genel Müdürünün Adı ve Soyadı"
                value={formData.ManagerName || ""}
                label="Tesis Genel Müdürünün Adı ve Soyadı"
                className="custom-textfield border"
                errorMessage={formErrors.ManagerName}
                onChange={(event: any) =>
                  setFormData("ManagerName", event.target.value)
                }
              />
            </div>
            <div className="item row">
              <TextField
                placeholder="Tesisin Web Sitesi"
                value={formData.webSite || ""}
                label="Tesisin Web Sitesi"
                className="custom-textfield border"
                errorMessage={formErrors.webSite}
                onChange={(event: any) =>
                  setFormData(
                    "webSite",
                    event.target.value === "" ? null : event.target.value
                  )
                }
              />
            </div>

            {this.props.formData.hospitalModalShow && (
              <div className="modal-overlay">
                <div className="modal">
                  <button
                    className="btn-close"
                    onClick={(x) =>
                      this.props.setFormData("hospitalModalShow", false)
                    }
                  >
                    Kapat
                  </button>
                  <HospitalModal history={this.props.history} />
                </div>
              </div>
            )}
            <div className="item row">
              <PrimaryButton
                onClick={(x) =>
                  this.props.setFormData("hospitalModalShow", true)
                }
                iconProps={{ iconName: "Add" }}
                className=""
                text="Tesisin anlaşmalı olduğu sağlık kuruluşunu ekle"
                style={{ marginTop: "20px", marginBottom: "5px" }}
                styles={{
                  root: {
                    background: "#aea64c",
                    borderColor: "#aea64c",
                  },
                  rootHovered: {
                    background: "#aea64c",
                    borderColor: "#aea64c",
                  },
                  rootDisabled: {
                    background: "#aea64c",
                  },
                }}
              />

              {this.props.formData.hospitalId ? (
                <Label className="hotelName full-width-label">
                  Seçili Sağlık Kuruluşu : {this.props.formData.hospitalName}
                </Label>
              ) : (
                <Label className="hotelName alert full-width-label">
                  Tesisin anlaşmalı olduğu sağlık kuruluşu bulunmuyor.
                </Label>
              )}
            </div>
            <div className="item row">
              <Checkbox
                label="Covid Testi Kiti var."
                onChange={() =>
                  setFormData("isHaveHealthKit", !formData.isHaveHealthKit)
                }
              />
            </div>
            <ActivityStatusOpenCloseDate />
          </div>
        </div>
      </div>
    );
  }
  getDate(date: any): any {
    if (date !== 0 && date !== undefined) {
      return toDate(date, "");
    }
  }
  showDialog(req: any): any {
    this.props.showErrorDialog(req);
  }
  setPrefix(): any {
    const el = document.createElement("span");
    el.className = "CertificateNo-span prefixSpan";
    el.id = "CertificateNo-span";
    el.innerHTML =
      usertype === "2"
        ? cookie.load("prefix") + "-"
        : this.props.formData.prefix + "-";
    const div = document.getElementById("CertificateNo");
    if (!document.getElementById("CertificateNo-span")) {
      this.insertBefore(div, el);
    } else {
      document.getElementById("CertificateNo-span")?.remove();
      this.insertBefore(div, el);
    }
    return false;
  }

  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Yeni Temalı Tesis Ekle"
          titleRenderer={() => (
            <span className="edit-title">
              Yönet:
              <strong>Yeni Temalı Tesis Ekle</strong>
            </span>
          )}
          showBack
          backUrl={
            usertype === "2"
              ? "/ThemedFacilityCertification"
              : "/ThemedFacility"
          }
          history={this.props.history}
          subTitle="Yeni bir Temalı Tesis eklemek için aşağıdaki bilgileri doldurunuz."
          rightType="create"
          right={
            <FormButtons
              isAbsolute
              onSubmit={() => this.onSubmit()}
              customRenderButtons={() => (
                <React.Fragment>
                  <PrimaryButton
                    onClick={() => this.onSubmit()}
                    disabled={
                      this.state.messageType === MessageBarType.success ||
                      this.state.isFileUploading
                        ? this.state.isFileUploading
                          ? true
                          : false
                        : false
                    }
                    styles={{
                      rootDisabled: {
                        background: "#626262",
                        borderColor: "#777373 !important",
                      },
                    }}
                    iconProps={{ iconName: "CheckMark" }}
                    text="Yayımla"
                  />
                  {this.state.isFileUploading && (
                    <div className="tooltip-warning">
                      Bazı dosyalar yükleniyor, lütfen dosyalar yüklenene kadar
                      bekleyiniz.
                    </div>
                  )}
                </React.Fragment>
              )}
            />
          }
        />
        {this.renderMessageBar()}

        {this.renderForm()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateThemedFacility);
