import { StatusEnums } from "../BaseEnum";

export interface SeaTourismFacilityTypeSaveDataModel {
  SeaTourismFacilityTypeName: string;
  SeaTourismFacilityTypeName_En: string;
  subCategoryId: number;
  isMinistry: boolean;
  status: StatusEnums;
}
export interface SeaTourismFacilityTypeSaveRequestModel {
  data: SeaTourismFacilityTypeSaveDataModel;
}

export interface SeaTourismFacilityTypeUpdateDataModel {
  SeaTourismFacilityTypeId: number;
  SeaTourismFacilityTypeName: string;
  SeaTourismFacilityTypeName_En: string;
  subCategoryId: number;
  isMinistry: boolean;
  status: StatusEnums;
}

export interface SeaTourismFacilityTypeUpdateRequestModel {
  data: SeaTourismFacilityTypeUpdateDataModel;
}

export interface SeaTourismFacilityTypeChangeStatusRequestModel {
  status: StatusEnums;
  id: number;
}

export enum ConditionOperatorEnum {
  Eq = 0,
  NotEq = 1,
  Like = 2,
  Gt = 3,
  Lt = 4,
  Ge = 5,
  Le = 6,
}

export interface criteriaItemListData {
  propertyName: string;
  value: any;
  isOr: boolean;
  conditionOperator: ConditionOperatorEnum;
}

export interface SeaTourismFacilityTypeGetAllRequestModel {
  criteriaItemList?: criteriaItemListData[];
  selectItemList?: string[];
  pageSize?: number;
  pageIndex?: number;
  orderField?: string;
  isDescending?: boolean;
  status?: StatusEnums;
}
export interface SeaTourismFacilityTypeGetByIdRequestModel {
  id: number;
}

export interface SeaTourismFacilityTypeChangeStatusRequestModel {
  id: number;
  status: StatusEnums;
}
