import * as React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { RootState } from "src/store/reducer";
import { qrUrl } from "src/config/apiConfig";

interface OwnProps {
  qrCodePath: string;
}

function mapStateToProps(state: RootState) {
  return {
    qrCodePath: state.form.formData.qrCodePath,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {

    },
    dispatch
  );
}
interface QrCodeState {

}

type QrCodeProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

class QrCode extends React.Component<
  QrCodeProps,
  QrCodeState
> {

  render() {
    return this.props.qrCodePath ? (
      <div className="item row qrWrapper">
        <img
          style={{
            width: "170px",
            float: "left",
            marginBottom: "15px",
          }}
          src={qrUrl + this.props.qrCodePath}
          alt="qr code"
        />
        <br />
        <a
          className="qrButton"
          style={{ width: "100px", marginTop: "-20px", fontSize: 11 }}
          target="_blank"
          href={qrUrl + this.props.qrCodePath}
          download 
          rel="noreferrer"
        >
          QR indir
        </a>
      </div>
    ) : null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QrCode);
