import { HttpClient } from "src/library/HttpClient";
import {
  WinterTourismCertificationGetAllRequestModel,
  WinterTourismCertificationGetByIdEndIdRequestModel,
  WinterTourismCertificationSaveRequestModel,
  WinterTourismCertificationUpdateRequestModel,
  WinterTourismCertificationGetByIdRequestModel,
  WinterTourismCertificationChangeStatusRequestModel,
  RemovePublish,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";
import { ILeaveCertificationProcessRequest } from "../BaseEnum";

export default class WinterTourismCertification {
  async getAll(
    request: WinterTourismCertificationGetAllRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismCertification +
        "GetWinterTourismCertificateList",
      { ...request, token }
    );
  }

  async excelDownload(request: any, name: string) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.WinterTourismCertification + "GenerateExcel",
      name,
      { ...request, token }
    );
  }

  async save(
    request: WinterTourismCertificationSaveRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismCertification + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(
    request: WinterTourismCertificationUpdateRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismCertification + "SaveOrUpdate",
      { ...request, token }
    );
  }
  async updateBySequenceId(
    request: WinterTourismCertificationUpdateRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismCertification + "UpdateBySequenceId",
      { ...request, token }
    );
  }

  async getById(
    request: WinterTourismCertificationGetByIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismCertification + "GetById",
      { ...request, token }
    );
  }
  async getBySequenceId(
    request: WinterTourismCertificationGetByIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismCertification + "GetBySequenceId",
      { ...request, token }
    );
  }

  async getByEndId(
    request: WinterTourismCertificationGetByIdEndIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismCertification + "GetByEntityId",
      { ...request, token }
    );
  }

  async changeStatus(
    request: WinterTourismCertificationChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismCertification + "ChangeStatus",
      { ...request, token }
    );
  }

  async GetWinterTourismCertificationAndRestaurantCount(
    request: WinterTourismCertificationGetAllRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismCertification +
        "GetWinterTourismAndRestaurantCount",
      { ...request, token }
    );
  }
  async RemoveWebPublish(request: RemovePublish): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification + "RemoveWebPublish",
      { ...request, token }
    );
  }
  async RemoveQrPublish(request: RemovePublish): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.hotelCertification + "RemoveQrPublish",
      { ...request, token }
    );
  }
  async ChangeCompanyUser(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismCertification + "ChangeCompanyUser",
      { ...request, token }
    );
  }

  async leaveCertificationProcess(
    request: ILeaveCertificationProcessRequest
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismCertification + "LeaveCertificationProcess",
      { ...request, token }
    );
  }

}
