import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData } from "./form/actions";
import SystemSettingService from "src/services/SystemSetting/SystemSettingService";
import { MessageType } from "./system/types";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function ChangeTokenEncryptor(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const systemSettingService = new SystemSettingService();
    const result = await systemSettingService.ChangeTokenEncryptor(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Token Düştü",
          "Token Düştü",
          MessageType.SUCCESS,
          "Token Düştü"
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}
export function OpenOrCloseSystemForCompanyUser(
  request: any,
  successMessage: any,
  error: any
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const systemSettingService = new SystemSettingService();
    const getByKeyResult = await systemSettingService.GetByKey({
      key: "close-system",
    });
    if (getByKeyResult.data === "0") {
      successMessage = "Firma kullanıcılarına kapatma işlemi tamamlandı.";
      error = "Firma kullanıcılarına kapatma işleminde hata oluştu.";
    } else {
      successMessage = "Firma kullanıcılarına açma işlemi tamamlandı.";
      error = "Firma kullanıcılarına açma işleminde hata oluştu.";
    }

    const result = await systemSettingService.OpenOrCloseSystemForCompanyUser(
      request
    );
    //dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          successMessage,
          "Başarılı",
          MessageType.SUCCESS,
          "İşlem Tamam"
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          `${error} Hata Mesajı: ${result.errorMessage}`,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Hata"
        )
      );
      return false;
    }
  };
}

export function GetByKey(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const systemSettingService = new SystemSettingService();
    const result = await systemSettingService.GetByKey(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
  };
}

export function companyUserStatus(): ThunkResult<Promise<any>> {
  return async () => {
    const systemSettingService = new SystemSettingService();
    const getByKeyResult = await systemSettingService.GetByKey({
      key: "close-system",
    });
    if (getByKeyResult.data === "1") {
      return true;
    } else {
      return false;
    }
  };
}
