import { HttpClient } from "src/library/HttpClient";
import {
  HotelTransferBackCertificationRequestGetMergedCertificateListModel,
  HotelTransferBackCertificationRequestRestoreMergedHotelCertificateModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class HotelTransferBackCertification {
  async getMergedCertificateList(
    request: HotelTransferBackCertificationRequestGetMergedCertificateListModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.MergedCertificate + "GetMergedCertificateList",
      { ...request, token }
    );
  }

  async restoreMergedHotelCertificateList(
    request: HotelTransferBackCertificationRequestRestoreMergedHotelCertificateModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.MergedCertificate + "RestoreMergedHotelCertificate",
      { ...request, token }
    );
  }
}
