import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { panels } from "src/utils/constants";
import { Link } from "react-router-dom";
import { History } from "history";
import BasicTable from "../BasicTable";
import {
  TextField,
  Dropdown,
  PrimaryButton,
  Label,
} from "office-ui-fabric-react";
import { getAll, changeStatus } from "src/store/thunkCounty";
import {
  CountyGetAllRequestModel,
  ConditionOperatorEnum,
  CountyChangeStatusRequestModel,
} from "src/services/County/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";
import { castThunkAction } from "src/helpers/casting";
import { toggleConfirmDialog } from "src/store/system/actions";
import { listCount } from "src/config/otherConfig";
import { getAllM } from "src/store/thunksCity";
import { CityGetAllRequestModel } from "src/services/City/RequestModel";
interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        getAll,
        changeStatus,
        toggleConfirmDialog,
        getAllM,
      },
      dispatch
    ),
  };
}

type CountyProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  pagerTotal: number;
}

class County extends Component<CountyProps, OwnState> {
  state = {
    filterData: {
      countyName: "",
      cityId: 0,
      status: 1,
    },
    pagerCurrent: 0,
    pagerTotal: 0,
  };

  UNSAFE_componentWillMount() {
    this.getData(this.state.pagerCurrent);
  }
  UNSAFE_componentWillUpdate(nextProps: any, nextState: any) {
    if (nextState.pagerTotal !== nextProps.formData.pageCount)
      this.setState({
        ...this.state,
        pagerTotal: this.props.formData.pageCount,
      });
  }

  getData(pagerSelected: number) {
    const request: CountyGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "districtName",
          value: this.state.filterData.countyName,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Like,
        },
        {
          propertyName: "cityId",
          value: this.state.filterData.cityId,
          isOr: false,
          conditionOperator:
            this.state.filterData.cityId === 0
              ? ConditionOperatorEnum.NotEq
              : ConditionOperatorEnum.Eq,
        },
        {
          propertyName: "Status",
          value: this.state.filterData.status,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
      selectItemList: [
        "DistrictCode",
        "City.CityName",
        "DistrictId",
        "DistrictName",
        "UpdatedSystemUserName",
        "CreatedSystemUserName",
        "CreatedSystemUserDatetime",
        "UpdatedSystemUserDatetime",
        "Status",
      ],
      pageSize: listCount,
      pageIndex: pagerSelected,
    };

    castThunkAction(this.props.getAll(request)).then((response: any) => {
      const request2: CityGetAllRequestModel = {
        selectItemList: ["CityId", "CityName"],
      };
      this.props.getAllM(request2);
    });
  }

  onDelete(CountyId: number) {
    const request: CountyChangeStatusRequestModel = {
      id: CountyId,
      status: StatusEnums.Deleted,
    };
    castThunkAction(this.props.changeStatus(request)).then((response: any) => {
      this.getData(this.state.pagerCurrent);
    });
  }

  renderDashboardItems() {
    return (
      <div className="dashboard-items row">
        {panels.map(
          (item, index) =>
            item.children && (
              <React.Fragment>
                <div className="title">
                  {item.text}
                  <div className="title-text">{item.subText}</div>
                </div>
                <div className="childrens row">
                  {item.children.map((children, childrenIndex) => (
                    <div key={index} className={`dashboard-item col-md-3`}>
                      <Link
                        className="row"
                        to={children.url ? children.url : "/"}
                      >
                        <div className="icon">
                          <i className={children.icon}></i>
                        </div>
                        <div className="title">
                          {children.text}
                          <div className="sub-text">{children.subText}</div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )
        )}
      </div>
    );
  }

  cleargetData(pagerSelected: number) {
    this.setState(
      {
        filterData: {
          countyName: "",
          cityId: 0,
          status: 1,
        },
        pagerCurrent: 0,
        pagerTotal: 0,
      },
      () => {
        this.getData(pagerSelected);
      }
    );
  }
  renderFilter() {
    return (
      <div className="table-filter row">
        <TextField
          className="custom-textfield col-md-3"
          label="İlçe İsmi İle Arama"
          value={this.state.filterData?.countyName || ""}
          onChange={(event: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                countyName: event.target.value !== "" ? event.target.value : "",
              },
            });
          }}
        />

        <Dropdown
          defaultSelectedKey={this.state.filterData?.cityId || ""}
          onChanged={(event: any) =>
            this.setState({
              filterData: {
                ...this.state.filterData,
                cityId: event.key,
              },
            })
          }
          label="İl"
          options={(
            this.props.formData.GroupList?.dataList || ([] as any)
          ).map((item: any) => ({ key: item.CityId, text: item.CityName }))}
          className="custom-dropdown col-md-3"
        />

        <Dropdown
          options={[
            { key: 1, text: "Yayımda" },
            // { key: 2, text: "Taslak" },
            { key: 8, text: "Silindi" },
          ]}
          className="custom-dropdown col-md-3"
          label="Duruma Göre Arama"
          placeholder="Seçiniz"
          selectedKey={this.state.filterData?.status || 1}
          onChange={(event: any, option: any) => {
            this.setState({
              filterData: {
                ...this.state.filterData,
                status: option.key,
              },
            });
          }}
          calloutProps={{
            className: "custom-dropdown-callout",
          }}
        />
        <PrimaryButton
          text="Arama Yap"
          className="custom-button"
          onClick={() => this.getData(0)}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />

        <PrimaryButton
          text="Tüm Filtreleri Temizle"
          className="custom-button"
          onClick={() => this.cleargetData(0)}
          iconProps={{ iconName: "trash" }}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
              background: "#FF4343",
              borderColor: "#FF4350",
            },
          }}
        />
      </div>
    );
  }

  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "DistrictCode",
            text: "Kod",
            col: "col-md-1",
          },
          {
            columnName: "CityCityName",
            text: "İl",
            col: "col-md-1",
          },
          {
            columnName: "DistrictName",
            text: "İlçe",
            col: "col-md-2",
          },

          {
            columnName: "CreatedSystemUserName",
            text: "Kayıt Yapan",
            col: "col-md-2",
          },
          {
            columnName: "CreatedSystemUserDatetimeString",
            text: "Kayıt Tarihi",
            col: "col-md-1",
          },

          {
            columnName: "UpdatedSystemUserName",
            text: "Güncelleme Yapan",
            col: "col-md-2",
          },

          {
            columnName: "UpdatedSystemUserDatetimeString",
            text: "Güncelleme Tarihi",
            col: "col-md-1",
          },
          {
            columnName: "DistrictId",
            text: "İşlem",
            col: "col-md-2",
            className: "operations",
            onRender: (item: { DistrictId: any; Status: StatusEnums }) => (
              <React.Fragment>
                {
                  <button
                    onClick={(x) =>
                      (window.location.href = `/County/edit/${item.DistrictId}`)
                    }
                  >
                    <i className="icon-edit-outline"></i> Düzenle
                  </button>
                }

                {item.Status !== StatusEnums.Deleted && (
                  <button
                    onClick={() =>
                      this.props.toggleConfirmDialog(true, "Silme işlemini gerçekleştirmek istiyor musunuz?", () =>
                        this.onDelete(item.DistrictId)
                      )
                    }
                  >
                    <i className="icon-trash-outline"></i> Sil
                  </button>
                )}
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.dataList || []}
      />
    );
  }
  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.state.pagerTotal}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }
  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getData(selected);
  };
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="İlçe Yönetimi"
          subTitle="Yönetilebilecek tüm alanları aşağıdan görüntüleyebilirsiniz."
          backUrl="/"
          showBack
          history={this.props.history}
        />
        <PrimaryButton
          onClick={() => this.props.history.push("/County/create")}
          iconProps={{ iconName: "Add" }}
          className="absolute-create"
          text="İlçe Ekle"
          styles={{
            root: {
              background: "#62a98e",
              borderColor: "#5b9e85",
            },
            rootHovered: {
              background: "#5b9e85",
              borderColor: "#5b9e85",
            },
            rootDisabled: {
              background: "#ececec",
            },
          }}
        />
        <Label className="totalRecord">
          Toplam Kayıt Sayısı: {this.props.formData?.dataCount}
        </Label>
        {this.renderFilter()}
        {this.renderList()}
        {this.renderPager()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(County);
