import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import CertificateAppeal from "src/services/CertificateAppeal/CertificateAppealService";
import {
  CertificateAppealGetAllRequestModel,
  CertificateAppealChangeStatusRequestModel,
  CertificateAppealGetByIdRequestModel,
  CertificateAppealUpdateRequestModel,
  CertificateAppealSaveRequestModel,
} from "src/services/CertificateAppeal/RequestModel";
import { MessageType } from "./system/types";
import { errorMessageSaveOrUpdate } from "src/helpers/functions";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllPage(
  request: CertificateAppealGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const CertificateAppealService = new CertificateAppeal();
    const result = await CertificateAppealService.getAll(request);
    dispatch(setFormData("excel", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: CertificateAppealGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CertificateAppealService = new CertificateAppeal();
    const result = await CertificateAppealService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: CertificateAppealSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CertificateAppealService = new CertificateAppeal();
    const result = await CertificateAppealService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return result;
    } else {

      errorMessageSaveOrUpdate(dispatch,result)
     
      return false;
    }
  };
}
export function update(
  request: CertificateAppealUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CertificateAppealService = new CertificateAppeal();
    const result = await CertificateAppealService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      errorMessageSaveOrUpdate(dispatch,result)

      return false;
    }
  };
}

export function getById(
  request: CertificateAppealGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CertificateAppealService = new CertificateAppeal();
    const result = await CertificateAppealService.getById(request);
    dispatch(
      initialFormData({
        ...result.data,
        initialAttachmentList: result.data.attachmentList,
      })
    );
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: CertificateAppealChangeStatusRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const CertificateAppealService = new CertificateAppeal();
    const result = await CertificateAppealService.changeStatus(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}
