import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import VehicleType from "src/services/VehicleType/VehicleTypeService";
import {
  VehicleTypeGetAllRequestModel,
  VehicleTypeChangeStatusRequestModel,
  VehicleTypeGetByIdRequestModel,
  VehicleTypeUpdateRequestModel,
  VehicleTypeSaveRequestModel,
} from "src/services/VehicleType/RequestModel";
import { MessageType } from "./system/types";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllVT(
  request: VehicleTypeGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const VehicleTypeService = new VehicleType();
    const result = await VehicleTypeService.getAll(request);
    dispatch(setFormData("GroupListVT", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: VehicleTypeGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const VehicleTypeService = new VehicleType();
    const result = await VehicleTypeService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: VehicleTypeSaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const VehicleTypeService = new VehicleType();
    const result = await VehicleTypeService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "İl Yönetimi"
        )
      );
      return result;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "İl Yönetimi"
        )
      );
      return false;
    }
  };
}
export function update(
  request: VehicleTypeUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const VehicleTypeService = new VehicleType();
    const result = await VehicleTypeService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "İl Yönetimi"
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "İl Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getById(
  request: VehicleTypeGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const VehicleTypeService = new VehicleType();
    const result = await VehicleTypeService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: VehicleTypeChangeStatusRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const VehicleTypeService = new VehicleType();
    const result = await VehicleTypeService.changeStatus(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "İl Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "İl Yönetimi"
        )
      );
      return false;
    }
  };
}
