import * as React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import { initializeIcons } from "@uifabric/icons";

// Components
import Loader from "./components/Loader";
import MessageBox from "./components/MessageBox";
import MessageBoxConfirme from "./components/MessageBoxConfirme";
import Confirm from "./components/Confirm";
import ConfirmFilterRemove from "./components/ConfirmFilterRemove";

// Routes
import HomeRoute from "./routes/HomeRoute";

import AuthVerification from "./components/AuthVerification";

import {
  EditSystemUserManagement,
  SystemUserManagementRoute,
  CreateSystemUserManagement,
} from "./routes/SystemUserManagement";
import { CityRoute, CreateCity, EditCity } from "./routes/City";
import {
  VehicleTypeRoute,
  CreateVehicleType,
  EditVehicleType,
} from "./routes/VehicleType";
import {
  VehicleDocumentTypeRoute,
  CreateVehicleDocumentType,
  EditVehicleDocumentType,
} from "./routes/VehicleDocumentType";
import {
  CertificateAppealRoute,
  CreateCertificateAppeal,
  EditCertificateAppeal,
} from "./routes/CertificateAppeal";

// import { LayoverRoute, CreateLayover, EditLayover } from './routes/Layover';
import {
  HotelCertificationRoute,
  CreateHotelCertification,
  EditHotelCertification,
  EditHotelCertificationEndId,
} from "./routes/HotelCeritifaciton";

import {
  RestaurantRoute,
  CreateRestaurant,
  EditRestaurant,
} from "./routes/Restaurant";
import {
  TransportCeritifacitonRoute,
  CreateTransportCeritifaciton,
  EditTransportCeritifaciton,
  EditTransportCertification,
  EditTransportCertificationEndId,
} from "./routes/TransportCeritifaciton";
import { AirportRoute, CreateAirport, EditAirport } from "./routes/Airport";

import { CountyRoute, CreateCounty, EditCounty } from "./routes/County";
import { NotificationRoute } from "./routes/Notification";
import {
  CertificateTypeRoute,
  CreateCertificateType,
  EditCertificateType,
} from "./routes/CertificateType";
import {
  MainCategoryRoute,
  CreateMainCategory,
  EditMainCategory,
} from "./routes/MainCategory";
import {
  HotelRoute,
  CreateHotel,
  EditHotel,
  EditHotelCeritifaciton,
} from "./routes/Hotel";
import { HospitalRoute, CreateHospital, EditHospital } from "./routes/Hospital";

import { DenunciationRoute, EditDenunciation } from "./routes/Denunciation";

import {
  SubCategoryRoute,
  CreateSubCategory,
  EditSubCategory,
} from "./routes/SubCategory";
import {
  LayoverSubCategoryRoute,
  CreateLayoverSubCategory,
  EditLayoverSubCategory,
} from "./routes/LayoverSubCategory";

import {
  CertificateStatusTypeRoute,
  CreateCertificateStatusType,
  EditCertificateStatusType,
} from "./routes/CertificateStatusType";
import {
  LayoverFacilityTypeRoute,
  CreateLayoverFacilityType,
  EditLayoverFacilityType,
} from "./routes/LayoverFacilityType";

import { Profile } from "./routes/Profile";

import {
  StatisticRoute,
  ProvinceBasedReport,
  HospitalBasedReport,
  GeneralHotelReport,
  Chart,
  CertificationActionReportRoute,
} from "./routes/Statistic";

import {
  CongressRoute,
  CreateCongress,
  EditCongress,
  EditCongressCeritifaciton,
} from "./routes/Congress";
import {
  CongressCertificationRoute,
  CreateCongressCertification,
  EditCongressCertification,
  EditCongressCertificationEndId,
} from "./routes/CongressCeritifaciton";
import {
  CongressSubCategoryRoute,
  CreateCongressSubCategory,
  EditCongressSubCategory,
} from "./routes/CongressSubCategory";
import {
  CongressFacilityTypeRoute,
  CreateCongressFacilityType,
  EditCongressFacilityType,
} from "./routes/CongressFacilityType";

import {
  SeaTourismRoute,
  CreateSeaTourism,
  EditSeaTourism,
  EditSeaTourismCeritifaciton,
} from "./routes/SeaTourism";
import {
  SeaTourismCertificationRoute,
  CreateSeaTourismCertification,
  EditSeaTourismCertification,
  EditSeaTourismCertificationEndId,
} from "./routes/SeaTourismCeritifaciton";
import {
  SeaTourismSubCategoryRoute,
  CreateSeaTourismSubCategory,
  EditSeaTourismSubCategory,
} from "./routes/SeaTourismSubCategory";
import {
  SeaTourismFacilityTypeRoute,
  CreateSeaTourismFacilityType,
  EditSeaTourismFacilityType,
} from "./routes/SeaTourismFacilityType";

import {
  WinterTourismRoute,
  CreateWinterTourism,
  EditWinterTourism,
  EditWinterTourismCeritifaciton,
} from "./routes/WinterTourism";
import {
  WinterTourismCertificationRoute,
  CreateWinterTourismCertification,
  EditWinterTourismCertification,
  EditWinterTourismCertificationEndId,
} from "./routes/WinterTourismCeritifaciton";
import {
  WinterTourismSubCategoryRoute,
  CreateWinterTourismSubCategory,
  EditWinterTourismSubCategory,
} from "./routes/WinterTourismSubCategory";
import {
  WinterTourismFacilityTypeRoute,
  CreateWinterTourismFacilityType,
  EditWinterTourismFacilityType,
} from "./routes/WinterTourismFacilityType";

import {
  ThemedFacilityRoute,
  CreateThemedFacility,
  EditThemedFacility,
  EditThemedFacilityCeritifaciton,
} from "./routes/ThemedFacility";
import {
  ThemedFacilityCertificationRoute,
  CreateThemedFacilityCertification,
  EditThemedFacilityCertification,
  EditThemedFacilityCertificationEndId,
} from "./routes/ThemedFacilityCeritifaciton";
import ViewHotelRoute from "./routes/Hotel/ViewHotel";
import InstantReportRoute from "./routes/Statistic/InstantReport";
import {
  AdditionalControlCreate,
  AdditionalControlCreateType,
  AdditionalControlEdit,
  AdditionalControlList,
  AdditionalControlListFacility,
} from "./routes/AdditionalControl";
import FacilityCompanyList from "./routes/Statistic/FacilityCompanyList";
import UserActionReport from "./routes/Statistic/UserActionReport";
import { MergeHotelRoute } from "./routes/MergeHotel";
import { HotelTransferBackCertificationRoute } from "./routes/HotelTransferBackCeritifaciton";
import cookie from "react-cookies";
import AdditionalAudit from "./routes/AdditionalAudit/AdditionalAuditReport";
import ConfirmModal from "./components/ConfirmModal";
import {
  CreateTransport,
  EditTransport,
  TransportRoute,
} from "./routes/Transport";
import { CreateFair, EditFair, FairRoute } from "./routes/Fair";

initializeIcons();

class App extends React.Component {
  render() {
    let usertype = Number(cookie.load("systemUserInfo"));

    return (
      <Provider store={store}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <AuthVerification>
            <Route exact path="/" component={HomeRoute} />
            <Route
              exact
              path="/HotelTransferBack"
              component={HotelTransferBackCertificationRoute}
            />
            {usertype === 1 && (
              <>
                <Route
                  exact
                  path="/systemuser-management"
                  component={SystemUserManagementRoute}
                />

                <Route
                  exact
                  path="/mergeHotelRoute"
                  component={MergeHotelRoute}
                />

                <Route
                  exact
                  path="/systemuser-management/create"
                  component={CreateSystemUserManagement}
                />
                <Route
                  exact
                  path="/systemuser-management/edit/:SystemUserId"
                  component={EditSystemUserManagement}
                />
              </>
            )}

            {(usertype === 3 || usertype === 5 || usertype === 1) && (
              <>
                <Route exact path="/City" component={CityRoute} />
                <Route exact path="/City/create" component={CreateCity} />
                <Route exact path="/City/edit/:CityId" component={EditCity} />
              </>
            )}

            <Route exact path="/Denunciation" component={DenunciationRoute} />
            <Route
              exact
              path="/Denunciation/edit/:DenunciationId/:FacilityType"
              component={EditDenunciation}
            />

            {(usertype === 3 || usertype === 5 || usertype === 1) && (
              <>
                <Route exact path="/VehicleType" component={VehicleTypeRoute} />
                <Route
                  exact
                  path="/VehicleType/create"
                  component={CreateVehicleType}
                />
                <Route
                  exact
                  path="/VehicleType/edit/:VehicleTypeId"
                  component={EditVehicleType}
                />
              </>
            )}

            {(usertype === 3 || usertype === 5 || usertype === 1) && (
              <>
                <Route
                  exact
                  path="/VehicleDocumentType"
                  component={VehicleDocumentTypeRoute}
                />
                <Route
                  exact
                  path="/VehicleDocumentType/create"
                  component={CreateVehicleDocumentType}
                />
                <Route
                  exact
                  path="/VehicleDocumentType/edit/:VehicleDocumentTypeyId"
                  component={EditVehicleDocumentType}
                />
              </>
            )}

            {(usertype === 3 || usertype === 5 || usertype === 1) && (
              <>
                <Route
                  exact
                  path="/LayoverFacilityType"
                  component={LayoverFacilityTypeRoute}
                />
                <Route
                  exact
                  path="/LayoverFacilityType/create"
                  component={CreateLayoverFacilityType}
                />
                <Route
                  exact
                  path="/LayoverFacilityType/edit/:LayoverFacilityTypeId"
                  component={EditLayoverFacilityType}
                />
              </>
            )}

            {(usertype === 3 || usertype === 5 || usertype === 1) && (
              <>
                <Route
                  exact
                  path="/CertificateStatusType"
                  component={CertificateStatusTypeRoute}
                />
                <Route
                  exact
                  path="/CertificateStatusType/create"
                  component={CreateCertificateStatusType}
                />
                <Route
                  exact
                  path="/CertificateStatusType/edit/:CertificateStatusTypeId"
                  component={EditCertificateStatusType}
                />
              </>
            )}

            {usertype === 1 && (
              <>
                <Route
                  exact
                  path="/CertificateAppeal"
                  component={CertificateAppealRoute}
                />
                <Route
                  exact
                  path="/CertificateAppeal/create"
                  component={CreateCertificateAppeal}
                />
                <Route
                  exact
                  path="/CertificateAppeal/edit/:CertificateAppealId"
                  component={EditCertificateAppeal}
                />
              </>
            )}
            {(usertype === 2 ||
              usertype === 3 ||
              usertype === 5 ||
              usertype === 1) && (
              <>
                <Route
                  exact
                  path="/HotelCertification"
                  component={HotelCertificationRoute}
                />
                <Route
                  exact
                  path="/HotelCertification/create"
                  component={CreateHotelCertification}
                />
                <Route
                  exact
                  path="/HotelCertification/create/:HotelId"
                  component={CreateHotelCertification}
                />
                <Route
                  exact
                  path="/HotelCertification/edit/:LayoverId/sequence/:SequenceId"
                  component={EditHotelCertification}
                />
                <Route
                  exact
                  path="/HotelCertification/edit/:LayoverId"
                  component={EditHotelCertification}
                />

                <Route
                  exact
                  path="/HotelCertification/editEndId/:LayoverId"
                  component={EditHotelCertificationEndId}
                />
              </>
            )}

            {/* {(usertype === 3 || usertype === 5 || usertype === 1) && (
              <>
                <Route exact path="/Comparison" component={ComparisonRoute} />
              </>
            )} */}

            {(usertype === 2 ||
              usertype === 3 ||
              usertype === 5 ||
              usertype === 1) && (
              <>
                <Route exact path="/Airport" component={AirportRoute} />
                <Route exact path="/Airport/create" component={CreateAirport} />
                <Route
                  exact
                  path="/Airport/edit/:AirportId"
                  component={EditAirport}
                />
              </>
            )}

            {(usertype === 2 ||
              usertype === 3 ||
              usertype === 5 ||
              usertype === 1) && (
              <>
                <Route exact path="/Restaurant" component={RestaurantRoute} />
                <Route
                  exact
                  path="/Restaurant/create"
                  component={CreateRestaurant}
                />
                <Route
                  exact
                  path="/Restaurant/edit/:RestaurantId"
                  component={EditRestaurant}
                />
              </>
            )}

            {(usertype === 2 ||
              usertype === 3 ||
              usertype === 5 ||
              usertype === 1) && (
              <>
                <Route
                  exact
                  path="/TransportCertification"
                  component={TransportCeritifacitonRoute}
                />
                <Route
                  exact
                  path="/TransportCertification/create"
                  component={CreateTransportCeritifaciton}
                />
                <Route
                  exact
                  path="/TransportCertification/edit/:LayoverId"
                  component={EditTransportCeritifaciton}
                />
                <Route
                  exact
                  path="/TransportCertification/edit/:LayoverId/sequence/:SequenceId"
                  component={EditTransportCertification}
                />
                {/* <Route
                    exact
                    path="/TransportCertification/edit/:LayoverId"
                    component={EditTransportCertification}
                  /> */}

                <Route
                  exact
                  path="/TransportCertification/editEndId/:LayoverId"
                  component={EditTransportCertificationEndId}
                />
              </>
            )}

            {(usertype === 3 ||
              usertype === 5 ||
              usertype === 1 ||
              usertype === 2) && (
              <>
                {(usertype === 3 ||
                  usertype === 5 ||
                  usertype === 1 ||
                  usertype === 2) && (
                  <Route exact path="/Transport" component={TransportRoute} />
                )}
                <Route
                  exact
                  path="/Transport/create"
                  component={CreateTransport}
                />
                <Route
                  exact
                  path="/Transport/edit/:HotelId"
                  component={EditTransport}
                />
              </>
            )}

            {(usertype === 3 || usertype === 5 || usertype === 1) && (
              <>
                <Route
                  exact
                  path="/CertificateType"
                  component={CertificateTypeRoute}
                />
                <Route
                  exact
                  path="/CertificateType/create"
                  component={CreateCertificateType}
                />
                <Route
                  exact
                  path="/CertificateType/edit/:CertificateTypeId"
                  component={EditCertificateType}
                />
              </>
            )}

            {usertype === 1 && (
              <>
                <Route
                  exact
                  path="/MainCategory"
                  component={MainCategoryRoute}
                />
                <Route
                  exact
                  path="/MainCategory/create"
                  component={CreateMainCategory}
                />
                <Route
                  exact
                  path="/MainCategory/edit/:MainCategoryId"
                  component={EditMainCategory}
                />
              </>
            )}

            {(usertype === 3 || usertype === 5 || usertype === 1) && (
              <>
                <Route exact path="/County" component={CountyRoute} />
                <Route exact path="/County/create" component={CreateCounty} />
                <Route
                  exact
                  path="/County/edit/:CountyId"
                  component={EditCounty}
                />
              </>
            )}

            {usertype === 1 && (
              <>
                <Route exact path="/SubCategory" component={SubCategoryRoute} />
                <Route
                  exact
                  path="/SubCategory/create"
                  component={CreateSubCategory}
                />
                <Route
                  exact
                  path="/SubCategory/edit/:SubCategoryId"
                  component={EditSubCategory}
                />
              </>
            )}

            {(usertype === 3 || usertype === 5 || usertype === 1) && (
              <>
                <Route
                  exact
                  path="/LayoverSubCategory"
                  component={LayoverSubCategoryRoute}
                />
                <Route
                  exact
                  path="/LayoverSubCategory/create"
                  component={CreateLayoverSubCategory}
                />
                <Route
                  exact
                  path="/LayoverSubCategory/edit/:LayoverSubCategoryId"
                  component={EditLayoverSubCategory}
                />
              </>
            )}

            <Route exact path="/Profile/" component={Profile} />

            <Route exact path="/Notification" component={NotificationRoute} />

            {(usertype === 3 ||
              usertype === 5 ||
              usertype === 1 ||
              usertype === 2) && (
              <>
                {(usertype === 3 || usertype === 5 || usertype === 1) && (
                  <Route exact path="/Hotel" component={HotelRoute} />
                )}
                <Route exact path="/Hotel/create" component={CreateHotel} />
                <Route
                  exact
                  path="/Hotel/edit/:HotelId"
                  component={EditHotel}
                />
                <Route
                  exact
                  path="/Hotel/view/:HotelId"
                  component={ViewHotelRoute}
                />
              </>
            )}

            <Route
              exact
              path="/EditHotelCeritifaciton/edit/:HotelId/:BackId/"
              component={EditHotelCeritifaciton}
            />

            {(usertype === 3 ||
              usertype === 5 ||
              usertype === 1 ||
              usertype === 2) && (
              <>
                {(usertype === 3 || usertype === 5 || usertype === 1) && (
                  <Route exact path="/Hospital" component={HospitalRoute} />
                )}
                <Route
                  exact
                  path="/Hospital/create"
                  component={CreateHospital}
                />
                <Route
                  exact
                  path="/Hospital/edit/:HospitalId"
                  component={EditHospital}
                />
              </>
            )}

            {(usertype === 3 || usertype === 5 || usertype === 1) && (
              <>
                {(usertype === 3 || usertype === 5 || usertype === 1) && (
                  <Route
                    exact
                    path="/AdditionalAudit"
                    component={AdditionalAudit}
                  />
                )}
                <Route
                  exact
                  path={"/CertificationActionReport"}
                  component={CertificationActionReportRoute}
                />
                <Route exact path="/Statistic" component={StatisticRoute} />
                <Route
                  exact
                  path="/Statistic/ProvinceBasedReport"
                  component={ProvinceBasedReport}
                />
                <Route
                  exact
                  path="/Statistic/HospitalBasedReport"
                  component={HospitalBasedReport}
                />
                <Route
                  exact
                  path="/Statistic/InstantReport"
                  component={InstantReportRoute}
                />
                <Route
                  exact
                  path="/Statistic/GeneralHotelReport"
                  component={GeneralHotelReport}
                />
                <Route exact path="/Statistic/Chart" component={Chart} />
                <Route
                  exact
                  path="/Statistic/FacilityCompanyList"
                  component={FacilityCompanyList}
                />
                <Route
                  exact
                  path="/Statistic/UserActionReport"
                  component={UserActionReport}
                />
              </>
            )}

            {(usertype === 2 ||
              usertype === 3 ||
              usertype === 5 ||
              usertype === 1) && (
              <>
                <Route
                  exact
                  path="/CongressCertification"
                  component={CongressCertificationRoute}
                />
                <Route
                  exact
                  path="/CongressCertification/create"
                  component={CreateCongressCertification}
                />
                <Route
                  exact
                  path="/CongressCertification/edit/:CongressCertificateId"
                  component={EditCongressCertification}
                />
                <Route
                  exact
                  path="/CongressCertification/edit/:CongressCertificateId/sequence/:SequenceId"
                  component={EditCongressCertification}
                />
                <Route
                  exact
                  path="/CongressCertification/editEndId/:CongressCertificateId"
                  component={EditCongressCertificationEndId}
                />
              </>
            )}

            {(usertype === 3 || usertype === 5 || usertype === 1) && (
              <>
                <Route
                  exact
                  path="/CongressFacilityType"
                  component={CongressFacilityTypeRoute}
                />
                <Route
                  exact
                  path="/CongressFacilityType/create"
                  component={CreateCongressFacilityType}
                />
                <Route
                  exact
                  path="/CongressFacilityType/edit/:CongressFacilityTypeId"
                  component={EditCongressFacilityType}
                />
              </>
            )}

            {(usertype === 3 || usertype === 5 || usertype === 1) && (
              <>
                <Route
                  exact
                  path="/CongressSubCategory"
                  component={CongressSubCategoryRoute}
                />
                <Route
                  exact
                  path="/CongressSubCategory/create"
                  component={CreateCongressSubCategory}
                />
                <Route
                  exact
                  path="/CongressSubCategory/edit/:CongressSubCategoryId"
                  component={EditCongressSubCategory}
                />
              </>
            )}

            {(usertype === 3 ||
              usertype === 5 ||
              usertype === 1 ||
              usertype === 2) && (
              <>
                {(usertype === 3 || usertype === 5 || usertype === 1) && (
                  <Route exact path="/Congress" component={CongressRoute} />
                )}
                <Route
                  exact
                  path="/Congress/create"
                  component={CreateCongress}
                />
                <Route
                  exact
                  path="/Congress/edit/:CongressId"
                  component={EditCongress}
                />
              </>
            )}

            <Route
              exact
              path="/EditCongressCeritifaciton/edit/:CongressId/:BackId"
              component={EditCongressCeritifaciton}
            />

            {(usertype === 2 ||
              usertype === 3 ||
              usertype === 5 ||
              usertype === 1) && (
              <>
                <Route
                  exact
                  path="/SeaTourismCertification"
                  component={SeaTourismCertificationRoute}
                />
                <Route
                  exact
                  path="/SeaTourismCertification/create"
                  component={CreateSeaTourismCertification}
                />
                <Route
                  exact
                  path="/SeaTourismCertification/edit/:SeaTourismCertificateId"
                  component={EditSeaTourismCertification}
                />
                <Route
                  exact
                  path="/SeaTourismCertification/edit/:SeaTourismCertificateId/sequence/:SequenceId"
                  component={EditSeaTourismCertification}
                />
                <Route
                  exact
                  path="/SeaTourismCertification/editEndId/:SeaTourismCertificateId"
                  component={EditSeaTourismCertificationEndId}
                />
              </>
            )}

            {usertype === 1 && (
              <>
                <Route
                  exact
                  path="/SeaTourismFacilityType"
                  component={SeaTourismFacilityTypeRoute}
                />
                <Route
                  exact
                  path="/SeaTourismFacilityType/create"
                  component={CreateSeaTourismFacilityType}
                />
                <Route
                  exact
                  path="/SeaTourismFacilityType/edit/:SeaTourismFacilityTypeId"
                  component={EditSeaTourismFacilityType}
                />

                <Route
                  exact
                  path="/SeaTourismSubCategory"
                  component={SeaTourismSubCategoryRoute}
                />
                <Route
                  exact
                  path="/SeaTourismSubCategory/create"
                  component={CreateSeaTourismSubCategory}
                />
                <Route
                  exact
                  path="/SeaTourismSubCategory/edit/:SeaTourismSubCategoryId"
                  component={EditSeaTourismSubCategory}
                />
              </>
            )}

            {(usertype === 3 ||
              usertype === 5 ||
              usertype === 1 ||
              usertype === 2) && (
              <>
                {(usertype === 3 || usertype === 5 || usertype === 1) && (
                  <Route exact path="/SeaTourism" component={SeaTourismRoute} />
                )}
                <Route
                  exact
                  path="/SeaTourism/create"
                  component={CreateSeaTourism}
                />
                <Route
                  exact
                  path="/SeaTourism/edit/:SeaTourismId"
                  component={EditSeaTourism}
                />
              </>
            )}

            <Route
              exact
              path="/EditSeaTourismCeritifaciton/edit/:SeaTourismId/:BackId"
              component={EditSeaTourismCeritifaciton}
            />

            {(usertype === 2 ||
              usertype === 3 ||
              usertype === 5 ||
              usertype === 1) && (
              <>
                <Route
                  exact
                  path="/WinterTourismCertification"
                  component={WinterTourismCertificationRoute}
                />
                <Route
                  exact
                  path="/WinterTourismCertification/create"
                  component={CreateWinterTourismCertification}
                />
                <Route
                  exact
                  path="/WinterTourismCertification/edit/:WinterTourismCertificateId"
                  component={EditWinterTourismCertification}
                />
                <Route
                  exact
                  path="/WinterTourismCertification/edit/:WinterTourismCertificateId/sequence/:SequenceId"
                  component={EditWinterTourismCertification}
                />
                <Route
                  exact
                  path="/WinterTourismCertification/editEndId/:WinterTourismCertificateId"
                  component={EditWinterTourismCertificationEndId}
                />
              </>
            )}

            <Route
              exact
              path="/WinterTourismFacilityType"
              component={WinterTourismFacilityTypeRoute}
            />
            <Route
              exact
              path="/WinterTourismFacilityType/create"
              component={CreateWinterTourismFacilityType}
            />
            <Route
              exact
              path="/WinterTourismFacilityType/edit/:WinterTourismFacilityTypeId"
              component={EditWinterTourismFacilityType}
            />

            <Route
              exact
              path="/WinterTourismSubCategory"
              component={WinterTourismSubCategoryRoute}
            />
            <Route
              exact
              path="/WinterTourismSubCategory/create"
              component={CreateWinterTourismSubCategory}
            />
            <Route
              exact
              path="/WinterTourismSubCategory/edit/:WinterTourismSubCategoryId"
              component={EditWinterTourismSubCategory}
            />

            {(usertype === 3 ||
              usertype === 5 ||
              usertype === 1 ||
              usertype === 2) && (
              <>
                {(usertype === 3 || usertype === 5 || usertype === 1) && (
                  <Route
                    exact
                    path="/WinterTourism"
                    component={WinterTourismRoute}
                  />
                )}
                <Route
                  exact
                  path="/WinterTourism/create"
                  component={CreateWinterTourism}
                />
                <Route
                  exact
                  path="/WinterTourism/edit/:WinterTourismId"
                  component={EditWinterTourism}
                />
              </>
            )}

            <Route
              exact
              path="/EditWinterTourismCeritifaciton/edit/:WinterTourismId/:BackId"
              component={EditWinterTourismCeritifaciton}
            />

            {(usertype === 2 ||
              usertype === 3 ||
              usertype === 5 ||
              usertype === 1) && (
              <>
                <Route
                  exact
                  path="/ThemedFacilityCertification"
                  component={ThemedFacilityCertificationRoute}
                />
                <Route
                  exact
                  path="/ThemedFacilityCertification/create"
                  component={CreateThemedFacilityCertification}
                />
                <Route
                  exact
                  path="/ThemedFacilityCertification/editEndId/:ThemedFacilityCertificateId"
                  component={EditThemedFacilityCertificationEndId}
                />
                <Route
                  exact
                  path="/ThemedFacilityCertification/edit/:ThemedFacilityCertificateId"
                  component={EditThemedFacilityCertification}
                />
                <Route
                  exact
                  path="/ThemedFacilityCertification/edit/:ThemedFacilityCertificateId/sequence/:SequenceId"
                  component={EditThemedFacilityCertification}
                />
              </>
            )}

            {(usertype === 3 ||
              usertype === 5 ||
              usertype === 1 ||
              usertype === 2) && (
              <>
                {(usertype === 3 || usertype === 5 || usertype === 1) && (
                  <Route
                    exact
                    path="/ThemedFacility"
                    component={ThemedFacilityRoute}
                  />
                )}
                <Route
                  exact
                  path="/ThemedFacility/create"
                  component={CreateThemedFacility}
                />
                <Route
                  exact
                  path="/ThemedFacility/edit/:ThemedFacilityId"
                  component={EditThemedFacility}
                />
              </>
            )}

            <Route
              exact
              path="/EditThemedFacilityCeritifaciton/edit/:ThemedFacilityId/:BackId"
              component={EditThemedFacilityCeritifaciton}
            />

            {(usertype === 2 ||
              usertype === 3 ||
              usertype === 5 ||
              usertype === 1) && (
              <>
                <Route
                  exact
                  path="/AdditionalControl"
                  component={AdditionalControlList}
                />
                <Route
                  exact
                  path="/Additional/:Id/:Type"
                  component={AdditionalControlListFacility}
                />
                <Route
                  exact
                  path="/AdditionalControl/Create"
                  component={AdditionalControlCreate}
                />
                <Route
                  exact
                  path="/AdditionalControl/Create/:Id/:Type"
                  component={AdditionalControlCreate}
                />
                <Route
                  exact
                  path="/AdditionalControl/CreateType"
                  component={AdditionalControlCreateType}
                />
                <Route
                  exact
                  path="/AdditionalControl/Edit/:relatedId"
                  component={AdditionalControlEdit}
                />
              </>
            )}
            {usertype === 1 && (
              <>
                <Route exact path="/Fair" component={FairRoute} />
                <Route exact path="/Fair/create" component={CreateFair} />
                <Route exact path="/Fair/edit/:FairId" component={EditFair} />
              </>
            )}
          </AuthVerification>
          <Loader label="" />
          <Confirm />
          <ConfirmModal />
          <ConfirmFilterRemove />
          <MessageBox />
          <MessageBoxConfirme />
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
