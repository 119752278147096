import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  TextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
} from "office-ui-fabric-react";
import { setFormData, setFormErrors } from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";
import { getById } from "../../store/thunksFair";

import {
  FairGetByIdRequestModel,
  FairUpdateRequestModel,
} from "src/services/Fair/RequestModel";

import { castThunkAction } from "src/helpers/casting";
import { update } from "src/store/thunksFair";

interface OwnProps {
  history: History;
  FairId: number;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        getById,
        update,
      },
      dispatch
    ),
  };
}

type EditFairProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  messageType: MessageBarType;
}

class EditFair extends Component<EditFairProps, OwnState> {
  state = {
    messageType: MessageBarType.warning,
  };

  UNSAFE_componentWillMount() {
    if (this.props.FairId) {
      this.props.getById({
        id: this.props.FairId,
      } as FairGetByIdRequestModel);
    }
  }

  renderTextByMessageType() {
    switch (this.state.messageType) {
      case MessageBarType.success:
        return "Kaydetme işlemi başarıyla gerçekleşmiştir.";
      case MessageBarType.error:
        return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
      default:
        return "Lütfen aşağıdaki bilgileri doldurun, ve oluşturmayı tamamlayın!";
    }
  }

  renderMessageBar() {
    return (
      <MessageBar messageBarType={this.state.messageType} isMultiline={false}>
        {this.renderTextByMessageType()}
      </MessageBar>
    );
  }

  onSave() {
    const request: FairUpdateRequestModel = {
      data: {
        ...this.props.formData,
      },
    };
    castThunkAction(this.props.update(request)).then((response: any) => {
      this.setState({
        messageType: response ? MessageBarType.success : MessageBarType.error,
      });
    });
  }

  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  onSubmit() {
    const { formData, setFormErrors } = this.props;

    const validationSchema = _yup.object({
      title: _yup.string().required("Bu alan zorunludur!"),
    });
    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});
        this.onSave();
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  renderForm() {
    const { formData, formErrors, setFormData } = this.props;
    return (
      <div className="form-groups row">
        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="item row">
              <TextField
                placeholder="No"
                value={formData?.fairOrder || ""}
                label="No"
                className="custom-textfield border"
                onChange={(event: any) =>
                  setFormData("fairOrder", parseInt(event.target.value))
                }
              />
            </div>
            <div className="item row">
              <TextField
                placeholder="Fuar Adı"
                value={formData?.title || ""}
                label="Fuar Adı"
                className="custom-textfield border"
                errorMessage={formErrors.title}
                onChange={(event: any) =>
                  setFormData("title", event.target.value)
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="page push-all">
        <PageTitle
          titleRenderer={() => (
            <span className="edit-title">
              Düzenle: <strong>{this.props.formData?.title || ""}</strong>
            </span>
          )}
          showBack
          backUrl="/Fair"
          history={this.props.history}
          subTitle="Değiştirmek istediğiniz alanları aşağıda bulabilirsiniz."
          rightType="create"
          right={
            <FormButtons
              isAbsolute
              onSubmit={() => this.onSubmit()}
              customRenderButtons={() => (
                <React.Fragment>
                  <PrimaryButton
                    onClick={() => this.onSubmit()}
                    styles={{
                      rootDisabled: {
                        background: "#626262",
                        borderColor: "#777373 !important",
                      },
                    }}
                    iconProps={{ iconName: "CheckMark" }}
                    text={"Kaydet"}
                  />
                  {/* {this.renderStatusByType()} */}
                </React.Fragment>
              )}
            />
          }
        />
        {this.renderMessageBar()}

        {this.renderForm()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditFair);
