import { HttpClient } from "src/library/HttpClient";
import {
  WinterTourismSaveRequestModel,
  GetWinterTourismListModel,
  WinterTourismUpdateRequestModel,
  WinterTourismGetByIdRequestModel,
  WinterTourismChangeStatusRequestModel,
  GetWinterTourismCertificateListByWinterTourismModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class WinterTourism {
  async getAllWinterTourism(request: GetWinterTourismListModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourism + "GetWinterTourismList",
      { ...request, token }
    );
  }

  async excelDownload(request: any, name: string) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.WinterTourism + "GenerateExcel",
      name,
      { ...request, token }
    );
  }

  async save(request: WinterTourismSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourism + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: WinterTourismUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourism + "SaveOrUpdate",
      { ...request, token }
    );
  }
  async GetWinterTourismCertificateListByWinterTourism(
    request: GetWinterTourismCertificateListByWinterTourismModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourismCertification +
        "GetWinterTourismCertificateListByWinterTourism",
      { ...request, token }
    );
  }
  async getById(request: WinterTourismGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourism + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(
    request: WinterTourismChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.WinterTourism + "ChangeStatus",
      { ...request, token }
    );
  }
}
