import { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { History } from "history";
import { toggleConfirmDialog } from "src/store/system/actions";
import { setFormData } from "src/store/form/actions";
// @ts-ignore
import {
  add3Hour,
  toDate,
  toTimeStamp,
  toTimeStampWithDate,
} from "src/helpers/converts";
import { DatePicker, PrimaryButton, Dropdown } from "office-ui-fabric-react";
// @ts-ignore

import BasicTable from "../BasicTable";
import ReactPaginate from "react-paginate";
import {
  DayPickerStrings,
  firstDayOfWeek,
  listCount,
  onFormatDate,
} from "src/config/otherConfig";
import { castThunkAction } from "src/helpers/casting";
import { GetUserLogCount, excelDownload } from "src/store/thunkStatistic";
import cookie from "react-cookies";
import { GetUserLogCountListModel } from "src/services/Statistic/RequestModel";

import { getSystemUserDropdownList } from "src/store/thunksSystemUser";

import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        toggleConfirmDialog,
        setFormData,
        GetUserLogCount,
        getSystemUserDropdownList,
        excelDownload,
      },
      dispatch
    ),
  };
}

type StatisticProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  pagerTotal: number;
  download: boolean;
  excel: boolean;
}

class UserActionReport extends Component<StatisticProps, OwnState> {
  state = {
    filterData: {
      startDate: 0,
      endDate: 0,
      systemUserType: "",
      systemUserId: null,
    },
    pagerCurrent: 0,
    pagerTotal: 0,
    download: false,
    excel: false,
  };

  UNSAFE_componentWillMount() {
    var startdate = new Date();
    startdate.setHours(0, 0, 0, 0);

    var enddate = new Date();
    enddate.setHours(23, 59, 59, 999);

    var starttoday = toTimeStampWithDate(
      toDate(toTimeStamp(startdate?.toString()))
    );
    var endtoday = toTimeStampWithDate(
      toDate(toTimeStamp(enddate?.toString()))
    );

    this.setState({
      filterData: {
        startDate: starttoday,
        endDate: endtoday,
      },
    });
    this.getData(this.state.pagerCurrent, starttoday, endtoday);
  }

  UNSAFE_componentWillUpdate(nextProps: any, nextState: any) {
    if (nextState.pagerTotal !== nextProps.formData.pageCount)
      this.setState({
        ...this.state,
        pagerTotal: this.props.formData.pageCount,
      });
  }

  onUserTypeUserList() {
    const request: SystemUserGetAllModel = {
      systemUserType: Number(this.state.filterData.systemUserType),
      pageIndex: 0,
    };
    this.props.getSystemUserDropdownList(request);
  }

  getDate(date: any): any {
    if (date !== 0 && date !== undefined) {
      return toDate(date, "");
    }
  }

  getData(pagerSelected: number, startDate: number, endDate: number) {
    const request: GetUserLogCountListModel = {
      ...this.state.filterData,
      pageSize: listCount,
      pageIndex: pagerSelected,
      startDate: startDate,
      endDate: endDate,
    };

    castThunkAction(this.props.GetUserLogCount(request)).then(
      (response: any) => {
        this.setState({
          pagerTotal: this.props.formData?.GetUserLogCount?.pageCount,
        });
      }
    );
  }

  getDataPager(pagerSelected: number) {
    const request: GetUserLogCountListModel = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...this.state.filterData,
    };
    this.setState({
      excel: false,
      download: false,
    });
    castThunkAction(this.props.GetUserLogCount(request)).then();
  }

  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.state.pagerTotal}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }

  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getDataPager(selected);
  };

  renderList() {
    return (
      <BasicTable
        columns={[
          {
            columnName: "username",
            text: "Kullanıcı Adı",
            col: "col-md-4",
          },
          {
            columnName: "systemUserTypeString",
            text: "Kullanıcı Tipi",
            col: "col-md-4",
          },
          {
            columnName: "count",
            text: "İşlem Sayısı",
            col: "col-md-4",
          },
        ]}
        items={this.props.formData?.GetUserLogCount?.dataList || []}
      />
    );
  }

  rederexcel() {
    return (
      <>
        <PrimaryButton
          text="Rapor Oluştur ve İndir"
          className="custom-button"
          onClick={() => this.downloadexcel()}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
      </>
    );
  }

  downloadexcel() {
    this.setState({ download: true });
    const requestexcel: GetUserLogCountListModel = {
      ...this.state.filterData,
    };

    castThunkAction(this.props.excelDownload(requestexcel)).then(
      (response: any) => {
        this.setState({ excel: true, download: false });
      }
    );
  }

  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Kullanıcı İşlem Sayısı Raporu"
          subTitle="Filtreler ile kullanıcı işlem raporuna buradan erişebilirsiniz."
          backUrl="/Statistic"
          showBack
          history={this.props.history}
        />
        <div className="table-filter row">
          <div
            className="statistic-filter-item"
            style={{ marginLeft: "25px", marginRight: "5px" }}
          >
            <DatePicker
              strings={DayPickerStrings}
              firstDayOfWeek={firstDayOfWeek}
              onSelectDate={(date) =>
                this.setState({
                  filterData: {
                    ...this.state.filterData,
                    startDate: toTimeStampWithDate(
                      add3Hour(toDate(toTimeStamp(date?.toString())))
                    ),
                  },
                })
              }
              label="Başlangıç Tarihi"
              placeholder="gün.ay.yıl"
              formatDate={onFormatDate}
              value={this.getDate(this.state.filterData.startDate)}
              maxDate={new Date()}
            />
          </div>
          <div
            className="statistic-filter-item"
            style={{ marginLeft: "28px", marginRight: "5px" }}
          >
            <DatePicker
              strings={DayPickerStrings}
              firstDayOfWeek={firstDayOfWeek}
              onSelectDate={(date) =>
                this.setState({
                  filterData: {
                    ...this.state.filterData,
                    endDate: toTimeStampWithDate(
                      add3Hour(toDate(toTimeStamp(date?.toString())))
                    ),
                  },
                })
              }
              label="Bitiş Tarihi"
              placeholder="gün.ay.yıl"
              formatDate={onFormatDate}
              value={this.getDate(this.state.filterData.endDate)}
              maxDate={new Date()}
            />
          </div>

          <div
            className="statistic-filter-item col-md-2"
            style={{ marginLeft: "15px", marginRight: "5px" }}
          >
            <Dropdown
              onChanged={(event: any) =>
                this.setState(
                  {
                    filterData: {
                      ...this.state.filterData,
                      systemUserType: event.key === "" ? null : event.key,
                      systemUserId: null,
                    },
                  },
                  () => this.onUserTypeUserList()
                )
              }
              label="Kullanıcı Tipi"
              options={[
                { key: "", text: "Tümü" },
                { key: 1, text: "Super Admin" },
                { key: 2, text: "Firma" },
                { key: 3, text: "TGA" },
                { key: 4, text: "KTB" },
                { key: 5, text: "Çağrı Merkezi" },
              ].map((item: any) => ({ key: item.key, text: item.text }))}
              className="custom-dropdown"
              selectedKey={this.state.filterData.systemUserType || ""}
            />
          </div>

          <div
            className="statistic-filter-item col-md-3"
            style={{ marginLeft: "1px", marginRight: "5px" }}
          >
            <Dropdown
              defaultSelectedKey={this.state.filterData?.systemUserId || null}
              onChanged={(event: any) =>
                this.setState({
                  filterData: {
                    ...this.state.filterData,
                    systemUserId: event.key,
                  },
                })
              }
              label="Kullanıcı Adı"
              options={
                this.props.formData &&
                this.props.formData.userList &&
                this.props.formData.userList.length > 0
                  ? this.props.formData.userList.map((item: any) => ({
                      key: item.systemUserId,
                      text: item.title,
                    }))
                  : ([] as any).map((item: any) => ({
                      key: item.systemUserId,
                      text: item.title,
                    }))
              }
              className="custom-dropdown"
              selectedKey={this.state.filterData.systemUserId || ""}
            />
          </div>

          <div
            className="statistic-filter-item"
            style={{ marginRight: "15px", marginLeft: "15px" }}
          >
            <PrimaryButton
              text="Arama Yap"
              className="custom-button"
              onClick={() => (
                cookie.save("UserActionListState", JSON.stringify(this.state), {
                  path: "/",
                }),
                this.getDataPager(0)
              )}
              styles={{
                root: {
                  fontSize: "11px",
                  marginTop: "27px",
                  height: "30px",
                },
              }}
            />
          </div>
          {this.rederexcel()}
        </div>
        <div style={{ marginTop: "30px" }}>{this.renderList()}</div>
        {this.renderPager()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserActionReport);
