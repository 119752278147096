import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import Denunciation from "src/services/Denunciation/DenunciationService";
import {
  GetDenunciationListGroupingRequestModel,
  SendToIsdemRequest,
  UpdateRequest,
  UndoDenunciationStatusRequestModel,
  SendDenunciationToMinistry,
  SendDenunciationRequestModel,
  GetDenunciationListByFacilityRequestModel,
  ChangeDenunciationStatus,
  SaveOrUpdateForTGARequest,
  RestoreToUnsentRequestModel,
} from "src/services/Denunciation/RequestModel";

import { MessageType } from "./system/types";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getList(
  request: GetDenunciationListGroupingRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const DenunciationService = new Denunciation();
    const result = await DenunciationService.getDenunciationList(request);

    dispatch(setFormData("data", result && result.data ? result.data : {}));

    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        setFormData(
          "pageCount",
          result && result.data ? result.data.pageCount : 1
        )
      );

      return true;
    }
    return false;
  };
}

export function getListExcel(
  request: GetDenunciationListGroupingRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const DenunciationService = new Denunciation();
    const result = await DenunciationService.getDenunciationList(request);
    dispatch(setFormData("getListExcel", result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function excelDownload(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    let tarih = new Date();
    const day = tarih.getDate();
    const year = tarih.getFullYear();
    const month = tarih.getMonth() + 1;
    const hours = tarih.getHours();
    const min = tarih.getMinutes();
    const excelName =
      "ŞikayetYönetimi" +
      day +
      "." +
      month +
      "." +
      year +
      "_" +
      hours +
      "." +
      min;

    const DenunciationService = new Denunciation();
    await DenunciationService.excelDownload(request, excelName);

    dispatch(toggleLoader());

    return true;
  };
}

export function UndoDenunciationStatus(
  request: UndoDenunciationStatusRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const DenunciationService = new Denunciation();
    const result = await DenunciationService.UndoDenunciationStatus(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Şikayet Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Şikayet Yönetimi"
        )
      );
      return false;
    }
  };
}

export function sendDenunciation(
  request: SendDenunciationRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const DenunciationService = new Denunciation();
    const result = await DenunciationService.sendDenunciation(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Şikayet Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Şikayet Yönetimi"
        )
      );
      return false;
    }
  };
}

export function SendToIsdem(
  request: SendToIsdemRequest
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const DenunciationService = new Denunciation();
    const result = await DenunciationService.SendToIsdem(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Şikayet Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Şikayet Yönetimi"
        )
      );
      return false;
    }
  };
}

export function sendDenunciationToMinistry(
  request: SendDenunciationToMinistry
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const DenunciationService = new Denunciation();
    const result = await DenunciationService.SendDenunciationToMinistry(
      request
    );
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Şikayet Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Şikayet Yönetimi"
        )
      );
      return false;
    }
  };
}

export function setDenunciationActionStatus(
  request: any
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const DenunciationService = new Denunciation();
    const result = await DenunciationService.setActionStatus(
      request
    );
    //dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      if (request.denunciationActionType) {
        dispatch(
          showMessage(
            "İlgili şikayet işlem yapılamadı olarak kaydedilmiştir. ",
            "Tebrikler!",
            MessageType.SUCCESS,
            "Şikayet Yönetimi",
            true
          )
        );
      } else {
        dispatch(
          showMessage(
            "İlgili şikayet için işlem yapılmadı etiketi kaldırılmıştır.",
            "Tebrikler!",
            MessageType.SUCCESS,
            "Şikayet Yönetimi",
            true
          )
        );
      }

      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Şikayet Yönetimi"
        )
      );
      return false;
    }
  };
}
export function RestoreToUnsentStatus(
  request: RestoreToUnsentRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const DenunciationService = new Denunciation();
    const result = await DenunciationService.RestoreToUnsent(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Şikayet Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Şikayet Yönetimi"
        )
      );
      return false;
    }
  };
}

export function changeDenunciationStatus(
  request: ChangeDenunciationStatus
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const DenunciationService = new Denunciation();
    const result = await DenunciationService.changeDenunciationStatus(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Şikayet Yönetimi",
          true
        )
      );
      return true;
    } else if (result.errorCode === "1") {
      dispatch(
        showMessage(
          "Şikayet kaydı seçmediniz. Lütfen, işlem yapmak istediğiniz şikayet kaydını seçerek tekrar deneyiniz.",
          "Uyarı!",
          MessageType.ERROR,
          "Şikayet Yönetimi",
          false
        )
      );
      return false;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Şikayet Yönetimi"
        )
      );
      return false;
    }
  };
}

export function update(request: UpdateRequest): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const DenunciationService = new Denunciation();
    const result = await DenunciationService.updateStatus(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Şikayet Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Şikayet Yönetimi"
        )
      );
      return false;
    }
  };
}

export function SaveOrUpdateForTGA(
  request: SaveOrUpdateForTGARequest
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const DenunciationService = new Denunciation();
    const result = await DenunciationService.updateDenunciation(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Aktarım işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Şikayet Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Şikayet Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getIdList(
  request: GetDenunciationListByFacilityRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const DenunciationService = new Denunciation();
    const result = await DenunciationService.getDenunciationListByFacility(
      request
    );
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

