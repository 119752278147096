import * as React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { RootState } from "src/store/reducer";
import { History } from "history";
import { NotificationCount } from "src/store/thunkNotification";
import { NotificationGetAllRequestModel } from "src/services/Notification/RequestModel";
import { castThunkAction } from "src/helpers/casting";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      NotificationCount,
    },
    dispatch
  );
}

export interface NotificationState {
  showProfile: boolean;
  userMenuDisabled: boolean;
  notificationCount2: number;
}

type NotificationProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

class Notification extends React.Component<
  NotificationProps,
  NotificationState
> {
  constructor(props: NotificationProps) {
    super(props);
    this.state = {
      showProfile: false,
      userMenuDisabled: false,
      notificationCount2: 0,
    };
  }
  UNSAFE_componentWillMount() {
    const request: NotificationGetAllRequestModel = {};

    castThunkAction(this.props.NotificationCount(request)).then(
      (response: any) => {
        this.setState({ ...this.state, notificationCount2: response });
      }
    );
  }
  render() {
    return (
      <div
        className="item notifications"
        onClick={() => this.props.history.push("/notification/")}
      >
        <div className="notification-indicator">
          <div className="badge not-found">
            <i className="notification-count">
              {this.state.notificationCount2}
            </i>
          </div>
          <i className="icon-notification"></i>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
