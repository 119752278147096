import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import WinterTourismSubCategory from "../services/WinterTourismSubCategory/WinterTourismSubCategoryService";
import {
  WinterTourismSubCategoryGetAllRequestModel,
  WinterTourismSubCategoryChangeStatusRequestModel,
  WinterTourismSubCategoryGetByIdRequestModel,
  WinterTourismSubCategoryUpdateRequestModel,
  WinterTourismSubCategorySaveRequestModel,
} from "../services/WinterTourismSubCategory/RequestModel";
import { MessageType } from "./system/types";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllLSU(
  request: WinterTourismSubCategoryGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    const WinterTourismSubCategoryService = new WinterTourismSubCategory();
    const result = await WinterTourismSubCategoryService.getAll(request);
    dispatch(setFormData("GroupListSU", result));

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAll(
  request: WinterTourismSubCategoryGetAllRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismSubCategoryService = new WinterTourismSubCategory();
    const result = await WinterTourismSubCategoryService.getAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(
  request: WinterTourismSubCategorySaveRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismSubCategoryService = new WinterTourismSubCategory();
    const result = await WinterTourismSubCategoryService.save(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Alt Kategori Yönetimi"
        )
      );
      return result;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Alt Kategori Yönetimi"
        )
      );
      return false;
    }
  };
}
export function update(
  request: WinterTourismSubCategoryUpdateRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismSubCategoryService = new WinterTourismSubCategory();
    const result = await WinterTourismSubCategoryService.update(request);
    dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Alt Kategori Yönetimi"
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Alt Kategori Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getById(
  request: WinterTourismSubCategoryGetByIdRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismSubCategoryService = new WinterTourismSubCategory();
    const result = await WinterTourismSubCategoryService.getById(request);
    dispatch(initialFormData(result.data));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: WinterTourismSubCategoryChangeStatusRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const WinterTourismSubCategoryService = new WinterTourismSubCategory();
    const result = await WinterTourismSubCategoryService.changeStatus(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Alt Kategori Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Alt Kategori Yönetimi"
        )
      );
      return false;
    }
  };
}
