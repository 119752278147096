import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  TextField,
  // MessageBar,
  MessageBarType,
  PrimaryButton,
  Dropdown,
  DatePicker,
  Label,
  Checkbox,
  ComboBox,
} from "office-ui-fabric-react";
import {
  setFormData,
  setFormErrors,
  initialFormData,
} from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";
import { getById, update, changeStatus,leaveCertificationProcess } from "src/store/thunkRestaurant";
import { castThunkAction } from "src/helpers/casting";
import {
  RestaurantGetByIdRequestModel,
  RestaurantUpdateRequestModel,
  RestaurantChangeStatusRequestModel,
} from "src/services/Restaurant/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";

import { qrUrl, apiConfig } from "src/config/apiConfig";

import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import {
  getAllTy,
  showErrorDialog,
  showChangesFieldDialog,
} from "src/store/thunkCertificateType";
import { getAllCR } from "src/store/thunkCertificateStatusType";
import { getCompanyUserList } from "src/store/thunksSystemUser";
import { getAllCompany } from "src/store/thunksCompany";
import _ from "lodash";
import {
  RemoveWebPublish,
  RemoveQrPublish,
} from "src/store/thunkHotelCertification";
import {
  updateNotification,
  updateNotificationUnRead,
} from "src/store/thunkNotification";

import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";
import { CertificateTypeGetAllRequestModel } from "src/services/CertificateType/RequestModel";
import { CertificateStatusTypeGetAllRequestModel } from "src/services/CertificateStatusType/RequestModel";
import cookie from "react-cookies";
import {
  DayPickerStrings,
  firstDayOfWeek,
  onFormatDate,
} from "src/config/otherConfig";
import { toTimeStamp, toDate } from "src/helpers/converts";
import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";
import { FileUpload } from "../FileUpload";
import {
  certificateStatusEnum,
  RECORD_CERTIFICATION_STATUS,
} from "src/helpers/enums";
import { NotificationUpdateRequestModel } from "src/services/Notification/RequestModel";
import { toggleConfirmDialog } from "src/store/system/actions";
import {
  certificateAppealStatusDate,
  formatSize,
  getChangesFieldGlobal,
  onSaveText,
  requestMapData,
  sertifikaTarihiBayram,
} from "src/helpers/functions";
import PhoneInput from "react-phone-input-2";
import CertificateStatus from "../CertificateStatus";
import ValidityDate from "../ValidityDate";
import QrCode from "../QrCode";


let token = "";
// let hasCertificateStatusTypeId = 0;
// let hasIsPublishWeb = false;

interface OwnProps {
  history: History;
  RestaurantId: number;
}
function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        initialFormData,
        getById,
        leaveCertificationProcess,
        getAllM,
        getAllC,
        getAllTy,
        getAllCR,
        update,
        getAllCompany,
        getCompanyUserList,
        showErrorDialog,
        RemoveWebPublish,
        RemoveQrPublish,
        showChangesFieldDialog,
        updateNotification,
        updateNotificationUnRead,
        changeStatus,
        toggleConfirmDialog,
      },
      dispatch
    ),
  };
}

type CreateRestaurantProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  messageType: MessageBarType;
  infoMessageGetDocumentNo: string;
  isFileUploading: boolean;
  fileUploadContainer: any;
}
let oldData: any = {};
let usertype: any = 0;

let hasCertificateStatusTypeId = 0;
let hasCorrectionTypeId = 0;
let isDisabled = false;

class EditRestaurant extends Component<CreateRestaurantProps, OwnState> {
  state = {
    messageType: MessageBarType.warning,
    infoMessageGetDocumentNo: "",
    isFileUploading: false,
    fileUploadContainer: "",
    onlyCountry: ["tr"],
  };

  UNSAFE_componentWillMount() {
    token = cookie.load("userId");
    this.props.initialFormData({
      status: StatusEnums.Published,
    });
    usertype = cookie.load("systemUserInfo");

    if (this.props.RestaurantId) {
      castThunkAction(
        this.props.getById({
          id: +this.props.RestaurantId,
        } as RestaurantGetByIdRequestModel)
      ).then((response: any) => {
        let item = certificateStatusEnum.find(
          (x: any) =>
            JSON.stringify(x.key) ===
            JSON.stringify(this.props.formData.certificateStatus)
        );

        this.props.setFormData("checkedStatusList", item ? item.list : []);

        const request: NotificationUpdateRequestModel = {
          CategoryType: 2,
          RelatedId: this.props.formData.restaurantCertificateId,
          isRead: true,
        };
        castThunkAction(this.props.updateNotification(request)).then(
          (response: any) => { }
        );
        hasCertificateStatusTypeId =
          this.props.formData.certificateStatusTypeId;
        hasCorrectionTypeId = this.props.formData.correctionType;
        isDisabled = (
          hasCorrectionTypeId === 1
            ? false
            : usertype === "2" && hasCertificateStatusTypeId === 2
        )
          ? true
          : false;

        this.renderFileUpload();
        this.props.setFormData(
          "certificateNo",
          this.props.formData.certificateNo.slice(
            this.props.formData.certificateNo.indexOf("-") + 1
          )
        );
        oldData = Object.assign(this.props.formData, {});

        // this.props.formData.webSite === ""
        //   ? this.props.setFormData("webSite", null)
        //   : this.props.setFormData("webSite", this.props.formData.webSite);

        const request2: CityGetAllRequestModel = {
          selectItemList: ["CityId", "CityName"],
          orderField: "CityName",
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllM(request2);

        const request4: CertificateTypeGetAllRequestModel = {
          selectItemList: ["CertificateTypeId", "Title"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllTy(request4);

        const request5: CertificateStatusTypeGetAllRequestModel = {
          selectItemList: ["CertificateStatusTypeId", "Title"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllCR(request5);

        const request6: SystemUserGetAllModel = {
          systemUserType: 2,
        };
        castThunkAction(this.props.getCompanyUserList(request6)).then(
          (x: any) => {
            if (usertype !== "2" && usertype !== "5") {
              const prefix =
                this.props.formData.CompanyUserList.dataList.filter(
                  (x: any) =>
                    x.systemUserId === this.props.formData.companyUserId
                )[0]?.prefix;

              this.props.setFormData("prefix", prefix);
            } else {
              this.props.setFormData("prefix", cookie.load("prefix"));
            }
          }
        );

        this.props.formData?.status === 30 &&
          this.props.setFormData("companyUserId", 0);

          
        this.CityChange(
          this.props.formData.cityId,
          this.props.formData.districtId
        );
        this.props.formData.appealDate === 0 &&
          this.props.setFormData("appealDate", null);
        this.props.formData.confirmationDate === 0 &&
          this.props.setFormData("confirmationDate", null);
      });
    }
  }
  componentDidUpdate(nextprops: any) {
    if (this.props.formData.certificateTypeId !== 0) {
      this.setLabelDescription(
        "companyName",
        "<br /> <span  id='companyName-span'  class='descSpan'>Tesis sahibi olan firmanın ticari ünvanı</span>"
      );
      this.setDdlDescription(
        "certificateStatusTypeId-label",
        "<br /> <span  id='certificateStatusTypeId-label-span'  class='descSpan'>Denetim için sözleşme yapıldığında <b>Belgelendirme Sürecinde</b>, belge verildikten sonra ise <b>Belge Verildi</b> seçeneğinin işaretlenmesi gerekmektedir.</span>"
      );
      if (this.props.formData.certificateTypeId === 4) {
        this.setLabelDescription(
          "documentNoDesc",
          "<br /> <span  id='documentNoDesc-span'  class='descSpan'>TC Kültür ve Turizm Bakanlığı tarafından tesise verilen çalışma belge numarası</span>"
        );
      }
    }
    if (
      this.props.formData.certificateStatusTypeId === 2 &&
      this.props.formData.prefix
    ) {
      this.setPrefix();
    }
  }
  setPrefix(): any {
    const el = document.createElement("span");
    el.className = "CertificateNo-span prefixSpan";
    el.id = "CertificateNo-span";
    el.innerHTML =
      usertype === "2"
        ? cookie.load("prefix") + "-"
        : this.props.formData.prefix + "-";
    const div = document.getElementById("CertificateNo");

    if (!document.getElementById("CertificateNo-span")) {
      this.insertBefore(div, el);
    } else {
      document.getElementById("CertificateNo-span")?.remove();
      this.insertBefore(div, el);
    }
    return false;
  }

  insertAfter(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  insertBefore(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode);
  }

  componentDidMount() {
    this.setDdlDescription(
      "certificateTypeIdDesc-label",
      "<br/> <span id='certificateTypeIdDesc-label-span' class='descSpan'>Tesisin kayıt edileceği belge tipini seçiniz. Tesise ait <b>T.C Kültür ve Turizm Bakanlığı</b> Belgesi bulunuyorsa bu belge tipini seçip ilgili belgeyi yüklemeniz ve belgeye uygun bilgileri doldurmanız gerekmektedir. Eğer tesise ait bu belge bulunmuyorsa lütfen <b>Diğer</b> seçerek ilerleyeniz.</b></span>"
    );
  }

  setDdlDescription(id: string, message: string) {
    const el = document.createElement("span");
    el.innerHTML = message;
    const div = document.getElementById(id);
    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div, el);
    }
  }

  setLabelDescription(id: string, message: string) {
    const el2 = document.createElement("span");
    el2.innerHTML = message;
    const div2 = document.querySelectorAll('[for="' + id + '"]');

    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div2[0], el2);
    }
  }
  onSave(isDraft?: boolean) {
    let item = certificateStatusEnum.find(
      (x: any) =>
        JSON.stringify(x.list) ===
        JSON.stringify(
          this.props.formData.checkedStatusList.sort((a: any, b: any) =>
            a > b ? 1 : -1
          )
        )
    );


    this.props.setFormData("certificateStatus", item ? item.key : null)

    const request: RestaurantUpdateRequestModel = {
      data: requestMapData({
        ...this.props.formData,
        status: isDraft ? StatusEnums.Draft : this.props.formData.status,
        certificateStatus: item ? item.key : null,
        latitude: this.props.formData.latitude,
        longitude: this.props.formData.longitude,
        confirmationDate:
          this.props.formData.certificateStatusTypeId === 1
            ? 0
            : this.props.formData.confirmationDate,
      }),
    };
    if (
      this.props.formData.attachmentList &&
      this.props.formData.attachmentList.length > 0
    ) {
      request.data["attachmentIdList"] = (
        this.props.formData.attachmentList || []
      ).map((item: any) => item.attachmentId);
    }
    if (
      this.props.formData.attachmentCertificateList &&
      this.props.formData.attachmentCertificateList.length > 0
    ) {
      request.data["attachmentCertificateIdList"] = (
        this.props.formData.attachmentCertificateList || []
      ).map((item: any) => item.attachmentId);
    }

    if (this.props.formData.confirmationDate === null) {
      this.props.formData.confirmationDate = 0;
    }
    hasCertificateStatusTypeId = this.props.formData.certificateStatusTypeId;
    hasCorrectionTypeId = this.props.formData.correctionType;
    isDisabled = (
      hasCorrectionTypeId === 1
        ? false
        : usertype === "2" && hasCertificateStatusTypeId === 2
    )
      ? true
      : false;

    getChangesFieldGlobal(usertype, oldData, this.props.formData).then((x: any) => {
      this.props.setFormData("changesField", JSON.stringify(x));
      request.data["changesField"] = JSON.stringify(x);
      castThunkAction(this.props.update(request)).then((response: any) => {
        this.props.setFormData(
          "initialAttachmentList",
          this.props.formData.attachmentList
        );
        this.props.setFormData(
          "initialAttachmentCertificateList",
          this.props.formData.attachmentCertificateList
        );

        this.setState(
          {
            messageType: response
              ? MessageBarType.success
              : MessageBarType.error,
          },
          () => {
            this.props.setFormData(
              "status",
              isDraft ? StatusEnums.Draft : this.props.formData.status
            );
          }
        );
      });
    });
  }



  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  removeElement(id: any) {
    var elem = document.getElementById(id);
    return elem?.parentNode?.removeChild(elem);
  }
  filterFileds(val: any) {
    if (
      val !== "" &&
      val !== 0 &&
      val !== "0" &&
      val !== null &&
      val !== "null" &&
      val !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  }
  onSubmit(isDraft?: boolean) {
    let desc = "";
    if (!this.filterFileds(this.props.formData.certificateTypeId)) {
      desc += "Tesis Belge Tipi, <br/>";
    }
    if (!this.filterFileds(this.props.formData.companyName)) {
      desc += "Belge Sahibi / Ticari Ünvan,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.brandName)) {
      desc += "Tesis Adı,  <br/>";
    }
    if (this.props.formData.certificateTypeId === 4) {
      if ((!this.filterFileds(this.props.formData.documentNo)) || this.props.formData.documentNo==="0") {
        desc += "Belge No,  <br/>";
      }
    }

    //https://b24-z1nwey.bitrix24.com.tr/workgroups/group/8/tasks/task/view/2042/ madde revize.
    if (this.props.formData.certificateTypeId === 5) {
      this.props.setFormData("documentNo", "")
    }

    if (!this.props.formData.vkNo || this.props.formData.vkNo.length < 10) {
      desc += "Vergi numarası 10 hane ve üzeri olmalıdır. <br/>";
    }
    if (!this.filterFileds(this.props.formData.cityId)) {
      desc += "İl,  <br/>";
    }
    if (!this.filterFileds(this.props.formData.districtId)) {
      desc += "İlçe,  <br/>";
    }
    // if(!this.filterFileds(this.props.formData.latitude)){
    //   desc += "X Koordinatı, <br/>";
    // }
    // if(!this.filterFileds(this.props.formData.longitude)){
    //   desc += "Y Koordinatı, <br/>";
    // }
    if (!this.filterFileds(this.props.formData.address)) {
      desc += "Tesis Adresi, <br/>";
    }
    if (!this.filterFileds(this.props.formData.appealDate)) {
      desc += "Sertifika Başvuru Tarihi,  <br/>";
    }
    if (usertype && usertype !== "2") {
      if (!this.filterFileds(this.props.formData.companyUserId)) {
        desc += "Denetleyici Firma <br/> ";
      }
    }
    if (this.props.formData.certificateStatusTypeId === 2) {
      if (!this.filterFileds(this.props.formData.confirmationDate)) {
        desc += "Sertifika Düzenleme Tarihi,  <br/>";
      }
      if (!this.filterFileds(this.props.formData.certificateNo)) {
        desc += "Sertifika No,  <br/>";
      }
      if (!this.filterFileds(this.props.formData.extendedClosedDate)) {
        desc += "Sertifika Geçerlilik Tarihi boş bırakılamaz.  <br/>";
      }
    }



    if (!this.filterFileds(this.props.formData.email)) {
      desc += "E-Posta Adresi,  <br/>";
    } else {
      const mail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!mail.test(this.props.formData.email)) {
        desc += "Geçersiz E-posta adresi,  <br/>";
      }
    }
    const phoneControl = /........../;
    if (!phoneControl.test(this.props.formData.phoneNumber)) {
      desc += "Telefon Numarası,  <br/>";
    }

    if (
      !this.props.formData.attachmentList ||
      this.props.formData.attachmentList.length === 0
    ) {
      desc += "İşletme belgesi yükleyiniz,  <br/>";
    }

    const request = {
      title: "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
      desc: desc,
    };
    if (desc !== "") {
      castThunkAction(this.props.showErrorDialog(request)).then(
        (response: any) => { }
      );
    }

    if (
      this.props.formData.appealDate &&
      this.props.formData.appealDate !== "" &&
      this.props.formData.appealDate !== null &&
      this.props.formData.appealDate !== 0 &&
      this.props.formData.appealDate !== "null" &&
      this.props.formData.appealDate !== "0"
    ) {
      this.removeElement("appealDate-span");
    } else {
      const el = document.createElement("span");
      el.className = "errorspan";
      el.innerHTML =
        "<br/> <span id='appealDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
      const div = document.getElementById("appealDate");
      if (!document.getElementById("appealDate-span")) {
        this.insertAfter(div, el);
      }
    }

    if (this.props.formData.certificateStatusTypeId === 2) {
      if (
        this.props.formData.confirmationDate &&
        this.props.formData.confirmationDate !== "" &&
        this.props.formData.confirmationDate !== null &&
        this.props.formData.confirmationDate !== 0 &&
        this.props.formData.confirmationDate !== "null" &&
        this.props.formData.confirmationDate !== "0"
      ) {
        this.removeElement("confirmationDate-span");
      } else {
        const el = document.createElement("span");
        el.className = "errorspan";
        el.innerHTML =
          "<br/> <span id='confirmationDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
        const div = document.getElementById("confirmationDate");
        if (!document.getElementById("confirmationDate-span")) {
          this.insertAfter(div, el);
        }
      }
    } else {
      this.removeElement("confirmationDate-span");
    }

    const { formData, setFormErrors } = this.props;
    const validationSchema = _yup.object({
      companyName: _yup.string().required("Bu alan zorunludur!"),
      brandName: _yup.string().required("Bu alan zorunludur!"),
      certificateStatusTypeId: _yup.string().required("Bu alan zorunludur!"),
      cityId: _yup.string().required("Bu alan zorunludur!"),
      districtId: _yup.string().required("Bu alan zorunludur!"),
      companyUserId: _yup.string().required("Bu alan zorunludur!"),
      vkNo: _yup.string().required("Bu alan zorunludur!"),
      email: _yup.string().required("Bu alan zorunludur!"),
      address: _yup.string().required("Bu alan zorunludur!"),
      phoneNumber: _yup.string().required("Bu alan zorunludur!"),
      documentNo: this.props.formData.certificateTypeId === 4
        ? _yup.string().nullable().required("Bu alan zorunludur!")
        : _yup.string().nullable(),

      certificateNo:
        this.props.formData.certificateStatusTypeId === 2
          ? _yup.string().required("Bu alan zorunludur!")
          : _yup.string().nullable(),
      appealDate: _yup.string().required("Bu alan zorunludur!"),
      confirmationDate:
        this.props.formData.certificateStatusTypeId === 2
          ? _yup.string().required("Bu alan zorunludur!")
          : _yup.string().nullable(),
      webSite: _yup
        .string()
        .matches(
          // eslint-disable-next-line
          /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "Geçersiz Web Sitesi"
        )
        .notRequired()
        .nullable(),
    });

    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});
        if (
          this.props.formData.appealDate &&
          this.props.formData.appealDate !== "" &&
          this.props.formData.appealDate !== null &&
          this.props.formData.appealDate !== 0 &&
          this.props.formData.appealDate !== "null" &&
          this.props.formData.appealDate !== "0"
        ) {
          this.removeElement("appealDate-span");

          if (this.props.formData.certificateStatusTypeId === 2) {
            if (
              this.props.formData.confirmationDate &&
              this.props.formData.confirmationDate !== "" &&
              this.props.formData.confirmationDate !== null &&
              this.props.formData.confirmationDate !== 0 &&
              this.props.formData.confirmationDate !== "null" &&
              this.props.formData.confirmationDate !== "0"
            ) {
              this.removeElement("appealDate-span");
              this.removeElement("confirmationDate-span");

              if (
                this.props.formData.attachmentList &&
                this.props.formData.attachmentList.length > 0
              ) {
                desc === "" && this.onSave(isDraft);
              } else {
                castThunkAction(
                  this.props.showErrorDialog({
                    title:
                      "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
                    desc: "İşletme belgesi yükleyiniz",
                  })
                ).then((response: any) => { });
              }
            } else {
              const el = document.createElement("span");
              el.className = "errorspan";
              el.innerHTML =
                "<br/> <span id='confirmationDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
              const div = document.getElementById("confirmationDate");
              if (!document.getElementById("confirmationDate-span")) {
                this.insertAfter(div, el);
              }
            }
          } else {
            this.removeElement("appealDate-span");
            this.removeElement("confirmationDate-span");
            if (
              this.props.formData.attachmentList &&
              this.props.formData.attachmentList.length > 0
            ) {
              desc === "" && this.onSave(isDraft);
            } else {
              castThunkAction(
                this.props.showErrorDialog({
                  title:
                    "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
                  desc: "İşletme belgesi yükleyiniz",
                })
              ).then((response: any) => { });
            }
          }
        } else {
          const el = document.createElement("span");
          el.className = "errorspan";
          el.innerHTML =
            "<br/> <span id='appealDate-span' class='descSpan errorspan'>Bu alan zorunludur</span>";
          const div = document.getElementById("appealDate");
          if (!document.getElementById("appealDate-span")) {
            this.insertAfter(div, el);
          }
        }
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  CityChange(item: any, districtId?: any) {
    const request2: CountyGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "cityId",
          value: item,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
      selectItemList: ["DistrictId", "DistrictName"],
      orderField: "DistrictName",
    };
    castThunkAction(this.props.getAllC(request2)).then((response: any) => {
      this.props.setFormData("districtId", districtId);
    });
  }

  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Seçiniz" });
    }

    return newArray;
  }

  renderFileUpload() {
    let fileuploadStatus = false;
    hasCorrectionTypeId === 1
      ? (fileuploadStatus = true)
      : usertype === "2" && hasCertificateStatusTypeId === 2
        ? (fileuploadStatus = false)
        : (fileuploadStatus = true);

    if (fileuploadStatus) {
      this.setState({
        ...this.state,
        fileUploadContainer: (
          <FileUpload
            onProcessStartFile={() => {
              this.setState({
                isFileUploading: true,
              });
            }}
            onProcessEndFile={(queue: boolean) => {
              if (!queue) {
                this.setState({
                  isFileUploading: false,
                });
              }
            }}
            onTransferedFile={(file: any, promise?: boolean) => {
              this.props.setFormData("attachmentList", [
                ...(this.props.formData?.attachmentList || []),
                file.attachmentIdList[0],
              ]);
            }}
            requestKey="files"
            token={token}
            endpointUrl={apiConfig.prefixes.attachment + "UploadFile"}
          />
        ),
      });
    }
  }

  renderForm() {
    const { formData, formErrors, setFormData } = this.props;
    return (
      <div className="form-groups row">
        <div className="form-group col-md-4" style={{ paddingBottom: 0 }}>
          {/* <div className="title">Genel Bilgiler</div> */}
          <div className="form-wrapper">
            <div className="item row">
              <Dropdown
                id="certificateTypeIdDesc"
                disabled={isDisabled}
                onChanged={(event: any) =>
                  setFormData("certificateTypeId", event.key)
                }
                errorMessage={formErrors.certificateTypeId}
                label="Tesis Belge Tipi"
                options={(this.props.formData.GroupListTY?.dataList
                  ? this.returnOptions(
                    this.props.formData.GroupListTY?.dataList,
                    "CertificateTypeId",
                    "Title"
                  )
                  : ([] as any)
                ).map((item: any) => ({
                  key: item.CertificateTypeId,
                  text: item.Title,
                }))}
                className="custom-dropdown"
                selectedKey={this.props.formData.certificateTypeId}
              />
            </div>
            {this.props.formData.certificateTypeId === 4 ? (
              <div className="item row">
                <TextField
                  id="documentNoDesc"
                  disabled={isDisabled}
                  placeholder="Belge No"
                  value={formData.documentNo || ""}
                  label="Belge No "
                  className="custom-textfield border"
                  errorMessage={formErrors.documentNo}
                  onChange={(event: any) =>
                    setFormData("documentNo", event.target.value)
                  }
                />
              </div>
            ) : (
              ""
            )}
            <div className="item row">
              <TextField
                placeholder="Tesis Vergi Numarası"
                value={formData.vkNo || ""}
                label="Tesis Vergi Numarası"
                type="number"
                className="custom-textfield border"
                errorMessage={formErrors.vkNo}
                onChange={(event: any) =>
                  setFormData("vkNo", event.target.value)
                }
              />
            </div>
          </div>
        </div>

        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="title">
              T.C Kültür ve Turizm Bakanlığı İşletme Belgesi / İşyeri Açma
              Ruhsatı ve Tesise Ait Diğer Resmi Evraklar
            </div>
            {this.state.fileUploadContainer}

            {this.props.formData.attachmentList &&
              this.props.formData.attachmentList.length > 0 && (
                <React.Fragment>
                  <div className="title">Yüklenen Belgeler</div>
                  {JSON.stringify(this.props.formData.initialAttachmentList) !==
                    JSON.stringify(this.props.formData.attachmentList) && (
                      <div className="file-save-warning">
                        Düzenlemeleri ve yüklenen belgeleri kaydetmek için, sağ
                        üst kısımdan "Kaydet veya Yayımla"ya basın.
                      </div>
                    )}
                  <div className="uploaded-files">
                    {(this.props.formData?.attachmentList || []).map(
                      (
                        item: {
                          attachmentId: number;
                          attachmentPath: string;
                          fileName: string;
                          fileSize: number;
                          createDateString: string;
                        },
                        index: number
                      ) => (
                        <div key={index} className="uploaded-file">
                          <div className="name row">
                            <span className="text col no-padding">
                              {item.fileName}-{item.createDateString}
                              <strong className="size m-left-auto">
                                {formatSize(item.fileSize)}
                              </strong>
                            </span>
                            <div className="buttons m-left-auto row">
                              <button
                                onClick={() =>
                                  window.open(
                                    qrUrl + item.attachmentPath,
                                    "_blank"
                                  )
                                }
                                className="view"
                              >
                                <i className="icon-explore"></i> Görüntüle
                              </button>
                              {usertype !== "5" && (
                                <button
                                  onClick={() => {
                                    let files = _.cloneDeep(
                                      this.props.formData?.attachmentList
                                    );
                                    let findIndex = (files || []).findIndex(
                                      (x: any) =>
                                        x.attachmentId === item.attachmentId
                                    );
                                    if (findIndex !== -1) {
                                      files.splice(findIndex, 1);
                                    }
                                    this.props.setFormData(
                                      "attachmentList",
                                      files
                                    );
                                  }}
                                  className="remove"
                                >
                                  <i className="icon-close"></i>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </React.Fragment>
              )}
          </div>
        </div>
        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="title">Sertifika Belgesi</div>
            <FileUpload
              onProcessStartFile={() => {
                this.setState({
                  isFileUploading: true,
                });
              }}
              onProcessEndFile={(queue: boolean) => {
                if (!queue) {
                  this.setState({
                    isFileUploading: false,
                  });
                }
              }}
              onTransferedFile={(file: any, promise?: boolean) => {
                this.props.setFormData("attachmentCertificateList", [
                  ...(this.props.formData?.attachmentCertificateList || []),
                  file.attachmentIdList[0],
                ]);
              }}
              requestKey="files"
              token={token}
              endpointUrl={
                apiConfig.prefixes.attachment + "UploadCertificateFile"
              }
            />
            {this.props.formData.attachmentCertificateList &&
              this.props.formData.attachmentCertificateList.length > 0 && (
                <React.Fragment>
                  <div className="title">Yüklenen Belgeler</div>
                  {JSON.stringify(
                    this.props.formData.initialAttachmentCertificateList
                  ) !==
                    JSON.stringify(
                      this.props.formData.attachmentCertificateList
                    ) && (
                      <div className="file-save-warning">
                        Düzenlemeleri ve yüklenen belgeleri kaydetmek için, sağ
                        üst kısımdan "Kaydet veya Yayımla"ya basın.
                      </div>
                    )}
                  <div className="uploaded-files">
                    {(this.props.formData?.attachmentCertificateList || []).map(
                      (
                        item: {
                          attachmentId: number;
                          attachmentPath: string;
                          fileName: string;
                          fileSize: number;
                        },
                        index: number
                      ) => (
                        <div key={index} className="uploaded-file">
                          <div className="name row">
                            <span className="text col no-padding">
                              {item.fileName}
                              <strong className="size m-left-auto">
                                {formatSize(item.fileSize)}
                              </strong>
                            </span>
                            <div className="buttons m-left-auto row">
                              <button
                                onClick={() =>
                                  window.open(
                                    qrUrl + item.attachmentPath,
                                    "_blank"
                                  )
                                }
                                className="view"
                              >
                                <i className="icon-explore"></i> Görüntüle
                              </button>
                              <button
                                onClick={() => {
                                  let files = _.cloneDeep(
                                    this.props.formData
                                      ?.attachmentCertificateList
                                  );
                                  let findIndex = (files || []).findIndex(
                                    (x: any) =>
                                      x.attachmentId === item.attachmentId
                                  );
                                  if (findIndex !== -1) {
                                    files.splice(findIndex, 1);
                                  }
                                  this.props.setFormData(
                                    "attachmentCertificateList",
                                    files
                                  );
                                }}
                                className="remove"
                              >
                                <i className="icon-close"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </React.Fragment>
              )}
          </div>
        </div>

        <aside
          className="col-md-12"
          style={{
            padding: 0,
            display:
              this.props.formData.certificateTypeId !== 0 ? "flex" : "none",
          }}
        >
          <div className="form-group col-md-4">
            <div className="form-wrapper">
              <div className="item row">
                <TextField
                  id="companyName"
                  disabled={isDisabled}
                  // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                  placeholder="Belge Sahibi / Ticari Ünvan"
                  value={formData.companyName || ""}
                  label="Belge Sahibi / Ticari Ünvan"
                  className="custom-textfield border"
                  errorMessage={formErrors.companyName}
                  onChange={(event: any) =>
                    setFormData("companyName", event.target.value)
                  }
                />
              </div>

              <div className="item row">
                <TextField
                  // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                  placeholder="Tesis Adı"
                  disabled={isDisabled}
                  value={formData.brandName || ""}
                  label="Tesis Adı"
                  className="custom-textfield border"
                  errorMessage={formErrors.brandName}
                  onChange={(event: any) =>
                    setFormData("brandName", event.target.value)
                  }
                />
              </div>

              <div className="item row">
                <Dropdown
                  disabled={isDisabled}
                  // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                  onChanged={(event: any) => (
                    this.CityChange(event.key), setFormData("cityId", event.key)
                  )}
                  label="İl"
                  options={(
                    this.props.formData.GroupList?.dataList || ([] as any)
                  ).map((item: any) => ({
                    key: item.CityId,
                    text: item.CityName,
                  }))}
                  className="custom-dropdown"
                  selectedKey={this.props.formData.cityId}
                  errorMessage={formErrors.cityId}
                />
              </div>

              <div className="item row">
                <Dropdown
                  disabled={isDisabled}
                  // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                  onChanged={(event: any) =>
                    setFormData("districtId", event.key)
                  }
                  label="İlçe"
                  options={(formData.GroupListC?.dataList || ([] as any)).map(
                    (item: any) => ({
                      key: item.DistrictId,
                      text: item.DistrictName,
                    })
                  )}
                  className="custom-dropdown"
                  selectedKey={this.props.formData.districtId}
                  errorMessage={formErrors.districtId}
                />
              </div>
              <div className="item row">
              <TextField
                placeholder="Tesis Adresi"
                value={formData.address || ""}
                label="Tesis Adresi"
                className="custom-textfield border"
                errorMessage={formErrors.address}
                onChange={(event: any) =>
                  setFormData("address", event.target.value)
                }
              />
              </div>  

              <div className="item row">
							<TextField
								placeholder="X Koordinatı"
								value={formData.latitude || ""}
								label="X Koordinatı"
                type="number"
								className="custom-textfield border"
								errorMessage={formErrors.latitude}
								onChange={(event: any) => setFormData("latitude", event.target.value)}
							/>
						</div>

						<div className="item row">
							<TextField
								placeholder="Y Koordinatı"
								value={formData.longitude || ""}
								label="Y Koordinatı"
								className="custom-textfield border"
                type="number"
								errorMessage={formErrors.longitude}
								onChange={(event: any) => setFormData("longitude", event.target.value)}
							/>
						</div>
              <div className="item row">
                <TextField
                  placeholder="E-posta Adresi"
                  value={formData.email || ""}
                  label="E-posta Adresi"
                  className="custom-textfield border"
                  errorMessage={formErrors.email}
                  onChange={(event: any) =>
                    setFormData("email", event.target.value)
                  }
                />
              </div>

              <div className="item row">
                <Label>Telefon Numarası</Label>
                <PhoneInput
                  onlyCountries={this.state.onlyCountry}
                  country={"tr"}
                  placeholder="Telefon Numarası"
                  value={formData.phoneNumber || ""}
                  onChange={(phone) => setFormData("phoneNumber", phone)}
                  disableDropdown={true}
                  disableCountryCode={true}
                  masks={{ tr: "(...) ...-..-.." }}
                  inputClass={"customizephone"}
                  defaultErrorMessage={formErrors.phoneNumber}
                />
                {formErrors.phoneNumber && (
                  <p className="ms-TextField-errorMessage errorMessage-63">
                    <span>{formErrors.phoneNumber}</span>
                  </p>
                )}
              </div>

              {usertype && usertype !== "2" ? (
                <>
                  <div className="item row">
                    <Dropdown
                      onChanged={(event: any) =>
                        setFormData("isPublishWeb", event.key)
                      }
                      label="Web Sitesinde Yayımlansın mı?"
                      options={[
                        { key: false, text: "Hayır" },
                        { key: true, text: "Evet" },
                      ].map((item: any) => ({
                        key: item.key,
                        text: item.text,
                      }))}
                      className="custom-dropdown"
                      selectedKey={this.props.formData.isPublishWeb}
                    />
                  </div>
                  <div className="item row">
                    <ComboBox
                      onChange={(event: any, option: any) => {
                        setFormData("companyUserId", option.key);
                        setFormData("prefix", option.prefix);
                        setFormData("certificateNo", "")
                      }}
                      selectedKey={this.props.formData.companyUserId || 0}

                      allowFreeform={true}
                      autoComplete="on"
                      label="Denetleyici Firma"
                      options={(
                        this.props.formData.CompanyUserList?.dataList ||
                        ([] as any)
                      ).map((item: any) => ({
                        key: item.systemUserId,
                        text: item.title,
                        prefix: item.prefix,
                      }))}
                      className="custom-dropdown"
                      errorMessage={formErrors.companyUserId}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="form-group col-md-4">
            <div className="form-wrapper">
              <div className="item row">
                <TextField
                  disabled={isDisabled}
                  placeholder="Tesisin Web Sitesi"
                  value={formData.webSite || ""}
                  label="Tesisin Web Sitesi"
                  className="custom-textfield border"
                  errorMessage={formErrors.webSite}
                  onChange={(event: any) =>
                    setFormData(
                      "webSite",
                      event.target.value === "" ? null : event.target.value
                    )
                  }
                />
              </div>

              <div className="item row">
                <DatePicker
                  disabled={isDisabled}
                  strings={DayPickerStrings}
                  firstDayOfWeek={firstDayOfWeek}
                  onSelectDate={(date) =>
                    setFormData("appealDate", toTimeStamp(date?.toString()))
                  }
                  label="Sertifika Başvuru Tarihi"
                  placeholder="gün.ay.yıl"
                  formatDate={onFormatDate}
                  isRequired={true}
                  value={this.getDate(
                    this.props.formData.appealDate === null
                      ? 0
                      : this.props.formData.appealDate
                  )}
                  minDate={certificateAppealStatusDate(
                    usertype,
                    true,
                    this.props.formData.intitialAppealDate
                      ? this.props.formData.intitialAppealDate
                      : this.props.formData.appealDate
                  )}
                  maxDate={new Date()}
                />

                <PrimaryButton
                  disabled={isDisabled}
                  id="appealDate"
                  onClick={(x) => this.props.setFormData("appealDate", 0)}
                  iconProps={{ iconName: "Trash" }}
                  text="Tarihi Sil"
                  className="deleteDateBtn"
                  styles={{
                    root: {
                      marginTop: 5,
                      background: "#ac6262",
                      borderColor: "#ac6262",
                    },
                    rootHovered: {
                      background: "#a34c4c",
                      borderColor: "#a34c4c",
                    },
                    rootPressed: {
                      background: "#a34c4c",
                      borderColor: "#a34c4c",
                    },
                    rootDisabled: {
                      background: "#ececec",
                    },
                  }}
                />
              </div>
              <div className="item row">
                {usertype && usertype === "2" ? (
                  <Dropdown
                    disabled={isDisabled}
                    onChanged={(event: any) =>
                      event.key !== 0 &&
                      setFormData("correctionType", event.key)
                    }
                    label="Revize Durumu"
                    options={[
                      { key: 1, text: "Revize İstendi", disabled: true },
                      {
                        key: 2,
                        text: "Revize Yapıldı",
                        disabled:
                          this.props.formData?.correctionType === 1
                            ? false
                            : true,
                      },
                      { key: 3, text: "Revize Onaylandı", disabled: true },
                    ]}
                    className="custom-dropdown"
                    selectedKey={this.props.formData.correctionType}
                  />
                ) : usertype && usertype === "3" ? (
                  <Dropdown
                    disabled={isDisabled}
                    onChanged={(event: any) =>
                      event.key !== 0 &&
                      setFormData("correctionType", event.key)
                    }
                    label="Revize Durumu"
                    options={[
                      { key: 0, text: "Seçiniz" },
                      { key: 1, text: "Revize İstendi" },
                      { key: 2, text: "Revize Yapıldı", disabled: true },
                      { key: 3, text: "Revize Onaylandı" },
                    ]}
                    className="custom-dropdown"
                    selectedKey={this.props.formData.correctionType}
                  />
                ) : (
                  <Dropdown
                    disabled={isDisabled}
                    onChanged={(event: any) =>
                      event.key !== 0 &&
                      setFormData("correctionType", event.key)
                    }
                    label="Revize Durumu"
                    options={[
                      { key: 0, text: "Seçiniz" },
                      { key: 1, text: "Revize İstendi" },
                      { key: 2, text: "Revize Yapıldı" },
                      { key: 3, text: "Revize Onaylandı" },
                    ].map((item: any) => ({
                      key: item.key,
                      text: item.text,
                    }))}
                    className="custom-dropdown"
                    selectedKey={this.props.formData.correctionType}
                  />
                )}
              </div>
              <div className="item row">
                <Checkbox
                  disabled={false}
                  checked={
                    this.props.formData.checkedStatusList?.filter(
                      (x: any) => x === 1
                    ).length > 0
                  }
                  onChange={() =>
                    this.props.formData.checkedStatusList?.filter(
                      (x: any) => x === 1
                    ).length > 0
                      ? this.props.setFormData(
                        "checkedStatusList",
                        this.props.formData.checkedStatusList?.filter(
                          (x: any) => x !== 1
                        )
                      )
                      : this.props.setFormData("checkedStatusList", [
                        1,
                        ...this.props.formData.checkedStatusList,
                      ])
                  }
                  label="Sertifika Uzatmalı Kapalı"
                  className="custom-checkbox"
                />
              </div>

              <div className="item row">
              {usertype !== "2" && 
                <Checkbox
                  disabled={false}
                  checked={
                    this.props.formData.checkedStatusList?.filter(
                      (x: any) => x === 2
                    ).length > 0
                  }
                  onChange={() =>
                    this.props.formData.checkedStatusList?.filter(
                      (x: any) => x === 2
                    ).length > 0
                      ? this.props.setFormData(
                        "checkedStatusList",
                        this.props.formData.checkedStatusList.filter(
                          (x: any) => x !== 2
                        )
                      )
                      : this.props.setFormData("checkedStatusList", [
                        2,
                        ...this.props.formData.checkedStatusList,
                      ])
                  }
                  label="Firma Sertifika Sürecinden Ayrıldı"
                  className="custom-checkbox"
                />
                }
              </div>

              <div className="item row">
                <Checkbox
                  disabled={false}
                  checked={
                    this.props.formData.checkedStatusList?.filter(
                      (x: any) => x === 3
                    ).length > 0
                  }
                  onChange={() =>
                    this.props.formData.checkedStatusList?.filter(
                      (x: any) => x === 3
                    ).length > 0
                      ? this.props.setFormData(
                        "checkedStatusList",
                        this.props.formData.checkedStatusList.filter(
                          (x: any) => x !== 3
                        )
                      )
                      : this.props.setFormData("checkedStatusList", [
                        3,
                        ...this.props.formData.checkedStatusList,
                      ])
                  }
                  className="custom-checkbox"
                  label="Kare Kodsuz Sertifikalı"
                />
              </div>
              <div className="item row">
                {usertype && usertype === "2" ? (
                  <div
                    style={{
                      display:
                        formData.correctionDescription === "" ||
                          formData.correctionDescription === null ||
                          formData.correctionDescription === undefined
                          ? "none"
                          : "block",
                    }}
                  >
                    <Label style={{ width: "100%" }}>
                      <b>Revize Açıklama</b>
                    </Label>
                    <Label>{formData.correctionDescription}</Label>
                  </div>
                ) : (
                  <>
                    <label className="correctionDescriptionLabel">
                      Revize Açıklaması
                    </label>
                    <textarea
                      disabled={isDisabled}
                      placeholder="Revize Açıklaması"
                      value={formData.correctionDescription || ""}
                      rows={20}
                      className="custom-textfield border correctionDescription"
                      onChange={(event: any) =>
                        setFormData(
                          "correctionDescription",
                          event.target.value === "" ? null : event.target.value
                        )
                      }
                    ></textarea>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="form-group col-md-4">
            <div className="form-wrapper">
              <div className="item row">
                <CertificateStatus />
              </div>

              {this.props.formData.certificateStatusTypeId === 2 &&
                this.props.formData.prefix ? (
                this.props.formData.prefix ? (
                  <>
                    <div className="item row">
                      <DatePicker
                        disabled={false}
                        strings={DayPickerStrings}
                        firstDayOfWeek={firstDayOfWeek}
                        onSelectDate={(date) =>
                          setFormData(
                            "confirmationDate",
                            toTimeStamp(date?.toString())
                          )
                        }
                        label="Sertifika Düzenleme Tarihi"
                        placeholder="gün.ay.yıl"
                        formatDate={onFormatDate}
                        isRequired={
                          this.props.formData.certificateStatusTypeId === 2
                        }
                        value={this.getDate(
                          formData.confirmationDate === null
                            ? 0
                            : formData.confirmationDate
                        )}
                        maxDate={sertifikaTarihiBayram(usertype)}
                      />
                      <PrimaryButton
                        disabled={false}
                        id="confirmationDate"
                        onClick={(x) =>
                          this.props.setFormData("confirmationDate", 0)
                        }
                        iconProps={{ iconName: "Trash" }}
                        text="Tarihi Sil"
                        className="deleteDateBtn"
                        styles={{
                          root: {
                            marginTop: 5,
                            background: "#ac6262",
                            borderColor: "#ac6262",
                          },
                          rootHovered: {
                            background: "#a34c4c",
                            borderColor: "#a34c4c",
                          },
                          rootPressed: {
                            background: "#a34c4c",
                            borderColor: "#a34c4c",
                          },
                          rootDisabled: {
                            background: "#ececec",
                          },
                        }}
                      />
                    </div>
                    <div className="item row">
                      <TextField
                        disabled={false}
                        id="CertificateNo"
                        placeholder="Sertifika No"
                        value={formData.certificateNo || ""}
                        label="Sertifika No"
                        className="custom-textfield border"
                        onChange={(event: any) =>
                          setFormData("certificateNo", event.target.value)
                        }
                        errorMessage={formErrors.certificateNo}
                      />
                    </div>
                    {usertype && usertype !== "2" && (
                      <div className="item row">
                        <Dropdown
                          onChanged={(event: any) =>
                            setFormData("isPublishQr", event.key)
                          }
                          label="Sertifika QR Kodu Yayımlansın mı?"
                          options={[
                            { key: false, text: "Hayır" },
                            { key: true, text: "Evet" },
                          ].map((item: any) => ({
                            key: item.key,
                            text: item.text,
                          }))}
                          className="custom-dropdown"
                          selectedKey={this.props.formData.isPublishQr}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  this.showDialog({
                    title: "Uyarı",
                    desc: "Sertifika Numarası için firma kodu Yok <br/> Denetleyici Firma Seçiniz",
                  })
                )
              ) : (
                ""
              )}
              <ValidityDate />

              {this.props.formData.certificateStatus != 3 &&
                this.props.formData.certificateStatus != 4 &&
                this.props.formData.certificateStatus != 6 &&
                this.props.formData.certificateStatus != 7 && (
                  <QrCode/>
                )}
            </div>
            {(usertype === "1" || usertype === "3") && this.props.formData.status === StatusEnums.Deleted && <div className="form-wrapper">
              <div>
                <label className="correctionDescriptionLabel">
                  Silinme Sebebi
                </label>
                <textarea
                  disabled={isDisabled}
                  placeholder="Silinme açıklamasını giriniz"
                  value={formData.deletedReason || ""}
                  rows={20}
                  className="custom-textfield border correctionDescription"
                  onChange={(event: any) => {
                    setFormData(
                      "deletedReason",
                      event.target.value === "" ? null : event.target.value
                    );
                  }}
                ></textarea>
              </div>
            </div>}
          </div>
        </aside>
      </div>
    );
  }
  showDialog(req: any): any {
    this.props.showErrorDialog(req);
  }

  getDate(date: any): any {
    if (date !== 0) {
      return toDate(date, "");
    }
  }
  renderStatusByType() {
    switch (this.props.formData.status) {
      case StatusEnums.Published:
        return (
          <div className="status">
            Durum: <strong>Yayımda</strong>
          </div>
        );
      case StatusEnums.Draft:
        return (
          <div className="status">
            Durum: <strong>Firma Değişti</strong>
          </div>
        );
      case StatusEnums.Deleted:
        return (
          <div className="status">
            Durum: <strong>Silindi</strong>
          </div>
        );
      case StatusEnums.DraftArchive:
        return (
          <div className="status">
            Durum: <strong>Uygun Görülmedi</strong>
          </div>
        );
      case StatusEnums.PendingApproval:
        return (
          <div className="status">
            Durum: <strong>Sertifika Süresi Bitenler</strong>
          </div>
        );
      case StatusEnums.ActivityStopped:
        return (
          <div className="status">
            Durum: <strong>Faaliyetini Durdurdu</strong>
          </div>
        );
      default:
        return null;
    }
  }

  onChangeStatus(status: StatusEnums) {
    const request: RestaurantChangeStatusRequestModel = {
      id: this.props.RestaurantId,
      status: status,
      deletedReason: this.props.formData.deletedReason
    };
    castThunkAction(this.props.changeStatus(request)).then((response: any) => {
      this.setState(
        {
          messageType: response ? MessageBarType.success : MessageBarType.error,
        },
        () => {
          if (response) this.props.setFormData("status", status);
        }
      );
    });
  }
  render() {
    const actionButton = (
      <FormButtons
        isAbsolute
        onSubmit={() => this.onSubmit()}
        customRenderButtons={() => (
          <React.Fragment>
            {this.getSaveButton()}

            {
              usertype === "2" && <PrimaryButton
                disabled={this.props.formData?.status !== 1}
                onClick={() => {
                  this.props.toggleConfirmDialog(
                    true,
                    "Kayıt Durumu, Sertifika Sürecinden Ayrıldı olarak düzenlenecektir ve tesis üzerindeki yetkileriniz düşürülecektir. Onaylıyor musunuz?",
                    () => this.props.leaveCertificationProcess({
                      endId: this.props.formData.endId,
                      id: this.props.formData.restaurantCertificateId
                    })
                  );
                }}
                text={"Sertifika Sürecinden Ayrıl"}
                styles={{
                  root: {
                    background: "#FF4343",
                    borderColor: "#FF4350",
                  },
                }}
              />
            }
            {usertype !== "5" && usertype !== "2" ? (
              <>
                <PrimaryButton
                  onClick={() => this.SetUnread()}
                  iconProps={{ iconName: "" }}
                  text="Tesis Bildirimlerini Okunmadı Yap"
                  styles={{
                    root: {
                      background: "#169ac0",
                      borderColor: "#169ac0",
                    },
                    rootHovered: {
                      background: "#169ac0",
                      borderColor: "#169ac0",
                    },
                    rootPressed: {
                      background: "#169ac0",
                      borderColor: "#169ac0",
                    },
                    rootDisabled: {
                      background: "#169ac0",
                    },
                  }}
                />
                <PrimaryButton
                  onClick={() => this.RemovePublish(true)}
                  iconProps={{ iconName: "" }}
                  text="Web Sitesinden Kaldır"
                  styles={{
                    root: {
                      background: "#eb8f0e",
                      borderColor: "#eb8f0e",
                    },
                    rootHovered: {
                      background: "#eb8f0e",
                      borderColor: "#eb8f0e",
                    },
                    rootPressed: {
                      background: "#eb8f0e",
                      borderColor: "#eb8f0e",
                    },
                    rootDisabled: {
                      background: "#eb8f0e",
                    },
                  }}
                />
                <PrimaryButton
                  onClick={() => this.RemovePublish(false)}
                  iconProps={{ iconName: "" }}
                  text="QR Kodu Yayımını Durdur"
                  styles={{
                    root: {
                      background: "#eb8f0e",
                      borderColor: "#eb8f0e",
                    },
                    rootHovered: {
                      background: "#eb8f0e",
                      borderColor: "#eb8f0e",
                    },
                    rootPressed: {
                      background: "#eb8f0e",
                      borderColor: "#eb8f0e",
                    },
                    rootDisabled: {
                      background: "#eb8f0e",
                    },
                  }}
                />
              </>
            ) : (
              ""
            )}
            {this.state.isFileUploading && (
              <div className="tooltip-warning">
                Bazı dosyalar yükleniyor, lütfen dosyalar yüklenene kadar
                bekleyiniz.
              </div>
            )}

            {usertype !== "5" && usertype !== "2" ? (
              <>
                <Dropdown
                  style={{ width: "280px", marginRight: "10px" }}
                  options={RECORD_CERTIFICATION_STATUS}
                  className="custom-dropdown "
                  selectedKey={this.props.formData?.status}
                  onChange={(event: any, option: any) => {
                    this.props.setFormData('isDeletedReason', option.key === StatusEnums.Deleted)
                    this.props.toggleConfirmDialog(
                      true,
                      "Kayıt durumunu değiştirmek istiyor musunuz?",
                      () => this.onChangeStatus(option.key)
                    );
                  }}
                />
              </>
            ) : (
              ""
            )}
            {this.renderStatusByType()}
          </React.Fragment>
        )}
      />
    );
    return (
      <div className="page push-all">
        {usertype && usertype != "2" && (
          <PageTitle
            titleRenderer={() => (
              <span className="edit-title">
                <strong>Sertifikayı Düzenle</strong>
                <strong>{this.props.formData?.title || ""}</strong>
              </span>
            )}
            showBack
            backUrl="/Restaurant"
            history={this.props.history}
            subTitle="Değiştirmek istediğiniz alanları aşağıda bulabilirsiniz."
            createdInfo={{
              createdUser: this.props.formData.createdUserName,
              createdDate: this.props.formData.createDateString,
              updatedUser: this.props.formData.updatedUserName,
              updatedDate: this.props.formData.updateDateString,
            }}
            rightType="edit"
            right={actionButton}
          />
        )}
        {usertype && usertype == "2" && (
          <PageTitle
            titleRenderer={() => (
              <span className="edit-title">
                <strong>Sertifikayı Düzenle</strong>
                <strong>{this.props.formData?.title || ""}</strong>
              </span>
            )}
            showBack
            backUrl="/Restaurant"
            history={this.props.history}
            subTitle="Değiştirmek istediğiniz alanları aşağıda bulabilirsiniz."
            rightType="edit"
            right={actionButton}
          />
        )}
        {(usertype === "1" || usertype === "3") && <div className="changedFields">
          <label>Değişen Alanlar : </label>
          {this.showChangesField()}
        </div>}
        Kayıt No : {this.props.formData.sequenceId}
        {/* {this.renderMessageBar()} */}
        {this.renderForm()}
      </div>
    );
  }
  showChangesField(): any {
    let desc = [];
    const changesField2: any[] = this.props.formData.changesField
      ? JSON.parse(this.props.formData.changesField)
      : [];
    for (let index = 0; index < changesField2.length; index++) {
      if (changesField2[index].tr)
        desc.push(<span>{changesField2[index].tr} </span>);
    }
    if (desc.length === 0) {
      desc.push(<span>Değişiklik Yok</span>);
    }
    return desc;
  }
  SetUnread(): any {
    const request: NotificationUpdateRequestModel = {
      CategoryType: 2,
      RelatedId: this.props.formData.restaurantCertificateId,
      isRead: false,
    };
    castThunkAction(this.props.updateNotificationUnRead(request)).then(
      (response: any) => { }
    );
  }
  RemovePublish(arg0: boolean): any {
    if (arg0) {
      castThunkAction(
        this.props.RemoveWebPublish({
          categoryType: 2,
          relatedId: this.props.formData.restaurantCertificateId,
        })
      ).then((response: any) => {
        window.location.reload();
      });
    } else {
      castThunkAction(
        this.props.RemoveQrPublish({
          categoryType: 2,
          relatedId: this.props.formData.restaurantCertificateId,
        })
      ).then((response: any) => {
        window.location.reload();
      });
    }
  }
  getSaveButton(): any {
    return (
      usertype !== "5" && (
        <PrimaryButton
          onClick={() => this.onSubmit()}
          disabled={usertype==="2" && this.props.formData?.status === 30 ? true : this.state.isFileUploading ? true : false}
          styles={{
            rootDisabled: {
              background: "#ececec",
              borderColor: "#ccc !important",
              opacity: 0.5,
            },
          }}
          iconProps={{ iconName: "CheckMark" }}
          text={onSaveText(this.props.formData.status)}
        />
      )
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditRestaurant);
