import { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { History } from "history";
import { toggleConfirmDialog } from "src/store/system/actions";
import cookie from "react-cookies";
import { getAllM } from "src/store/thunksCity";
import {
  CityGetAllRequestModel,
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";
import { GetHotelByHospital } from "src/store/thunkStatistic";
import {
  ComboBox,
  Checkbox,
  PrimaryButton,
  Dropdown,
} from "office-ui-fabric-react";
import { setFormData } from "src/store/form/actions";

import { castThunkAction } from "src/helpers/casting";

// @ts-ignore
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { formatDate } from "src/helpers/converts";

let selectableFieldsConst = [
  { field: "brandName", text: "OTEL ADI" },
  { field: "cityName", text: "ŞEHİR" },
  { field: "districtName", text: "İLÇE" },
  { field: "layoverFacilityTypeName", text: "YILDIZ" },
  { field: "roomCount", text: "ODA SAYISI" },
  { field: "bedCount", text: "YATAK SAYISI" },
  { field: "certificateStatusTypeName", text: "SERTİFİKA" },
];

let usertype: any = 0;
interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        toggleConfirmDialog,
        getAllM,
        GetHotelByHospital,
        setFormData,
      },
      dispatch
    ),
  };
}

type HospitalBasedReportProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  pagerTotal: number;
  excel: boolean;
  download: boolean;
  selectableFields: any[];
  selectedFields: any[];
}

class List extends Component<HospitalBasedReportProps, OwnState> {
  state = {
    filterData: {
      searchKey: "",
      isRead: null,
      orderField: "ProcessDate",
      isDescending: true,
    },
    pagerCurrent: 0,
    excel: false,
    download: false,
    pagerTotal: 0,
    selectableFields: selectableFieldsConst,
    selectedFields: selectableFieldsConst,
  };

  UNSAFE_componentWillMount() {
    usertype = cookie.load("systemUserInfo");
    if (usertype === "2") {
      window.location.href = "/";
    }
    this.props.setFormData("isColored", 2);
    const request2: CityGetAllRequestModel = {
      selectItemList: ["CityId", "CityName"],
      isDescending: false,
      orderField: "CityName",
      criteriaItemList: [
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
    };
    this.props.getAllM(request2);
    this.props.setFormData("roomCount", 50);
  }
  UNSAFE_componentWillUpdate(nextProps: any, nextState: any) {
    if (nextState.pagerTotal !== nextProps.formData.pageCount)
      this.setState({
        ...this.state,
        pagerTotal: this.props.formData.pageCount,
      });
  }
  rederexcel() {
    if (this.state.excel) {
      return (
        <>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="table-to-xls"
            filename={"HastaneBazlıOtelRaporu_" + formatDate()}
            sheet="Rapor 1"
            buttonText="Rapor İndir"
          />
          <table id="table-to-xls">
            <thead>
              <tr>
                <th>Sağlık Kuruluşu ADI</th>
                {this.state.selectedFields.map((x: any) => (
                  <th>{x.text}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.props.formData?.HotelByHospital?.hospitals.map(
                (x: any, indexHospital: any) => {
                  let colorHex = Math.floor(Math.random() * 16777215).toString(
                    16
                  );
                  return x.hotels.map((y: any, index: any) =>
                    index === 0 ? (
                      <tr>
                        <td
                          style={{
                            verticalAlign: "top",
                            background:
                              "#" +
                              (this.props.formData.isColored === 1
                                ? colorHex
                                : "fff"),
                          }}
                          rowSpan={x.hotels.length}
                        >
                          {x.hospitalName}
                        </td>
                        {this.state.selectedFields.map((field: any) => (
                          <td
                            style={{
                              verticalAlign: "top",
                              background:
                                "#" +
                                (this.props.formData.isColored === 1
                                  ? colorHex
                                  : "fff"),
                            }}
                          >
                            {y[field.field]}
                          </td>
                        ))}
                      </tr>
                    ) : (
                      <tr>
                        {this.state.selectedFields.map((field: any) => (
                          <td
                            style={{
                              verticalAlign: "top",
                              background:
                                "#" +
                                (this.props.formData.isColored === 1
                                  ? colorHex
                                  : "fff"),
                            }}
                          >
                            {y[field.field]}
                          </td>
                        ))}
                      </tr>
                    )
                  );
                }
              )}
            </tbody>
          </table>
        </>
      );
    }
    return "";
  }
  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Sağlık Kuruluşu Bazlı Otel Raporu"
          subTitle="Sağlık Kuruluşu bazlı otel raporunu bu sayfada alabilirsiniz"
          backUrl="/"
          showBack
          history={this.props.history}
        />
        <div className="form-groups row">
          <aside className="col-md-12 row" style={{ padding: 0 }}>
            <div className="form-group col-md-8">
              <div className="form-wrapper">
                <div className="item row">
                  <ComboBox
                    label="Rapor almak istediğiniz şehri seçin"
                    allowFreeform={true}
                    autoComplete="on"
                    multiSelect={false}
                    options={(
                      this.props.formData.GroupList?.dataList || ([] as any)
                    ).map((item: any) => ({
                      key: item.CityId,
                      text: item.CityName,
                    }))}
                    onChange={(event: any, option: any) =>
                      this.props.setFormData("cityId", option.key)
                    }
                    selectedKey={this.props.formData.cityId}
                  />
                </div>
                <div className="item row">
                  <Dropdown
                    options={[
                      { key: 1, text: "Var" },
                      { key: 2, text: "Yok" },
                    ]}
                    label="Renklendirme"
                    onChange={(event: any, option: any) =>
                      this.props.setFormData("isColored", option.key)
                    }
                    selectedKey={this.props.formData.isColored}
                  />
                </div>
                <div className="item row">
                  <label
                    style={{
                      float: "left",
                      width: "100%",
                      fontSize: "20px",
                      marginTop: "7px",
                      marginBottom: "15px",
                    }}
                  >
                    Raporda istediğiniz alanları seçiniz
                  </label>
                  {this.state.selectableFields.map((x) => (
                    <div
                      style={{ float: "left", width: "48%", marginRight: "2%" }}
                    >
                      <div style={{ marginBottom: "15px" }}>
                        <Checkbox
                          label={x.text}
                          checked={this.getBool(x)}
                          onChange={() => (
                            this.state.selectedFields.length > 0
                              ? this.state.selectedFields.filter(
                                (y) => y.field === x.field
                              ).length > 0
                                ? this.setState({
                                  ...this.state,
                                  selectedFields: this.state.selectedFields.filter(
                                    (y) => y.field !== x.field
                                  ),
                                })
                                : this.setState({
                                  ...this.state,
                                  selectedFields: [
                                    ...this.state.selectedFields,
                                    x,
                                  ],
                                })
                              : this.setState({
                                ...this.state,
                                selectedFields: [
                                  ...this.state.selectedFields,
                                  x,
                                ],
                              })
                          )}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <PrimaryButton
                  text="Rapor Oluştur"
                  className="custom-button"
                  onClick={() => this.getReport()}
                  styles={{
                    root: {
                      fontSize: "11px",
                      marginTop: "27px",
                      height: "30px",
                    },
                  }}
                />
                {this.rederexcel()}
              </div>
            </div>
          </aside>
        </div>
      </div>
    );
  }
  getBool(x: any) {
    return this.state.selectedFields.filter((y: any) => y.field === x.field)
      .length > 0
      ? true
      : false;
  }
  getReport() {
    const request: any = {
      cityId: this.props.formData.cityId,
    };

    castThunkAction(this.props.GetHotelByHospital(request)).then((x: any) => {
      this.setState({ excel: true, download: false });
    });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
