import { StatusEnums } from "../BaseEnum";

export interface CongressFacilityTypeSaveDataModel {
  CongressFacilityTypeName: string;
  CongressFacilityTypeName_En: string;
  subCategoryId: number;
  isMinistry: boolean;
  status: StatusEnums;
}
export interface CongressFacilityTypeSaveRequestModel {
  data: CongressFacilityTypeSaveDataModel;
}

export interface CongressFacilityTypeUpdateDataModel {
  CongressFacilityTypeId: number;
  CongressFacilityTypeName: string;
  CongressFacilityTypeName_En: string;
  subCategoryId: number;
  isMinistry: boolean;
  status: StatusEnums;
}

export interface CongressFacilityTypeUpdateRequestModel {
  data: CongressFacilityTypeUpdateDataModel;
}

export interface CongressFacilityTypeChangeStatusRequestModel {
  status: StatusEnums;
  id: number;
}

export enum ConditionOperatorEnum {
  Eq = 0,
  NotEq = 1,
  Like = 2,
  Gt = 3,
  Lt = 4,
  Ge = 5,
  Le = 6,
}

export interface criteriaItemListData {
  propertyName: string;
  value: any;
  isOr: boolean;
  conditionOperator: ConditionOperatorEnum;
}

export interface CongressFacilityTypeGetAllRequestModel {
  criteriaItemList?: criteriaItemListData[];
  selectItemList?: string[];
  pageSize?: number;
  pageIndex?: number;
  orderField?: string;
  isDescending?: boolean;
  status?: StatusEnums;
}
export interface CongressFacilityTypeGetByIdRequestModel {
  id: number;
}

export interface CongressFacilityTypeChangeStatusRequestModel {
  id: number;
  status: StatusEnums;
}
