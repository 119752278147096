import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
    TextField,
    MessageBar,
    MessageBarType,
    PrimaryButton,
} from "office-ui-fabric-react";
import { setFormData, setFormErrors } from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";
import { castThunkAction } from "src/helpers/casting";
import { StatusEnums } from "src/services/BaseEnum";
import {
    ChangePassword,
    getSystemUserById,
    UpdateSystemUser,
} from "src/store/thunksSystemUser";
import { SystemUserSystemUserLoginDataModel } from "src/services/SystemUser/ResponseModel";
import { SystemUserChangePasswordRequestModel } from "src/services/SystemUser/RequestModel";
import cookie from "react-cookies";
import { tokenControl } from "src/store/system/actions";
import _ from "lodash";

interface OwnProps {
    history: History;
}

const usertype = cookie.load("systemUserInfo");

function mapStateToProps(state: RootState) {
    return {
        language: state.system.language,
        formData: state.form.formData,
        formErrors: state.form.formErrors,
        userInfo: state.system.userInfo as SystemUserSystemUserLoginDataModel,
    };
}

let changesFiled: any[] = [];
let oldData: any = {};
const keys = {
    "uid": "Kullanıcı Adı",
    'title': "İsim Soyisim",
    'password': "Parola",
    'systemUserType': "Kullanıcı Tipi",
    'prefix': "Sertifika No Prefix",
    'emailAddressList': "Eposta Adresi Listesi",
    'email': "Eposta Adresi"
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        ...bindActionCreators(
            {
                setFormData,
                setFormErrors,
                ChangePassword,
                getSystemUserById,
                UpdateSystemUser,
                tokenControl,
            },
            dispatch
        ),
    };
}

type EditCountyProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    OwnProps;

interface OwnState {
    messageType: MessageBarType;
}

class Edit extends Component<EditCountyProps, OwnState> {
    state = {
        messageType: MessageBarType.warning,
    };

    UNSAFE_componentWillMount() {
        this.props.setFormData("password", "");
        this.props.setFormData("oldPassword", "");

        // if (usertype === "2") {

        const request: any = {
        };
        castThunkAction(this.props.getSystemUserById(request)).then(
            (response: any) => {
                this.props.setFormData("oldData", response.data.uid);
                oldData = _.cloneDeep(this.props.formData);
                Object.keys(oldData).length === 0 && (oldData = _.cloneDeep(this.props.formData))
            }
        );
        // }
    }

    renderTextByMessageType() {
        switch (this.state.messageType) {
            case MessageBarType.success:
                return "Kaydetme işlemi başarıyla gerçekleşmiştir.";
            case MessageBarType.error:
                return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
            default:
                return "Lütfen aşağıdaki bilgileri doldurun, ve oluşturmayı tamamlayın!";
        }
    }

    renderMessageBar() {
        return (
            <MessageBar messageBarType={this.state.messageType} isMultiline={false}>
                {this.renderTextByMessageType()}
            </MessageBar>
        );
    }

    getChangesField(): any {
        return new Promise((resolve: any, reject) => {
            changesFiled = []
            Object.keys(keys).forEach(key => {
                if (this.props.formData?.[key] === 'password' && this.props.formData.password && this.props.formData.password !== null) {
                    changesFiled.push(key);
                } else {
                    if (JSON.stringify(this.props.formData?.[key]) !== JSON.stringify(oldData?.[key])) {
                        changesFiled.push(key);
                    }
                }

            })

            resolve(changesFiled);
        });
    }
    onSave() {
        const request: SystemUserChangePasswordRequestModel = {
            password: this.props.formData.password,
            oldPassword: this.props.formData.oldPassword,
        };

        castThunkAction(this.props.ChangePassword(request)).then(
            (response: any) => {
                this.setState({
                    messageType: response ? MessageBarType.success : MessageBarType.error,
                });
                if (response.success) {
                    setTimeout(() => {
                        this.logOut();
                    }, 1000);
                }
                oldData = _.cloneDeep(this.props.formData);
            }
        );
    }

    /**
     * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
     * formErrors Objesini Dolduracak Action'u Tetikler.
     */
    onSubmit(isDraft?: boolean) {
        const { formData, setFormErrors } = this.props;
        const validationSchema = _yup.object({
            password: _yup
                .string()
                .required("Şifre girilmedi.")
                .matches(
                    // eslint-disable-next-line
                    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                    "Şifre en az bir büyük harf, bir küçük harf, bir rakam, bir özel karakter içermeli ve en az sekiz karakter uzunluğunda olmalıdır."
                ).nullable(),
            // uid: _yup.string().required("Bu alan zorunludur!"),
            // title: _yup.string().required("Bu alan zorunludur!"),
            // prefix: _yup.string().required("Bu alan zorunludur!"),
            // email: _yup.string().required("Bu alan zorunludur!"),
            oldPassword: _yup.string().required("Bu alan zorunludur!"),
        });
        let formErrors = {};
        validationSchema
            .validate(formData, {
                abortEarly: false,
            })
            .then(() => {
                setFormErrors({});
                this.onSave();
            })
            .catch((err) => {
                err.inner.forEach((e: any) => {
                    const { path, message } = e;
                    formErrors[path] = message;
                });
                setFormErrors(formErrors);
            });
    }

    logOut(): any {
        cookie.remove("userId");
        cookie.remove("systemUserInfo");
        cookie.remove("systemUserName");
        this.props.tokenControl(false);
    }

    onSubmitUpdate() {
        const { formData, setFormErrors } = this.props;
        const validationSchema = _yup.object({
            uid: _yup.string().required("Bu alan zorunludur!"),
            title: _yup.string().required("Bu alan zorunludur!"),
            //prefix: _yup.string().required("Bu alan zorunludur!"),
        });
        let formErrors = {};
        validationSchema
            .validate(formData, {
                abortEarly: false,
            })
            .then(() => {
                setFormErrors({});
                this.onUpdate();
            })
            .catch((err) => {
                err.inner.forEach((e: any) => {
                    const { path, message } = e;
                    formErrors[path] = message;
                });
                setFormErrors(formErrors);
            });
    }

    onUpdate() {
        const request: any = {
            data: {
                uid: this.props.formData.uid,
                title: this.props.formData.title,
                prefix: this.props.formData.prefix,
                email: '',
                systemUserId: this.props.formData.systemUserId,
                emailAddressList: this.props.formData.emailAddressList,
                systemUserType: this.props.formData.systemUserType
            },
        };
        this.getChangesField().then((changesFiled: any) => {
            if (changesFiled && changesFiled.length > 0) {
                this.props.setFormData("changesFields", JSON.stringify(changesFiled));
                request.data["changesFields"] = JSON.stringify(changesFiled);
            }
            castThunkAction(this.props.UpdateSystemUser(request)).then(
                (response: any) => {
                    this.setState({
                        messageType: response ? MessageBarType.success : MessageBarType.error,
                    });
                    // if (response.success) {
                    //     setTimeout(() => {
                    //         this.logOut();
                    //     }, 1000);
                    // }
                }
            );
        })
    }
    addEmail() {
        const mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!mail.test(this.props.formData.newEmail)) {
            alert("Geçersiz e-Posta");
        } else {

            if (this.props.formData?.emailAddressList?.length > 0 && this.props.formData?.emailAddressList?.indexOf(this.props.formData.newEmail) >= 0) {
                alert("Eklemeye çalıştığınız e-posta sisteme kayıtlıdır.")
            } else {
                const newArray = Object.assign(this.props.formData?.emailAddressList);
                newArray?.push(this.props.formData?.newEmail)
                this.props.setFormData("emailAddressList", newArray);
                this.props.setFormData("newEmail", "");
            }

        }
    }

    renderForm() {
        const { formData, formErrors, setFormData } = this.props;
        // function editable(usertype:string) {

        //     if (usertype === '1') {
        //       return false
        //     }
        // }
        return (
            <div className="form-groups row">
                <div className="form-group col-md-4">
                    <div className="form-wrapper">
                        <div className="item row">
                            <TextField
                                placeholder="Kullanıcı Adınızı Giriniz"
                                value={formData.uid || ""}
                                disabled={usertype === "1" ? false : true}
                                label="Kullanıcı Adı"
                                className="custom-textfield border"
                                errorMessage={formErrors.uid}
                                onChange={(event: any) =>
                                    setFormData("uid", event.target.value)
                                }
                            />
                        </div>
                        <div className="item row">
                            <TextField
                                placeholder="İsim Soyisim Giriniz"
                                value={formData.title || ""}
                                label="İsim Soyisim"
                                disabled={usertype === "1" ? false : true}
                                className="custom-textfield border"
                                errorMessage={formErrors.title}
                                onChange={(event: any) =>
                                    setFormData("title", event.target.value)
                                }
                            />
                        </div>
                        {usertype === '2' &&
                            <div className="item row">
                                <TextField
                                    placeholder="Sertifika No Prefix"
                                    value={formData.prefix || ""}
                                    label="Sertifika No Prefix"
                                    disabled={true}
                                    className="custom-textfield border"
                                    errorMessage={formErrors.prefix}
                                    onChange={(event: any) =>
                                        setFormData("prefix", event.target.value)
                                    }
                                />
                            </div>}
                        {/* <div className="item row">
                                <TextField
                                    placeholder="E Posta Adresi"
                                    value={formData.email || ""}
                                    label="E Posta Adresi"
                                    className="custom-textfield border"
                                    errorMessage={formErrors.email}
                                    onChange={(event: any) =>
                                        setFormData("email", event.target.value)
                                    }
                                />
                            </div> */}
                        <div className="item row">
                            <label
                                style={{ marginTop: "10px", fontSize: "11px", fontWeight: "bold", color: "#323130" }}>Kayıtlı
                                E-Posta Adresleri</label>
                            {this.props.formData?.emailAddressList?.map((x: any) => (
                                <div className="newEmailDiv" key={x}>
                                    <label className="newEmailLabel">{x}</label>
                                    <button
                                        className="removeEmailBtn"
                                        onClick={() => {
                                            if (this.props.formData.emailAddressList && this.props.formData.emailAddressList.length > 1) {
                                                this.props.setFormData(
                                                    "emailAddressList",
                                                    this.props.formData.emailAddressList.filter(
                                                        (y: any) => y !== x
                                                    )
                                                )
                                            } else {
                                                alert('En az bir eposta adresi zorunlu.')
                                            }

                                        }}
                                    >
                                        Sil
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div className="item row" style={{ width: "65%", marginTop: "20px" }}>
                            <TextField
                                placeholder="Yeni E-Posta Adresi Ekle"
                                value={formData.newEmail || ""}
                                label="Yeni E-Posta Adresi Ekle"
                                className="custom-textfield border"
                                errorMessage={formErrors.newEmail}
                                type="email"
                                onChange={(event: any) =>
                                    setFormData("newEmail", event.target.value)
                                }
                            />
                        </div>
                        <div className="item row">
                            <button className="addEmailBtn" onClick={() => this.addEmail()}
                                style={{ marginLeft: 'auto', marginTop: "-7%", backgroundColor: '#eb8f0e',justifySelf:'flex-end' }}>
                                e-Posta Ekle
                            </button>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'flex-end', marginTop: 20 }}>
                            <PrimaryButton
                                onClick={() => this.onSubmitUpdate()}
                                styles={{
                                    rootDisabled: {
                                        background: "#626262",
                                        borderColor: "#777373 !important",
                                    },
                                }}
                                iconProps={{ iconName: "CheckMark" }}
                                text={"Kullanıcı Bilgileri Güncelle"}
                            />
                        </div>
                    </div>
                </div>



                <div className="form-group col-md-4">
                    <div className="form-wrapper">
                        {/* <div className="item row">
                            <Label className="custom-textfield border">Kullanıcı Adı :</Label>
                            <Label className="custom-textfield border specLabel">
                                {this.props.userInfo?.title}
                            </Label>
                        </div> */}
                        <div className="item row">
                            <TextField
                                placeholder="Eski Şifre"
                                value={formData.oldPassword}
                                label="Eski Şifre"
                                className="custom-textfield border"
                                autoComplete="new-password"
                                errorMessage={formErrors.oldPassword}
                                onChange={(event: any) =>
                                    setFormData("oldPassword", event.target.value)
                                }
                                type="password"
                            />
                        </div>
                        <div className="item row">
                            <TextField
                                placeholder="Yeni Şifre"
                                value={formData.password}
                                autoComplete="new-password"
                                label="Yeni Şifre"
                                className="custom-textfield border"
                                errorMessage={formErrors.password}
                                onChange={(event: any) =>
                                    setFormData("password", event.target.value)
                                }
                                type="password"
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'flex-end', marginTop: 20 }}>
                            <PrimaryButton
                                onClick={() => this.onSubmit()}
                                styles={{
                                    rootDisabled: {
                                        background: "#626262",
                                        borderColor: "#777373 !important",
                                    },
                                }}
                                iconProps={{ iconName: "CheckMark" }}
                                text={"Şifre Güncelle"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderStatusByType() {
        switch (this.props.formData.status) {
            case StatusEnums.Published:
                return (
                    <div className="status">
                        Durum: <strong>Yayımda</strong>
                    </div>
                );
            case StatusEnums.Draft:
                return (
                    <div className="status">
                        Durum: <strong>Taslak</strong>
                    </div>
                );
            case StatusEnums.Deleted:
                return (
                    <div className="status">
                        Durum: <strong>Silindi</strong>
                    </div>
                );
            default:
                return null;
        }
    }

    render() {
        return (
            <div className="page push-all">
                <PageTitle
                    title="İlçe Tipi Düzenle"
                    titleRenderer={() => (
                        <span className="edit-title">
                            Profil:
                            <strong>{this.props.userInfo?.title || ""}</strong>
                        </span>
                    )}
                    showBack
                    backUrl="/"
                    history={this.props.history}
                    subTitle="Profilinizi bu sayfada güncelleyebilirsiniz"
                />
                {this.renderMessageBar()}
                <FormButtons
                    isAbsolute
                    // onSubmit={() => this.onSubmit()}
                    customRenderButtons={() => (
                        <React.Fragment>



                            {this.renderStatusByType()}
                        </React.Fragment>
                    )}
                />
                {this.renderForm()}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
