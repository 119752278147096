/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import { ConditionOperatorEnum } from "src/services/Hotel/RequestModel";
import { showMessage } from "src/store/system/actions";

import { StatusEnums } from "src/services/BaseEnum";
import { toggleConfirmModalDialog } from "src/store/system/actions";
import { getAllCR } from "src/store/thunkCertificateStatusType";
import cookie from "react-cookies";
import { CertificateStatusTypeGetAllRequestModel } from "src/services/CertificateStatusType/RequestModel";
import { setFormData } from "src/store/form/actions";
import { Dropdown } from "office-ui-fabric-react";

interface OwnProps { }

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        toggleConfirmModalDialog,
        getAllCR,
        setFormData,
        showMessage,
      },
      dispatch
    ),
  };
}

type CertificateStatusProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  certificateStatusTypeId: number;
  initialCertificateStatusTypeId: number | null;
}

let usertype: number = 0;

class CertificateStatus extends Component<CertificateStatusProps, OwnState> {
  state = {
    certificateStatusTypeId: 0,
    initialCertificateStatusTypeId: null,
  };

  UNSAFE_componentWillReceiveProps(nextProps: CertificateStatusProps) {
    if (
      nextProps.formData.certificateStatusTypeId !==
      this.props.formData.certificateStatusTypeId &&
      !this.state.initialCertificateStatusTypeId
    ) {
      this.setState({
        initialCertificateStatusTypeId:
          nextProps.formData?.certificateStatusTypeId,
      });
    }
  }

  componentDidMount() {
    usertype = Number(cookie.load("systemUserInfo"));

    this.getData();
  }

  getData() {
    const request5: CertificateStatusTypeGetAllRequestModel = {
      selectItemList: ["CertificateStatusTypeId", "Title"],
      criteriaItemList: [
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
    };

    this.props.getAllCR(request5);
  }

  render() {
    const {
      certificateStatusTypeId,

    } = this.state;
    const { formData, formErrors, setFormData } = this.props;

    return (
      <Dropdown
        id="certificateStatusTypeId"
        disabled={
          certificateStatusTypeId === 0 &&
          usertype === 2 &&
          formData.certificateStatusTypeId === 5
        }
        onChanged={(event: any) => {
          this.setState({ certificateStatusTypeId: event.key }, () => {
            if (event.key === 1) {
              setFormData("certificateNo", "");
              setFormData("confirmationDate", 0);
            }
            if (
              usertype === 2 &&
              event.key === 2 &&
              this.state.initialCertificateStatusTypeId !== 2
            ) {
              this.props.toggleConfirmModalDialog(
                true,
                "Onayla",
                "Kaydınız “Belge Verildi” olarak güncellenecek ve bu kayıt tekrar Belgelendirme Sürecine çekilemeyecektir. Onaylıyor musunuz?",
                () => setFormData("certificateStatusTypeId", event.key),

                () => { },
                "Onayla",
                "Vazgeç"
              );
            } else if (
              usertype === 2 &&
              event.key === 5 &&
              formData.certificateStatusTypeId !== 5
            ) {
              this.props.toggleConfirmModalDialog(
                true,
                "Onayla",
                "Kaydınız” Uygun Görülmedi” olarak güncellenecek ve bu kayıt tekrar farklı bir statüye çekilemeyecektir. Onaylıyor musunuz?",
                () => setFormData("certificateStatusTypeId", event.key),
                () => { },
                "Onayla",
                "Vazgeç"
              );
            } else {
              setFormData("certificateStatusTypeId", event.key);
            }
          });
        }}
        errorMessage={formErrors.errorMessage}
        label="Sertifika Durumu"
        options={(formData.GroupListCR?.dataList || ([] as any)).map(
          (item: any) => ({
            key: item.CertificateStatusTypeId,
            text: item.Title,
            disabled:
              (item.CertificateStatusTypeId === 1 &&
                usertype === 2 &&
                this.state.initialCertificateStatusTypeId == 2 &&
                true) ||
              false,
          })
        )}
        className="custom-dropdown"
        selectedKey={formData.certificateStatusTypeId}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CertificateStatus);
