import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  TextField,
  // MessageBar,
  MessageBarType,
  PrimaryButton,
  Dropdown,
  Label,
  Modal,
  Icon,
  DefaultButton,
} from "office-ui-fabric-react";
import {
  setFormData,
  setFormErrors,
  initialFormData,
} from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";
import BasicTable from "../BasicTable";
import {
  getById,
  update,
  changeStatus,
  getAllSertificationMA
} from "src/store/thunkVehicle";
import { CallCenterDisabledInput, castThunkAction } from "src/helpers/casting";

import { StatusEnums } from "src/services/BaseEnum";
import { qrUrl, apiConfig } from "src/config/apiConfig";
import { getAllLFA } from "src/store/thunkLayoverFacilityType";
import {
  getAllTy,
  showErrorDialog,
  showChangesFieldDialog,
  showAtDialog,
} from "src/store/thunkCertificateType";

import { getAllVD } from "src/store/thunkVehicleDocumentType";
import { getAllVT } from "src/store/thunkVehicleType";
import _ from "lodash";

import {
  ConditionOperatorEnum,
} from "src/services/City/RequestModel";
import { CertificateTypeGetAllRequestModel } from "src/services/CertificateType/RequestModel";

import cookie from "react-cookies";

import { FileUpload } from "../FileUpload";

import { certificateKeys, RECORD_STATUS } from "src/helpers/enums";
import {
  removeArrayItem,
  onSaveText,
  requestMapStatusData,
  getRecordStatus,
  isNumberValue,
} from "src/helpers/functions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { toDate } from "src/helpers/converts";

import {
  toggleConfirmDialog,
  toggleConfirmModalDialog,
} from "src/store/system/actions";
import { VehicleChangeStatusRequestModel, VehicleGetByIdRequestModel, VehicleUpdateRequestModel } from "src/services/Vehicle/RequestModel";
import { VehicleTypeGetAllRequestModel } from "src/services/VehicleType/RequestModel";
import { VehicleDocumentTypeGetAllRequestModel } from "src/services/VehicleDocumentType/RequestModel";
import { CountyGetAllRequestModel } from "src/services/County/RequestModel";
import { getAllC } from "src/store/thunkCounty";
import { getAllM } from "src/store/thunksCity";
import {CityGetAllRequestModel} from "src/services/City/RequestModel";


let token = "";

interface OwnProps {
  history: History;
  HotelId: number;
}
function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        initialFormData,
        getById,
        getAllTy,
        getAllVD,
        getAllC,
        getAllM,
        getAllVT,
        getAllSertificationMA,
        toggleConfirmDialog,
        toggleConfirmModalDialog,
        update,
        getAllLFA,
        showErrorDialog,
        showAtDialog,
        showChangesFieldDialog,
        changeStatus,
      },
      dispatch
    ),
  };
}

type EditVehicleProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  messageType: MessageBarType;
  infoMessageGetDocumentNo: string;
  isFileUploading: boolean;
  fileUploadContainer: any;
  changesFieldHtml: any;
  modalshow: boolean;
  modalanswer: boolean;
  modelattachmentId: number;
  modalType: number;
  isHaveHealthKit: boolean;
  onlyCountry: string[];
}
let changesFiled: any[] = [];
let oldData: any = {};
let usertype: any = 0;

class EditVehicle extends Component<EditVehicleProps, OwnState> {
  state = {
    messageType: MessageBarType.warning,
    infoMessageGetDocumentNo: "",
    isFileUploading: false,
    fileUploadContainer: "",
    changesFieldHtml: "",
    modalshow: false,
    modalanswer: false,
    modelattachmentId: 0,
    modalType: 0,
    isHaveHealthKit: this.props.formData.isHaveHealthKit,
    onlyCountry: ["tr"],
  };

  UNSAFE_componentWillMount() {
    token = cookie.load("userId");
    this.props.initialFormData({
      status: StatusEnums.Published,
    });
    usertype = cookie.load("systemUserInfo");


    if (this.props.HotelId) {
      castThunkAction(
        this.props.getById({
          id: this.props.HotelId,
        } as VehicleGetByIdRequestModel)
      ).then((response: any) => {


        this.renderFileUpload();
        this.props.setFormData(
          "certificateNo",
          this.props.formData?.certificateNo?.slice(
            this.props.formData.certificateNo.indexOf("-") + 1
          )
        );


        if (usertype !== "2") {
          const request10: VehicleGetByIdRequestModel = {
            id: this.props.HotelId,
          };
          this.props.getAllSertificationMA(request10);
        }

        oldData = Object.assign(this.props.formData, {});



        const request4: CertificateTypeGetAllRequestModel = {
          selectItemList: ["CertificateTypeId", "Title"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllTy(request4);
        this.CityChange(
          this.props.formData?.cityId,
          this.props.formData?.districtId
        );
        const request2: CityGetAllRequestModel = {
          selectItemList: ["CityId", "CityName"],
          orderField: "CityName",
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllM(request2);


        const request8: VehicleDocumentTypeGetAllRequestModel = {
          selectItemList: ["VehicleDocumentTypeId", "VehicleDocumentTypeName"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllVD(request8);

        const request7: VehicleTypeGetAllRequestModel = {
          selectItemList: ["VehicleTypeId", "VehicleTypeName"],
          criteriaItemList: [
            {
              propertyName: "Status",
              value: StatusEnums.Published,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
          ],
        };
        this.props.getAllVT(request7);

        this.props.formData.appealDate === 0 &&
          this.props.setFormData("appealDate", null);
        this.props.formData.confirmationDate === 0 &&
          this.props.setFormData("confirmationDate", null);
      });
    }
  }

  componentDidUpdate(nextprops: any) {
    if (this.props.formData.certificateTypeId !== 0) {
      this.setLabelDescription(
        "companyName",
        "<span id='companyName-span' class='descSpan'>Tesis sahibi olan firmanın ticari ünvanı</span>"
      );

      if (this.props.formData.certificateTypeId === 4) {
        this.setLabelDescription(
          "documentNoDesc",
          "<span id='documentNoDesc-span' class='descSpan'>TC Kültür ve Turizm Bakanlığı tarafından tesise verilen çalışma belge numarası</span>"
        );
      }
    }

  }

  insertAfter(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  insertBefore(referenceNode: any, newNode: any) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode);
  }

  componentDidMount() {
    this.setDdlDescription(
      "certificateTypeIdDesc-label",
      "<br/> <span id='certificateTypeIdDesc-label-span' class='descSpan'>Tesisin kayıt edileceği belge tipini seçiniz. Tesise ait <b>T.C Kültür ve Turizm Bakanlığı</b> Belgesi bulunuyorsa bu belge tipini seçip ilgili belgeyi yüklemeniz ve belgeye uygun bilgileri doldurmanız gerekmektedir. Eğer tesise ait bu belge bulunmuyorsa lütfen <b>Diğer</b> seçerek ilerleyeniz.</span>"
    );
    CallCenterDisabledInput(usertype);
  }

  setDdlDescription(id: string, message: string) {
    const el = document.createElement("span");
    el.innerHTML = message;
    const div = document.getElementById(id);
    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div, el);
    }
  }

  setLabelDescription(id: string, message: string) {
    const el2 = document.createElement("span");
    el2.innerHTML = message;
    const div2 = document.querySelectorAll('[for="' + id + '"]');

    if (!document.getElementById(id + "-span")) {
      this.insertAfter(div2[0], el2);
    }
  }
  // renderTextByMessageType() {
  //   switch (this.state.messageType) {
  //     case MessageBarType.success:
  //       return "Kaydetme işlemi başarıyla gerçekleşmiştir.";
  //     case MessageBarType.error:
  //       return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
  //     default:
  //       return "Lütfen aşağıdaki bilgileri doldurun ve formu oluşturun!";
  //   }
  // }

  // renderMessageBar() {
  //   return (
  //     <MessageBar messageBarType={this.state.messageType} isMultiline={false}>
  //       {this.renderTextByMessageType()}
  //     </MessageBar>
  //   );
  // }

  onSave(isDraft?: boolean) {
    const request: VehicleUpdateRequestModel = requestMapStatusData(
      {
        ...this.props.formData,
        GroupListCR: {},
        GroupListTY: {},
        GroupListVD: {},
        GroupListVT: {},
        TransportSerticationList: {}
      },
      isDraft
    );
    if (
      this.props.formData.attachmentList &&
      this.props.formData.attachmentList.length > 0
    ) {
      request.data["attachmentIdList"] = (
        this.props.formData.attachmentList || []
      ).map((item: any) => item.attachmentId);
    }



    this.getChangesField().then((x: any) => {
      this.props.setFormData("changesField", JSON.stringify(changesFiled));
      request.data["changesField"] = JSON.stringify(changesFiled);
      castThunkAction(this.props.update(request)).then((response: any) => {
        this.props.setFormData(
          "initialAttachmentList",
          this.props.formData.attachmentList
        );

        this.setState(
          {
            messageType: response
              ? MessageBarType.success
              : MessageBarType.error,
          },
          () => {
            this.props.setFormData(
              "status",
              isDraft ? StatusEnums.Draft : this.props.formData.status
            );
          }
        );
      });
    });
  }
  getChangesField(): any {
    var promise = new Promise((resolve: any, reject) => {
      changesFiled =
        usertype === 2
          ? this.props.formData.changesField
            ? JSON.parse(this.props.formData.changesField)
            : []
          : [];

      for (let index = 0; index < Object.keys(oldData).length; index++) {
        if (
          oldData[Object.keys(oldData)[index]] !==
          this.props.formData[Object.keys(this.props.formData)[index]]
        ) {
          certificateKeys.filter((x) => x.en === Object.keys(oldData)[index])
            .length > 0 &&
            changesFiled.push({
              ...certificateKeys.find(
                (x) => x.en === Object.keys(oldData)[index]
              ),
            });
        }
      }

      usertype !== "2" ? (changesFiled = []) : (changesFiled = changesFiled);
      resolve(null);
    });
    return promise;
  }

  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  removeElement(id: any) {
    var elem = document.getElementById(id);
    return elem?.parentNode?.removeChild(elem);
  }
  filterFileds(val: any) {
    if (
      val !== "" &&
      val !== 0 &&
      val !== "0" &&
      val !== null &&
      val !== "null" &&
      val !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  }
  onSubmit(isDraft?: boolean) {
    let desc = "";
    // eslint-disable-next-line
    const webSite = /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    if (this.props.formData.webSite) {
      if (!webSite.test(this.props.formData.webSite)) {
        desc += "Geçersiz web sitesi,  <br/>";
      }
    }


    if (!this.filterFileds(this.props.formData.vehiclePlaque)) {
      desc += "Taşıt Plaka No,  <br/>";
    }


    if (!this.filterFileds(this.props.formData.certificateTypeId)) {
      desc += "Taşıt Belge Tipi, <br/>";
    }
    if (this.props.formData.certificateTypeId === 4) {
      if ((!this.filterFileds(this.props.formData.documentNo)) || this.props.formData.documentNo === "0") {
        desc += "Belge No,  <br/>";
      }
    }

    //https://b24-z1nwey.bitrix24.com.tr/workgroups/group/8/tasks/task/view/2042/ madde revize.
    if (this.props.formData.certificateTypeId === 5) {
      this.props.setFormData("documentNo", "")
    }

    if (!this.filterFileds(this.props.formData.companyName)) {
      desc += "Belge Sahibi / Ticari Ünvan,  <br/>";
    }


    if (!this.filterFileds(this.props.formData.email)) {
      desc += "Taşıt E-Posta Adresi,  <br/>";
    }
    // eslint-disable-next-line
    const mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!mail.test(this.props.formData.email)) {
      desc += "Geçersiz E-posta adresi,  <br/>";
    }
    const phoneControl = /........../;
    if (!phoneControl.test(this.props.formData.phoneNumber)) {
      desc += "Taşıt Telefon Numarası,  <br/>";
    }

    if (!this.filterFileds(this.props.formData.vehicleDocumentTypeId)) {
      desc += "Aracın Sahip Olduğu Yetki Belgesi Sınıfı,  <br/>";
    }

    if (!this.filterFileds(this.props.formData.vehicleTypeId)) {
      desc += "Araç Cinsi,  <br/>";
    }

    if (!this.filterFileds(this.props.formData.cityId)) {
      desc += "İl,  <br/>";
    }

    if (!this.filterFileds(this.props.formData.districtId)) {
      desc += "İlçe,  <br/>";
    }

    if (!this.filterFileds(this.props.formData.address)) {
      desc += "Tesis Adresi,  <br/>";
    }

    if (!this.filterFileds(this.props.formData.seatCount)) {
      desc += "Koltuk Sayısı,  <br/>";
    }

    if (!this.props.formData.vkNo || this.props.formData.vkNo.length < 10) {
      desc += "Vergi numarası 10 hane ve üzeri olmalıdır. <br/>";
    }
    if (this.props.formData.certificateStatusTypeId === 2) {
      if (!this.filterFileds(this.props.formData.confirmationDate)) {
        desc += "Sertifika Tarihi,  <br/>";
      }
      if (!this.filterFileds(this.props.formData.CertificateNo)) {
        desc += "Sertifika No,  <br/>";
      }
    }

    if (
      !this.props.formData.attachmentList ||
      this.props.formData.attachmentList.length <= 2
    ) {
      desc +=
        "Ruhsat - Taşıt Kartı - Yetki Belgesi (3 dosyayı da) Yüklemeniz Gerekmektedir,  <br/>";
    }

    const request = {
      title: "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
      desc: desc,
    };
    if (desc !== "") {
      castThunkAction(
        this.props.showErrorDialog(request)
      ).then((response: any) => { });
    }

    const { formData, setFormErrors } = this.props;
    const validationSchema = _yup.object({
      webSite: _yup
        .string()
        .matches(
          // eslint-disable-next-line
          /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          "Geçersiz Web Sitesi"
        )
        .notRequired()
        .nullable(),
      companyName: _yup.string().required("Bu alan zorunludur!"),
      vkNo: _yup.string().required("Bu alan zorunludur!"),
      documentNo: this.props.formData.certificateTypeId === 4
        ? _yup.string().required("Bu alan zorunludur!")
        : _yup.string().nullable(),

      CertificateNo:
        this.props.formData.certificateStatusTypeId === 2
          ? _yup.string().required("Bu alan zorunludur!")
          : _yup.string().nullable(),
      email: _yup
        .string()
        .matches(
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Geçersiz E-posta Adresi"
        )
        .required('Bu alan zorunludur!')
        .nullable(),
      vehiclePlaque: _yup.string().required("Bu alan zorunludur!"),
      phoneNumber: _yup.string().required("Bu alan zorunludur!"),
      vehicleDocumentTypeId: _yup.string().required("Bu alan zorunludur!"),
      vehicleTypeId: _yup.string().required("Bu alan zorunludur!"),
      seatCount: _yup.string().required("Bu alan zorunludur!"),
      certificateTypeId: _yup.string().required("Bu alan zorunludur!"),
      cityId: _yup.string().required("Bu alan zorunludur!"),
      districtId: _yup.string().required("Bu alan zorunludur!"),
      address: _yup.string().required("Bu alan zorunludur!").nullable(),
    });

    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});

        if (desc === "") {
          if (
            this.props.formData.attachmentList &&
            this.props.formData.attachmentList.length > 0
          ) {
            this.onSave(isDraft);
          } else {
            castThunkAction(
              this.props.showErrorDialog({
                title:
                  "Formu kaydetmek için aşağıdaki alanları eksiksiz doldurun",
                desc: "İşletme belgesi yükleyiniz",
              })
            ).then((response: any) => { });
          }
        }
      })
      .catch((err) => {
        console.log(err, "fdslkdsflk ")
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }


  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Seçiniz" });
    }

    return newArray;
  }

  formatSize = (n: number) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "KB";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "MB";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "GB";
    return n;
  };

  CityChange(item: any, districtId?: any) {
    const request2: CountyGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "cityId",
          value: item,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
        {
          propertyName: "Status",
          value: StatusEnums.Published,
          isOr: false,
          conditionOperator: ConditionOperatorEnum.Eq,
        },
      ],
      selectItemList: ["DistrictId", "DistrictName"],
      orderField: "DistrictName",
    };
    castThunkAction(this.props.getAllC(request2)).then((response: any) => {
      this.props.setFormData("districtId", districtId);
    });
  }

  onRemoveAllVersionAttachmentCertificateList = (
    index: number,
    attachmentId: number
  ) => {
    let allVersionAttachmentCertificateList =
      this.props.formData?.allVersionAttachmentCertificateList || [];

    let certificateAttachmentIdList =
      this.props.formData?.certificateAttachmentIdList || [];
    if (
      allVersionAttachmentCertificateList &&
      allVersionAttachmentCertificateList.length > 0
    )
      allVersionAttachmentCertificateList = removeArrayItem(
        index,
        allVersionAttachmentCertificateList
      );

    certificateAttachmentIdList.push(attachmentId);

    this.props.setFormData(
      "certificateAttachmentIdList",
      certificateAttachmentIdList
    );
    this.props.setFormData(
      "allVersionAttachmentCertificateList",
      allVersionAttachmentCertificateList
    );
  };

  renderFileUpload() {
    let fileuploadStatus = false;

    if (fileuploadStatus) {
      this.setState({
        ...this.state,
        fileUploadContainer: (
          <FileUpload
            onProcessStartFile={() => {
              this.setState({
                isFileUploading: true,
              });
            }}
            onProcessEndFile={(queue: boolean) => {
              if (!queue) {
                this.setState({
                  isFileUploading: false,
                });
              }
            }}
            onTransferedFile={(file: any, promise?: boolean) => {
              this.props.setFormData("attachmentList", [
                ...(this.props.formData?.attachmentList || []),
                file.attachmentIdList[0],
              ]);
            }}
            requestKey="files"
            token={token}
            endpointUrl={apiConfig.prefixes.attachment + "UploadFile"}
          />
        ),
      });
    }
  }

  renderForm() {
    const { formData, formErrors, setFormData } = this.props;

    const isDelete = formData.isDelete;

    //2116 r5 madde 2 
    const isDisabledPlaka = formData.isApproved ? true : false

    
    const isDisabled = isDelete
      ? true
      : usertype === "2" &&
        this.props.formData.correctionType !== 1 &&
        this.props.formData.correctionType !== null &&
        this.props.formData.correctionType !== 0
        ? true
        : false

    return (

      <div className="form-groups row">
        <div className="form-group col-md-4" style={{ paddingBottom: 0 }}>
          <div className="form-wrapper">
            <div className="item row">
              <TextField
                label="Taşıt ID"
                value={this.props.formData.transportId}
                disabled
                className="custom-textfield border"
              />
            </div>
            <div className="item row">
              <TextField
                placeholder="Taşıt Plaka No"
                value={formData.vehiclePlaque || ""}
                label="Taşıt Plaka No"
                type="text"
                className="custom-textfield border"
                errorMessage={formErrors.vehiclePlaque}
                onChange={(event: any) =>
                  setFormData("vehiclePlaque", event.target.value.replace(/\s/g, ''))
                }
                disabled={isDisabledPlaka ? isDisabledPlaka : isDisabled}
              />
            </div>
            <div className="item row">
              <Dropdown
                disabled={isDisabled}
                id="certificateTypeIdDesc"
                onChanged={(event: any) => (
                  setFormData("certificateTypeId", event.key)
                )}
                errorMessage={formErrors.certificateTypeId}
                label="Taşıt Belge Tipi"
                options={(this.props.formData.GroupListTY?.dataList
                  ? this.returnOptions(
                    this.props.formData.GroupListTY?.dataList,
                    "CertificateTypeId",
                    "Title"
                  )
                  : ([] as any)
                ).map((item: any, key: any) => ({
                  key: item.CertificateTypeId,
                  text: item.Title,
                  keys: key,
                }))}
                selectedKey={formData.certificateTypeId || 0}
                className="custom-dropdown"
              />
            </div>

            {this.props.formData.certificateTypeId === 4 ? (
              <div className="item row">
                <TextField
                  disabled={isDisabled}
                  id="documentNoDesc"
                  placeholder="Belge No"
                  value={formData.documentNo || ""}
                  label="Belge No "
                  className="custom-textfield border"
                  errorMessage={formErrors.documentNo}
                  onChange={(event: any) =>
                    setFormData("documentNo", event.target.value)
                  }
                />
              </div>
            ) : (
              ""
            )}
            <div className="item row">
              <TextField
                disabled={isDisabled}
                placeholder="Taşıt Vergi Numarası"
                value={formData.vkNo || ""}
                label="Taşıt Vergi Numarası"
                type="number"
                className="custom-textfield border"
                errorMessage={formErrors.vkNo}
                onChange={(event: any) =>
                  setFormData("vkNo", event.target.value)
                }
              />
            </div>

          </div>
        </div>
        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="item row">
              <TextField
                disabled={isDisabled}
                id="companyName"
                // disabled={this.props.formData.certificateTypeId === 4 ? true : false}
                placeholder="Belge Sahibi / Ticari Ünvan"
                value={formData.companyName || ""}
                label="Belge Sahibi / Ticari Ünvan"
                className="custom-textfield border"
                errorMessage={formErrors.companyName}
                onChange={(event: any) =>
                  setFormData("companyName", event.target.value)
                }
              />
            </div>
            <div className="item row">
              <Dropdown
                disabled={isDisabled}
                onChanged={(event: any) =>
                  setFormData("vehicleDocumentTypeId", event.key)
                }
                label="Aracın Sahip Olduğu Yetki Belgesi Sınıfı"
                options={(
                  (this.props.formData.GroupListVD && this.props.formData.GroupListVD?.dataList) ? this.returnOptions(this.props.formData.GroupListVD?.dataList, 'VehicleDocumentTypeId', 'VehicleDocumentTypeName') : ([] as any)
                ).map((item: any) => ({
                  key: item.VehicleDocumentTypeId,
                  text: item.VehicleDocumentTypeName,
                }))}
                selectedKey={formData.vehicleDocumentTypeId || 0}
                className="custom-dropdown"
                errorMessage={formErrors.vehicleDocumentTypeId}
              />
            </div>
            <div className="item row">
              <Dropdown
                onChanged={(event: any) =>
                  setFormData("vehicleTypeId", event.key)
                }
                disabled={isDisabled}
                label="Araç Cinsi"
                options={(
                  this.props.formData.GroupListVT && this.props.formData.GroupListVT?.dataList ? this.returnOptions(this.props.formData.GroupListVT?.dataList, 'VehicleTypeId', 'VehicleTypeName') : ([] as any)
                ).map((item: any) => ({
                  key: item.VehicleTypeId,
                  text: item.VehicleTypeName,
                }))}
                selectedKey={formData.vehicleTypeId || 0}

                className="custom-dropdown"
                errorMessage={formErrors.vehicleTypeId}
              />
            </div>
            <div className="item row">
              <Dropdown
                disabled={
                  usertype === "2" &&
                  this.props.formData.correctionType !== 1 &&
                  this.props.formData.correctionType !== null &&
                  this.props.formData.correctionType !== 0
                    ? true
                    : false
                }
                onChanged={(event: any) => (
                  this.CityChange(event.key), setFormData("cityId", event.key)
                )}
                label="İl"
                options={(
                  this.props.formData.GroupList?.dataList || ([] as any)
                ).map((item: any) => ({
                  key: item.CityId,
                  text: item.CityName,
                }))}
                className="custom-dropdown"
                selectedKey={this.props.formData.cityId}
                errorMessage={formErrors.cityId}
              />
            </div>
            <div className="item row">
              <Dropdown
                disabled={
                  usertype === "2" &&
                  this.props.formData.correctionType !== 1 &&
                  this.props.formData.correctionType !== null &&
                  this.props.formData.correctionType !== 0
                    ? true
                    : false
                }
                onChanged={(event: any) => setFormData("districtId", event.key)}
                label="İlçe"
                options={(formData.GroupListC?.dataList || ([] as any)).map(
                  (item: any) => ({
                    key: item.DistrictId,
                    text: item.DistrictName,
                  })
                )}
                className="custom-dropdown"
                selectedKey={this.props.formData.districtId}
                errorMessage={formErrors.districtId}
              />
            </div>
            <div className="item row">
              <TextField
                disabled={
                  usertype === "2" &&
                  this.props.formData.correctionType !== 1 &&
                  this.props.formData.correctionType !== null &&
                  this.props.formData.correctionType !== 0
                    ? true
                    : false
                }
                placeholder="Tesis Adresi"
                value={formData.address || ""}
                label="Tesis Adresi"
                className="custom-textfield border"
                errorMessage={formErrors.address}
                onChange={(event: any) =>
                  setFormData("address", event.target.value)
                }
              />
            </div>
          </div>
        </div>
        <div className="form-group col-md-4">
          <div className="form-wrapper">
            <div className="title">
              Ruhsat - Taşıt Kartı - Yetki Belgesi (3 belgeyi yüklemeden kayıt
              işlemi yapamazsınız)

            </div>
            <FileUpload
              onProcessStartFile={() => {
                this.setState({
                  isFileUploading: true,
                });
              }}
              onProcessEndFile={(queue: boolean) => {
                if (!queue) {
                  this.setState({
                    isFileUploading: false,
                  });
                }
              }}
              onTransferedFile={(file: any, promise?: boolean) => {
                this.props.setFormData("attachmentList", [
                  ...(this.props.formData?.attachmentList || []),
                  file.attachmentIdList[0],
                ]);
              }}
              requestKey="files"
              token={token}
              endpointUrl={apiConfig.prefixes.attachment + "UploadFile"}
            />
            {this.props.formData.attachmentList &&
              this.props.formData.attachmentList.length > 0 && (
                <React.Fragment>
                  <div className="title">Yüklenen Belgeler</div>
                  {JSON.stringify(this.props.formData.initialAttachmentList) !==
                    JSON.stringify(this.props.formData.attachmentList) && (
                      <div className="file-save-warning">
                        Düzenlemeleri ve yüklenen belgeleri kaydetmek için, sağ
                        üst kısımdan "Kaydet veya Yayımla"ya basın.
                      </div>
                    )}
                  <div className="uploaded-files">
                    {(this.props.formData?.attachmentList || []).map(
                      (
                        item: {
                          attachmentId: number;
                          attachmentPath: string;
                          fileName: string;
                          fileSize: number;
                        },
                        index: number
                      ) => (
                        <div key={index} className="uploaded-file">
                          <div className="name row">
                            <span className="text col no-padding">
                              {item.fileName}
                              <strong className="size m-left-auto">
                                {this.formatSize(item.fileSize)}
                              </strong>
                            </span>
                            <div className="buttons m-left-auto row">
                              <button
                                onClick={() =>
                                  window.open(
                                    qrUrl + item.attachmentPath,
                                    "_blank"
                                  )
                                }
                                className="view"
                              >
                                <i className="icon-explore"></i> Görüntüle
                              </button>
                              {(usertype === "2" &&
                                this.props.formData.correctionType !== 1 &&
                                this.props.formData.correctionType !== null &&
                                this.props.formData.correctionType !== 0) ||
                                (usertype !== "5" && !isDelete && (<button
                                  onClick={() => {
                                    let files = _.cloneDeep(
                                      this.props.formData?.attachmentList
                                    );
                                    let findIndex = (files || []).findIndex(
                                      (x: any) =>
                                        x.attachmentId === item.attachmentId
                                    );
                                    if (findIndex !== -1) {
                                      files.splice(findIndex, 1);
                                    }
                                    this.props.setFormData(
                                      "attachmentList",
                                      files
                                    );
                                  }}
                                  className="remove"
                                >
                                  <i className="icon-close"></i>
                                </button>))}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </React.Fragment>
              )}
          </div>
        </div>

        <div className="form-group col-md-4">
          <div className="form-wrapper">


            <div className="item row">
              <TextField
                placeholder="Taşıt E-posta Adresi"
                value={formData.email || ""}
                label="Taşıt E-posta Adresi"
                className="custom-textfield border"
                errorMessage={formErrors.email}
                onChange={(event: any) =>
                  setFormData("email", event.target.value)
                }
                disabled={isDisabled}
              />
            </div>

            <div className="item row">
              <Label>Taşıt Telefon Numarası</Label>
              <PhoneInput
                onlyCountries={this.state.onlyCountry}
                country={"tr"}
                placeholder="Taşıt Telefon Numarası"
                value={formData.phoneNumber || ""}
                onChange={(phone) => setFormData("phoneNumber", phone)}
                disableDropdown={true}
                disabled={isDisabled}
                disableCountryCode={true}
                masks={{ tr: "(...) ...-..-.." }}
                inputClass={"customizephone"}
                defaultErrorMessage={formErrors.phoneNumber}
              />
              {formErrors.phoneNumber && (
                <p className="ms-TextField-errorMessage errorMessage-63">
                  <span>{formErrors.phoneNumber}</span>
                </p>
              )}
            </div>
          </div>
        </div>



        <div className="form-group col-md-4">
          <div className="form-wrapper">

            <div className="item row">
              <TextField
                placeholder="Taşıtın Web Sitesi"
                value={formData.webSite || ""}
                label="Taşıtın Web Sitesi"
                className="custom-textfield border"
                errorMessage={formErrors.webSite}
                onChange={(event: any) =>
                  setFormData(
                    "webSite",
                    event.target.value === "" ? null : event.target.value
                  )
                }
                disabled={isDisabled}
              />
            </div>





            <div className="item row">
              <TextField
                placeholder="Koltuk Sayısı"
                value={formData.seatCount || ""}
                label="Koltuk Sayısı"
                className="custom-textfield border"
                errorMessage={formErrors.seatCount}
                onChange={(event: any) =>
                  setFormData(
                    "seatCount",
                    isNumberValue(event.target.value === "" ? null : event.target.value)
                  )
                }
                disabled={isDisabled}
              />
            </div>

            {usertype && usertype !== "2" ? (
              <div className="item row">
                <Dropdown
                  options={[
                    { key: 1, text: "Onaylanmış" },
                    { key: 0, text: "Onaylanmamış" },
                  ]}
                  label="Taşıt Onay Durumu"
                  placeholder="Seçiniz"
                  selectedKey={this.props.formData.isApproved ? 1 : 0}
                  onChanged={(event: any) =>
                    setFormData("isApproved", event.key)
                  }
                  calloutProps={{
                    className: "custom-dropdown-callout",
                  }}
                  disabled={isDisabled}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

      </div>
    );
  }
  getDate(date: any): any {
    if (date !== 0 && date !== undefined) {
      return toDate(date, "");
    }
  }
  showDialog(req: any): any {
    this.props.showErrorDialog(req);
  }

  renderStatusByType() {
    switch (this.props.formData.status) {
      case StatusEnums.Published:
        return (
          <div className="status">
            Durum: <strong>Yayımda</strong>
          </div>
        );
      case StatusEnums.Draft:
        return (
          <div className="status">
            Durum: <strong>Firma Değişti</strong>
          </div>
        );
      case StatusEnums.Deleted:
        return (
          <div className="status">
            Durum: <strong>Silindi</strong>
          </div>
        );
      case StatusEnums.DraftArchive:
        return (
          <div className="status">
            Durum: <strong>Uygun Görülmedi</strong>
          </div>
        );
      case StatusEnums.PendingApproval:
        return (
          <div className="status">
            Durum: <strong>Sertifika Süresi Bitenler</strong>
          </div>
        );
      case StatusEnums.ActivityStopped:
        return (
          <div className="status">
            Durum: <strong>Faaliyetini Durdurdu</strong>
          </div>
        );
      default:
        return null;
    }
  }
  onChangeStatus(status: StatusEnums) {
    const request: VehicleChangeStatusRequestModel = {
      id: this.props.HotelId,
      status: status,
    };
    castThunkAction(this.props.changeStatus(request)).then((response: any) => {
      this.setState(
        {
          messageType: response ? MessageBarType.success : MessageBarType.error,
        },
        () => {
          if (response) this.props.setFormData("status", status);
        }
      );
    });
  }
  render() {
    const { formData } = this.props;

    const isDelete = formData.isDelete;
    return (
      <div className="page push-all">
        {this.state.modalshow && (
          <Modal className={"warning custom-message-box danger"} isOpen={true}>
            <div className="modal-header row">
              <div className="title">
                {this.state.modalType === 1
                  ? "Dosya Siliyorsunuz Emin misiniz?"
                  : "Tesis dahilinde covid testi kiti bulunduğunu onaylıyor musunuz?"}
              </div>
              <span
                onClick={() => this.setState({ modalshow: false })}
                className="close"
              >
                <Icon iconName="ChromeClose" />
              </span>
            </div>
            <div
              style={{ paddingBottom: 0 }}
              className="description modal-content"
            >
              <div className="modal-indicators">
                {this.state.modalType === 1 && (
                  <DefaultButton
                    style={{ marginTop: 15 }}
                    text="EVET"
                    onClick={() => {
                      let files = _.cloneDeep(
                        this.props.formData?.attachmentList
                      );
                      let findIndex = (files || []).findIndex(
                        (x: any) =>
                          x.attachmentId === this.state.modelattachmentId
                      );
                      if (findIndex !== -1) {
                        files.splice(findIndex, 1);
                      }
                      this.props.setFormData("attachmentList", files);
                      this.setState({ modalshow: false });
                    }}
                  />
                )}
                {this.state.modalType === 2 && (
                  <DefaultButton
                    style={{ marginTop: 15 }}
                    text="EVET"
                    onClick={() => {
                      {
                        this.props.setFormData("isHaveHealthKit", true);
                        this.setState({
                          modalshow: false,
                          isHaveHealthKit: true,
                        });
                      }
                    }}
                  />
                )}
                {this.state.modalType === 1 && (
                  <DefaultButton
                    style={{ marginTop: 15 }}
                    text="İPTAL"
                    onClick={() => this.setState({ modalshow: false })}
                  />
                )}
                {this.state.modalType === 2 && (
                  <DefaultButton
                    style={{ marginTop: 15 }}
                    text="HAYIR"
                    onClick={() => {
                      this.setState({
                        modalshow: false,
                        isHaveHealthKit: false,
                      });
                      setFormData("isHaveHealthKit", false);
                    }}
                  />
                )}
              </div>
            </div>
          </Modal>
        )}

        <PageTitle
          titleRenderer={() => (
            <span className="edit-title">
              <strong>Taşıt Düzenle</strong>
              <strong>{this.props.formData?.title || ""}</strong>
            </span>
          )}
          showBack
          backUrl={usertype !== 2 ? "/Transport" : '/TransportCertification'}
          history={this.props.history}
          subTitle="Değiştirmek istediğiniz alanları aşağıda bulabilirsiniz."
          rightType="create"
          right={
            <FormButtons
              isAbsolute
              onSubmit={() => this.onSubmit()}
              customRenderButtons={() => (
                <React.Fragment>
                  {/* {
								//firma ise
								usertype === "2" ? (
									//belge verildi ise
									hasCertificateStatusTypeId === 2 ?
										// webde yayınlandı ise
										(hasIsPublishWeb ?
											// revize istendi ise koy
											(this.props.formData.correctionType === 1 || this.props.formData.correctionType === 2 ?
												this.getSaveButton()
												// revize istenmedi ise koyma
												: "")
											// webde yayınlanmadı ise
											: this.getSaveButton())
										//belge verilmedi ise koy
										: this.getSaveButton()
								)
									// firma değilse koy
									: this.getSaveButton()
							} */}

                  {this.getSaveButton()}

                  {this.state.isFileUploading && (
                    <div className="tooltip-warning">
                      Bazı dosyalar yükleniyor, lütfen dosyalar yüklenene kadar
                      bekleyiniz.
                    </div>
                  )}

                  {usertype !== "5" && usertype !== "2" ? (
                    <>
                      <Dropdown
                        disabled={
                          isDelete
                            ? true
                            : usertype === "2" &&
                              this.props.formData.correctionType !== 1 &&
                              this.props.formData.correctionType !== null &&
                              this.props.formData.correctionType !== 0
                              ? true
                              : false
                        }
                        style={{ width: "180px", marginRight: "10px" }}
                        options={RECORD_STATUS}
                        className="custom-dropdown "
                        selectedKey={this.props.formData?.status}
                        onChange={(event: any, option: any) => {
                          this.props.toggleConfirmDialog(
                            true,
                            "Kayıt durumunu değiştirmek istiyor musunuz?",
                            () => this.onChangeStatus(option.key)
                          );
                        }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {this.renderStatusByType()}
                </React.Fragment>
              )}
            />
          }
        />

        {/* {this.renderMessageBar()} */}

        <div className="record-info">
          {usertype !== "2" && this.props.formData.createdUserName && (
            <span>
              Ekleyen <label>{this.props.formData.createdUserName}</label>
            </span>
          )}
          {usertype !== "2" &&
            this.props.formData.updatedUserName &&
            this.props.formData.updatedUserName !== "" && (
              <span>
                Son Güncelleyen{" "}
                <label>{this.props.formData.updatedUserName}</label>
              </span>
            )}
        </div>
       
       <div style={{clear:'both'}}/>

        {this.renderForm()}
        {usertype !== "2" && this.renderList()}
      </div>
    );
  }

  showChangesField(): any {
    let desc = [];
    const changesField2: any[] = this.props.formData.changesField
      ? JSON.parse(this.props.formData.changesField)
      : [];
    for (let index = 0; index < changesField2.length; index++) {
      desc.push(<span>{changesField2[index].tr} </span>);
    }
    if (desc.length === 0) {
      desc.push(<span>Değişiklik Yok</span>);
    }
    return desc;
  }




  renderList() {
    return (
      <BasicTable
        currentVersion
        columns={[
          {
            columnName: "sequenceId",
            text: "Kayıt No",
            col: "col-md-1",
          },
          {
            columnName: "createdUserName",
            text: "Kayıt Yapan",
            col: "col-md-1",
          },
          {
            columnName: "createDateString",
            text: "Kayıt Tarihi",
            col: "col-md-1",
          },
          {
            columnName: "updatedUserName",
            text: "Güncelleyen",
            col: "col-md-1",
          },
          {
            columnName: "updateDateString",
            text: "Güncelleme Tarihi",
            col: "col-md-1",
          },

          {
            columnName: "companyUserTitle",
            text: "Denetleyici Firma",
            col: "col-md-1",
          },

          {
            columnName: "certificateStatusTypeName",
            text: "Durum",
            col: "col-md-1",
          },

          {
            columnName: "certificateNo",
            text: "Sertifika No",
            col: "col-md-1",
            onRender: (item: {
              certificateNo: any;
              certificateStatusTypeId: any;
            }) => (
              <React.Fragment>
                {item.certificateStatusTypeId === 2 ? item.certificateNo : ""}
              </React.Fragment>
            ),
          },

          {
            columnName: "appealDateString",
            text: "Sertifika Başvuru Tarihi",
            col: "col-md-1",
          },
          {
            columnName: "confirmationDateString",
            text: "Sertifika Düzenleme Tarihi",
            col: "col-md-1",
            onRender: (item: {
              confirmationDateString: any;
              certificateStatusTypeId: any;
            }) => (
              <React.Fragment>
                {item.certificateStatusTypeId === 2
                  ? item.confirmationDateString
                  : ""}
              </React.Fragment>
            ),
          },
          {
            columnName: "createdUserName",
            text: "Kayıt Durumu",
            col: "col-md-1",
            onRender: (item: any) => getRecordStatus(item.status)
          },
          {
            columnName: "endId",
            text: "İşlem",
            col: "col-md-1",
            className: "operations",
            onRender: (item: { endId: any; sequenceId: any; Status: StatusEnums, currentBySequenceId: boolean }) => (
              <React.Fragment>
                {
                  <>
                    <button onClick={(x) => this.goEdit(item.endId)}>
                      <i className="icon-edit-outline"></i> İncele
                    </button>
                    {item.currentBySequenceId && <button onClick={(x) => this.goCertificate(item.endId, item.sequenceId)}>
                      <i className="icon-edit-outline"></i> Sertifikaya Git
                    </button>}
                  </>
                }
              </React.Fragment>
            ),
          },
        ]}
        items={this.props.formData?.TransportSerticationList?.dataList || []}
      />
    );
  }
  goEdit(endId: any): any {
    window.open("/TransportCertification/editendId/" + endId);
  }
  goCertificate(endId: any, sequenceId: any): any {
    window.open("/TransportCertification/edit/" + endId + "/sequence/" + sequenceId);
  }
  
  getSaveButton(): any {
    const { formData } = this.props;

    const isDelete = formData.isDelete;
    return (
      usertype != "5" && (
        <PrimaryButton
          onClick={() => this.onSubmit()}
          disabled={isDelete ? true : this.state.isFileUploading ? true : false}
          styles={{
            rootDisabled: {
              background: "#ececec",
              borderColor: "#ccc !important",
              opacity: 0.5,
            },
          }}
          iconProps={{ iconName: "CheckMark" }}
          text={onSaveText(this.props.formData.status)}
        />
      )
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditVehicle);
