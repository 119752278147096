import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  TextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Dropdown,
} from "office-ui-fabric-react";
import {
  setFormData,
  setFormErrors,
  initialFormData,
} from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";
import { saveOrUpdateSystemUser } from "src/store/thunksSystemUser";
import { castThunkAction } from "src/helpers/casting";
import { SystemUserSaveRequestModel } from "src/services/SystemUser/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";
import { requestMapStatusData } from "src/helpers/functions";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        initialFormData,
        saveOrUpdateSystemUser,
      },
      dispatch
    ),
  };
}

type CreateSystemUserProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  messageType: MessageBarType;
}

class CreateJob extends Component<CreateSystemUserProps, OwnState> {
  state = {
    messageType: MessageBarType.warning,
  };

  UNSAFE_componentWillMount() {
    this.props.initialFormData({
      status: StatusEnums.Published,
    });
    this.props.setFormData("emailAddressList", []);
  }

  renderTextByMessageType() {
    switch (this.state.messageType) {
      case MessageBarType.success:
        return "Kaydetme işlemi başarıyla gerçekleşmiştir.";
      case MessageBarType.error:
        return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
      default:
        return "Lütfen aşağıdaki bilgileri doldurun, ve oluşturmayı tamamlayın!";
    }
  }

  renderMessageBar() {
    return (
      <MessageBar messageBarType={this.state.messageType} isMultiline={false}>
        {this.renderTextByMessageType()}
      </MessageBar>
    );
  }

  onCreate(isDraft?: boolean) {
    const request: SystemUserSaveRequestModel = requestMapStatusData(this.props.formData,isDraft)
    
    castThunkAction(this.props.saveOrUpdateSystemUser(request)).then(
      (response: any) => {
        this.setState(
          {
            messageType: response
              ? MessageBarType.success
              : MessageBarType.error,
          },
          () => {
            if (response.success) {
              this.props.history.push(
                "/systemuser-management/edit/" + response.id
              );
            }
          }
        );
      }
    );
  }

  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse İlgili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  onSubmit(isDraft?: boolean) {
    const { formData, setFormErrors } = this.props;
    const validationSchema = _yup.object({
      password: _yup
        .string()
        .matches(
          // eslint-disable-next-line
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "En az bir büyük harf, bir küçük harf, bir sayı ve bir özel karakter olmalıdır"
        )
        .required("Bu alan zorunludur!"),
      title: _yup.string().required("Bu alan zorunludur!"),
      uid: _yup.string().required("Bu alan zorunludur!"),
    });
    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});
        if (this.props.formData.systemUserType === 2) {
          if (
            this.props.formData.prefix &&
            this.props.formData.prefix !== "" &&
            this.props.formData.prefix !== null
          ) {
            this.onCreate(isDraft);
          } else {
            alert("Prefix Girmek Zorundasınız");
          }
        } else {
          this.onCreate(isDraft);
        }
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  addEmail() {
    //eslint-disable-next-lin
    const mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!mail.test(this.props.formData.newEmail)) {
      alert("Geçersiz e-Posta");
    } else {
      if (this.props.formData?.emailAddressList?.length > 0 && this.props.formData?.emailAddressList?.indexOf(this.props.formData.newEmail) >= 0) {
        alert("Eklemeye çalıştığınız e-posta sisteme kayıtlıdır.")
      } else {
        const newArray = this.props.formData?.emailAddressList ? Object.assign(this.props.formData?.emailAddressList) : [];
        newArray.push(this.props.formData.newEmail)
        this.props.setFormData("emailAddressList", newArray);
        this.props.setFormData("newEmail", "");
      }
    }
  }
  renderForm() {
    const { formData, formErrors, setFormData } = this.props;

    return (
      <div className="form-groups row">
        <div className="form-group col-md-4">
          <div className="title">Genel Bilgiler</div>
          <div className="form-wrapper">
            <div className="item row">
              <TextField
                placeholder="Kullanıcı Adınızı Giriniz"
                value={formData.uid || ""}
                label="Kullanıcı"
                className="custom-textfield border"
                errorMessage={formErrors.uid}
                onChange={(event: any) =>
                  setFormData("uid", event.target.value)
                }
              />
            </div>
            <div className="item row">
              <TextField
                placeholder="Adınızı Giriniz"
                value={formData.title || ""}
                label="İsim"
                className="custom-textfield border"
                errorMessage={formErrors.title}
                onChange={(event: any) =>
                  setFormData("title", event.target.value)
                }
              />
            </div>
            <div className="item row">
              <TextField
                type="password"
                placeholder="Şifre Giriniz"
                value={formData.password || ""}
                label="Şifre"
                className="custom-textfield border"
                autoComplete="new-password"
                resizable={false}
                errorMessage={formErrors.password}
                onChange={(event: any) =>
                  setFormData("password", event.target.value)
                }
              />
            </div>
            <div className="item row">
              <Dropdown
                onChanged={(event: any) =>
                  setFormData("systemUserType", event.key)
                }
                label="Kullanıcı Tipi"
                options={[
                  { key: 1, text: "Super Admin" },
                  { key: 2, text: "Firma" },
                  { key: 3, text: "TGA" },
                  { key: 4, text: "KTB" },
                  { key: 5, text: "Çağrı Merkezi" },
                ].map((item: any) => ({ key: item.key, text: item.text }))}
                className="custom-dropdown"
                selectedKey={this.props.formData.systemUserType}
              />
            </div>

            <div className="item row">
              <TextField
                placeholder="Sertifika No Prefix"
                value={formData.prefix || ""}
                label="Sertifika No Prefix"
                className="custom-textfield border"
                errorMessage={formErrors.prefix}
                onChange={(event: any) =>
                  setFormData("prefix", event.target.value)
                }
              />
            </div>


            <div className="item row">
              <TextField
                placeholder="E-Posta Adresi listesi"
                value={formData.newEmail || ""}
                label="E-Posta Adresi"
                className="custom-textfield border"
                errorMessage={formErrors.newEmail}
                type="email"
                onChange={(event: any) =>
                  setFormData("newEmail", event.target.value)
                }
              />
              <button className="addEmailBtn" onClick={() => this.addEmail()} style={{
                backgroundColor: '#eb8f0e',
                justifySelf:'flex-end' 
              }}>
                e-Posta Ekle
              </button>
            </div>

            <div className="item row">
              {this.props.formData?.emailAddressList?.map((x: any) => (
                <div className="newEmailDiv">
                  <label className="newEmailLabel">{x}</label>{" "}
                  <button
                    className="removeEmailBtn"
                    onClick={() => {
                      if (this.props.formData.emailAddressList && this.props.formData.emailAddressList.length > 1) {
                        this.props.setFormData(
                          "emailAddressList",
                          this.props.formData.emailAddressList.filter(
                            (y: any) => y !== x
                          )
                        )
                      } else {
                        alert('En az bir eposta adresi zorunlu.')
                      }

                    }}
                  >
                    Sil
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Yeni istem Kullanıcısı Ekle"
          titleRenderer={() => (
            <span className="edit-title">
              Yönet:
              <strong>Yeni Sistem Kullanıcısı Ekle</strong>
            </span>
          )}
          showBack
          backUrl="/systemuser-management"
          history={this.props.history}
          subTitle="Yeni bir Sistem Kullanıcısı eklemek için aşağıdaki bilgileri doldurunuz."
          rightType="create"
          right={
            <FormButtons
              isAbsolute
              onSubmit={() => this.onSubmit()}
              customRenderButtons={() => (
                <React.Fragment>
                  <PrimaryButton
                    onClick={() => this.onSubmit()}
                    disabled={
                      this.state.messageType === MessageBarType.success
                        ? true
                        : false
                    }
                    styles={{
                      rootDisabled: {
                        background: "#626262",
                        borderColor: "#777373 !important",
                      },
                    }}
                    iconProps={{ iconName: "CheckMark" }}
                    text="Yayımla"
                  />
                </React.Fragment>
              )}
            />
          }
        />
        {this.renderMessageBar()}

        {this.renderForm()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateJob);
