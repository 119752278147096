export type PanelOptions =
  | "system-pagess"
  | "adres-pages"
  | "other-pages"
  | "certificate-page"
  | "hotel-page"
  | "";

export interface IPanels {
  user?: number;
  user2?: number;
  icon: string;
  name: PanelOptions;
  text: string;
  url?: string;
  children?: IPanels[];
  subText?: string;
}

export const panels: IPanels[] = [
  {
    icon: "icon-anasayfa",
    name: "",
    user: 2,
    user2: 5,
    url: "/",
    text: "Anasayfa",
  },

  {
    icon: "fas fa-building",
    name: "hotel-page",
    text: "Tesis / Araç Yönetim İşlemleri",
    user2: 5,
    subText: "Sertifika İşlemleri sayfalarını aşağıdan görüntüleyebilirsiniz.",
    children: [
      {
        icon: "fas fa-building",
        name: "",
        user2: 5,
        url: "/hotel",
        text: "Konaklama Tesisi Yönetimi",
        subText:
          "Konaklama Tesisi   ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },

      {
        icon: "fas fa-hospital",
        name: "",
        user2: 5,
        url: "/hospital",
        text: "Anlaşmalı Sağlık Kuruluşu Yönetimi",
        subText:
          "Sağlık Kuruluşu  ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
      {
        icon: "fas fa-building",
        name: "",
        user2: 5,
        url: "/congress",
        text: "Kongre ve Sanat Tesisi Yönetimi",
        subText:
          "Kongre Salonu  ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },

      {
        icon: "fas fa-building",
        name: "",
        user2: 5,
        url: "/SeaTourism",
        text: "Deniz Turizm Tesisi ve Aracı Yönetimi",
        subText:
          "Deniz Turizm Tesisi ve Araç ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
      {
        icon: "fas fa-building",
        name: "",
        user2: 5,
        url: "/WinterTourism",
        text: "Kış Turizmi Mekanik Hatlar Tesisi Yönetimi",
        subText:
          "Kış Turizmi Mekanik Hatlar Tesisi işlemlerini yapabilirsiniz.",
      },
      {
        icon: "fas fa-building",
        name: "",
        user2: 5,
        url: "/ThemedFacility",
        text: "Temalı Tesisler Yönetimi",
        subText:
          "Temalı Tesis ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
      {
        icon: "fas fa-car",
        name: "",
        user2: 5,
        url: "/Transport",
        text: "Taşıt Yönetimi",
        subText:
          "Taşıt ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
    ],
  },
  {
    icon: "fa fa-ban",
    name: "other-pages",
    text: "Diğer Yönetim İşlemleri",
    user: 2,
    user2: 5,
    subText: "Şikayet düzenleme gibi işlemleri yapabilirsiniz.",
    children: [
      {
        icon: "fa fa-ban",
        name: "",
        user: 2,
        user2: 5,
        url: "/denunciation",
        text: "Şikayet Yönetimi",
        subText: "Şikayet düzenleme gibi işlemleri yapabilirsiniz.",
      },
      {
        icon: "fas fa-calculator",
        name: "",
        user: 2,
        user2: 5,
        url: "/AdditionalControl",
        text: "Ek Denetimler Yönetimi",
        subText: "Şikayet düzenleme gibi işlemleri yapabilirsiniz.",
      },
      {
        icon: "fas fa-certificate",
        name: "",
        user: 2,
        user2: 5,
        url: "/Fair",
        text: "Fuar Yönetimi",
        subText: "Fuar düzenleme gibi işlemleri yapabilirsiniz.",
      },
    ],
  },
  {
    icon: "far fa-file",
    name: "certificate-page",
    text: "Sertifika İşlemleri",
    user: 2,
    user2: 5,
    subText: "Sertifika İşlemleri sayfalarını aşağıdan görüntüleyebilirsiniz.",
    children: [
      {
        icon: "icon-sistem-kullanicilari",
        name: "",
        user: 2,
        user2: 5,
        url: "/HotelCertification",
        text: "Konaklama Tesisi Sertifika Yönetimi",
        subText:
          "Konaklama Tesisi sertifikalarında  ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
      {
        icon: "icon-sistem-kullanicilari",
        name: "",
        user: 2,
        user2: 5,
        url: "/Restaurant",
        text: "Restoran / Kafe Sertifika Yönetimi",
        subText:
          "Restoran / Kafe sertifikalarında  ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
      {
        icon: "icon-sistem-kullanicilari",
        name: "",
        user: 2,
        user2: 5,
        url: "/TransportCertification",
        text: "Tur ve Transfer Araçları Sertifika Yönetimi",
        subText:
          "Tur ve Transfer Araçları sertifikalarında  ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
      {
        icon: "icon-sistem-kullanicilari",
        name: "",
        user: 2,
        user2: 5,
        url: "/Airport",
        text: "Havalimanı Sertifika Yönetimi",
        subText:
          "Havalimanı sertifikalarında  ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
      {
        icon: "icon-sistem-kullanicilari",
        name: "",
        user: 2,
        user2: 5,
        url: "/CongressCertification",
        text: "Kongre ve Sanat Tesisi Sertifika Yönetimi",
        subText:
          "Kongre ve Sanat Tesisi sertifikalarında  ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
      {
        icon: "icon-sistem-kullanicilari",
        name: "",
        user: 2,
        user2: 5,
        url: "/SeaTourismCertification",
        text: "Deniz Turizm Tesisi ve Aracı Sertifika Yönetimi",
        subText:
          "Deniz Turizm Tesisi ve Araç sertifikalarında  ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
      {
        icon: "icon-sistem-kullanicilari",
        name: "",
        user: 2,
        user2: 5,
        url: "/WinterTourismCertification",
        text: "Kış Turizmi Mekanik Hatlar Tesisi Sertifika Yönetimi",
        subText:
          "Kış Turizmi Mekanik Hatlar Tesisi sertifikalarında  ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
      {
        icon: "icon-sistem-kullanicilari",
        name: "",
        user: 2,
        user2: 5,
        url: "/ThemedFacilityCertification",
        text: "Temalı Tesisler Sertifika Yönetimi",
        subText:
          "Temalı Tesisler sertifikalarında  ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
      // {
      //   icon: "icon-sistem-kullanicilari",
      //   name: "",
      //   user2: 5,
      //   url: "/Comparison",
      //   text: "Sertifika İşlemleri 1",
      //   subText:
      //     "Konaklama Tesisi ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      // },
      {
        icon: "fas fa-shield-alt",
        name: "",
        user2: 5,
        url: "/CertificateStatusType",
        text: "Sertifika Durumu",
        subText:
          "Sertifika Durumu Yönetimi üzerinde ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
      {
        icon: "icon-sistem-kullanicilari",
        name: "",
        user2: 5,
        url: "/CertificateType",
        text: "Sertifika Tipi",
        subText:
          "Sertifika tipleri üzerinde ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
    ],
  },

  {
    icon: "fas fa-map-signs",
    name: "adres-pages",
    text: "Adres İşlemleri",
    user: 1,
    subText: "Adres İşlemleri sayfalarını aşağıdan görüntüleyebilirsiniz.",
    children: [
      {
        icon: "fas fa-map-signs",
        name: "",
        url: "/City",
        text: "İl Yönetimi",
        subText:
          "İl Yönetimi üzerinde ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
      {
        icon: "fas fa-map-signs",
        name: "",
        url: "/County",
        text: "İlçe Yönetimi",
        subText:
          "İlçe yönetimi üzerinde ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
    ],
  },

  {
    icon: "fas fa-cogs",
    name: "system-pagess",
    text: "Sistem Sayfaları",
    subText:
      "Sistem üzerinde yönetim sayfalarını aşağıdan görüntüleyebilirsiniz.",
    children: [
      {
        icon: "fas fa-chart-bar",
        name: "",
        user: 2,
        url: "/Statistic",
        text: "İstatistikler",
        subText: "Raporlama ve istatistik çıktıları alabilirsiniz",
      },
      {
        icon: "icon-sistem-kullanicilari",
        name: "",
        user: 1,
        url: "/systemuser-management",
        text: "Sistem Kullanıcıları Yönetimi",
        subText:
          "Sistem kullanıcıları üzerinde ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },

      // {
      // 	icon: "icon-sistem-kullanicilari",
      // 	name: "",
      // 	url: "/MainCategory",
      // 	text: "Ana Kategori Tipleri Yönetimi",
      // 	subText: "Ana kategori tipleri üzerinde ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz."
      // },
      {
        icon: "icon-sistem-kullanicilari",
        name: "",
        url: "/LayoverSubCategory",
        text: "Konaklama Alt Kategori Yönetimi",
        subText:
          "Konaklama Alt kategori tipleri üzerinde ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
      {
        icon: "icon-sistem-kullanicilari",
        name: "",
        url: "/LayoverFacilityType",
        text: "Konaklama Tesis Sınıfı Yönetimi",
        subText:
          "Konaklama tesis sınıfı üzerinde ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },

      {
        icon: "icon-sistem-kullanicilari",
        name: "",
        url: "/CongressSubCategory",
        text: "Kongre ve Sanat Tesisleri  Alt Kategori Yönetimi",
        subText:
          "Kongre ve Sanat Tesisleri  Alt kategori tipleri üzerinde ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
      {
        icon: "icon-sistem-kullanicilari",
        name: "",
        url: "/CongressFacilityType",
        text: "Kongre ve Sanat Tesisleri  Tesis Sınıfı Yönetimi",
        subText:
          "Kongre ve sanat tesisleri   sınıfı üzerinde ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
      // {
      // 	icon: "icon-sistem-kullanicilari",
      // 	name: "",
      // 	url: "/SeaTourismSubCategory",
      // 	text: "Deniz Turizm Tesisleri  Alt Kategori Yönetimi",
      // 	subText: "Deniz Turizm Tesisleri  Alt kategori tipleri üzerinde ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz."
      // },
      // {
      // 	icon: "icon-sistem-kullanicilari",
      // 	name: "",
      // 	url: "/SeaTourismFacilityType",
      // 	text: "Deniz Turizm Tesisleri  Tesis Sınıfı Yönetimi",
      // 	subText: "Deniz Turizm tesisleri   sınıfı üzerinde ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz."
      // },
      {
        icon: "icon-sistem-kullanicilari",
        name: "",
        url: "/VehicleDocumentType",
        text: "Aracın sahip olduğu yetki belgesi Sınıfı Yönetimi",
        subText:
          "Aracın sahip olduğu yetki üzerinde ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
      {
        icon: "icon-sistem-kullanicilari",
        name: "",
        url: "/VehicleType",
        text: "Araç Cinsi Yönetimi",
        subText:
          "Aracın cinsi üzerinde ekleme, silme veya düzenleme gibi işlemleri yapabilirsiniz.",
      },
      {
        icon: "fas fa-building",
        name: "",
        url: "/mergeHotelRoute",
        text: "Otel Kayıtları Aktarımı",
        subText: "Bir konaklama tesisinden diğerine dataları aktarımını sağlar",
      },
    ],
  },
];
