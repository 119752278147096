import { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import { toggleConfirmDialog } from "src/store/system/actions";
import { setFormData } from "src/store/form/actions";
import {
  TextField,
  Dropdown,
  PrimaryButton,
  Label,
  ComboBox,
} from "office-ui-fabric-react";
import BasicTable from "../BasicTable";
import ReactPaginate from "react-paginate";
import { listCount } from "src/config/otherConfig";
import { castThunkAction } from "src/helpers/casting";
import { Report, excelDownload } from "src/store/thunkAdditionalAudit";
import cookie from "react-cookies";

import { CityGetAllRequestModel } from "src/services/City/RequestModel";
import { getAllM } from "src/store/thunksCity";
import { getAllC } from "src/store/thunkCounty";
import { getAllCR } from "src/store/thunkCertificateStatusType";
import { getAllLSU } from "src/store/thunkLayoverSubCategory";
import { getCompanyUserList } from "src/store/thunksSystemUser";

import { CountyGetAllRequestModel } from "src/services/County/RequestModel";
import { ConditionOperatorEnum } from "src/services/Hotel/RequestModel";
import {
  cityReturnOptions,
  getRecordStatusAll,
  isNumberValue,
  RECORD_STATUS_ALL_ENUM,
} from "src/helpers/functions";
import { StatusEnums } from "src/services/BaseEnum";
import { CertificateStatusTypeGetAllRequestModel } from "src/services/CertificateStatusType/RequestModel";
import { LayoverSubCategoryGetAllRequestModel } from "src/services/LayoverSubCategory/RequestModel";
import { SystemUserGetAllModel } from "src/services/SystemUser/RequestModel";

interface OwnProps {
  history:any;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        toggleConfirmDialog,
        setFormData,
        Report,
        excelDownload,
        getAllM,
        getAllCR,
        getAllC,
        getAllLSU,
        getCompanyUserList,
      },
      dispatch
    ),
  };
}

type AdditionalAuditProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  filterData: any;
  pagerCurrent: number;
  pagerTotal: number;
  download: boolean;
  excel: boolean;
}

const initialState = {
  filterData: {
    searchKey: "",
    districtId: 0,
    certificateNo: "",
    status: StatusEnums.All,
    cityId: 0,
    layoverSubCategoryId: 0,
    isPublishWeb: null,
    certificateStatusTypeId: 2,
    companyUserId: 0,
    correctionType: null,
    isPublishQr: 0,
    orderField: "UpdatedSystemUserDatetime",
    isDescending: true,
    hotelId: 0,
    certificateStatus: null,
    reportType: 0,
    sequenceId: "",
    companyName: "",
    isOpen: "all",
    isExempt: null,
    roomCount: 1,
    activeCompanyUserId: 0,
  },
  pagerCurrent: 0,
  pagerTotal: 0,
  excel: false,
  download: false,
};

class AdditionalAuditReport extends Component<AdditionalAuditProps, OwnState> {
  state = initialState;

  componentDidMount() {
    this.getData(this.state.pagerCurrent);
  }

  UNSAFE_componentWillUpdate(nextProps: any, nextState: any) {
    if (nextState.pagerTotal !== nextProps.formData.pageCount)
      this.setState({
        ...this.state,
        pagerTotal: this.props.formData.pageCount,
      });
  }

  getData(pagerSelected: number) {
    const filterData = {
      ...this.state.filterData,
      isOpen:
        this.state.filterData.isOpen === "all"
          ? null
          : this.state.filterData.isOpen,
    };
    const request: any = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...filterData,
    };

    castThunkAction(this.props.Report(request)).then((response: any) => {
      this.setState({
        pagerTotal: this.props.formData?.Report?.pageCount,
      });

      const request2: CityGetAllRequestModel = {
        selectItemList: ["CityId", "CityName"],
        orderField: "CityName",
      };

      this.props.getAllM(request2);

      const request3: LayoverSubCategoryGetAllRequestModel = {
        criteriaItemList: [
          {
            propertyName: "Status",
            value: 1,
            isOr: false,
            conditionOperator: 0,
          },
        ],
        selectItemList: ["LayoverSubCategoryId", "LayoverSubCategoryName"],
      };
      this.props.getAllLSU(request3);

      const request4: CertificateStatusTypeGetAllRequestModel = {
        criteriaItemList: [
          {
            propertyName: "Status",
            value: 1,
            isOr: false,
            conditionOperator: 0,
          },
        ],
        selectItemList: ["CertificateStatusTypeId", "Title"],
      };
      this.props.getAllCR(request4);

      const request6: SystemUserGetAllModel = {
        systemUserType: 2,
      };
      this.props.getCompanyUserList(request6);

      this.state.filterData.cityId !== 0 &&
        this.state.filterData.cityId !== null &&
        this.CityChange(this.state.filterData.cityId);
    });
  }

  cityOnChange(event: any, option: any, value: any) {
    if (value !== undefined) {
      this.CityChange(option.key);
      this.setState({
        filterData: {
          ...this.state.filterData,
          cityId: option.key,
          districtId: 0,
        },
      });
    } else {
      this.setState({
        filterData: {
          ...this.state.filterData,
          cityId: 0,
          districtId: 0,
        },
      });
      this.props.setFormData("GroupListC", []);
    }
  }

  CityChange(item: any) {
    this.setState(
      {
        filterData: {
          ...this.state.filterData,
          cityId: item,
        },
      },
      () => {
        const request2: CountyGetAllRequestModel = {
          criteriaItemList: [
            {
              propertyName: "cityId",
              value: item,
              isOr: false,
              conditionOperator: ConditionOperatorEnum.Eq,
            },
            {
              propertyName: "Status",
              value: 1,
              isOr: false,
              conditionOperator: 0,
            },
          ],
          selectItemList: ["DistrictId", "DistrictName"],
          orderField: "DistrictName",
        };
        this.props.getAllC(request2);
      }
    );
  }

  getDataPager(pagerSelected: number) {
    const filterData = {
      ...this.state.filterData,
      isOpen:
        this.state.filterData.isOpen === "all"
          ? null
          : this.state.filterData.isOpen,
    };
    const request: any = {
      pageSize: listCount,
      pageIndex: pagerSelected,
      ...filterData,
    };
    castThunkAction(this.props.Report(request)).then();
  }

  renderPager() {
    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.props.formData.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    );
  }

  handlePageClick = (data: any) => {
    let selected = data.selected;
    this.setState({ ...this.state, pagerCurrent: selected });
    this.getDataPager(selected);
  };

  goCertificate(endId: any, sequenceId: any): any {
    window.open(
      "/HotelCertification/edit/" + endId + "/sequence/" + sequenceId
    );
  }

  renderList() {
    return (
      <>
        <style>
          {`.table-list .table-body .list-td{text-align: left;}.table-list .row .list-th:last-child,.table-list .table-body .list-td:last-child{padding-right:0}.table-list .table-head .list-th,.table-list .table-body .list-td { flex: 0 0 7.69%;max-width: 7.69%; }.table-list .table-body .item,.table-list .table-head{padding-right:0}`}
        </style>
        <BasicTable
          columns={[
            {
              columnName: "facilityId",
              text: "Tesis ID",
              col: "col-md-1-4",
            },
            {
              columnName: "sequenceId",
              text: "Kayıt No",
              col: "col-md-1-4",
            },

            {
              columnName: "cityName",
              text: "İl / İlçe",
              col: "col-md-1-4",
              onRender: (item) => `${item.cityName} / ${item.districtName}`,
            },
            {
              columnName: "facilityName",
              text: "Tesis Adı",
              col: "col-md-1",
            },

            {
              columnName: "companyName",
              text: "Belge Sahibi / Ticari Ünvan",
              col: "col-md-1",
            },

            {
              columnName: "confirmationDateString",
              text: "Sertifika Düzenleme Tarihi",
              col: "col-md-1",
            },

            {
              columnName: "companyUserTitle",
              text: "Denetim Sorumlusu Denetleyici Firma",
              col: "col-md-1",
            },
            {
              columnName: "activeCompanyUserTitle",
              text: "Güncel Denetleyici Firma",
              col: "col-md-1",
            },

            {
              columnName: "status",
              text: "Kayıt Durumu",
              col: "col-md-1",
              onRender: (item: { status: any }) =>
                getRecordStatusAll(item.status),
            },
            {
              columnName: "reportTypeString",
              text: "Habersiz Denetim Durumu",
              col: "col-md-1",
            },
            /*{
              columnName: "withoutQrCertificateStatus",
              text: "Karekodsuz Sertifikalı durumu",
              col: "col-md-1-4",
            },*/

            {
              columnName: "activeIsOpen",
              text: "Güncel Tesis Faaliyet Durumu",
              col: "col-md-1",
              onRender: (item: { isOpen: any }) =>
                item.isOpen ? "Açık" : "Kapalı",
            },
            {
              columnName: "isExempt",
              text: "Muafiyet Durumu ",
              col: "col-md-1",
              onRender: (item: { isExempt: any }) =>
                item.isExempt ? "Var" : "Yok",
            },
            {
              columnName: "hotelId",
              text: "İşlem",
              col: "col-md-1",
              className: "operations",
              onRender: (item: any) => (
                <button
                  onClick={() =>
                    this.goCertificate(item.hotelCertificateId, item.sequenceId)
                  }
                >
                  Detay
                </button>
              ),
            },

            // {
            //   columnName: "certificateStatusTypeName",
            //   text: "Sertifika Durumu",
            //   col: "col-md-1-2",
            // },
          ]}
          items={this.props.formData?.Report?.dataList || []}
        />
      </>
    );
  }
  returnOptions(array: any[], key: string, text: string) {
    let newArray: any[] = array;
    if (newArray.filter((x) => x[key] === 0).length === 0) {
      newArray.unshift({ [key]: 0, [text]: "Tümünü Seç" });
    }
    return newArray;
  }

  downloadexcel() {
    this.setState({ download: true });
    const filterData = {
      ...this.state.filterData,
      isOpen:
        this.state.filterData.isOpen === "all"
          ? null
          : this.state.filterData.isOpen,
    };
    const requestexcel: any = {
      ...filterData,
    };

    castThunkAction(this.props.excelDownload(requestexcel)).then(
      (response: any) => {
        this.setState({ excel: true, download: false });
      }
    );
  }

  rederexcel() {
    return (
      <>
        {" "}
        <PrimaryButton
          text="Rapor Oluştur ve İndir"
          className="custom-button"
          onClick={() => this.downloadexcel()}
          style={{ marginLeft: "15px" }}
          styles={{
            root: {
              fontSize: "11px",
              marginTop: "27px",
              height: "30px",
            },
          }}
        />
        {this.state.download && (
          <Label style={{ marginLeft: "10px", marginTop: "27px" }}>
            Rapor Oluşturuluyor...
          </Label>
        )}
      </>
    );
  }

  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Habersiz Denetim Raporu"
          subTitle="Filtreler ile Konaklama tesisleri için Habersiz Denetim raporuna buradan erişebilirsiniz."
          backUrl="/"
          showBack
          history={this.props.history}
        />
        <div className="table-filter row">
          <TextField
            className="custom-textfield col-md-2"
            label="Tesis Adı"
            value={this.state.filterData?.searchKey || ""}
            onChange={(event: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  searchKey:
                    event.target.value !== "" ? event.target.value : "",
                },
              });
            }}
          />
          <TextField
            className="custom-textfield col-md-1"
            label="Tesis ID"
            value={
              this.state.filterData?.hotelId &&
                this.state.filterData?.hotelId !== 0
                ? this.state.filterData?.hotelId.toString()
                : ""
            }
            onChange={(event: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  hotelId: isNumberValue(event.target.value),
                },
              });
            }}
            type="text"
          />

          <TextField
            className="custom-textfield col-md-3"
            label="Belge Sahibi / Ticari Ünvan"
            value={this.state.filterData?.companyName || ""}
            onChange={(event: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  companyName:
                    event.target.value !== "" ? event.target.value : "",
                },
              });
            }}
          />
          <ComboBox
            selectedKey={this.state.filterData.cityId || ""}
            label="İl"
            allowFreeform={true}
            autoComplete="on"
            multiSelect={false}
            className="custom-dropdown col-md-2"
            onChange={(event: any, option: any, value: any) =>
              this.cityOnChange(event, option, value)
            }
            options={(this.props.formData.GroupList?.dataList
              ? cityReturnOptions(
                this.props.formData.GroupList?.dataList,
                "CityId",
                "CityName"
              )
              : ([] as any)
            ).map((item: any) => ({ key: item.CityId, text: item.CityName }))}
          />
          <Dropdown
            defaultSelectedKey={this.state.filterData?.districtId || ""}
            onChanged={(event: any) =>
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  districtId: event.key,
                },
              })
            }
            label="İlçe"
            options={(this.props.formData.GroupListC?.dataList
              ? this.returnOptions(
                this.props.formData.GroupListC?.dataList,
                "DistrictId",
                "DistrictName"
              )
              : ([] as any)
            ).map((item: any) => ({
              key: item.DistrictId,
              text: item.DistrictName,
            }))}
            className="custom-dropdown col-md-2"
          />

          <Dropdown
            defaultSelectedKey={
              this.state.filterData?.layoverSubCategoryId || 0
            }
            onChanged={(event: any) =>
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  layoverSubCategoryId: event.key,
                },
              })
            }
            label="Tesis Kategorisi"
            options={(this.props.formData.GroupListSU?.dataList
              ? this.returnOptions(
                this.props.formData.GroupListSU?.dataList,
                "LayoverSubCategoryId",
                "LayoverSubCategoryName"
              )
              : ([] as any)
            ).map((item: any) => ({
              key: item.LayoverSubCategoryId,
              text: item.LayoverSubCategoryName,
            }))}
            className="custom-dropdown col-md-2"
          />

          <Dropdown
            options={[
              { key: 1, text: "Tümü" },
              { key: 2, text: "30 Oda ve Altı" },
              { key: 3, text: "30 ile 49 Oda Arası" },
              { key: 4, text: "50 Oda ve Üstü" },
            ]}
            className="custom-dropdown col-md-2"
            label="Tesis Kapasitesi"
            placeholder="Tümü"
            selectedKey={this.state.filterData?.roomCount || null}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  roomCount: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />

          <ComboBox
            options={(this.props.formData.CompanyUserList?.dataList
              ? this.returnOptions(
                this.props.formData.CompanyUserList?.dataList,
                "systemUserId",
                "title"
              )
              : ([] as any)
            ).map((item: any) => ({
              key: item.systemUserId,
              text: item.title,
            }))}
            allowFreeform={true}
            autoComplete="on"
            className="custom-dropdown col-md-3"
            label="Denetim Sorumlusu Denetleyici Firma"
            placeholder="Seçiniz"
            selectedKey={this.state.filterData.companyUserId}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  companyUserId: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />

          <ComboBox
            placeholder="Seçiniz"
            className="custom-dropdown col-md-3"
            label="Güncel Denetleyici Firma"
            allowFreeform
            autoComplete="on"
            selectedKey={this.state.filterData.activeCompanyUserId}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,

                  activeCompanyUserId: option.key,
                },
              });
            }}
            options={(this.props.formData.CompanyUserList?.dataList
              ? this.returnOptions(
                this.props.formData.CompanyUserList?.dataList,
                "systemUserId",
                "title"
              )
              : ([] as any)
            ).map((item: any) => ({
              key: item.systemUserId,
              text: item.title,
            }))}
          />

          <Dropdown
            options={(this.props.formData.GroupListCR?.dataList
              ? this.returnOptions(
                this.props.formData.GroupListCR?.dataList,
                "CertificateStatusTypeId",
                "Title"
              )
              : ([] as any)
            ).map((item: any) => ({
              key: item.CertificateStatusTypeId,
              text: item.Title,
            }))}
            className="custom-dropdown col-md-2"
            label="Sertifika Durumu"
            placeholder="Seçiniz"
            selectedKey={this.state.filterData.certificateStatusTypeId}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  certificateStatusTypeId: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />

          <Dropdown
            options={[
              { key: "BrandName", text: "Tesis Adı" },
              { key: "CreatedSystemUserDatetime", text: "Oluşturma Tarihi" },
              { key: "UpdatedSystemUserDatetime", text: "Güncelleme Tarihi" },
            ]}
            className="custom-dropdown col-md-2"
            label="Sıralama Kriteri"
            placeholder="Seçiniz"
            selectedKey={this.state.filterData?.orderField || null}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  orderField: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />
          <Dropdown
            options={(this.state.filterData.orderField === "BrandName"
              ? [
                { key: "false", text: "Baştan Sona" },
                { key: "true", text: "Sondan Başa" },
              ]
              : [
                { key: "false", text: "Eskiden Yeniye" },
                { key: "true", text: "Yeniden Eskiye" },
              ]
            ).map((item: any) => ({
              key: item.key,
              text: item.text,
            }))}
            className="custom-dropdown col-md-2"
            label="Sıralama Türü"
            selectedKey={String(this.state.filterData?.isDescending) || "true"}
            placeholder="Seçiniz"
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  isDescending: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />
          <Dropdown
            options={RECORD_STATUS_ALL_ENUM}
            className="custom-dropdown col-md-3"
            label="Kayıt Durumu"
            placeholder="Seçiniz"
            selectedKey={this.state.filterData?.status || StatusEnums.All}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  status: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />

          <Dropdown
            options={[
              { key: "all", text: "Tümü" },

              {
                key: true,
                text: "Açık",
              },
              {
                key: false,
                text: "Kapalı",
              },
            ].map((item: any) => ({
              key: item.key,
              text: item.text,
            }))}
            className="custom-dropdown col-md-2"
            label="Güncel Tesis Faaliyet Durumu"
            placeholder="Seçiniz"
            selectedKey={this.state.filterData?.isOpen ?? "all"}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  isOpen: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />

          <Dropdown
            options={[
              { key: 0, text: "Tümü" },
              { key: 3, text: "Bekleniyor" },
              { key: 1, text: "Yapıldı" },
              { key: 2, text: "Yapılmadı" },

              // { key: 4, text: "Muaf" },
            ]}
            className="custom-dropdown col-md-2"
            label="Habersiz Denetim"
            placeholder="Seçiniz"
            selectedKey={this.state.filterData?.reportType || 0}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  reportType: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />
          <Dropdown
            options={[
              { key: null, text: "Seçiniz" },

              {
                key: true,
                text: "Var",
              },
              {
                key: false,
                text: "Yok",
              },
            ].map((item: any) => ({
              key: item.key,
              text: item.text,
            }))}
            className="custom-dropdown col-md-2"
            label="Muafiyet Durumu"
            placeholder="Seçiniz"
            selectedKey={this.state.filterData?.isExempt ?? null}
            onChange={(event: any, option: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  isExempt: option.key,
                },
              });
            }}
            calloutProps={{
              className: "custom-dropdown-callout",
            }}
          />

          <TextField
            className="custom-textfield col-md-1"
            label="Kayıt No"
            value={String(this.state.filterData?.sequenceId) || ""}
            onChange={(event: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  sequenceId:
                    event.target.value !== "" ? event.target.value : "",
                },
              });
            }}
          />

          <TextField
            className="custom-textfield col-md-2"
            label="Sertifika No"
            value={this.state.filterData?.certificateNo || ""}
            onChange={(event: any) => {
              this.setState({
                filterData: {
                  ...this.state.filterData,
                  certificateNo:
                    event.target.value !== "" ? event.target.value : "",
                },
              });
            }}
          />

          <PrimaryButton
            text="Arama Yap"
            className="custom-button"
            onClick={() => {
              this.setState({ excel: false });
              return (
                cookie.save(
                  "AdditionalAuditListState",
                  JSON.stringify(this.state),
                  {
                    path: "/",
                  }
                ),
                this.getDataPager(0)
              );
            }}
            style={{ marginLeft: "12px" }}
            styles={{
              root: {
                fontSize: "11px",
                marginTop: "27px",
                height: "30px",
              },
            }}
          />
          {this.rederexcel()}

          <PrimaryButton
            text="Tüm Filtreleri Temizle"
            className="custom-button"
            iconProps={{ iconName: "trash" }}
            onClick={() =>
              this.setState(
                {
                  ...this.state,
                  filterData: initialState.filterData,
                },
                () => {
                  cookie.save(
                    "AdditionalAuditListState",
                    JSON.stringify(this.state),
                    {
                      path: "/",
                    }
                  );
                  this.CityChange(0);
                  this.getDataPager(0);
                }
              )
            }
            style={{ marginLeft: "15px" }}
            styles={{
              root: {
                fontSize: "11px",
                marginTop: "27px",
                height: "30px",
                background: "#FF4343",
                borderColor: "#FF4350",
              },
            }}
          />
        </div>
        <div style={{ marginTop: "30px" }}>{this.renderList()}</div>
        {this.renderPager()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdditionalAuditReport);
