import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { toggleLoader, showMessage } from "./system/actions";
import { initialFormData, setFormData } from "./form/actions";
import AdditionalService from "src/services/AdditionalControl/AdditionalService";
import { MessageType } from "./system/types";
import { AdditionalChangeStatusRequestModel } from "src/services/AdditionalControl/RequestModel";
import { formatDate } from "src/helpers/converts";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function getAllPage(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const Additional = new AdditionalService();
    const result = await Additional.GetAll(request);
    dispatch(setFormData("AdditionalList", result));
    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}
export function getAllForExcel(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const Additional = new AdditionalService();
    const excelName = "EkDenetimModulu_" + formatDate();
    await Additional.excelDownload(request, excelName);

    dispatch(toggleLoader());

    return false;
  };
}

export function getAll(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const Additional = new AdditionalService();
    const result = await Additional.GetAll(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function save(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const Additional = new AdditionalService();
    const result = await Additional.save(request);
    // dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Geçerli işlem başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return result;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );

      return false;
    }
  };
}
export function update(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const Additional = new AdditionalService();
    const result = await Additional.update(request);
    // dispatch(setFormData("result", result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "Sertifika Yönetimi",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.errorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          "Sertifika Yönetimi"
        )
      );
      return false;
    }
  };
}

export function getById(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const Additional = new AdditionalService();
    const result = await Additional.GetById(request);
    dispatch(
      initialFormData({
        ...result.data,
        initialAttachmentList: result.data.attachmentList,
        initialAttachmentCertificateList: result.data.attachmentCertificateList,
        initialAttachmentUnCertificateList:
          result.data.attachmentUnCertificateList,
      })
    );
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAllForPager(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const Additional = new AdditionalService();
    const result = await Additional.GetAll(request);

    dispatch(setFormData("dataList", result.dataList));
    dispatch(setFormData("pageCount", result.pageCount));
    dispatch(setFormData("dataCount", result.dataCount));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function changeStatus(
  request: AdditionalChangeStatusRequestModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const Additional = new AdditionalService();
    const result = await Additional.changeStatus(request);
    dispatch(initialFormData(result));
    dispatch(toggleLoader());
    if (result.success) {
      dispatch(
        showMessage(
          "Tebrikler! Düzenleme işlemi başarıyla gerçekleşmiştir.",
          "Tebrikler!",
          MessageType.SUCCESS,
          "",
          true
        )
      );
      return true;
    } else {
      dispatch(
        showMessage(
          result.ErrorMessage,
          "Bir Sorun Oluştu!",
          MessageType.ERROR,
          ""
        )
      );
      return false;
    }
  };
}
