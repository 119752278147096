import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../store/reducer";
import { Dispatch } from "redux";
import PageTitle from "./PageTitle";
import { panels } from "src/utils/constants";
import { Link } from "react-router-dom";
import cookie from "react-cookies";

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {};
}

type DashboardProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class Dashboard extends Component<DashboardProps> {
  renderDashboardItems() {
    const usertype = cookie.load("systemUserInfo");
    return (
      <div className="dashboard-items row">
        {usertype !== "4" &&
          panels.map(
            (item, index) =>
              item.children &&
              (usertype === "2" ? (
                item.user === 2 && (
                  <React.Fragment key={index}>
                    <div className="title">
                      {item.text}
                      <div className="title-text">{item.subText}</div>
                    </div>
                    <div className="childrens row">
                      {item.children.map(
                        (children, childrenIndex) =>
                          usertype === "2" &&
                          children.user === 2 && (
                            <div
                              key={childrenIndex}
                              className={`dashboard-item col-md-3`}
                            >
                              <Link
                                className="row"
                                to={children.url ? children.url : "/"}
                              >
                                <div className="icon">
                                  <i className={children.icon}></i>
                                </div>
                                <div className="title">
                                  {children.text}
                                  <div className="sub-text">
                                    {children.subText}
                                  </div>
                                </div>
                              </Link>
                            </div>
                          )
                      )}
                    </div>
                  </React.Fragment>
                )
              ) : usertype === "5" ? (
                item.user2 === 5 && (
                  <React.Fragment key={index}>
                    <div className="title">
                      {item.text}
                      <div className="title-text">{item.subText}</div>
                    </div>
                    <div className="childrens row">
                      {item.children.map(
                        (children, childrenIndex) =>
                          usertype === "5" &&
                          children.user2 === 5 && (
                            <div
                              key={childrenIndex}
                              className={`dashboard-item col-md-3`}
                            >
                              <Link
                                className="row"
                                to={children.url ? children.url : "/"}
                              >
                                <div className="icon">
                                  <i className={children.icon}></i>
                                </div>
                                <div className="title">
                                  {children.text}
                                  <div className="sub-text">
                                    {children.subText}
                                  </div>
                                </div>
                              </Link>
                            </div>
                          )
                      )}
                    </div>
                  </React.Fragment>
                )
              ) : (
                <React.Fragment key={index}>
                  <div className="title">
                    {item.text}
                    <div className="title-text">{item.subText}</div>
                  </div>
                  <div className="childrens row">
                    {item.children.map((children, childrenIndex) =>
                      (usertype === "3" || usertype === "5") &&
                      children.user === 1 ? null : (
                        <div
                          key={childrenIndex}
                          className={`dashboard-item col-md-3`}
                        >
                          <Link
                            className="row"
                            to={children.url ? children.url : "/"}
                          >
                            <div className="icon">
                              <i className={children.icon}></i>
                            </div>
                            <div className="title">
                              {children.text}
                              <div className="sub-text">{children.subText}</div>
                            </div>
                          </Link>
                        </div>
                      )
                    )}
                  </div>
                </React.Fragment>
              ))
          )}
        {usertype === "4" && (
          <>
            <div className="title">
              Şikayet Yönetimi
              <div className="title-text">
                Şikayet düzenleme gibi işlemleri yapabilirsiniz.
              </div>
            </div>
            <div className="childrens row">
              <div className="dashboard-item col-md-3">
                <a className="row" href="/denunciation">
                  <div className="icon">
                    <i className="fa fa-ban"></i>
                  </div>
                  <div className="title">
                    Şikayet Yönetimi
                    <div className="sub-text">
                      Şikayet düzenleme gibi işlemleri yapabilirsiniz.
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="page push-all home-page">
        <PageTitle
          title="Anasayfa"
          subTitle="Yönetilebilecek tüm alanları aşağıdan görüntüleyebilirsiniz."
        />
        {this.renderDashboardItems()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
