import { HttpClient } from "src/library/HttpClient";
import {
  CongressFacilityTypeChangeStatusRequestModel,
  CongressFacilityTypeGetByIdRequestModel,
  CongressFacilityTypeUpdateRequestModel,
  CongressFacilityTypeSaveRequestModel,
  CongressFacilityTypeGetAllRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class CongressFacilityType {
  async getAll(request: CongressFacilityTypeGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressFacilityType + "GetAll",
      { ...request, token }
    );
  }

  async save(request: CongressFacilityTypeSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressFacilityType + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: CongressFacilityTypeUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressFacilityType + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(
    request: CongressFacilityTypeGetByIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressFacilityType + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(
    request: CongressFacilityTypeChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressFacilityType + "ChangeStatus",
      { ...request, token }
    );
  }
}
