import { HttpClient } from "src/library/HttpClient";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class AdditionalAudit {
  async Report(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.AdditionalAudit + "Report",
      { ...request, token }
    );
  }


  async excelDownload(
    request: any,
    name: string
  ) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.AdditionalAudit + "ReportExcel",
      name,
      { ...request, token }
    );
  }
  
}
