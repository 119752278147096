import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducer";
import { Dispatch, bindActionCreators } from "redux";
import PageTitle from "../PageTitle";
import {
  TextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Dropdown,
  Checkbox,
} from "office-ui-fabric-react";
import {
  setFormData,
  setFormErrors,
  initialFormData,
} from "src/store/form/actions";
import { History } from "history";
import * as _yup from "yup";
import FormButtons from "../FormButtons";
import { save } from "src/store/thunkSeaTourismFacilityType";

import { castThunkAction } from "src/helpers/casting";
import { SeaTourismFacilityTypeSaveRequestModel } from "../../services/SeaTourismFacilityType/RequestModel";
import { getAllLSU } from "src/store/thunkSeaTourismSubCategory";
import { SeaTourismSubCategoryGetAllRequestModel } from "../../services/SeaTourismSubCategory/RequestModel";
import { StatusEnums } from "src/services/BaseEnum";
import { requestMapStatusData } from "src/helpers/functions";

interface OwnProps {
  history: History;
}

function mapStateToProps(state: RootState) {
  return {
    language: state.system.language,
    formData: state.form.formData,
    formErrors: state.form.formErrors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    ...bindActionCreators(
      {
        setFormData,
        setFormErrors,
        initialFormData,
        save,
        getAllLSU,
      },
      dispatch
    ),
  };
}

type CreateSeaTourismFacilityTypeProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnState {
  messageType: MessageBarType;
}

class CreateSeaTourismFacilityType extends Component<
  CreateSeaTourismFacilityTypeProps,
  OwnState
> {
  state = {
    messageType: MessageBarType.warning,
  };

  UNSAFE_componentWillMount() {
    this.props.initialFormData({
      status: StatusEnums.Published,
    });

    const request: SeaTourismSubCategoryGetAllRequestModel = {
      criteriaItemList: [
        {
          propertyName: "Status",
          value: 1,
          isOr: false,
          conditionOperator: 0,
        },
      ],
      selectItemList: ["SeaTourismSubCategoryId", "SeaTourismSubCategoryName"],
    };
    this.props.getAllLSU(request);
  }

  renderTextByMessageType() {
    switch (this.state.messageType) {
      case MessageBarType.success:
        return "Kaydetme işlemi başarıyla gerçekleşmiştir.";
      case MessageBarType.error:
        return "Kayıt işlemi yapılırken bir hata oluşmuştur. Lütfen servis sağlayıcınızla iletişime geçin.";
      default:
        return "Lütfen aşağıdaki bilgileri doldurun, ve oluşturmayı tamamlayın!";
    }
  }

  renderMessageBar() {
    return (
      <MessageBar messageBarType={this.state.messageType} isMultiline={false}>
        {this.renderTextByMessageType()}
      </MessageBar>
    );
  }

  onCreate(isDraft?: boolean) {
    const request: SeaTourismFacilityTypeSaveRequestModel = requestMapStatusData(this.props.formData,isDraft) 
    
    castThunkAction(this.props.save(request)).then((response: any) => {
      this.setState(
        {
          messageType: response ? MessageBarType.success : MessageBarType.error,
        },
        () => {
          if (response) {
            this.props.history.push(
              "/SeaTourismFacilityType/edit/" + response.id
            );
          }
        }
      );
    });
  }

  /**
   * Validation Şemasını Kontrol Ederek, Eğer Validate ise Doğrulama modalını aktive eder, Değilse Alt Kategorigili Yerlere Hata Dönecek Yani,
   * formErrors Objesini Dolduracak Action'u Tetikler.
   */
  onSubmit(isDraft?: boolean) {
    const { formData, setFormErrors } = this.props;
    const validationSchema = _yup.object({
      seaTourismFacilityTypeName: _yup.string().required("Bu alan zorunludur!"),
      seaTourismSubCategoryId: _yup.string().required("Bu alan zorunludur!"),
    });

    let formErrors = {};
    validationSchema
      .validate(formData, {
        abortEarly: false,
      })
      .then(() => {
        setFormErrors({});
        this.onCreate(isDraft);
      })
      .catch((err) => {
        err.inner.forEach((e: any) => {
          const { path, message } = e;
          formErrors[path] = message;
        });
        setFormErrors(formErrors);
      });
  }

  renderForm() {
    const { formData, formErrors, setFormData } = this.props;
    return (
      <div className="form-groups row">
        <div className="form-group col-md-4">
          <div className="title">Genel Bilgiler</div>
          <div className="form-wrapper">
            <div className="item row">
              <TextField
                placeholder="Deniz Turizm Tesisi Sınıfı Adı"
                value={formData.seaTourismFacilityTypeName || ""}
                label="Deniz Turizm Tesisi Sınıfı Adı"
                className="custom-textfield border"
                errorMessage={formErrors.seaTourismFacilityTypeName}
                onChange={(event: any) =>
                  setFormData("seaTourismFacilityTypeName", event.target.value)
                }
              />
            </div>
            <div className="item row">
              <TextField
                placeholder="Deniz Turizm Tesisi Sınıfı Adı İngilizce"
                value={formData.seaTourismFacilityTypeName_En || ""}
                label="Deniz Turizm Tesisi Sınıfı Adı İngilizce"
                className="custom-textfield border"
                errorMessage={formErrors.seaTourismFacilityTypeName_En}
                onChange={(event: any) =>
                  setFormData(
                    "seaTourismFacilityTypeName_En",
                    event.target.value
                  )
                }
              />
            </div>
            <div className="item row">
              <Dropdown
                onChanged={(event: any) =>
                  setFormData("seaTourismSubCategoryId", event.key)
                }
                errorMessage={formErrors.seaTourismSubCategoryId}
                label="Alt Kategori"
                options={(
                  this.props.formData.GroupListSU?.dataList || ([] as any)
                ).map((item: any) => ({
                  key: item.SeaTourismSubCategoryId,
                  text: item.SeaTourismSubCategoryName,
                }))}
                className="custom-dropdown"
              />
            </div>

            <div className="item row">
              <Checkbox
                onChange={() =>
                  setFormData("isMinistry", !this.props.formData.isMinistry)
                }
                label="Diğer Kategorisinde Gösterilsin"
                className="custom-checkbox"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="page push-all">
        <PageTitle
          title="Yeni  Deniz Turizm Tesisi Sınıfı Ekle"
          titleRenderer={() => (
            <span className="edit-title">
              Yönet:
              <strong>Yeni Tesis Sınıfı Ekle</strong>
            </span>
          )}
          showBack
          backUrl="/SeaTourismFacilityType"
          history={this.props.history}
          subTitle="Yeni bir konakalama tesis Sınıfı eklemek için aşağıdaki bilgileri doldurunuz."
          rightType="create"
          right={
            <FormButtons
            isAbsolute
            onSubmit={() => this.onSubmit()}
            customRenderButtons={() => (
              <React.Fragment>
                <PrimaryButton
                  onClick={() => this.onSubmit()}
                  disabled={
                    this.state.messageType === MessageBarType.success
                      ? true
                      : false
                  }
                  styles={{
                    rootDisabled: {
                      background: "#626262",
                      borderColor: "#777373 !important",
                    },
                  }}
                  iconProps={{ iconName: "CheckMark" }}
                  text="Yayımla"
                />
              </React.Fragment>
            )}
          />
          }
        />
        {this.renderMessageBar()}
       
        {this.renderForm()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSeaTourismFacilityType);
