import { HttpClient } from "src/library/HttpClient";
import {
  SystemUserSystemUserLogin,
  SystemUserGetAllModel,
  SystemUserSaveRequestModel,
  SystemUserUpdateRequestModel,
  SystemUserGetByIdRequestModel,
  SystemUserChangeStatusModel,
  SystemUserCheckTokenModel,
  SystemUserChangePasswordRequestModel,
} from "./RequestModel";
import {
  SystemUserSystemUserLoginResponsesModel,
  SystemUserCheckTokenResponseModel,
} from "./ResponseModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class SystemUserService {
  /** Login İşlemi */
  async onLogin(
    request: SystemUserSystemUserLogin
  ): Promise<SystemUserSystemUserLoginResponsesModel> {
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.systemUser + "SystemUserLogin",
      request
    );
  }
  //  Tüm Listeyi Getiri
  async getAll(request: SystemUserGetAllModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.systemUser + "GetSystemUserList",
      { ...request, token }
    );
  }
  /** Kullanıcıların Kayıt  İşlemini Yapar */
  async saveOrUpdate(request: SystemUserSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.systemUser + "SaveOrUpdate",
      { ...request, token }
    );
  }
  async Update(request: SystemUserUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.systemUser + "Update",
      { ...request, token }
    );
  }

  /** ID ile Kullanıcıları Çekme */
  async getById(request: SystemUserGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.systemUser + "GetById",
      { ...request, token }
    );
  }
  /** Durum Değiştirme */
  async changeStatus(request: SystemUserChangeStatusModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.systemUser + "ChangeStatus",
      { ...request, token }
    );
  }
  /** Token Kontrolu */
  async checkToken(): Promise<SystemUserCheckTokenResponseModel> {
    const req: SystemUserCheckTokenModel = {};
    req.token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.systemUser + "checkToken",
      req
    );
  }
  async ChangePassword(
    request: SystemUserChangePasswordRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.systemUser + "ChangePassword",
      { ...request, token }
    );
  }
}
