import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Header from "../../components/Header";
import LeftPanel from "../../components/LeftPanel";
import { EditSystemUser } from "../../components/SystemUser";

export default class EditSystemUserManagement extends React.Component<
  RouteComponentProps<any>
> {
  render() {
    return (
      <React.Fragment>
        <Header history={this.props.history} />
        <div className="row">
          <LeftPanel history={this.props.history} />
          <EditSystemUser
            history={this.props.history}
            SystemUserId={this.props.match.params.SystemUserId}
          />
        </div>
      </React.Fragment>
    );
  }
}
