import { ThunkAction } from "redux-thunk";
import { RootState } from "./reducer";
import { Action } from "redux";
import { setFormData } from "./form/actions";
import Statistic from "src/services/Statistic/StatisticService";
import { toggleLoader } from "./system/actions";
import {
  GetUserLogCountListModel,
  ICertificationActionReportRequest,
} from "src/services/Statistic/RequestModel";
import { excelDownloadFileDate, formatDate } from "src/helpers/converts";

type ThunkResult<R> = ThunkAction<R, RootState, {}, Action>;

export function GetHotelCountByCity(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const StatisticService = new Statistic();
    const result = await StatisticService.GetHotelCountByCity(request);
    dispatch(setFormData("HotelCountByCity", result));
    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}
export function GetHotelByHospital(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const StatisticService = new Statistic();
    const result = await StatisticService.GetHotelByHospital(request);
    dispatch(setFormData("HotelByHospital", result));
    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function GetFacilitySummaryList(
  request: any,
  name: string
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const StatisticService = new Statistic();
    const result = await StatisticService.GetFacilitySummaryList(request, name);
    dispatch(setFormData("result", result));

    dispatch(toggleLoader());
    return false;
  };
}
export function GetDetailedSearch(
  request: any,
  name: string
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const StatisticService = new Statistic();
    const result = await StatisticService.GetDetailedSearch(request, name);
    dispatch(setFormData("result", result));

    dispatch(toggleLoader());

    return false;
  };
}

export function statisticDownload(): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const StatisticService = new Statistic();
    await StatisticService.download();
    dispatch(toggleLoader());

    return true;
  };
}

export function GetFacilitySummaryCount(
  request: any
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const StatisticService = new Statistic();
    const result = await StatisticService.GetFacilitySummaryCount(request);
    dispatch(setFormData("GetFacilitySummaryCount", result));
    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function GetDetailedSearchCount(
  request: any
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const StatisticService = new Statistic();
    const result = await StatisticService.GetDetailedSearchCount(request);
    dispatch(setFormData("GetDetailedSearchCount", result));
    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function GetCompanyUserStatistic(
  request: any
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const StatisticService = new Statistic();
    const result = await StatisticService.GetCompanyUserStatistic(request);
    dispatch(setFormData("GetCompanyUserStatistic", result));
    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function GetUserLogCount(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const StatisticService = new Statistic();
    const result = await StatisticService.GetUserLogCount(request);
    dispatch(setFormData("GetUserLogCount", result));
    dispatch(setFormData("pageCount", result.pageCount));
    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getAllUserLogCountForExcel(
  request: GetUserLogCountListModel
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const StatisticService = new Statistic();
    const result = await StatisticService.GetUserLogCount(request);
    dispatch(setFormData("GetUserLogCountForExcel", result));
    dispatch(toggleLoader());
    if (result.success) {
      return true;
    }
    return false;
  };
}

export function getGetCertificateReport(
  request: ICertificationActionReportRequest
): ThunkResult<Promise<boolean>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const StatisticService = new Statistic();
    const result = await StatisticService.getGetCertificateReport(request);
    dispatch(toggleLoader());
    if (result.success) {
      if (result.dataList && result.dataList.length > 0) {
        dispatch(setFormData("GetCertificateReport", result));
      } else {
        dispatch(
          setFormData("GetCertificateReport", {
            dataCount: 0,
            dataList: [],
            entityRecordInfo: null,
            errorCode: 0,
            errorMessageList: [],
            exception: null,
            methodName: null,
            pageCount: 0,
            totalCount: 0,
          })
        );
      }
      return true;
    } else {
      dispatch(
        setFormData("GetCertificateReport", {
          dataCount: 0,
          dataList: [],
          entityRecordInfo: null,
          errorCode: 0,
          errorMessageList: [],
          exception: null,
          methodName: null,
          pageCount: 0,
          totalCount: 0,
        })
      );
    }
    return false;
  };
}

export function excelDownloadCertificateReport(
  request: ICertificationActionReportRequest,
  date: Date = new Date()
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const excelName = "SertifikaAnlıkRapor_" + excelDownloadFileDate(date);

    const HotelCertificationService = new Statistic();
    await HotelCertificationService.excelDownloadCertificateReport(
      request,
      excelName
    );

    dispatch(toggleLoader());

    return true;
  };
}

export function getGetCertificateReportExcel(
  request: ICertificationActionReportRequest
): ThunkResult<Promise<boolean>> {
  request.pageSize = 0;
  request.pageIndex = 0;
  return async (dispatch) => {
    dispatch(toggleLoader());
    const StatisticService = new Statistic();
    const result = await StatisticService.getGetCertificateReport(request);
    dispatch(toggleLoader());
    if (result.success) {
      if (result.dataList && result.dataList.length > 0) {
        dispatch(setFormData("GetCertificateReportForExcel", result));
      } else {
        dispatch(
          setFormData("GetCertificateReportForExcel", {
            dataCount: 0,
            dataList: [],
            entityRecordInfo: null,
            errorCode: 0,
            errorMessageList: [],
            exception: null,
            methodName: null,
            pageCount: 0,
            totalCount: 0,
          })
        );
      }
      return true;
    } else {
      dispatch(
        setFormData("GetCertificateReportForExcel", {
          dataCount: 0,
          dataList: [],
          entityRecordInfo: null,
          errorCode: 0,
          errorMessage: "",
          errorMessageList: [],
          exception: null,
          exceptionMessage: "",
          innerExceptionMessage: "",
          methodName: null,
          pageCount: 0,
          totalCount: 0,
        })
      );
    }
    return false;
  };
}

export function GetFacilityCompanyList(
  request: any
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());

    const StatisticService = new Statistic();
    const result = await StatisticService.GetFacilityCompanyList(request);
    dispatch(setFormData("GetFacilityCompanyList", result));
    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}
export function GetFacilityCompanyUserStatistic(
  request: any
): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const StatisticService = new Statistic();
    const result = await StatisticService.GetFacilityCompanyUserStatistic(
      request
    );
    dispatch(setFormData("GetFacilityCompanyUserStatistic", result));
    dispatch(toggleLoader());

    if (result.success) {
      return true;
    }
    return false;
  };
}

export function excelDownload(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const excelName = "KullanıcıİşlemSayısıRaporu" + formatDate();

    const StatisticService = new Statistic();
    await StatisticService.excelDownload(request, excelName);
    dispatch(toggleLoader());

    return true;
  };
}
export function excelDownloadCompany(request: any): ThunkResult<Promise<any>> {
  return async (dispatch) => {
    dispatch(toggleLoader());
    const excelName = "DenetimFirmasiDegisiklikleri_" + formatDate();

    const StatisticService = new Statistic();
    await StatisticService.excelDownloadCompany(request, excelName);

    dispatch(toggleLoader());

    return true;
  };
}
