import { HttpClient } from "src/library/HttpClient";
import {
  CongressCertificationGetAllRequestModel,
  CongressCertificationGetByIdEndIdRequestModel,
  CongressCertificationSaveRequestModel,
  CongressCertificationUpdateRequestModel,
  CongressCertificationGetByIdRequestModel,
  CongressCertificationChangeStatusRequestModel,
  RemovePublish,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";
import { ILeaveCertificationProcessRequest } from "../BaseEnum";

export default class CongressCertification {
  async getAll(request: CongressCertificationGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressCertification + "GetCongressCertificateList",
      { ...request, token }
    );
  }

  async save(request: CongressCertificationSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressCertification + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: CongressCertificationUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressCertification + "SaveOrUpdate",
      { ...request, token }
    );
  }
  async updateBySequenceId(
    request: CongressCertificationUpdateRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressCertification + "UpdateBySequenceId",
      { ...request, token }
    );
  }

  async getById(
    request: CongressCertificationGetByIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressCertification + "GetById",
      { ...request, token }
    );
  }
  async getBySequenceId(
    request: CongressCertificationGetByIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressCertification + "GetBySequenceId",
      { ...request, token }
    );
  }

  async getByEndId(
    request: CongressCertificationGetByIdEndIdRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressCertification + "GetByEntityId",
      { ...request, token }
    );
  }

  async changeStatus(
    request: CongressCertificationChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressCertification + "ChangeStatus",
      { ...request, token }
    );
  }

  async GetCongressCertificationAndRestaurantCount(
    request: CongressCertificationGetAllRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressCertification +
        "GetCongressAndRestaurantCount",
      { ...request, token }
    );
  }
  async RemoveWebPublish(request: RemovePublish): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressCertification + "RemoveWebPublish",
      { ...request, token }
    );
  }
  async RemoveQrPublish(request: RemovePublish): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressCertification + "RemoveQrPublish",
      { ...request, token }
    );
  }
  async ChangeCompanyUser(request: any): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressCertification + "ChangeCompanyUser",
      { ...request, token }
    );
  }

  async excelDownload(request: any, name: string) {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().downloadFile(
      apiConfig.prefixes.CongressCertification + "GenerateExcel",
      name,
      { ...request, token }
    );
  }
  async leaveCertificationProcess(
    request: ILeaveCertificationProcessRequest
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.CongressCertification + "LeaveCertificationProcess",
      { ...request, token }
    );
  }

}
