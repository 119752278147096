import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Header from "../../components/Header";
import LeftPanel from "../../components/LeftPanel";
import { EditWinterTourism } from "../../components/WinterTourism";

export default class EditWinterTourismRoute extends React.Component<
  RouteComponentProps<any>
> {
  render() {
    return (
      <React.Fragment>
        <Header history={this.props.history} />
        <div className="row">
          <LeftPanel history={this.props.history} />
          <EditWinterTourism
            history={this.props.history}
            WinterTourismId={this.props.match.params.WinterTourismId}
          />
        </div>
      </React.Fragment>
    );
  }
}
