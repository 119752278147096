import * as React from "react";
import {
  Dialog,
  DialogType,
  DialogFooter,
} from "office-ui-fabric-react/lib/Dialog";
import {
  PrimaryButton,
  DefaultButton,
} from "office-ui-fabric-react/lib/Button";
import { getId } from "office-ui-fabric-react/lib/Utilities";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RootState } from "src/store/reducer";
import { toggleConfirmModalDialog } from "src/store/system/actions";

function mapStateToProps(state: RootState) {
  return {
    show: state.system.toggleConfirmModal.show,
    text: state.system.toggleConfirmModal.text,
    title: state.system.toggleConfirmModal.title,
    callback: state.system.toggleConfirmModal.callback,
    reject: state.system.toggleConfirmModal.reject,
    yesTitle: state.system.toggleConfirmModal.yesTitle,
    noTitle: state.system.toggleConfirmModal.noTitle,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      toggleConfirmModalDialog,
    },
    dispatch
  );
}

type ConfirmProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class Confirm extends React.Component<ConfirmProps> {
  _labelId: string = getId("dialogLabel");
  _subTextId: string = getId("subTextLabel");
//master
  render() {
    return (
      <div>
        <Dialog
          hidden={!this.props.show}
          onDismiss={() =>
            this.props.toggleConfirmModalDialog(false, "", "", () => {})
          }
          dialogContentProps={{
            type: DialogType.normal,
            title: this.props.title,
            closeButtonAriaLabel: "Kapat",
            subText: this.props.text,
          }}
          modalProps={{
            titleAriaId: this._labelId,
            subtitleAriaId: this._subTextId,
            isBlocking: false,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                this.props.callback(true);
                this.props.toggleConfirmModalDialog(false, "", "", () => {});
              }}
              text={this.props.yesTitle || "Evet"}
            />
            <DefaultButton
              onClick={() =>{
                  this.props.reject && this.props.reject(true);
                this.props.toggleConfirmModalDialog(false, "", "", () => {})
              } }
              text={this.props.noTitle || "Hayır"}
            />
          </DialogFooter>
        </Dialog>
        
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
