import { HttpClient } from "src/library/HttpClient";
import {
  CertificateAppealGetAllRequestModel,
  CertificateAppealSaveRequestModel,
  CertificateAppealUpdateRequestModel,
  CertificateAppealGetByIdRequestModel,
  CertificateAppealChangeStatusRequestModel,
} from "./RequestModel";
import { apiConfig } from "src/config/apiConfig";
import cookie from "react-cookies";

export default class CertificateAppeal {
  async getAll(request: CertificateAppealGetAllRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.certificateAppeal + "GetCertificateAppealList",
      { ...request, token }
    );
  }

  async save(request: CertificateAppealSaveRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.certificateAppeal + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async update(request: CertificateAppealUpdateRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.certificateAppeal + "SaveOrUpdate",
      { ...request, token }
    );
  }

  async getById(request: CertificateAppealGetByIdRequestModel): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.certificateAppeal + "GetById",
      { ...request, token }
    );
  }

  async changeStatus(
    request: CertificateAppealChangeStatusRequestModel
  ): Promise<any> {
    const token = cookie.load("userId");
    return HttpClient.getDefaultClient().post(
      apiConfig.prefixes.certificateAppeal + "ChangeStatus",
      { ...request, token }
    );
  }
}
